import React from "react";
import type { MouseEvent } from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";

type AcceptApplicationModalProps = {
	show: boolean;
	onClose: () => void;
	edit: () => void;
	currentModalDataProps: {
		currentModalData: {
			title: string;
			body: string;
			cancel: string;
			confirm: string;
		};
	};
};

export function AcceptApplicationModal({
	show,
	currentModalDataProps,
	onClose,
	edit,
}: AcceptApplicationModalProps) {
	const { currentModalData } = currentModalDataProps;
	return (
		<Modal
			show={show}
			title={currentModalData.title}
			body={currentModalData.body}
			onClose={() => {
				onClose();
			}}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					onClick={(e: MouseEvent<HTMLElement>) => {
						e.stopPropagation();
						onClose();
					}}
					label={currentModalData.cancel}
				/>,
				<ButtonPrimary
					key={1}
					onClick={(e: MouseEvent<HTMLElement>) => {
						e.stopPropagation();
						onClose();
						edit();
					}}
					label={currentModalData.confirm}
				/>,
			]}
		/>
	);
}
