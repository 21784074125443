import type { CampusType } from "@/types/campusTypes";
import type { promotionType } from "@/types/promotionTypes";
import { refreshOutline } from "@assets/Icons";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { MultiselectV2 } from "@designSystem/organisms/multiselectV2/MultiselectV2";
import React, { useEffect, useState } from "react";
import {
	fetchCampuses,
	fetchMetabaseEmbed,
	fetchPromotions,
} from "./SchoolStatisticsRequests";

export function SchoolStatistics() {
	const [embedUrl, setEmbedUrl] = useState("");
	const [campusesOptions, setCampusesOptions] = useState([]);
	const [promotionsOptions, setPromotionsOptions] = useState([]);
	const [selectedCampusesValues, setSelectedCampusesValues] = useState<
		string[]
	>([]);
	const [selectedPromotionsValues, setSelectedPromotionsValues] = useState<
		string[]
	>([]);

	const getEmbedUrl = async () => {
		const data = {
			campuses: selectedCampusesValues,
			promotions: selectedPromotionsValues,
			referents: [],
		};

		const response = await fetchMetabaseEmbed(data);
		setEmbedUrl(response);
	};

	const fetchCampusesOptions = async () => {
		const campuses = await fetchCampuses();
		setCampusesOptions(
			campuses.map((campus: CampusType) => ({
				label: campus.name,
				value: campus.id,
			})),
		);
	};

	const fetchPromotionsOptions = async () => {
		const promotions = await fetchPromotions(selectedCampusesValues);
		setPromotionsOptions(
			promotions.map((promotion: promotionType) => ({
				label: promotion.name,
				value: promotion.id,
			})),
		);
	};

	useEffect(() => {
		fetchPromotionsOptions();
	}, [selectedCampusesValues]);

	useEffect(() => {
		getEmbedUrl();
		fetchCampusesOptions();
	}, []);

	return (
		<main className="flex flex-col gap-sm h-[calc(100vh-128px)] overflow-hidden">
			<div className="flex gap-xsm">
				<MultiselectV2
					name="Campus"
					options={campusesOptions}
					onSelectOption={(updatedOptionsValues) => {
						setSelectedCampusesValues(updatedOptionsValues);
					}}
					preselectedOptionsValues={selectedCampusesValues}
				/>
				<MultiselectV2
					name="Promotions"
					options={promotionsOptions}
					onSelectOption={(updatedOptionsValues) => {
						setSelectedPromotionsValues(updatedOptionsValues);
					}}
					hasSearchbar
					preselectedOptionsValues={selectedPromotionsValues}
					searchbarPlaceholder="Rechercher une promotion"
				/>
				<ButtonPrimary label="Filtrer" onClick={getEmbedUrl} />
				<ButtonTertiary
					icon={refreshOutline}
					onClick={() => {
						setSelectedCampusesValues([]);
						setSelectedPromotionsValues([]);
						getEmbedUrl();
					}}
				/>
			</div>
			<iframe
				src={embedUrl}
				title="metabase"
				className="w-[calc(100%+50px)] flex-grow mx-[-25px] mb-[-60px]"
			/>
		</main>
	);
}
