import type { EventType } from "@/types/eventsTypes";
import React from "react";

import {
	checkmarkOutline,
	closeOutline,
	questionMarkOutline,
} from "@assets/Icons";

import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { isMentorGoalEvent } from "@tools/Events";
import { errorToast, success } from "@tools/Toasts";

import { updateEventReplyRequest } from "../../core/api/events.request";
import { useEventSubscriptions } from "./useEventSubscriptions";

export function EventSubscriptionRow({
	eventTarget,
	setIsKnowMoreModalOpen,
}: {
	eventTarget: EventType;
	setIsKnowMoreModalOpen?: Function;
}) {
	const presenter = useEventSubscriptions(eventTarget);

	const handleReplyModification = async (
		reply: "registered" | "maybe" | "notParticipating",
	) => {
		if (reply === presenter.invitationData?.reply) return;
		let response;
		if (presenter.invitationData?.id) {
			response = await updateEventReplyRequest(
				reply,
				presenter.invitationData.id,
				isMentorGoalEvent(eventTarget.type),
			);
		} else {
			response = await presenter.callSubscribeToEvent(reply);
		}

		if (response && response.responseLabel === "success") {
			presenter.updateInvitationData(reply);
			success("Votre réponse a bien été enregistrée");
			if (setIsKnowMoreModalOpen) setIsKnowMoreModalOpen(false);
		} else {
			errorToast(response?.message || "Une erreur est survenue");
		}
	};

	return (
		<>
			<div
				className="hidden w-full justify-between gap-2 md:flex"
				data-testid="inscriptionButtons"
			>
				<ButtonSecondary
					label="Participe"
					icon={checkmarkOutline}
					className={`w-full ${presenter.getOptionColors("registered")}`}
					onClick={() => {
						handleReplyModification("registered");
					}}
					disabled={presenter.isFetchingData}
				/>
				<ButtonSecondary
					label="Peut-être"
					icon={questionMarkOutline}
					className={`w-full ${presenter.getOptionColors("maybe")}`}
					onClick={() => {
						handleReplyModification("maybe");
					}}
					disabled={presenter.isFetchingData}
				/>
				<ButtonSecondary
					label="Participe pas"
					icon={closeOutline}
					className={`w-full ${presenter.getOptionColors("notParticipating")}`}
					onClick={() => {
						handleReplyModification("notParticipating");
					}}
					disabled={presenter.isFetchingData}
				/>
			</div>
			<div
				className="flex w-full gap-2 md:hidden"
				data-testid="inscriptionButtons"
			>
				<ButtonQuaternary
					label=""
					icon={checkmarkOutline}
					className={`!h-10 !w-full ${presenter.getOptionColors("registered")}`}
					onClick={() => {
						handleReplyModification("registered");
					}}
					disabled={presenter.isFetchingData}
				/>
				<ButtonQuaternary
					label=""
					icon={questionMarkOutline}
					className={`!h-10 !w-full ${presenter.getOptionColors("maybe")}`}
					onClick={() => {
						handleReplyModification("maybe");
					}}
					disabled={presenter.isFetchingData}
				/>
				<ButtonQuaternary
					label=""
					icon={closeOutline}
					className={`!h-10 !w-full ${presenter.getOptionColors(
						"notParticipating",
					)}`}
					onClick={() => {
						handleReplyModification("notParticipating");
					}}
					disabled={presenter.isFetchingData}
				/>
			</div>
		</>
	);
}
