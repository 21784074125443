import PropTypes from "prop-types";
import React from "react";

import { formatMonthYear } from "@tools/Dates";

import { InlineButton } from "@designSystem/atoms/InlineButton";
import { CardSecondary } from "@designSystem/molecules/CardSecondary";

export function Career({ formations, professionalExperiences, projects }) {
	return (
		<div className="career-container mt-lg">
			<div>
				<p className="title-career">Expérience professionnelle</p>
				{professionalExperiences.length > 0 ? (
					professionalExperiences?.map((exp, i) => {
						return (
							<CardSecondary
								key={i}
								title={exp.job}
								secondTitle={exp.organization?.name}
								className="my-3"
								subtitle={`${formatMonthYear(exp?.startDate)} - ${
									exp.endDate ? formatMonthYear(exp?.endDate) : "Aujourd'hui"
								}`}
								body={
									<p className="mt-sm text-xsm text-primary-500">
										{exp.detail}
									</p>
								}
							/>
						);
					})
				) : (
					<p className="flex items-center justify-center p-sm text-primary-150">
						Pas d&apos;expérience enregistrée
					</p>
				)}
			</div>
			<div>
				<p className="title-career">Formation</p>
				{formations.length > 0 ? (
					formations?.map((formation, i) => {
						return (
							<CardSecondary
								key={i}
								title={formation.name}
								secondTitle={formation.schoolName}
								className="my-3"
								subtitle={`${formatMonthYear(formation.startDate)} - ${
									formation.endDate
										? formatMonthYear(formation.endDate)
										: "Aujourd'hui"
								}`}
								body={
									<p className="mt-sm text-xsm text-primary-500">
										{formation.detail}
									</p>
								}
							/>
						);
					})
				) : (
					<p className="flex items-center justify-center p-sm text-primary-150">
						Pas de formation enregistrée
					</p>
				)}
			</div>
			<div>
				<p className="title-career">Projets</p>
				{projects.length > 0 ? (
					projects?.map((project, i) => {
						return (
							<CardSecondary
								key={i}
								title={project.name}
								className="my-2"
								body={
									<div>
										<p className="text-xsm text-primary-500">
											{project.detail}
										</p>
										<InlineButton
											className="mt-sm"
											label={project.url}
											onClick={() => {
												window.open(project.url);
											}}
										/>
									</div>
								}
							/>
						);
					})
				) : (
					<p className="flex items-center justify-center p-sm text-primary-150">
						Pas de projet enregistré
					</p>
				)}
			</div>
		</div>
	);
}

Career.propTypes = {
	studentProps: PropTypes.shape({
		id: PropTypes.string,
		professionalExperiences: PropTypes.arrayOf(),
		projects: PropTypes.arrayOf(),
		formations: PropTypes.arrayOf(),
	}).isRequired,
};
