import type { SelectionType } from "@/types/profileSharingTypes";
import { eyeOutline } from "@assets/Icons";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { InputCopy } from "@designSystem/molecules/InputCopy";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

type ShareLinkProps = {
	selection: Partial<SelectionType> | undefined;
};

export const ShareLink = ({ selection }: ShareLinkProps) => {
	const { user } = useContext(GlobalContext);
	const uri = `${import.meta.env.VITE_URL}/selection/${user?.schoolSubdomain}/${selection?.token}`;
	return (
		<div className={"flex flex-col gap-sm"}>
			<p className={"text-xxsm"}>
				Copiez le lien ci-dessous et envoyez le à votre interlocuteur pour
				partager les profils des étudiants.
			</p>
			<InlineButton
				id="share-link-preview"
				label="Prévisualiser le partage en tant qu’entreprise"
				icon={eyeOutline}
				onClick={() => {
					window.open(uri, "_blank");
				}}
			/>
			<InputCopy
				URI={uri}
				label="Copiez le lien ci-dessous et envoyez-le à l’entreprise"
			/>
		</div>
	);
};
