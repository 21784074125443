import React, { type ReactNode } from "react";

export function Subcontainer({
	children,
	withoutBackground = false,
}: {
	children: ReactNode;
	withoutBackground?: boolean;
}) {
	return (
		<article
			className={`flex w-full flex-col gap-sm rounded-lg ${
				withoutBackground ? "" : "bg-white p-md"
			} `}
			data-testid="subcontainer"
		>
			{children}
		</article>
	);
}
