import type {
	ApplicationStepType,
	ApplicationType,
} from "@/types/applicationTypes";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import "../../ApplicationDetails.scss";

import { clockOutline, pricetagsOutline } from "@assets/Icons";

import {
	INTERVIEW_ACTIONS,
	READY_TO_APPLY_ACTIONS,
	RELAUNCH_ACTIONS,
	SENT_ACTIONS,
} from "@constants/Actions";

import { BadgesGroup } from "@containers/student/Applications/ApplicationDetail/ui/Tabs/BadgesGroup";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { CheckboxLabelPrimary } from "@designSystem/molecules/CheckboxLabel";
import { Input } from "@designSystem/molecules/Input";
import { InputTime } from "@designSystem/molecules/InputTime";
import { DatePicker } from "@designSystem/organisms/DatePicker";
import { errorToast, success } from "@tools/Toasts";

import { createAction } from "@containers/student/Applications/core/api/ApplicationDetailAPI";

export type ActionType = {
	id: string;
	datetime: string;
	title: string;
	done: boolean;
	date: Date;
	hour: string;
	notes: string;
	type: string;
	user: string;
	application?: ApplicationType;
};

type ActionsProps = {
	application: {
		id: string;
		step: string;
	};
	actionsState: {
		actions: any[];
		setActions: (value: any[]) => void;
	};
};

type FormValuesType = {
	title: string;
	date: Date;
	hour: string;
	notes: string;
	done: boolean;
	[key: string]: string | Date | boolean | number;
};

type FormErrorsType = {
	title: string;
	date: string;
};

const DEFAULT_FORM_VALUES: FormValuesType = {
	title: "",
	date: new Date(),
	hour: "08:00",
	notes: "",
	done: false,
};

const DEFAULT_ERRORS: FormErrorsType = {
	title: "",
	date: "",
};

export function Actions({ application, actionsState }: ActionsProps) {
	const [formValues, setFormValues] =
		useState<FormValuesType>(DEFAULT_FORM_VALUES);
	const [formErrors, setFormErrors] = useState<FormErrorsType>(DEFAULT_ERRORS);
	const [currentStep, setCurrentStep] =
		useState<ApplicationStepType>("readyToApply");
	const { user } = useContext(GlobalContext);
	const { actions, setActions } = actionsState;

	type FormValueType = {
		value: string | number | boolean | Date;
		key: string;
	};
	const modifyValueInForm = async (valueToUpdate: FormValueType[]) => {
		const newValues = { ...formValues };
		valueToUpdate.forEach((value) => {
			newValues[value.key] = value.value;
		});
		setFormValues(newValues as unknown as FormValuesType);
	};

	const checkFormValidity = (): boolean => {
		const errors = { ...formErrors };
		if (!formValues.title || formValues.title === "") {
			errors.title = "Entrez un titre";
		} else {
			errors.title = "";
		}
		if (!formValues.date) {
			errors.date = "Entrez une date";
		} else {
			errors.date = "";
		}
		setFormErrors(errors);
		return !Object.values(errors).some((x) => x !== "");
	};

	const newAction = async (values: FormValuesType) => {
		const actionsCopy = [...actions];

		const dateWithHour = new Date(formValues.date);
		dateWithHour.setHours(Number(formValues.hour.split(":")[0]));
		dateWithHour.setMinutes(Number(formValues.hour.split(":")[1]));

		const dataForCreateAction = {
			application: application.id,
			title: values.title,
			note: values.notes,
			datetime: dateWithHour.toISOString(),
			done: values.done,
			type: "action",
			spsStudent: user?.id,
		};
		const response = await createAction(dataForCreateAction);
		if (response.responseLabel !== "success") {
			errorToast("L'action n'a pas pu être créée");
		} else {
			const action: ActionType = response?.data?.createAction?.action;
			if (!action) {
				errorToast("L'action n'a pas pu être créée correctement");
			}
			success("L'action a bien été créée");
			actionsCopy.push({
				id: action.id,
				datetime: action.datetime,
				title: action.title,
				done: action.done,
				date: values.date,
				hour: values.hour,
				notes: values.notes,
				type: "action",
				spsStudent: user?.id,
			});
			setActions([...actionsCopy]);
		}
	};

	const resolveAction = (step: string) => {
		switch (step) {
			case "sent":
				return SENT_ACTIONS;
			case "relaunch":
				return RELAUNCH_ACTIONS;
			case "interview":
				return INTERVIEW_ACTIONS;
			default:
				return READY_TO_APPLY_ACTIONS;
		}
	};

	const handleSubmitCreateAction = () => {
		if (!checkFormValidity()) return;
		newAction(formValues);
		setFormValues(DEFAULT_FORM_VALUES);
	};

	const handlePreselectAction = async (action: string) => {
		await modifyValueInForm([
			{ value: action, key: "title" },
			{
				value:
					resolveAction(currentStep)?.find((x) => x.name === action)?.note ||
					"",
				key: "notes",
			},
		]);
	};

	useEffect(() => {
		setCurrentStep(application.step as ApplicationStepType);
	}, [application]);

	return (
		<div className="actions-container w-full p-0">
			<div>
				<Input
					icon={pricetagsOutline}
					value={formValues.title}
					name="titleInput"
					onChange={(e) => {
						modifyValueInForm([{ value: e.target.value, key: "title" }]);
					}}
					className="mt-sm"
					placeholder="Nom de l'action"
					errorMessage={formErrors.title}
				/>
				<BadgesGroup
					className="mt-sm flex-wrap"
					onClick={(action) => {
						handlePreselectAction(action);
					}}
					badges={resolveAction(currentStep)?.map((action) => {
						return {
							label: action.name,
							bgColor: "bg-stone-light",
						};
					})}
					selectedBadges={[formValues.title]}
				/>

				<div className="input-date mt-sm flex">
					<DatePicker
						onDateChange={(value) => {
							modifyValueInForm([{ value, key: "date" }]);
						}}
						withIcon
						className="w-full"
						selectedDate={formValues.date}
						errorMessage={formErrors.date}
					/>
					<InputTime
						value={formValues.hour}
						className="ml-sm"
						icon={clockOutline}
						onChange={(value) => {
							modifyValueInForm([{ value, key: "hour" }]);
						}}
					/>
				</div>
				<TextArea
					value={formValues.notes}
					onChange={(value) => {
						modifyValueInForm([{ value, key: "notes" }]);
					}}
					className="mt-sm"
					placeholder="Notes"
					height={300}
				/>
				<div className="bottom-part mt-sm flex justify-between">
					<CheckboxLabelPrimary
						checked={formValues.done}
						onCheck={(checked: boolean) => {
							modifyValueInForm([{ value: checked, key: "done" }]);
						}}
						label="Marquer comme effectuée"
					/>
					<div className="buttons flex items-center">
						<ButtonQuaternary
							onClick={() => {
								setFormValues(DEFAULT_FORM_VALUES);
							}}
							label="Réinitialiser"
							className="w-fit-content mr-sm"
						/>
						<ButtonPrimary
							onClick={() => {
								handleSubmitCreateAction();
							}}
							label="Enregistrer"
							className="w-fit-content"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
