import type { CompaniesCellNameType } from "@/types/companiesManagement.types";
import { formatDistanceToNow } from "date-fns";

import type { CrmCompanyDomainModel } from "@/types/crmCompany.domainModel";
import { COMPANY_RELATIONS_MAPPING } from "@constants/Company";
import { cellFactory } from "@designSystem/templates/table/Cell.factory";

function useCompaniesCellFactory() {
	const currentCellFactory = cellFactory();

	const buildCompaniesCellFromName = (
		cellName: CompaniesCellNameType | "testing",
		companyData: CrmCompanyDomainModel.Company,
	) => {
		const cellsMapping = new Map<
			CompaniesCellNameType | "testing",
			JSX.Element
		>([
			["name", currentCellFactory.company(companyData, false)],
			[
				"relation",
				currentCellFactory.tag(
					COMPANY_RELATIONS_MAPPING.get(companyData?.relation) || {
						label: "Non renseignée",
						shortLabel: "NR",
						bgColor: "bg-stone-light",
					},
				),
			],
			[
				"spsReferent",
				currentCellFactory.person(companyData?.spsReferent, true),
			],
			[
				"location",
				currentCellFactory.text(
					companyData?.postalCode && companyData?.city
						? `${companyData?.postalCode}, ${companyData?.city}`
						: companyData?.city || "Non renseignée",
				),
			],
			[
				"offersCount",
				currentCellFactory.number(
					companyData?.offersCount ? companyData?.offersCount : 0,
				),
			],
			[
				"studentsPlaced",
				currentCellFactory.number(
					companyData?.studentsPlaced ? companyData?.studentsPlaced : 0,
				),
			],
			[
				"createdAt",
				currentCellFactory.text(
					companyData?.createdAt &&
						!Number.isNaN(Date.parse(companyData.createdAt))
						? formatDistanceToNow(new Date(companyData.createdAt), {
								addSuffix: true,
							})
						: "Date non renseignée",
				),
			],
			["testing", currentCellFactory.testing(companyData?.id)],
		]);
		return cellsMapping.get(cellName);
	};

	return {
		buildCompaniesCellFromName,
	};
}

export default useCompaniesCellFactory;
