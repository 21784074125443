import type { UserType } from "@/types/userTypes";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { GlobalContext } from "@navigation/Router";
import DOMPurify from "dompurify";
import React, { useContext, useState } from "react";

import { ALLOWED_TAGS_FOR_MESSAGE_VIEW } from "@constants/Mailbox";
import { MODULE_PRESET } from "@constants/Others";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { getUserAvatarPath } from "@tools/Users";

type CreateGroupedMessagesModalType = {
	show: boolean;
	studentSelectedMessageGrouped: UserType[];
	onClose: Function;
	onClick: Function;
};
export default function CreateGroupedMessagesModal({
	show,
	studentSelectedMessageGrouped,
	onClose,
	onClick,
}: CreateGroupedMessagesModalType) {
	const { user } = useContext(GlobalContext);
	const { isMobile } = useBreakpoints();

	const [message, setMessage] = useState<string>("");

	return (
		<Modal
			show={show}
			size="lg"
			onClose={() => {
				onClose();
			}}
			title="Message Multiple"
			body={
				<div className="flex flex-col gap-sm p-md">
					<div className="text-sm font-bold">
						Envoi du message multiple{" "}
						<span className="text-primary-300">
							• ({studentSelectedMessageGrouped.length} étudiants)
						</span>
					</div>
					<div>
						Rédigez ci-dessous le message à adresser à vos étudiants
						sélectionnés.
					</div>
					<div className="flex gap-sm overflow-hidden">
						<div
							className={`flex flex-col items-center gap-xsm ${
								isMobile ? "w-full" : "w-6/12"
							}`}
						>
							<div className="text-xxxsm uppercase text-primary-200">
								votre message
							</div>
							<RichTextEditor
								className="mb-sm w-full !flex-col [&_.ql-editor]:!border-none rounded-2xl border px-md py-xsm text-sm shadow [&_.ql-active]:!rounded [&_.ql-active]:!bg-primary-150 [&_.ql-container]:h-fit [&_.ql-container]:max-h-[380px] [&_.ql-container]:overflow-hidden  [&_.ql-container]:overflow-y-auto  [&_.ql-container]:bg-white [&_.ql-container]:text-xsm [&_.ql-toolbar]:bg-white [&_.ql-toolbar]:!p-0 !mt-0"
								modules={MODULE_PRESET}
								placeholder="Ecrire un message ..."
								value={message}
								onChange={(value) => {
									setMessage(value);
								}}
							/>
						</div>
						{!isMobile && (
							<div className="flex w-6/12 flex-col items-center gap-xsm">
								<div className="text-xxxsm uppercase text-primary-200">
									Prévisualisation du message
								</div>
								<div className="flex max-h-[430px] w-full items-start gap-sm overflow-hidden overflow-y-auto rounded-lg bg-primary-50 p-sm">
									<Avatar
										firstname={user?.firstname || ""}
										lastname={user?.lastname || ""}
										size="xs"
										image={user ? getUserAvatarPath(user) : ""}
									/>
									<div>
										<p className="font-semibold">
											{user?.firstname} {user?.lastname}
										</p>
										<div
											className="h-fit w-full [&>p]:break-all"
											dangerouslySetInnerHTML={{
												__html: DOMPurify.sanitize(message, {
													ALLOWED_TAGS: ALLOWED_TAGS_FOR_MESSAGE_VIEW,
												}),
											}}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					label="Revoir la sélection"
					onClick={() => {
						onClose(true);
					}}
				/>,
				<ButtonPrimary
					key={1}
					label={`Envoyer aux ${studentSelectedMessageGrouped?.length} étudiants`}
					onClick={() => {
						onClick();
						onClose();
					}}
				/>,
			]}
		/>
	);
}
