import type { CrmCompanyDomainModel } from "../../../../../types/crmCompany.domainModel";

export class CompanyDetailsPresenter {
	retrieveCompanyIdFromUrl(
		url: string,
	): CrmCompanyDomainModel.ExtractionResponse {
		if (typeof url !== "string") {
			return { success: false, id: "" };
		}
		const urlParts = url.split("/");
		if (urlParts.length < 2 || urlParts[urlParts.length - 1] === "") {
			return { success: false, id: "" };
		}
		return { success: true, id: urlParts[urlParts.length - 1] };
	}
}
