import { GlobalContext } from "@navigation/Router";
import React from "react";
import { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { diffDate } from "@tools/Dates";

import { Spinner } from "@designSystem/atoms/Spinner";

import { queryLogs } from "@containers/student/StudentLayout/SlidingPaneContent/SlidingPaneContentAPI";

import type { LogType } from "@/types/logTypes";
import { LogCard } from "./Logs/LogCard";

export function Logs() {
	const { user } = useContext(GlobalContext);
	const [thisWeekLogs, setThisWeekLogs] = useState<LogType[]>([]);
	const [lastWeekLogs, setLastWeekLogs] = useState<LogType[]>([]);
	const [olderLogs, setOlderLogs] = useState<LogType[]>([]);
	const [lastPage, setLastPage] = useState<number>(2);
	const [load, setLoad] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);

	const logsSplitter = (logs: LogType[]) => {
		setThisWeekLogs([
			...thisWeekLogs,
			...logs.filter((log) => {
				return diffDate(log.createdAt as Date) < 7;
			}),
		]);

		setLastWeekLogs([
			...lastWeekLogs,
			...logs.filter((log) => {
				return (
					diffDate(log.createdAt as Date) >= 7 &&
					diffDate(log.createdAt as Date) <= 14
				);
			}),
		]);

		setOlderLogs([
			...olderLogs,
			...logs.filter((log) => {
				return diffDate(log.createdAt as Date) > 14;
			}),
		]);
	};

	const fetchLogs = (page = 1) => {
		queryLogs(user?.id as string, page).then(
			({ collection, paginationInfo }) => {
				logsSplitter(collection);
				setPage(page);
				setLastPage(paginationInfo.lastPage);
				setLoad(false);
			},
		);
	};

	return (
		<div className="flex h-full flex-col">
			<p className="mb-lg h-4 text-sm font-semibold text-primary-700P">
				Voici l’historique de ton activité sur la plateforme :
			</p>
			<div className="mt-md flex flex-col overflow-y-scroll">
				<InfiniteScroll
					pageStart={1}
					loadMore={() => {
						if (!load) {
							setLoad(true);
							fetchLogs(page + 1);
						}
					}}
					useWindow={false}
					hasMore={page < lastPage}
					loader={
						<div className="mt-sm text-center" key={0}>
							<Spinner />
						</div>
					}
				>
					{thisWeekLogs.length > 0 && (
						<div>
							<p className="text-sm font-bold text-primary-700P">
								Cette semaine
							</p>
							<div className="mt-md flex flex-col gap-md">
								{thisWeekLogs.map((log) => {
									return <LogCard log={log} />;
								})}
							</div>
						</div>
					)}
					{lastWeekLogs.length > 0 && (
						<div className="my-md">
							<p className="text-sm font-bold text-primary-700P">
								La semaine dernière
							</p>
							<div className="mt-md flex flex-col gap-md">
								{lastWeekLogs.map((log) => {
									return <LogCard log={log} />;
								})}
							</div>
						</div>
					)}
					{olderLogs.length > 0 && (
						<div className="my-md">
							<p className="text-sm font-bold text-primary-700P">
								Plus anciens
							</p>
							<div className="mt-md flex flex-col gap-md">
								{olderLogs.map((log) => {
									return <LogCard log={log} />;
								})}
							</div>
						</div>
					)}
				</InfiniteScroll>
			</div>
		</div>
	);
}
