import { TagsGroup } from "@designSystem/atoms/TagsGroup";
import React from "react";
import type { UserDetails } from "../SelectionAPI";

type SkillsProps = {
	userDetails: UserDetails;
};

export const Skills = ({ userDetails }: SkillsProps) => {
	return (
		<div className="flex gap-sm flex-col">
			<p className="text-primary-700P text-sm font-bold">Compétences</p>
			<div className="flex gap-xxsm flex-col">
				<p className="font-bold text-xxxsm text-primary-300">Hard skills</p>
				<TagsGroup
					tags={userDetails.tags.filter((tag) => tag.type === "hardSkill")}
					className={"!max-w-full"}
				/>
			</div>
			<div className="flex gap-xxsm flex-col">
				<p className="font-bold text-xxxsm text-primary-300">Soft skills</p>
				<TagsGroup
					className={"!max-w-full"}
					tags={userDetails.tags.filter((tag) => tag.type === "softSkill")}
				/>
			</div>
		</div>
	);
};
