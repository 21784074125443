import type { CityType } from "@/types/LocationTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { pinOutline } from "@assets/Icons";

import { SelectLocation } from "@designSystem/organisms/select/SelectLocation";

import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

function LocationModifier() {
	const { offerToCreate } = useAppSelector((state) => state.offers);
	const dispatchEvent = useDispatch();

	const handleLocationChange = (value: CityType) => {
		if (!value) return;
		dispatchEvent(
			modifyOfferToCreate({
				location: value.location,
				longitude: value.longitude,
				latitude: value.latitude,
			}),
		);
	};
	return (
		<div data-testid="location">
			<SelectLocation
				icon={pinOutline}
				handleNewLocation={(e: CityType) => {
					handleLocationChange(e);
				}}
				defaultValue={{
					location: offerToCreate?.location || "",
					longitude: offerToCreate?.longitude,
					latitude: offerToCreate?.latitude,
				}}
				autoClear={false}
			/>
		</div>
	);
}

export default LocationModifier;
