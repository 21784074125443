import type { FilterType } from "@/types/filterType";
import type { SchoolOfferType, ScrapedOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";

import { bulbOutline } from "@assets/Icons";

import { JobboardFilter } from "@containers/student/Jobboard/ui/jobboard/ui/Body/JobboardFilter";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { InformationBanner } from "@designSystem/templates/informationBanner/InformationBanner";

import { setFilters } from "@containers/student/Jobboard/core/store/jobboard.slice";

import { HaveYouPostulateModal } from "../../../shared/HaveYouPostulateModal";

import { NoResults } from "./shared/NoResults";
import { OfferCardsSkeleton } from "./shared/OfferCardSkeleton";
import { ScrapedOfferCard } from "./shared/ScrapedOfferCard";

export function JobboardsOffers() {
	const dispatchJobboard = useDispatch();
	const { scrapedOffers, lastPage, loadScrapedOffers, tab, filters } =
		useAppSelector((state) => state.jobboard);

	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const [selectedOffer, setSelectedOffer] = useState<ScrapedOfferType>();
	const [showHaveYouPostulateModal, setShowHaveYouPostulateModal] =
		useState(false);

	const handleJobboardFilters = (filtersSelected: Array<FilterType>) => {
		dispatchJobboard(
			setFilters({
				...filters,
				jobboard_list: filtersSelected?.map((filter) => {
					return filter?.value;
				}),
				page: 1,
			}),
		);
	};

	return (
		<div className="col-span-4 flex flex-col md:col-span-8 lg:col-span-12">
			<InformationBanner
				icon={bulbOutline}
				className="mb-md hidden w-full md:flex"
				body={
					<div className="text-xsm text-primary-700P">
						<p>
							Il s'agit des offres d'emploi qui proviennent de certains
							jobboards (ex : Indeed, Pole emploi, Jobteaser...)
						</p>
					</div>
				}
			/>
			<JobboardFilter
				handleJobboardFilters={handleJobboardFilters}
				currentFilters={filters.jobboard_list ?? []}
			/>
			{scrapedOffers?.length === 0 && !loadScrapedOffers && <NoResults />}
			{scrapedOffers?.length > 0 && !loadScrapedOffers && (
				<div id="offers" className="mt-lg flex flex-col gap-sm">
					{scrapedOffers?.map((offer: SchoolOfferType | ScrapedOfferType) => {
						return (
							<ScrapedOfferCard
								offer={offer as ScrapedOfferType}
								key={offer?.id}
								setSelectedOffer={setSelectedOffer}
								setShowHaveYouPostulateModal={setShowHaveYouPostulateModal}
							/>
						);
					})}
					<PaginationPrimary
						last={lastPage}
						current={filters?.page}
						onChange={(e) => {
							dispatchJobboard(setFilters({ ...filters, page: e }));
							window.scrollTo(0, 0);
						}}
					/>
				</div>
			)}
			{(loadScrapedOffers || tab !== "jobboardsOffers") && (
				<OfferCardsSkeleton />
			)}{" "}
			{selectedOffer && currentInterface === "student" && (
				<HaveYouPostulateModal
					show={showHaveYouPostulateModal}
					offer={selectedOffer}
					onClose={() => {
						setShowHaveYouPostulateModal(false);
					}}
				/>
			)}
		</div>
	);
}
