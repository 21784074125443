import { useAppSelector } from "@config/redux/hook";
import { RichTextPreview } from "@designSystem/atoms/RichTextPreview";
import React from "react";

export function OfferDetailsDescription() {
	const { offerToEdit } = useAppSelector((state) => state.offers);
	return (
		<article
			data-testid="offerDetailsDescription"
			className="flex w-full flex-col gap-sm"
		>
			<p className="text-sm font-bold text-primary-700P">
				Description de l'offre
			</p>
			<RichTextPreview unsanitizedText={offerToEdit?.description || ""} />
		</article>
	);
}
