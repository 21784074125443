import { Icon, type IconifyIcon } from "@iconify/react";
import React from "react";

import "@assets/sass/ItemMenu.scss";

import { chevronRightOutline } from "@assets/Icons";

type ItemSimpleSmallPropsType = {
	label: string;
	icon?: IconifyIcon | null;
	className?: string;
	isSubMenu?: boolean;
	color?: string;
	onClick?: () => void;
};

export function ItemSimpleSmall({
	label,
	icon = null,
	className = "",
	isSubMenu = false,
	color = "primary-700P",
	onClick = () => {},
}: ItemSimpleSmallPropsType) {
	return (
		<div
			data-id="itemSimpleSmall"
			onClick={onClick}
			className={`${className}  simple-item-small-container`}
		>
			<div className="flex items-center">
				{icon && <Icon icon={icon} className={`with-icon text-${color}`} />}
				<p className={`text-${color}`}>{label}</p>
			</div>
			{isSubMenu && (
				<Icon icon={chevronRightOutline} className="with-sub-menu" />
			)}
		</div>
	);
}
