import type { EntOffersType } from "@/types/EntCompanyTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useNavigate } from "react-router";

import type { SchoolType } from "@/types/schoolTypes";
import OffersIllustration from "@assets/illustrations/entreprise/offers-illustration.svg";
import type { AppState } from "@config/redux/store";
import type { CompanySliceType } from "@containers/company/slice/companySlice";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";
import { extractIdNumberFromId } from "@tools/Users";
import { OfferCard } from "../Offers/ui/OfferCard";
import { OfferSkeleton } from "../Offers/ui/OfferSkeleton";

type HomepageRecruitmentsType = {
	latestSelections: EntOffersType[];
	selectionsLoading: boolean;
};

export function HomepageOffers({
	latestSelections,
	selectionsLoading,
}: HomepageRecruitmentsType) {
	const navigate = useNavigate();
	const { school, companySchoolProfile } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);

	return (
		<WhiteCard
			size="small"
			content={
				<>
					<div className="mb-sm flex justify-between">
						<p className="text-md font-bold">Recrutements en cours</p>
						{companySchoolProfile && (
							<ButtonSecondary
								label="Voir mes recrutements"
								onClick={() => {
									navigate(
										`/company/company-selections?${school?.subdomain || ""}`,
									);
								}}
							/>
						)}
					</div>
					{selectionsLoading ? (
						<div className="flex flex-col gap-md">
							<OfferSkeleton />
							<OfferSkeleton />
						</div>
					) : latestSelections.length ? (
						<div className="flex flex-col gap-sm">
							{latestSelections.map((selection, index) => {
								return (
									<OfferCard
										key={`offer-${selection.id}`}
										school={school as Partial<SchoolType>}
										className="!mt-0 border-2 border-primary-100"
										showCenterBlocks={false}
										index={index}
										offer={selection}
										onSelect={() => {
											navigate(
												`/company/company-selections?${
													school?.subdomain
												}&selectedOffer=${extractIdNumberFromId(selection.id)}`,
											);
										}}
									/>
								);
							})}
						</div>
					) : (
						<div>
							<img
								src={OffersIllustration as string}
								alt="Une femme sous un parapluie"
								className="mx-auto mb-sm h-[220px]"
							/>
							<p className="text-center font-bold">
								Aucun recrutement à ce jour.
							</p>
						</div>
					)}
				</>
			}
		/>
	);
}
