import React, { useContext, useState } from "react";

import { arrowForwardFill } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";
import { FileInput } from "@designSystem/molecules/FileInput";
import { FilesPrimary } from "@designSystem/molecules/Files";

import { MyProfileContext } from "../../MyProfile";

export function Document({
	label,
	file,
	filename,
	maxSize,
	documents,
	setDocuments,
	type,
}: any) {
	const [error, setError] = useState("");
	const [fileIsLoading, setFileIsLoading] = useState(false);
	const { uploadFile, updateProfileValue } = useContext(MyProfileContext);

	const handleOnSuccess = async (files: any) => {
		if (files !== undefined) {
			setFileIsLoading(true);
			const response = await uploadFile(files[0], type);
			if (response) {
				updateProfileValue({ [filename]: response["@id"] });
				setDocuments({
					...documents,
					[filename]: { ...documents[filename], file: response },
				});
			}

			setFileIsLoading(false);
			setError("");
		} else {
			setError(
				"Le fichier envoyé dépasse la limite de 1Mo ou n'est pas sous format PDF",
			);
		}
	};

	const handleDelete = async () => {
		setFileIsLoading(true);
		setDocuments({
			...documents,
			[filename]: { ...documents[filename], file: null },
		});
		updateProfileValue({ [filename]: null });

		setFileIsLoading(false);
	};
	return (
		<div className="w-full flex flex-col gap-xxsm">
			<p className="font-bold">{label}</p>
			{file?.filePath ? (
				<FilesPrimary
					isLoading={fileIsLoading}
					title={file?.filePath || ""}
					subtitle={`${Math.floor(file.size / 1000)} Ko`}
					className="mt-3"
					onDelete={() => {
						handleDelete();
					}}
					onView={() => {
						window.open(`${import.meta.env.VITE_S3}${file.filePath}`);
					}}
				/>
			) : (
				<div>
					<FileInput
						id={filename}
						isLoading={fileIsLoading}
						acceptFormats={{ "application/pdf": [] }}
						maxSize={maxSize}
						onSuccess={(files: any) => {
							handleOnSuccess(files);
						}}
						onError={(files: any) => {
							setError(
								files[0]?.size / 1000000 >= 1
									? "Le fichier envoyé dépasse la limite de 1Mo"
									: "Le fichier envoyé n'est pas sous format PDF",
							);
						}}
					/>
				</div>
			)}

			{error && <p className="text-error-dark">{error}</p>}
			{error && (
				<InlineButton
					id="compress-button"
					className="mt-xsm"
					icon={arrowForwardFill}
					onClick={() => {
						window.open("https://www.ilovepdf.com/compress_pdf", "_blank");
					}}
					label="Compresser mon fichier"
				/>
			)}
		</div>
	);
}
