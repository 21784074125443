import type { OrganizationType } from "@/types/organizationTypes";
import { GlobalContext } from "@navigation/Router";
import { Formik } from "formik";
import React, { useContext, useState } from "react";

import { briefcaseOutline, pinOutline } from "@assets/Icons";
import CITIES from "@assets/json/cities.json";

import { ORGANIZATIONS_BUSINESS_LINE_OPTIONS } from "@constants/Organizations";

import { removeUndefinedValue } from "@tools/Objects";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Combobox } from "@designSystem/molecules/Combobox";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { success } from "@tools/Toasts";

import { SpontaneousApplicationContext } from "../..";
import { addOrganization, autoComplet } from "../../SpontaneousApplicationAPI";

type CreateOrganizationModalType = {
	show: boolean;
	onClose: Function;
};

type CompanyType = { label: string; logo: string; domain: string };
export function CreateOrganizationModal({
	show,
	onClose,
}: CreateOrganizationModalType) {
	const { user } = useContext(GlobalContext);
	const { setFilters, filters } = useContext(SpontaneousApplicationContext);
	const [companyList, setCompanyList] = useState<CompanyType[]>([]);
	const [load, setLoad] = useState(false);
	const [locationSelectOptions, setLocationSelectOptions] = useState(
		CITIES.slice(0, 4),
	);
	const [businessLinesList, setBusinessLinesList] = useState(
		ORGANIZATIONS_BUSINESS_LINE_OPTIONS,
	);

	const handleBusinessLine = (businessLine: string) => {
		setBusinessLinesList(
			ORGANIZATIONS_BUSINESS_LINE_OPTIONS.filter((option) => {
				return option.label.toLowerCase().includes(businessLine.toLowerCase());
			}),
		);
	};

	const handleLocation = (location: string) => {
		setLocationSelectOptions(
			CITIES.filter((city) => {
				return city.label.toLowerCase().includes(location.toLowerCase());
			}).slice(0, 4),
		);
	};

	const autocompletAPI = async (company: string) => {
		const companyList: { logo: string; domain: string; label: string }[] = [];
		if (!company) {
			setCompanyList(companyList);
			return;
		}
		const data: any = await autoComplet(company);

		if (data) {
			data.map((company: OrganizationType) => {
				if (company) {
					return companyList.push({
						logo: company.logo,
						domain: company.domain,
						label: company.name,
					});
				}

				return "";
			});

			setCompanyList(companyList);
		}
	};

	const createOrganization = (
		values: { [k: string]: any },
		resetForm: Function,
	) => {
		const inputs = {
			...values,
			school: user?.school,
		};

		setLoad(true);
		addOrganization(inputs)
			.then(() => {
				success("L'entreprise a bien été créée !");

				setFilters({ ...filters });
				onClose();
				setLoad(false);
			})
			.finally(() => {
				resetForm({});
			});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				logo: undefined,
				name: "",
				linkedin: "",
				phone: "",
				website: "",
				locations: [],
				businessSector: "",
				businessLines: [],
			}}
			validate={(values) => {
				const errors: { name?: string } = {};
				if (values.name === "") {
					errors.name = "Veuillez renseigner un nom";
				}
				return errors;
			}}
			onSubmit={(values, { resetForm }) => {
				const filterValue = removeUndefinedValue(values);
				createOrganization(filterValue, resetForm);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				resetForm,
			}) => (
				<Modal
					title="Ajouter une entreprise"
					onClose={() => {
						resetForm({});
						onClose();
					}}
					show={show}
					body={
						<div>
							<div className="flex flex-col items-center">
								<Avatar
									image={values.logo || ""}
									firstname={values.name.split(" ")[0]}
									lastname={values.name.split(" ")[1]}
									onChange={(e: { url: string }) => {
										setFieldValue("logo", e.url);
									}}
									size="lg"
								/>
								<Combobox
									value={values.name}
									onChange={(e) => {
										setFieldValue("name", e.target.value);
										autocompletAPI(e.target.value);
									}}
									options={companyList}
									className="mt-md"
									placeholder="Nom de l'entreprise"
									onClickOnItem={(elem) => {
										setFieldValue("logo", elem.logo);
									}}
									errorMessage={errors.name && touched.name && errors.name}
								/>
							</div>
							<div className="flex flex-col gap-sm">
								<Combobox
									placeholder="Localisation"
									label="Localisation"
									options={locationSelectOptions}
									icon={pinOutline}
									value={values.locations[0]}
									hideAvatar
									onChange={(e) => {
										handleLocation(e.target.value);
									}}
									onClickOnItem={(e) => {
										setFieldValue("locations", [e.label]);
									}}
								/>
								<Combobox
									className="[&_.options-list]:-translate-y-[130%] [&_.options-list]:shadow-2xl"
									placeholder="Secteurs d'activité"
									label="Secteurs d'activité (optionnel)"
									options={businessLinesList}
									icon={briefcaseOutline}
									value={values.businessSector}
									hideAvatar
									onChange={(e) => handleBusinessLine(e.target.value)}
									onClickOnItem={(e) => {
										setFieldValue("businessSector", e.label);
										setFieldValue("businessLines", [e.label]);
									}}
								/>
							</div>
						</div>
					}
					buttonsRight={[
						<ButtonSecondary
							label="Fermer"
							onClick={() => {
								resetForm({});
								onClose();
							}}
						/>,
						<ButtonPrimary
							label="Créer"
							onClick={() => {
								handleSubmit();
							}}
							disabled={load}
						/>,
					]}
				/>
			)}
		</Formik>
	);
}
