import React from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { errorToast } from "@tools/Toasts";

type DeleteModalType = {
	label: string;
	show: boolean;
	type: string;
	onClose: () => void;
	entityId?: string;
	editCareer: Function;
};

export default function DeleteModal({
	label,
	show,
	type,
	onClose,
	entityId,
	editCareer,
}: DeleteModalType) {
	const handleDelete = async () => {
		try {
			await editCareer(type, "delete", { id: entityId });
			onClose();
		} catch (_error) {
			errorToast("Erreur lors de la suppression");
		}
	};

	return (
		<Modal
			onClose={onClose}
			title="Confirmer la suppression"
			show={show}
			body={<p>{label}</p>}
			buttonsRight={[
				<ButtonSecondary
					label="Non, annuler"
					onClick={() => {
						onClose();
					}}
				/>,
				<ButtonPrimary
					id="deleteExpBtn"
					label="Oui, supprimer"
					onClick={() => {
						handleDelete();
					}}
				/>,
			]}
		/>
	);
}
