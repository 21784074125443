import type { ApplicationType } from "@/types/applicationTypes";
import type { LinkItem } from "@/types/genericType";
import { Icon } from "@iconify/react";
import React, { type MouseEvent, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router";

import { pinOutline } from "@assets/Icons";

import { checkUrl } from "@tools/Checkers";

import type { OrganizationType } from "@/types/organizationTypes";
import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { Divider } from "@designSystem/atoms/Divider";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { deleteCookie, getCookie, setCookie } from "@tools/Cookies";
import { extractIdNumberFromId } from "@tools/Users";
import { SaveApplicationModal } from "./SaveApplicationModal";

type OrganizationNetworkCardType = {
	id: string;
	image: string;
	name: string;
	location: string;
	links: LinkItem[];
	className: string;
	onClick: () => void;
	application?: Partial<ApplicationType>;
	generateSpontaneousApplication: (
		organization: Partial<OrganizationType>,
	) => void;
};

const DONT_SHOW_AGAIN_COOKIE = "dontShowSaveApplicationModalAgain";

export function OrganizationNetworkCard({
	id,
	image,
	name,
	location,
	links,
	className,
	onClick,
	application,
	generateSpontaneousApplication,
}: OrganizationNetworkCardType) {
	const truncateRef = useRef<HTMLSpanElement | null>(null);

	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const navigate = useNavigate();
	const [showSaveApplicationModal, setShowSaveApplicationModal] =
		useState<boolean>(false);

	const [showTooltip, setShowTooltip] = useState(false);

	useEffect(() => {
		if (!truncateRef?.current) return;

		const element = truncateRef.current;
		if (element && element.scrollWidth > element.offsetWidth) {
			setShowTooltip(true);
		}
	}, [truncateRef]);

	const handleClickOnSaveApplication = (e: MouseEvent) => {
		e.stopPropagation();
		const cookie = getCookie(DONT_SHOW_AGAIN_COOKIE);
		if (cookie) {
			saveAppplication();
		} else {
			setShowSaveApplicationModal(true);
		}
	};

	const triggerCloseModal = () => {
		setShowSaveApplicationModal(false);
	};

	const triggerClickOnApproved = () => {
		setShowSaveApplicationModal(false);
		saveAppplication();
	};

	const saveAppplication = () => {
		generateSpontaneousApplication({
			id,
			name,
		});
	};

	const triggerDontShowAgain = (status: boolean) => {
		if (status) {
			setCookie(DONT_SHOW_AGAIN_COOKIE, "true", 31536000);
		} else {
			deleteCookie(DONT_SHOW_AGAIN_COOKIE);
		}
	};

	return (
		<div className={`${className} card-quartenary relative`}>
			<div
				className="h-max-full flex cursor-pointer flex-col rounded-lg bg-white p-sm shadow-lg"
				onClick={() => {
					onClick();
				}}
			>
				<div className="flex flex-col justify-between">
					<div className="flex">
						<Avatar image={image} firstname={name} lastname="" />
						<div className="ml-sm flex flex-col overflow-hidden">
							{showTooltip && (
								<TooltipContainer
									anchorId={`#tooltip-complete-name-${extractIdNumberFromId(id)}`}
									place="top"
								>
									{name}
								</TooltipContainer>
							)}
							<span
								ref={truncateRef}
								id={`tooltip-complete-name-${extractIdNumberFromId(id)}`}
								className="wg-no-translate truncate text-xsm font-bold text-primary-700P"
							>
								{name}
							</span>

							<div className="flex items-center">
								<Icon icon={pinOutline} className="text-primary-700P" />
								<p className="ml-xxsm text-xsm font-bold text-primary-500">
									{location}
								</p>
							</div>
						</div>
					</div>
					{currentInterface === "school" && (
						<div className="br-corner mt-sm flex self-end">
							{links?.map(({ icon, link }: LinkItem, index) => {
								return (
									link && (
										<ButtonTertiary
											key={`link-${index}`}
											icon={icon}
											onClick={() => window.open(link)}
											className="ml-sm"
										/>
									)
								);
							})}
						</div>
					)}
				</div>
				{currentInterface === "student" && (
					<div>
						<Divider className="mx-xsm" />
						<div className="mt-sm flex flex-wrap items-center justify-between">
							<div className="flex flex-col sm:w-full md:w-6/12">
								{application ? (
									<ButtonPrimary
										label="Voir ma candidature"
										className="!p-2"
										onClick={(e: MouseEvent) => {
											e.stopPropagation();
											navigate(
												`/applications/${(application?.id as string).split("/")[3]}`,
											);
										}}
									/>
								) : (
									<ButtonPrimary
										label="Enregistrer"
										onClick={(e: MouseEvent) => {
											handleClickOnSaveApplication(e);
										}}
									/>
								)}
							</div>
							{currentInterface === "student" && (
								<div className="br-corner flex justify-end sm:w-full md:w-5/12">
									{links.map(({ icon, link }, i: number) => {
										return (
											link && (
												<ButtonTertiary
													key={`link-student-${i}`}
													icon={icon}
													onClick={() => {
														window.open(
															checkUrl(link) ? link : `https://${link}`,
															"_blank",
														);
													}}
													className={`${i > 0 ? "ml-sm" : ""}`}
												/>
											)
										);
									})}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			<SaveApplicationModal
				showModal={showSaveApplicationModal}
				triggerClose={triggerCloseModal}
				triggerApproved={triggerClickOnApproved}
				triggerDontShowAgain={triggerDontShowAgain}
				companyName={name}
			/>
		</div>
	);
}
