import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";

import {
	arrowBackOutline,
	arrowForwardOutline,
	birthdayCake,
	bookOpenOutline,
	bulbOutline,
	linkedinOutline,
	pinOutline,
} from "@assets/Icons";
import { MOBILITY_OPTIONS } from "@constants/Users";
import { Avatar } from "@designSystem/atoms/Avatar";
import type { BadgeType } from "@designSystem/atoms/Badge";
import { Badge } from "@designSystem/atoms/Badge";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Divider } from "@designSystem/atoms/Divider";
import { IconButton } from "@designSystem/atoms/IconButton";
import { FilesPrimary } from "@designSystem/molecules/Files";
import type { File, Referent } from "../SelectionAPI";

import { PdfViewer } from "@designSystem/atoms/PdfViewer";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { GlobalContext } from "@navigation/Router";
import { formatLink } from "@tools/Links";
import { useNavigate } from "react-router";
import {
	StudentsSelectionContext,
	UserDetailsContext,
} from "../SelectionStudentDetails";
import { Review } from "./Review";
import { Skills } from "./Skills";
import { getBadgeByStatus, getFrContractType } from "./StudentCard";

type StudentDetailsProps = {
	referent: Referent | undefined;
};

interface FilesType extends File {
	name: string;
}

export const StudentDetails = ({ referent }: StudentDetailsProps) => {
	const { user } = useContext(GlobalContext);
	const { userDetails } = useContext(UserDetailsContext);
	const { students, setStudents } = useContext(StudentsSelectionContext);
	const [badge, setBadge] = useState<BadgeType | null>(null);
	useEffect(() => {
		setBadge(getBadgeByStatus(userDetails.selectionStatus));
	}, [userDetails.selectionStatus]);
	const [selectedFileIndex, setSelectedFileIndex] = useState<number>(-1);
	const [files, setFiles] = useState<FilesType[]>([]);
	const [position, setPosition] = useState<number>(0);
	useEffect(() => {
		setPosition(students.findIndex((student) => student.id === userDetails.id));
	}, [students, userDetails.id]);
	useEffect(() => {
		const files: FilesType[] = [];
		if (userDetails.cv?.filePath) {
			files.push({ ...userDetails.cv, name: "CV.pdf" });
		}
		if (userDetails.motivationLetter?.filePath) {
			files.push({ ...userDetails.motivationLetter, name: "LM.pdf" });
		}
		if (userDetails.portfolioPdf?.filePath) {
			files.push({ ...userDetails.portfolioPdf, name: "Portfolio.pdf" });
		}
		setFiles(files);
	}, [userDetails.cv, userDetails.motivationLetter, userDetails.portfolioPdf]);
	const goToStudent = (index: number) => {
		setSelectedFileIndex(-1);
		if (!user && userDetails.selectionStatus === 0) {
			const newStudents = [...students];
			newStudents[position] = { ...students[position], selectionStatus: 1 };
			setStudents(newStudents);
		}
		navigate(`../users/${students[index].id}`);
	};
	const { isMobile } = useBreakpoints(576);
	const navigate = useNavigate();
	return (
		<div className="flex flex-[3] gap-md md:gap-lg p-xsm md:p-md py-sm md:pt-xsm rounded-lg flex-col bg-white text-primary-700P shadow-sm self-start">
			<div className="flex justify-between">
				<IconButton
					className="disabled:opacity-50"
					icon={arrowBackOutline}
					disabled={position === 0}
					onClick={() => goToStudent(position - 1)}
				/>
				<p className="text-2xl text-primary-700P">
					{userDetails.firstname} {userDetails.lastname}
				</p>
				<IconButton
					className="disabled:opacity-50"
					icon={arrowForwardOutline}
					disabled={position === students.length - 1}
					onClick={() => goToStudent(position + 1)}
				/>
			</div>
			<div className="flex flex-col lg:flex-row flex-1 gap-sm ">
				<div className="flex flex-[2] p-sm gap-sm flex-col">
					{badge && (
						<Badge
							label={badge.label}
							bgColor={badge.bgColor}
							textColor={badge.textColor}
							borderColor={badge.borderColor}
							leftIcon={badge?.leftIcon}
							size="small"
						/>
					)}
					<div className="flex gap-xsm flex-wrap">
						<Avatar
							firstname={userDetails.firstname}
							lastname={userDetails.lastname}
							image={userDetails.avatar}
							size="md"
						/>
						<div className="flex flex-col gap-xxsm">
							<p className="text-sm font-bold text-primary-700P">
								{userDetails.firstname} {userDetails.lastname}
							</p>
							<Badge
								label={getFrContractType(userDetails.contractSearched)}
								size="small"
								bgColor="bg-alert-info-turquoise-100"
								textColor="text-alert-info-turquoise-500"
								borderColor="border-alert-info-turquoise-500"
							/>
						</div>
					</div>
					<div className="flex flex-wrap gap-xsm text-primary-300">
						{userDetails.birthdate && (
							<div className="flex items-center gap-xxsm">
								<Icon icon={birthdayCake} />
								<span className="text-center">{userDetails.birthdate}</span>
							</div>
						)}
						{userDetails.mobility && (
							<div className="flex items-center gap-xxsm">
								<Icon icon={bulbOutline} />
								<span className="text-center">
									{
										MOBILITY_OPTIONS.find(
											(mobility) => mobility.value === userDetails.mobility,
										)?.label
									}
								</span>
							</div>
						)}
						{userDetails.postalCode && userDetails.city && (
							<div className="flex items-center gap-xxsm">
								<Icon icon={pinOutline} />
								<span className="text-center">
									{userDetails.postalCode} {userDetails.city}
								</span>
							</div>
						)}
					</div>
					<div className="flex gap-xsm">
						{userDetails.linkedin && (
							<ButtonSecondary
								size="small"
								label="Linkedin"
								icon={linkedinOutline}
								onClick={() => window.open(formatLink(userDetails.linkedin))}
							/>
						)}
						{userDetails.portfolio && (
							<ButtonSecondary
								size="small"
								label="Portfolio"
								icon={bookOpenOutline}
								onClick={() => window.open(formatLink(userDetails.portfolio))}
							/>
						)}
					</div>
					{userDetails.tags.length > 0 && (
						<>
							<Divider className="h-[1px] bg-primary-100" />
							<Skills userDetails={userDetails} />
						</>
					)}
				</div>
				<Review userDetails={userDetails} referent={referent} />
			</div>
			{files.length > 0 && (
				<div className="flex gap-md flex-col">
					<Divider className="h-[1px] bg-primary-100" />
					<p className="font-bold text-sm">Documents partagés</p>
					<div className="flex flex-col lg:flex-row gap-xsm flex-1">
						{files.map((file, index) => (
							<div
								key={`StudentDetails-file-${index}`}
								className={`w-full border px-sm py-xsm shadow-md rounded-md transition-all hover:cursor-pointer hover:border-primary-200 ${
									index === selectedFileIndex ||
									(index === 0 && selectedFileIndex < 0)
										? "border-primary-200"
										: "border-transparent"
								} ${
									files.length > 2 && !isMobile
										? "lg:w-1/3"
										: files.length > 1 && !isMobile
											? "lg:w-1/2"
											: ""
								}`}
								onClick={() => setSelectedFileIndex(index)}
							>
								<FilesPrimary
									title={file.name}
									onDownload={(event: React.MouseEvent<HTMLButtonElement>) => {
										event.stopPropagation();
										window.open(
											`${import.meta.env.VITE_S3}${file.filePath}`,
											"_blank",
										);
									}}
									onView={
										!isMobile
											? (event: React.MouseEvent<HTMLButtonElement>) => {
													event.stopPropagation();
													setSelectedFileIndex(index);
												}
											: undefined
									}
									// size is in bytes, we convert it to Ko
									subtitle={`${file.size / 1000} Ko`}
									className="w-full"
								/>
							</div>
						))}
					</div>
					{!isMobile && (
						<PdfViewer
							src={`${import.meta.env.VITE_S3}${
								files[selectedFileIndex < 0 ? 0 : selectedFileIndex].filePath
							}`}
							title="CV"
							className="iframe-cv"
						/>
					)}
				</div>
			)}
		</div>
	);
};
