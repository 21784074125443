import type { CellType } from "@/types/TableTypes";
import type {
	BackgroundColorType,
	BorderColorType,
	TextColorType,
} from "@/types/colorsType";
import type { FilterType } from "@/types/filterType";
import type {
	OfferApplicationType,
	OfferCellNameType,
	OfferSelectOption,
	OfferStatusSelectionOption,
	OfferTypeSelectionOption,
	OfferVisibilityLabelType,
	OfferVisibilityType,
	OffersStatusLabelType,
	OffersStatusType,
	SchoolOfferType,
} from "@/types/offerTypes";

import {
	awardOutline,
	checkMarkCircleOutline,
	clockOutline,
	closeCircleOutline,
	editOutline,
	externalLinkOutline,
	eyeOutline,
	slashOutline,
} from "@assets/Icons";

export const QUALIFICATION_OPTIONS: OfferSelectOption[] = [
	{
		id: 1,
		label: "CAP ou équivalent",
		value: "CAP ou équivalent",
	},
	{
		id: 2,
		label: "Bac ou équivalent",
		value: "Bac ou équivalent",
	},
	{ id: 3, label: "Bac +2", value: "Bac +2" },
	{ id: 4, label: "Bac +3", value: "Bac +3" },
	{ id: 6, label: "Bac +4", value: "Bac +4" },
	{ id: 9, label: "Bac +5 ou plus", value: "Bac +5 ou plus" },
	{ id: 0, label: "Non précisé", value: "default", default: true },
];

export const OFFERS_TYPES_OPTIONS = [
	{ id: 0, label: "Offre Jobboard", value: "jobboard" },
	{ id: 1, label: "Offre Partenaire", value: "partner" },
];

export const CONTRACT_TYPE_OPTIONS: OfferSelectOption[] = [
	{ id: 1, label: "Alternance", value: "apprenticeship" },
	{ id: 2, label: "Bénévolat", value: "volunteering" },
	{ id: 3, label: "CDD", value: "temporary" },
	{ id: 4, label: "CDI", value: "permanent" },
	{ id: 5, label: "Service civique", value: "civic" },
	{ id: 6, label: "Stage", value: "intern" },
	{ id: 7, label: "Non précisé", value: "unknown", default: true },
];

export const TELEWORK_OPTIONS: OfferSelectOption[] = [
	{ id: 1, label: "Télétravail total", value: "full" },
	{ id: 2, label: "Télétravail partiel", value: "half" },
	{ id: 3, label: "Pas de télétravail", value: "none" },
	{ id: 4, label: "Non précisé", value: "unknown", default: true },
];

export const OFFER_STATUS_BADGES = new Map<
	OffersStatusType,
	{
		label: string;
		bgColor?: BackgroundColorType;
		textColor?: TextColorType | null;
		borderColor?: BorderColorType | null;
	}
>([
	[
		"online",
		{
			label: "En cours",
			bgColor: "bg-secondary-50",
			textColor: "text-secondary-700",
			borderColor: "border-secondary-700",
		},
	],
	[
		"draft",
		{
			label: "Brouillon",
			bgColor: "bg-secondary-50",
			textColor: "text-secondary-700",
			borderColor: "border-secondary-700",
		},
	],
	[
		"provided",
		{
			label: "Pourvue",
			bgColor: "bg-alert-info-turquoise-100",
			textColor: "text-alert-info-turquoise-500",
			borderColor: "border-alert-info-turquoise-500",
		},
	],
	[
		"notProvided",
		{
			label: "Non pourvue",
			bgColor: "bg-alert-info-turquoise-100",
			textColor: "text-alert-info-turquoise-500",
			borderColor: "border-alert-info-turquoise-500",
		},
	],
	[
		"expired",
		{
			label: "Expirée",
			bgColor: "bg-alert-info-turquoise-100",
			textColor: "text-alert-info-turquoise-500",
			borderColor: "border-alert-info-turquoise-500",
		},
	],
]);
export const OFFER_STATUS_OPTIONS = [
	{
		label: "En ligne",
		value: "online",
		icon: clockOutline,
		color: "#7FC4BC",
	},
	{
		label: "Brouillon",
		value: "draft",
		icon: editOutline,
		color: "#F4A261",
	},
	{
		label: "Pourvue",
		value: "provided",
		icon: awardOutline,
		color: "#7EC264",
	},
	{
		label: "Terminée",
		value: "finished",
		icon: closeCircleOutline,
		color: "#E76F51",
	},
];

export const SCHOOL_JOBBOARD_NAMES = ["partner", "jobboard"];

export const JOBBOARD_SELECT_OPTIONS: FilterType[] = [
	{ label: "Welcome to the Jungle", value: "Welcome To The Jungle" },
	{ label: "Jobteaser", value: "Jobteaser" },
	{ label: "Indeed", value: "Indeed" },
	{ label: "Linkedin", value: "Linkedin" },
	{ label: "Pôle Emploi", value: "Pôle Emploi" },
	{ label: "Hellowork", value: "Hellowork" },
	{ label: "Monster", value: "Monster" },
	{ label: "Apec", value: "Apec" },
	{ label: "Le Sport Recrute", value: "Le Sport Recrute" },
	{ label: "Sport Jobs Hunter", value: "SportJobsHunter" },
	{ label: "My Jobs Sports", value: "MyJobSports" },
];

export const JOBBOARD_COMPLETE_LIST = {
	classic: [
		{ label: "Indeed", link: "https://fr.indeed.com/" },
		{ label: "Monster", link: "https://www.monster.fr/" },
		{
			label: "Pôle Emploi",
			link: "https://candidat.pole-emploi.fr/offres/emploi",
		},
		{
			label: "LinkedIn",
			link: "https://www.linkedin.com/jobs/?originalSubdomain=fr",
		},
		{
			label: "Jobteaser",
			link: "https://www.jobteaser.com/fr/job-offers",
		},
		{
			label: "Le Figaro Emploi",
			link: "https://emploi.lefigaro.fr/recherche/offres-emploi",
		},
		{
			label: "B Bigger",
			link: "https://www.bbigger.fr/",
		},
	],
	handicap: [
		{
			label: "La bonne alternance",
			link: "https://labonnealternance.pole-emploi.fr/",
		},
		{ label: "Hanploi", link: "https://www.hanploi.com/" },
		{ label: "Handi Alternance", link: "https://www.handi-alternance.fr/" },
		{ label: "Mission Handicap", link: "https://missionhandicap.com/" },
		{ label: "Handicap Job", link: "https://www.handicap-job.com/" },
		{ label: "Handi Questa", link: "https://www.handiquesta.com/index.php" },
	],
	sport: [
		{
			label: "Sport Stratégies Emploi",
			link: "https://www.sportstrategies.com/offre-emploi/",
		},
		{ label: "Global Sports Jobs", link: "https://www.globalsportsjobs.com/" },
		{ label: "Sport Carrière", link: "https://www.sportcarriere.com/" },
		{ label: "Bloch Consulting", link: "https://www.bloch-consulting.fr/" },
		{ label: "Boost", link: "https://www.boost-partners.io/job-board" },
		{ label: "My Job Sports", link: "https://www.myjobsports.com/" },
		{
			label: "LinkedIn Sport",
			link: "https://www.linkedin.com/jobs/sports-emplois/?originalSubdomain=fr",
		},
		{ label: "Indeed filtre Sport", link: "https://fr.indeed.com/" },
	],
};

export const DISTANCE_OPTIONS = [
	{
		id: 0,
		label: "1 Km",
		value: 1,
	},
	{
		id: 0,
		label: "3 Km",
		value: 3,
	},
	{
		id: 0,
		label: "5 Km",
		value: 5,
	},
	{
		id: 1,
		label: "10 Km",
		value: 10,
	},
	{
		id: 2,
		label: "15 Km",
		value: 15,
	},
	{
		id: 3,
		label: "30 Km",
		value: 30,
	},
	{
		id: 4,
		label: "50 Km",
		value: 50,
	},
	{
		id: 5,
		label: "100 Km",
		value: 100,
	},
	{
		id: 6,
		label: "> 100 Km",
		value: 500,
	},
];
export const offersTabsLabels = new Map<string, string>([
	["online", "En cours"],
	["provided", "Pourvues"],
	["notProvided", "Non pourvues"],
	["draft", "Brouillons"],
	["expired", "Expirées"],
	["draft", "Brouillons"],
]);

export const offersStatusLabel = new Map<
	OffersStatusType,
	OffersStatusLabelType
>([
	["online", { label: "Offre en cours", icon: clockOutline }],
	["draft", { label: "Offre en préparation (brouillon)", icon: editOutline }],
	[
		"provided",
		{ label: "Offre terminée (pourvue)", icon: checkMarkCircleOutline },
	],
	[
		"notProvided",
		{ label: "Offre terminée (non pourvue)", icon: closeCircleOutline },
	],
	["expired", { label: "En cours", icon: closeCircleOutline }],
]);

export const offerTypeLabel = new Map<OfferApplicationType, string>([
	["direct", "Direct étudiant"],
	["preselection", "Présélection"],
	["shared", "Envoi de CV"],
]);

export const OFFER_TYPE_LABEL_FOR_DETAILS = new Map<
	OfferApplicationType,
	{ label: string; subLabel: string }
>([
	["direct", { label: "Direct étudiant", subLabel: "Par l'étudiant" }],
	["preselection", { label: "Présélection", subLabel: "Par l'école" }],
	["shared", { label: "Envoi de CV", subLabel: "via un lien unique" }],
]);

export const STATUS_ALLOWED_FOR_TAB = new Map<string, OffersStatusType[]>([
	["online", ["online"]],
	["provided", ["provided"]],
	["notProvided", ["notProvided"]],
	["expired", ["expired"]],
	["draft", ["draft"]],
]);

export const offerVisibilityLabel = new Map<
	OfferVisibilityType,
	OfferVisibilityLabelType
>([
	[
		"visible",
		{
			label: "Visible",
			shortLabel: "Visible",
			bgColor: "bg-success-light",
		},
	],
	[
		"restricted",
		{
			label: "Restreinte campus",
			shortLabel: "Restreinte campus",
			bgColor: "bg-info-light",
		},
	],
	[
		"private",
		{
			label: "Privée",
			shortLabel: "Privée",
			bgColor: "bg-warning-light",
		},
	],
	[
		"invisible",
		{
			label: "Non visible",
			shortLabel: "Non visible",
			bgColor: "bg-stone-light",
		},
	],
]);

type VisibilityAffixType = {
	private: string;
	restricted: string;
	visible: string;
};

export const visibilityAffix: VisibilityAffixType = {
	private: "étudiants invités",
	restricted: "campus",
	visible: "par tous les étudiants de votre école",
};

type OfferContractType = string;

type OfferContractColorType = {
	bgColor: string;
	textColor: string;
	borderColor: string;
};

export const contractColor = new Map<OfferContractType, OfferContractColorType>(
	[
		[
			"Apprentissage",
			{
				bgColor: "bg-accent-4-lighter",
				textColor: "text-accent-4-dark",
				borderColor: "border-accent-4-dark",
			},
		],
		[
			"CDI",
			{
				bgColor: "bg-accent-1-lighter",
				textColor: "text-accent-1-dark",
				borderColor: "border-accent-1-dark",
			},
		],
		[
			"CDD",
			{
				bgColor: "bg-primary-100",
				textColor: "text-primary-500",
				borderColor: "border-primary-500",
			},
		],
		[
			"Stage",
			{
				bgColor: "bg-secondary-100",
				textColor: "text-secondary-500P",
				borderColor: "border-secondary-500P",
			},
		],
	],
);

export const CELL_DISALLOWED_RULES = new Map<
	OfferCellNameType,
	OffersStatusType[]
>([
	["conContract_spsStudent_id", ["online", "notProvided", "draft", "expired"]],
	["closeOffer", ["provided", "notProvided", "draft"]],
	["closeReason", ["online", "provided", "draft", "expired"]],
	["closeAt", ["online", "provided", "draft", "expired"]],
	["providedAt", ["online", "notProvided", "draft", "expired"]],
	["shared", ["provided", "notProvided", "draft", "expired"]],
	["visibility", ["notProvided", "provided", "expired"]],
	["applicationsToSend", ["notProvided", "draft", "expired"]],
	["applicationsToTreat", ["provided", "notProvided", "draft", "expired"]],
]);

export const offersCellMapping = new Map<OfferCellNameType, CellType>([
	["externalId", { cellName: "externalId", cellLabel: "#", cellIndex: 0 }],
	[
		"crmCompany_name",
		{ cellName: "crmCompany_name", cellLabel: "Entreprise", cellIndex: 1 },
	],
	["job", { cellName: "job", cellLabel: "Nom de l'offre", cellIndex: 2 }],
	[
		"closeReason",
		{ cellName: "closeReason", cellLabel: "Motif", cellIndex: 3 },
	],
	[
		"conContract_spsStudent_id",
		{
			cellName: "conContract_spsStudent_id",
			cellLabel: "Étudiant",
			cellIndex: 4,
		},
	],
	["contract", { cellName: "contract", cellLabel: "Contrat", cellIndex: 5 }],
	[
		"responsable_firstname",
		{
			cellName: "responsable_firstname",
			cellLabel: "Responsable",
			cellIndex: 6,
		},
	],
	[
		"closeAt",
		{ cellName: "closeAt", cellLabel: "Date de clôture", cellIndex: 7 },
	],
	[
		"providedAt",
		{ cellName: "providedAt", cellLabel: "Pourvue le", cellIndex: 8 },
	],
	[
		"location",
		{ cellName: "location", cellLabel: "Localisation", cellIndex: 9 },
	],
	["type", { cellName: "type", cellLabel: "Type", cellIndex: 10 }],
	["tags", { cellName: "tags", cellLabel: "Tags", cellIndex: 11 }],
	[
		"visibility",
		{ cellName: "visibility", cellLabel: "Visibilité", cellIndex: 12 },
	],
	[
		"sharesCount",
		{ cellName: "sharesCount", cellLabel: "Invités", cellIndex: 13 },
	],
	["views", { cellName: "views", cellLabel: "Vues", cellIndex: 14 }],
	[
		"applicationsCount",
		{ cellName: "applicationsCount", cellLabel: "Candidats", cellIndex: 15 },
	],
	[
		"sentToCompanyCount",
		{
			cellName: "sentToCompanyCount",
			cellLabel: "Profils envoyés",
			cellIndex: 16,
		},
	],
	[
		"applicationsToTreat",
		{ cellName: "applicationsToTreat", cellLabel: "À traiter", cellIndex: 17 },
	],
	[
		"applicationsToSend",
		{ cellName: "applicationsToSend", cellLabel: "À envoyer", cellIndex: 18 },
	],

	["shared", { cellName: "shared", cellLabel: "Partager", cellIndex: 19 }],
	[
		"updateAt",
		{ cellName: "updateAt", cellLabel: "Actualisation", cellIndex: 20 },
	],
	["date", { cellName: "date", cellLabel: "Créée", cellIndex: 21 }],
	["expiredAt", { cellName: "expiredAt", cellLabel: "Expire", cellIndex: 22 }],
	[
		"closeOffer",
		{ cellName: "closeOffer", cellLabel: "Clôturer", cellIndex: 23 },
	],
]);

export const DEFAULT_OFFERS_TABLE_CONFIG: OfferCellNameType[] = [
	"crmCompany_name",
	"job",
	"closeReason",
	"conContract_spsStudent_id",
	"contract",
	"closeAt",
	"responsable_firstname",
	"applicationsToTreat",
	"visibility",
	"tags",
	"type",
	"views",
	"applicationsCount",
	"shared",
	"closeOffer",
];

export const DEFAULT_OFFER_TO_CREATE: Partial<SchoolOfferType> = {
	type: "preselection",
	contract: "unknown",
};
export const OFFER_TYPE_SELECTION_OPTIONS: OfferTypeSelectionOption[] = [
	{
		type: "preselection",
		label: "Présélection des candidatures par l’école",
		shortLabel: "Présélection",
		icon: eyeOutline,
	},
	{
		type: "direct",
		label: "Candidature directe par l’étudiant",
		shortLabel: "Direct étudiant",
		icon: externalLinkOutline,
	},
];

export const OFFER_TYPE_DESCRIPTION_MESSAGE = new Map<
	OfferApplicationType,
	string
>([
	[
		"direct",
		"Vous allez créer une offre d'emploi pour vos étudiants. Les étudiants candidatent directement depuis le site partenaire ou l’adresse mail de la candidature. Vous pourrez consulter dans le détail de l’offre les étudiants ayant candidaté.",
	],
	[
		"preselection",
		"Vous allez créer une offre d'emploi pour vos étudiants. Les étudiants intéressés vous adressent leurs candidatures. Vous pouvez les accepter, les refuser ou demander des informations complémentaires à l'étudiant. Vous devez ensuite envoyer les candidatures aux entreprises partenaires.",
	],
]);

export const OFFER_CREATION_HEADER_TOOLTIP = new Map<
	OfferApplicationType,
	string
>([
	[
		"direct",
		"Les étudiants candidatent directement depuis le site ou l'adresse mail de la candidature. ",
	],
	[
		"preselection",
		"Les étudiants intéressés vous adressent leurs candidatures. Vous pouvez les accepter, les refuser ou demander des informations complémentaires à l'étudiant. Vous devez ensuite envoyer les candidatures aux entreprises partenaires.",
	],
]);
export const OFFER_DETAILS_TYPE_TOOLTIP = new Map<OfferApplicationType, string>(
	[
		[
			"direct",
			"Les étudiants intéressés vous adressent leurs candidatures. Vous pouvez les accepter, les refuser ou demander des informations complémentaires à l'étudiant. Vous devez ensuite envoyer les candidatures aux entreprises partenaires. ",
		],
		[
			"preselection",
			"Les étudiants candidatent directement depuis le site partenaire ou l'adresse mail de la candidature. Vous pouvez consulter ci-dessous les étudiants qui ont candidaté.",
		],
	],
);
export const OFFER_CREATION_HEADER_DESCRIPTION = new Map<
	OfferApplicationType,
	string
>([
	[
		"direct",
		"Vous êtes sur l'interface de gestion d'une offre avec candidature directe par les étudiants. Cela signifie que les étudiants candidatent directement depuis le site ou l'adresse mail de la candidature. ",
	],
	[
		"preselection",
		"Vous êtes sur l'interface de gestion d'une offre avec présélection des candidatures par l’école. Cela signifie que les étudiants intéressés vous adressent leurs candidatures. Vous pouvez les accepter, les refuser ou demander des informations complémentaires à l'étudiant. Vous devez ensuite envoyer les candidatures aux entreprises partenaires.",
	],
]);

export const OFFER_SELECT_STATUS_OPTIONS: OfferStatusSelectionOption[] = [
	{
		checked: false,
		label: "Offre en cours",
		type: "online",
		icon: clockOutline,
		color: "#2A4054",
	},
	{
		checked: false,
		label: "Offre en préparation (brouillon)",
		type: "draft",
		icon: editOutline,
		color: "#2A4054",
	},
	{
		checked: false,
		label: "Offre terminée (pourvue)",
		type: "provided",
		icon: awardOutline,
		color: "#2A4054",
	},
	{
		checked: false,
		label: "Offre terminée (non pourvue)",
		type: "notProvided",
		icon: closeCircleOutline,
		color: "#2A4054",
	},
	{
		checked: false,
		label: "Offre expirée",
		type: "expired",
		icon: slashOutline,
		color: "#2A4054",
	},
];

export const OFFER_CLOSE_REASON_OPTIONS = [
	{
		label: "Concurrence du marché",
		closeReason: "La concurrence du marché est rude",
		value: "concurrence",
	},
	{
		label: "Offre annulée",
		closeReason: "L’offre a été annulée",
		value: "cancelled",
	},
	{
		label: "Localisation",
		closeReason: "La localisation ne convient pas",
		value: "location",
	},
	{
		label: "Autre",
		closeReason: "Insérer la raison ici",
		value: "other",
	},
];

export const visibilityAffixForMonocampus: VisibilityAffixType = {
	private: "étudiants invités",
	restricted: "",
	visible: "par tous vos étudiants",
};
