import type { CampusType } from "@/types/campusTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { NoteType } from "@/types/notesType";
import type { promotionType } from "@/types/promotionTypes";
import type { TagType } from "@/types/tagTypes";
import type { EntCompanyType, EntEmployeeStatusType } from "./EntCompanyTypes";

export type ConnexionInterfaceType =
	| "student"
	| "school"
	| "ats"
	| "company"
	| null;

export type UserRoleType =
	| "ROLE_MG"
	| "ROLE_CAMPUS_ADMIN"
	| "ROLE_SCHOOL"
	| "ROLE_CAMPUS_USER"
	| "ROLE_ATS";

export type OnboardingType = {
	jobboard: boolean;
	application: boolean;
	resources: boolean;
	dashboard: boolean;
};

export enum EStatus {
	TOPLACE = 0,
	PLACED = 1,
	SURREND = 2,
	BEING_SIGNED = 3,
	NOT_SEARCHING = 4,
}
export enum EState {
	DONE = 1,
	ARCHIVED = 2,
	WAIT_CONNECTION = 3,
	WAIT_APPROVAL = 4,
}

export enum EStatusState {
	ACCEPTED = 0,
	REFUSED = 1,
	REQUESTED = 2,
}

export type StudentStatusType = Exclude<EStatus, EStatus.SURREND> | null;

export type FormationType = {
	id?: string;
	name: string;
	startDate: string;
	endDate: string;
	detail: string;
	schoolName: string;
	city: string;
};

export type ProfessionalExperienceType = {
	id?: string;
	job: string;
	startDate: string;
	endDate: string;
	detail: string;
	organizationV2: {
		name: string;
		logo: string;
	};
	contract: string;
};

export type ProjectType = {
	id?: string;
	name: string;
	detail: string;
	url: string;
};

export type ProjectCollectionType = {
	collection: ProjectType[];
};

export type FormationCollectionType = {
	collection: FormationType[];
};

export type ProfessionalExperienceCollectionType = {
	collection: ProfessionalExperienceType[];
};

export type UserType = {
	isClient: boolean;
	id: string;
	firstname: string;
	roles: string[];
	newLogsCount: number;
	campusName: string;
	choosedLanguage: string;
	schoolName: string;
	promotionName: string;
	coreCampus: string;
	coreUser: string;
	coreCampuses: CampusType[];
	lastname: string;
	email: string;
	birthdate: string;
	linkedin: string;
	address: string;
	avatar: MediaObjectType;
	dateOfInvitation: string;
	schoolLogo: string;
	school: string;
	entCompany?: EntCompanyType;
	entEmployeeStatus?: EntEmployeeStatusType;
	status: EStatus;
	studentStatus: StudentStatusType;
	phone: string;
	cv?: MediaObjectType;
	engNotes: { collection: NoteType[] };
	hasSeenNews?: boolean;
	portfolioPdf?: MediaObjectType;
	schoolDocuments?: Partial<MediaObjectType>[];
	motivationLetter?: MediaObjectType;
	onboardingSteps?: string;
	spsPromotion: promotionType;
	schoolSubdomain?: string;
	mobility: string;
	postalCode: string;
	city: string;
	country: string;
	portfolio: string;
	hardSkills: TagType[];
	softSkills: TagType[];
	streetNumber: number;
	streetName: string;
	longitude: number;
	latitude: number;
	desiredJob: string;
	desiredStartOfWork: string;
	geoAreaOfResearch: string;
	formations: FormationCollectionType;
	professionalExperiences: ProfessionalExperienceCollectionType;
	projects: ProjectCollectionType;
	lastWeekApplicationsCount: number;
	lastWeekContactsCount: number;
	lastWeekInterviewsCount: number;
	lastWeekRelaunchsCount: number;
	thisWeekApplicationsCount: number;
	thisWeekContactsCount: number;
	thisWeekInterviewsCount: number;
	thisWeekRelaunchsCount: number;
	contactsCount: number;
	applicationsCount: number;
	relaunchsCount: number;
	interviewsCount: number;
	contractSearched: number;
	mobilityRadius: number;
	campuses: CampusType[];
	profilesCount: number;
	companySchoolsCount: number;
};

export type UserCompleteType = UserType & {
	campus: {
		isGalliaEnabled: boolean;
	};
	desiredJob: string;
	desiredStartOfWork: string;
	geoAreaOfResearch: string;
	[key: string]: any; // Used for collections
};

export type ReferentType = Partial<UserType>;

export type FormatViewType =
	| "default"
	| "spsPromotion"
	| "spsStudent_spsPromotion";
