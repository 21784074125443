import React, { useEffect, useState } from "react";

import {
	bookOpenOutline,
	briefcaseOutline,
	peopleOutline,
	refreshOutline,
} from "@assets/Icons";

import { StatsSecondary } from "@designSystem/molecules/StatsSecondary";

export function StudentsActionsEssentialsInNumbers({ structure }) {
	const [activities, setActivities] = useState([]);

	const defineActivitesData = () => {
		setActivities([
			{
				label: "Candidatures",
				value: structure.applications,
				icon: peopleOutline,
				color: "text-primary-500",
				bgIcon: "bg-primary-100",
				tippyContent:
					"Nombre de candidatures effectuées par vos étudiants sur la plateforme",
			},
			{
				label: "Contacts",
				value: structure.contactsNbr,
				icon: bookOpenOutline,
				color: "text-secondary-700",
				bgIcon: "bg-secondary-50",
				tippyContent: "Nombre de contacts ajoutés par vos étudiants",
			},
			{
				label: "Relances",
				value: structure.relaunchs,
				icon: refreshOutline,
				color: "text-accent-2-dark",
				bgIcon: "bg-accent-2-lighter",
				tippyContent:
					"Nombre de candidatures qui ont fait l’objet d’une relance par l’étudiant",
			},
			{
				label: "Entretiens",
				value: structure.interviews,
				icon: briefcaseOutline,
				color: "text-accent-1-dark",
				bgIcon: "bg-accent-1-lighter",
				tippyContent:
					"Nombre de candidatures qui ont débouché sur un entretien",
			},
		]);
	};

	useEffect(() => {
		if (structure) {
			defineActivitesData();
		}
	}, [structure]);

	return (
		<div className="mt-xlg">
			<p className="font-semifont-bold pb-sm text-xsm text-primary-700P">
				L&apos;essentiel des actions des étudiants en chiffres
			</p>
			<div className="-m-sm flex overflow-x-scroll p-sm scrollbar-hide">
				{activities?.map((item, i) => {
					return (
						<StatsSecondary
							key={i}
							label={item.label}
							value={item.value.toString()}
							icon={item.icon}
							iconColor={item.color}
							color={item.color}
							bgIcon={item.bgIcon}
							tippyContent={item.tippyContent}
						/>
					);
				})}
			</div>
		</div>
	);
}
