import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";

import { searchOutline } from "@assets/Icons";

import { Input } from "@designSystem/molecules/Input";

export function Search({
	filters,
	updateResourcesList,
	load,
}: {
	filters: any;
	updateResourcesList: Function;
	load: boolean;
}) {
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search, 1000);
	useEffect(() => {
		if (!load) {
			updateResourcesList({
				name: "title",
				value: debouncedValue !== "" ? debouncedValue.replace(" ", "%") : null,
			});
		}
	}, [debouncedValue]);

	return (
		<div className="flex flex-col items-center rounded-lg bg-white py-lg w-full">
			<p className="text-sm font-bold text-primary-700P">
				Trouve la ressource qu'il te faut !
			</p>
			<Input
				className="input-head-ressources mt-md w-8/12"
				icon={searchOutline}
				placeholder="Rechercher..."
				type="text"
				value={filters.search ? filters.search : ""}
				onChange={(e) => {
					e.target.value = e.target.value.replace(";", "");
					setSearch(e.target.value);
				}}
			/>
		</div>
	);
}
