import type { CampusType } from "@/types/campusTypes";
import { getCampusesRequest } from "../api/campus.request";
import type { ICampusGateway } from "../gateway/campus.gateway";

interface CampusResponse {
	data: {
		campuses: {
			collection: CampusType[];
		};
	};
}

function isCampusResponse(response: any): response is CampusResponse {
	return (
		response &&
		typeof response === "object" &&
		"data" in response &&
		typeof response.data === "object" &&
		"campuses" in response.data &&
		typeof response.data.campuses === "object" &&
		"collection" in response.data.campuses
	);
}
export class InAPICampusGateway implements ICampusGateway {
	async getCampuses(schoolId: string) {
		const requestResponse = await getCampusesRequest(schoolId);

		if (!isCampusResponse(requestResponse)) {
			throw new Error("Invalid response structure");
		}

		return requestResponse.data.campuses.collection;
	}
}
