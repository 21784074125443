import { setCommentSelectionUser } from "@containers/public/Selection/SelectionAPI";
import type {
	IMemoGateway,
	RetrieveMemoType,
} from "@designSystem/molecules/memo/core/memo.gateway";

export class SelectionMemoGateway implements IMemoGateway {
	async saveThisMemo(
		id: string,
		value: string,
		initialValue: string,
	): Promise<boolean> {
		if (initialValue === value) return false;
		const [token, userId] = id.split("#");
		const response = await setCommentSelectionUser(
			token,
			Number.parseInt(userId),
			value,
		);
		return response.code !== 200;
	}

	async retrieveMemo(
		_id: string,
		defaultValue: string,
	): Promise<RetrieveMemoType> {
		return { body: defaultValue, initialValue: "" };
	}
}
