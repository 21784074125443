import type { ProfessionalExperienceType } from "@/types/userTypes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import { trash2Outline } from "@assets/Icons";

import { CONTRACT_TYPE_OPTIONS } from "@constants/Offers";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { Combobox } from "@designSystem/molecules/Combobox";
import { Input } from "@designSystem/molecules/Input";
import { DatePicker } from "@designSystem/organisms/DatePicker";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";

import { autoComplet } from "@containers/student/StudentDashboard/DashboardAPI";

import type { CrmCompanyDomainModel } from "@/types/crmCompany.domainModel";
import DeleteModal from "./DeleteModal";

type ExperienceEditorModalType = {
	show: boolean;
	onClose: Function;
	editCareer: Function;
	experience?: ProfessionalExperienceType;
};

type CompanyOptionType = {
	label: string;
	logo: string;
};

export function ExperienceEditorModal({
	show,
	onClose,
	editCareer,
	experience,
}: ExperienceEditorModalType) {
	const [companyList, setCompanyList] = useState<CompanyOptionType[]>([]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [keyForReload, setKeyForReload] = useState<number>(0);
	const [companyToSearch, setCompanyToSearch] = useState<string>("");
	const [logo, setLogo] = useState<string>(
		experience?.organizationV2?.logo || "",
	);

	const autocompletAPI = async (company: string) => {
		const companyList: CompanyOptionType[] = [];
		if (!company) {
			return;
		}

		const data: CrmCompanyDomainModel.Company[] = await autoComplet(company);

		if (data.length > 0) {
			data?.map((company) => {
				if (company) {
					return companyList.push({
						label: company?.name,
						logo: company.logo,
					});
				}

				return "";
			});
		}
		setCompanyList(companyList);
	};

	useEffect(() => {
		setKeyForReload(keyForReload + 1);
	}, [experience]);

	return (
		<div>
			<Formik
				initialValues={{
					id: experience?.id || null,
					job: experience?.job || "",
					organizationV2: experience?.organizationV2 || null,
					contract: experience?.contract || "apprenticeship",
					detail: experience?.detail || "",
					startDate: experience?.startDate
						? new Date(experience?.startDate)
						: undefined,
					endDate: experience?.endDate
						? new Date(experience?.endDate)
						: undefined,
				}}
				validate={(values) => {
					const errors: any = {};
					if (!values.organizationV2) {
						errors.organizationV2 = "Veuillez entrer un nom d'entreprise";
					}
					if (!values.job) errors.job = "Veuillez entrer un nom de poste ";
					if (!values.startDate) {
						errors.startDate = "Veuillez entrer une date de début";
					}

					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					autocompletAPI("");
					editCareer(
						"professionalExperience",
						experience === null ? "create" : "update",
						values,
					);
					resetForm({});
					onClose();
				}}
				key={keyForReload}
			>
				{({
					values,
					errors,
					setFieldValue,
					handleChange,
					handleSubmit,
					resetForm,
				}) => (
					<Modal
						show={show}
						onClose={() => {
							onClose();
							resetForm({});
						}}
						title={
							experience ? "Modifier une expérience" : "Ajouter une expérience"
						}
						body={
							<div className="flex flex-col gap-sm">
								<div className="flex flex-col gap-xsm items-center">
									<Avatar
										image={logo}
										firstname={values.organizationV2?.name || ""}
										lastname={values.organizationV2?.name.slice(0, 1)[1] || ""}
										onChange={() => {}}
										size="lg"
									/>
									<Combobox
										value={values.organizationV2?.name}
										onChange={(e) => {
											setCompanyToSearch(e.target.value);
											autocompletAPI(e.target.value);
										}}
										options={companyList}
										placeholder="Nom de l'entreprise"
										onClickOnItem={(elem) => {
											setCompanyToSearch(elem.label);
											setLogo(elem.logo);
											setFieldValue("organizationV2", {
												name: elem.label,
												logo: elem.logo,
											});
										}}
										onBlur={() => {
											setFieldValue("organizationV2", {
												name: companyToSearch,
												logo: logo,
											});
										}}
										errorMessage={errors.organizationV2 as string}
									/>
								</div>
								<div className="flex flex-wrap gap-sm md:flex-nowrap ">
									<Input
										label="Intitulé du poste occupé"
										placeholder="Ex : Développeur Web"
										className="w-full md:w-6/12"
										name="job"
										value={values.job}
										onChange={handleChange}
										errorMessage={errors.job as string}
									/>
									<SelectTertiary
										position="right"
										label="Type de contrat (optionnel)"
										className="wg-no-translate w-full md:w-6/12"
										options={CONTRACT_TYPE_OPTIONS}
										defaultValue={CONTRACT_TYPE_OPTIONS.find(
											(contract) => contract.value === values.contract,
										)}
										onChange={(e) => {
											setFieldValue("contract", e.value);
										}}
									/>
								</div>

								<DatePicker
									onDateChange={(date) => {
										setFieldValue("startDate", date);
									}}
									selectedDate={values.startDate}
									label="Début du contrat"
									errorMessage={errors.startDate as string}
								/>

								<DatePicker
									onDateChange={(date) => {
										setFieldValue("endDate", date);
									}}
									selectedDate={values.endDate}
									label="Fin du contrat (optionnel)"
								/>
								<TextArea
									label="Détails (optionnel)"
									placeholder="Tu peux indiquer tes missions, ton rôle, les compétences acquises, ..."
									height="150px"
									value={values.detail}
									onChange={(e) => {
										setFieldValue("detail", e);
									}}
								/>
							</div>
						}
						buttonsLeft={[
							experience && (
								<ButtonTertiary
									icon={trash2Outline}
									onClick={() => {
										onClose();
										setShowDeleteModal(true);
									}}
								/>
							),
						]}
						buttonsRight={[
							<ButtonSecondary
								label="Annuler"
								key="cancelBTN"
								onClick={() => {
									resetForm({});
									onClose();
								}}
							/>,
							<ButtonPrimary
								label={experience ? "Modifier" : "Ajouter"}
								key="addBTN"
								type="submit"
								onClick={() => {
									handleSubmit();
								}}
							/>,
						]}
					/>
				)}
			</Formik>
			<DeleteModal
				label="Es-tu sûr de vouloir supprimer cette experience ?"
				show={showDeleteModal}
				type="professionalExperience"
				entityId={experience?.id}
				editCareer={editCareer}
				onClose={() => {
					setShowDeleteModal(false);
				}}
			/>
		</div>
	);
}
