import type { ApplicationType } from "@/types/applicationTypes";
import React from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";

type ContactType = {
	firstname: string;
	lastname: string;
	spsStudent: { firstname: string; lastname: string };
	job: string;
	applications: {
		collection: ApplicationType & { organization: { name: string } }[];
	};
};

type ContactPropsType = {
	currentContact: ContactType;
	setCurrentContact: Function;
};

type DisplayPropsType = {
	displayContactDetailModal: boolean;
	setDisplayContactDetailModal: Function;
};

type ContactDetailModalType = {
	contactProps: ContactPropsType;
	displayProps: DisplayPropsType;
};
export function ContactDetailModal({
	contactProps,
	displayProps,
}: ContactDetailModalType) {
	const { currentContact, setCurrentContact } = contactProps;
	const { displayContactDetailModal, setDisplayContactDetailModal } =
		displayProps;

	const rowInfo = (label: string, value: string) => (
		<div className="flex">
			<p className="w-1/3 font-semibold">{label} :</p>
			<p className="text-primary-500">{value}</p>
		</div>
	);

	return (
		<Modal
			title="Détails du contact"
			show={displayContactDetailModal}
			body={
				<div>
					{rowInfo(
						"Nom du contact",
						`${currentContact.firstname} ${currentContact.lastname}`,
					)}
					{rowInfo(
						"Entreprise(s) liée(s)",
						currentContact.applications?.collection[0]
							? currentContact.applications?.collection[0].organization.name
							: "Non renseignée",
					)}
					{rowInfo(
						"Étudiant lié",
						`${
							currentContact?.spsStudent?.firstname
								? currentContact.spsStudent.firstname
								: ""
						} ${
							currentContact.spsStudent?.lastname
								? currentContact.spsStudent?.lastname
								: ""
						}`,
					)}
					{rowInfo(
						"Candidature(s) liée(s)",
						currentContact.job ? currentContact.job : "Non renseignée",
					)}
				</div>
			}
			onClose={() => {
				setDisplayContactDetailModal(!displayContactDetailModal);
				setCurrentContact({});
			}}
			buttonsRight={[
				<ButtonPrimary
					key={0}
					label="Fermer"
					onClick={() => {
						setDisplayContactDetailModal(!displayContactDetailModal);
						setCurrentContact({});
					}}
				/>,
			]}
		/>
	);
}
