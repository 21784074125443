import { Formik } from "formik";
import React, { useState } from "react";

import { trash2Outline } from "@assets/Icons";

import { COLORS_TAG_OPTIONS, TAG_TYPES_OPTIONS } from "@constants/Tags";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Tag } from "@designSystem/atoms/Tag";
import { TextArea } from "@designSystem/atoms/TextArea";
import { ColorChoice } from "@designSystem/molecules/ColorChoice";
import { Input } from "@designSystem/molecules/Input";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";
import { errorToast, success } from "@tools/Toasts";

import { editTag } from "@containers/school/TagsManagement/TagsAPI";

import { DeleteTagModal } from "./DeleteTagModal";

type ModifyTagModalType = {
	show: boolean;
	onClose: Function;
	tag: any;
	filtersProps: { filters: any; setFilters: Function };
	getData: Function;
};

type FormValues = {
	type: string;
	name: string;
	color: string;
	description: string;
};
export function ModifyTagModal({
	show,
	onClose,
	tag,
	filtersProps,
	getData,
}: ModifyTagModalType) {
	const [displayDeleteTag, setDisplayDeleteTag] = useState(false);
	const { filters, setFilters } = filtersProps;

	const modifyTag = async (values: FormValues) => {
		const editResponse = await editTag({ id: tag.id, ...values });
		if (editResponse.responseLabel === "success") {
			success("Tag mis à jour avec succès.");
			setFilters({ ...filters });
		} else {
			errorToast(editResponse.message);
		}
		getData();
		onClose();
	};

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={{
					type: tag?.type,
					name: tag?.name,
					color: tag?.color,
					description: tag?.description,
				}}
				validate={(values) => {
					const errors: { name: string } = { name: "" };
					if (!values.name) {
						errors.name = "Veuillez remplir un titre";
					}
					if (values.name.length > 60) {
						errors.name = "Veuillez avoir un titre inférieur à 60 caractères";
					}
					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					modifyTag(values).then(() => {
						resetForm({});
					});
				}}
			>
				{({ values, errors, resetForm, setFieldValue }) => (
					<Modal
						title="Modifier le tag"
						show={show}
						onClose={() => {
							onClose();
							resetForm();
						}}
						body={
							<div>
								<SelectTertiary
									label="Type de tag"
									placeholder="Choisir..."
									options={TAG_TYPES_OPTIONS}
									className="mb-md"
									position="right"
									defaultValue={{ label: values.name, value: values.type }}
									onChange={(e) => {
										setFieldValue("type", e.value);
									}}
								/>
								<Input
									label="Nom du tag"
									placeholder="Ex: 1. Info : Zone géographique limitée"
									className="mb-md"
									value={values.name}
									errorMessage={errors.name as string}
									onChange={(e) => {
										setFieldValue("name", e.target.value);
									}}
								/>
								<p className="mb-xsm text-xsm text-primary-700P">Couleur</p>
								<p className="mb-xsm text-xsm text-primary-300">
									Vous pouvez attribuer une couleur à ce tag :
								</p>
								<ColorChoice
									defaultColor={values.color}
									optionsColor={COLORS_TAG_OPTIONS}
									onChange={(e) => {
										setFieldValue("color", e.value);
									}}
								/>
								<p className="font-semifont-bold mb-sm text-xsm text-primary-700P">
									Aperçu
								</p>
								<Tag label={values.name} bgColor={values.color} />
								<TextArea
									value={values.description}
									className="mt-md"
									label="Description du tag (optionnel)"
									placeholder="Rédigez votre description ici..."
									onBlur={(e) => {
										setFieldValue("description", e.target.value);
									}}
								/>
							</div>
						}
						buttonsLeft={[
							<ButtonQuaternary
								key={0}
								icon={trash2Outline}
								onClick={() => {
									setDisplayDeleteTag(!displayDeleteTag);
									onClose();
								}}
							/>,
						]}
						buttonsRight={[
							<ButtonSecondary
								key={0}
								label="Annuler"
								onClick={() => {
									resetForm();
									onClose();
								}}
							/>,
							<ButtonPrimary
								key={1}
								label="Modifier"
								onClick={() => {
									modifyTag(values).then(() => {
										resetForm({});
									});
								}}
							/>,
						]}
					/>
				)}
			</Formik>
			<DeleteTagModal
				tag={tag}
				show={displayDeleteTag}
				onClose={() => {
					setDisplayDeleteTag(!displayDeleteTag);
				}}
				getData={getData}
				filtersProps={filtersProps}
			/>
		</>
	);
}
