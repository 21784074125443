import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useOutletContext } from "react-router-dom";

import "./ResourceDetail.scss";

import { externalLinkFill } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

import { getCookie } from "@tools/Cookies";
import { getResourceDetailData } from "./ResourceDetailAPI";
import { Content } from "./ui/Content";
import { Header } from "./ui/Header";
import { Information } from "./ui/Information";

export function ResourceDetail() {
	const { user } = useContext(GlobalContext);
	const location = useLocation();
	const userId = user?.id;

	const ressourceId = `${getCookie("interface")}/resources/${
		window.location.pathname.split("/")[2]
	}`;
	const { currentInterface } = useOutletContext();
	const [resource, setResource] = useState();
	const [resourcesIds, setResourcesIds] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const params = new Proxy(new URLSearchParams(window.location.search), {
		get: (searchParams, prop) => searchParams.get(prop),
	});

	const getData = () => {
		getResourceDetailData(ressourceId, user.school, params.type).then(
			({ resource, resources }) => {
				setResource(resource);
				setResourcesIds(resources.collection);
			},
		);
	};

	useEffect(() => {
		getData();
	}, [location]);

	return (
		<main className="ressources grid w-full grid-cols-4 gap-4 sm:gap-6 md:grid-cols-8 lg:grid-cols-12 xxl:gap-8">
			<div className="ressources-details col-span-4 mb-xlg flex w-full md:col-span-8 lg:col-span-12">
				<div className="h-fit-content flex w-full flex-col p-0 lg:w-9/12 ">
					<Header
						roles={user.roles}
						userId={userId}
						resource={resource}
						resourcesIds={resourcesIds}
						currentInterface={currentInterface}
					/>
					<Content resource={resource} />
				</div>
				<div className="col-span-4 w-full p-0 md:col-span-8 lg:col-span-12 lg:w-3/12">
					{resource?.link && (
						<ButtonPrimary
							onClick={() => {
								if (resource.link?.includes("http")) {
									window.open(resource.link, "_blank").focus();
								} else {
									window.open(`https://${resource.link}`, "_blank").focus();
								}
							}}
							icon={externalLinkFill}
							label="Voir la ressource"
							className="mb-lg ml-md w-11/12"
						/>
					)}
					<Information resource={resource} />
				</div>
			</div>
		</main>
	);
}
