import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";

import { chevronRightOutline } from "@assets/Icons";

import { setCookie } from "@tools/Cookies";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Spinner } from "@designSystem/atoms/Spinner";

import type { SchoolType } from "@/types/schoolTypes";
import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import {
	changePassword,
	login,
	retrieveUser,
} from "@containers/auth/ChangePassword/ChangePasswordAPI";
import { InputDoublePassword } from "@designSystem/molecules/InputDoublePassword";
import { errorToast } from "@tools/Toasts";

export function ChangePassword() {
	const [password, setPassword] = useState("");
	const [passwordVerified, setPasswordVerified] = useState("");
	const [validate, setValidate] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isCompany, setIsCompany] = useState(false);
	const [isWelcome, setIsWelcome] = useState(false);
	const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);

	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const { school }: { school: Partial<SchoolType> } = useAppSelector(
		(state: AppState) => state.company,
	);

	const sendLogin = async (data: { email: string; password: string }) => {
		const { token } = await login(data);

		if (!token) {
			errorToast("Token manquant.");
			return;
		}

		setCookie("mainToken", token);

		if (isCompany) {
			window.location.replace(`/company/login?${school?.subdomain || ""}`);
		} else {
			window.location.replace("/choose_profile");
		}
	};

	const updatePassword = async () => {
		const token = params.token;

		if (!token) {
			errorToast("Token manquant.");
			return;
		}

		const data = {
			password,
			passwordVerified,
		};

		if (password === passwordVerified) {
			setLoading(true);
			const school = isCompany ? 4 : undefined;
			try {
				const { email } = await changePassword(token, data, school);
				if (!email) {
					errorToast("Une erreur est survenue");
					setLoading(false);
					return;
				}
				setValidate(true);
				sendLogin({
					email,
					password: data.password,
				});
			} catch {
				errorToast("Une erreur est survenue");
				setLoading(false);
			}
		}
	};

	const checkToken = async () => {
		if (!params.token) {
			setTokenIsValid(false);
			return;
		}
		try {
			const { code } = await retrieveUser(params.token);
			if (code === 200) setTokenIsValid(true);
			else setTokenIsValid(false);
		} catch {
			setTokenIsValid(false);
		}
	};

	useEffect(() => {
		if (location.pathname.includes("/company/")) {
			setIsCompany(true);
		}
		if (location.pathname.includes("/welcome")) {
			setIsWelcome(true);
			document.title = "Bienvenue sur Mentor Goal";
		} else {
			document.title = "Modifier mot de passe - Mentor Goal";
		}

		checkToken();
	}, []);

	const companyClasses =
		"flex flex-col items-center bg-white shadow-md rounded-md p-md !mx-auto !mt-md";

	if (tokenIsValid === false) {
		return (
			<div
				className={`forgot-password ml-0 flex w-full flex-col items-center md:ml-[8%] md:block md:w-11/12 ${
					isCompany ? companyClasses : ""
				}`}
			>
				<h2 className="text-primary-900">Lien invalide</h2>
				<p className="mt-3 w-8/12 p-0 text-center text-xxsm font-normal text-primary-300 md:text-left">
					Le lien a expiré. Vous pouvez générer un nouveau lien en cliquant sur
					le bouton ci-dessous :
				</p>
				<NavLink to="/forgot_password" className="text-decoration-none">
					<ButtonPrimary
						label="Générer un nouveau lien"
						icon={chevronRightOutline}
						className="mt-5"
						onClick={undefined}
					/>
				</NavLink>
				<ButtonSecondary
					label="Aller à la page de connexion"
					className="mt-3 [&>svg]:w-[48px]"
					onClick={() => {
						navigate("/login");
					}}
				/>
			</div>
		);
	}

	if (validate) {
		return (
			<div
				className={`forgot-password ml-0 w-full md:ml-[8%] md:w-11/12 ${
					isCompany ? companyClasses : ""
				}`}
			>
				<h2 className="text-primary-900">Modification réussie !</h2>
				<p className="text-center">Votre mot de passe a bien été mis à jour.</p>
				{loading && (
					<div className="ml-0 mt-5 w-full pl-0 md:w-8/12">
						<div className="text-center">
							<Spinner />
							<p className="mt-2 text-xxxsm text-primary-500">
								Connexion en cours...
							</p>
						</div>
					</div>
				)}
			</div>
		);
	}

	return tokenIsValid ? (
		<div
			className={`forgot-password ml-0 w-full md:ml-[8%] md:w-11/12 ${
				isCompany ? companyClasses : ""
			}`}
		>
			<h2 className="text-primary-900">
				{isWelcome ? "Bienvenue" : "Modifier votre mot de passe"}
			</h2>
			<p className="text-primary-300 mt-sm w-full md:w-8/12">
				{isWelcome
					? "Bienvenue dans l’aventure Mentor Goal ! Commencez par choisir un mot de passe."
					: "Réinitialisez votre mot de passe"}
			</p>
			<div className="ml-0 w-full pl-0 md:w-8/12">
				{loading ? (
					<div className="ml-0 mt-5 w-full pl-0">
						<div className="text-center">
							<Spinner />
							<p className="mt-2">Mise à jour en cours...</p>
						</div>
					</div>
				) : (
					<InputDoublePassword
						buttonLabel={isWelcome ? "Démarrer" : "Envoyer"}
						className="px-6 md:px-0"
						firstPlaceholder="Mot de passe"
						onChange={setPassword}
						onChangeConfirm={setPasswordVerified}
						secondPlaceholder="Confirmer mot de passe"
						onValidate={updatePassword}
					/>
				)}
			</div>
		</div>
	) : (
		<div className="ml-0 mt-5 w-full pl-0">
			<div className="text-center">
				<Spinner />
			</div>
		</div>
	);
}
