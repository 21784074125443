import type { UserType } from "@/types/userTypes";
import type { IconifyIcon } from "@iconify/types";
import React, { useEffect, useState } from "react";

import { bookOpenOutline, briefcaseOutline, clockOutline } from "@assets/Icons";

import { Stats } from "@designSystem/molecules/Stats";
import { getEvolution } from "@tools/Math";

type StatType = {
	type: string;
	label: string;
	value: number;
	progressValue: number;
	icon: IconifyIcon;
	bgIcon: string;
	color: string;
};
type StatisticsType = {
	student: UserType & {
		applicationsCount: number;
		relaunchsCount: number;
		interviewsCount: number;
	};
};
export function Statistics({ student }: StatisticsType) {
	const [stats, setStats] = useState<StatType[] | []>([]);

	const calculateStats = () => {
		const applicationsEvolution = getEvolution(
			student.thisWeekApplicationsCount,
			student.lastWeekApplicationsCount,
		);
		const interviewEvolution = getEvolution(
			student.thisWeekInterviewsCount,
			student.lastWeekInterviewsCount,
		);
		const relaunchEvolution = getEvolution(
			student.thisWeekRelaunchsCount,
			student.lastWeekRelaunchsCount,
		);

		setStats([
			{
				type: "application",
				label: "Candidatures",
				value: student.applicationsCount,
				progressValue: applicationsEvolution,
				icon: bookOpenOutline,
				bgIcon: "bg-info-light",
				color: "text-accent-1",
			},
			{
				type: "relaunch",
				label: "Relances",
				value: student.relaunchsCount,
				progressValue: relaunchEvolution,
				icon: clockOutline,
				bgIcon: "bg-warning-light",
				color: "text-accent-2",
			},
			{
				type: "interview",
				label: "Entretiens",
				value: student.interviewsCount,
				progressValue: interviewEvolution,
				icon: briefcaseOutline,
				bgIcon: "bg-error-light",
				color: "text-accent-3",
			},
		]);
	};

	useEffect(() => {
		if (student) {
			calculateStats();
		}
	}, [student]);

	return (
		<div className="flex w-full items-center gap-sm">
			{stats?.map((item, i) => {
				return (
					<Stats
						key={i}
						className="w-4/12"
						label={item.label}
						subtitle="vs 7 dern. jours"
						value={`${item.value}${item.type === "relaunch" ? "%" : ""}`}
						progressValue={item.progressValue}
						progress
						color={item.color}
						icon={item.icon}
						bgIcon="bg-primary-100"
					/>
				);
			})}
		</div>
	);
}
