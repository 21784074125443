import React, { useEffect, useState } from "react";

import { ChartDoughnut } from "@containers/school/SchoolDashboard/ChartDoughnut";
import { Legend } from "@designSystem/molecules/Legend";

export function StudentsDistributionByStatus({ structure }) {
	const [statusDoughnut, setStatusDoughnut] = useState({});

	const defineStatusChart = () => {
		setStatusDoughnut({
			placed: structure.placed,
			toPlace: structure.toPlace,
			beingSigned: structure.beingSigned,
			surrend: structure.surrend,
		});
	};

	useEffect(() => {
		if (structure) {
			defineStatusChart();
		}
	}, [structure]);

	return (
		<div className="mr-lg w-full rounded-lg bg-white p-4 shadow-sm md:w-6/12">
			<p className="font-semifont-bold pb-sm text-xsm text-primary-700P">
				Répartition des étudiants par statut
			</p>
			<div className="mt-4 flex flex-col items-center">
				<ChartDoughnut
					className="doughnut-responsive-items"
					topTitle="Sur"
					midTitle={
						structure.id
							? structure.placed +
								structure.toPlace +
								structure.surrend +
								structure.beingSigned
							: 0
					}
					bottomTitle="Étudiants"
					data={[
						{ value: statusDoughnut.toPlace, color: "#466A8B" },
						{ value: statusDoughnut.placed, color: "#7EC264" },
						{ value: statusDoughnut.beingSigned, color: "#F4A261" },
						{ value: statusDoughnut.surrend, color: "#E76F51" },
					]}
				/>
				<Legend
					className="mt-3"
					direction="wrap"
					legend={[
						{ name: "Étudiants à placer", color: "#466A8B" },
						{ name: "Étudiants placés", color: "#7EC264" },
						{ name: "En cours de signature", color: "#F4A261" },
						{ name: "Abandons", color: "#E76F51" },
					]}
				/>
			</div>
		</div>
	);
}
