import type { FilterType } from "@/types/filterType";
import React from "react";

import { JOBBOARD_SELECT_OPTIONS } from "@constants/Offers";

import { formatFilterForMultiselect } from "@tools/Filters";

import { Select } from "@designSystem/organisms/select/Select";

export function JobboardFilter({
	handleJobboardFilters,
	currentFilters,
}: {
	handleJobboardFilters: Function;
	currentFilters: string[];
}) {
	return (
		<aside className="w-full md:w-[20%]">
			<Select
				placeholder={
					currentFilters?.length > 0
						? "Jobboard(s) sélectionné(s)"
						: "Tous les jobboards"
				}
				inputs={formatFilterForMultiselect(
					JOBBOARD_SELECT_OPTIONS,
					currentFilters || [],
				)}
				onChange={(filtersChanged: FilterType[]) => {
					handleChange(filtersChanged, handleJobboardFilters);
				}}
				breakSpaces={true}
				multiselect={true}
				position="left"
			/>
		</aside>
	);
}

const handleChange = (
	filtersChanged: FilterType[],
	handleJobboardFilters: Function,
) => {
	const filterValue = filtersChanged[filtersChanged.length - 1]?.value;
	if (filtersChanged === undefined || filterValue?.length === 0) {
		handleJobboardFilters([]);
		return;
	}
	handleJobboardFilters(filtersChanged);
};
