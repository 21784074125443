import React, { useState } from "react";

import { Spinner } from "@designSystem/atoms/Spinner";
import { Stepper } from "@designSystem/molecules/Stepper";

import { editMedia } from "@containers/school/Students/StudentDetail/StudentDetailAPI";

import type { UserType } from "@/types/userTypes";
import { InProgress } from "./CV/InProgress";
import { ToDeposit } from "./CV/ToDeposit";
import { ToValidate } from "./CV/ToValidate";
import { Validated } from "./CV/Validated";

const ECVLabels = {
	TO_DEPOSIT: "En attente",
	TO_VALIDATE: "À valider",
	IN_PROGRESS: "À corriger",
	VALIDATED: "Validé",
};

type CVProps = {
	student: Partial<UserType>;
	updateData: (id: string) => void;
};
export function CV({ student, updateData }: CVProps) {
	const [load, setLoad] = useState(false);

	const steps = [
		{
			label: ECVLabels.TO_DEPOSIT,
		},
		{
			label: ECVLabels.TO_VALIDATE,
		},
		{
			label: ECVLabels.IN_PROGRESS,
		},
		{
			label: ECVLabels.VALIDATED,
		},
	];

	const changeStep = async (newStep: string) => {
		setLoad(true);
		let action = "";
		if (newStep === "validated") {
			action = "validate";
		}
		if (newStep === "editing") {
			action = "refused";
		}

		await editMedia({
			id: student?.cv?.id,
			state: newStep,
			action,
		});

		await updateData(student.id as string);

		setLoad(false);
	};

	const getStep = () => {
		if (student.cv === null) {
			return { color: 1, index: 0 };
		}
		if (student.cv?.state === "toValidate") {
			return { color: 2, index: 1 };
		}
		if (student.cv?.state === "editing") {
			return { color: 3, index: 2 };
		}
		return { color: 4, index: 3 };
	};

	const getStudentIdentity = () => {
		return {
			firstname: student?.firstname || "",
			lastname: student?.lastname || "",
		};
	};

	const renderRightStep = () => {
		const step = getStep().index;
		switch (step) {
			case 0:
				return <ToDeposit student={student} updateData={updateData} />;
			case 1:
				return (
					<ToValidate
						cv={student.cv}
						changeStep={changeStep}
						studentIdentity={getStudentIdentity()}
					/>
				);
			case 2:
				return (
					<InProgress
						studentId={student?.id || ""}
						cv={student.cv}
						changeStep={changeStep}
						updateData={updateData}
						studentIdentity={getStudentIdentity()}
					/>
				);
			default:
				return (
					<Validated
						cv={student.cv}
						changeStep={changeStep}
						studentIdentity={getStudentIdentity()}
					/>
				);
		}
	};

	return (
		<div className="mt-md pb-md pl-xsm pr-xsm">
			<p className="font-weight-font-bold mb-sm text-sm text-primary-700P">
				État du CV
			</p>
			<Stepper
				className="mb-md"
				steps={steps}
				color={getStep().color}
				indexActive={getStep().index}
				onClickOnStep={false}
			/>
			{load ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				renderRightStep()
			)}
		</div>
	);
}
