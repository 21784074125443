import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

import { History } from "@modules/logHistory/History";

export function MyHistory() {
	const { user } = useContext(GlobalContext);

	if (!user) return;
	return (
		<History
			className=""
			currentStudent={user}
			showByDefault={true}
			filters={{
				action_list: [
					"submitCV",
					"modifyCV",
					"deleteCV",
					"cvToModify",
					"cvValidated",
					"cvReminder",
					"loginReminder",
					"shareJobOffer",
					"createStudent",
					"loginReminder",
					"changeStatus",
					"completeProfile",
					"modifyProfile",
					"submitMotivationLetter",
					"deleteMotivationLetter",
					"responseToEvent",
					"createSpontaneousApplication",
					"firstConnection",
					"creatingAnApplication",
					"applicationSent",
					"relaunch",
					"interviewObtained",
				],
				page: 1,
			}}
		/>
	);
}
