import React, { useEffect, useState } from "react";

import {
	type RadioElementType,
	RadioGroup,
} from "@designSystem/atoms/RadioGroup";
import { TextArea } from "@designSystem/atoms/TextArea";

import type { EvaluationType, InterviewDataType } from "@/types/interviewTypes";
import { editApplication } from "@containers/student/Applications/core/api/ApplicationDetailAPI";
import { getCookie } from "@tools/Cookies";
import { success } from "@tools/Toasts";

enum EvaluationEnum {
	VERY_BAD = "Très mal",
	BAD = "Mal",
	GOOD = "Bien",
	VERY_GOOD = "Très bien",
	EXCELLENT = "Excellent !",
}

export function PostInterview({
	applicationId,
	interviewInfo,
	interviewLoaded,
}: InterviewDataType) {
	let { explanationEvaluation, interviewEvaluation } = interviewInfo;

	const [evaluations, setEvaluations] = useState<EvaluationType[]>([
		{
			label: EvaluationEnum.VERY_BAD,
			value: 0,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.BAD,
			value: 1,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.GOOD,
			value: 2,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.VERY_GOOD,
			value: 3,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.EXCELLENT,
			value: 4,
			defaultValue: false,
		},
	]);
	const [interviewEvaluationValue, setInterviewEvaluationValue] =
		useState<number>(0);

	useEffect(() => {
		const copyEvaluation = [...evaluations];

		if (interviewEvaluation) {
			copyEvaluation[interviewEvaluation].defaultValue =
				!evaluations[interviewEvaluation].defaultValue;
		}
		setEvaluations(copyEvaluation);
	}, [interviewInfo]);

	useEffect(() => {
		if (!interviewLoaded) return;
		if (!interviewInfo) return;
		if (interviewLoaded) {
			explanationEvaluation = interviewInfo.explanationEvaluation;
			setInterviewEvaluationValue(interviewInfo.interviewEvaluation);
		}
	}, [interviewInfo, interviewLoaded]);

	const handleOnChange = async (newEvaluationValueReceived: number) => {
		setInterviewEvaluationValue(newEvaluationValueReceived);
		editApplication({
			id: `${getCookie("interface")}/applications/${applicationId}`,
			interviewEvaluation: newEvaluationValueReceived,
		});
		success("Evaluation enregistrée");
	};

	return (
		<div className="flex w-full flex-col items-start pl-sm pr-sm">
			<RadioGroup
				className="mb-md"
				handleOnChange={handleOnChange}
				elements={evaluations as Object[] as RadioElementType[]}
				storedValue={interviewEvaluationValue}
				direction="horizontal"
			/>
			<p className="font-semifont-bold mb-xxsm mt-3 text-primary-500">
				Pourquoi ?
			</p>
			<TextArea
				className="mb-md"
				value={explanationEvaluation}
				onBlur={(e) => {
					editApplication({
						id: `${getCookie("interface")}/applications/${applicationId}`,
						explanationEvaluation: e.target.value,
					}).then(() => {
						success("Evaluation enregistrée");
					});
				}}
				placeholder="Qu'est ce qui vous a marqué ?"
			/>
		</div>
	);
}
