import React from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";
type DeleteSelectionModalType = {
	show: boolean;
	onClose: () => void;
	onDelete: () => void;
};
export function DeleteSelectionModal({
	show,
	onClose,
	onDelete,
}: DeleteSelectionModalType) {
	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title="Confirmer la suppression"
			body={
				<p>
					Êtes-vous sûr de vouloir supprimer cette sélection ? <br /> Cette
					action est irréversible
				</p>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					label="Non, annuler"
					onClick={() => {
						onClose();
					}}
				/>,
				<ButtonPrimary
					key={1}
					label="Oui, confirmer"
					onClick={() => {
						onDelete();
					}}
				/>,
			]}
		/>
	);
}
