import { useAppDispatch } from "@config/redux/store";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import useDebounce from "@hooks/useDebounce";
import React, { useState, useEffect } from "react";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";

type AddSiretToCompanyProps = {
	siretValidator: (siret: string) => void;
};
export function AddSiretToCompany({ siretValidator }: AddSiretToCompanyProps) {
	const dispatchEvent = useAppDispatch();
	const [inputSiretValue, setInputSiretValue] = useState<string>("");

	const debouncedCompanyNameValue = useDebounce(inputSiretValue, 500);

	const modifyCompanySiret = (value: string) => {
		if (siretValidator) {
			siretValidator(value);
		}

		dispatchEvent(
			companiesManagementSlice.actions.modifyCompanyToCreate({ siret: value }),
		);
	};

	useEffect(() => {
		if (debouncedCompanyNameValue) {
			modifyCompanySiret(debouncedCompanyNameValue);
		}
	}, [debouncedCompanyNameValue]);

	return (
		<div>
			<InputLargePrimary
				onChange={(element) => {
					setInputSiretValue(element.target.value);
				}}
				placeholder="Numéro SIRET ou numéro SIREN"
				label="SIRET ou SIREN"
			/>
		</div>
	);
}
