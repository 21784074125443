import { Icon } from "@iconify/react";
import React from "react";

export function InformationBanner({ body, icon, className }) {
	return (
		<div
			id="informations"
			className={`${className} flex items-start justify-between rounded-lg bg-white p-md shadow-md`}
		>
			<Icon
				icon={icon}
				className="text-secondary-500P"
				height={24}
				width={24}
			/>
			<div className="ml-xsm mt-[2px] w-[98%] text-xsm text-primary-700P">
				{body}
			</div>
		</div>
	);
}
