import React from "react";

import { refreshOutline } from "@assets/Icons";

import { convertDateFromNow } from "@tools/Dates";

import { InlineButton } from "@designSystem/atoms/InlineButton";
import { PdfViewer } from "@designSystem/atoms/PdfViewer";

type ValidatedProps = {
	cv: any;
	changeStep: (newStep: string) => void;
	studentIdentity: {
		firstname: string;
		lastname: string;
	};
};
export function Validated({ cv, changeStep, studentIdentity }: ValidatedProps) {
	return (
		<>
			<p className="font-weight-font-bold mb-sm mt-md text-xsm text-primary-700P">
				Validation du CV
			</p>
			<div
				style={{ backgroundColor: "#F2F9EF" }}
				className="mb-md rounded-md pb-xsm pl-sm pr-sm pt-xsm
      "
			>
				<p className="text-xsm text-primary-700P">
					Vous avez validé le CV de l&apos;étudiant{" "}
					{convertDateFromNow(cv?.updatedAt)}.
				</p>
				<InlineButton
					id="relaunch-all"
					onClick={() => changeStep("toValidate")}
					className="mt-xxsm"
					label="Annuler la décision"
					icon={refreshOutline}
				/>
			</div>
			<PdfViewer
				src={`${import.meta.env.VITE_S3}${cv?.filePath}`}
				title={`cv_${studentIdentity.firstname}_${studentIdentity.lastname}`}
			/>
		</>
	);
}
