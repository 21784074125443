import React from "react";

import {
	checkmarkOutline,
	downloadOutline,
	emailOutline,
	link2Outline,
} from "@assets/Icons";

import type { ShareCVModalPageType } from "@containers/school/CVBoard/ui/CVsList/TableView/CreateSelectionModal";
import { getCompanyList } from "@containers/school/Offers_v3/core/api/offersManagement.request";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { CardTertiary } from "@designSystem/molecules/CardTertiary";
import { CheckboxLabelPrimary } from "@designSystem/molecules/CheckboxLabel";
import { Input } from "@designSystem/molecules/Input";
import { SelectCompany } from "@designSystem/organisms/select/SelectCompany";
import { Icon } from "@iconify/react";

type ShareProps = {
	shareTypeProps: {
		shareType: any;
		setShareType: (value: number) => void;
	};
	offerNameProps: {
		offerName: any;
		setOfferName: (value: string) => void;
	};
	offerCompanyProps: {
		offerCompany: any;
		setOfferCompany: (value: string) => void;
	};
	sharedDataProps: {
		sharedData: any;
		setSharedData: (value: number) => void;
	};
	noModifiable?: boolean;
};

export const EMAIL_SHARE_TYPE = 0;
export const LINK_SHARE_TYPE = 1;
export const DOWNLOAD_SHARE_TYPE = 2;

type ShareType = {
	title: string;
	type: number;
	icon: any;
	description: string;
	buttonLabel: string;
	buttonPage?: ShareCVModalPageType;
};

export const SHARE_TYPES: ShareType[] = [
	{
		title: "Par email automatique",
		type: EMAIL_SHARE_TYPE,
		icon: emailOutline,
		description:
			"Un email automatique sera envoyé via Mentor Goal à votre interlocuteur. Vous pourrez personnaliser le contenu avant l'envoi.",
		buttonLabel: "Personnaliser le mail",
		buttonPage: 4,
	},
	{
		title: "Via un lien",
		icon: link2Outline,
		type: LINK_SHARE_TYPE,
		description:
			"Pour partager les profils des étudiants, générez un lien et transmettez-le à votre interlocuteur.",
		buttonLabel: "Générer le lien",
		buttonPage: 3,
	},
	{
		title: "Télécharger",
		icon: downloadOutline,
		type: DOWNLOAD_SHARE_TYPE,
		description:
			"Téléchargez les CV et Lettre de motivation des étudiants sélectionnés.",
		buttonLabel: "Télécharger",
	},
];

export const SHARED_DATA_CV = 1;
export const SHARED_DATA_MOTIVATION_LETTER = 2;
export const SHARED_DATA_PORTFOLIO = 4;

export function ShareV2({
	shareTypeProps,
	offerNameProps,
	offerCompanyProps,
	sharedDataProps,
	noModifiable = false,
}: ShareProps) {
	const { shareType, setShareType } = shareTypeProps;
	const { offerCompany, setOfferCompany } = offerCompanyProps;
	const { offerName, setOfferName } = offerNameProps;
	const { sharedData, setSharedData } = sharedDataProps;
	const handleShareDataToggle = (data: number) => {
		setSharedData(sharedData ^ data);
	};
	return (
		<div className="flex flex-1 flex-col text-primary-700P gap-sm">
			<div className="flex flex-col gap-sm">
				<p className={"text-xsm font-semibold"}>Détails de l’offre</p>
				<div className="flex flex-1 gap-xsm">
					<SelectCompany
						disabled={noModifiable}
						handleNewCompany={setOfferCompany}
						defaultValue={{ name: offerCompany }}
						className={"flex flex-1"}
						getCompanyList={getCompanyList}
					/>
					<Input
						disabled={noModifiable}
						label="Poste à pourvoir*"
						value={offerName}
						placeholder="Nom du poste"
						className={"flex-1"}
						onChange={(e) => setOfferName(e.target.value)}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-sm">
				<p className={"text-xsm font-semibold flex items-center gap-xsm"}>
					Sélectionnez les informations à partager
					<TooltipElement id="shareCVTooltip" />
					<TooltipContainer
						anchorId="#shareCVTooltip"
						place="top"
						children={
							<p>
								Les documents sélectionnés seront visibles uniquement si
								l’étudiant les a déposés.
							</p>
						}
					/>
				</p>
				<div className="flex gap-sm">
					<div className="flex items-center">
						<Icon icon={checkmarkOutline} className="w-6 h-6" />
						<p className={"text-xxsm"}>CV (Requis)</p>
					</div>
					<CheckboxLabelPrimary
						checked={(sharedData & SHARED_DATA_MOTIVATION_LETTER) !== 0}
						onCheck={() => handleShareDataToggle(SHARED_DATA_MOTIVATION_LETTER)}
						label={"Lettre de motivation"}
					/>
					<CheckboxLabelPrimary
						checked={(sharedData & SHARED_DATA_PORTFOLIO) !== 0}
						onCheck={() => handleShareDataToggle(SHARED_DATA_PORTFOLIO)}
						label={"Portfolio"}
					/>
				</div>
			</div>
			<div className="flex flex-1 flex-col gap-sm">
				<p className={"text-xsm font-semibold"}>
					Choisissez votre option de partage
				</p>
				<div className="flex gap-sm">
					<div className="flex items-center gap-sm flex-1">
						{SHARE_TYPES.map((share, index) => (
							<CardTertiary
								key={`shareType-${index}`}
								title={share.title}
								icon={share.icon}
								className={"p-xsm pb-sm flex-1"}
								selected={share.type === shareType}
								onSelect={(selected) => {
									if (selected) {
										setShareType(share.type);
									} else {
										setShareType(EMAIL_SHARE_TYPE);
									}
								}}
							/>
						))}
					</div>
				</div>
				<p className={"text-xxsm"}>{SHARE_TYPES[shareType].description}</p>
			</div>
		</div>
	);
}
