import type { ApplicationStepType } from "@/types/applicationTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
	checkmarkOutline,
	closeOutline,
	questionMarkOutline,
} from "@assets/Icons";

import { diffDate, formatDate } from "@tools/Dates";

import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { success, updateToastWithSuccess } from "@tools/Toasts";

import { updateApplication } from "@containers/school/Offers_v3/core/api/offerApplication.request";
import {
	fetchOffersApplication,
	fetchOffersApplicationsThumbnails,
} from "@containers/school/Offers_v3/core/store/offersManagement.thunks";

import { AcceptApplicationModal } from "../../../modal/AcceptApplicationModal";
import { dataAppDetails } from "../../../modal/ApplicationModalsData";
import { OnHoldApplicationModal } from "../../../modal/OnHoldApplicationModal";
import { RefuseApplicationModal } from "../../../modal/RefuseApplicationModal";
import { RemoveProfilButton } from "../sendToCompany/RemoveProfilButton";

import type { UserType } from "@/types/userTypes";
import {
	modifyOfferToEdit,
	setOfferToEdit,
} from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import { OfferStudentProfilCard } from "./ApplicationDataVisualizer/OfferStudentProfilCard";
import { ApplicationDocumentsReader } from "./ApplicationDocumentsReader";
import { ApplicationMessages } from "./ApplicationMessages";
import { ApplicationStep } from "./ApplicationStep";

export function ApplicationDataVisualizer() {
	const dispatchEvent = useDispatch();
	const { applicationSelectedData, offerToEdit, currentDetailTabSelected } =
		useAppSelector((state) => state.offers);
	const [treatmentReason, setTreatmentReason] = useState<string>("");
	const [messages, setMessages] = useState([""]);

	const [showAcceptModal, setShowAcceptModal] = useState(false);
	const [showMaybeModal, setShowMaybeModal] = useState(false);
	const [showRefusedModal, setShowRefusedModal] = useState<
		false | "application" | "selection"
	>(false);

	const [currentModalData, setCurrentModalData] = useState<any>(
		dataAppDetails.modals.accept,
	);

	const setModalData = (key: "accept" | "maybe" | "decline") => {
		setCurrentModalData(dataAppDetails.modals[key]);
	};

	const editApplicationResponse = async (
		stateValue: string,
		treatmentReason: string | undefined = undefined,
		messages: string[] | undefined = undefined,
	) => {
		const loadingToast = toast.loading("Traitement en cours...", {
			isLoading: true,
			hideProgressBar: true,
			type: "default",
		});
		const oldStatus = applicationSelectedData?.isTreated;
		const response = await updateApplication({
			id: applicationSelectedData?.id || "",
			state: stateValue,
			isTreated: true,
			treatmentReason: treatmentReason || null,
			messages,
			operation: "treat",
		});

		if (response.responseLabel === "success") {
			dispatchEvent(
				fetchOffersApplicationsThumbnails(offerToEdit?.id || "") as any,
			);
			dispatchEvent(
				fetchOffersApplication(applicationSelectedData?.id || "") as any,
			);
			if (offerToEdit && !oldStatus) {
				const newOfferToEdit = { ...offerToEdit };
				newOfferToEdit.applicationsToTreat -= 1;
				dispatchEvent(setOfferToEdit(newOfferToEdit));
			}
			updateToastWithSuccess(
				loadingToast,
				"La candidature a bien été modifiée",
			);
		}
	};

	const toggleShowRefuseModal = (from: "application" | "selection") => {
		setModalData("decline");
		setShowRefusedModal(from);
	};

	const handleRemoveProfilClick = async () => {
		if (
			offerToEdit?.id &&
			applicationSelectedData?.spsStudent?.id &&
			offerToEdit?.selection?.id
		) {
			const selectionUsers =
				offerToEdit.selection.selectionUsers.collection.filter(
					({ user }: { user: UserType }) =>
						user.id !== applicationSelectedData?.spsStudent?.id,
				);
			dispatchEvent(
				modifyOfferToEdit({
					selection:
						selectionUsers.length === 0
							? null
							: {
									...offerToEdit.selection,
									selectionUsers: {
										collection: selectionUsers,
									},
								},
				}),
			);
			dispatchEvent(
				fetchOffersApplicationsThumbnails(offerToEdit?.id || "") as any,
			);
			success("Profil retiré de l'envoi");
		}
	};

	return (
		<section className="flex flex-col gap-8">
			<article className="flex w-full justify-between">
				<header>
					<p className="text-sm font-bold text-primary-700P">
						Candidature de
						{` ${applicationSelectedData?.spsStudent?.firstname} ${applicationSelectedData?.spsStudent?.lastname}`}
					</p>
					<p className="text-xxsm font-semibold text-primary-200">
						{currentDetailTabSelected === "sendToCompany"
							? `Sélectionné ${
									diffDate(applicationSelectedData?.updateAt || "") !== 0
										? `il y a ${diffDate(
												applicationSelectedData?.updateAt || "",
											)}j`
										: "aujourd'hui"
								}`
							: `Reçue le ${formatDate(
									applicationSelectedData?.createdAt || "",
								)}`}
					</p>
				</header>
				{currentDetailTabSelected !== "sendToCompany" &&
					offerToEdit?.type === "preselection" && (
						<aside className="flex flex-col items-center">
							<p className="mr-xsm text-xsm font-bold text-primary-200">
								Profil intéressant ?
							</p>
							<div className="flex items-center gap-0">
								<ButtonTertiary
									active={applicationSelectedData?.state === "accepted"}
									onClick={() => {
										setModalData("accept");
										setShowAcceptModal(true);
									}}
									className="ml-sm"
									icon={checkmarkOutline}
									activeIcon={checkmarkOutline}
								/>
								<ButtonTertiary
									active={applicationSelectedData?.state === "onHold"}
									onClick={() => {
										setModalData("maybe");
										setShowMaybeModal(true);
									}}
									className="ml-sm"
									icon={questionMarkOutline}
									activeIcon={questionMarkOutline}
								/>
								<ButtonTertiary
									active={applicationSelectedData?.state === "refused"}
									onClick={() => toggleShowRefuseModal("application")}
									className="ml-sm"
									icon={closeOutline}
									activeIcon={closeOutline}
								/>
							</div>
						</aside>
					)}
				{currentDetailTabSelected === "sendToCompany" &&
					offerToEdit?.selection?.selectionUsers?.collection.find(
						({ user }: { user: UserType }) =>
							applicationSelectedData?.spsStudent?.id === user.id,
					) && (
						<RemoveProfilButton
							onClick={() => toggleShowRefuseModal("selection")}
						/>
					)}
			</article>
			{currentDetailTabSelected !== "sendToCompany" &&
				offerToEdit?.type === "direct" && (
					<ApplicationStep
						step={(applicationSelectedData?.step as ApplicationStepType) || ""}
					/>
				)}
			{applicationSelectedData?.state === "update" && <ApplicationMessages />}
			{applicationSelectedData?.state === "onHold" && <ApplicationMessages />}
			<OfferStudentProfilCard />
			<ApplicationDocumentsReader />
			<AcceptApplicationModal
				show={showAcceptModal}
				onClose={() => {
					return setShowAcceptModal(!showAcceptModal);
				}}
				currentModalDataProps={{ currentModalData }}
				edit={() => {
					return editApplicationResponse(currentModalData.confirmValue);
				}}
			/>
			<OnHoldApplicationModal
				show={showMaybeModal}
				onClose={() => {
					return setShowMaybeModal(!showMaybeModal);
				}}
				state={applicationSelectedData?.state || ""}
				treatmentProps={{ treatmentReason, setTreatmentReason }}
				messagesProps={{ messages, setMessages }}
				edit={() => {
					return editApplicationResponse(
						currentModalData.confirmValue,
						treatmentReason || "",
						messages,
					);
				}}
			/>

			<RefuseApplicationModal
				show={showRefusedModal !== false}
				onClose={() => {
					setShowRefusedModal(false);
				}}
				from={showRefusedModal || "application"}
				state={applicationSelectedData?.state || ""}
				treatmentProps={{ treatmentReason, setTreatmentReason }}
				messagesProps={{ messages, setMessages }}
				edit={() => {
					handleRemoveProfilClick();
					editApplicationResponse(
						currentModalData.confirmValue,
						treatmentReason || "",
						messages,
					);
				}}
			/>
		</section>
	);
}
