import { getSchoolBySubdomain } from "@navigation/NavigationAPI";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import logoDark from "@assets/logos/logoDark.svg";

import { getSubdomain } from "@tools/Auth";
import { getAuthIllustrationFromURL } from "@tools/Illustration";

export const AuthLayout = ({ defineInstance, setIsMicrosoftLogin }) => {
	const [school, setSchool] = useState();
	const [illustration, setIllustration] = useState();
	const location = useLocation();

	useEffect(() => {
		setIllustration(
			getAuthIllustrationFromURL(window.location.href.split("/")[3]),
		);
	}, [location]);

	const retrieveSchool = async (subdomain) => {
		await getSchoolBySubdomain(subdomain).then(
			({ name, logo, illustration }) => {
				if (name) {
					setSchool({
						subdomain,
						name,
						logo,
						illustrations: `${import.meta.env.VITE_S3}${illustration}`,
					});
				}
			},
		);
	};

	useEffect(() => {
		const subdomain = getSubdomain();
		if (subdomain) {
			if (
				!["app", "login", "redirect", "school", "student"].some((schoolName) =>
					subdomain.includes(schoolName),
				)
			) {
				retrieveSchool(subdomain);
			}

			if (
				["amos", "cmh", "esdac", "esiea", "intechinfo", "sso-ace"].some(
					(schoolName) => subdomain.includes(schoolName),
				)
			) {
				defineInstance(subdomain);
				setIsMicrosoftLogin(true);
			} else if (["ace"].some((schoolName) => subdomain.includes(schoolName))) {
				defineInstance(subdomain);
			}
		}
	}, []);

	return (
		<div className="mr-0 flex h-screen w-full justify-center pr-0">
			<div className="flex w-full flex-col items-center justify-center p-0 px-md md:px-xxlg lg:w-7/12">
				<header className="mt-md pl-0 md:w-full">
					<NavLink to="/">
						<img
							alt="logo Mentor Goal"
							className="cursor-pointer"
							src={logoDark}
						/>
					</NavLink>
				</header>

				<div className="flex h-[calc(100%-104px)] w-full items-center p-0">
					<Outlet context={{ school, setSchool }} />
				</div>
			</div>

			<div
				id="connexion-right"
				className="hidden w-5/12 overflow-hidden rounded-l-[32px] md:block"
			>
				{school ? (
					<img
						className="h-full w-full object-cover"
						alt="illustration de travail"
						src={
							school?.illustrations.split("/")[
								school?.illustrations.split("/").length - 1
							] !== "null"
								? school?.illustrations
								: illustration
						}
					/>
				) : (
					<img
						src={illustration}
						className="h-full w-full object-cover"
						alt="illustration de travail"
					/>
				)}
			</div>
		</div>
	);
};
