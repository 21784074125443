import type { EventType } from "@/types/eventsTypes";
import React from "react";

import EventCard from "../card/EventCard";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { useEventReplies } from "../card/useEventReplies";
import { ReplyListModal } from "../modal/replyListModal/ReplyListModal";
import EventsSkeleton from "./EventsSkeleton";
import NoEvent from "./NoEvent";
import { useEventsList } from "./useEventsList";

type EventsListProps = {
	events: EventType[];
	listTitle: string;
	listID: string;
};

function EventsList({ events, listTitle, listID }: EventsListProps) {
	const repliesPresenter = useEventReplies();
	const listPresenter = useEventsList();

	return (
		<section className="flex w-full flex-col items-center justify-center gap-6">
			{events?.length === 0 && listPresenter.initialized ? (
				<NoEvent />
			) : (
				renderList({
					listTitle,
					events,
					listID,
					presenter: listPresenter,
				})
			)}
			<Modal
				title="Invités"
				id="participantListModal"
				buttonsRight={[
					<ButtonPrimary
						label="Fermer"
						onClick={repliesPresenter.closeRepliesModal}
					/>,
				]}
				show={repliesPresenter.showRepliesListModal || false}
				onClose={repliesPresenter.closeRepliesModal}
				body={<ReplyListModal />}
			/>
		</section>
	);
}

export default EventsList;

type RenderList = {
	listTitle: string;
	events: EventType[];
	listID: string;
	presenter: ReturnType<typeof useEventsList>;
};

const renderList = ({ listTitle, events, listID, presenter }: RenderList) => {
	return (
		<article key={listTitle} className="flex w-full flex-col gap-6">
			<div className="text-sm font-bold not-italic text-primary-700P">
				{listTitle}
			</div>
			{presenter.showSkeleton ? (
				<EventsSkeleton />
			) : (
				<div className="flex w-full flex-wrap items-start justify-start gap-6">
					{presenter
						.getSortedEvents(events, listID)
						.map((event) => renderArticle(event, listID))}
				</div>
			)}
		</article>
	);
};

const renderArticle = (event: EventType, listID: string) => {
	return (
		<EventCard
			key={`card-${event.id}-${Math.random()}`}
			event={event}
			parentListID={listID}
		/>
	);
};
