import type { CampusId } from "@/types/campusTypes";
import { Spinner } from "@designSystem/atoms/Spinner";
import { MultiselectV2 } from "@designSystem/organisms/multiselectV2/MultiselectV2";
import React from "react";
import { useCampusMultiselect } from "./useCampusMultiselect";

type CampusMultiselectProps = {
	defaultCoreCampusesSelected: CampusId[];
	handleSelectCampus: (campusesSelected: CampusId[]) => void;
	useFullWidth?: boolean;
	useUserCampus?: boolean;
	isRequired?: boolean;
};

/**
 * @description The CampusMultiselect component is a multiselect component that allows the user to select one or multiple campuses.
 * @param {CampusId[]} defaultCoreCampusesSelected - The list of preselected campuses.
 * @param {function} handleSelectCampus - The function to call when a campus is selected.
 * @param {boolean} useFullWidth - If true, the multiselect will use the full width.
 * @default false
 * @param {boolean} useUserCampus - If true, the user campus will be added to the preselected campuses. Exepted
 * if the user has multiple campuses.
 * @default false
 * @param {boolean} isRequired - If true, the multiselect will be marked as required with *.
 * @default false
 * @returns {JSX.Element} - A CampusMultiselect component.
 */
export function CampusMultiselect({
	defaultCoreCampusesSelected,
	handleSelectCampus,
	useFullWidth = false,
	useUserCampus = false,
	isRequired = false,
}: CampusMultiselectProps) {
	const presenter = useCampusMultiselect(
		defaultCoreCampusesSelected,
		useUserCampus,
		handleSelectCampus,
	);

	if (!presenter.accessGranted) return null;
	return (
		<div className="wg-no-translate w-full">
			{presenter.isLoading ? (
				<div className="flex gap-2 items-center justify-start">
					<span>Chargement des campus</span>
					<Spinner size="small" />
				</div>
			) : (
				<MultiselectV2
					searchbarPlaceholder="Rechercher un campus..."
					hasSearchbar
					label="Campus"
					preselectedOptionsValues={presenter.defaultValues}
					options={presenter.campuses}
					name="Campus"
					onSelectOption={handleSelectCampus}
					isRequired={isRequired}
					useFullWidth={useFullWidth}
				/>
			)}
		</div>
	);
}
