import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import {
	CONTRACT_TYPE_OPTIONS,
	QUALIFICATION_OPTIONS,
	TELEWORK_OPTIONS,
} from "@constants/Offers";

import {
	getContractOption,
	getQualificationOption,
	getTeleworkOption,
} from "@tools/Offers";

import { Input } from "@designSystem/molecules/Input";
import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";

import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";
import { ReferentModifier } from "../actions/ReferentModifier";

type ComplementaryInformationsProps = {
	editionMode?: boolean;
};

export function ComplementaryInformations({
	editionMode = false,
}: ComplementaryInformationsProps) {
	const dispatchEvent = useDispatch();
	const { offerToCreate } = useAppSelector((state) => state.offers);
	const handleContractChange = (value: string) => {
		dispatchEvent(modifyOfferToCreate({ contract: value }));
	};

	const handleQualificationChange = (value: string) => {
		dispatchEvent(modifyOfferToCreate({ qualification: value }));
	};

	const handleTeleworkChange = (value: string) => {
		dispatchEvent(modifyOfferToCreate({ telework: value }));
	};

	const handleCountJobOpening = (value: string) => {
		if (!value) {
			return dispatchEvent(modifyOfferToCreate({ openPositionsCount: 1 }));
		}
		return dispatchEvent(modifyOfferToCreate({ openPositionsCount: value }));
	};

	const openPositionsCount =
		offerToCreate?.openPositionsCount && offerToCreate?.openPositionsCount !== 0
			? offerToCreate?.openPositionsCount
			: 1;

	return (
		<aside
			className="flex w-full flex-col gap-6"
			data-testid="complementaryInformations"
		>
			<p className="text-sm font-bold text-primary-700P">
				Informations complémentaires
			</p>
			<SelectSecondary
				position="right"
				label="Type de contrat"
				defaultValue={getContractOption(offerToCreate)}
				options={CONTRACT_TYPE_OPTIONS}
				onChange={(e) => {
					handleContractChange(e.value);
				}}
			/>
			<SelectSecondary
				position="right"
				label="Niveau requis"
				defaultValue={getQualificationOption(offerToCreate)}
				options={QUALIFICATION_OPTIONS}
				onChange={(e) => {
					handleQualificationChange(e.value);
				}}
			/>
			<SelectSecondary
				label="Télétravail"
				position="right"
				defaultValue={getTeleworkOption(offerToCreate)}
				options={TELEWORK_OPTIONS}
				onChange={(e) => {
					handleTeleworkChange(e.value);
				}}
			/>

			<Input
				name="company"
				type="number"
				className="w-full"
				label="Nombre de poste(s) à pourvoir"
				numberValue={openPositionsCount}
				onChange={(value) => {
					handleCountJobOpening(value);
				}}
				min={openPositionsCount}
				errorMessage={""}
			/>
			{!editionMode && <ReferentModifier />}
		</aside>
	);
}
