import type { TagType } from "@/types/tagTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { removeDuplicateTags } from "@tools/Tags";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { TagsFinder } from "@designSystem/organisms/TagsFinder/TagsFinder";

import { getTags } from "../../core/api/offersManagement.request";
import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";
import { fetchModifyOfferTags } from "../../core/store/offersManagement.thunks";

function TagsModifier({ useAutosave = false }: { useAutosave?: boolean }) {
	const { offerToEdit } = useAppSelector((state) => state.offers);

	const [skillsTagsOptions, setSkillsTagsOptions] = useState<TagType[]>([]);
	const [genericTagsOptions, setGenericTagsOptions] = useState<TagType[]>([]);
	const [selectedSkillsTags, setSelectedSkillsTags] = useState<TagType[]>([]);
	const [selectedGenericTags, setSelectedGenericTags] = useState<TagType[]>([]);
	const dispatchEvent = useDispatch();

	const retrieveTags = async () => {
		const fetchTagResponse = await getTags();
		if (fetchTagResponse?.length > 0) {
			const skillsTagsOptions = fetchTagResponse?.filter(
				(tag: TagType) => tag.type === "hardSkill" || tag.type === "softSkill",
			);
			setSkillsTagsOptions(skillsTagsOptions);
			setGenericTagsOptions(
				fetchTagResponse?.filter((tag: TagType) => tag.type === "school"),
			);
		}
	};

	const handleTagsSelection = (selectedTags: TagType[]) => {
		const tagsWithoutDuplicates = removeDuplicateTags(selectedTags);
		if (useAutosave) {
			dispatchEvent(
				fetchModifyOfferTags({
					id: offerToEdit?.id,
					tags: tagsWithoutDuplicates?.map((tag: TagType) => tag.id),
				}) as any,
			);
		} else {
			dispatchEvent(
				modifyOfferToCreate({
					tags: tagsWithoutDuplicates?.map((tag: TagType) => tag.id),
				}),
			);
		}
	};

	useEffect(() => {
		if (offerToEdit) {
			setSelectedSkillsTags(
				offerToEdit?.tags?.filter((tag: TagType) => tag.type !== "school"),
			);
			setSelectedGenericTags(
				offerToEdit?.tags?.filter((tag: TagType) => tag.type === "school"),
			);
		}
	}, []);

	useEffect(() => {
		retrieveTags();
	}, []);

	return (
		<div data-testid="tagsModifier" className="flex w-full flex-col gap-sm">
			<p className="font-bold text-primary-700P">Tags et compétences</p>
			<div className="flex flex-col gap-xxsm">
				<p className="flex gap-xxsm text-xxsm text-primary-500">
					Tags
					<TooltipElement id="tooltip-tags" />
				</p>
				<TagsFinder
					options={genericTagsOptions}
					placeholder="Rechercher un tag..."
					defaultOptions={selectedGenericTags}
					onChange={(selectedTags: TagType[]) => {
						handleTagsSelection([...selectedTags, ...selectedSkillsTags]);
						setSelectedGenericTags(selectedTags);
					}}
				/>
				<TooltipContainer
					anchorId="#tooltip-tags"
					children="Ces tags ne sont pas visibles par l’étudiant."
					place="right"
				/>
			</div>
			<div className="flex flex-col gap-xxsm">
				<p className="flex gap-xxsm text-xxsm text-primary-500">
					Compétences
					<TooltipElement id="tooltip-skills" />
				</p>
				<TagsFinder
					options={skillsTagsOptions}
					placeholder="Rechercher un tag compétences..."
					defaultOptions={selectedSkillsTags}
					onChange={(selectedTags: TagType[]) => {
						setSelectedSkillsTags(selectedTags);
						handleTagsSelection([...selectedGenericTags, ...selectedTags]);
					}}
				/>
				<TooltipContainer
					anchorId="#tooltip-skills"
					children="Les tags compétences sont visibles par l’étudiant."
					place="right"
				/>
			</div>
		</div>
	);
}

export default TagsModifier;
