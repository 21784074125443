import type {
	EntCompanySchoolProfileType,
	EntOffersType,
} from "@/types/EntCompanyTypes";
import React from "react";

import { Badge } from "@designSystem/atoms/Badge";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";

import { OfferNumbersBlock } from "@containers/company/Offers/ui/OfferNumbersBlock";

import type { EntOfferStatusSelectionOption } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import { ENT_COMPANY_OFFER_STATUS } from "@constants/Company";
import { CompanyHeader } from "@containers/company/Offers/ui/OfferSmallList/CompanyHeader";
import { EmailInfo } from "@containers/company/Offers/ui/OfferSmallList/EmailInfo";
import { OfferMainInfo } from "@containers/company/Offers/ui/OfferSmallList/MainInfo";
import { OfferDescription } from "@containers/company/Offers/ui/OfferSmallList/OfferDescription";
import { PdfDisplay } from "@containers/company/Offers/ui/OfferSmallList/PdfDisplay";
import { SchoolHeader } from "@containers/company/Offers/ui/OfferSmallList/SchoolHeader";
import type { CompanySliceType } from "@containers/company/slice/companySlice";
import { selectionHasNewUsers } from "@tools/Selections";

export function OfferPanel({
	isCompany = false,
	seeModal,
	setSeeModal,
}: {
	isCompany?: boolean;
	seeModal?: boolean | string;
	setSeeModal?: (e: false | string) => void;
}) {
	const { activeOffer } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);

	let status: EntOfferStatusSelectionOption | undefined;
	if (isCompany && selectionHasNewUsers(activeOffer?.spsSelection)) {
		status = ENT_COMPANY_OFFER_STATUS.find((e) => e.value === "new");
	} else
		status =
			ENT_COMPANY_OFFER_STATUS.find((e) => e.value === activeOffer?.status) ||
			ENT_COMPANY_OFFER_STATUS.find((e) => e.default);

	return (
		<div className="w-full">
			<WhiteCard
				content={
					<div>
						<div className="mb-sm flex flex-col items-center justify-between md:flex-row">
							<p className="mb-sm flex items-center text-primary-300 max-w-[75%] md:mb-0">
								<span className="mr-xsm font-bold text-primary-900">
									{activeOffer?.job}
								</span>
								{!isCompany ? (
									<span>
										-{" "}
										{
											(
												activeOffer?.entCompanySchoolProfile as EntCompanySchoolProfileType
											).entCompany.name
										}
									</span>
								) : (
									<Badge
										className="mb-auto"
										size="medium"
										label={status?.label || "Erreur"}
										bgColor={status?.bgColor}
										hasBorder={true}
									/>
								)}
							</p>
							{isCompany ? (
								<CompanyHeader
									activeOffer={activeOffer as EntOffersType}
									seeModal={seeModal as boolean}
									setSeeModal={setSeeModal as (e: boolean | string) => boolean}
								/>
							) : (
								<SchoolHeader activeOffer={activeOffer as EntOffersType} />
							)}
						</div>
						{isCompany && activeOffer?.userData && (
							<OfferNumbersBlock
								className="mb-md w-full justify-between"
								userData={activeOffer.userData}
							/>
						)}
						<OfferMainInfo activeOffer={activeOffer as EntOffersType} />
						<EmailInfo
							activeOffer={activeOffer as EntOffersType}
							isCompany={isCompany}
						/>
						<OfferDescription activeOffer={activeOffer as EntOffersType} />
						<PdfDisplay activeOffer={activeOffer as EntOffersType} />
					</div>
				}
			/>
		</div>
	);
}
