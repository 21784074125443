import type { CampusId } from "@/types/campusTypes";
import { useAppSelector } from "@config/redux/hook";
import { useAppDispatch } from "@config/redux/store";
import { companiesManagementSlice } from "@containers/school/Companies/core/store/companiesManagement.slice";
import { verifyCompanySiretExistance } from "@containers/school/Companies/core/usecases/verifyCompanySiretExistance.usecase";

type CompanyCreation = () => {
	checkCompanySiret: (siret: string) => void;
	handleCampusesSelection: (campuses: CampusId[]) => void;
	coreCampusesSelected: CampusId[];
};

export const useCompanyCreation: CompanyCreation = () => {
	const modifyCompanyToCreate =
		companiesManagementSlice.actions.modifyCompanyToCreate;
	const dispatch = useAppDispatch();
	const { companyToCreate } = useAppSelector(
		(state) => state.companiesManagement,
	);

	const checkCompanySiret = async (siret: string) => {
		await dispatch(verifyCompanySiretExistance(siret));
	};

	const handleCampusesSelection = (campuses: CampusId[]) => {
		dispatch(
			modifyCompanyToCreate({
				coreCampuses: campuses,
			}),
		);
	};

	return {
		checkCompanySiret,
		handleCampusesSelection,
		coreCampusesSelected: companyToCreate.coreCampuses,
	};
};
