import React from "react";

import type { ResourceType } from "@/types/resourcesTypes";
import { CardModel } from "@containers/shared/Resources/ui/Body/CardModel";
import { CardResource } from "@containers/shared/Resources/ui/Body/CardResource";
import { getCookie } from "@tools/Cookies";
import { useNavigate } from "react-router";

export function ResourcesList({
	mgResources,
	schoolResources,
	isSchool,
}: {
	mgResources: ResourceType[];
	schoolResources: ResourceType[];
	isSchool: boolean;
}) {
	const navigate = useNavigate();
	const getResourceCard = (resource: ResourceType, type: string) => {
		if (
			resource.type === "cvModel" ||
			resource.type === "motivationLetterModel"
		) {
			return (
				<CardModel
					key={resource.link + resource.id}
					onClick={() => {
						if (
							resource.link.includes("https") ||
							resource.link.includes("http")
						) {
							window.open(resource.link, "_blank");
						} else {
							window.open(`https://${resource.link}`, "_blank");
						}
					}}
					className="w-full min-w-fit"
					image={
						resource.illustration
							? `${import.meta.env.VITE_S3}${typeof resource.illustration === "string" ? resource.illustration : resource.illustration?.filePath}`
							: null
					}
					title={resource.title}
					edit={type === "school" && getCookie("interface") === "referent"}
					translate={false}
					smallCard={true}
					onEdit={() => {
						navigate(`/school-resources/edit/${resource.id.split("/")[3]}`);
					}}
				/>
			);
		}
		return (
			<CardResource
				key={resource.link + resource.id}
				isDirectLink={resource.isDirectLink}
				onClick={() => {
					if (resource.isDirectLink) {
						window.open(`${resource.link}`, "_blank");
					} else {
						navigate(
							`/${isSchool ? "school-" : ""}resources/${resource.id.split("/")[3]}?type=${resource.type}`,
						);
					}
				}}
				image={
					resource.illustration
						? `${import.meta.env.VITE_S3}${typeof resource.illustration === "string" ? resource.illustration : resource.illustration?.filePath}`
						: ""
				}
				color={resource.color ? resource.color : "bg-accent-1"}
				title={resource.title}
				description={resource?.description || ""}
				edit={
					type === "school" &&
					getCookie("interface") === "referent" &&
					!resource.isDirectLink
				}
				onEdit={() => {
					navigate(`/school-resources/edit/${resource.id.split("/")[3]}`);
				}}
			/>
		);
	};

	return (
		<div className="flex flex-col gap-md w-full">
			{schoolResources.length > 0 && (
				<p className="text-sm uppercase text-primary-300">
					Les ressources de ton école
				</p>
			)}

			<div className="w-full grid-list mt-sm">
				{schoolResources.map((resource) => getResourceCard(resource, "school"))}
			</div>
			{mgResources.length > 0 && (
				<p className="text-sm uppercase text-primary-300">
					Les ressources Mentor Goal
				</p>
			)}
			<div className="w-full grid-list mt-sm">
				{mgResources.map((resource) => getResourceCard(resource, "mg"))}
			</div>
		</div>
	);
}
