import axios from "axios";

import api, { checkGraphQLError, graphqlRequestConstructor } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function editApplication(inputs: any) {
	let rep = {};
	const data = {
		query: `mutation {
      updateApplication(input: ${stringifyRequestInputs(inputs)}) {
        application {
          id
        }
      }
    }`,
	};
	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function autoComplet(value = "") {
	let rep = {};
	await axios
		.get(
			`https://autocomplete.clearbit.com/v1/companies/suggest?query=${value}`,
		)
		.then(({ data }) => {
			rep = data;
		});
	return rep;
}

export async function createApplication(inputs: any) {
	let rep = {};
	const data = {
		query: `mutation {
      createApplication(input: ${stringifyRequestInputs(inputs)}) {
        application {
          id
        }
      }
    }`,
	};
	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function getApplicationsColumnView(filters: any, cols: string[]) {
	const data = {
		query: `query {
     ${cols.map((col) => {
				return `${col}: collectionQueryApplications(itemsPerPage: 10000, ${filters}, order: [{id: "DESC"}], step: "${col}") {
          collection {
            step
            id
            favorite
            job
            accepted
            state
            isTreated
            relaunchDate
            archived
            createdAt
            sentDate
            interviewDate
            organization: organizationV2
          }
        }\n`;
			})}
    }`,
	};

	const rep = await graphqlRequestConstructor(
		data,
		"collectionQueryApplications",
	);
	return rep.data;
}

export async function getApplicationsList(filters: any) {
	let rep = {};
	const data = {
		query: `query {
      collectionQueryApplications(itemsPerPage: 10, ${filters}, order: [{id: "DESC"}]) {
        collection {
          id
          favorite
          job
          step
          accepted
          state
          relaunchDate
          createdAt
          sentDate
          interviewDate
          actions (itemsPerPage: 1, done: false, order: {datetime: "ASC"}) {
            collection{
              id
              done
              title
              datetime
            }
          }
          organization: organizationV2
        },
        paginationInfo {
          lastPage
        }
      },
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.collectionQueryApplications;
	});
	return rep;
}
