import type { EntEmployeeType } from "@/types/EntCompanyTypes";
import { useAppSelector } from "@config/redux/hook";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { arrowBackOutline } from "@assets/Icons";

import { checkEmail } from "@tools/Checkers";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Input } from "@designSystem/molecules/Input";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { queryUsers } from "./UsersAPI";
import { TableView } from "./ui/List/TableView";
import success = toast.success;

export function CompanyUsers() {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState<EntEmployeeType[]>([]);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	const { school } = useAppSelector((state) => state.company);

	const getUsers = async () => {
		setLoading(true);
		try {
			const data = await queryUsers();
			setUsers(data);
			setLoading(false);
		} catch {
			Error("Une erreur est survenue");
		}
	};

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<div>
			<div className="mt-sm flex justify-between">
				<InlineButton
					disabled={loading}
					className="mb-sm"
					label="Retour"
					icon={arrowBackOutline}
					onClick={() => {
						navigate(`/company?${school?.subdomain || ""}`);
					}}
				/>
				<ButtonPrimary
					label="Ajouter un utilisateur"
					onClick={() => {
						setShowModal(true);
					}}
				/>
			</div>
			<TableView userList={users} loading={loading} />
			<Modal
				show={showModal}
				onClose={() => {
					setShowModal(false);
				}}
				title="Ajouter un utilisateur"
				body={
					<div>
						<p>
							Inviter une personne à rejoindre l’espace [nom entreprise] de{" "}
							{school!.name}.
						</p>
						<Formik
							onSubmit={() => {
								setShowModal(false);
								success("Mail envoyé");
							}}
							initialValues={{ firstname: "", lastname: "", email: "" }}
							validateOnChange={false}
							validate={(values) => {
								const errors: {
									firstname?: string;
									lastname?: string;
									email?: string;
								} = {};
								if (!values.firstname || !values.firstname.length) {
									errors.firstname = "Veuillez entrer un prénom";
								}
								if (!values.lastname || !values.lastname.length) {
									errors.lastname = "Veuillez entrer un nom";
								}
								if (!values.email || !checkEmail(values.email)) {
									errors.email = "Veuillez entrer une adresse email valide";
								}
								return errors;
							}}
						>
							{({ values, errors, handleSubmit, handleChange }) => {
								return (
									<div>
										<div className="my-sm flex gap-sm">
											<Input
												errorMessage={errors.lastname}
												className="w-full"
												label="Nom"
												name="lastname"
												value={values.lastname}
												onChange={handleChange}
												placeholder="Nom"
											/>
											<Input
												errorMessage={errors.firstname}
												className="w-full"
												label="Prénom"
												name="firstname"
												onChange={handleChange}
												value={values.firstname}
												placeholder="Prénom"
											/>
										</div>

										<Input
											errorMessage={errors.email}
											label="Email"
											name="email"
											value={values.email}
											onChange={handleChange}
											placeholder="Email"
										/>
										<ButtonPrimary
											className="ml-auto mt-md"
											label="Inviter"
											onClick={() => {
												handleSubmit();
											}}
										/>
									</div>
								);
							}}
						</Formik>
					</div>
				}
			/>
		</div>
	);
}
