import type { SchoolOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";

import {
	arrowBackFill,
	chevronLeftOutline,
	chevronRightOutline,
} from "@assets/Icons";

import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";

import {
	clearDataFetched,
	selectNextOfferToEdit,
	selectPreviousOfferToEdit,
} from "../../core/store/offersManagement.slice";

function OfferDetailsHeader() {
	const [params] = useSearchParams();
	const dispatchEvent = useDispatch();
	const { offerToEdit, filteredOffersList } = useAppSelector(
		(state) => state.offers,
	);
	const getIndexOfOfferToEdit = () => {
		if (!offerToEdit || !filteredOffersList) return 0;
		return filteredOffersList.findIndex(
			(offer: SchoolOfferType) => offer.id === offerToEdit.id,
		);
	};
	const handleNavigateToNextOffer = () => {
		dispatchEvent(selectNextOfferToEdit());
	};
	const handleNavigateToPreviousOffer = () => {
		dispatchEvent(selectPreviousOfferToEdit());
	};

	const getu = () => {
		const u = params.get("u");
		if (u) {
			return u;
		}
		return "/offers";
	};

	return (
		<section
			className="flex min-h-[72px] items-center justify-start gap-sm rounded-lg bg-white p-sm"
			data-testid="offerDetailsHeader"
		>
			<NavLink to={getu()}>
				<ButtonQuinary
					icon={arrowBackFill}
					onClick={() => dispatchEvent(clearDataFetched())}
				/>
			</NavLink>
			<div className="flex items-center justify-start gap-2">
				<p className="text-sm font-bold text-primary-700P">
					Offre {offerToEdit?.job}
				</p>
				<p className="text-sm font-bold text-primary-300">
					{" "}
					- {offerToEdit?.crmCompany?.name}
				</p>
			</div>
			{filteredOffersList?.length > 1 && (
				<div className="flex w-full flex-1 items-center justify-end gap-xsm">
					<ButtonQuinary
						icon={chevronLeftOutline}
						onClick={handleNavigateToPreviousOffer}
						disabled={getIndexOfOfferToEdit() === 0}
						iconColor={`${
							getIndexOfOfferToEdit() === 0
								? "text-primary-300"
								: "text-primary-500"
						}`}
					/>
					<p className="text-xxsm font-bold text-primary-300">
						{getIndexOfOfferToEdit() + 1}/{filteredOffersList?.length}
					</p>
					<ButtonQuinary
						icon={chevronRightOutline}
						onClick={handleNavigateToNextOffer}
						disabled={
							getIndexOfOfferToEdit() === filteredOffersList?.length - 1
						}
						iconColor={`${
							getIndexOfOfferToEdit() === filteredOffersList?.length - 1
								? "text-primary-300"
								: "text-primary-500"
						}`}
					/>
				</div>
			)}
		</section>
	);
}

export default OfferDetailsHeader;
