import type {
	FormationType,
	ProfessionalExperienceType,
	ProjectType,
	UserType,
} from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import { plusOutline } from "@assets/Icons";

import { CONTRACT_TYPE_OPTIONS } from "@constants/Offers";

import { formatMonthYear } from "@tools/Dates";

import { InlineButton } from "@designSystem/atoms/InlineButton";
import { CardSecondary } from "@designSystem/molecules/CardSecondary";
import { errorToast, success } from "@tools/Toasts";

import { getCareerProfile, manageCareer } from "../DashboardAPI";

import { ExperienceEditorModal } from "./MyCareer/ExperienceEditorModal";
import { FormationEditorModal } from "./MyCareer/FormationEditorModal";
import { MyCareerSkeleton } from "./MyCareer/MyCareerSkeleton";
import { ProjectEditorModal } from "./MyCareer/ProjectEditorModal";

type CareerType = ProfessionalExperienceType | FormationType | ProjectType;

type CareerSectionType = {
	title: string;
	buttonLabel: string;
	type: string;
	items: {
		title: string;
		subtitle?: string;
		secondTitle?: string;
		body: string;
		value: CareerType;
	}[];
}[];

export function MyCareer() {
	const { user } = useContext(GlobalContext);
	const [profile, setProfile] = useState<UserType>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [careerSection, setCareerSection] = useState<CareerSectionType>([]);
	const [openModal, setOpenModal] = useState("");
	const [modalItem, setModalItem] = useState<any>();

	const editCareer = async (
		type: string,
		operation: string,
		values: CareerType,
	) => {
		const completeOperation = `${operation}${type
			.charAt(0)
			.toUpperCase()}${type.slice(1)}`;
		const typePlural = `${type}s`;

		try {
			if (operation === "create") delete values.id;

			const response: any = await manageCareer(completeOperation, type, values);

			const profileCopy: any = { ...profile };
			const collection = profileCopy[typePlural].collection;

			if (operation === "delete") {
				const index = collection.findIndex(
					(item: CareerType) =>
						item.id === response[completeOperation][type].id,
				);
				collection.splice(index, 1);
			} else if (operation === "update") {
				const index = collection.findIndex(
					(item: CareerType) =>
						item.id === response[completeOperation][type].id,
				);
				collection[index] = {
					id: response[completeOperation][type].id,
					...values,
				};
			}

			if (operation === "create") {
				collection.push({
					id: response[completeOperation][type].id,
					...values,
				});
			}

			setProfile({ ...profileCopy, [typePlural]: { collection } });
			success("Opération effectuée avec succès");
		} catch (_e) {
			errorToast("Une erreur est survenue");
		}
	};

	const getCareer = () => {
		if (!profile) return;
		setCareerSection([
			{
				title: "Expérience professionnelle",
				buttonLabel: "Ajouter une expérience",
				type: "experience",
				items: profile.professionalExperiences.collection?.map((exp) => {
					return {
						title: exp.job,
						secondTitle: `${exp.organizationV2?.name} (${
							CONTRACT_TYPE_OPTIONS.find(
								(contract) => contract.value === exp.contract,
							)?.label
						})`,
						subtitle: `${formatMonthYear(exp.startDate)} - ${
							exp.endDate ? formatMonthYear(exp.endDate) : "Aujourd'hui"
						}`,
						body: exp.detail,
						value: exp,
					};
				}),
			},
			{
				title: "Formation",
				buttonLabel: "Ajouter une formation",
				type: "formation",
				items: profile.formations.collection?.map((formation) => {
					return {
						title: formation?.name,
						secondTitle: `${formation.schoolName} (${formation.city})`,
						subtitle: `${formatMonthYear(formation.startDate)} - ${
							formation.endDate
								? formatMonthYear(formation.endDate)
								: "Aujourd'hui"
						}`,
						body: formation.detail,
						value: formation,
					};
				}),
			},
			{
				title: "Projet",
				buttonLabel: "Ajouter un projet",
				type: "project",
				items: profile.projects.collection?.map((project) => {
					return {
						title: project?.name,
						body: project.detail,
						value: project,
					};
				}),
			},
		]);
	};

	const fetchData = async () => {
		if (!user) return;
		const response = await getCareerProfile(user.id);
		setProfile(response);
		setIsLoading(false);
	};

	useEffect(() => {
		getCareer();
	}, [profile]);

	useEffect(() => {
		fetchData();
	}, []);

	if (isLoading) return <MyCareerSkeleton />;

	return (
		<div className="flex flex-col gap-lg">
			{careerSection?.map((category) => {
				return (
					<div className="flex flex-col gap-sm">
						<p className="font-bold text-[18px]">{category.title}</p>
						{category.items?.map((item) => {
							return (
								<CardSecondary
									title={item.title}
									subtitle={item.subtitle}
									secondTitle={item.secondTitle}
									body={item.body}
									onClick={() => {
										setOpenModal(category.type);
										setModalItem(item.value);
									}}
								/>
							);
						})}
						<InlineButton
							id="add-experience"
							label={category.buttonLabel}
							icon={plusOutline}
							onClick={() => {
								setOpenModal(category.type);
								setModalItem(null);
							}}
						/>
					</div>
				);
			})}
			<ExperienceEditorModal
				show={openModal === "experience"}
				onClose={() => setOpenModal("")}
				experience={modalItem}
				editCareer={editCareer}
			/>
			<FormationEditorModal
				show={openModal === "formation"}
				onClose={() => setOpenModal("")}
				formation={modalItem}
				editCareer={editCareer}
			/>

			<ProjectEditorModal
				show={openModal === "project"}
				onClose={() => setOpenModal("")}
				project={modalItem}
				editCareer={editCareer}
			/>
		</div>
	);
}
