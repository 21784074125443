import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { arrowBackOutline, emailOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";

import { mailChangePassword } from "@containers/auth/ForgotPassword/ForgotPasswordAPI";

import { checkMicrosoftEmail } from "@tools/Checkers";

export function ForgotPassword({ isMicrosoftLoginState }) {
	const [email, setEmail] = useState(
		localStorage.getItem("email") ? localStorage.getItem("email") : "",
	);
	const { setIsMicrosoftLogin } = isMicrosoftLoginState;
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);

	const inputs = {
		email: setEmail,
	};

	useEffect(() => {
		document.title = "Mot de passe oublié ? - Mentor Goal";
	}, []);

	useEffect(() => {
		checkMicrosoftEmail(email, setIsMicrosoftLogin, navigate);
	}, [email]);

	const handleOnChange = (e) => {
		inputs[e.target.name](e.target.value);
	};

	const sendMail = async () => {
		mailChangePassword(email);
	};

	return (
		<div className="forgot-password ml-0 w-full md:ml-[8%] lg:w-11/12">
			<NavLink
				to={{
					pathname: "/",
					state: {
						email,
					},
				}}
			>
				<ButtonQuinary
					className="back-icon mb-md cursor-pointer text-primary-700P"
					icon={arrowBackOutline}
				/>
			</NavLink>
			{validate ? (
				<div className="forgot-password ml-0 w-full md:ml-[8%] lg:w-11/12">
					<h2 className="text-primary-900">E-mail envoyé !</h2>
					<div className="ml-0 w-full px-6 md:w-8/12 md:px-0">
						<p className="mb-md mt-md  text-center text-xxsm text-primary-300 md:text-left">
							Consultez votre boite mail et suivez les instructions de
							réinitialisation.
						</p>
					</div>
				</div>
			) : (
				<>
					<h2 className="!font-semibold text-primary-900">
						Mot de passe oublié ?
					</h2>
					<div className="ml-0 w-full px-6 md:px-0 lg:w-8/12">
						<p className="mb-md mt-md  text-center text-xxsm text-primary-300 md:text-left">
							Entrez votre adresse e-mail ci-dessous pour débuter la
							réinitialisation du mot de passe.
						</p>
						<InputLargePrimary
							onChange={handleOnChange}
							value={email}
							name="email"
							type="text"
							icon={emailOutline}
							placeholder="Adresse e-mail"
						/>
						<ButtonPrimary
							onClick={() => {
								setValidate(true);
								sendMail();
							}}
							className="mt-md w-full"
							disabled={email === ""}
							label="Envoyer"
						/>
					</div>
				</>
			)}
		</div>
	);
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};
