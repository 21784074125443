import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router";

import { Spinner } from "@designSystem/atoms/Spinner";

import { errorToast } from "@tools/Toasts";

import { alertTriangleOutline } from "@assets/Icons";
import { StudentListTitle } from "@containers/public/Selection/SelectionStudentDetails";
import { SelectionHeaderTitle } from "@containers/public/Selection/ui/SelectionHeaderTitle";
import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import type { Referent, School, User } from "./SelectionAPI";
import { querySelection } from "./SelectionAPI";
import { ContactCard } from "./ui/ContactCard";
import { StudentCard } from "./ui/StudentCard";

export const Selection = () => {
	const [students, setStudents] = useState<User[]>([]);
	const [offset, setOffset] = useState(0);
	const [total, setTotal] = useState(0);
	const [referent, setReferent] = useState<Referent | undefined>();
	const [school, setSchool] = useState<School | undefined>();
	const [selectionName, setSelectionName] = useState("Sélection de CV");
	const [load, setLoad] = useState(true);
	const [searchLoad] = useState(false);
	const token = window.location.pathname.split("/")[3];
	const [companyName, setCompanyName] = useState("");

	const navigate = useNavigate();

	const getSelection = async () => {
		try {
			const { school, referent, users, name, totalCount, company } =
				await querySelection(token, 0);
			setSchool(school);
			setReferent(referent);
			setSelectionName(name);
			setCompanyName(company);
			setTotal(totalCount);
			setStudents([...users]);
			setLoad(false);
		} catch (e) {
			if (e.response.status === 404) {
				navigate("/selection/404");
			}
			errorToast("une erreur est survenue");
		}
	};

	const getMore = async () => {
		try {
			const newOffset = offset + 10;
			const { users } = await querySelection(token, newOffset);
			setStudents([...students, ...users]);
			setOffset(newOffset);
		} catch {
			errorToast("une erreur est survenue");
		}
	};

	const { user } = useContext(GlobalContext);
	useEffect(() => {
		getSelection();
	}, []);
	return (
		<div className="flex h-full min-h-[100vh] w-[100vw] max-w-[100%] justify-center bg-no-repeat bg-bottom">
			{load ? (
				<Spinner className="self-center" />
			) : (
				<div className="flex w-full flex-col items-center">
					{user && <PreVisualisationMode />}
					<SelectionHeaderTitle
						companyName={companyName}
						selectionName={selectionName}
					/>
					<div className="flex w-full max-w-[1400px] flex-col gap-lg px-10">
						{referent && school && (
							<div className="flex w-full justify-center gap-lg">
								<ContactCard referent={referent} school={school} />
							</div>
						)}
						<StudentListTitle total={total} />
						{!searchLoad ? (
							<>
								<div
									id="user-list"
									className="flex flex-wrap gap-md text-primary-700P md:overflow-y-auto"
								>
									{students.map((student, index) => (
										<StudentCard key={index} student={student} />
									))}
								</div>
								<InfiniteScroll
									loadMore={() => {
										if (students.length < total) {
											getMore();
										}
									}}
									hasMore={students.length < total}
									loader={
										<div className="mt-sm text-center" key={0}>
											<Spinner />
										</div>
									}
								/>
							</>
						) : (
							<div className="mt-sm text-center" key={0}>
								<Spinner />
							</div>
						)}

						<p className="text-center text-xsm pb-8">
							Propulsé par{" "}
							<a
								href={"https://mentorgoal.com"}
								target="_blank"
								rel="noreferrer"
							>
								<b>Mentor Goal</b>
							</a>
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export const PreVisualisationMode = () => (
	<p className="flex items-center justify-center bg-alert-error-100 text-alert-error-500 text-center w-full py-2 gap-xsm">
		<Icon icon={alertTriangleOutline} />
		Prévisualisation
	</p>
);
