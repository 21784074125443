import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import "../ApplicationDetails.scss";

import {
	arrowBackFill,
	checkmarkFill,
	closeFill,
	heartFill,
	heartOutline,
	lockOutline,
	moreHorizontalFill,
} from "@assets/Icons";

import { getColumnIndex, getStepByIndex } from "@tools/Applications";
import { bigConfetti } from "@tools/Confetti";

import { ToggleButtonQuaternary } from "@containers/student/Applications/ApplicationDetail/ui/ToggleButtonQuaternary";
import { Badge } from "@designSystem/atoms/Badge";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Dropdown } from "@designSystem/molecules/Dropdown";
import { Stepper } from "@designSystem/molecules/Stepper";
import { CompanyName } from "@designSystem/organisms/company/CompanyName";
import { success } from "@tools/Toasts";

import { editApplication } from "@containers/student/Applications/core/api/ApplicationDetailAPI";

import { DeleteModal } from "./Modals/DeleteModal";

import "react-loading-skeleton/dist/skeleton.css";

export function Head({ applicationProps, loading }) {
	const navigate = useNavigate();

	const { application, setApplication } = applicationProps;
	const [userAction, setUserAction] = useState(null);
	const [currentStep, setCurrentStep] = useState(2);
	const [displayModalDelete, setDisplayModalDelete] = useState(false);

	const [favorite, setFavorite] = useState(application?.favorite);

	const getDateApp = (applicationCopy) => {
		switch (applicationCopy.step) {
			case "interview":
				return { interviewDate: moment().format() };
			case "relaunch":
				return { relaunchDate: moment().format() };
			case "sent":
				return { sentDate: moment().format() };
			default:
				return null;
		}
	};

	useEffect(() => {
		setCurrentStep(getColumnIndex(application.step));
		setFavorite(application?.favorite);
		if (application?.accepted) {
			setUserAction("accept");
		} else if (application?.accepted === false) {
			setUserAction("decline");
		}
	}, [application, applicationProps, loading]);

	const dropdownItems = [
		{
			label: "Supprimer la candidature",
			action: () => {
				setDisplayModalDelete(true);
			},
		},
		{
			label: application.archived
				? "Restaurer la candidature"
				: "Archiver la candidature ",
			action: async () => {
				await editApplication({
					id: application.id,
					archived: !application.archived,
				}).then(() => {
					success("Modification enregistrée !");
				});
				navigate("/applications");
			},
		},
	];

	return (
		<div className="rounded-lg bg-white p-4">
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<ButtonQuinary
						onClick={() => {
							navigate("/applications");
						}}
						icon={arrowBackFill}
						className="mr-xsm"
					/>
					{loading ? (
						<Skeleton height={15} width={400} />
					) : (
						<div className="wg-no-translate company-job flex items-center">
							<p className="mr-xxsm text-sm font-bold text-primary-700P">
								{application?.job}
							</p>
							<CompanyName
								nameToDisplay={application?.organization?.name}
								nameColor="text-primary-300"
								additionalClass="mr-sm font-bold"
							>
								<span>- </span>
							</CompanyName>
						</div>
					)}
					<ToggleButtonQuaternary
						active={favorite}
						activeIcon={heartFill}
						icon={heartOutline}
						className="small ml-sm-sm"
						onClick={() => {
							setFavorite(!favorite);
							editApplication({
								id: application.id,
								favorite: !favorite,
							}).then(() => {
								success("Modification enregistrée !");
							});
						}}
					/>
				</div>
				<div className="alig-items-center user-action flex">
					<div
						className={`btn-container flex items-center ${
							userAction === "decline" && "can-hover"
						}`}
					>
						{userAction === "accept" && (
							<span className="flex">
								<Badge
									label="Embauché(e)"
									className="no-pointer mr-sm"
									rightIcon={checkmarkFill}
									bgColor="bg-success-light"
									hasBorder
								/>
							</span>
						)}
						{userAction === "decline" && (
							<span className="flex">
								<Badge
									label="Candidature refusée"
									className="no-pointer mr-sm"
									rightIcon={closeFill}
									bgColor="bg-error-light"
									hasBorder
								/>
							</span>
						)}

						<ButtonQuinary
							className={`mr-xsm ${!userAction && "hidden"}`}
							icon={lockOutline}
							onClick={() => {
								setUserAction(null);
								editApplication({ id: application.id, accepted: null }).then(
									() => {
										success("Modification enregistrée !");
									},
								);
							}}
						/>

						<ButtonSecondary
							onClick={() => {
								setUserAction("accept");
								bigConfetti();
								editApplication({
									id: application.id,
									accepted: true,
								}).then(() => {
									success("Modification enregistrée !");
								});
							}}
							label="Embauché(e)"
							className={`accept-button mr-sm ${
								userAction === "accept" || userAction === "decline"
									? "hidden"
									: "block"
							}`}
						/>
						<ButtonSecondary
							onClick={() => {
								setUserAction("decline");
								editApplication({ id: application.id, accepted: false }).then(
									() => {
										success("Modification enregistrée !");
									},
								);
							}}
							label="Refusée"
							className={`decline-button mr-sm ${
								userAction === "accept" || userAction === "decline"
									? "hidden"
									: "block"
							}`}
						/>
					</div>
					<Dropdown
						direction="left"
						button={<ButtonQuinary dropdownButton icon={moreHorizontalFill} />}
						items={dropdownItems}
					/>
				</div>
			</div>

			{loading ? (
				<Skeleton className="mt-sm" height={28} />
			) : (
				<Stepper
					indexActive={currentStep}
					onClickOnStep={(index) => {
						const applicationCopy = { ...application };
						applicationCopy.step = getStepByIndex(index);

						editApplication({
							id: applicationCopy.id,
							step: applicationCopy.step,
							...getDateApp(applicationCopy),
						}).then(() => {
							success("Modification enregistrée !");
						});
						setApplication(applicationCopy);
						setCurrentStep(index);
						if (index === 3) {
							bigConfetti();
						}
					}}
					steps={[
						{ label: "Candidature en préparation" },
						{ label: "Candidature envoyée" },
						{ label: "Relance réalisée" },
						{ label: "Entretien obtenu" },
					]}
					color={currentStep + 1}
					className="mt-sm"
				/>
			)}

			<DeleteModal
				show={displayModalDelete}
				onClose={() => {
					setDisplayModalDelete(false);
				}}
				applicationProps={applicationProps}
			/>
		</div>
	);
}
Head.propTypes = {
	loading: PropTypes.bool.isRequired,
	applicationProps: PropTypes.shape({
		application: PropTypes.shape({
			id: PropTypes.string,
			archived: PropTypes.bool,
			favorite: PropTypes.bool,
			accepted: PropTypes.bool,
			organization: PropTypes.shape({
				name: PropTypes.string,
			}),
			step: PropTypes.string,
			job: PropTypes.string,
		}),
		setApplication: PropTypes.func,
	}).isRequired,
};
