import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";

import {
	getCampusDashboardData,
	getPromotionDashboardData,
	getSchoolDashboardData,
} from "@containers/school/SchoolDashboard/DashboardAPI";
import AnnouncementsModal from "@containers/shared/AnnoncementsModal/index";

import { DashboardLoader } from "./ui/DashboardLoader";
import { StudentsActionsEssentialsInNumbers } from "./ui/StudentsActionsEssentialsInNumbers";
import { StudentsDistributionByLastConnexion } from "./ui/StudentsDistributionByLastConnexion";
import { StudentsDistributionByStatus } from "./ui/StudentsDistributionByStatus";
import { StudentEssentialsByNumbers } from "./ui/StudentsEssentialsInNumbers";

export const formatPromotionForSelect = (promotions) => {
	const array = [];

	promotions.forEach((promo) => {
		array.push({
			id: promo.id,
			label: promo.name,
			value: promo.id,
		});
	});

	return array;
};

export function SchoolDashboard() {
	const { user, setUser } = useContext(GlobalContext);
	const { structureView, setTitle } = useOutletContext();
	const [structure, setStructure] = useState({});
	const [load, setLoad] = useState(true);
	const [currentPromo, setCurrentPromo] = useState(null);
	const [promotions, setPromotions] = useState([]);
	const [displayAnnoncements, setDisplayAnnoncements] = useState(true);

	const getDashboardData = async () => {
		setLoad(true);
		if (Object.keys(structureView)[0] === "school") {
			getSchoolDashboardData(structureView).then(({ school }) => {
				setStructure(school);
				setPromotions([
					{ label: "Toutes les promotions", value: null },
					...formatPromotionForSelect(school.promotions.collection),
				]);
				setLoad(false);
			});
		} else if (Object.keys(structureView)[0] === "campus") {
			await getCampusDashboardData(structureView).then(({ campus }) => {
				setStructure(campus);
				setPromotions([
					{ label: "Toutes les promotions", value: null },
					...formatPromotionForSelect(campus.promotions.collection),
				]);
				setLoad(false);
			});
		}
	};

	const updateDashboardData = async (promotionURI) => {
		setLoad(true);

		getPromotionDashboardData(promotionURI).then(({ promotion }) => {
			setStructure(promotion);
			setLoad(false);
		});
	};

	useEffect(() => {
		setTitle(`Bienvenue sur votre espace ${user.schoolName}`);
		getDashboardData();
		if (user?.hasSeenNews !== undefined) {
			setDisplayAnnoncements(!user?.hasSeenNews);
		}
	}, [structureView]);

	return (
		<main className="overflow-x-hidden">
			{load ? (
				<DashboardLoader />
			) : (
				<div>
					<SelectSecondary
						placeholder={currentPromo || "Filtrer par promotion"}
						options={promotions}
						className="w-fit-content mb-lg min-w-[190px]"
						onChange={(promo) => {
							if (promo.value) {
								updateDashboardData(promo.id);
							} else {
								getDashboardData();
							}
							setCurrentPromo(promo.label);
						}}
						fitContent
					/>
					<StudentEssentialsByNumbers structure={structure} />
					<StudentsActionsEssentialsInNumbers structure={structure} />
					<div className="mt-xlg flex flex-col md:flex-row">
						<StudentsDistributionByStatus structure={structure} />
						<StudentsDistributionByLastConnexion structure={structure} />
					</div>
					<AnnouncementsModal
						show={displayAnnoncements}
						onClose={() => {
							setUser({
								...user,
								hasSeenNews: true,
							});
							setDisplayAnnoncements(false);
						}}
						link="https://armand9110.softr.app/"
					/>
				</div>
			)}
		</main>
	);
}
