import type { SchoolType } from "@/types/schoolTypes";
import { login } from "@containers/auth/Login/LoginAPI";
import { LoginForm } from "@containers/auth/LoginCompany/ui/LoginForm";
import { getNoSchoolDataApi } from "@containers/company/Layout/LayoutAPI";
import { SchoolCard } from "@containers/company/Layout/NoSchool/ui/SchoolCard";
import { SchoolCardLoader } from "@containers/company/Layout/NoSchool/ui/SchoolCardLoader";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";
import { GlobalContext } from "@navigation/Router";
import { errorToast } from "@tools/Toasts";
import { extractIdNumberFromId } from "@tools/Users";
import React, { useContext, useEffect, useState } from "react";

export function NoSchool() {
	const { user } = useContext(GlobalContext);

	const [schoolsLoading, setSchoolsLoading] = useState<boolean>(false);
	const [schools, setSchools] = useState<Partial<SchoolType>[]>([]);
	const [noEmployee, setNoEmployee] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [hasErrors, setHasErrors] = useState<boolean>(false);

	useEffect(() => {
		const getProfiles = async () => {
			if (!user) return;
			setSchoolsLoading(true);
			try {
				const schoolsData = await getNoSchoolDataApi(
					extractIdNumberFromId(user.id),
				);
				if (schoolsData.data) {
					setSchools(schoolsData.data);
				} else {
					setNoEmployee(true);
				}
				setSchoolsLoading(false);
			} catch {
				errorToast(
					"Une erreur est survenue lors de la récupération des écoles",
				);
			}
		};
		getProfiles();
	}, [user]);

	return user ? (
		<>
			<WhiteCard
				className="flex w-full flex-col mt-sm"
				title="Les écoles déjà contactées"
				content={
					schoolsLoading ? (
						[1, 2, 3].map((num) => <SchoolCardLoader key={`loader-${num}`} />)
					) : noEmployee ? (
						<p>Vous n'avez pas de profil employé.</p>
					) : schools.length === 0 ? (
						<p>Aucune école trouvée</p>
					) : (
						schools.map((school) => (
							<SchoolCard key={school.id} school={school} />
						))
					)
				}
			/>
		</>
	) : (
		<LoginForm
			loading={loading}
			setLoading={setLoading}
			hasErrors={hasErrors}
			setHasErrors={setHasErrors}
			onSubmit={login}
			showCreateAccount={false}
		/>
	);
}
