import React, { useState } from "react";

import { Tag } from "@designSystem/atoms/Tag";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { Table } from "@designSystem/templates/table/Table";

import type { TagType } from "@/types/tagTypes";
import { ModifyTagModal } from "./TableView/ModifyTagModal";

type TableViewProps = {
	load: boolean;
	tags: any[];
	tab: string;
	lastPage: number;
	filtersProps: { filters: any; setFilters: Function };
	getData: Function;
};

const EMPTY_TAG: TagType = {
	id: "",
	name: "",
	type: "school",
	color: "bg-primary-100",
	description: "",
};

export function TableView({
	load,
	tags,
	tab,
	lastPage,
	filtersProps,
	getData,
}: TableViewProps) {
	const [currentTag, setCurrentTag] = useState<TagType>(EMPTY_TAG);
	const [displayModifyTag, setDisplayModifyTag] = useState<boolean>(false);
	const { filters, setFilters } = filtersProps;
	const headers = [
		{
			label: "TAG",
			sort: { fieldName: "name", defaultOrder: "ASC" },
		},
		{
			label: "DESCRIPTION DU TAG",
			sort: { fieldName: "description", defaultOrder: "ASC" },
		},
		{
			label: "TYPES DE COMPÉTENCE",
			tab: "skillsTags",
			sort: { fieldName: "type", defaultOrder: "ASC" },
		},
	].filter((header) => {
		return header.tab === tab || header.tab === undefined;
	});

	const tagType = (tag: { type: string }) => {
		switch (tag.type) {
			case "hardSkill":
				return "Hard Skill";
			case "softSkill":
				return "Soft Skill";
			default:
				return "Non défini";
		}
	};

	const cellsResolver = (tag: TagType) => {
		return [
			{
				children: <Tag label={tag.name} bgColor={tag.color} />,
				tabs: ["generalTags", "skillsTags"],
			},
			{
				children: <span className="wg-no-translate">{tag.description}</span>,
				tabs: ["generalTags", "skillsTags"],
			},
			{
				children: tagType(tag),
				tabs: ["skillsTags"],
			},
		].filter((cell) => {
			return cell.tabs.includes(tab);
		});
	};

	const getEmptyMessage = () => {
		if (tab === "skillsTags") {
			return "Aucun tag de compétence trouvé.";
		}
		return "Aucun tag général trouvé.";
	};

	return (
		<>
			<Table
				isLoading={load}
				emptyMessage={tags.length === 0 && getEmptyMessage()}
				className="mt-md"
				headers={headers}
				filtersProps={{ filters, setFilters }}
				rows={tags.map((tag) => {
					return {
						onClick: () => {
							setDisplayModifyTag(!displayModifyTag);
							setCurrentTag(tag);
						},
						cells: cellsResolver(tag),
					};
				})}
			/>
			<PaginationPrimary
				onChange={(page) => {
					setFilters({ ...filters, page });
				}}
				current={filters.page}
				className="pagination-list flex items-center justify-center"
				last={lastPage}
			/>

			<ModifyTagModal
				show={displayModifyTag}
				tag={currentTag}
				filtersProps={filtersProps}
				onClose={() => {
					setDisplayModifyTag(!displayModifyTag);
				}}
				getData={getData}
			/>
		</>
	);
}
