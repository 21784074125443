import type { studentSelectedType } from "@/types/mailbox";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Spinner } from "@designSystem/atoms/Spinner";

import {
	getConversation,
	getStudentConversation,
} from "../controller/mailBox.request";
import { setMessages, setStudentSelected } from "../controller/mailBox.slice";

import { errorToast } from "@tools/Toasts";
import MessageEditor from "./MessageEditor";
import ChatContent from "./component/ChatContent";
import ChatHead from "./component/ChatHead";

type ChatType = { className?: string };
export default function Chat({ className }: ChatType) {
	const dispatchMailBox = useDispatch();
	const ChatRef = useRef(null);
	const { user, currentInterface } = useContext(GlobalContext);

	const { studentSelected, loading, messages } = useAppSelector(
		(state) => state.mailBox,
	);

	const [loadConversation, setLoadConversation] = useState<boolean>(false);

	const getConversationWithReferent = async () => {
		setLoadConversation(true);
		if (user && typeof user?.id === "string") {
			const { data } = await getStudentConversation(user.id);

			if (data) {
				dispatchMailBox(setStudentSelected(data.user));
				dispatchMailBox(
					setMessages(data.user.conversation?.spsMessages.collection),
				);
			}
		} else {
			errorToast("Un erreur est survenue.");
		}
		setLoadConversation(false);
	};
	const getConversationWithStudent = async (student: studentSelectedType) => {
		if (!student?.conversation) return dispatchMailBox(setMessages([]));
		setLoadConversation(true);
		const { data } = await getConversation(student.conversation.id);
		if (data) {
			const newMessages = data.spsConversation?.spsMessages.collection;
			if (newMessages.length !== messages.length) {
				dispatchMailBox(setMessages(newMessages));
			}
		}

		return setLoadConversation(false);
	};
	useEffect(() => {
		if (currentInterface === "school" && studentSelected?.id) {
			getConversationWithStudent(studentSelected);
		}
	}, [studentSelected]);

	useEffect(() => {
		if (currentInterface === "student") {
			getConversationWithReferent();
		}
	}, [currentInterface]);

	useEffect(() => {
		const crispElement = document.getElementById("crisp-chatbox");
		const panel = document.querySelector('[id*="panel"]');
		if (crispElement) {
			const bodyElement = document.getElementsByTagName("body")[0];
			bodyElement.style.overflow = panel ? "hidden" : "";

			crispElement.classList.toggle("cc-tlyw", !panel);
			crispElement.classList.toggle("!hidden", !!panel);
		}
	}, []);
	return (
		<div
			ref={ChatRef}
			className={`m-0 flex max-h-[81vh] rounded-lg w-full flex-col bg-white py-md px-lg ${className}`}
		>
			{!studentSelected || !studentSelected?.conversation ? (
				<>
					{loading ? (
						<div className="margin-auto flex items-center h-full justify-center">
							<Spinner />
						</div>
					) : (
						<>
							<hr className="separator-menu-item my-sm" />
							<div className="h-full">
								<p className="text-center text-primary-700P pt-xxlg">
									{studentSelected?.conversation !== null
										? "Aucune conversation sélectionné"
										: "Une erreur est survenue"}
								</p>
							</div>
						</>
					)}
				</>
			) : (
				<>
					<ChatHead />
					<ChatContent loadConversation={loadConversation} />
					<MessageEditor />
				</>
			)}
		</div>
	);
}
