import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";

import { infoOutline } from "@assets/Icons";

import { getDefaultEventIllustration } from "@tools/Events";

import { ImageUploader } from "@containers/school/Events/ui/creation/ImageUploader";
import { CheckboxLabelPrimary } from "@designSystem/molecules/CheckboxLabel";
import { FilesPrimary } from "@designSystem/molecules/Files";

import { useAppDispatch } from "@config/redux/store";
import { uploadEventIllustrationRequest } from "@containers/shared/Events/core/api/events.request";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";

const ILLUSTRATION_MAX_SIZE = 1;
const DEFAULT_ILLUSTRATIONS_SELECTED = "yellow";
const DEFAULT_ILLUSTRATIONS = [
	{ label: "Jaune", value: "yellow" },
	{ label: "Bleu", value: "blue" },
	{ label: "Vert", value: "green" },
	{ label: "Orange", value: "orange" },
	{ label: "Rouge", value: "red" },
];
const BASE_MEDIA_URL = `${import.meta.env.VITE_S3}`;

export function EventImageSelection() {
	const eventToCreate = useAppSelector((state) => state.events.eventToCreate);
	const dispatchEvent = useAppDispatch();
	const actions = eventsSlice.actions;

	const [isIllustrationLoading, setIsIllustrationLoading] =
		useState<boolean>(false);
	const [uploadError, setUploadError] = useState<string>("");

	const handleDefaultIllustrationSelection = (illustrationSelected: string) => {
		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				defaultIllustration: illustrationSelected,
			}),
		);
	};

	const handleCustomIllustrationUploaded = async (illustration: any) => {
		setIsIllustrationLoading(true);
		const data = new FormData();
		data.append("file", illustration);
		const mediaUploaded: MediaObjectType =
			(await uploadEventIllustrationRequest(data)) as MediaObjectType;
		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				illustration: mediaUploaded,
			}),
		);

		setIsIllustrationLoading(false);
	};

	const getIsDefaultIllustration = (illustration: string) => {
		if (!eventToCreate?.defaultIllustration) {
			return illustration === DEFAULT_ILLUSTRATIONS_SELECTED;
		}
		return eventToCreate?.defaultIllustration === illustration;
	};

	const renderDefaultIllustrations = (
		handleDefaultIllustrationSelection: (illustrationSelected: string) => void,
	) => {
		return DEFAULT_ILLUSTRATIONS.map((illustration) => (
			<CheckboxLabelPrimary
				key={illustration.value}
				checked={getIsDefaultIllustration(illustration.value)}
				label={illustration.label}
				onCheck={() => handleDefaultIllustrationSelection(illustration.value)}
				disabled={false}
			/>
		));
	};

	const handleDeleteIllustration = () => {
		dispatchEvent(
			actions.setEventToCreate({ ...eventToCreate, illustration: undefined }),
		);
	};

	const handleViewIllustration = () => {
		if (eventToCreate?.illustration) {
			window.open(
				`${import.meta.env.VITE_S3}${eventToCreate.illustration.filePath}`,
			);
		}
	};

	useEffect(() => {
		if (!eventToCreate?.defaultIllustration) {
			dispatchEvent(
				actions.setEventToCreate({
					...eventToCreate,
					defaultIllustration: DEFAULT_ILLUSTRATIONS_SELECTED,
				}),
			);
		}
	}, [dispatchEvent, eventToCreate]);

	return (
		<article
			data-testid="eventImageSectionContainer"
			className="flex w-full flex-col gap-8 sm:flex-row"
		>
			<div className="grow basis-3/4">
				<span className="mb-1 text-xxsm font-normal text-primary-300">
					Choisir une image
				</span>
				<img
					src={
						eventToCreate?.illustration
							? `${BASE_MEDIA_URL + eventToCreate.illustration.filePath}`
							: getDefaultEventIllustration(
									eventToCreate?.defaultIllustration ||
										DEFAULT_ILLUSTRATIONS_SELECTED,
								)
					}
					alt=""
					className="h-[213px] w-[443px] object-cover"
					data-testid="eventImageSelectionView"
				/>
				<div className="mt-4 flex flex-col justify-start gap-2">
					<span className="text-xxsm font-normal text-primary-300">
						Format recommandé {ILLUSTRATION_MAX_SIZE}Mo max
					</span>
					<div
						className="max-w-[225px]"
						data-testid="eventImageSelectionImport"
					>
						{eventToCreate?.illustration ? (
							<FilesPrimary
								isLoading={isIllustrationLoading}
								title={eventToCreate?.illustration.filePath}
								onView={() => {
									handleViewIllustration();
								}}
								subtitle={`${Math.round(
									eventToCreate?.illustration.size / 1000,
								)} KO`}
								onDelete={() => {
									handleDeleteIllustration();
								}}
								error={uploadError !== ""}
							/>
						) : (
							<ImageUploader
								isLoading={isIllustrationLoading}
								id="event-illustration-upload"
								onSuccess={(e: object[]) => {
									setUploadError("");
									handleCustomIllustrationUploaded(e[0]);
								}}
								maxSize={ILLUSTRATION_MAX_SIZE}
								acceptFormats={["png", "jpg", "jpeg"]}
								onError={() => {
									setUploadError("Erreur lors de l'importation de l'image");
								}}
							/>
						)}
						{uploadError !== "" && (
							<span className="text-xxsm font-normal text-error-dark">
								{uploadError}
							</span>
						)}
					</div>
				</div>
			</div>
			<div
				className="flex grow basis-1/4 flex-col gap-4"
				data-testid="eventImageSelectionOptions"
			>
				<div className="flex items-center gap-1">
					<span className="text-xxsm font-normal text-primary-300">
						Image par défaut
					</span>
					<Tippy content="Sélectionnez une image parmi nos images par défaut ou importez votre image">
						<span className="text-xsm font-normal text-primary-300">
							<Icon icon={infoOutline} className="h-[16px]" />
						</span>
					</Tippy>
				</div>
				{renderDefaultIllustrations(handleDefaultIllustrationSelection)}
			</div>
		</article>
	);
}
