import React from "react";
import { useDropzone } from "react-dropzone";

import "@assets/sass/FileInput.scss";

import { plusOutline } from "@assets/Icons";

import { stringifyFileFormats } from "@tools/Objects";

import { InlineButton } from "@designSystem/atoms/InlineButton";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";

type FileInputType = {
	id: string;
	isLoading: boolean;
	title?: string;
	acceptFormats: any;
	className?: string;
	onSuccess: Function;
	onError: Function;
	maxSize: number;
	error?: boolean;
};
export function InlineFileInput({
	id = "",
	title = "Ajouter un document",
	acceptFormats,
	className = "",
	onSuccess,
	onError,
	maxSize = 1,
	error = false,
	isLoading = false,
}: FileInputType) {
	const onDrop = (files: File[]) => {
		const goodFiles = files.filter((file: File) => {
			if (file.size / 1000000 <= maxSize) return file;
			return 0;
		});
		const badFiles = files.filter((file: File) => {
			if (file.size / 1000000 > maxSize) return file;
			return 0;
		});
		if (files.length === 0) onError(files);
		else if (badFiles.length > 0) onError(badFiles);
		else onSuccess(goodFiles);
	};

	const { getRootProps, fileRejections, getInputProps } = useDropzone({
		onDrop,
		accept: acceptFormats,
		noDrag: true,
		disabled: isLoading,
	});

	return (
		<div className={className}>
			<div
				id={id}
				data-id="fileInput"
				{...getRootProps()}
				className={`w-fit-content cursor-pointer rounded-md border-[1px] border-primary-700P p-xxsm pr-xsm ${
					error && "border-error-dark"
				} ${isLoading && "!cursor-default grayscale-[50%]"}`}
			>
				<input {...getInputProps()} />

				<span id="inlinefileinput-tooltip">
					<InlineButton
						id="inlinefileinput-button"
						onClick={() => {}}
						disabled={isLoading}
						textClasses="hover:!no-underline"
						className={error ? "text-error-dark" : ""}
						label={title}
						icon={plusOutline}
					/>
					<TooltipContainer
						anchorId="#inlinefileinput-tooltip"
						children={
							<span className="mt-xsm text-xsm">
								Taille max : {maxSize}Mo {stringifyFileFormats(acceptFormats)}
							</span>
						}
						place="top"
					/>
				</span>
			</div>
			{(fileRejections.length > 0 || error) && (
				<div className="text-p mt-xxsm text-error-dark">Fichier invalide</div>
			)}
		</div>
	);
}
