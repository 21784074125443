import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";
import { toast } from "react-toastify";

import { plusOutline } from "@assets/Icons";

import { CreateStudentModal } from "@containers/school/Students/StudentsManagement/ui/CreateStudentModal";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { UsersFilters } from "@modules/usersFilters";
import { countCurrentFilters } from "@modules/usersFilters/usersFiltersSlice";
import { ResetUserFiltersButton } from "@modules/usersFilters/widgets/ResetUserFiltersButton";
import { ToggleUserFilters } from "@modules/usersFilters/widgets/ToggleUserFilters";
import { UserfilterSearchbar } from "@modules/usersFilters/widgets/UserfilterSearchbar";
import { updateToastWithError, updateToastWithSuccess } from "@tools/Toasts";

import { EState } from "@/types/userTypes";
import { StudentsManagementContext } from "..";
import { exportStudentsCsv } from "../StudentsManagementAPI";

export function Head() {
	const dispatchEvent = useDispatch();

	const { displayNewStudent, setDisplayNewStudent } = useContext(
		StudentsManagementContext,
	);
	const { structureView }: { structureView: any } = useOutletContext();

	const { currentFilters, showFilters } = useAppSelector(
		(state) => state.usersFilters,
	);

	const exportStudents = async () => {
		const id = toast.loading("Exportation en cours...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		const filters = { ...currentFilters };
		if (filters.state === "all") {
			delete filters.state;
		}

		const response = await exportStudentsCsv(structureView, filters);
		const fileName = response?.data?.fileName;
		if (fileName) {
			updateToastWithSuccess(id as string, "Export terminé.");
			window.open(`${import.meta.env.VITE_BACK}/csv/${fileName}`, "_blank");
			return;
		}
		updateToastWithError(id as string, "Erreur lors de l'export.");
	};

	useEffect(() => {
		dispatchEvent(countCurrentFilters());
	}, []);

	return (
		<div className="flex flex-col gap-sm">
			<div className="flex justify-between">
				<div className="flex gap-sm">
					<UserfilterSearchbar />
					<ToggleUserFilters
						disabled={!showFilters}
						cannotBeOpen={
							currentFilters && currentFilters.state === EState.WAIT_APPROVAL
						}
					/>
					{currentFilters.state !== EState.WAIT_APPROVAL && (
						<ResetUserFiltersButton />
					)}
				</div>

				<div className={"flex gap-sm"}>
					<ButtonSecondary
						label="Exporter"
						onClick={() => {
							exportStudents();
						}}
					/>
					<ButtonPrimary
						icon={plusOutline}
						label={"Ajouter un étudiant" as string}
						onClick={() => {
							setDisplayNewStudent(!displayNewStudent);
						}}
					/>
				</div>
			</div>
			{showFilters && (
				<UsersFilters
					filtersProps={{ filters: currentFilters, setFilters: () => {} }}
				/>
			)}
			<CreateStudentModal
				show={displayNewStudent}
				onClose={() => {
					setDisplayNewStudent(!displayNewStudent);
				}}
			/>
		</div>
	);
}
