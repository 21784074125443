import type {
	OfferApplicationType,
	OfferTypeSelectionOption,
} from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { alertTriangleOutline } from "@assets/Icons";

import {
	DEFAULT_OFFER_TO_CREATE,
	OFFER_TYPE_DESCRIPTION_MESSAGE,
	OFFER_TYPE_SELECTION_OPTIONS,
} from "@constants/Offers";

import { CardTertiary } from "@designSystem/molecules/CardTertiary";

import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

export function OfferTypeSelectionModal({
	onChange,
}: {
	onChange?: (type: OfferApplicationType) => void;
}) {
	const dispatchEvent = useDispatch();
	const navigation = useNavigate();
	const { offerToCreate } = useAppSelector((state) => state.offers);
	const [selectionType, setSelectionType] = useState<OfferApplicationType>(
		offerToCreate?.type || "preselection",
	);

	const handleSelection = (type: OfferApplicationType) => {
		setSelectionType(type);
		dispatchEvent(modifyOfferToCreate({ type }));
		if (onChange) {
			onChange(type);
		}
	};

	const handleOpenCVTek = () => {
		navigation("/cvboard");
	};

	useEffect(() => {
		dispatchEvent(
			modifyOfferToCreate({
				type: selectionType,
				contract: DEFAULT_OFFER_TO_CREATE.contract,
			}),
		);
	}, []);

	return (
		<section>
			<article className="my-4 flex gap-4">
				{OFFER_TYPE_SELECTION_OPTIONS?.map((option) =>
					OfferTypeOption(option, selectionType, handleSelection),
				)}
			</article>
			<div className="text-left text-xxsm font-normal text-primary-500">
				{getDescriptionMessageFromType(selectionType)}
			</div>
			<div
				className="my-4 flex h-12 cursor-pointer items-center justify-start gap-2 rounded-lg border px-6 py-4"
				onClick={handleOpenCVTek}
			>
				<Icon icon={alertTriangleOutline} className="h-4 w-4 text-accent-2" />
				<p className="text-xxsm font-normal text-primary-700P">
					Vous pouvez partager directement des profils à une entreprise depuis
					la <span className="font-bold">CVthèque.</span>
				</p>
			</div>
		</section>
	);
}

const OfferTypeOption = (
	option: OfferTypeSelectionOption,
	selectedOption: OfferApplicationType,
	selectFunction: (type: OfferApplicationType) => void,
) => {
	return (
		<div data-testid="offerTypeOption" key={option.label} className="flex-1">
			<CardTertiary
				title={option.label}
				icon={option.icon}
				selected={option.type === selectedOption}
				onSelect={() => {
					selectFunction(option.type);
				}}
			/>
		</div>
	);
};

const getDescriptionMessageFromType = (type: OfferApplicationType) => {
	return OFFER_TYPE_DESCRIPTION_MESSAGE.get(type) || "";
};
