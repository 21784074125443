import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";

type FetchCampusListUsecaseProps = {
	schoolId: string;
	setLoadingState: Function;
	handleError: Function;
};

export const fetchCampusList =
	({ schoolId, setLoadingState, handleError }: FetchCampusListUsecaseProps) =>
	async (
		_dispatch: AppDispatch,
		_api: AppGetState,
		dependencies: Dependencies,
	) => {
		try {
			if (!dependencies.campusGateway) {
				throw new Error("campusGateway is not available in dependencies");
			}
			setLoadingState(true);
			const response = await dependencies.campusGateway.getCampuses(schoolId);
			setLoadingState(false);
			return response;
		} catch (error: unknown) {
			handleError(extractError(error).message.toString());
			return;
		}
	};
