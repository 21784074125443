import { useEffect, useState } from "react";

import { stringifyRequestFilters } from "@tools/Objects";

import api from "@tools/API";

export async function getContactsManagementData(view) {
	let rep = {};
	const data = {
		query: `
    query {
      contacts: collectionQueryContacts(${view}, order: [{ lastname: "ASC" }]){
        collection{
          firstname
          lastname
          job
          phone
          email
          linkedin
          applications{
            collection{
                organization: organizationV2
            }
          }
          spsStudent {
            firstname
            lastname
          }
          avatar{
            id
            filePath
          }
        },
        paginationInfo {
          lastPage
        }
      },
      contactIdsList: collectionQueryContacts(${view}, order: [{ lastname: "ASC" }], itemsPerPage: 10000){
        collection {
          id
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});

	return rep;
}

export async function getContactsTableView(view, filters) {
	const stringifyFilters = stringifyRequestFilters({ ...view, ...filters });

	let rep = {};
	const data = {
		query: `
    query {
      contacts: collectionQueryContacts(${stringifyFilters}){
        collection{
          firstname
          lastname
          job
          phone
          email
          applications{
            collection{
              organization: organizationV2
            }
          }
          spsStudent {
            firstname
            lastname
          }
          linkedin
          avatar{
            id
            filePath
          }
        },
        paginationInfo {
          lastPage
        }
      },
      contactIdsList: collectionQueryContacts(${stringifyRequestFilters(view)}, order: [{ lastname: "ASC" }], itemsPerPage: 10000){
        collection {
          id
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});

	return rep;
}

export function GetContactById(contactId) {
	let rep = {};
	const [contact, setContact] = useState({});
	const data = {
		query: `
        query {
          contact(id: "${contactId}") {
            id
            firstname
            lastname
            job
            phone
            email
            linkedin
          }
        }`,
	};
	useEffect(() => {
		api.post("graphql", data).then(({ data }) => {
			rep = data.data.contact;
			setContact(rep);
		});
	}, []);
	return [contact, setContact];
}

export async function exportContactsCSV(contacts) {
	let rep = {};

	await api.post("export_contacts", contacts).then(({ data }) => {
		rep = data;
	});
	return rep;
}
