import React from "react";

import { Avatar } from "@designSystem/atoms/Avatar";

import type { School } from "../SelectionAPI";

type SchoolCardProps = {
	school: School;
};
export function SchoolCard({ school }: SchoolCardProps) {
	return (
		<div className="flex h-full flex-col items-center md:flex-row justify-center gap-16">
			<div className="flex w-full flex-col items-center gap-lg md:flex-row">
				<Avatar
					firstname={school.name}
					lastname={school.name}
					modify={false}
					image={
						school.logo ? `${import.meta.env.VITE_S3}${school.logo}` : null
					}
					size="lg"
					className={"shadow-sm"}
				/>
				<div className="flex flex-col justify-between gap-xxsm">
					<p className="wg-no-translate text-md font-bold">{school.name}</p>
					{school.name !== school.campus && (
						<div className="flex gap-8">
							<p>{school.campus}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
