import { graphqlRequestConstructor } from "@tools/API";

export async function getCampusesRequest(schoolId = "") {
	const queryToSend = {
		query: `query {
      campuses (itemsPerPage: 1000, school: "${schoolId}", order: [{name: "ASC"}]) {
          collection {
            id
            name
          }
      }
    }`,
	};

	const response = graphqlRequestConstructor(
		queryToSend,
		"getCampuses",
		"Erreur lors de la récupération des campus. Veuillez réessayer.",
	);
	return response;
}
