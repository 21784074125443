import React from "react";
import { useDispatch } from "react-redux";

import { paperPlaneOutline } from "@assets/Icons";

import { setSendProfilWasClicked } from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

export function SendProfilsButton() {
	const dispatchEvent = useDispatch();

	const handleSendProfils = async () => {
		dispatchEvent(setSendProfilWasClicked(true));
	};

	return (
		<div className="w-full">
			<ButtonPrimary
				icon={paperPlaneOutline}
				iconPlacement="left"
				label="Envoyer les profils"
				size="large"
				className="!w-full"
				onClick={handleSendProfils}
			/>
		</div>
	);
}
