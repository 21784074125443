import DOMPurify from "dompurify";
import React, { useContext } from "react";

import tinyLogoDark from "@assets/logos/tinyLogoDark.svg";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

import logo from "@containers/public/Selection/assets/logo.svg";
import { GlobalContext } from "@navigation/Router";

export function MailPreview({
	message,
	subject,
}: {
	subject: string;
	message: string;
}) {
	const { user } = useContext(GlobalContext);

	return (
		<div className="flex flex-col overflow-auto">
			<p className="text-primary-200 text-xxsm text-center mb-sm">
				Prévisualisation de votre message
			</p>
			<div className="shadow-sm p-xsm rounded-md border-[1px] border-primary-150 relative">
				<div className="mt-md mb-lg flex flex-col">
					<img
						src={user?.schoolLogo || logo}
						alt="Mentor Goal"
						className="mx-auto mb-sm rounded bg-primary-100 h-[36px] w-auto"
					/>
					<h3 className="!text-md !font-bold text-center">
						Nouvelle invitation
					</h3>
					<p className="text-center font-bold mt-sm">{subject}</p>
				</div>

				<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
				<br />
				<p>
					Cliquez sur le bouton ci-dessous pour vous inscrire dès maintenant :
				</p>
				<div className="flex mt-lg mb-xxlg">
					<ButtonPrimary
						onClick={() => {}}
						className="mx-auto"
						label={`S'inscrire à l'espace entreprise ${user?.schoolName}`}
					/>
				</div>

				<div className="text-xxxsm">
					<p className="text-xxxsm">
						Envoyé via Mentor Goal - la plateforme d'emploi qui connecte les
						écoles, les étudiants et les entreprises !
					</p>
					<p className="mt-xxlg text-xxxsm">
						Une question technique ? une suggestion pour améliorer votre
						expérience?
						<br />
						Écrivez vous à hello@mentorgoal.com ou répondez à cet email.
					</p>
					<p className="text-center mt-lg text-xxxsm">
						Ne plus recevoir d'email de Mentor Goal
					</p>
				</div>

				<img src={tinyLogoDark} alt="Mentor Goal" className="mx-auto mt-lg" />
			</div>
		</div>
	);
}
