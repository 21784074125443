import React, { createContext, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router";

import { Spinner } from "@designSystem/atoms/Spinner";

import { arrowBackOutline } from "@assets/Icons";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { errorToast } from "@tools/Toasts";

import { PreVisualisationMode } from "@containers/public/Selection/index";
import { GlobalContext } from "@navigation/Router";
import {
	type Referent,
	type User,
	type UserDetails,
	querySelection,
	querySelectionUser,
} from "./SelectionAPI";
import { SmallStudentCard } from "./ui/SmallStudentCard";
import { StudentDetails } from "./ui/StudentDetails";

export const UserDetailsContext = createContext<{
	userDetails: UserDetails;
	setUserDetails: (userDetails: UserDetails) => void;
}>({
	userDetails: {} as UserDetails,
	setUserDetails: () => {},
});

export const StudentsSelectionContext = createContext<{
	students: User[];
	setStudents: (students: User[]) => void;
}>({
	students: [],
	setStudents: () => {},
});

export const SelectionStudentDetails = () => {
	const [students, setStudents] = useState<User[]>([]);
	const [offset, setOffset] = useState(0);
	const [total, setTotal] = useState(0);
	const [referent, setReferent] = useState<Referent | undefined>();
	const [selectionName, setSelectionName] = useState("Sélection de CV");
	const [load, setLoad] = useState(true);
	const token = window.location.pathname.split("/")[3];
	const [companyName, setCompanyName] = useState("");
	const [userDetails, setUserDetails] = useState<UserDetails | undefined>();
	const userId = window.location.pathname.split("/")[5];
	const navigate = useNavigate();
	const { isMobile } = useBreakpoints(972);
	const getSelection = async () => {
		try {
			const { referent, users, name, totalCount, company } =
				await querySelection(token, 0);
			setReferent(referent);
			setSelectionName(name);
			setCompanyName(company);
			setTotal(totalCount);
			setStudents([...users]);
			setLoad(false);
		} catch (e) {
			if (e.response.status === 404) {
				navigate("/selection/404");
			}
			errorToast("une erreur est survenue");
		}
	};

	const getSelectionUser = async () => {
		try {
			const user = await querySelectionUser(token, userId);
			setUserDetails(user);
		} catch (e) {
			if (e.response.status === 404) {
				navigate("/selection/404");
			}
			errorToast("une erreur est survenue");
		}
	};

	const getMore = async () => {
		try {
			const newOffset = offset + 10;
			const { users } = await querySelection(token, newOffset);
			setStudents([...students, ...users]);
			setOffset(newOffset);
		} catch {
			errorToast("une erreur est survenue");
		}
	};

	useEffect(() => {
		getSelection();
	}, []);

	useEffect(() => {
		getSelectionUser();
	}, [userId]);

	const { user } = useContext(GlobalContext);
	return (
		<div className="flex h-full min-h-[100vh] w-[100vw] max-w-[100%] justify-center bg-no-repeat bg-bottom">
			{load ? (
				<Spinner className="self-center" />
			) : (
				<div className="flex w-full flex-col items-center">
					{user && <PreVisualisationMode />}
					<div className="flex flex-col items-center gap-lg w-full justify-center py-10">
						<div className="flex-col items-center gap-sm md:flex-row flex w-full max-w-[1600px] px-md sm:px-10">
							<p className="w-10/12 text-center text-sm md:text-left font-bold">
								{companyName} {selectionName && "-"} {selectionName}
							</p>
						</div>
						{isMobile && (
							<div className={"self-start px-md sm:px-10"}>
								<BackToSelection />
							</div>
						)}
					</div>
					<div className="flex w-full max-w-[1600px] gap-md px-md sm:px-10">
						<StudentsSelectionContext.Provider
							value={{ students, setStudents }}
						>
							<div
								className={`hidden ${
									isMobile ? "" : "md:flex"
								} flex-col gap-md p-md rounded-lg bg-white text-primary-700P shadow-sm h-fit min-w-[368px]`}
							>
								<BackToSelection />
								<StudentListTitle total={total} />
								<div className=" max-h-full overflow-y-auto">
									<InfiniteScroll
										pageStart={0}
										loadMore={() => {
											if (students.length < total) {
												getMore();
											}
										}}
										className="flex flex-col gap-sm"
										useWindow={false}
										hasMore={students.length < total}
										loader={
											<div className="loader" key={0}>
												Loading ...
											</div>
										}
									>
										{students.map((student, index) => (
											<SmallStudentCard student={student} key={index} />
										))}
									</InfiniteScroll>
								</div>
							</div>
							{userDetails && (
								<UserDetailsContext.Provider
									value={{ userDetails, setUserDetails }}
								>
									<StudentDetails referent={referent} />
								</UserDetailsContext.Provider>
							)}
						</StudentsSelectionContext.Provider>
					</div>

					<div className="flex w-full max-w-[1600px] flex-col gap-xlg px-10">
						<p className="text-center text-xsm py-8">
							Propulsé par{" "}
							<a
								href={"https://mentorgoal.com"}
								target="_blank"
								rel="noreferrer"
							>
								<b>Mentor Goal</b>
							</a>
						</p>
					</div>
				</div>
			)}
		</div>
	);
};
const BackToSelection = () => {
	const token = window.location.pathname.split("/")[3];
	const schoolShortName = window.location.pathname.split("/")[2];
	const navigate = useNavigate();
	return (
		<InlineButton
			id="back-to-selection"
			icon={arrowBackOutline}
			label="Retour à la liste"
			onClick={() => {
				navigate(`/selection/${schoolShortName}/${token}`);
			}}
		/>
	);
};

export const StudentListTitle = ({ total }: { total: number }) => {
	return (
		<p className="text-primary-700P text-sm font-bold">
			Liste des candidats{" "}
			<span className="text-primary-200 font-semibold">({total})</span>
		</p>
	);
};
