import type { TagType } from "@/types/tagTypes";
import { InlineIcon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import { infoOutline } from "@assets/Icons";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TagsFinder } from "@designSystem/organisms/TagsFinder/TagsFinder";
import { errorToast, success } from "@tools/Toasts";

import { getSchoolTags, getUserTags, updateTags } from "../../DashboardAPI";

export function Competence() {
	const { user } = useContext(GlobalContext);
	const [tags, setTags] = useState<TagType[]>([]);
	const [hardSkillsOptions, setHardSkillsOptions] = useState<TagType[]>([]);
	const [softSkillsOptions, setSoftSkillsOptions] = useState<TagType[]>([]);

	const fetchTags = async () => {
		if (!user) return;

		const response = await getUserTags(user.id);
		setTags(response.tags);

		const { hardSkills, softSkills } = await getSchoolTags(user.school);
		setHardSkillsOptions(hardSkills);
		setSoftSkillsOptions(softSkills);
	};

	const updateTagsList = async (updateInputs: object) => {
		if (!user) return;

		try {
			await updateTags({
				id: user.id,
				...updateInputs,
			});
			success("Modification enregistrée");
		} catch (_e) {
			errorToast("Erreur lors de la modification");
		}
	};

	useEffect(() => {
		fetchTags();
	}, []);

	return (
		<div className="flex flex-col gap-sm">
			<p className="text-[18px] font-bold">Compétences</p>

			<section>
				<div className="flex gap-xxsm items-center">
					<p className="text-xsm text-primary-500">Hard Skills</p>

					<TooltipContainer
						anchorId={"#tooltip-info-tag-1"}
						makeClickable={false}
						children="Les tags sont créés par ton école uniquement. Les suggestions qui apparaissent lorsque tu recherche sont donc celles proposées par ton école"
						place="top"
					/>
					<span id="tooltip-info-tag-1">
						<InlineIcon
							className="ml-xsm h-4 text-primary-300"
							icon={infoOutline}
						/>
					</span>
				</div>
				<TagsFinder
					placeholder="Rechercher..."
					defaultOptions={tags?.filter((tag) => tag.type === "hardSkill")}
					options={hardSkillsOptions}
					errorMessage="Aucun hard skill renseigné"
					onChange={(hardSkillsSelected: TagType[]) => {
						updateTagsList({
							tags: [
								...tags.filter((tag) => tag.type !== "hardSkill"),
								...hardSkillsSelected,
							]?.map((tag) => tag.id),
						});
						setTags([
							...tags.filter((tag) => tag.type !== "hardSkill"),
							...hardSkillsSelected,
						]);
					}}
				/>
			</section>

			<section>
				<div className="flex gap-xxsm items-center">
					<p className="text-xsm text-primary-500">Soft Skills</p>
					<TooltipContainer
						anchorId={"#tooltip-info-tag-2"}
						makeClickable={false}
						children="Les tags sont créés par ton école uniquement. Les suggestions qui apparaissent lorsque tu recherche sont donc celles proposées par ton école"
						place="top"
					/>
					<span id="tooltip-info-tag-2">
						<InlineIcon
							className="ml-xsm h-4 text-primary-300"
							icon={infoOutline}
						/>
					</span>
				</div>
				<TagsFinder
					placeholder="Rechercher..."
					defaultOptions={tags?.filter((tag) => tag.type === "softSkill")}
					options={softSkillsOptions}
					errorMessage="Aucun soft skill renseigné"
					onChange={(softSkillsSelected: TagType[]) => {
						updateTagsList({
							tags: [
								...tags.filter((tag) => tag.type !== "softSkill"),
								...softSkillsSelected,
							]?.map((tag) => tag.id),
						});
						setTags([
							...tags.filter((tag) => tag.type !== "softSkill"),
							...softSkillsSelected,
						]);
					}}
				/>
			</section>
		</div>
	);
}
