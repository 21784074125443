//Documentation : https://github.com/ai/nanoid#readme
//Collision counter -> https://zelark.github.io/nano-id-cc/

import { nanoid } from "nanoid";
import type { IIDProvider } from "./id-provider";

const ID_SIZE = 12;

export class SystemIDProvider implements IIDProvider {
	generate(): string {
		return nanoid(ID_SIZE);
	}
}
