import type { CityType } from "@/types/LocationTypes";
import type { OfferVisibilityType } from "@/types/offerTypes";
import type {
	OffersFiltersContextType,
	OffersFiltersType,
} from "@/types/offersFiltersTypes";
import type { TagType } from "@/types/tagTypes";
import type { ReferentType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { Avatar } from "@designSystem/atoms/Avatar";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { Dropdown } from "@designSystem/molecules/Dropdown";
import { TagsFinder } from "@designSystem/organisms/TagsFinder/TagsFinder";
import { SelectLocation } from "@designSystem/organisms/select/SelectLocation";
import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";
import { GlobalContext } from "@navigation/Router";
import {
	getAllOfferContractOptions,
	getOfferTypeOptions,
	getVisibilityOptions,
} from "@tools/Offers";
import { extractIdNumberFromId } from "@tools/Users";
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch } from "react-redux";
import { getReferents, getTags } from "./OffersFiltersAPI";
import { countCurrentFilters, setCurrentFilters } from "./offersFiltersSlice";

export const OffersFiltersContext = createContext<OffersFiltersContextType>({
	filters: {},
	setFilters: () => {},
});

export function OffersFilters({ filtersProps }: OffersFiltersType) {
	const { filters, setFilters } = filtersProps;
	return (
		<OffersFiltersContext.Provider value={{ filters, setFilters }}>
			<section
				className="
        flex w-full flex-col  gap-sm
        divide-y-2 divide-solid divide-primary-150
        rounded-lg border-[1px] border-primary-150 
        p-sm"
			>
				<div className="flex flex-wrap gap-sm">
					<VisibilityFilter />
					<TypeFilter />
					<ContractFilter />
					<ReferentFilter />
				</div>
				<div className="flex flex-col gap-sm pt-sm">
					<LocationFilter />
					<TagsFilter />
				</div>
			</section>
		</OffersFiltersContext.Provider>
	);
}

function ContractFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.offersFilters.currentFilters,
	);

	const handleChange = (contractSelected: string) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				contract: contractSelected,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<SelectSecondary
			position="right"
			defaultValue={
				getAllOfferContractOptions()?.find(
					(contract) => contract.value === currentFilters?.contract,
				) || { value: "", label: "Contrat" }
			}
			options={getAllOfferContractOptions()}
			onChange={(e) => {
				handleChange(e.value);
			}}
		/>
	);
}

export function LocationFilter() {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector((state) => state.offersFilters);

	const handleLocationChange = (cityReceived: CityType) => {
		if (!cityReceived) {
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					location: undefined,
					position: undefined,
				}),
			);
			return;
		}
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				location: cityReceived.location,
				position: [cityReceived.longitude, cityReceived.latitude],
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const getCityObjectFromFilters = (): CityType | null => {
		if (currentFilters.location && currentFilters.position) {
			return {
				location: currentFilters.location,
				latitude: currentFilters.position[1],
				longitude: currentFilters.position[0],
			};
		}
		return null;
	};

	return (
		<div className="flex w-fit gap-1">
			<SelectLocation
				withLabel={false}
				handleNewLocation={(cityReceived: CityType) => {
					handleLocationChange(cityReceived);
				}}
				defaultValue={
					currentFilters.location ? getCityObjectFromFilters() : null
				}
			/>
		</div>
	);
}

function ReferentFilter() {
	const { user } = useContext(GlobalContext);
	const dispatchEvent = useDispatch();
	const { currentFilters, useFilters } = useAppSelector(
		(state) => state.offersFilters,
	);
	const [referents, setReferents] = useState<ReferentType[] | undefined>();
	const avatarGrpRef = useRef<any>(null);

	const placeOwnerAtTop = (referents: ReferentType[]) => {
		const referentsCopy = [...referents];
		const ownerReferent = referents?.find(
			(referent) =>
				extractIdNumberFromId(referent?.id || "") === Number(user?.id),
		);
		if (ownerReferent) {
			referentsCopy.splice(referentsCopy.indexOf(ownerReferent), 1);
			referentsCopy.unshift(ownerReferent);
		}
		return referentsCopy;
	};

	const getReferentOptions = async () => {
		const response = await getReferents(user?.school || "");
		if (response.responseLabel === "success") {
			setReferents(
				placeOwnerAtTop(response?.data?.schoolReferents?.collection),
			);
		}
	};

	const buildReferentAvatars = () => {
		let referentAvatars: AvatarType[] = [];
		if (referents !== undefined && referents?.length !== 0) {
			referentAvatars = referents
				?.sort((referent) => (referent.lastname === user?.lastname ? -1 : 1))
				.map(
					(referent) =>
						({
							id: referent?.id,
							firstname: referent?.firstname,
							lastname: referent?.lastname,
							filePath: referent?.avatar?.filePath,
						}) as AvatarType,
				);
		}
		return referentAvatars;
	};

	const handleAvatarClick = (referentSelectedId: string) => {
		const filterShouldBeReset = referentSelectedId === currentFilters.referent;
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				referent: filterShouldBeReset ? undefined : referentSelectedId,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	useEffect(() => {
		if (useFilters === true) getReferentOptions();
	}, [user, useFilters]);

	return (
		<div className="h-full w-32" ref={avatarGrpRef}>
			<AvatarGroup
				onClickOnUser={(referentSelectedId) => {
					handleAvatarClick(referentSelectedId);
				}}
				avatars={buildReferentAvatars()}
				tooltipPrefix="Offres de "
				ownerTooltip="Mes offres"
				defaultSelectedId={currentFilters?.referent}
				shouldBeReset={currentFilters?.referent === undefined}
			/>
		</div>
	);
}

const MAX_AVATARS_TO_SHOW = 6;

type AvatarType = {
	id: string;
	firstname: string;
	lastname: string;
	filePath?: string;
};

type AvatarGroupPropsType = {
	avatars: AvatarType[];
	tooltipPrefix?: string;
	ownerTooltip?: string;
	onClickOnUser: (id: string) => void;
	defaultSelectedId?: string;
	shouldBeReset?: boolean;
};

function AvatarGroup(props: AvatarGroupPropsType) {
	const {
		avatars,
		onClickOnUser,
		tooltipPrefix,
		ownerTooltip,
		defaultSelectedId,
	} = props;
	const { user } = useContext(GlobalContext);
	const [indexSelected, setIndexSelected] = useState<number>(-1);
	const [idSelectedOutsideAvatarRow, setIdSelectedOutsideAvatarRow] = useState<
		string | undefined
	>(undefined);
	const [isSelectedOutsideAvatarRow, setIsSelectedOutsideAvatarRow] =
		useState<boolean>(false);

	const resetSelection = () => {
		setIndexSelected(-1);
		setIdSelectedOutsideAvatarRow(undefined);
		setIsSelectedOutsideAvatarRow(false);
	};

	const checkIfThisItemIsSelected = (id: string, outsideRow = false) => {
		if (defaultSelectedId && defaultSelectedId === id) return true;
		if (outsideRow) {
			const result = id === idSelectedOutsideAvatarRow;
			return result;
		}
		return false;
	};
	const dropdownItems = useMemo(
		() =>
			avatars.slice(MAX_AVATARS_TO_SHOW).map((avatar) => ({
				label: `${avatar.firstname} ${avatar.lastname}`,
				color: checkIfThisItemIsSelected(avatar?.id, true) && "primary-700P",
				isSelect: checkIfThisItemIsSelected(avatar?.id, true),
				action: () => onClickOnOptionOutsideAvatarRow(avatar?.id),
			})),
		[avatars, idSelectedOutsideAvatarRow],
	);

	const resetIsSelectedOutsideAvatarRow = () => {
		setIsSelectedOutsideAvatarRow(false);
		setIdSelectedOutsideAvatarRow(undefined);
	};

	const onClickOnOptionOutsideAvatarRow = (avatarId: string) => {
		setIdSelectedOutsideAvatarRow(avatarId);
		onClickOnUser(avatarId);

		if (avatarId === idSelectedOutsideAvatarRow) {
			resetSelection();
		}
	};
	const onClickOnAvatar = (avatar: AvatarType, index: number) => {
		setIndexSelected(index);
		resetIsSelectedOutsideAvatarRow();
		onClickOnUser(avatar?.id);

		if (index === indexSelected) {
			resetSelection();
		}
	};

	const itemSelectedOustideAvatarRow = () => {
		if (
			isSelectedOutsideAvatarRow &&
			idSelectedOutsideAvatarRow !== undefined
		) {
			return true;
		}
		return false;
	};

	const checkIfItsMyAvatar = (id: string, userId: string) => {
		return extractIdNumberFromId(id).toString() === userId.toString();
	};

	useEffect(() => {
		if (props.shouldBeReset) {
			resetSelection();
		}
	}, [props.shouldBeReset]);
	return (
		<div className="flex">
			{avatars
				?.filter((_, index) => index < MAX_AVATARS_TO_SHOW)
				?.map(({ id, firstname, lastname, filePath }: AvatarType, index) => {
					return (
						<div
							key={index}
							className={`h-[40px] w-[40px] min-w-[40px] p-2 cursor-pointer rounded-full flex justify-center items-center bg-white ${`z-[${index}]`} ${
								index < MAX_AVATARS_TO_SHOW && "border-2"
							} hover:border-primary-500 ${
								checkIfThisItemIsSelected(id, itemSelectedOustideAvatarRow())
									? "border-primary-700P"
									: "border-white"
							}`}
							onClick={() =>
								onClickOnAvatar({ id, firstname, lastname, filePath }, index)
							}
							style={{
								marginLeft: index > 0 ? -8 : 0,
								zIndex: Math.abs(index - avatars.length),
							}}
						>
							{index < MAX_AVATARS_TO_SHOW && (
								<span
									id={`avatar-${index}`}
									onClick={() => {
										resetIsSelectedOutsideAvatarRow();
									}}
								>
									<Avatar
										key={index}
										firstname={firstname}
										lastname={lastname}
										image={filePath || ""}
										size="xs"
									/>
									<TooltipContainer
										anchorId={`#avatar-${index}`}
										children={
											checkIfItsMyAvatar(id, user?.id || "")
												? ownerTooltip
												: `${tooltipPrefix} ${firstname} ${lastname}`
										}
										place="top"
										float={false}
									/>
								</span>
							)}
						</div>
					);
				})}
			<div className="flex justify-center items-center h-full -ml-2 mt-[2px]">
				{avatars.length > MAX_AVATARS_TO_SHOW && (
					<Dropdown
						button={
							<Avatar
								image=""
								firstname="+"
								lastname={`${avatars.length - MAX_AVATARS_TO_SHOW}`}
								className={`${
									dropdownItems?.find((item) => item.isSelect) &&
									"border-2 border-primary-700P"
								} [&>p]:text-primary-700P ![&>p]:text-xxsm bg-primary-200`}
								size="xs"
							/>
						}
						items={dropdownItems}
						direction="right"
						triggerOpening={() => {
							if (idSelectedOutsideAvatarRow === undefined) {
								setIsSelectedOutsideAvatarRow(!isSelectedOutsideAvatarRow);
							}
						}}
					/>
				)}
			</div>
		</div>
	);
}

function TagsFilter() {
	const dispatchEvent = useDispatch();
	const { user } = useContext(GlobalContext);
	const currentFilters = useAppSelector(
		(state) => state.offersFilters.currentFilters,
	);
	const [tagsOptions, setTagsOptions] = useState<TagType[]>([]);

	const handleOnTagsChange = (tagsList: number[] = [], type = "and") => {
		const filtersCpy = { ...currentFilters };

		if (tagsList.length === 0) {
			filtersCpy.tags = undefined;
			dispatchEvent(setCurrentFilters(filtersCpy));
		} else {
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					tags: { list: tagsList, operator: type },
					page: 1,
				}),
			);
		}
		dispatchEvent(countCurrentFilters());
	};

	const getDefaultTags = () => {
		let tagsList: TagType[] = [];

		if (currentFilters.tags) {
			tagsList = tagsOptions.filter((tagOption) =>
				currentFilters.tags?.list.includes(
					Number.parseInt(tagOption.id.split("/")[3], 10),
				),
			);
		}

		return tagsList;
	};

	const retrieveTags = () => {
		if (user) {
			getTags(user.school).then(({ collection }: { collection: TagType[] }) => {
				setTagsOptions(collection);
			});
		}
	};

	useEffect(() => {
		retrieveTags();
	}, [user]);

	return (
		<TagsFinder
			options={tagsOptions.sort((a, b) => a.name.localeCompare(b.name))}
			defaultOptions={getDefaultTags()}
			placeholder="Rechercher un tag... "
			useAsFilter={true}
			onChange={(tagFilter: any) => {
				handleOnTagsChange(tagFilter.list, tagFilter.operator);
			}}
		/>
	);
}

function TypeFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.offersFilters.currentFilters,
	);

	const handleChange = (typeSelected: string) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				type: typeSelected,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<SelectSecondary
			position="right"
			defaultValue={
				getOfferTypeOptions(true)?.find(
					(type) => type.value === currentFilters?.type,
				) || { value: "", label: "Type" }
			}
			options={[
				{
					value: null,
					label: "Tous",
				},
				...getOfferTypeOptions(true),
			]}
			onChange={(e) => {
				handleChange(e.value);
			}}
		/>
	);
}

function VisibilityFilter() {
	const dispatchEvent = useDispatch();

	const currentFilters = useAppSelector(
		(state) => state.offersFilters.currentFilters,
	);

	const handleChange = (visibilitySelected: string) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				visibility: visibilitySelected,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<SelectSecondary
			position="right"
			defaultValue={
				getVisibilityOptions({ cutDetails: true, roles: [] })?.find(
					(visibility) =>
						visibility.value ===
						(currentFilters?.visibility as OfferVisibilityType),
				) || { value: "", label: "Visibilité" }
			}
			options={getVisibilityOptions({ cutDetails: true, roles: [] })}
			onChange={(e) => {
				handleChange(e.value);
			}}
		/>
	);
}
