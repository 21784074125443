import type { ReferentType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

import { sortReferentsByLastName } from "@tools/Offers";
import { extractIdNumberFromId } from "@tools/Users";

import { useAppDispatch } from "@config/redux/store";
import { Spinner } from "@designSystem/atoms/Spinner";
import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";

type ReferentSelectionProps = {
	triggerUpdate?: (e: any) => void;
};

export function ReferentSelection({ triggerUpdate }: ReferentSelectionProps) {
	const updateReferentList =
		companiesManagementSlice.actions.updateReferentList;
	const modifyCompanyToCreate =
		companiesManagementSlice.actions.modifyCompanyToCreate;
	const dispatchEvent = useAppDispatch();
	const { user } = useContext(GlobalContext);
	const { companyToCreate, loadingStates } = useAppSelector(
		(state) => state.companiesManagement,
	);

	const { referentsListReceived } = useAppSelector((state) => state.referents);

	const getReferentListByRole = () => {
		const roles = user?.roles;
		if (referentsListReceived) {
			return roles?.includes("ROLE_SCHOOL")
				? referentsListReceived.schoolReferents
				: referentsListReceived.campusReferents;
		}
		return [];
	};

	const addReferentToTheList = (referent: ReferentType) => {
		const roles = user?.roles;
		const referentList = getReferentListByRole();
		if (referentList) {
			if (referentList.find((ref: ReferentType) => ref.id === referent.id)) {
				return;
			}
			const updatedReferentList = [...referentList, referent];
			const referents = {
				campusReferents: roles?.includes("ROLE_SCHOOL")
					? referentsListReceived?.campusReferents
					: sortReferentsByLastName(updatedReferentList),
				schoolReferents: roles?.includes("ROLE_SCHOOL")
					? sortReferentsByLastName(updatedReferentList)
					: referentsListReceived?.schoolReferents,
			};
			dispatchEvent(updateReferentList(referents));
		}
	};

	const makeSelectOptionsFromReferents = () => {
		return getReferentListByRole()?.map((referent: ReferentType) => {
			return {
				label: `${referent?.lastname} ${referent?.firstname}`,
				value: referent?.id,
			};
		});
	};

	const findReferentOptionFromId = (idOfReferentToFind: string) => {
		const referentList = getReferentListByRole();
		if (idOfReferentToFind === "" || referentList?.length === 0) {
			return "";
		}
		if (companyToCreate?.responsable) {
			if (
				!referentList?.find(
					(ref: ReferentType) => ref.id === companyToCreate?.responsable?.id,
				)
			) {
				addReferentToTheList(companyToCreate?.responsable);
			}
			return {
				label: `${companyToCreate?.responsable?.lastname} ${companyToCreate?.responsable?.firstname}`,
				value: companyToCreate?.responsable?.id,
			};
		}
		const referentFind = getReferentListByRole()?.find(
			(referentFromTheList: ReferentType) => {
				return (
					extractIdNumberFromId(referentFromTheList.id) ===
					extractIdNumberFromId(idOfReferentToFind)
				);
			},
		);

		if (!referentFind) {
			return "";
		}
		return {
			label: `${referentFind?.lastname} ${referentFind?.firstname}`,
			value: referentFind?.id,
		};
	};

	const findReferentDataFromId = (id: string): ReferentType | undefined => {
		const referentFound = getReferentListByRole()?.find(
			(referent: ReferentType) => {
				return extractIdNumberFromId(referent.id) === extractIdNumberFromId(id);
			},
		);
		return referentFound;
	};

	const tryToSelectActiveUserAsReferent = () => {
		if (!getReferentListByRole()?.length || !user) {
			return "";
		}
		const canUseUserAsReferent = getReferentListByRole()?.find(
			(referent: ReferentType) => {
				return referent?.id === user?.id;
			},
		);

		if (canUseUserAsReferent) {
			dispatchEvent(
				modifyCompanyToCreate({
					spsReferent: canUseUserAsReferent,
				}),
			);
			return {
				label: `${canUseUserAsReferent?.lastname} ${canUseUserAsReferent?.firstname}`,
				value: canUseUserAsReferent?.id,
			};
		}
		return "";
	};

	const handleReferentChange = (value: string) => {
		const referentData = findReferentDataFromId(value);
		if (triggerUpdate) {
			triggerUpdate(referentData);
			return;
		}
		dispatchEvent(
			modifyCompanyToCreate({
				spsReferent: {
					id: referentData?.id,
					user: referentData,
				},
			}),
		);
	};

	if (loadingStates.isFetchingReferents) {
		return <Spinner size="small" />;
	}

	if (!referentsListReceived) {
		return <p>Auncun responsable disponible.</p>;
	}

	return (
		<SelectSecondary
			label="Responsable des relations entreprises*"
			defaultValue={
				companyToCreate?.spsReferent
					? findReferentOptionFromId(companyToCreate?.spsReferent?.id || "")
					: tryToSelectActiveUserAsReferent()
			}
			position="right"
			placeholder={
				referentsListReceived?.campusReferents?.length !== 0
					? "Choisir un responsable..."
					: "Pas de responsable disponible"
			}
			options={makeSelectOptionsFromReferents() || []}
			onChange={(e) => {
				handleReferentChange(e.value);
			}}
			className="wg-no-translate w-full"
		/>
	);
}
