import { useBreakpoints } from "@hooks/useBreakpoints";
import { InlineIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import $ from "jquery"; /* DEPRECATED */
import React, { useEffect, useState } from "react";

import "@assets/sass/Progress.scss";

import { infoOutline } from "@assets/Icons";

import { StepperPrimary } from "../molecules/StepperItem";

export type ProgressBaseType = {
	className?: string;
	progressValue: number;
};

export type ProgressBarType = ProgressBaseType & {
	label: string;
	fullValue: number;
	tippyLabel?: string;
};

export type ProgressStepperType = {
	label: string;
	className: string;
	activeStep: number;
	color: string;
	displayArrow: boolean;
	date?: string | undefined;
	clickable?: (value: number) => void;
	stepIndex: number;
};

export type ProgressStepType = {
	label: string;
	value: number;
	accentColor: number;
	date?: string | undefined;
};

export type ProgressIndicatorType = {
	steps: ProgressStepType[];
	className: string;
	indexActive: number;
	onClickOnStep: (index: number) => void;
};

export function ProgressBar({
	className = "",
	label = "",
	progressValue = 0,
	fullValue = 0,
	tippyLabel = "",
}: ProgressBarType) {
	return (
		<div className={`${className}`}>
			<div className="alig-items-center flex justify-between">
				<div className="alig-items-center flex">
					<p className="text-primary-500">{label}</p>
					{tippyLabel && (
						<Tippy
							content={tippyLabel}
							theme="primary"
							animation="fade"
							placement="right"
							zIndex={5}
						>
							<span className="ml-xxsm cursor-pointer">
								<InlineIcon
									className="text-primary-200"
									width={16}
									height={16}
									icon={infoOutline}
								/>
							</span>
						</Tippy>
					)}
				</div>
				<p className="text-primary-300">
					{progressValue}/{fullValue}
				</p>
			</div>
			<div className="container-progress-bar mt-xxsm">
				<div className="progress-bar">
					<span
						className="progress-bar-fill"
						style={{ width: `${(progressValue / fullValue) * 100}%` }}
					/>
				</div>
			</div>
		</div>
	);
}

export function ProgressBarSecondary({
	className = "",
	label = "",
	progressValue = 0,
	fullValue = 0,
}: ProgressBarType) {
	return (
		<div className={`${className}`}>
			<div className="alig-items-center flex justify-between">
				<p className="font-bold text-primary-700P">{label}</p>
				<p className="text-primary-300">{(progressValue / fullValue) * 100}%</p>
			</div>
			<div className="container-progress-bar-secondary mt-xxsm">
				<div className="progress-bar-secondary">
					<span
						className="progress-bar-fill-secondary"
						style={{ width: `${(progressValue / fullValue) * 100}%` }}
					/>
				</div>
			</div>
		</div>
	);
}

export function ProgressIndicator({
	steps,
	className,
	indexActive,
	onClickOnStep = () => {},
}: ProgressIndicatorType) {
	const { isMobile } = useBreakpoints();

	const [activeStep, setActiveStep] = useState(indexActive);

	useEffect(() => {
		const targetElement = $(".stepper-container .stepper-item");

		targetElement.mouseenter(function () {
			const currentIndex = $(this).index();
			targetElement.each(function (i) {
				const children = $(this).children(".stepper-item-primary-container");
				if (
					!children.hasClass("active") &&
					!children.hasClass("passed") &&
					i < currentIndex
				) {
					$(this)
						.children(".stepper-item-primary-container")
						.addClass("skipped");
				}
			});
		});

		targetElement.mouseleave(() => {
			targetElement.each(function () {
				$(this)
					.children(".stepper-item-primary-container")
					.removeClass("skipped");
			});
		});
	}, [steps, activeStep]);

	return (
		<div className={`progress-indicator-container ${className}`}>
			{steps.map((step, stepIndex) => {
				return (
					<div
						key={stepIndex}
						className="flex w-full "
						onClick={() => {
							if (!isMobile) {
								onClickOnStep(step.value);
								setActiveStep(stepIndex);
							}
						}}
					>
						<StepperPrimary
							className={`progress-indicator-item stepper-${stepIndex}`}
							label={step.label}
							color={step.accentColor}
							stepIndex={stepIndex}
							date={step.date}
							activeStep={activeStep}
						/>
					</div>
				);
			})}
		</div>
	);
}
