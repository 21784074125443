import type { StudentStatusType, UserCompleteType } from "@/types/userTypes";
import { EState, type EStatus } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import { format } from "date-fns";
import React, {
	type MouseEvent,
	type ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
	checkMarkCircleOutline,
	closeCircleOutline,
	closeOutline,
	emailOutline,
	paperPlaneOutline,
} from "@assets/Icons";

import { STUDENT_STATUS_OPTIONS } from "@constants/Users";

import { convertDateFromNow, diffDate } from "@tools/Dates";

import { Badge } from "@designSystem/atoms/Badge";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { Checkbox } from "@designSystem/atoms/Checkbox";
import { Spinner } from "@designSystem/atoms/Spinner";
import { CellAvatarLabelSecondary } from "@designSystem/molecules/CellAvatarLabelSecondary";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";
import { ToastCustom } from "@designSystem/organisms/toast/Toast";
import { Table } from "@designSystem/templates/table/Table";
import {
	setCurrentFilters,
	setPagination,
	setShowFilters,
} from "@modules/usersFilters/usersFiltersSlice";
import {
	errorToast,
	success,
	updateToastWithError,
	updateToastWithSuccess,
} from "@tools/Toasts";

import {
	editUser,
	relaunchStudent,
} from "@containers/school/Students/StudentsManagement/StudentsManagementAPI";

import type { AppState } from "@config/redux/store";
import { exportStudentsToGallia } from "../../../StudentDetail/StudentDetailAPI";
import { StudentsManagementContext } from "../../index";
import {
	setRequestedUsersCount,
	setStudentsList,
} from "../../studentsManagementSlice";

type TableViewType = {
	selectedStudents: UserCompleteType[];
	handleOnCheckStudent: (student: UserCompleteType) => void;
	handleOnCheckAll: (checked: boolean) => void;
	checkAllLoader: boolean;
	setSelectStudents: (students: UserCompleteType[]) => void;
};

const DAYS_BETWEEN_RELAUNCH = 1;
export function TableView({
	checkAllLoader,
	selectedStudents,
	handleOnCheckStudent,
	handleOnCheckAll,
	setSelectStudents,
}: TableViewType) {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector(
		(state: AppState) => state.usersFilters,
	);
	const studentManagementContext = useContext(StudentsManagementContext);
	const isGalliaEnabled = studentManagementContext.promotions.find(
		(promotion) => promotion?.campus?.isGalliaEnabled,
	);
	const [isExportingToGallia, setIsExportingToGallia] =
		useState<boolean>(false);

	const [isStudentStatusLoading, setIsStudentStatusLoading] =
		useState<boolean>(false);

	useEffect(() => {
		if (currentFilters.state === EState.WAIT_APPROVAL) {
			dispatchEvent(setShowFilters(false));
		} else {
			dispatchEvent(setShowFilters(true));
		}
	}, [currentFilters?.state]);
	const {
		studentsList,
		studentsListLoading,
		studentsListLastPage,
		studentsListTotalCount,
		requestedUsersCount,
	} = useAppSelector((state: AppState) => state.studentsManagement);

	const changeStudentStatus = async (
		student: UserCompleteType,
		newStatus?: EStatus,
		newStudentStatus?: StudentStatusType,
	) => {
		const data = {
			status: newStatus,
			studentStatus: newStudentStatus,
			id: student.id,
			studentStatusState: null,
		};
		try {
			if (student.status !== student.studentStatus && requestedUsersCount > 0) {
				dispatchEvent(setRequestedUsersCount(requestedUsersCount - 1));
			}
			let newStudent: UserCompleteType;
			const newStudentsList = [...studentsList];
			const index = newStudentsList.findIndex((e) => e.id === student.id);
			if (newStudentStatus !== undefined && newStatus !== undefined) {
				newStudent = {
					...student,
					status: newStatus,
					studentStatus: newStudentStatus,
					studentStatusState: null,
				};
			} else if (newStatus !== undefined) {
				newStudent = { ...student, status: newStatus };
			} else if (newStudentStatus !== undefined) {
				newStudent = { ...student, studentStatus: newStudentStatus };
			} else {
				return;
			}
			newStudentsList[index] = newStudent as UserCompleteType;

			dispatchEvent(setStudentsList(newStudentsList));

			setIsStudentStatusLoading(true);
			const editUserResponse = await editUser(data);
			if (editUserResponse) {
				success("Le statut de l'étudiant a été modifié.");
			}
		} catch {
			errorToast("Erreur lors du changement de statut.");
		} finally {
			setIsStudentStatusLoading(false);
		}
	};

	const handleOnRelaunch = async (ids: string[]) => {
		const relaunchToastId = toast.loading("Relance des étudiants...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		const relaunchResponse = await relaunchStudent(ids);
		if (relaunchResponse && relaunchResponse.responseLabel === "success") {
			const newStudentsList = [...studentsList];
			for (const id of ids) {
				let student = studentsList.find(
					(student: UserCompleteType) => student.id?.split("/")[3] === id,
				);
				if (student) {
					student = { ...student, dateOfInvitation: new Date().toISOString() };

					const index = studentsList.findIndex(
						(student: UserCompleteType) => student.id?.split("/")[3] === id,
					);
					newStudentsList[index] = student;
				}
			}
			dispatchEvent(setStudentsList(newStudentsList));
			dispatchEvent(setCurrentFilters(currentFilters));
			setSelectStudents([]);
			updateToastWithSuccess(relaunchToastId, "Relances effectuées");
		} else {
			updateToastWithError(relaunchToastId, "Erreur lors de la relance");
		}
	};

	const handleOnRelaunchAll = () => {
		const students = selectedStudents?.filter((student) => {
			if (!student.dateOfInvitation) return true;

			return (
				format(new Date(), "dd/MM/yyyy") !==
				format(new Date(student.dateOfInvitation), "dd/MM/yyyy")
			);
		});
		let ids = students?.map((student) => student.id?.split("/")[3]) || [];
		ids = ids.filter((id) => typeof id === "string");
		handleOnRelaunch(ids as string[]);
	};

	const headers = [
		{
			content: checkAllLoader ? (
				<Spinner size="small" />
			) : (
				<Checkbox
					checked={selectedStudents.length === studentsListTotalCount}
					onEnabled={() => {
						handleOnCheckAll(true);
					}}
					onDisabled={() => {
						handleOnCheckAll(false);
					}}
				/>
			),
			tabs: isGalliaEnabled
				? ["all", EState.WAIT_CONNECTION]
				: [EState.WAIT_CONNECTION],
		},
		{
			label: `ÉTUDIANTS (${
				studentsListLoading ? "..." : studentsListTotalCount
			})`,
			sort: {
				fieldName: "lastname",
				defaultOrder: currentFilters.order?.[0].lastname
					? currentFilters.order[0].lastname
					: "ASC",
			},
			tabs: ["all", EState.WAIT_CONNECTION, EState.WAIT_APPROVAL],
			className: "max-w-[260px]",
		},
		{
			label: "LOCALISATION",
			sort: {
				fieldName: "postalCode",
				defaultOrder: currentFilters.order?.[0].postalCode
					? currentFilters.order[0].postalCode
					: "ASC",
			},
			tabs: ["all"],
		},
		{
			label: "CANDIDATURES",
			sort: {
				fieldName: "applicationsCount",
				defaultOrder: currentFilters.order?.[0].applicationsCount
					? currentFilters.order[0].applicationsCount
					: "ASC",
			},

			tabs: ["all"],
		},
		{
			label: "RELANCES",
			sort: {
				fieldName: "relaunchsCount",
				defaultOrder: currentFilters.order?.[0].relaunchsCount
					? currentFilters.order[0].relaunchsCount
					: "ASC",
			},
			tabs: ["all"],
		},
		{
			label: "ENTRETIENS",
			tabs: ["all"],
			sort: {
				fieldName: "interviewsCount",
				defaultOrder: currentFilters.order?.[0].interviewsCount
					? currentFilters.order[0].interviewsCount
					: "ASC",
			},
		},
		{
			label: "STATUT",
			tabs: ["all", EState.WAIT_CONNECTION],

			sort: {
				fieldName: "status",
				defaultOrder: currentFilters.order?.[0].status
					? currentFilters.order[0].status
					: "ASC",
			},
		},
		{
			label: "STATUT ACTUEL",
			tabs: [EState.WAIT_APPROVAL],

			sort: {
				fieldName: "status",
				defaultOrder: currentFilters.order?.[0].status
					? currentFilters.order[0].status
					: "ASC",
			},
		},
		{
			label: "MIS À JOUR PAR L'ÉTUDIANT",
			tabs: [EState.WAIT_APPROVAL],

			sort: {
				fieldName: "studentStatus",
				defaultOrder: currentFilters.order?.[0].studentStatus
					? currentFilters.order[0].studentStatus
					: "ASC",
			},
		},
		{
			label: "DATE DE DEMANDE",
			tabs: [EState.WAIT_APPROVAL],
		},
		{
			label: "Valider la mise à jour ?",
			tabs: [EState.WAIT_APPROVAL],
		},
		{
			label: "DERNIÈRE CONNEXION",
			tabs: ["all"],
			sort: {
				fieldName: "lastConnexion",
				defaultOrder: currentFilters.order?.[0].lastConnexion
					? currentFilters.order[0].lastConnexion
					: "ASC",
			},
		},
		{
			label: "CRÉATION",
			tabs: ["all"],
			sort: {
				fieldName: "createdAt",
				defaultOrder: currentFilters.order?.[0].createdAt
					? currentFilters.order[0].createdAt
					: "ASC",
			},
		},
		{
			label: "DERNIÈRE RELANCE",
			tabs: [EState.WAIT_CONNECTION],
		},
		{
			label: "RELANCE",
			tabs: [EState.WAIT_CONNECTION],
		},
	].filter((header) => header.tabs.includes(currentFilters?.state || "all"));

	const cellsResolver = (student: UserCompleteType) => {
		const getStudentStatus = () => {
			return STUDENT_STATUS_OPTIONS.find(
				(item) => item.value === student.studentStatus,
			);
		};
		const getStatus = () => {
			return STUDENT_STATUS_OPTIONS.find(
				(item) => item.value === student.status,
			);
		};

		type CellType = {
			onClick: null | ((e: MouseEvent<HTMLElement>) => void);
			children: ReactNode;
			tabs: string[];
		};

		const cells = [
			{
				onClick: null,

				children: (
					<>
						{diffDate(new Date(student.dateOfInvitation)) <
						DAYS_BETWEEN_RELAUNCH ? (
							<Icon
								className="text-primary-300"
								icon={checkMarkCircleOutline}
							/>
						) : (
							<Checkbox
								checked={
									selectedStudents.filter((e) => e.id === student.id).length > 0
								}
								onEnabled={(event: MouseEvent) => {
									event.preventDefault();
									event.stopPropagation();
									handleOnCheckStudent(student);
								}}
								onDisabled={(event: MouseEvent) => {
									event.preventDefault();
									event.stopPropagation();
									handleOnCheckStudent(student);
								}}
							/>
						)}
					</>
				),

				tabs: isGalliaEnabled
					? ["all", EState.WAIT_CONNECTION]
					: [EState.WAIT_CONNECTION],
			},
			{
				children: (
					<CellAvatarLabelSecondary
						className="max-w-[260px]"
						translate={false}
						label={`${student.lastname} ${student.firstname}`}
						sublabel={student.promotion.name}
						firstname={student.firstname}
						lastname={student.lastname}
						avatar={student.avatar?.filePath}
						avatarColor={`${
							student?.status !== undefined
								? STUDENT_STATUS_OPTIONS.find(
										(x) => x.value === student?.status,
									)?.backgroundColor
								: null
						}`}
					/>
				),

				tabs: ["all", EState.WAIT_CONNECTION, EState.WAIT_APPROVAL],
			},
			{
				children: `${
					!student.postalCode && !student.city
						? "Non renseigné"
						: student.postalCode ?? ""
				} ${
					(student.postalCode && student.city) || student.city
						? student.city
						: ""
				}`,
				tabs: ["all"],
			},
			{
				children: student.applicationsCount,
				tabs: ["all"],
			},
			{
				children: student.relaunchsCount,
				tabs: ["all"],
			},
			{
				children: student.interviewsCount,
				tabs: ["all"],
			},
			{
				onClick: null,
				children: (
					<SelectSecondary
						position="top"
						options={STUDENT_STATUS_OPTIONS}
						className="w-[250px]"
						defaultValue={() => {
							return STUDENT_STATUS_OPTIONS.find(
								(x) => x.value === student.status,
							);
						}}
						onChange={(value) => {
							if (!value.value && Number.isNaN(Number.parseInt(value.value))) {
								errorToast("Une erreur est survenue : statut invalide.");
								return;
							}
							changeStudentStatus(
								student,
								Number.parseInt(value.value),
								Number.parseInt(value.value),
							);
						}}
					/>
				),

				tabs: ["all", EState.WAIT_CONNECTION],
			},
			{
				children: (
					<Badge
						size="medium"
						bgColor={getStatus() ? getStatus()?.backgroundColor : undefined}
						textColor={
							getStatus()?.textColor ? getStatus()?.textColor : undefined
						}
						borderColor={
							getStatus()?.borderColor ? getStatus()?.borderColor : undefined
						}
						label={getStatus() ? (getStatus()?.label as string) : ""}
						hasBorder
					/>
				),
				tabs: [EState.WAIT_APPROVAL],
			},
			{
				children: (
					<Badge
						size="medium"
						bgColor={
							getStudentStatus()
								? getStudentStatus()?.backgroundColor
								: undefined
						}
						textColor={
							getStudentStatus()?.textColor
								? getStudentStatus()?.textColor
								: undefined
						}
						borderColor={
							getStudentStatus()?.borderColor
								? getStudentStatus()?.borderColor
								: undefined
						}
						label={
							getStudentStatus() ? (getStudentStatus()?.label as string) : ""
						}
						hasBorder
					/>
				),
				tabs: [EState.WAIT_APPROVAL],
			},
			{
				children: (
					<span>
						{student.studentStatusRequestTime
							? format(
									new Date(student.studentStatusRequestTime),
									"dd/MM/yyyy HH:MM",
								)
							: "-"}
					</span>
				),
				tabs: [EState.WAIT_APPROVAL],
			},
			{
				children: student.dateOfInvitation
					? convertDateFromNow(student.dateOfInvitation)
					: "Pas de relance faite",

				tabs: [EState.WAIT_CONNECTION],
			},
			{
				onClick: (e: MouseEvent<HTMLElement>) => {
					e.preventDefault();
					e.stopPropagation();
					handleOnRelaunch([student.id?.split("/")[3] || ""]);
				},
				children: (
					<ButtonPrimary
						label=""
						onClick={() => {}}
						disabled={
							format(new Date(), "dd/MM/yyyy") ===
							format(new Date(student.dateOfInvitation), "dd/MM/yyyy")
						}
						icon={
							format(new Date(), "dd/MM/yyyy") ===
							format(new Date(student.dateOfInvitation), "dd/MM/yyyy")
								? checkMarkCircleOutline
								: emailOutline
						}
					/>
				),

				tabs: [EState.WAIT_CONNECTION],
			},
			{
				onClick: (e: MouseEvent<HTMLElement>) => {
					e.preventDefault();
					e.stopPropagation();
				},
				children: (
					<div className="flex gap-xsm">
						<ButtonTertiary
							disabled={
								isStudentStatusLoading ||
								student.status === student.studentStatus
							}
							icon={checkMarkCircleOutline}
							onClick={() => {
								changeStudentStatus(student, student.studentStatus as EStatus);
							}}
						/>
						<ButtonTertiary
							disabled={
								isStudentStatusLoading ||
								student.status === student.studentStatus
							}
							icon={closeCircleOutline}
							onClick={() => {
								changeStudentStatus(
									student,
									student.status,
									student.status as StudentStatusType,
								);
							}}
						/>
					</div>
				),

				tabs: [EState.WAIT_APPROVAL],
			},
			{
				children: `${
					student.lastConnexion
						? convertDateFromNow(student.lastConnexion)
						: "Jamais connecté"
				}`,
				tabs: ["all"],
			},
			{
				children: `${
					student.createdAt
						? format(new Date(student.createdAt), "dd/MM/yyyy")
						: "Non renseigné"
				}`,
				tabs: ["all"],
			},
		] as CellType[];

		return cells.filter((cell) => cell.tabs.includes(currentFilters?.state));
	};

	const getEmptyMessage = (index: number) => {
		switch (index) {
			case EState.WAIT_CONNECTION:
				return "Aucun étudiant n’est en attente de connexion à la plateforme.";
			case EState.WAIT_APPROVAL:
				return "Aucun étudiant n’est en attente de validation de son statut.";
			default:
				return "Aucun résultat correspondant.";
		}
	};
	const handleTableSetCurrentFilters = (filters: any) => {
		dispatchEvent(setCurrentFilters(filters));
	};

	return (
		<div className="flex flex-col gap-md">
			<Table
				className="mt-md"
				emptyMessage={
					((studentsList === null || studentsList.length === 0) &&
						getEmptyMessage(
							(currentFilters &&
								Object.keys(currentFilters).length < 3 &&
								currentFilters?.state) ||
								"all",
						)) ||
					""
				}
				isLoading={studentsListLoading}
				filtersProps={{
					filters: currentFilters,
					setFilters: handleTableSetCurrentFilters,
				}}
				headers={headers}
				rows={studentsList?.map((student: UserCompleteType) => {
					return {
						url: `/students/${student.id?.split("/")[3]}`,
						cells: cellsResolver(student),
					};
				})}
			/>
			<PaginationPrimary
				onChange={(page) => {
					if (currentFilters) {
						dispatchEvent(setPagination({ page }));
					}
				}}
				current={currentFilters?.page}
				last={studentsListLastPage}
			/>
			{selectedStudents.length > 0 && (
				<ToastCustom>
					<div className="flex items-center gap-sm">
						<ButtonQuinary
							className=""
							disabled={false}
							shape="rectangle"
							dropdownButton={false}
							notifCount={0}
							onClick={() => setSelectStudents([])}
							icon={closeOutline}
						/>
						<p className="rounded-md bg-info-dark px-xsm py-xxsm text-xxsm text-white">
							{selectedStudents.length}
						</p>
						<p className="text-primary-700 text-nowrap text-xsm">
							Étudiants sélectionnés
						</p>
						{currentFilters.state === EState.WAIT_CONNECTION && (
							<ButtonPrimary
								onClick={handleOnRelaunchAll}
								icon={emailOutline}
								label="Relancer"
							/>
						)}
						{isGalliaEnabled &&
							(currentFilters.state === "all" ||
								currentFilters.state === EState.WAIT_CONNECTION) && (
								<ButtonPrimary
									disabled={isExportingToGallia}
									icon={paperPlaneOutline}
									onClick={() => {
										setIsExportingToGallia(true);
										let studentsIds = selectedStudents.map(
											(student) => student.id?.split("/")[3],
										);
										studentsIds = studentsIds.filter(
											(id) => typeof id === "string",
										);
										exportStudentsToGallia(studentsIds as string[]).then(() => {
											setIsExportingToGallia(false);
										});
									}}
									label="Exporter vers Gallia"
								/>
							)}
					</div>
				</ToastCustom>
			)}
		</div>
	);
}
