import React, { useState } from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { FileInput } from "@designSystem/molecules/FileInput";
import { FilesPrimary } from "@designSystem/molecules/Files";
import { Modal } from "@designSystem/organisms/modal/Modal";

import {
	deleteFile,
	editApplication,
} from "@containers/student/Applications/core/api/ApplicationDetailAPI";

export function OnHoldApplicationModal({
	onClose,
	show,
	uploadFile,
	applicationProps,
}) {
	const { application, setApplication } = applicationProps;
	const [response, setResponse] = useState([]);

	const [cv, setCv] = useState([]);
	const [motivation, setMotivation] = useState([]);
	const [messageError, setMessageError] = useState("");
	const [isLoadingResume, setIsLoadingResume] = useState(false);
	const [isLoadingMotivation, setIsLoadingMotivation] = useState(false);

	const deleteDocument = async (file, type) => {
		if (type === "motivation") {
			setIsLoadingMotivation(true);
			await deleteFile({ id: file.id });
			setMotivation(null);
			setIsLoadingMotivation(false);
		}
		if (type === "cv") {
			setIsLoadingResume(true);
			await deleteFile({ id: file.id });
			setCv(null);
			setIsLoadingResume(false);
		}
	};

	const studentResponse = (
		_stateValue,
		isTreated,
		treatmentReason,
		messages,
	) => {
		if (cv.length > 0) {
			uploadFile(cv, "cv");
		} else {
			uploadFile(motivation, "motivation");
		}

		editApplication({
			id: application.id,
			state: "update",
			isTreated: isTreated || null,
			treatmentReason: treatmentReason || null,
			messages: messages || null,
		}).then(() => {
			setApplication({
				...application,
				state: "update",
				isTreated: isTreated || null,
				treatmentReason: treatmentReason || null,
				messages: messages || null,
			});
		});
	};

	const modalBody = () => {
		switch (application.treatmentReason) {
			case "motivationLetter":
				return (
					<div>
						<p className="mb-md text-xsm text-primary-700P">
							Ton école a besoin que tu modifies ta lettre de motivation avant
							de pouvoir traiter ta candidature. Voici son message :
						</p>
						<TextArea
							disabled
							height="88px"
							className="mb-md"
							value={application.messages?.[0]}
						/>
						{motivation[0] ? (
							<FilesPrimary
								isLoading={isLoadingMotivation}
								title={motivation[0]?.path}
								onDelete={() => {
									setMotivation(null);
									deleteDocument(motivation[0], "motivation");
								}}
							/>
						) : (
							<FileInput
								isLoading={isLoadingMotivation}
								title="Dépose ton fichier ou clique ici pour l’importer"
								subtitle="Taille max : 1Mo - Formats : .zip, .pdf, .docx, .ppt"
								onSuccess={(e) => {
									setMotivation(e);
									setMessageError("");
								}}
								maxSize={1}
								acceptFormats={{ "application/pdf": [] }}
							/>
						)}
						{messageError && (
							<div className="text-xsm text-error-dark">{messageError}</div>
						)}
						<p className="mb-sm mt-sm text-xsm font-bold text-primary-700P">
							Commentaire
						</p>
						<TextArea
							height="88px"
							label="Message à envoyer à l'école : (optionnel)"
							placeholder="Ton message ici..."
							value={application.messages?.[1]}
							onBlur={(e) => {
								setResponse([application.messages[0], e.target.value]);
							}}
						/>
					</div>
				);
			case "updateCV":
				return (
					<div>
						<p className="mb-md text-xsm text-primary-700P">
							Ton école a besoin que tu mette à jour ton CV avant de pouvoir
							traiter ta candidature . Voici son message :
						</p>
						<TextArea
							disabled
							height="88px"
							className="mb-md"
							value={application.messages?.[0]}
						/>

						{cv[0] ? (
							<FilesPrimary
								isLoading={isLoadingResume}
								title={cv[0].path}
								onDelete={() => {
									setCv(null);
									deleteDocument(cv[0], "cv");
								}}
							/>
						) : (
							<FileInput
								isLoading={isLoadingResume}
								title="Dépose ton fichier ou clique ici pour l’importer"
								subtitle="Taille max : 1Mo - Formats : .zip, .pdf, .docx, .ppt"
								onSuccess={(e) => {
									setCv(e);
									setMessageError("");
								}}
								maxSize={1}
								acceptFormats={{ "application/pdf": [] }}
							/>
						)}
						{messageError && (
							<div className="text-xsm text-error-dark">{messageError}</div>
						)}
						<p className="mb-sm mt-sm text-xsm font-bold text-primary-700P">
							Commentaire
						</p>
						<TextArea
							height="88px"
							label="Message à envoyer à l'école : (optionnel)"
							placeholder="Ton message ici..."
							value={application.messages?.[1]}
							onBlur={(e) => {
								setResponse([...application.messages, e.target.value]);
							}}
						/>
					</div>
				);
			default:
				return (
					<div>
						<p className="mb-md text-xsm text-primary-700P">
							Ton école a besoin d&apos;informations complémentaires avant de
							pouvoir traiter ta candidature. Voici son message :
						</p>
						<TextArea
							disabled
							height="88px"
							className="mb-md"
							value={application.messages?.[0]}
						/>
						<p className="mb-sm text-xsm font-bold text-primary-700P">
							Écris ci-dessous ta réponse :
						</p>
						<TextArea
							height="88px"
							label="Message à envoyer à l'école :"
							placeholder="Ton message ici..."
							value={application.messages?.[1]}
							onBlur={(e) => {
								setResponse([...application.messages, e.target.value]);
							}}
						/>
						{messageError && (
							<div className="text-xsm text-error-dark">{messageError}</div>
						)}
					</div>
				);
		}
	};
	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title="Mettre à jour la candidature"
			body={modalBody()}
			buttonsRight={[
				<ButtonSecondary
					label="Annuler"
					onClick={() => {
						onClose();
					}}
				/>,
				<ButtonPrimary
					label="Envoyer"
					onClick={() => {
						if (
							(application.treatmentReason === "requestInformation" ||
								application.treatmentReason === "geographicalArea") &&
							response.length !== 2
						) {
							if (response[1] !== "") {
								setMessageError("Ce champs est obligatoire.");
							}
						} else if (
							(application.treatmentReason === "updateCV" ||
								application.treatmentReason === "motivationLetter") &&
							cv.length === 0 &&
							motivation.length === 0
						) {
							setMessageError("Veuillez renseigner un document.");
						} else {
							studentResponse(
								application.state,
								application.isTreated,
								application.treatmentReason,
								response,
							);
							onClose();
						}
					}}
				/>,
			]}
		/>
	);
}
