import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavLink, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import "./StudentDetail.scss";

import { arrowBackFill } from "@assets/Icons";

import { getCookie } from "@tools/Cookies";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import {
	success,
	updateToastWithError,
	updateToastWithSuccess,
} from "@tools/Toasts";

import { EStatus } from "@/types/userTypes";
import {
	getNeighbors,
	getSchoolPromotions,
	getSchoolTag,
	getSideData,
	getStudent,
	updateStudentTag,
} from "@containers/school/Students/StudentDetail/StudentDetailAPI";
import { Files } from "@containers/school/Students/StudentDetail/ui/Files";
import { Informations } from "@containers/school/Students/StudentDetail/ui/Informations";
import { Profile } from "@containers/school/Students/StudentDetail/ui/Profile";
import { Skills } from "@containers/school/Students/StudentDetail/ui/Skills";
import { Statistics } from "@containers/school/Students/StudentDetail/ui/Statistics";
import { StudentDetailLoader } from "@containers/school/Students/StudentDetail/ui/StudentDetailLoader";
import { Tags } from "@containers/school/Students/StudentDetail/ui/Tags";

export function StudentDetail() {
	const location = useLocation();
	const { user } = useContext(GlobalContext);
	const { structureView, setTitle } = useOutletContext();
	const navigate = useNavigate();

	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	const [loading, setLoading] = useState(true);
	const [student, setStudent] = useState([]);
	const [applications, setApplications] = useState([]);
	const [promotions, setPromotions] = useState([]);
	const [studentTags, setStudentTags] = useState([]);
	const [informationsLoading, setInformationsLoading] = useState(true);
	const [previousStudentId, setPreviousStudentId] = useState();
	const [nextStudentId, setNextStudentId] = useState();
	const [tags, setTags] = useState([]);
	const [schoolTags, setSchoolTags] = useState([]);
	const [hardSkills, setHardSkills] = useState([]);
	const [softSkills, setSoftSkills] = useState([]);
	const [formations, setFormations] = useState([]);
	const [professionalExperiences, setProfessionalExperiences] = useState([]);
	const [projects, setProjects] = useState([]);
	const [neighborsLoading, setNeighborsLoading] = useState(true);

	let filtersList = {};

	if (
		window.location.pathname.split("/")[1] === "cvboard" &&
		getCookie("cvboardFilters")
	) {
		filtersList = JSON.parse(getCookie("UserFilterForCVtek")).currentFilters;
	} else if (
		window.location.pathname.split("/")[1] === "students" &&
		getCookie("UserFilterForStudentManagement")
	) {
		filtersList = JSON.parse(
			getCookie("UserFilterForStudentManagement"),
		).currentFilters;
	}

	const getData = async (id) => {
		if (filtersList.tab) {
			switch (filtersList.tab) {
				case "without":
					filtersList.exists = { cv: false };
					break;
				case "validated":
					filtersList.cv_state = "validated";
					break;
				case "toValidate":
					filtersList.cv_state = "toValidate";
					break;
				case "editing":
					filtersList.cv_state = "editing";
					break;
				default:
					filtersList.exists = { cv: true };
					break;
			}
			delete filtersList.tab;
		} else {
			switch (filtersList.state) {
				case "active":
					filtersList.order = [{ lastConnexion: "DESC" }];
					break;
				case "waitConnection":
					filtersList.order = [{ id: "DESC" }];
					break;
				default:
					if (currentFilters.status_list.length === 0) {
						filtersList.status_list = [
							EStatus.TOPLACE,
							EStatus.PLACED,
							EStatus.BEING_SIGNED,
						];
					}

					break;
			}
		}

		if (filtersList.state === "all") filtersList.state = undefined;
		filtersList.page = undefined;
		setLoading(true);
		const { user } = await getStudent(id);
		setTitle(`${user.firstname} ${user.lastname}`);
		setStudent(user);

		setStudentTags(user.tags?.collection);
		setSchoolTags(
			user.tags
				?.filter((x) => x.type === "school")
				.sort((a, b) => a.name.localeCompare(b.name, "en", { numeric: true })),
		);
		setHardSkills(
			user.tags
				?.filter((x) => x.type === "hardSkill")
				.sort((a, b) => a.name.localeCompare(b.name, "en", { numeric: true })),
		);
		setSoftSkills(
			user.tags
				?.filter((x) => x.type === "softSkill")
				.sort((a, b) => a.name.localeCompare(b.name, "en", { numeric: true })),
		);
		setLoading(false);
		loadNeighbors(id);
		setInformationsLoading(true);
		getSideData(id).then(({ user }) => {
			setApplications(user.applications.collection);

			setFormations(user.formations.collection);
			setProfessionalExperiences(user.professionalExperiences.collection);
			setProjects(user.projects.collection);

			setInformationsLoading(false);
		});

		if (!tags || !tags.length) {
			getSchoolTag(user?.school?.id).then(({ collection }) => {
				setTags(collection);
			});
		}
	};

	const loadNeighbors = (currentId) => {
		setNeighborsLoading(true);
		getNeighbors(currentId, structureView, filtersList).then(
			({ previousUserId, nextUserId }) => {
				setPreviousStudentId(previousUserId);
				setNextStudentId(nextUserId);
				setNeighborsLoading(false);
			},
		);
	};

	const editStudentTags = async (type, tags) => {
		const toastId = toast.loading("Sauvegarde en cours...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		let copyTagsSelected = [];

		switch (type) {
			case "softSkill":
				copyTagsSelected = [...hardSkills, ...schoolTags, ...tags];
				setSoftSkills([...tags]);
				break;
			case "school":
				copyTagsSelected = [...hardSkills, ...softSkills, ...tags];
				setSchoolTags([...tags]);
				break;
			case "hardSkill":
				copyTagsSelected = [...softSkills, ...schoolTags, ...tags];
				setHardSkills([...tags]);
				break;
			default:
				copyTagsSelected = [
					...hardSkills,
					...softSkills,
					...schoolTags,
					...tags,
				];
		}

		const updateResponse = await updateStudentTag({
			id: student.id,
			tags: copyTagsSelected?.map((tag) => tag.id),
		});

		if (updateResponse.responseLabel === "success") {
			updateToastWithSuccess(toastId, "Tags mis à jour avec succès");
			setStudentTags(copyTagsSelected);
		} else {
			updateToastWithError(toastId, "Erreur lors de la mise à jour des tags");
		}
	};

	useEffect(() => {
		if (loading)
			getData(`/referent/sps_students/${location.pathname.split("/")[2]}`);

		if (location?.state?.isCreateStudent) {
			success(
				"Étudiant créé avec succès. Un mail d'invitation lui a été envoyé.",
			);
			location.state.created = null;
		}
	}, []);

	useEffect(() => {
		getSchoolPromotions(structureView).then(({ collection }) => {
			const responseFilter = collection.map((promo) => {
				return {
					id: promo.id,
					label: promo.name,
					value: promo.name,
					campus: promo.campus,
				};
			});
			setPromotions(responseFilter);
		});
	}, [structureView]);
	return (
		<main>
			<header className="flex justify-between">
				<div className="flex items-center">
					<NavLink
						exact
						to={
							location.pathname.split("/")[1] === "cvboard"
								? "/cvboard"
								: "/students"
						}
						className="text-decoration-none"
					>
						<ButtonQuinary icon={arrowBackFill} />
					</NavLink>
					<p className="text-back">Revenir à la liste</p>
				</div>
				<aside className="flex items-center">
					<ButtonSecondary
						disabled={neighborsLoading || loading || !previousStudentId}
						label="Précédent"
						onClick={() => {
							navigate(`/students/${previousStudentId}`);
							getData(previousStudentId, user?.school);
						}}
					/>

					<ButtonPrimary
						disabled={neighborsLoading || loading || !nextStudentId}
						label="Suivant"
						onClick={() => {
							navigate(`/students/${nextStudentId}`);
							getData(
								`/${getCookie("interface")}/sps_students/${nextStudentId}`,
								user?.school,
							);
						}}
						className="ml-sm"
					/>
				</aside>
			</header>

			{loading ? (
				<StudentDetailLoader />
			) : (
				<div>
					{student && (
						<section className="mb-xlg mt-lg flex gap-sm">
							<div className=" h-fit-content flex w-5/12 flex-col items-center">
								<Profile student={student} promotions={promotions} />
								<Files student={student} />
								<Tags
									schoolTags={schoolTags}
									editStudentTags={editStudentTags}
									student={student}
									tags={tags}
								/>
								<Skills
									student={student}
									classname="p-md"
									softSkills={softSkills}
									hardSkills={hardSkills}
									editStudentTags={editStudentTags}
									studentTags={studentTags}
									tags={tags}
								/>
							</div>
							<div className="student-detail-left flex w-7/12 flex-col items-center">
								<Statistics student={student} applications={applications} />
								<Informations
									student={student}
									setStudent={setStudent}
									applications={applications}
									updateData={getData}
									formations={formations}
									professionalExperiences={professionalExperiences}
									projects={projects}
									load={informationsLoading}
								/>
							</div>
						</section>
					)}
				</div>
			)}
		</main>
	);
}
