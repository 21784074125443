import type { Author, MessageType } from "@/types/mailbox";
import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import { isSameDay, isSameHour, isSameMinute } from "date-fns";
import { format } from "date-fns-tz";
import { fr } from "date-fns/locale";
import DOMPurify from "dompurify";
import React, { useContext, useMemo, useState } from "react";

import { emailOutline, peopleOutline, phoneOutline } from "@assets/Icons";

import { ALLOWED_TAGS_FOR_MESSAGE_VIEW } from "@constants/Mailbox";

import { Avatar } from "@designSystem/atoms/Avatar";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { getUserAvatarPath } from "@tools/Users";

export default function Message({
	className,
	message,
	prevMessage,
}: {
	className?: string;
	message: MessageType;
	prevMessage: MessageType | undefined;
}) {
	const { user, currentInterface } = useContext(GlobalContext);

	const {
		spsStudent,
		spsReferent,
		content,
		datetime,
		isSendBySMS,
		isSendByMail,
		isGroupedMessage,
	} = message;
	const author = spsStudent || (spsReferent as Author);
	const [isOwner, setIsOwner] = useState<boolean>(false);
	const INFOS = [
		{
			id: 0,
			icon: peopleOutline,
			toolipInformation: "Message Multiple",
			active: isGroupedMessage,
		},
		{
			id: 1,
			icon: emailOutline,
			toolipInformation: "Reçu par mail",
			active: isSendByMail,
		},
		{
			id: 2,
			icon: phoneOutline,
			toolipInformation: "Reçu par SMS",
			active: isSendBySMS,
		},
	];

	const checkDisplayDate = () => {
		if (datetime && prevMessage?.datetime) {
			return !isSameDay(new Date(datetime), new Date(prevMessage.datetime));
		}
		return true;
	};
	const displayHour = () => {
		if (datetime && prevMessage?.datetime) {
			return !(
				isSameHour(new Date(datetime), new Date(prevMessage.datetime)) &&
				isSameMinute(new Date(datetime), new Date(prevMessage.datetime))
			);
		}
		return true;
	};
	const displayMessage = () => {
		if (!prevMessage) return true;
		const prevMessageAuthor =
			prevMessage?.spsReferent || (prevMessage?.spsStudent as Author);
		return !prevMessageAuthor.id || prevMessageAuthor.id !== author.id;
	};
	useMemo(() => {
		if (author?.id) {
			setIsOwner(author.id === user?.id);
		}
	}, [message]);
	return (
		<div key={message.id} className={`flex flex-col items-center ${className}`}>
			<div className="text-xxxsm font-semibold uppercase text-primary-200">
				{checkDisplayDate() &&
					datetime &&
					format(new Date(datetime), "d MMMM yyyy", {
						locale: fr,
					})}
			</div>

			<div
				className={`flex w-full items-start justify-between gap-sm ${
					isOwner ? "flex-row-reverse" : ""
				}`}
			>
				{displayMessage() ? (
					<Avatar
						firstname={author.firstname}
						lastname={author.lastname}
						size="xs"
						image={user ? getUserAvatarPath(user) : ""}
					/>
				) : (
					<div className="w-[32px]" />
				)}

				<div className="flex w-full flex-col">
					<div
						className={`flex w-full items-center justify-between ${
							isOwner ? "flex-row-reverse" : ""
						}`}
					>
						<div className="mb-xxxsm text-xsm font-semibold text-primary-700P">
							{displayMessage() && (
								<>
									{author?.firstname} {author?.lastname}
									{" • "}
								</>
							)}
							<span className="text-xxsm text-primary-300">
								{displayHour() &&
									`${
										datetime &&
										format(new Date(datetime), "HH:mm", {
											locale: fr,
										})
									}`}
							</span>
						</div>
					</div>
					<div
						className={`flex flex-col group mt-xxsm ${
							isOwner ? "items-end" : ""
						}`}
					>
						{content && (
							<div
								className={`ql-editor break-all flex flex-col rounded-md w-fit !px-xsm !py-xxsm ${
									isOwner
										? "bg-primary-500 text-white rounded-br-none"
										: "bg-primary-100 rounded-bl-none"
								} ${currentInterface === "student" ? "mb-xxsm" : ""}`}
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(content, {
										ALLOWED_TAGS: ALLOWED_TAGS_FOR_MESSAGE_VIEW,
									}),
								}}
							/>
						)}
						{currentInterface === "school" && (
							<div className="flex gap-xsm w-fit">
								{INFOS.map((info) => (
									<>
										{info.active && (
											<>
												<Icon
													id={`tooltip-info-${info.id}`}
													className="text-primary-200"
													icon={info.icon}
												/>
												<TooltipContainer
													anchorId={`#tooltip-info-${info.id}`}
													makeClickable={false}
													children={info.toolipInformation}
													place="top-start"
												/>
											</>
										)}
									</>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
