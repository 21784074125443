import API, { graphqlRequestConstructor } from "@tools/API";

export async function fetchMetabaseEmbed(data: {
	campuses: string[];
	promotions: string[];
	referents: string[];
}) {
	const response = await API.post("get_metabase_dashboard", data);
	return response.data.embed;
}

export async function fetchCampuses() {
	const data = {
		query: `query{
			campuses (itemsPerPage: 1000) {
				collection {
					id
					name
				}
			}
    }`,
	};

	const response = graphqlRequestConstructor(data, "fetchCampuses");
	return (await response).data.campuses.collection;
}

export async function fetchPromotions(campusUris: string[]) {
	const data = {
		query: `query {
			promotions (itemsPerPage: 1000, type: "student", archived: false, campus_list: ${JSON.stringify(campusUris)}) {
				collection {
					id
					name
				}
			}
		}`,
	};

	const response = graphqlRequestConstructor(data, "fetchPromotions");
	return (await response).data.promotions.collection;
}

export async function fetchReferents() {
	const data = {
		query: `query{
			spsReferents (itemsPerPage: 1000}) {
				collection {
					id
					firstname
					lastname
				}
			}
		}`,
	};

	const response = graphqlRequestConstructor(data, "fetchReferents");
	return (await response).data.spsReferents.collection;
}
