import useDebounce from "@hooks/useDebounce";
import Tippy from "@tippyjs/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	archiveFill,
	archiveOutline,
	heartFill,
	heartOutline,
	layoutOutline,
	listFill,
	plusOutline,
	searchFill,
} from "@assets/Icons";

import { getCookie, setCookie } from "@tools/Cookies";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { ToggleButtonGroupLarge } from "@designSystem/atoms/ToggleButtonGroup";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import { errorToast, success } from "@tools/Toasts";

import { littleConfetti } from "@tools/Confetti";
import { createApplication } from "../../core/api/ApplicationsManagementAPI";

import { NewApplicationModal } from "./HeadBar/NewApplicationModal";
export function HeadBar({ getApplications, setView, filtersProps, setLoad }) {
	const navigate = useNavigate();

	const { filters, setFilters } = filtersProps;
	const [displayNewAppModal, setDisplayNewAppModal] = useState(false);
	const [savedData] = useState({});
	const [search, setSearch] = useState(filters.search ?? "");
	const debouncedValue = useDebounce(search);

	const newApplication = (values) => {
		const newApp = {
			job: values.job,
			step: values.step,
			type: values.type,
			contract: values.contract,
			organizationV2: {
				name: values.company,
				logo: values.logo,
			},
			offerLink: values.offerLink,
			favorite: false,
			accepted: null,
		};

		if (values.step === "sent") {
			newApp.sentDate = moment().format();
		}
		createApplication(newApp).then((rep) => {
			if (rep.responseLabel !== "success") {
				errorToast(
					"Erreur lors de la création de la candidature. Veuillez réessayer.",
				);
				return;
			}
			success("Candidature créée avec succès.");
			getApplications();
			navigate("/applications", {
				state: {
					isApplicationCreate: true,
				},
			});
		});

		if (values.step === "interview") littleConfetti();
	};

	const getViewIndex = (view = "columns") => {
		const viewCpy = getCookie("view") ? getCookie("view") : view;
		switch (viewCpy) {
			case "list":
				return 1;
			default:
				return 0;
		}
	};

	const groupButton = [
		{
			icon: layoutOutline,
			onClick: () => {
				setLoad(true);
				setView("columns");
				setCookie("view", "columns");
				getViewIndex("columns");
				getApplications();
			},
		},
		{
			icon: listFill,
			active: true,
			onClick: () => {
				setLoad(true);
				setView("list");
				setCookie("view", "list");
				getViewIndex("list");
				getApplications();
			},
		},
	];

	useEffect(() => {
		if (search !== filters.search) {
			setFilters({ ...filters, search });
		}
	}, [debouncedValue]);

	return (
		<div className="tools-bar-applications mb-lg flex w-full flex-col items-center justify-between pl-0">
			<div className="flex w-full flex-col justify-between gap-sm md:flex-row ">
				<div className="flex w-full gap-xsm md:w-4/12">
					<InputLargePrimary
						icon={searchFill}
						placeholder="Rechercher..."
						className="w-full md:w-11/12"
						type="text"
						value={filters.job}
						onChange={(e) => {
							e.target.value = e.target.value.replace(";", "");
							setSearch(e.target.value);
						}}
					/>
				</div>
				<div className="flex w-full gap-xsm md:w-fit">
					<ToggleButtonGroupLarge
						buttons={groupButton}
						className="w-2/12"
						selectedIndex={getViewIndex}
					/>
					<Tippy
						content="Favoris"
						textContent=""
						theme="primary"
						animation="fade"
						placement="top"
						zIndex={5}
					>
						<span className="ml-xxsm cursor-pointer">
							<ButtonTertiary
								icon={!filters.favorite ? heartOutline : heartFill}
								iconColor="text-primary-700P"
								activeIcon={heartFill}
								onClick={() => {
									setFilters({
										...filters,
										favorite: filters.favorite === true ? null : true,
									});
								}}
							/>
						</span>
					</Tippy>
					<Tippy
						content="Archivés"
						textContent=""
						theme="primary"
						animation="fade"
						placement="top"
						zIndex={5}
					>
						<span className="ml-xxsm cursor-pointer">
							<ButtonTertiary
								icon={!filters.archived ? archiveOutline : archiveFill}
								iconColor="text-primary-700P"
								activeIcon={archiveFill}
								onClick={() => {
									setFilters({ ...filters, archived: !filters.archived });
								}}
							/>
						</span>
					</Tippy>
					<ButtonPrimary
						icon={plusOutline}
						activeIcon={plusOutline}
						className="w-8/12"
						label="Candidature"
						onClick={() => {
							setDisplayNewAppModal(true);
						}}
					/>
				</div>
			</div>

			<NewApplicationModal
				show={displayNewAppModal}
				onClose={() => {
					setDisplayNewAppModal(false);
				}}
				savedData={savedData}
				onApply={(values) => {
					newApplication(values);
				}}
			/>
		</div>
	);
}
