import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";

import { trash2Outline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { formatDate } from "@tools/Dates";

export function ManageNoteModal({ show, onClose, note, onApply, onDelete }) {
	return (
		<Formik
			initialValues={{
				note: note.content,
			}}
			enableReinitialize
			validate={(values) => {
				const errors = {};
				if (!values.note) {
					errors.note = "Veuillez remplir le champs afin d'ajouter une note";
				}
				return errors;
			}}
			onSubmit={(values, { resetForm }) => {
				onApply(note.id, values.note);
				resetForm({});
				onClose();
			}}
		>
			{({ values, handleSubmit, resetForm, setFieldValue }) => (
				<Modal
					show={show}
					onClose={() => {
						onClose();
						resetForm();
					}}
					title={`Note du ${formatDate(note.date)}`}
					body={
						<div>
							<TextArea
								label="Notes"
								placeholder="Ecris ta note ici..."
								value={values.note}
								onChange={(value) => {
									setFieldValue("note", value);
								}}
								height={307}
							/>
						</div>
					}
					buttonsRight={[
						<ButtonSecondary
							key="cancelBTN"
							onClick={() => {
								resetForm();
								onClose();
							}}
							label="Annuler"
						/>,
						<ButtonPrimary
							key="confirmBTN"
							onClick={() => {
								handleSubmit();
							}}
							label="Confirmer"
						/>,
					]}
					buttonsLeft={[
						<ButtonQuaternary
							key="deleteBTN"
							onClick={() => {
								onDelete(note.id);
								onClose();
							}}
							icon={trash2Outline}
						/>,
					]}
				/>
			)}
		</Formik>
	);
}
ManageNoteModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	note: PropTypes.shape({
		id: PropTypes.number,
		content: PropTypes.string,
		date: PropTypes.string,
	}).isRequired,
	onApply: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};
