import type { SelectionType } from "@/types/profileSharingTypes";
import type { UserCompleteType } from "@/types/userTypes";
import React, { useEffect, useState } from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Spinner } from "@designSystem/atoms/Spinner";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { errorToast, success } from "@tools/Toasts";

import {
	createSelection,
	exportSelectionUsersFiles,
	updateSelection,
} from "../../../CVBoardAPI";

import { EditOutline } from "@assets/Icons";
import { ShareLinkModal } from "@containers/school/CVBoard/ui/CVsList/TableView/ShareLinkModal";
import { ViewSelectionListModal } from "@containers/school/CVBoard/ui/CVsList/TableView/ViewSelectionListModal";
import { ShareSelectionEmailModal } from "@containers/school/ProfileSharing/ui/ShareSelectionEmailModal";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import {
	DOWNLOAD_SHARE_TYPE,
	EMAIL_SHARE_TYPE,
	LINK_SHARE_TYPE,
	SHARED_DATA_CV,
	SHARED_DATA_MOTIVATION_LETTER,
	SHARED_DATA_PORTFOLIO,
	SHARE_TYPES,
	ShareV2,
} from "./ShareCVModal/ShareV2";

export type CVsListProps = {
	selectedCVs: Partial<UserCompleteType>[];
	setSelectedCVs: Function;
};

type ShareCVModalProps = {
	show: boolean;
	onClose: Function;
	CVsListProps: CVsListProps;
	noModifiable?: boolean;
	autocomplete?: { company: string; jobname: string };
	selectionToEdit?: Partial<SelectionType>;
	catchSelection?: (selectionToCatch: Partial<SelectionType>) => void;
	offerId?: string | null;
};

const SHARE_PAGE = 1;
const SELECTION_PAGE = 2;
const LINK_PAGE = 3;
const EMAIL_PAGE = 4;

export type ShareCVModalPageType =
	| typeof SHARE_PAGE
	| typeof SELECTION_PAGE
	| typeof LINK_PAGE
	| typeof EMAIL_PAGE;

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export function CreateSelectionModal({
	show,
	onClose,
	CVsListProps,
	noModifiable = false,
	autocomplete,
	selectionToEdit,
	catchSelection,
	offerId = null,
}: ShareCVModalProps) {
	const [page, setPage] = useState<ShareCVModalPageType>(SHARE_PAGE);
	const { selectedCVs, setSelectedCVs } = CVsListProps;
	const [selection, setSelection] = useState<
		Partial<SelectionType> | undefined
	>(undefined);
	const [offerName, setOfferName] = useState<string>(
		autocomplete?.jobname || "",
	);
	const [offerCompany, setOfferCompany] = useState<string>(
		autocomplete?.company || "",
	);
	const [sharedData, setSharedData] = useState<number>(SHARED_DATA_CV);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
	const [load, setLoad] = useState(false);
	const [shareType, setShareType] = useState(EMAIL_SHARE_TYPE);
	useEffect(() => {
		setShareType(selectionToEdit?.type || EMAIL_SHARE_TYPE);
		setSharedData(selectionToEdit?.sharedData || SHARED_DATA_CV);
	}, [selectionToEdit]);

	const reset = () => {
		setPage(SHARE_PAGE);
		setShareType(EMAIL_SHARE_TYPE);
		setOfferCompany("");
		setOfferName("");
		setSelection(undefined);
		setLoad(false);
		setSharedData(SHARED_DATA_CV);
		setSelectedCVs([]);
		onClose();
	};
	const generateSelection = async () => {
		if (offerName && offerCompany) {
			setLoad(true);
			let newSelection: Partial<SelectionType> | undefined;
			let sucessMessage = "La sélection a bien été créée !";
			if (!selectionToEdit) {
				const stringifyInputsForCreation = JSON.stringify({
					name: offerName,
					crmCompanyName: offerCompany,
					type: SHARE_TYPES[shareType].type,
					sharedData,
					users: selectedCVs.map((student) => student.id),
				})?.replace(/"([^"]+)":/g, "$1:") as string;
				const response = await createSelection(stringifyInputsForCreation);
				newSelection = response.data.createSelection.selection;
			} else {
				const stringifyInputsForUpdate = JSON.stringify({
					id: selectionToEdit.id,
					type: SHARE_TYPES[shareType].type,
					sharedData,
					users: selectedCVs
						.filter(
							(student) =>
								!selectionToEdit.selectionUsers?.collection.some(
									(selectionUser) => selectionUser.user.id === student.id,
								),
						)
						.map((student) => student.id),
				})?.replace(/"([^"]+)":/g, "$1:") as string;
				const response = await updateSelection(stringifyInputsForUpdate);
				newSelection = response.data.updateSelection.selection;
				sucessMessage = "La sélection a bien été mise à jour !";
			}
			if (newSelection) {
				setSelection(newSelection);
				setPage(SHARE_TYPES[shareType].buttonPage ?? SHARE_PAGE);
				success(sucessMessage);
				if (catchSelection) catchSelection(newSelection);
			}
			setLoad(false);
		}
	};

	useEffect(() => {
		if (
			selectedCVs.length === 0 ||
			offerName.trim() === "" ||
			offerCompany.trim() === ""
		) {
			setButtonDisabled(true);
			return;
		}
		setButtonDisabled(false);
	}, [selectedCVs, offerName, offerCompany]);

	const downloadSelection = async (offerId: string | null) => {
		setLoad(true);
		const res = await exportSelectionUsersFiles(
			selectedCVs.map((cv) => cv.id?.split("/")[3] as string),
			(sharedData & SHARED_DATA_PORTFOLIO) !== 0,
			(sharedData & SHARED_DATA_MOTIVATION_LETTER) !== 0,
			offerId,
		);
		if (res.status !== 200) {
			errorToast("Une erreur est survenue lors du téléchargement");
			setLoad(false);
			return;
		}
		window.open(
			`${import.meta.env.VITE_BACK}/zip_export/${res.data.fileName}`,
			"_blank",
		);
		success("Les fichiers ont bien été téléchargés !");
		reset();
	};

	return (
		<>
			<Modal
				show={page === SHARE_PAGE && show}
				onClose={() => {
					reset();
				}}
				title={"Partager ces profils à une entreprise"}
				className="!min-h-[604px]"
				body={
					load ? (
						<div className="flex items-center justify-center">
							<Spinner />
						</div>
					) : (
						<ShareV2
							shareTypeProps={{ shareType, setShareType }}
							offerCompanyProps={{ offerCompany, setOfferCompany }}
							offerNameProps={{ offerName, setOfferName }}
							sharedDataProps={{ sharedData, setSharedData }}
							noModifiable={noModifiable}
						/>
					)
				}
				buttonsRight={[
					<ButtonSecondary
						key={0}
						onClick={() => {
							reset();
						}}
						label="Annuler"
					/>,
					<ButtonPrimary
						key={1}
						onClick={() => {
							switch (shareType) {
								case EMAIL_SHARE_TYPE:
								case LINK_SHARE_TYPE:
									generateSelection();
									break;
								case DOWNLOAD_SHARE_TYPE:
									downloadSelection(offerId || null);
									break;
							}
						}}
						disabled={buttonDisabled || load}
						label={SHARE_TYPES[shareType].buttonLabel}
					/>,
				]}
				buttonsLeft={[
					<InlineButton
						id="view-selected-students"
						icon={EditOutline}
						label={`Voir les étudiants sélectionnés (${selectedCVs.length})`}
						onClick={() => setPage(2)}
					/>,
				]}
			/>

			{page === SELECTION_PAGE && (
				<ViewSelectionListModal
					CVsListProps={CVsListProps}
					noModifiable={noModifiable}
					show={true}
					onClose={() => setPage(SHARE_PAGE)}
				/>
			)}

			{page === LINK_PAGE && (
				<ShareLinkModal show={true} onClose={reset} selection={selection} />
			)}

			{page === EMAIL_PAGE && (
				<ShareSelectionEmailModal
					show={page === EMAIL_PAGE}
					onClose={reset}
					selection={selection}
				/>
			)}
		</>
	);
}
