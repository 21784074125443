import React, { useContext, useEffect, useState } from "react";

import { MultiEmailInput } from "@containers/school/Partners/ui/Tabs/Invites/ui/MultiEmailInput";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { updateToastWithError, updateToastWithSuccess } from "@tools/Toasts";

import {
	type InviteCreateDataType,
	createInvites,
} from "@containers/school/Partners/PartnersAPI";

import { GlobalContext } from "@navigation/Router";
import { toast } from "react-toastify";
import { MailPreview } from "./MailPreview";

type MultiMailModalType = {
	title?: string;
	show: boolean;
	onClose?: () => void;
	existingInvites: string[];
};

export function MultiMailModal({
	title = "Inviter par mail",
	show,
	onClose = () => {},
	existingInvites,
}: MultiMailModalType) {
	type ErrorsType = { subject?: string; body?: string; recipients?: string };
	const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
	const [subjectInputValue, setSubjectInputValue] = useState<string>("");
	const [bodyInputValue, setBodyInputValue] = useState("");
	const [errors, setErrors] = useState<ErrorsType>({});
	const [load, setLoad] = useState(false);
	const maxEmails = 10;

	const { user } = useContext(GlobalContext);

	const validate = () => {
		const errors: ErrorsType = {};
		if (!subjectInputValue.length) {
			errors.subject = "Veuillez entrer l'objet de votre message";
		}
		if (!bodyInputValue.length) {
			errors.body = "Veuillez entrer le contenu de votre message";
		}
		if (!selectedEmails.length) {
			errors.recipients = "Veuillez entrer au moins un destinataire";
		} else if (selectedEmails.length > maxEmails) {
			errors.recipients = `Veuillez ne pas entrer plus de ${maxEmails} destinataires`;
		}
		setErrors(errors);
	};

	const sendMail = async () => {
		if (!show) return;
		setLoad(true);
		const loadingToast = toast.loading("Envoi des invitations en cours...", {
			isLoading: true,
			hideProgressBar: true,
			type: "default",
		});
		try {
			const mails: InviteCreateDataType[] = [];
			for (const email of selectedEmails) {
				const data: InviteCreateDataType = {
					body: bodyInputValue,
					subject: subjectInputValue,
					receiver: email,
				};
				mails.push(data);
			}
			const mailsSent = await createInvites(mails);
			updateToastWithSuccess(
				loadingToast,
				mailsSent.length > 1
					? `${mailsSent.length} utilisateurs invités.`
					: `${mailsSent.length} utilisateur invité.`,
			);
			setSelectedEmails([]);
			onClose();
		} catch {
			updateToastWithError(
				loadingToast,
				"Une erreur est survenue lors de l'envoi.",
			);
		} finally {
			setLoad(false);
		}
	};

	useEffect(() => {
		setSubjectInputValue(
			`Invitation - Rejoignez l’espace entreprise ${user?.schoolName}`,
		);
		setBodyInputValue(
			`<p>Bonjour,<br/><br/>Veuillez trouver dans ce mail le lien vous permettant d’accéder à l’espace entreprise <b>${user?.schoolName}</b>. Diffusez vos besoins de recrutements directement dans notre école et auprès de nos apprenants, accédez à notre CVthèque de candidats et trouvez votre futur alternant ! <br/>`,
		);
	}, [user?.schoolName]);

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			return;
		}
		sendMail();
	}, [errors]);

	return (
		<Modal
			buttonsRight={[
				<ButtonSecondary
					key="back"
					label="Retour"
					onClick={onClose}
					disabled={load}
				/>,
				<ButtonPrimary
					key="send"
					label="Envoyer"
					onClick={validate}
					disabled={load || !selectedEmails.length}
				/>,
			]}
			size="lg"
			onClose={onClose}
			show={show}
			title={title}
			body={
				<div className="flex gap-sm">
					<div className="w-full">
						<MultiEmailInput
							bannedEmails={existingInvites}
							disabled={load}
							errorMessage={errors.recipients}
							title="Destinataires"
							selectedEmails={selectedEmails}
							setSelectedEmails={setSelectedEmails}
							className="mb-sm"
						/>
						<p className="font-bold mb-xsm">Objet du mail</p>
						<InputLargePrimary
							disabled={load}
							errorMessage={errors.subject}
							placeholder="Objet"
							value={subjectInputValue}
							onChange={(e) => {
								setSubjectInputValue(e.target.value);
							}}
						/>
						<p className="font-bold mt-sm">Votre message</p>
						<RichTextEditor
							placeholder="Contenu de votre mail"
							value={bodyInputValue}
							modules={{
								toolbar: false,
								clipboard: {
									matchVisual: false,
								},
							}}
							className="text-[16px]"
							onChange={(e) => {
								setBodyInputValue(e);
							}}
						/>
						{errors.body && (
							<p className="text-xxxsm font-bold text-error-dark">
								{errors.body}
							</p>
						)}
					</div>

					<div className="w-full">
						<MailPreview message={bodyInputValue} subject={subjectInputValue} />
					</div>
				</div>
			}
		/>
	);
}
