import React from "react";
import Skeleton from "react-loading-skeleton";

import { getRandomItem } from "@tools/Arrays";

import { Divider } from "@designSystem/atoms/Divider";

import "react-loading-skeleton/dist/skeleton.css";

export function SpontaneousApplicationSchoolLoader() {
	const colors = ["#e9f5e4", "#fdf0e4", "", "#fbe6e1", "#e9f5f4"];

	const array: string[] = new Array(24).fill("");

	return (
		<div className="mt-sm flex w-full flex-wrap justify-between gap-4">
			{array.map((_item, index) => (
				<div
					className="flex w-full flex-col rounded-md bg-white p-sm shadow-lg md:w-[48%] lg:w-[30%] xl:w-[24%]"
					key={`skeleton-application-${index}`}
				>
					<div className="flex justify-between">
						<div className="flex">
							<Skeleton
								className="mr-sm"
								height={48}
								width={48}
								circle
								baseColor={getRandomItem(colors)}
							/>
							<div>
								<Skeleton height={16} width={64} />
								<Skeleton height={16} width={144} />
							</div>
						</div>
						<Skeleton height={24} width={24} />
					</div>
					<Divider className="mt-md" />
					<div className="mt-sm flex items-end justify-between">
						<div className="flex">
							<Skeleton
								height={16}
								width={16}
								baseColor={getRandomItem(["#e9f5e4", "#fbe6e1"])}
							/>
							<Skeleton height={16} width={100} className="ml-sm" />
						</div>
						<div className="flex">
							<Skeleton height={36} width={36} />
							<Skeleton height={36} width={36} className="ml-sm" />
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
