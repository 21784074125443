import { Icon, type IconifyIcon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import { closeOutline, eyeOffOutline, eyeOutline } from "@assets/Icons";

type PasswordInputType = {
	icon?: IconifyIcon;
	disabled?: boolean;
	errorMessage?: string | boolean;
	name?: string;
	label?: string;
	value?: string;
	className?: string;
	onBlur?: (e?: any) => void;
	onChange?: (e?: any) => void;
	placeholder?: string;
};

export function PasswordInput({
	icon,
	errorMessage,
	name = "password",
	value = "",
	className = "",
	onBlur,
	label,
	onChange,
	disabled = false,
	placeholder = "Votre mot de passe",
}: PasswordInputType) {
	const [passwordType, setPasswordType] = useState("password");
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const resetValue = () => {
		setInputValue("");

		if (onChange) onChange({ target: { value: "", name } });
	};

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	return (
		<div
			className={`input-large-password input-tertiary-container ${className}`}
		>
			{label && (
				<p
					className={`text-label-size mb-xxsm text-primary-300 ${
						disabled && "is-disabled"
					}`}
				>
					{label}
				</p>
			)}
			<div
				data-id="passwordInput"
				className={`relative flex min-h-[40px] w-full rounded-md border border-primary-150 bg-white px-4 py-2 transition${
					icon && " pl-0 "
				}`}
			>
				{icon && <Icon className="icon-input" icon={icon} />}
				<input
					autoComplete="on"
					onBlur={() => {
						if (onBlur) {
							onBlur();
						}
					}}
					name={name}
					placeholder={placeholder}
					value={inputValue}
					onChange={(e) => {
						if (onChange) {
							onChange(e);
						}
						setInputValue(e.target.value);
					}}
					type={passwordType}
					className={`w-11/12 truncate outline-0 ${icon ? " pl-12 " : ""} ${
						disabled ? "value-disabled" : ""
					}`}
				/>
				{passwordType === "password" ? (
					<Icon
						className="password-icon"
						icon={eyeOutline}
						onClick={togglePassword}
					/>
				) : (
					<Icon
						className="password-icon"
						icon={eyeOffOutline}
						onClick={togglePassword}
					/>
				)}

				<Icon
					onClick={() => {
						resetValue();
					}}
					className={`close-icon ${!inputValue && "hidden"}`}
					icon={closeOutline}
				/>
			</div>
			{errorMessage && (
				<p className="text-label-size pt-xxsm text-error-dark">
					{errorMessage}
				</p>
			)}
		</div>
	);
}
