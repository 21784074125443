import type { SchoolOfferType } from "@/types/offerTypes";
import React from "react";

import { BodyGrid } from "@designSystem/templates/page/BodyGrid";
import { Container } from "@designSystem/templates/page/Container";
import { Subcontainer } from "@designSystem/templates/page/Subcontainer";

import { useAppDispatch } from "@config/redux/store";
import { Memo } from "@designSystem/molecules/memo/ui/Memo";
import { OfferMemoGateway } from "../../core/offer.memoGateway";
import { OfferDetailsDescription } from "./tabs/offer/OfferDetailsDescription";
import { OfferDetailsDocuments } from "./tabs/offer/OfferDetailsDocuments";
import { OfferDetailsInformations } from "./tabs/offer/OfferDetailsInformations";
import { OfferDetailsShareStudentPannel } from "./tabs/offer/OfferDetailsShareStudentPannel";

export function InformationsTab({
	offerToEdit,
}: {
	offerToEdit: Partial<SchoolOfferType>;
}) {
	const dispatchEvent = useAppDispatch();
	const memoGateway = new OfferMemoGateway(dispatchEvent);
	return (
		<BodyGrid>
			<Container side="left">
				<Subcontainer>
					<OfferDetailsInformations />
					<OfferDetailsDescription />
					<OfferDetailsDocuments />
				</Subcontainer>
			</Container>
			<Container side="right">
				<Subcontainer>
					<OfferDetailsShareStudentPannel />
				</Subcontainer>
				<Subcontainer withoutBackground>
					<Memo memoId={offerToEdit?.id || ""} memoGateway={memoGateway} />
				</Subcontainer>
			</Container>
		</BodyGrid>
	);
}
