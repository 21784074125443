import { Icon, type IconifyIcon } from "@iconify/react";
import React from "react";
import uuid from "react-uuid";
import { TooltipContainer } from "./TooltipContainer";

type IconButtonProps = {
	icon: string | IconifyIcon;
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	disabled?: boolean;
	className?: string;
	tooltip?: string;
};

export const IconButton = ({
	icon,
	onClick,
	disabled = false,
	className = "",
	tooltip = "",
}: IconButtonProps) => {
	const id = uuid();
	return (
		<button
			id={`icon-button-tooltip-${id}`}
			onClick={onClick}
			disabled={disabled}
			className={className}
		>
			{tooltip !== "" && (
				<TooltipContainer
					anchorId={`#icon-button-tooltip-${id}`}
					makeClickable={false}
					children={tooltip}
					place="top"
				/>
			)}
			<Icon className={"h-6 w-6"} icon={icon} />
		</button>
	);
};
