import api from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function importOffer(
	id: string,
	inputs: { documents: (any | string)[]; step: string },
) {
	try {
		const { data } = await api.post(
			`create_application_from_offer/${id}`,
			inputs.documents,
		);
		return data;
	} catch (e) {
		return e;
	}
}

export async function getOfferDetailData(id: string, userId: string) {
	let rep = {};
	const data = {
		query: `
        query {
          user: spsStudent(id: "${userId}") {
            cv {
              id
              filePath
              size
            }
          },
          offer(id: "${id}") {
                id
                job
                location
                type
                url
                wayToApply
                description
                date
                contract
                responsable {
                  firstname
                  lastname
                  avatar {
                    filePath
                  }
                }
                motivationLetterRequired
                applications (spsStudent: "${userId}") {
                  collection {
                    id
                    step
                  }
                }
                pdf{
                  id
                  filePath
                }              
                displayCompanyName
                school {
                    name
                }
                qualification
                telework
                startDate
                tags
          }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function sendFile(formdata: FormData) {
	let rep = {};

	await api.post("media_objects", formdata).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function editApplication(inputs: object) {
	let rep = {};
	const data = {
		query: `mutation {
        updateApplication(input: ${stringifyRequestInputs(inputs)}) {
          application {
            id
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateApplication;
	});
	return rep;
}
