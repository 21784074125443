import type { CampusId, CampusType } from "@/types/campusTypes";
import type { ViewType } from "@/types/genericType";
import { useAppDispatch } from "@config/redux/store";
import { GlobalContext } from "@navigation/Router";
import { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { fetchCampusList } from "../core/usecases/fetchCampusList.usecase";

const UNAUTHORIZED_ROLE = "ROLE_CAMPUS_USER";

export const useCampusMultiselect = (
	defaultCoreCampusesSelected: CampusId[],
	useUserCampus: boolean,
	handleSelectCampus: (campusesSelected: CampusId[]) => void,
) => {
	const callFetchCampuses = async () => {
		if (!user?.school) {
			console.error("No school found in user context");
			return;
		}
		const response = await dispatch(
			fetchCampusList({
				schoolId: user?.school,
				setLoadingState: setIsLoading,
				handleError: setLoadingError,
			}),
		);
		if (response) {
			setCampuses(response);
		}
	};

	const formatCampusesToOptions = (campuses: CampusType[]) => {
		return campuses?.map((campus) => ({
			label: campus.name,
			value: campus.id,
		}));
	};

	const addUserCampusToDefaultValues = () => {
		if (!user || !user.campuses || user.campuses.length !== 1) {
			return;
		}
		const userCampus = user?.campuses[0].id;
		if (userCampus) {
			if (defaultValues?.includes(userCampus)) {
				return;
			}
			const newCampusesSelected = [...defaultValues, userCampus];
			setDefaultValues(newCampusesSelected);
			handleSelectCampus(newCampusesSelected);
		}
	};

	const checkAccessGranted = (): boolean => {
		if (user?.roles.includes(UNAUTHORIZED_ROLE)) {
			return false;
		}
		return true;
	};
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState<string | null>(null);
	const [campuses, setCampuses] = useState<CampusType[]>([]);
	const [defaultValues, setDefaultValues] = useState<CampusId[]>(
		defaultCoreCampusesSelected || [],
	);
	const { user } = useContext(GlobalContext);
	const { structureView }: { structureView: ViewType } = useOutletContext();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (useUserCampus) {
			addUserCampusToDefaultValues();
		}
		callFetchCampuses();
	}, [structureView]);

	return {
		campuses: formatCampusesToOptions(campuses),
		isLoading,
		loadingError,
		defaultValues,
		accessGranted: checkAccessGranted(),
	};
};
