import type {
	ConversationStatusType,
	studentSelectedType,
} from "@/types/mailbox";
import { useAppSelector } from "@config/redux/hook";
import useIsTextTruncated from "@hooks/useIsTextTruncated";
import { Icon } from "@iconify/react";
import DOMPurify from "dompurify";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";

import {
	arrowCircleRightFill,
	checkmarkCircle2Fill,
	plusCircleOutline,
} from "@assets/Icons";

import { convertDateFromNow } from "@tools/Dates";

import { Avatar } from "@designSystem/atoms/Avatar";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";

import {
	setConversation,
	setStudentSelected,
} from "../controller/mailBox.slice";
import { fetchMessage } from "../controller/mailBoxManagement.thunks";

type StudentCardType = {
	index: number;
	student: studentSelectedType;
};
export default function StudentCard({ index, student }: StudentCardType) {
	const dispatchMailBox = useDispatch();
	const { studentSelected } = useAppSelector((state) => state.mailBox);
	const studentRef = useRef(null);
	const textTruncated = useIsTextTruncated(studentRef);

	const handleClick = async () => {
		const lastMessage = student.conversation?.spsMessages?.collection[0];
		if (lastMessage?.isReadByStudent) {
			dispatchMailBox(
				fetchMessage({
					id: lastMessage.id,
					isReadByStudent: true,
				}) as any,
			);
		}

		dispatchMailBox(setStudentSelected(student));
		dispatchMailBox(setConversation(student.conversation));
	};

	const getIconConversationStatus = (
		status: ConversationStatusType = "resolved",
	) => {
		if (!status) {
			return <Icon className="ml-xsm text-2xl" icon={plusCircleOutline} />;
		}
		return (
			<Icon
				className={`ml-xsm text-2xl ${
					status === "unsolved" ? "text-accent-3" : "text-accent-4"
				}`}
				icon={
					status === "unsolved" ? arrowCircleRightFill : checkmarkCircle2Fill
				}
			/>
		);
	};

	return (
		<div
			className={`flex w-full cursor-pointer rounded bg-white px-sm py-3 ${
				studentSelected?.id === student.id
					? "border-l-4 border-solid border-primary-700P"
					: ""
			}`}
			onClick={handleClick}
		>
			<div className="w-10/12 flex">
				<Avatar
					className="mr-xsm"
					firstname={student.firstname}
					lastname={student.lastname}
					size="xs"
					image={
						student.avatar
							? `${import.meta.env.VITE_S3}${student.avatar?.filePath}`
							: null
					}
				/>
				<div className="flex flex-col w-10/12">
					{textTruncated && (
						<TooltipContainer
							anchorId={`#student-${index}`}
							makeClickable={false}
							children={`${student.firstname} ${student.lastname}`}
							place="top"
						/>
					)}

					<span
						id={`student-${index}`}
						ref={studentRef}
						className={`text-primary-${
							studentSelected?.id === student.id ? "700P" : "300"
						} truncate text-xxsm font-bold mb-xsm flex flex-wrap`}
					>
						{student.firstname}{" "}
						<span className="truncate ml-xxsm">{student.lastname}</span>
					</span>

					<div
						className="!text-xxxsm line-clamp-2"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								student.conversation?.spsMessages?.collection?.[0]?.content
									? student.conversation?.spsMessages?.collection?.[0]?.content
									: "Démarrer une conversation",
								{
									ALLOWED_TAGS: ["p"],
									FORBID_ATTR: ["class"],
									FORBID_TAGS: ["style"],
								},
							),
						}}
					/>
				</div>
			</div>
			<div className="flex w-2/12 flex-col items-end gap-xxsm">
				<p className="line-clamp-1 text-xxxsm text-primary-300">
					{student.conversation?.spsMessages?.collection?.[0]?.datetime
						? convertDateFromNow(
								student.conversation?.spsMessages?.collection?.[0]?.datetime,
								true,
							)
						: ""}
				</p>
				{getIconConversationStatus(student.conversation?.status)}
			</div>
		</div>
	);
}
