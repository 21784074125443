import { SystemIDProvider } from "@config/core/system.id-provider";
import type { AppState } from "@config/redux/store";
import useDebounce from "@hooks/useDebounce";
import { GlobalContext } from "@navigation/Router";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { IMemoGateway } from "../core/memo.gateway";

type PresenterProps = {
	memoId: string;
	memoGateway: IMemoGateway;
	defaultValue?: string;
};
export const useMemoComponent = ({
	memoId,
	memoGateway,
	defaultValue,
}: PresenterProps) => {
	const changeThisMemo = (value: string) => {
		setMemoBody(value);
	};

	const retrieveMemo = async () => {
		if (memoGateway) {
			const memo = await memoGateway.retrieveMemo(memoId, defaultValue || "");
			setMemoBody(memo.body);
			if (memo.initialValue) setInitialValue(memo.initialValue);
			setIsInitialized(true);
		}
	};

	const callSaveAction = async (value: string) => {
		if (!isInitialized) {
			setHasAnError(false);
			return;
		}
		setIsSaving(true);
		if (memoGateway) {
			const hasError = await memoGateway.saveThisMemo(
				memoId,
				value,
				initialValue,
			);
			setHasAnError(hasError);
		}
		setIsSaving(false);
		setHasCompletedSaving(true);
		return value;
	};

	const uniqueId = new SystemIDProvider().generate();
	const { user } = useContext(GlobalContext);

	const { targetCompany } = useSelector(
		(state: AppState) => state.companiesManagement,
	);
	const [memoBody, setMemoBody] = useState<string>(defaultValue || "");
	const debounced = useDebounce(memoBody, 1000);

	const [isInitialized, setIsInitialized] = useState<boolean>(false);
	const [initialValue, setInitialValue] = useState<string>(defaultValue || "");

	const [hasAnError, setHasAnError] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [hasCompletedSaving, setHasCompletedSaving] = useState<boolean>(false);

	useEffect(() => {
		// force new initialisation because id has changed
		setIsInitialized(false);
		retrieveMemo();
	}, [memoId, targetCompany]);

	useEffect(() => {
		setHasCompletedSaving(false);
		callSaveAction(debounced);
	}, [debounced]);

	return {
		uniqueId,
		schoolName: user?.schoolName || "",
		changeThisMemo,
		callSaveAction,
		memoBody,
		isInitialized,
		isSaving,
		hasCompletedSaving,
		hasAnError,
		showErrorMessage: hasCompletedSaving && !isSaving && hasAnError,
		showSaveSuccessStatus: hasCompletedSaving && !isSaving && !hasAnError,
	};
};
