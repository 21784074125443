import type { UserType } from "@/types/userTypes";
import useDebounce from "@hooks/useDebounce";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import { briefcaseOutline, pinOutline } from "@assets/Icons";

import { SEARCH_DISTANCE_OPTIONS } from "@constants/Others";

import { objectsAreEqual } from "@tools/Objects";

import { RadioGroup } from "@designSystem/atoms/RadioGroup";
import { Spinner } from "@designSystem/atoms/Spinner";
import { CardTertiary } from "@designSystem/molecules/CardTertiary";
import { ComboboxSearchAddress } from "@designSystem/molecules/ComboboxSearchAddress";
import { Input } from "@designSystem/molecules/Input";
import { DatePicker } from "@designSystem/organisms/DatePicker";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";
import { errorToast, success } from "@tools/Toasts";

import {
	getSearchStudent,
	updateSpsStudent,
} from "@containers/student/StudentDashboard/DashboardAPI";

import { MySearchSkeleton } from "./MySearch/MySearchSkeleton";

export function MySearch() {
	const { user } = useContext(GlobalContext);
	const [profile, setProfile] = useState<UserType>();
	const [values, setValues] = useState<UserType>();
	const debouncedValue = useDebounce(values);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const radioElements = [
		{ label: "Contrat d'apprentissage", value: 1 },
		{ label: "Contrat de professionnalisation", value: 3 },
	];
	const updateProfileValue = async (updateInputs: object) => {
		if (!user) return;

		try {
			await updateSpsStudent({
				id: user.id,
				...updateInputs,
			});
			success("Modification enregistrée");
		} catch (_e) {
			errorToast("Erreur lors de la modification");
		}
	};

	const fetchData = async () => {
		if (!user) return;
		const response = await getSearchStudent(user.id);
		setProfile({ ...response });
		setValues({ ...response });
		setIsLoading(false);
	};

	useEffect(() => {
		if (!profile || !values) return;
		if (!objectsAreEqual(profile, values)) {
			updateProfileValue({ ...values });
			setProfile({ ...values });
		}
	}, [debouncedValue]);

	useEffect(() => {
		fetchData();
	}, []);

	if (isLoading) return <MySearchSkeleton />;
	if (!profile) return <Spinner />;
	return (
		<div className="flex flex-col gap-sm">
			<p className="text-[18px] font-bold">Que recherches-tu ?</p>
			<div className="flex gap-sm">
				<CardTertiary
					icon={briefcaseOutline}
					className="w-4/12"
					title="Alternance"
					selected={
						profile.contractSearched === 1 || profile.contractSearched === 3
					}
					onSelect={() => {
						setValues({ ...profile, contractSearched: 1 });
					}}
				/>
				<CardTertiary
					icon={briefcaseOutline}
					className=" w-4/12"
					title="Stage"
					selected={profile.contractSearched === 4}
					onSelect={() => {
						setValues({ ...profile, contractSearched: 4 });
					}}
				/>
				<CardTertiary
					icon={briefcaseOutline}
					className="w-4/12"
					title="Emploi"
					selected={profile.contractSearched === 2}
					onSelect={() => {
						setValues({ ...profile, contractSearched: 2 });
					}}
				/>
			</div>
			{(profile.contractSearched === 1 || profile.contractSearched === 3) && (
				<div>
					<RadioGroup
						elements={[
							{ label: "Contrat d'apprentissage", value: "1" },
							{ label: "Contrat de professionnalisation", value: "3" },
						]}
						storedValue={radioElements.findIndex((e) => {
							return profile.contractSearched === Number(e.value);
						})}
						handleOnChange={(index) => {
							const chooseValue = radioElements[index];
							setValues({
								...profile,
								contractSearched: Number(chooseValue.value),
							});
						}}
						direction="vertical"
						side="left"
					/>
				</div>
			)}

			<div className="mt-md flex flex-wrap items-end gap-sm md:flex-nowrap">
				<DatePicker
					className="w-full"
					label="À partir de quand ?"
					withIcon
					selectedDate={new Date(profile.desiredStartOfWork)}
					onDateChange={(date: Date, action: string) => {
						if (action === "click" || action === "input") {
							setValues({
								...profile,
								desiredStartOfWork: date.toISOString(),
							});
						}
					}}
				/>
			</div>
			<Input
				label="Pour quel poste ?"
				placeholder="Ex : stagiaire développeur web"
				className="mt-lg"
				name="desiredJob"
				value={profile.desiredJob}
				onChange={(e) => {
					setValues({ ...profile, desiredJob: e.target.value });
				}}
			/>
			<div className="mt-md flex flex-wrap items-end gap-sm md:flex-nowrap">
				<ComboboxSearchAddress
					className="city w-full items-center md:w-6/12"
					label="Dans quelle zone géographique ?"
					name="city"
					value={profile.geoAreaOfResearch}
					icon={pinOutline}
					onChange={({ city, searchValue }) => {
						setValues({
							...profile,
							geoAreaOfResearch: city?.shortName || searchValue,
						});
					}}
					placeholder="Ville"
				/>
				<SelectTertiary
					label="Ta mobilité géographique ?"
					placeholder="Choisir"
					defaultValue={SEARCH_DISTANCE_OPTIONS.find((areaMob) => {
						return areaMob.value === profile.mobilityRadius;
					})}
					position="right"
					className="w-full md:w-6/12"
					options={SEARCH_DISTANCE_OPTIONS}
					onChange={(length) => {
						setValues({
							...profile,
							mobilityRadius: length.value,
						});
					}}
				/>
			</div>
		</div>
	);
}
