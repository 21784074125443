import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

import { LeftBorderCard } from "@designSystem/molecules/LeftBorderCard";

export function Surrend() {
	const { user } = useContext(GlobalContext);

	if (!user) return null;
	return (
		<LeftBorderCard
			borderColor="border-error-dark"
			content={
				<p className="flex gap-xsm items-center mb-sm">
					{user.schoolName} a restreint tes accès à l’espace emploi. Tu peux
					consulter tes anciennes candidatures.
					<br />
					En cas de problème contacte directement ton école.
				</p>
			}
		/>
	);
}
