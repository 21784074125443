import type { InterviewInfoType } from "@/types/interviewTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import React, { useEffect, useState } from "react";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";

import { Actions } from "./Tabs/Actions";
import { Application } from "./Tabs/Application";
import { Interview } from "./Tabs/Interview";
import { Notes } from "./Tabs/Notes";
import { Organization } from "./Tabs/Organization";

type InformationProps = {
	contactsProps: any;
	companyProps: any;
	applicationProps: InterviewInfoType & {
		job: string;
		type: string;
		location: string;
		contract: string;
		documents: { collection: MediaObjectType[] };
	};
	actionsProps: any;
	notes: any;
	step: string;
};
export function Information({
	contactsProps,
	step,
	applicationProps,
	actionsProps,
	notes,
}: InformationProps) {
	const [tabIndex, setTabIndex] = useState(0);
	useEffect(() => {
		const anchor = window.location.href.split("#")[1];
		switch (anchor) {
			case "action":
				if (window.innerWidth <= 599) {
					setTabIndex(2);
					break;
				}
				setTabIndex(1);
				break;
			case "contact":
				if (window.innerWidth <= 599) {
					setTabIndex(1);
				}
				break;
			default:
				setTabIndex(0);
		}
	}, []);

	const returnTab = (index: number) => {
		switch (index) {
			case 1:
				return <Organization application={applicationProps} />;
			case 2:
				return <Actions actions={actionsProps} />;
			case 3:
				return <Notes notes={notes} />;
			case 4:
				if (window.innerWidth <= 599) {
					return <Notes notes={notes} />;
				}
				return (
					<Interview contacts={contactsProps} application={applicationProps} />
				);

			case 5:
				return null;
			default:
				return <Application application={applicationProps} />;
		}
	};

	return (
		<div className="information-container w-full overflow-hidden p-0 pl-0">
			<div>
				<ScrollableTabGroup
					className="mb-lg hidden md:flex"
					tabs={[
						{
							label: "Candidature",
							active: tabIndex === 0,
						},
						{
							label: "Organisation",
							active: tabIndex === 1,
						},
						{ label: "Actions", active: tabIndex === 2 },
						{ label: "Notes", active: tabIndex === 3 },
						{
							label: "Entretien",
							active: tabIndex === 4,
							display: step === "interview",
						},
					]}
					onTabChange={(i) => {
						setTabIndex(i);
					}}
				/>

				{returnTab(tabIndex)}
			</div>
		</div>
	);
}
