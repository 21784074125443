import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

import { arrowDownOutline, arrowUpOutline, checkmarkFill } from "@assets/Icons";

import { DropdownItem } from "@designSystem/molecules/DropdownItem";
import { ItemSimpleSmall } from "@designSystem/molecules/ItemSimpleSmall";

import { SelectModal } from "@designSystem/organisms/selectModal/SelectModal";

let uniqueId = 0;

type SelectSecondaryStepperType = {
	className?: string;
	placeholder?: string;
	options: Array<{
		label: string;
		value: string;
	}>;
	accentColor: string;
	onChange: (input: any) => void;
	disabled?: boolean;
	label?: string;
	defaultValue?: any;
	indexActive: number;
	modalTitle?: string;
};

// C'est pas un select ca
export function SelectSecondaryStepper({
	className,
	placeholder,
	options,
	accentColor,
	onChange,
	disabled,
	label,
	defaultValue,
	indexActive,
	modalTitle,
}: SelectSecondaryStepperType) {
	const [componentId] = useState(() => uniqueId++);
	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState(defaultValue);
	const [displayModal, setDisplayModal] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue, options]);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [isOpen]);

	return (
		<div
			id={`select-secondary-${componentId}`}
			className={`select-secondary-stepper dropdown w-full ${className} ${
				disabled ? "disabled" : ""
			}`}
			ref={ref}
		>
			<p className="label mb-xxsm">{label}</p>
			<div
				data-toggle="dropdown"
				className="select-secondary-stepper-dropdown-container w-full align-items bg-white rounded shadow cursor-pointer flex h-9 justify-between p-xsm relative transition-all "
				onClick={() => {
					setIsOpen(!isOpen);
					setDisplayModal(true);
				}}
			>
				<p className={value ? "text-xsm" : "placeholder"}>{`${
					value?.label || placeholder
				}`}</p>

				<Icon
					className="icon-dropdown-input h-4"
					icon={isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>

			<div className="dropdown-container hidden sm:block w-full">
				<div className="dropdown-menu w-full">
					{options?.map((input, i) => {
						return (
							<DropdownItem
								key={i}
								onClick={() => {
									setValue(input.label);
									if (onChange) {
										onChange(
											input.value !== undefined ? input.value : input.label,
										);
									}
									setIsOpen(false);
								}}
								label={input.label}
							/>
						);
					})}
				</div>
			</div>

			<SelectModal
				title={modalTitle || ""}
				onClick={() => {
					setDisplayModal(false);
				}}
				body={
					<div>
						{options?.map((input, i) => {
							return (
								<div
									role="menu"
									key={i}
									onClick={() => {
										setValue(input.label);
										if (onChange) {
											onChange(
												input.value !== undefined ? input.value : input.label,
											);
										}
									}}
								>
									<ItemSimpleSmall
										label={input.label}
										icon={indexActive === i ? checkmarkFill : null}
									/>
								</div>
							);
						})}
					</div>
				}
				show={displayModal}
			/>
			<div className="select-stepper-container">
				{options.map((_, i) => {
					return (
						<div
							key={i}
							className={`select-stepper ${className} ${
								indexActive >= i ? `bg-accent-${accentColor}` : "bg-primary-150"
							}`}
						/>
					);
				})}
			</div>
		</div>
	);
}
