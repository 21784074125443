import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";
import { toast } from "react-toastify";

import { plusOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { StudentsTargetingModal } from "@modules/studentsTargetingModal";
import { countCurrentFilters } from "@modules/usersFilters/usersFiltersSlice";
import { UserfilterSearchbar } from "@modules/usersFilters/widgets/UserfilterSearchbar";
import {
	success,
	updateToastWithError,
	updateToastWithSuccess,
} from "@tools/Toasts";

import { exportStudentsCsv } from "@containers/school/Students/StudentsManagement/StudentsManagementAPI";

import CreateGroupedMessagesModal from "./CreateGroupedMessagesModal";

export function Head() {
	const dispatchEvent = useDispatch();

	const { structureView }: { structureView: object } = useOutletContext();
	const [displayTargetingModal, setDisplayTargetingModal] = useState(false);
	const [displayCreateMail, setDisplayCreateMail] = useState(false);

	const exportStudents = async () => {
		const id = toast.loading("Exportation en cours...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		// TO-DO: Change way to export students
		const response = await exportStudentsCsv(structureView, undefined);
		const fileName = response?.data?.fileName;
		if (fileName) {
			updateToastWithSuccess(id as string, "Export terminé.");
			window.open(`${import.meta.env.VITE_BACK}/csv/${fileName}`, "_blank");
			return;
		}
		updateToastWithError(id as string, "Erreur lors de l'export.");
	};

	const sendMultipleMessage = async () => {
		success(`L'email a bien été envoyée !`);
	};

	useEffect(() => {
		dispatchEvent(countCurrentFilters());
	}, []);

	return (
		<div className="flex flex-col gap-sm">
			<div className="flex justify-between">
				<div className={"flex w-7/12 gap-sm"}>
					<UserfilterSearchbar />
				</div>

				<div className={"flex w-4/12 gap-sm"}>
					<ButtonSecondary
						label="Exporter"
						className="ml-xsm w-4/12"
						onClick={() => {
							exportStudents();
						}}
					/>
					<ButtonPrimary
						icon={plusOutline}
						label="Envoyer un email"
						className="w-8/12"
						onClick={() => {
							setDisplayTargetingModal(true);
						}}
					/>
				</div>
			</div>

			<StudentsTargetingModal
				show={displayTargetingModal}
				onClose={() => {
					setDisplayTargetingModal(false);
					setDisplayCreateMail(true);
				}}
				structureView={structureView}
				title="Message multiple"
				label="Vous pouvez sélectionner des étudiants à qui rédiger un message multiple."
				buttonLabel="Valider la sélection"
				share={() => {
					return null;
				}}
			/>

			<CreateGroupedMessagesModal
				show={displayCreateMail}
				onClick={() => {
					sendMultipleMessage();
				}}
				onClose={() => {
					setDisplayCreateMail(false);
				}}
			/>
		</div>
	);
}
