export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toCamel(string: string) {
	const capitalize = (str: string) =>
		str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
	const stringToCamel = string
		.toLowerCase()
		.replace(/[^A-Za-z0-9]/g, " ")
		.split(" ")
		.reduce((result, word) => result + capitalize(word.toLowerCase()));
	return stringToCamel.charAt(0).toLowerCase() + stringToCamel.slice(1);
}

export function disableAccent(string: string) {
	return string
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase();
}

export const formatRichTextEditor = (content: string) => {
	const newContent = content
		.replaceAll("<ul>", '<ul class="pl-6 list-disc">')
		.replaceAll("<ol>", '<ol class="pl-6 list-decimal">')
		.replaceAll(
			'<p class="ql-align-right">',
			'<p class="ql-align-right text-right">',
		)
		.replaceAll(
			'<p class="ql-align-center">',
			'<p class="ql-align-center text-center">',
		)
		.replaceAll(
			'<p class="ql-align-justify">',
			'<p class="ql-align-justify text-justify">',
		);
	return newContent;
};

export type ExistsFilter = {
	filterName: string;
	value: boolean;
};

/**
 * @description Add the new keys to the existing “exists” filter key
 * @param currentFiltersSelected The current filters selected
 * @param existsFiltersToAdd The new exists filters to add. It use an array of objects with the filterName and the value
 * @returns The new exists filters as a string
 */
export const addExistsFiltersToCurrent = (
	currentFiltersSelected: object,
	existsFiltersToAdd: ExistsFilter[],
): string => {
	if (existsFiltersToAdd.length === 0) {
		return "";
	}

	const hasExistsIntoCurrentFilters = "exists" in currentFiltersSelected;

	const exists: { [key: string]: boolean } = hasExistsIntoCurrentFilters
		? (currentFiltersSelected as any).exists
		: {};

	for (let i = 0; i < existsFiltersToAdd.length; i++) {
		const filter = existsFiltersToAdd[i];
		exists[filter.filterName] = filter.value; // This will overwrite any existing value for the same key
	}

	const existsFiltersConvertedInString = Object.entries(exists)
		.map(([key, value]) => `${key}: ${value}`)
		.join(", ");

	return `exists: { ${existsFiltersConvertedInString} }`;
};
