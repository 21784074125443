import type { TagType } from "@/types/tagTypes";
import type { UserCompleteType } from "@/types/userTypes";
import { Icon } from "@iconify/react";
import React from "react";

import {
	birthdayCake,
	diagonalArrowRightUpOutline,
	emailOutline,
	phoneOutline,
	pinOutline,
	searchFill,
} from "@assets/Icons";

import { SEARCH_DISTANCE_OPTIONS } from "@constants/Others";

import {
	convertDateFromNow,
	formatDate,
	getAgeFromBirthdate,
} from "@tools/Dates";

import { Avatar } from "@designSystem/atoms/Avatar";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { PdfViewer } from "@designSystem/atoms/PdfViewer";
import { Tag } from "@designSystem/atoms/Tag";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";

type CVModalType = {
	student: UserCompleteType;
};
export function CVModal({ student }: CVModalType) {
	const {
		firstname,
		lastname,
		cv,
		avatar,
		promotion,
		birthdate,
		email,
		phone,
		city,
		postalCode,
		geoAreaOfResearch,
		tags,
		mobilityRadius,
	} = student;
	const formatedDate = new Date(birthdate);
	return (
		<div className="h-fit-content  w-full rounded-lg bg-white p-4 md:w-full slg:ml-sm l:w-8/12">
			<div>
				<p className="mb-6 font-bold">
					{" CV de "}
					<span className="wg-no-translate">
						{firstname} {lastname}
					</span>
					<span className="mr-2 font-normal text-primary-300">
						{` - déposé ${convertDateFromNow(cv?.createdAt ?? "")} `}
					</span>
				</p>
				<div className="mb-md rounded-lg bg-primary-50 p-sm">
					<div className="mb-sm flex w-full justify-between">
						<div className="flex">
							<Avatar
								firstname={firstname}
								lastname={lastname}
								image={avatar?.filePath}
								size="md"
							/>
							<div className="ml-sm flex flex-col">
								<p className="wg-no-translate truncate font-bold text-primary-700P">
									{`${firstname} ${lastname}`}
								</p>
								<p className="wg-no-translate font-semibold text-primary-700P">
									{promotion.name}
								</p>
							</div>
						</div>
						<div>
							<span id="cv-modal-tooltip">
								<InlineButton
									id="cv-modal-button"
									iconSize="h-6 w-6"
									iconPosition="right"
									icon={diagonalArrowRightUpOutline}
									onClick={() => {
										window.open(
											`/students/${student.id.split("/")[3]}`,
											"_blank",
										);
									}}
								/>
								<TooltipContainer
									children={"Voir la fiche de l’étudiant"}
									place="top-start"
									anchorId="#cv-modal-tooltip"
								/>
							</span>
						</div>
					</div>
					<div className="mb-xsm flex flex-wrap">
						{birthdate && (
							<div className="mb-xsm mr-md flex items-center text-primary-500">
								<Icon className="mr-xxsm" icon={birthdayCake} />
								<p className="font-semibold">
									{`${getAgeFromBirthdate(formatedDate)} ans - ${formatDate(
										birthdate,
									)}`}
								</p>
							</div>
						)}
						{email && (
							<div className="mb-xsm mr-md flex items-center text-primary-500">
								<Icon className="mr-xxsm" icon={emailOutline} />
								<p className="font-semibold">{email}</p>
							</div>
						)}
						{phone && (
							<div className="mb-xsm mr-md flex items-center text-primary-500">
								<Icon className="mr-xxsm" icon={phoneOutline} />
								<p className="font-semibold">
									{` +${phone.slice(0, phone.length - 9)} ${phone.slice(-9)}`}
								</p>
							</div>
						)}
						{(city || postalCode) && (
							<div className="mb-xsm mr-md flex items-center text-primary-500">
								<Icon className="mr-xxsm" icon={pinOutline} />

								<p className="whitespace-pre-line font-semibold">
									{`${city} ${postalCode}`}
								</p>
							</div>
						)}

						{geoAreaOfResearch && (
							<div className="mb-xsm mr-md flex items-center text-primary-500">
								<Icon className="mr-xxsm" icon={searchFill} />
								<p className="font-semibold">
									{geoAreaOfResearch.split(" ")[0]}
									{mobilityRadius &&
										` -  ${
											SEARCH_DISTANCE_OPTIONS.find((option) => {
												return option.value === mobilityRadius;
											})?.label
										}`}
								</p>
							</div>
						)}
					</div>
					<div className="flex flex-col">
						<div className="flex flex-wrap">
							{tags
								?.filter((tag: TagType) => tag.type === "hardSkill")
								?.map((tag: TagType) => (
									<Tag
										className="no-event m-xxsm"
										label={tag.name}
										bgColor={tag.color}
									/>
								))}
						</div>
						<div className="flex flex-wrap">
							{tags
								?.filter((tag: TagType) => tag.type === "softSkill")
								?.map((tag: TagType) => (
									<Tag
										className="no-event m-xxsm"
										label={tag.name}
										bgColor={tag.color}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
			{cv?.filePath?.endsWith(".pdf") ? (
				<div className="h-fit">
					<PdfViewer
						src={`${import.meta.env.VITE_S3}${cv?.filePath}`}
						title={`cv_${firstname}_${lastname}`}
					/>
				</div>
			) : (
				<p className="my-auto flex h-[200px] items-center justify-center text-center text-sm text-primary-500">
					Le CV n'est pas au format PDF, veuillez demander à l'étudiant de
					déposer son CV au format PDF
				</p>
			)}
		</div>
	);
}
