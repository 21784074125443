import type { UserCompleteType } from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useState } from "react";

import { closeOutline } from "@assets/Icons";

import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";

import { StudentDetail } from "./SlidingPanelContent/StudentDetail";
import { StudentsActivityFlow } from "./SlidingPanelContent/StudentsActivityFlow";

type SlidingPanelContentType = {
	setSlidingPaneIsOpen: Function;
	scrollWhenClosePanel: Function;
};
export function SlidingPanelContent({
	setSlidingPaneIsOpen,
	scrollWhenClosePanel,
}: SlidingPanelContentType) {
	const { user } = useContext(GlobalContext);
	const [showStudent, setShowStudent] = useState<boolean>(false);
	const [selectedStudent, setSelectedStudent] = useState<
		UserCompleteType | undefined
	>(undefined);

	return showStudent ? (
		<StudentDetail
			selectedStudentProps={{ selectedStudent, setSelectedStudent }}
			setSlidingPaneIsOpen={setSlidingPaneIsOpen}
			setShowStudent={setShowStudent}
			scrollWhenClosePanel={scrollWhenClosePanel}
		/>
	) : (
		<div className="p-sm">
			<div className="mb-md flex justify-end">
				<ButtonQuinary
					icon={closeOutline}
					onClick={() => {
						setSlidingPaneIsOpen(false);
						scrollWhenClosePanel();
					}}
				/>
			</div>
			<p className="mb-xlg text-center text-md text-primary-700P">
				Bonjour <span className="wg-no-translate">{user?.firstname}</span>
			</p>

			<StudentsActivityFlow
				selectedStudentState={{ selectedStudent, setSelectedStudent }}
				setShowStudent={setShowStudent}
				user={user}
			/>
		</div>
	);
}
