import type { EntOffersType } from "@/types/EntCompanyTypes";
import type { EntOffersStatusType } from "@/types/offerTypes";
import type { SchoolType } from "@/types/schoolTypes";
import { createSlice } from "@reduxjs/toolkit";
import { errorToast } from "@tools/Toasts";

export type CompanySliceType = {
	school?: Partial<SchoolType> & { id: number };
	title?: string | { title: string; subtitle: string };
	companySchoolProfile?: { id: number };
	showEmployeeAccessModal: boolean;
	entOffers: EntOffersType[];
	entOffersLoading: boolean;
	activeOffer: EntOffersType | undefined;
};

const initialState: CompanySliceType = {
	school: undefined,
	title: undefined,
	showEmployeeAccessModal: false,
	entOffers: [],
	entOffersLoading: false,
	activeOffer: undefined,
};

const companySlice = createSlice({
	name: "companySlice",
	initialState,
	reducers: {
		setSchool(state, action) {
			state.entOffers = [];
			state.school = action.payload;
		},
		setTitle(state, action) {
			state.title = action.payload;
		},
		setCompanySchoolProfile(state, action) {
			state.companySchoolProfile = action.payload;
		},
		setShowEmployeeAccessModal(state, action) {
			state.showEmployeeAccessModal = action.payload;
		},
		setEntOffers(state, action) {
			state.entOffers = action.payload;
		},
		setEntOffersLoading(state, action) {
			state.entOffersLoading = action.payload;
		},
		openSelection(state, action: { payload: EntOffersType; type: string }) {
			if (!state.activeOffer || state.activeOffer.id !== action.payload.id) {
				return;
			}

			const currentIndex = state.entOffers.findIndex((item: EntOffersType) => {
				return item.id === state.activeOffer?.id;
			});
			if (currentIndex === -1) return;
			window.open(
				`${import.meta.env.VITE_URL}/selection/${state.school?.subdomain}/${
					state.activeOffer.spsSelection?.token
				}`,
				"_blank",
			);
			if (state.entOffers[currentIndex].spsSelection) {
				state.entOffers[currentIndex].spsSelection.lastOpening =
					new Date().toString();
			}
			state.activeOffer = state.entOffers[currentIndex];
		},
		updateOfferStatus(
			state,
			action: {
				payload: {
					offer?: EntOffersType | string;
					newStatus: EntOffersStatusType;
				};
				type: string;
			},
		) {
			if (!state.activeOffer && !action.payload.offer) {
				errorToast("Offre manquante");
				return;
			}
			const offerToTreat = action.payload.offer || state.activeOffer;
			const currentIndex = state.entOffers.findIndex((item: EntOffersType) => {
				return (
					item.id ===
					(typeof offerToTreat === "string" ? offerToTreat : offerToTreat?.id)
				);
			});
			if (currentIndex === -1) return;
			if (state.entOffers[currentIndex]) {
				state.entOffers[currentIndex].status = action.payload.newStatus;
			}
			if (
				(typeof offerToTreat === "string" &&
					offerToTreat === state.activeOffer?.id) ||
				(typeof offerToTreat !== "string" &&
					offerToTreat?.id === state.activeOffer?.id)
			) {
				state.activeOffer = state.entOffers[currentIndex];
			}
		},
		setActiveOffer(
			state,
			action: { payload: EntOffersType | undefined; type: string },
		) {
			state.activeOffer = action.payload;
		},
	},
});

export const {
	setSchool,
	setTitle,
	setCompanySchoolProfile,
	setShowEmployeeAccessModal,
	setEntOffers,
	setEntOffersLoading,
	openSelection,
	updateOfferStatus,
	setActiveOffer,
} = companySlice.actions;

export default companySlice.reducer;
