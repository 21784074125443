import React, { useEffect, useState } from "react";

import { refreshOutline } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";
import { FileInput } from "@designSystem/molecules/FileInput";

import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { UserType } from "@/types/userTypes";
import {
	editUser,
	sendFile,
} from "@containers/school/Students/StudentDetail/StudentDetailAPI";
import { PdfViewer } from "@designSystem/atoms/PdfViewer";
import { errorToast } from "@tools/Toasts";

type InProgressProps = {
	studentId: string;
	cv: any;
	changeStep: (newStep: string) => void;
	updateData: (id: string) => void;
	studentIdentity: {
		firstname: string;
		lastname: string;
	};
};
export function InProgress({
	studentId,
	cv,
	changeStep,
	updateData,
	studentIdentity,
}: InProgressProps) {
	const [isLoading, setIsLoading] = useState(false);

	const importCV = async (file: File) => {
		setIsLoading(true);
		const formData: FormData = new FormData();
		formData.append("file", file);
		formData.append("type", "cv");
		formData.append("state", "validated");
		formData.append("ownerId", studentId.split("/")[3]);

		const data: Pick<UserType, "id"> & { cv?: string } = {
			id: studentId,
		};
		const response: Partial<MediaObjectType> = await sendFile(formData);
		data.cv = response["@id"];
		await editUser(data);
		updateData(studentId);
	};

	useEffect(() => {
		setIsLoading(false);
	}, [cv]);

	return (
		<>
			<p className="mb-sm text-xsm text-primary-700P">
				Votre étudiant a modifié son CV et vous l’a transmis directement ? Vous
				pouvez le déposer à sa place ci-dessous.
			</p>
			<FileInput
				id="cv"
				onError={(error: string) => {
					errorToast(error);
				}}
				isLoading={isLoading}
				subtitle={
					<p className="text-xxxsm text-primary-200">
						Taille max : 1Mo - Format : PDF
					</p>
				}
				acceptFormats={{ "application/pdf": [] }}
				maxSize={1}
				onSuccess={(e: File[]) => {
					importCV(e[0]);
				}}
			/>
			<p className="font-weight-font-bold mb-sm mt-md text-xsm text-primary-700P">
				Validation du CV
			</p>
			<div
				style={{ backgroundColor: "#FBEDEE" }}
				className="rounded-md pb-xsm pl-sm pr-sm pt-xsm
      "
			>
				<p className="text-xsm text-primary-700P">
					Vous avez indiqué à l’étudiant que son CV doit être modifié.
				</p>
				<InlineButton
					id="abort-validation"
					onClick={() => changeStep("toValidate")}
					className="mt-xxsm"
					label="Annuler la décision"
					icon={refreshOutline}
				/>
			</div>
			<PdfViewer
				src={`${import.meta.env.VITE_S3}${cv.filePath}`}
				title={`cv_${studentIdentity.firstname}_${studentIdentity.lastname}`}
				className="iframe-cv mt-lg"
			/>
		</>
	);
}
