import {
	checkmarkOutline,
	closeOutline,
	emailOutline,
	phoneOutline,
	refreshFill,
} from "@assets/Icons";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Divider } from "@designSystem/atoms/Divider";
import { RatingStars } from "@designSystem/atoms/RatingStars";
import React, { useContext, useState } from "react";
import type {
	Referent,
	SelectionStatusUpdate,
	UserDetails,
} from "../SelectionAPI";

import { setStarsSelectionUser, setStatusSelectionUser } from "../SelectionAPI";

import type { reviewStarsType } from "@/types/selectionTypes";
import { SelectionMemoGateway } from "@containers/school/ProfileSharing/core/selection.memoGateway";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Memo } from "@designSystem/molecules/memo/ui/Memo";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { GlobalContext } from "@navigation/Router";
import { errorToast, success } from "@tools/Toasts";
import {
	StudentsSelectionContext,
	UserDetailsContext,
} from "../SelectionStudentDetails";

type ReviewProps = {
	userDetails: UserDetails;
	referent: Referent | undefined;
};

export const Review = ({ referent }: ReviewProps) => {
	const memoGateway = new SelectionMemoGateway();
	const [openContactModal, setOpenContactModal] = useState<boolean>(false);
	const token = window.location.pathname.split("/")[3];
	const { userDetails, setUserDetails } = useContext(UserDetailsContext);
	const { students, setStudents } = useContext(StudentsSelectionContext);
	const onRatingUpdate = (stars: reviewStarsType) => {
		setStarsSelectionUser(token, userDetails.id, stars).then((r) => {
			if (r.code === 200) {
				setUserDetails({ ...userDetails, stars });
			} else {
				errorToast("Erreur lors de la mise à jour de la note");
			}
		});
	};

	const { user } = useContext(GlobalContext);

	const updateUserSelectionStatus = (
		selectionStatus: SelectionStatusUpdate,
	) => {
		setStatusSelectionUser(token, userDetails.id, selectionStatus).then((r) => {
			if (r.code === 200) {
				setUserDetails({ ...userDetails, selectionStatus });
				// check if the user is in the list of students
				const index = students.findIndex((s) => s.id === userDetails.id);
				if (index > -1) {
					const newStudents = [...students];
					newStudents[index] = { ...students[index], selectionStatus };
					setStudents(newStudents);
				}
				if (selectionStatus === 3) success("Choix pris en compte");
			} else {
				errorToast("Erreur lors de la mise à jour du choix");
			}
		});
		setUserDetails({ ...userDetails, selectionStatus });
	};

	return (
		<div className="flex flex-1 h-fit p-sm border border-primary-150 rounded-md flex-col gap-sm">
			<p className="font-bold text-xsm">Un avis sur ce profil ?</p>
			<RatingStars
				checkedStars={userDetails.stars}
				onUpdate={onRatingUpdate}
				disabled={user !== undefined}
			/>
			{userDetails.stars > 0 && (
				<Memo
					memoId={`${token}#${userDetails.id}`}
					defaultValue={userDetails.comment || ""}
					tooltip="Votre commentaire sera visible par l’école"
					title="Commentaire"
					placeholder="Écrivez un commentaire ici..."
					memoGateway={memoGateway}
					disabled={user !== undefined}
				/>
			)}
			<Divider className="h-[1px] bg-primary-100" />
			<p className="font-bold text-xsm">
				Souhaitez-vous rencontrer ce profil ?
			</p>
			{userDetails.selectionStatus < 2 ? (
				<div className="flex gap-xsm">
					<ButtonSecondary
						disabled={user !== undefined}
						label="Oui"
						icon={checkmarkOutline}
						onClick={() => {
							updateUserSelectionStatus(2);
							setOpenContactModal(true);
						}}
					/>
					<ButtonSecondary
						disabled={user !== undefined}
						label="Non"
						icon={closeOutline}
						onClick={() => updateUserSelectionStatus(3)}
					/>
				</div>
			) : (
				<div className="flex flex-col gap-sm">
					<p className="text-primary-300 font-bold text-xsm">{`Vous ${
						userDetails.selectionStatus === 2
							? "avez souhaité"
							: "ne souhaitez pas"
					} rencontrer ce profil.`}</p>
					{userDetails.selectionStatus === 2 && (
						<ButtonSecondary
							label="Voir les coordonnées"
							onClick={() => setOpenContactModal(true)}
						/>
					)}
					<InlineButton
						id="change-choice"
						label={"Revenir sur mon choix"}
						disabled={user !== undefined}
						icon={refreshFill}
						onClick={() => updateUserSelectionStatus(1)}
					/>
				</div>
			)}

			<Modal
				buttonsRight={[
					<ButtonPrimary
						label="Fermer"
						onClick={() => setOpenContactModal(false)}
					/>,
				]}
				body={
					<div className="flex flex-col gap-sm">
						<p>
							Voici quelques informations concernant l’école et l'étudiant si
							vous souhaitez les contacter.
						</p>
						{referent && (
							<div className="flex flex-col gap-xsm border-2 rounded-2xl border-emerald-light">
								<div className="flex flex-col flex-1 p-sm gap-sm">
									<p className="text-xsm">Votre contact dans l’école</p>
									<div className="flex flex-col gap-xsm">
										{referent.phone && (
											<InlineButton
												icon={phoneOutline}
												label={referent.phone}
												onClick={() => {}}
											/>
										)}
										{referent.email && (
											<InlineButton
												onClick={() => {}}
												icon={emailOutline}
												label={referent.email}
											/>
										)}
									</div>
								</div>
							</div>
						)}
						<div className="flex flex-col gap-xsm border-2 rounded-2xl border-lavender-light">
							<div className="flex flex-col flex-1 p-sm gap-sm">
								<p className="text-xsm">Informations de l’étudiant</p>
								<div className="flex flex-col gap-xsm">
									{userDetails.phone && (
										<InlineButton
											icon={phoneOutline}
											label={userDetails.phone}
											onClick={() => {
												window.open(`tel:${userDetails.phone}`);
											}}
										/>
									)}
									{userDetails.email && (
										<InlineButton
											icon={emailOutline}
											label={userDetails.email}
											onClick={() => {
												window.open(`mailto:${userDetails.email}`);
											}}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				}
				title="Informations"
				show={openContactModal}
				onClose={() => setOpenContactModal(false)}
			/>
		</div>
	);
};
