import * as Sentry from "@sentry/react";
import { setDefaultOptions } from "date-fns";
import { createRoot } from "react-dom/client";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

import "@assets/sass/tailwind.scss";
import "./noprint.css";

import { DATEFNS_LOCALES, type LangType } from "@constants/Locales";

import { Router } from "@navigation/Router";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import maintenance from "@assets/illustrations/maintenance.svg";
import { ToastContainer } from "react-toastify";

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ["localhost", /.*\.mentorgoal\.com/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	environment: import.meta.env.MODE,
	enabled: import.meta.env.MODE !== "development",
});

const container = document.getElementById("root");
// initializes DateFns language
const lang: LangType = Weglot.getCurrentLang() as LangType;
setDefaultOptions({ locale: DATEFNS_LOCALES()[lang] });

if (import.meta.env.VITE_MAINTENANCE === "true") {
	if ("caches" in window) {
		caches.keys().then((names) => {
			names.forEach((name) => {
				caches.delete(name);
			});
		});
	}
}
if (container) {
	const root = createRoot(container);
	root.render(
		<>
			{process.env.NODE_ENV !== "production" && (
				<div className="bg-accent-3 w-full z-20 flex text-white justify-center font-semibold">
					<p>{`Vous êtes actuellement en ${process.env.NODE_ENV}`}</p>
				</div>
			)}
			{import.meta.env.VITE_MAINTENANCE === "true" ? (
				<Maintenance />
			) : (
				<Router />
			)}
			<ToastContainer
				closeButton={true}
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				closeOnClick={false}
			/>
		</>,
	);
}

function Maintenance() {
	return (
		<div className="flex flex-col justify-center items-center h-screen w-screen text-center m-0">
			<img src={maintenance} alt="maintenance" />
			<h1 className="mt-[30px] text-[2em]">En maintenance</h1>
			<p className="text-primary-700P text-[1em]">
				Nous apportons actuellement des améliorations à la plateforme.
				<br />
				Merci de revenir dans quelques instants.
			</p>
		</div>
	);
}
