import { useAppSelector } from "@config/redux/hook";
import React, { useState } from "react";

import { extractTimeFromISOString } from "@tools/Dates";

import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";
import { Switch } from "@designSystem/atoms/Switch";
import { InputTime } from "@designSystem/molecules/InputTime";
import { DatePicker } from "@designSystem/organisms/DatePicker";
import { isSameDay } from "date-fns";

export function EventDate() {
	const eventToCreate = useAppSelector((state) => state.events.eventToCreate);
	const dispatchEvent = useAppDispatch();
	const actions = eventsSlice.actions;

	const checkIfEventIsOnSeveralDays = () => {
		if (eventToCreate?.startDate && eventToCreate?.endDate) {
			return !isSameDay(
				new Date(eventToCreate?.startDate),
				new Date(eventToCreate?.endDate),
			);
		}
		return false;
	};
	const [isEventOnSeveralDays, setIsEventOnSeveralDays] = useState<boolean>(
		checkIfEventIsOnSeveralDays(),
	);

	const handleDateChange = (
		dateName: "startDate" | "endDate",
		conservTime: boolean,
		value: Date,
	) => {
		if (conservTime) {
			const startDateModified = new Date(value);
			const startDateCopy = new Date(eventToCreate?.startDate || new Date());
			startDateModified.setHours(startDateCopy.getHours());
			startDateModified.setMinutes(startDateCopy.getMinutes());
			const endDateModified = new Date(value);
			const endDateCopy = new Date(eventToCreate?.endDate || new Date());
			endDateModified.setUTCHours(endDateCopy.getUTCHours());
			endDateModified.setUTCMinutes(endDateCopy.getUTCMinutes());
			endDateModified.setUTCDate(startDateModified.getUTCDate());

			dispatchEvent(
				actions.setEventToCreate({
					...eventToCreate,
					startDate: startDateModified,
					endDate: endDateModified,
				}),
			);
		}

		if (!conservTime) {
			const dateToUseForModification = new Date(value);
			dateToUseForModification.setHours(12);

			dispatchEvent(
				actions.setEventToCreate({
					...eventToCreate,
					[dateName]: dateToUseForModification,
				}),
			);
		}
	};

	/**
	 *
	 * @param dateName use startDate or endDate for using as key of eventToCreate
	 * @param value
	 */
	const handleHourChange = (
		dateName: "startDate" | "endDate",
		value: string,
	) => {
		let dateToUseForModification = new Date();

		if (dateName === "startDate" && eventToCreate?.startDate) {
			dateToUseForModification = new Date(eventToCreate?.startDate);
		}

		if (dateName === "endDate" && eventToCreate?.endDate) {
			dateToUseForModification = new Date(eventToCreate?.endDate);
		}

		const [hours, minutes] = value.split(":");
		dateToUseForModification.setHours(Number(hours));
		dateToUseForModification.setMinutes(Number(minutes));

		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				[dateName]: dateToUseForModification,
			}),
		);
	};

	const handleDisableSeveralDays = () => {
		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				endDate: eventToCreate?.startDate,
			}),
		);
		setIsEventOnSeveralDays(false);
	};
	return (
		<article
			data-testid="eventDateSection"
			className="flex w-full flex-col rounded-lg bg-white px-6 py-4"
		>
			<div
				className="text-sm font-bold text-primary-700P"
				data-testid="eventDateSectionTitle"
			>
				Date et heure
			</div>
			{isEventOnSeveralDays ? (
				<div
					className="my-6 flex w-full flex-col items-center justify-center gap-4"
					data-testid="eventMultiDays"
				>
					<DatePicker
						selectedDate={new Date(eventToCreate?.startDate || "")}
						onDateChange={(dateReceived: Date) => {
							handleDateChange("startDate", false, dateReceived);
						}}
					/>
					<span className="my-4 text-sm font-bold text-primary-700P">au</span>
					<DatePicker
						selectedDate={new Date(eventToCreate?.endDate || "")}
						onDateChange={(dateReceived: Date) => {
							handleDateChange("endDate", false, dateReceived);
						}}
					/>
				</div>
			) : (
				<div
					className="my-6 flex w-full items-center justify-center gap-1 md:gap-3 lg:gap-4"
					data-testid="eventMonoDate"
				>
					<DatePicker
						selectedDate={new Date(eventToCreate?.startDate || "")}
						onDateChange={(dateReceived: Date) => {
							handleDateChange("startDate", true, dateReceived);
						}}
						data-testid="eventDateSectionDatePicker"
					/>
					<span className="my-4 text-sm text-primary-150"> | </span>
					<div
						data-testid="eventMonoDateTimeSelect"
						className="flex flex-[1_1_100%] flex-col items-center justify-center gap-2 lg:flex-row"
					>
						<InputTime
							value={
								extractTimeFromISOString(eventToCreate?.startDate || "") || ""
							}
							onChange={(timeReceived: any) => {
								handleHourChange("startDate", timeReceived);
							}}
							className="max-w-[120px]"
						/>
						<span className="my-0 max-w-[48px] text-sm text-primary-150 lg:my-4">
							{" "}
							-{" "}
						</span>
						<InputTime
							value={extractTimeFromISOString(eventToCreate?.endDate || "")}
							onChange={(timeReceived: any) => {
								handleHourChange("endDate", timeReceived);
							}}
							className="max-w-[120px]"
						/>
					</div>
				</div>
			)}
			<div className="flex w-full items-center justify-between">
				<span className="text-xxsm font-normal text-primary-700P">
					Évènement sur plusieurs jours
				</span>
				<div data-testid="eventDateSectionSwitch">
					<Switch
						checked={isEventOnSeveralDays}
						onEnabled={() => setIsEventOnSeveralDays(true)}
						onDisabled={() => handleDisableSeveralDays()}
					/>
				</div>
			</div>
		</article>
	);
}
