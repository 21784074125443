import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { bulbFill } from "@assets/Icons";

import { STUDENT_SEARCH_STATUS_OPTIONS } from "@constants/Users";

import { formatDate } from "@tools/Dates";

import { AttentionDot } from "@containers/student/StudentDashboard/AttentionDot";
import {
	type RadioElementType,
	RadioGroup,
} from "@designSystem/atoms/RadioGroup";
import { LeftBorderCard } from "@designSystem/molecules/LeftBorderCard";

import { EStatusState } from "@/types/userTypes";
import { getUserStatusLogs } from "@containers/student/StudentDashboard/DashboardAPI";
import { errorToast } from "@tools/Toasts";
import { MyProfileContext } from "../../MyProfile";

export function StatusSwitcher() {
	const [statusChangeMessage, setStatusChangeMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const { profile, updateProfileValue } = useContext(MyProfileContext);
	const { user } = useContext(GlobalContext);

	useEffect(() => {
		const getLatestStatusChange = async () => {
			if (!user) return;

			const rep = await getUserStatusLogs(user.id);
			setIsLoading(false);

			if (rep.data.logs.collection.length > 0) {
				const log = rep.data.logs.collection[0];
				switch (log.action) {
					case "studentUpdatesStudentStatus":
						setStatusChangeMessage(
							`Ta demande de mise à jour de statut a été envoyée le ${formatDate(
								log.createdAt,
							)}`,
						);
						break;
					case "schoolRefusesStudentStatus":
						setStatusChangeMessage(
							`Ton école a refusé ton statut le ${formatDate(log.createdAt)}`,
						);
						break;
					case "schoolAcceptsStudentStatus":
						setStatusChangeMessage(
							`Ton école a validé ton statut le ${formatDate(log.createdAt)}`,
						);
						break;
					case "schoolChangesStudentStatus":
						setStatusChangeMessage(
							`Ton école a mis à jour ton statut le ${formatDate(
								log.createdAt,
							)}`,
						);
						break;
				}
			}
		};

		getLatestStatusChange();
	}, [user]);

	return (
		<LeftBorderCard
			content={
				<>
					<p className="flex gap-xsm items-center mb-sm">
						<Icon icon={bulbFill} color="#EDC666" height={20} width={20} />
						<b className="mt-0.5">Où en es-tu dans ta recherche ?</b>
						{statusChangeMessage.includes("Ton école a refusé ton statut") && (
							<AttentionDot />
						)}
					</p>
					{isLoading ? (
						<Skeleton className="mb-sm" />
					) : (
						statusChangeMessage && (
							<p className="mb-sm text-primary-500 text-xxsm">
								{statusChangeMessage}
							</p>
						)
					)}
					<RadioGroup
						elements={STUDENT_SEARCH_STATUS_OPTIONS as RadioElementType[]}
						storedValue={STUDENT_SEARCH_STATUS_OPTIONS.findIndex(
							(e) => e.value === profile.studentStatus,
						)}
						handleOnChange={async (index) => {
							const newStudentStatus =
								STUDENT_SEARCH_STATUS_OPTIONS[index].value;
							if (newStudentStatus === profile.status) {
								return;
							}
							setIsLoading(true);
							try {
								await updateProfileValue({
									studentStatus: newStudentStatus,
									studentStatusState:
										profile.status === newStudentStatus
											? null
											: EStatusState.REQUESTED,
								});
								setStatusChangeMessage(
									`Ta demande de mise à jour de statut a été envoyée le ${formatDate(
										new Date(),
									)}`,
								);
							} catch {
								errorToast(
									"Une erreur est survenue lors du changement de statut",
								);
							} finally {
								setIsLoading(false);
							}
						}}
						direction="vertical"
						side="left"
					/>
					<p className="mt-sm text-primary-500 text-xxsm">
						Ton école sera tenue informée de ton changement de statut.
					</p>
				</>
			}
		/>
	);
}
