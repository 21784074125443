import type { InterviewInfoType } from "@/types/interviewTypes";
import PropTypes from "prop-types";
import React from "react";

import { AccordionCustom } from "@designSystem/organisms/Accordion";

import { InterviewInformation } from "./Interview/InterviewInformation";
import { PostInterview } from "./Interview/PostInterview";
import { Questions } from "./Interview/Questions";

type InterviewProps = {
	contacts: any;
	application: InterviewInfoType;
};

export function Interview({ application }: InterviewProps) {
	const interviewInfo = {
		id: application.id,
		explanationEvaluation: application.explanationEvaluation,
		interviewEvaluation: application.interviewEvaluation,
		answerPreparationQuestions: application.answerPreparationQuestions,
		interviewDate: application.interviewDate,
		alternativeAddress: application.alternativeAddress,
		interviewLocation: application.interviewLocation,
		contacts: application.contacts,
		date: application.date,
		hour: application.hour,
		typeOfInterview: application.typeOfInterview,
	} as InterviewInfoType;

	return (
		<div>
			<div id="questions">
				<InterviewInformation application={application} />
				<AccordionCustom
					title=" Les 10 questions d'un entretien réussi"
					subtitle=""
					items={[]}
					headColor="primary-100"
					className="mt-md"
					parentId="#questions"
					headClassName="p-sm"
					body={
						<Questions interviewInfo={interviewInfo} interviewLoaded={true} />
					}
				/>
				<AccordionCustom
					title="Comment s'est passé votre entretien ?"
					subtitle=""
					items={[]}
					headColor="primary-100"
					className="mt-md"
					parentId="#questions"
					headClassName="p-sm"
					body={
						<PostInterview
							application={application as Object as string}
							applicationId={1} // Its required by the type but not used in this case
							interviewInfo={interviewInfo}
							interviewLoaded={true}
						/>
					}
				/>
			</div>
		</div>
	);
}

Interview.propTypes = {
	contacts: PropTypes.shape({}).isRequired,
	application: PropTypes.shape({
		id: PropTypes.string,
	}).isRequired,
};
