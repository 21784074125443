import { useBreakpoints } from "@hooks/useBreakpoints";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useState } from "react";

import stepOneApplications from "@assets/onBoarding/Application/stepOne.svg";
import stepTwoApplications from "@assets/onBoarding/Application/stepTwo.svg";
import stepFourDashboard from "@assets/onBoarding/Dashboard/stepFour.svg";
import stepThreeDashboard from "@assets/onBoarding/Dashboard/stepThree.svg";
import stepTwoDashboard from "@assets/onBoarding/Dashboard/stepTwo.svg";
import stepOneOffers from "@assets/onBoarding/Offers/stepOne.svg";
import stepThreeOffers from "@assets/onBoarding/Offers/stepThree.svg";
import stepTwoOffers from "@assets/onBoarding/Offers/stepTwo.svg";
import stepOneResources from "@assets/onBoarding/Resources/stepOne.svg";
import stepThreeResources from "@assets/onBoarding/Resources/stepThree.svg";
import stepTwoResources from "@assets/onBoarding/Resources/stepTwo.svg";
import bulb from "@assets/onBoarding/assets/bulb.svg";

import { Avatar } from "@designSystem/atoms/Avatar";

import type { UserType } from "@/types/userTypes";
import { ONBOARDING_TITLES } from "@constants/Others";
import { errorToast } from "@tools/Toasts";
import { editUser, sendFile } from "../OnboardingAPI";

type DataItemType = {
	id: number;
	content: JSX.Element;
	firstBtn: { onClick: () => void };
	secondBtn: { onClick: () => void };
	image?: string;
};

type DataOnboardingType = {
	dashboard: DataItemType[];
	resources: DataItemType[];
	jobboard: DataItemType[];
	applications: DataItemType[];
};

export function dataOnboarding(
	page: number,
	setPage?: (page: number) => void,
	onClose?: () => void,
	updateOnboardingSteps?: () => void,
): DataOnboardingType {
	const { user, setUser } = useContext(GlobalContext);
	const { isMobile } = useBreakpoints();
	const [avatar] = useState<
		Partial<{
			id: string;
			firstname: string;
			lastname: string;
			filePath?: string;
		}>
	>({});
	const [error, setError] = useState("");

	const closeModal = () => {
		if (onClose) {
			onClose();
		} else {
			return;
		}
	};
	const changeOnboardingStep = () => {
		if (updateOnboardingSteps) {
			updateOnboardingSteps();
		} else {
			return;
		}
	};
	const changePage = (newPage: number) => {
		if (setPage) {
			setPage(newPage);
		} else {
			return;
		}
	};

	const uploadFile = async (file: File, fileName: string) => {
		const formData = new FormData();
		formData.append("file", file);

		const updatedUser: Partial<UserType> = {
			id: `${user?.id}`,
		};

		try {
			const response = await sendFile(formData);
			if (fileName === "avatar") updatedUser.avatar = response;
			setError("");
		} catch {
			setError("La taille maximale pour la photo de profil est de 1Mo.");
		}

		try {
			await editUser({
				id: `${user?.id}`,
				avatar: updatedUser?.avatar?.["@id"],
			});
			setUser({
				...user,
				avatar: updatedUser.avatar,
			});
		} catch {
			errorToast("Erreur lors de la mise à jour du profil.");
		}
	};

	const deleteAvatar = async () => {
		await editUser({ id: `${user?.id}`, avatar: null });
		setUser({ ...user, avatar: null });
	};

	return {
		dashboard: [
			{
				id: 0,
				content: (
					<div>
						{ONBOARDING_TITLES.dashboard[page]?.firstContentStartMsg &&
						ONBOARDING_TITLES.dashboard[page].firstContentEndMsg ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.dashboard[page].firstContentStartMsg}
								{<b className="wg-no-translate">{` ${user?.firstname} `}</b>}
								{ONBOARDING_TITLES.dashboard[page].firstContentEndMsg}
							</p>
						) : null}
						<div className="file-input pb-xxlg pt-xxlg">
							<Avatar
								size="lg"
								className="avatar-xlg-primary"
								image={user?.avatar?.filePath || avatar?.filePath || null}
								firstname={user?.firstname}
								lastname={user?.lastname}
								onChange={(image: any) => {
									uploadFile(image.file, "avatar");
								}}
								onDelete={() => {
									deleteAvatar();
								}}
								modify
							/>

							<p className="mt-sm text-primary-300">
								Taille max : 1Mo - Formats : .jpg, .png
							</p>
							{error && (
								<p className="text-error mt-xxsm text-error-dark">{error}</p>
							)}
						</div>

						{ONBOARDING_TITLES.dashboard[page]?.secondContent ? (
							<p className="mt-md text-xsm">
								{ONBOARDING_TITLES.dashboard[page].secondContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center" />
					</div>
				),
				firstBtn: {
					onClick: () => {
						closeModal();
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 1,
				content: (
					<div>
						{ONBOARDING_TITLES.dashboard[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.dashboard[page].firstContent}
							</p>
						) : null}
						{ONBOARDING_TITLES.dashboard[page]?.secondContent ? (
							<p className="text-xsm">
								{ONBOARDING_TITLES.dashboard[page].secondContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepTwoDashboard as string} alt="step" />
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 2,
				content: (
					<div>
						{ONBOARDING_TITLES.dashboard[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.dashboard[page].firstContent}
							</p>
						) : null}
						{ONBOARDING_TITLES.dashboard[page]?.secondContent ? (
							<p className="text-xsm">
								{ONBOARDING_TITLES.dashboard[page].secondContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepThreeDashboard as string} alt="step" />
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 3,
				content: (
					<div>
						{ONBOARDING_TITLES.dashboard[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.dashboard[page].firstContent}
							</p>
						) : null}
						{ONBOARDING_TITLES.dashboard[page]?.secondContent ? (
							<p className="text-xsm">
								{ONBOARDING_TITLES.dashboard[page].secondContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepFourDashboard as string} alt="step" />
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changeOnboardingStep();
						closeModal();
					},
				},
			},
		],
		applications: [
			{
				id: 0,
				content: (
					<div>
						{ONBOARDING_TITLES.applications[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.applications[page].firstContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepOneApplications as string} alt="step" />
						</div>
						{ONBOARDING_TITLES.applications[page]?.secondContent ? (
							<p className="text-xsm">
								{ONBOARDING_TITLES.applications[page].secondContent}
							</p>
						) : null}
					</div>
				),
				firstBtn: {
					onClick: () => {
						changeOnboardingStep();
						closeModal();
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 1,
				content: (
					<div>
						{ONBOARDING_TITLES.applications[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.applications[page].firstContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepTwoApplications as string} alt="step" />
						</div>
						<div className="flex items-center justify-center">
							{!isMobile && (
								<img className="bulb mr-md" src={bulb as string} alt="bulb" />
							)}
							{ONBOARDING_TITLES.applications[page]?.secondContent ? (
								<p className="text-xsm">
									{ONBOARDING_TITLES.applications[page].secondContent}
								</p>
							) : null}
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changeOnboardingStep();
						closeModal();
					},
				},
			},
		],
		jobboard: [
			{
				id: 0,
				content: (
					<div>
						{ONBOARDING_TITLES.jobboard[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.jobboard[page].firstContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepOneOffers as string} alt="step" />
						</div>
						<div className="mt-md flex items-center justify-center">
							{!isMobile && (
								<img className="bulb mr-md" src={bulb as string} alt="bulb" />
							)}
							{ONBOARDING_TITLES.jobboard[page]?.secondContent ? (
								<p className="text-xsm">
									{ONBOARDING_TITLES.jobboard[page].secondContent}
								</p>
							) : null}
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changeOnboardingStep();
						closeModal();
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 1,
				content: (
					<div>
						{ONBOARDING_TITLES.jobboard[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.jobboard[page].firstContent}
							</p>
						) : null}
						<div className="mb-md mt-md flex items-center justify-center">
							<img src={stepTwoOffers as string} alt="step" />
						</div>
					</div>
				),
				image: stepTwoOffers as string,
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 2,
				content: (
					<div>
						{ONBOARDING_TITLES.jobboard[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.jobboard[page].firstContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepThreeOffers as string} alt="step" />
						</div>
						<div className="mt-md flex items-center justify-center">
							{!isMobile && (
								<img className="bulb mr-md" src={bulb as string} alt="bulb" />
							)}
							{ONBOARDING_TITLES.jobboard[page]?.secondContentStartMsg &&
							ONBOARDING_TITLES.jobboard[page].secondContentEndMsg ? (
								<p className="text-xsm">
									{ONBOARDING_TITLES.jobboard[page].secondContentStartMsg}
									<b> {user?.schoolName} </b>
									{ONBOARDING_TITLES.jobboard[page].secondContentEndMsg}
								</p>
							) : null}
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changeOnboardingStep();
						closeModal();
					},
				},
			},
		],
		resources: [
			{
				id: 0,
				content: (
					<div>
						{ONBOARDING_TITLES.resources[page]?.firstContentStartMsg &&
						ONBOARDING_TITLES.resources[page].firstContentEndMsg ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.resources[page].firstContentStartMsg}
								<b className="wg-no-translate"> {user?.schoolName} </b>
								{ONBOARDING_TITLES.resources[page].firstContentEndMsg}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepOneResources as string} alt="step" />
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changeOnboardingStep();
						closeModal();
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 1,
				content: (
					<div>
						{ONBOARDING_TITLES.resources[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.resources[page].firstContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepTwoResources as string} alt="step" />
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changePage(page + 1);
					},
				},
			},
			{
				id: 2,
				content: (
					<div>
						{ONBOARDING_TITLES.resources[page]?.firstContent ? (
							<p className="mb-md text-xsm text-primary-700P">
								{ONBOARDING_TITLES.resources[page].firstContent}
							</p>
						) : null}
						<div className="mt-md flex items-center justify-center">
							<img src={stepThreeResources as string} alt="step" />
						</div>
					</div>
				),
				firstBtn: {
					onClick: () => {
						changePage(page - 1);
					},
				},
				secondBtn: {
					onClick: () => {
						changeOnboardingStep();
						closeModal();
					},
				},
			},
		],
	};
}
