import DOMPurify from "dompurify";
import React, { useContext } from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

import logo from "@containers/public/Selection/assets/logo.svg";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { GlobalContext } from "@navigation/Router";

export function ShareMailPreview({
	message,
	subject,
	to,
	cc,
}: {
	subject: string;
	message: string;
	to: string;
	cc: string;
}) {
	const { user } = useContext(GlobalContext);

	return (
		<div className="flex flex-1 flex-col overflow-auto gap-sm">
			<p className="text-primary-200 text-xxsm mb-sm uppercase">
				Prévisualisation de votre message
			</p>
			<div className="flex flex-col gap-xsm">
				<EmailAttribute label="De">
					<>
						<span className="font-bold">
							{user?.firstname} {user?.lastname}
						</span>
						<TooltipElement id="tooltip-from" className="items-center" />
						<TooltipContainer
							anchorId="#tooltip-from"
							makeClickable={false}
							children={`La réponse sera automatiquement envoyée à ${user?.email}`}
							place="top"
						/>
					</>
				</EmailAttribute>
				<EmailAttribute label="À">{to}</EmailAttribute>
				<EmailAttribute label="Cc">{cc}</EmailAttribute>
				<EmailAttribute label="Objet">{subject}</EmailAttribute>
			</div>
			<div className="shadow-sm p-xsm rounded-md border-[1px] border-primary-150 relative">
				<div className="mt-md mb-lg flex flex-col">
					<img
						src={user?.schoolLogo || logo}
						alt="logo"
						className="mx-auto mb-sm rounded-full bg-primary-100 h-[75px] w-auto"
					/>
					<h3 className="!text-md !font-bold text-center">{subject}</h3>
				</div>

				<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
				<div className="flex mt-lg mb-xxlg">
					<ButtonPrimary className="mx-auto" label="Voir les profils" />
				</div>
				<p className="text-xxxsm text-center mb-md">
					Envoyé via Mentor Goal <br />
					<br />
					Ne plus recevoir d'email de Mentor Goal
				</p>
			</div>
		</div>
	);
}

const EmailAttribute = ({
	label,
	children,
}: { label: string; children: React.ReactNode }) => {
	return (
		<p className="flex items-center gap-xsm">
			<span className="font-bold whitespace-nowrap">{label} :</span> {children}
		</p>
	);
};
