import PropTypes from "prop-types";
import React from "react";

import { globe2Outline } from "@assets/Icons";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";

export function OrganizationDetail({ crmCompany }) {
	return (
		<div className="w-6/12">
			<h4 className="text-sm font-bold text-primary-700P">Organisation</h4>
			<div className="mt-xlg flex flex-col items-center justify-center">
				<Avatar
					image={crmCompany.logo}
					firstname={crmCompany.name.split(" ")[0]}
					lastname={crmCompany.name.split(" ")[1]}
				/>
				<p className="mt-sm text-xsm font-bold text-primary-700P">
					{crmCompany.name}
				</p>
				<div className="flex items-center">
					{crmCompany.website && (
						<ButtonQuaternary
							icon={globe2Outline}
							onClick={() => {
								window.open(crmCompany.website, "_blank");
							}}
						/>
					)}
				</div>
			</div>
			{crmCompany.address && (
				<div className="mt-xxlg flex  justify-between">
					<p className="text-xsm font-bold text-primary-200">Siège</p>
					<p className="ml-xlg text-right text-xsm text-primary-700P">
						{crmCompany.address}
					</p>
				</div>
			)}
			{crmCompany.contactEmail && (
				<div className="mt-sm flex  justify-between">
					<p className="text-xsm font-bold text-primary-200">Email</p>
					<p className="ml-xlg text-right text-xsm text-primary-700P">
						{crmCompany.contactEmail}
					</p>
				</div>
			)}
			{crmCompany.contactPhone && (
				<div className="mt-sm flex justify-between">
					<p className="text-xsm font-bold text-primary-200">Téléphone</p>
					<p className="ml-xlg text-right text-xsm text-primary-700P">
						{crmCompany.contactPhone}
					</p>
				</div>
			)}
		</div>
	);
}

OrganizationDetail.propTypes = {
	crmCompany: PropTypes.shape({
		logo: PropTypes.string,
		name: PropTypes.string,
		linkedin: PropTypes.string,
		address: PropTypes.string,
		email: PropTypes.string,
		website: PropTypes.string,
		phone: PropTypes.string,
	}).isRequired,
};
