import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect, useState } from "react";

import { PdfViewer } from "@designSystem/atoms/PdfViewer";
import { FilesPrimary } from "@designSystem/molecules/Files";
import { GlobalContext } from "@navigation/Router";
import { errorToast } from "@tools/Toasts";

export function ApplicationDocumentsReader() {
	const { user } = useContext(GlobalContext);
	const { applicationSelectedData } = useAppSelector((state) => state.offers);
	const [documentToDisplay, setDocumentToDisplay] =
		useState<Partial<MediaObjectType> | null>(null);
	const [fileSelected, setFileSelected] = useState<number>(0);
	const [cv, setCv] = useState<MediaObjectType | null>(null);
	const [motivationLetter, setMotivationLetter] =
		useState<MediaObjectType | null>(null);

	const findSpecificDocument = (type: string) => {
		const documentsFound: Partial<MediaObjectType>[] =
			applicationSelectedData?.documents?.collection?.filter(
				(document: Partial<MediaObjectType>) => document.type === type,
			);
		if (documentsFound.length === 0) return null;
		return documentsFound[0] as MediaObjectType;
	};
	const handleCVClick = () => {
		setFileSelected(0);
		setDocumentToDisplay(findSpecificDocument("cv"));
	};
	const handleMotivationLetterClick = () => {
		if (!motivationLetter?.filePath) return false;
		setFileSelected(1);
		setDocumentToDisplay(findSpecificDocument("motivation"));
		return true;
	};

	useEffect(() => {
		if (applicationSelectedData?.documents?.collection) {
			setCv(findSpecificDocument("cv"));
			setMotivationLetter(findSpecificDocument("motivation"));
			setDocumentToDisplay(findSpecificDocument("cv"));
		}
	}, [applicationSelectedData]);
	return (
		<article className="flex w-full flex-col items-start justify-start gap-sm">
			<p className="text-base font-semibold uppercase text-primary-300 ">
				Pièces jointes
			</p>
			<div
				className={`w-full border ${
					fileSelected === 0
						? "border-primary-200 px-sm py-xsm shadow-md"
						: "border-transparent px-sm py-xsm"
				} rounded-md transition-all hover:cursor-pointer hover:border-primary-200
        `}
				onClick={handleCVClick}
			>
				{cv?.filePath ? (
					<FilesPrimary
						isLoading={false}
						title={cv?.filePath ? cv?.filePath : "Aucun CV sélectionné"}
						subtitle={`${Math.floor(cv?.size ? cv?.size / 1000 : 0)} Ko`}
						onView={() => {
							if (cv?.filePath) {
								window.open(`${import.meta.env.VITE_S3}${cv?.filePath}`);
							} else {
								errorToast("Aucun document à visualiser");
							}
						}}
					/>
				) : (
					"Aucun CV sélectionné"
				)}
			</div>
			<div
				className={`w-full border ${
					fileSelected === 1
						? "border-primary-200 px-sm py-xsm shadow-md"
						: "border-transparent px-sm py-xsm"
				} ${
					motivationLetter?.filePath &&
					"rounded-md transition-all hover:cursor-pointer hover:border-primary-200"
				}`}
				onClick={handleMotivationLetterClick}
			>
				{motivationLetter?.filePath ? (
					<FilesPrimary
						isLoading={false}
						title={
							motivationLetter?.filePath
								? motivationLetter?.filePath
								: "Aucune lettre de motivation sélectionnée"
						}
						subtitle={`${Math.floor(
							motivationLetter?.size ? motivationLetter?.size / 1000 : 0,
						)} Ko`}
						onView={() => {
							if (motivationLetter?.filePath) {
								window.open(
									`${import.meta.env.VITE_S3}${motivationLetter?.filePath}`,
								);
							} else {
								errorToast("Aucun document à visualiser");
							}
						}}
					/>
				) : (
					"Aucune lettre de motivation sélectionnée"
				)}
			</div>
			{documentToDisplay?.filePath?.includes("pdf") ? (
				<div className="h-fit w-full">
					<PdfViewer
						src={`${import.meta.env.VITE_S3}${documentToDisplay?.filePath}`}
						title={`cv_${user?.firstname}_${user?.lastname}`}
					/>
				</div>
			) : (
				<p className="my-auto flex h-[200px] items-center justify-center text-center text-sm text-primary-500">
					Le document n'est pas au format PDF, veuillez demander à l'étudiant de
					déposer son document au format PDF
				</p>
			)}
		</article>
	);
}
