import { FileInput } from "@designSystem/molecules/FileInput";
import { FilesPrimary } from "@designSystem/molecules/Files";
import React from "react";
import {
	type ApplicationWithDocuments,
	useDocumentManager,
} from "./useDocumentManager";

type DocumentManagerProps = {
	documentType: string;
	application: ApplicationWithDocuments;
};

export default function DocumentManager({
	documentType,
	application,
}: DocumentManagerProps) {
	const presenter = useDocumentManager({
		documentType,
		initialData: application,
	});

	return (
		<>
			{presenter.document ? (
				<FilesPrimary
					isLoading={presenter.isLoading}
					title={presenter.document?.filePath}
					subtitle={`${Math.floor(presenter.document?.size / 1000)} Ko`}
					className="mb-sm mt-sm"
					onDelete={() => {
						presenter.deleteDocument(presenter.document);
					}}
					onView={() => {
						window.open(
							`${import.meta.env.VITE_S3}${presenter.document?.filePath}`,
						);
					}}
				/>
			) : (
				<FileInput
					isLoading={presenter.isLoading}
					onSuccess={(e: File[]) => {
						presenter.uploadFile(e, application);
					}}
					className="md:w-fit-content mt-sm w-full"
					maxSize={1}
					acceptFormats={{ "application/pdf": [] }}
					onError={() => {
						presenter.setError(
							"Le fichier envoyé dépasse la limite de 1Mo ou n'est pas sous format pdf",
						);
					}}
					id="cv"
				/>
			)}
			{presenter.error ? (
				<p className="text-error-dark">{presenter.error}</p>
			) : (
				""
			)}
		</>
	);
}
