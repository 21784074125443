import api from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";
export async function editUser(
	inputs: object,
	currentInterface: "student" | "school",
) {
	const updateSubject = currentInterface === "student" ? "Student" : "Referent";
	const data = {
		query: `mutation {
        updateSps${updateSubject}(input: ${stringifyRequestInputs(inputs)}) {
            user: sps${updateSubject} {
                id
                hasSeenNews
            }
        }
    }`,
	};
	try {
		const response = await api.post("graphql", data);
		return response.data.data.updateSpsStudent.user;
	} catch (error) {
		throw new Error(error);
	}
}
