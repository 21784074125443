import type { UserCompleteType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import { convertDateFromNow } from "@tools/Dates";

import { STUDENT_STATUS_OPTIONS } from "@constants/Users";
import { Tag } from "@designSystem/atoms/Tag";
import { CellAvatarLabelSecondary } from "@designSystem/molecules/CellAvatarLabelSecondary";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { Table } from "@designSystem/templates/table/Table";
import {
	setCurrentFilters,
	setPagination,
} from "@modules/usersFilters/usersFiltersSlice";
const DATASET = [
	{
		id: 0,
		datetime: "2023-12-07T10:00:00",
		status: "Envoyé",
		bgColor: "bg-accent-4-light",
		title: "Gestionnaire de paie",
		send: 20,
		views: 5,
		clic: 3,
	},
	{
		id: 1,
		datetime: "2023-12-07T11:30:00",
		status: "Envoyé",
		bgColor: "bg-accent-4-light",
		title: "Entretien de sélection pour alternance en marketing",
		firstname: "Jane",
		lastname: "Smith",
		send: 30,
		views: 10,
		clic: 7,
	},
	{
		id: 2,
		datetime: "2023-12-07T13:45:00",
		status: "Brouillon",
		bgColor: "bg-accent-2-light",
		title:
			"Réunion sur les opportunités d'alternance dans le département financier",
		send: "-",
		views: "-",
		clic: "-",
	},
	{
		id: 3,
		datetime: "2023-12-07T15:15:00",
		status: "Brouillon",
		bgColor: "bg-accent-2-light",
		title: "Confirmation de disponibilité pour un entretien d'alternance",
		send: "-",
		views: "-",
		clic: "-",
	},
	{
		id: 4,
		datetime: "2023-12-07T16:45:00",
		status: "Envoyé",
		bgColor: "bg-accent-4-light",
		title: "Proposition de contrat d'alternance en génie civil",
		send: 28,
		views: 16,
		clic: 14,
	},
	{
		id: 5,
		datetime: "2023-12-07T18:00:00",
		status: "Brouillon",
		bgColor: "bg-accent-2-light",
		title: "Rentrée 2023 - Gestionnaire de paie",
		send: "-",
		views: "-",
		clic: "-",
	},
];

export function TableView() {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector((state) => state.usersFilters);
	const { user } = useContext(GlobalContext);
	const { studentsList, studentsListLoading, studentsListLastPage } =
		useAppSelector((state) => state.studentsManagement);

	const headers = [
		{
			label: "STATUS",
			sort: { fieldName: "lastname", defaultOrder: "ASC" },
			tabs: ["all", "active", "waitConnection"],
		},
		{
			label: "TITRE",
			sort: { fieldName: "postalCode", defaultOrder: "ASC" },
			tabs: ["all"],
		},
		{
			label: "ENVOI",
			sort: { fieldName: "applicationsCount", defaultOrder: "ASC" },

			tabs: ["all", "active"],
		},
		{
			label: "ENVOYé PAR",
			sort: { fieldName: "relaunchs", defaultOrder: "ASC" },
			tabs: ["all", "active"],
		},
		{
			label: "ENVOI",
			tabs: ["all", "active"],
			sort: { fieldName: "interviews", defaultOrder: "ASC" },
		},
		{
			label: "VUES",
			tabs: ["all", "active", "waitConnection"],

			sort: { fieldName: "status", defaultOrder: "ASC" },
		},
		{
			label: "CLICS",
			tabs: ["all", "active"],
			sort: { fieldName: "lastConnexion", defaultOrder: "ASC" },
		},
	];

	const cellsResolver = (data: UserCompleteType) => {
		return [
			{
				children: (
					<Tag
						label={STUDENT_STATUS_OPTIONS[data.status].label}
						bgColor={data.bgColor}
					/>
				),
				tabs: ["all", "active", "waitConnection"],
			},
			{
				children: `${data.title}`,
				tabs: ["all"],
			},
			{
				children: convertDateFromNow(data.datetime),
				tabs: ["all", "active"],
			},
			{
				children: (
					<CellAvatarLabelSecondary
						translate={false}
						label={`${user?.firstname} ${user?.lastname}`}
						sublabel={""}
						firstname={user?.firstname ?? ""}
						lastname={user?.lastname ?? ""}
						avatar={user!.avatar?.filePath}
					/>
				),
				tabs: ["all", "active"],
			},
			{
				children: data.send,
				tabs: ["all", "active"],
			},
			{
				children: data.views,
				tabs: ["all", "active"],
			},
			{
				children: data.clic,
				tabs: ["all", "active"],
			},
		];
	};

	const getEmptyMessage = (index: any) => {
		switch (index) {
			case "active":
				return "Vous n’avez aucun étudiant en recherche active pour l’instant.";
			case "waitConnection":
				return "Aucun étudiant n’est en attente de connexion à la plateforme.";
			default:
				return "Aucun résultat correspondant.";
		}
	};
	const handleTableSetCurrentFilters = (filters: any) => {
		dispatchEvent(setCurrentFilters(filters));
	};

	return (
		<div className="flex flex-col gap-md">
			<Table
				className="mt-md"
				emptyMessage={
					((studentsList === null || studentsList.length === 0) &&
						getEmptyMessage(
							(currentFilters &&
								Object.keys(currentFilters).length < 3 &&
								currentFilters?.state) ||
								"all",
						)) ||
					""
				}
				isLoading={studentsListLoading}
				filtersProps={{
					filters: currentFilters,
					setFilters: handleTableSetCurrentFilters,
				}}
				headers={headers}
				rows={DATASET.map((data: any) => {
					return {
						cells: cellsResolver(data),
					};
				})}
			/>
			<PaginationPrimary
				onChange={(page) => {
					if (currentFilters) {
						dispatchEvent(setPagination({ page }));
					}
				}}
				current={currentFilters?.page}
				last={studentsListLastPage}
			/>
		</div>
	);
}
