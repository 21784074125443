import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { cloudDownloadOutline, searchFill } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import { updateToastWithError, updateToastWithSuccess } from "@tools/Toasts";

import { exportContactsCSV } from "../ContactsAPI";

export function Head({ contactIdsList, filtersProps, load }) {
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search, 1000);
	const { filters, setFilters } = filtersProps;

	const exportContact = async () => {
		const id = toast.loading("Exportation en cours...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		const response = await exportContactsCSV(contactIdsList);
		const fileName = response.fileName;
		if (fileName) {
			updateToastWithSuccess(id, "Export terminé.");
			window.open(`${import.meta.env.VITE_BACK}/csv/${fileName}`, "_blank");
			return;
		}
		updateToastWithError(id, "Erreur lors de l'export.");
	};

	useEffect(() => {
		if (!load) {
			setFilters({
				...filters,
				search: debouncedValue !== "" ? debouncedValue.replace(" ", "%") : "",
				page: 1,
			});
		}
	}, [debouncedValue]);

	return (
		<div className="flex items-center justify-between">
			<InputLargePrimary
				icon={searchFill}
				className="w-fit"
				type="text"
				placeholder="Rechercher..."
				onChange={(e) => {
					e.target.value = e.target.value.replace(";", "");
					setSearch(e.target.value);
				}}
				value={filters.search ? filters.search : ""}
			/>

			<ButtonPrimary
				icon={cloudDownloadOutline}
				label="Exporter"
				onClick={exportContact}
			/>
		</div>
	);
}
