import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";
import { companiesManagementSlice } from "../store/companiesManagement.slice";

export const fetchCompanyMemo = async (
	dispatch: AppDispatch,
	api: AppGetState,
	dependencies: Dependencies,
) => {
	try {
		if (!dependencies.companyInformationsGateway) {
			throw new Error(
				"companyInformationsGateway is not available in dependencies",
			);
		}
		dispatch(companiesManagementSlice.actions.handleMemoLoading());
		const companyMemo =
			await dependencies.companyInformationsGateway.getCompanyMemo(
				api().companiesManagement.targetCompany,
			);

		dispatch(companiesManagementSlice.actions.modifyCompanyMemo(companyMemo));
		return companyMemo;
	} catch (error: unknown) {
		console.error(error);
		if (error instanceof Error && error.message === "aborted") return null;
		dispatch(
			companiesManagementSlice.actions.handleMemoError(
				extractError(error).message.toString(),
			),
		);
		return null;
	}
};
