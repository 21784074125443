import React from "react";
import { useNavigate } from "react-router";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";

import type { ResourceType } from "@/types/resourcesTypes";
import type { UseResourcesNavigationType } from "../useResourcesNavigation";
import { ResourcesList } from "./Body/ResourcesList";
import { ResourcesLoader } from "./ResourcesLoader";

export function Body({
	load,
	schoolResources,
	mgResources,
	presenterRef,
}: {
	load: boolean;
	schoolResources: ResourceType[];
	mgResources: ResourceType[];
	presenterRef: UseResourcesNavigationType;
}) {
	const navigate = useNavigate();

	return (
		<>
			<ScrollableTabGroup
				className="w-full"
				tabs={[
					{
						label: "Ressources",
						state: "resources",
						active: presenterRef.tabSelected === "resources",
					},
					{
						label: "Modèles de CV",
						state: "cvModels",
						active: presenterRef.tabSelected === "cvModels",
					},
					{
						label: "Modèles de document de motivation",
						state: "motivationLetterModels",
						active: presenterRef.tabSelected === "motivationLetterModels",
					},
				]}
				onTabChange={(tabIndex) => {
					switch (tabIndex) {
						case 1:
							navigate(presenterRef.cvModels);
							presenterRef.selectThisTab("cvModels");
							break;
						case 2:
							navigate(presenterRef.motivationLetter);
							presenterRef.selectThisTab("motivationLetterModels");
							break;
						default:
							navigate(presenterRef.resources);
							presenterRef.selectThisTab("resources");
							break;
					}
				}}
			/>
			{load ? (
				<ResourcesLoader tab={presenterRef.tabSelected} />
			) : (
				<ResourcesList
					schoolResources={schoolResources}
					mgResources={mgResources}
					isSchool={presenterRef.isSchool}
				/>
			)}
		</>
	);
}
