import React from "react";
import { NavLink } from "react-router-dom";

import { plusOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "../core/store/events.slice";
import { EventSearchbar } from "./EventSearchbar";

export default function EventsHeaderActionsRow({ canCreateEvent = false }) {
	return (
		<div
			className="mx-20 mt-12 flex w-full flex-col items-start justify-center gap-4 rounded-lg bg-white p-6 sm:flex-row sm:justify-between sm:p-4"
			role="menubar"
		>
			<EventSearchbar />
			{canCreateEvent && <AddEventButton />}
		</div>
	);
}

function AddEventButton() {
	const dispatchEvent = useAppDispatch();
	const handleAddButtonClick = () => {
		dispatchEvent(eventsSlice.actions.setEventTarget(undefined));
		dispatchEvent(eventsSlice.actions.setEventToCreate(undefined));
	};
	return (
		<NavLink to="/event-creation">
			<ButtonPrimary
				label="Ajouter un évènement"
				icon={plusOutline}
				onClick={() => handleAddButtonClick()}
				className="w-full sm:max-w-[245px]"
			/>
		</NavLink>
	);
}
