import { getSchoolBySubdomain } from "@navigation/NavigationAPI";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { NavLink } from "react-router-dom";

import { arrowBackOutline } from "@assets/Icons";
import oktaLogo from "@assets/illustrations/okta.svg";

import { setCookie } from "@tools/Cookies";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Spinner } from "@designSystem/atoms/Spinner";

import { getToken } from "./LoginOktaAPI";

export function LoginOkta() {
	const { authState, oktaAuth } = useOktaAuth();
	const { school, setSchool }: any = useOutletContext();
	const [authInProgress, setAuthInProgress] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleLogin = () => {
		oktaAuth.signInWithRedirect();
	};

	const mentorgoalLogin = async () => {
		setAuthInProgress(true);
		const { token, code, message }: any = await getToken(
			authState?.accessToken?.accessToken,
		);

		if (code === 200) {
			setCookie("mainToken", token);
			location.href = "/choose_profile";
		} else {
			setErrorMessage(message);
			setAuthInProgress(false);
		}
	};

	useEffect(() => {
		if (!school) {
			getSchoolBySubdomain("igs").then(({ name, logo, illustration }) => {
				if (name) {
					setSchool({
						subdomain: "igs",
						name,
						logo,
						illustrations: `${import.meta.env.VITE_S3}${illustration}`,
					});
				}
			});
		}
	}, [authState, oktaAuth]);

	return (
		<div className="flex flex-col gap-xlg ml-0 w-full md:ml-[8%] lg:w-11/12">
			{school ? (
				<div className="flex w-full items-center md:w-8/12">
					<Avatar size="lg" modify={false} image={school?.logo} />
					<p className="ml-sm text-md font-semibold text-primary-700P">
						Bienvenue sur votre espace {school?.name}
					</p>
				</div>
			) : (
				<h2 className="text-primary-900">Connexion</h2>
			)}
			{authInProgress ? (
				<div className="w-full flex items-center flex-col">
					<Spinner />
					<p className="mt-2">Connexion en cours...</p>
				</div>
			) : authState?.isAuthenticated ? (
				<div className="flex flex-col gap-sm">
					<ButtonPrimary
						label={`Continuer en tant que ${authState.idToken?.claims.name}`}
						onClick={() => {
							mentorgoalLogin();
						}}
					/>
					{errorMessage && (
						<p className="w-9/12 text-xsm text-error-dark">{errorMessage}</p>
					)}
					<ButtonSecondary
						label="Utiliser un autre compte"
						onClick={() => {
							oktaAuth.signOut();
						}}
						className="w-fit"
					/>
				</div>
			) : (
				<div className="flex flex-col gap-sm">
					<button
						onClick={handleLogin}
						className="button-primary flex w-full !rounded-md px-1 md:w-8/12"
						type="button"
					>
						<img src={oktaLogo} alt="okta" className="w-6 h-6 mr-2" />
						Se connecter avec Okta
					</button>
					{errorMessage && (
						<p className="mt-3 w-9/12 text-xsm text-error-dark">
							{errorMessage}
						</p>
					)}
					<NavLink
						to={import.meta.env.VITE_URL || "/"}
						className="flex w-full items-center md:w-8/12"
					>
						<ButtonSecondary
							icon={arrowBackOutline}
							label="Retourner a la page de connexion"
						/>
					</NavLink>
				</div>
			)}
		</div>
	);
}
