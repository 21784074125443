import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { Avatar } from "@designSystem/atoms/Avatar";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";

type CellAvatarLabelSecondaryType = {
	label: string;
	sublabel: string;
	firstname: string;
	lastname: string;
	avatar?: string;
	url?: string;
	onClick?: any;
	avatarColor?: string;
	className?: string;
	translate?: boolean;
	maxWidth?: number;
};

export function CellAvatarLabelSecondary({
	label,
	sublabel,
	firstname,
	lastname,
	avatar,
	url,
	onClick,
	avatarColor,
	className,
	translate = true,
}: CellAvatarLabelSecondaryType) {
	const [showLabelHint, setShowLabelHint] = useState<boolean>(false);
	const [showSublabelHint, setShowSublabelHint] = useState<boolean>(false);
	const [randomId] = useState<number>(Math.floor(Math.random() * 256));
	const labelRef = useRef<HTMLParagraphElement>(null);
	const sublabelRef = useRef<HTMLParagraphElement>(null);

	useEffect(() => {
		if (labelRef?.current) {
			const label = labelRef.current;
			if (label.offsetWidth < label.scrollWidth) {
				setShowLabelHint(true);
			}
		}

		if (sublabel && sublabelRef?.current) {
			const sublabel = sublabelRef.current;
			if (sublabel.offsetWidth < sublabel.scrollWidth) {
				setShowSublabelHint(true);
			}
		}
	}, [labelRef, sublabelRef]);

	return (
		<>
			{showLabelHint && (
				<TooltipContainer
					anchorId={`#label-${randomId}`}
					makeClickable={false}
					children={label}
					place="top"
				/>
			)}
			{sublabel && showSublabelHint && (
				<TooltipContainer
					anchorId={`#sublabel-${randomId}`}
					makeClickable={false}
					children={sublabel}
					place="top"
				/>
			)}
			{url ? (
				<NavLink
					to={url}
					className={`cell text-decoration-none wg-no-translate flex items-center ${className} ${
						url ? "cursor-pointer" : ""
					}${!translate ? " wg-no-translate" : ""}`}
				>
					<Avatar
						firstname={firstname}
						lastname={lastname}
						image={avatar || null}
						bgColor={avatarColor}
						size="xs"
					/>
					<div className="ml-sm flex w-10/12 flex-col p-0">
						<p id={`label-${randomId}`} className="text truncate max-w-full">
							{label}
						</p>
						{sublabel && (
							<p
								id={`sublabel-${randomId}`}
								className="text truncate max-w-full text-primary-200"
							>
								{sublabel}
							</p>
						)}
					</div>
				</NavLink>
			) : (
				<div
					onClick={onClick}
					className={`flex items-center !pl-0 transition ${className} ${
						onClick ? "cursor-pointer" : ""
					}${!translate ? " wg-no-translate" : ""}`}
				>
					<Avatar
						firstname={firstname}
						lastname={lastname}
						image={avatar || null}
						size="xs"
					/>
					<div className="ml-sm flex flex-col w-10/12">
						<p
							ref={labelRef}
							id={`label-${randomId}`}
							className="text truncate max-w-full"
						>
							{label}
						</p>
						{sublabel && (
							<p
								id={`sublabel-${randomId}`}
								className="text truncate max-w-full text-primary-200"
								ref={sublabelRef}
							>
								{sublabel}
							</p>
						)}
					</div>
				</div>
			)}
		</>
	);
}
