import { checkmarkCircle2Outline, refreshFill } from "@assets/Icons";
import { Icon } from "@iconify/react";
import React from "react";

import { TextArea } from "@designSystem/atoms/TextArea";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import type { IMemoGateway } from "../core/memo.gateway";
import { useMemoComponent } from "./useMemoComponent";

type MemoProps = {
	memoId: string;
	defaultValue?: string;
	title?: string;
	tooltip?: string;
	placeholder?: string;
	memoGateway: IMemoGateway;
	disabled?: boolean;
};

export function Memo({
	memoId,
	defaultValue = "",
	title = "Mémo",
	tooltip,
	placeholder = "Écrivez un mémo ici…",
	memoGateway,
	disabled = false,
}: MemoProps) {
	const presenter = useMemoComponent({ memoId, memoGateway, defaultValue });

	return (
		<aside className="flex  min-h-fit w-full flex-col items-center gap-6 rounded-lg bg-secondary-100 p-4">
			<div className="flex items-center justify-start gap-1">
				<p className="text-sm font-bold text-primary-700P">{title}</p>
				<TooltipElement id={`tooltip-memo-${presenter.uniqueId}`} />
				<TooltipContainer
					anchorId={`#tooltip-memo-${presenter.uniqueId}`}
					makeClickable={false}
					children={
						tooltip
							? tooltip
							: `Seuls vous et les autres référents de ${presenter.schoolName} pourront voir et modifier le mémo.`
					}
					place="top"
				/>
			</div>
			<TextArea
				value={presenter.memoBody}
				onChange={presenter.changeThisMemo}
				placeholder={
					presenter.isInitialized ? placeholder : "Chargement du mémo..."
				}
				areaBackground="bg-transparent"
				height={"auto"}
				autosize
				disabled={disabled}
			/>

			<div
				className="flex w-full items-center justify-start gap-1"
				data-testid="memo-status"
			>
				{presenter.isSaving && (
					<>
						<p className="text-xxsm font-bold text-primary-200">
							Enregistrement...
						</p>
						<Icon
							icon={refreshFill}
							className="animate-spin text-secondary-700"
						/>
					</>
				)}
				{presenter.showSaveSuccessStatus && (
					<>
						<p className="text-xxsm font-bold text-primary-200">Enregistré</p>{" "}
						<Icon
							icon={checkmarkCircle2Outline}
							className="text-success-dark"
						/>
					</>
				)}
				{presenter.showErrorMessage && (
					<p className="text-xxsm font-bold text-primary-200">
						Erreur lors de la modification du mémo
					</p>
				)}
			</div>
		</aside>
	);
}
