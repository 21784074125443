import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { arrowBackOutline } from "@assets/Icons";

import { useAppSelector } from "@config/redux/hook";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { GlobalContext } from "@navigation/Router";

export function MailSent() {
	const navigate = useNavigate();
	const { school } = useAppSelector((state) => state.company);
	const { user } = useContext(GlobalContext);
	return (
		<div className="mx-auto mt-sm w-11/12 md:w-10/12 lg:w-8/12">
			<InlineButton
				className="mb-sm"
				label="Retour"
				icon={arrowBackOutline}
				onClick={() => {
					navigate(`/company?${school?.subdomain || ""}`);
				}}
			/>
			<div className="mx-auto mb-lg h-auto w-full">
				<div className="mb-lg rounded-md bg-white p-lg text-center shadow-md">
					<p className="mb-lg font-bold">Demande envoyée</p>
					<p>
						Nous avons le plaisir de vous informer que nous avons bien reçu
						votre demande d'adhésion pour rejoindre l’espace{" "}
						<b>{user?.entCompany?.name}</b>. <br />
						<br />
						Votre demande est <b>en cours de traitement</b>, et nous vous
						tiendrons informé(e) dans les plus brefs délais.
					</p>
				</div>
			</div>
		</div>
	);
}
