import axios from "axios";

import { getCookie } from "@tools/Cookies";
import { errorToast } from "@tools/Toasts";

export async function login(data: { email: string | null; password: string }) {
	let rep: { token?: string } = {};
	await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
			headers: {
				"Content-Type": "application/json",
				"X-Forwarded-For": getCookie("ip"),
			},
		})
		.post("authentication_token", data)
		.then(({ data }) => {
			rep = data;
		});

	return rep;
}

export async function changePassword(
	token: string,
	pwData: { password: string; passwordVerified: string },
	school?: number,
) {
	let rep: {
		code?: number;
		message?: string;
		email?: string;
		firstConnexion?: boolean;
	} = {};
	// Paramètre school utilisé pour l'espace entreprise uniquement
	const url = school
		? `change_password/${token}?school=${school}`
		: `change_password/${token}`;
	try {
		const { data } = await axios
			.create({
				baseURL: `${import.meta.env.VITE_BACK}/`,
				timeout: 20000,
			})
			.post(url, pwData);
		rep = data;
	} catch {
		errorToast("Une erreur est survenue");
	}

	return rep;
}

export async function retrieveUser(token: string) {
	let rep: { code?: number; message?: string; firstname?: string } = {};
	await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
		})
		.post(`retrieve_user/${token}`)
		.then(({ data }) => {
			rep = data;
		})
		.catch((error) => {
			return error;
		});

	return rep;
}
