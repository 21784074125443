import api from "@tools/API";

export async function getSchoolDashboardData({ school }) {
	let rep = {};
	const data = {
		query: `query {
      school (id: "${school}") {
          id
          toPlace
          notSearching
          placed
          surrend
          applications
          relaunchs
          interviews
          inactiveUsers
          monthlyActiveUsers
          weeklyActiveUsers
          neverConnectedUsers
          beingSigned
          contactsNbr
          userPlacementRate
          promotions (itemsPerPage: 1000, type: "student", archived: false) {
            collection {
              id
              name
            }
          }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getCampusDashboardData({ campus }) {
	let rep = {};
	const data = {
		query: `query {
      campus (id: "${campus}") {
          id
          toPlace
          notSearching
          placed
          surrend
          applications
          relaunchs
          interviews
          inactiveUsers
          monthlyActiveUsers
          weeklyActiveUsers
          neverConnectedUsers
          beingSigned
          contactsNbr
          userPlacementRate
          promotions (itemsPerPage: 1000, type: "student", archived: false) {
            collection {
              id
              name
            }
          }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getPromotionDashboardData(promotionURI) {
	let rep = {};
	const data = {
		query: `query {
      promotion (id: "${promotionURI}") {
          id
          toPlace
          notSearching
          placed
          surrend
          applications
          relaunchs
          interviews
          inactiveUsers
          monthlyActiveUsers
          weeklyActiveUsers
          neverConnectedUsers
          beingSigned
          contactsNbr
          userPlacementRate
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}
