import { InlineIcon } from "@iconify/react";
import { Formik, type FormikErrors, type FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "../ResourceEditor.scss";

import { infoOutline, link2Outline, pricetagsOutline } from "@assets/Icons";

import { RESOURCE_TYPE_OPTIONS } from "@constants/Resources";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { CheckboxLabelPrimary } from "@designSystem/molecules/CheckboxLabel";
import { FileInput } from "@designSystem/molecules/FileInput";
import { FilesPrimary } from "@designSystem/molecules/Files";
import { Input } from "@designSystem/molecules/Input";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";

import {
	createResource,
	editResources,
	upload,
} from "@containers/school/Resources/ResourceEditor/ResourceEditorAPI";

import type { FileType, MediaObjectType } from "@/types/mediaObjectTypes";
import type { ResourceType } from "@/types/resourcesTypes";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { formatRichTextEditor } from "@tools/Strings";
import { success } from "@tools/Toasts";

const RESOURCE_TEXT_MAX_LENGTH = 12000;

type FormPropsType = {
	loadingState: {
		loading: boolean;
		setLoading: (loading: boolean) => void;
	};
	resourceId?: string;
	newResourceProps: {
		newResource: ResourceType;
		setNewResource: (resource: ResourceType) => void;
	};
	illustrationProps: {
		illustration?: FileType;
		setIllustration?: (illustration: FileType) => void;
	};
};

export function Form({
	loadingState,
	resourceId,
	newResourceProps,
}: FormPropsType) {
	const { newResource, setNewResource } = newResourceProps;
	const [defaultType, setDefaultType] = useState(newResource.type);
	const { setLoading } = loadingState;
	const navigate = useNavigate();
	const [resourcePdf, setResourcePdf] = useState<
		undefined | FileType | string
	>();
	const [resourceFileLoading, setResourceFileLoading] = useState(false);

	const uploadFile = async (files: File[]) => {
		setResourceFileLoading(true);

		const data = new FormData();
		data.append("file", files[0]);

		const media: MediaObjectType = (await upload(data)) as MediaObjectType;

		setNewResource({
			...newResource,
			pdf: media["@id"],
		});
		setResourcePdf({
			id: media["@id"],
			size: media.size,
			filePath: media.filePath,
		});

		setResourceFileLoading(false);
	};

	const postResource = async (dataToSend: ResourceType) => {
		await createResource({
			...dataToSend,
		});
		success("La ressource a bien été créée");

		setLoading(false);
		navigate("/school-resources", {
			state: {
				isNewResource: true,
			},
		});
	};

	const updateResource = async (dataToSend: ResourceType) => {
		if (dataToSend.pdf && typeof dataToSend.pdf !== "string") {
			dataToSend.pdf = dataToSend.pdf.id;
		}
		if (
			dataToSend.illustration &&
			typeof dataToSend.illustration !== "string"
		) {
			dataToSend.illustration = dataToSend.illustration.id;
		}

		await editResources({
			...dataToSend,
			id: `${resourceId}`,
		});
		setLoading(false);
		navigate("/school-resources", {
			state: {
				isModifyResource: true,
			},
		});
	};

	useEffect(() => {
		if (newResource.pdf) {
			setResourcePdf(newResource.pdf);
		}
		setDefaultType(
			RESOURCE_TYPE_OPTIONS.find((x) => x.value === newResource.type)?.value ||
				"resource",
		);
		setResourceFileLoading(false);
	}, []);

	return (
		<Formik
			className="mb-xxlg"
			initialValues={{
				title: newResource.title,
				description: newResource.description ?? "",
				content: newResource?.content ?? "",
				isOnline: newResource?.isOnline || true,
				isMentorgoal: false,
				link: newResource.link,
				isDirectLink: newResource.isDirectLink || false,
			}}
			enableReinitialize
			validate={(values) => {
				const errors: FormikErrors<FormikValues> = {};

				if (!values.title) {
					errors.title = "Veuillez entrer le titre de la ressource";
				}

				if (
					!values.description &&
					newResource.type === "resource" &&
					!values.isDirectLink
				) {
					errors.description = "Veuillez entrer une courte description ";
				}

				if (values.content.length > 1000000) {
					errors.content =
						"Les images et GIFs de la ressource ne doivent pas dépasser 1 Mo.";
				}

				if (!values.link && values.isDirectLink) {
					errors.link = "Veuillez entrer un lien";
				}

				if (values.description && values.description.length > 512) {
					errors.description =
						"Veuillez entrer une courte description de 512 caractères maximum";
				}

				return errors;
			}}
			onSubmit={(values, { resetForm }) => {
				values.content = formatRichTextEditor(values.content);
				const dataToSend: any = {
					...newResource,
					...values,
				};
				setLoading(true);
				if (window.location.pathname.split("/")[2] !== "create") {
					dataToSend.views = undefined;
					dataToSend.users = undefined;
					dataToSend.createdAt = undefined;
					dataToSend.author = undefined;
					updateResource(dataToSend);
				} else {
					postResource(dataToSend);
				}
				setNewResource({ ...newResource, ...values });
				resetForm({});
			}}
		>
			{({
				values,
				touched,
				handleChange,
				handleSubmit,
				errors,
				setFieldValue,
			}) => (
				<div>
					<Input
						name="title"
						placeholder="Titre de la ressource"
						icon={pricetagsOutline}
						value={values.title}
						onChange={handleChange}
						errorMessage={
							errors.title && typeof errors.title === "string" && touched.title
								? errors.title
								: undefined
						}
					/>
					<SelectTertiary
						className="resource-type mb-sm mt-sm"
						options={RESOURCE_TYPE_OPTIONS}
						defaultValue={defaultType}
						onChange={(e) => {
							setNewResource({ ...newResource, type: e.value });

							setFieldValue(
								"isDirectLink",
								e.value === "cvModel" || e.value === "motivationLetterModel",
							);
						}}
						placeholder="Type de ressource"
					/>
					{!values.isDirectLink && (
						<Input
							name="description"
							className="mb-sm mt-sm"
							placeholder="Description courte"
							value={values.description}
							onChange={handleChange}
							errorMessage={
								errors.description &&
								typeof errors.description === "string" &&
								touched.description
									? errors.description
									: undefined
							}
						/>
					)}
					{newResource.type === "resource" && (
						<div className="flex items-center">
							<CheckboxLabelPrimary
								className="mr-xxsm"
								label="Lien direct"
								checked={values.isDirectLink}
								onCheck={(e: boolean) => {
									setFieldValue("isDirectLink", e);
								}}
							/>

							<TooltipContainer anchorId="#info-external-icon" place="right">
								La ressource enverra directement vers un lien externe.
							</TooltipContainer>
							<div id="info-external-icon" className="ml-xxsm cursor-pointer">
								<InlineIcon
									className=" h-4 text-primary-500"
									icon={infoOutline}
								/>
							</div>
						</div>
					)}
					<p className="text-p mt-sm pt-xxsm text-primary-200">
						Lien{" "}
						{newResource.type === "resource" ? "de la ressource" : "du modèle"}{" "}
						{!values.isDirectLink && "(optionnel)"}
					</p>
					<Input
						name="link"
						className=" mb-sm"
						placeholder="https://"
						icon={link2Outline}
						value={values.link}
						onChange={handleChange}
						errorMessage={
							errors.link && typeof errors.link === "string" && touched.link
								? errors.link
								: undefined
						}
					/>
					{!values.isDirectLink && (
						<div>
							<RichTextEditor
								maxLength={RESOURCE_TEXT_MAX_LENGTH}
								placeholder="Redigez votre contenu ici..."
								onChange={(e) => {
									setFieldValue("content", e);
								}}
								value={values.content}
							/>
							{errors.content && (
								<p className="text-p pt-xxsm text-error-dark">
									{errors.content}
								</p>
							)}
							{resourcePdf ? (
								<FilesPrimary
									isLoading={resourceFileLoading}
									className="mt-sm"
									title={
										typeof resourcePdf === "string"
											? resourcePdf
											: resourcePdf.filePath
									}
									subtitle={`${typeof resourcePdf === "string" ? undefined : Math.floor(resourcePdf.size / 1000)} Ko`}
									onView={() => {
										if (resourcePdf) {
											const windowOpened = window.open(
												`${import.meta.env.VITE_S3}${typeof resourcePdf === "string" ? resourcePdf : resourcePdf?.filePath}`,
											);
											if (windowOpened) {
												windowOpened.focus();
											}
										}
									}}
									onDelete={() => {
										if (window.location.pathname.split("/")[2] !== "create") {
											editResources({ id: newResource.id, pdf: null });
										}
										setNewResource({ ...newResource, pdf: null });
										setResourcePdf(undefined);
									}}
								/>
							) : (
								<>
									<p className=" text-p mt-sm pt-xxsm text-primary-200">
										Pièces-jointes (optionnel)
									</p>
									<FileInput
										onError={() => {}}
										isLoading={resourceFileLoading}
										id="documents-upload"
										className="mt-sm"
										onSuccess={uploadFile}
										maxSize={1}
										acceptFormats={{ "application/pdf": [] }}
									/>
								</>
							)}
						</div>
					)}
					<div className="mb-md ml-[66%] mt-md flex w-4/12 items-center  justify-end">
						<NavLink
							to="/school-resources"
							className="text-decoration-none mr-md"
						>
							<ButtonSecondary label="Annuler" className="button-form" />
						</NavLink>
						<ButtonPrimary
							key="confirmBTN"
							label={
								window.location.pathname.split("/")[2] === "create"
									? "Créer"
									: "Modifier"
							}
							className=" button-form "
							type="submit"
							onClick={() => handleSubmit()}
							disabled={values.content.length > RESOURCE_TEXT_MAX_LENGTH}
						/>
					</div>
				</div>
			)}
		</Formik>
	);
}
