import React, { useEffect, useState } from "react";

import "@assets/sass/RadioGroup.scss";

import { RadioPrimitive } from "./RadioPrimitive";

let uniqueId = 0;

export type RadioElementType = {
	label: string;
	value: string | number;
	className?: string;
	defaultCheckedState?: boolean | undefined;
};

type RadioGroupType = {
	elements: RadioElementType[];
	handleOnChange: (value: number) => void;
	className?: string | undefined;
	storedValue?: number;
	direction?: "horizontal" | "vertical";
	side?: "right" | "left";
	disabled?: boolean;
};

const makeElementName = (componentId: string, index: number): string => {
	return `radiogroup-${componentId}-${index}`;
};

export function RadioGroup({
	elements,
	handleOnChange,
	className,
	storedValue,
	direction = "horizontal",
	side = "right",
	disabled = false,
}: RadioGroupType) {
	const [componentId] = useState<number>(() => uniqueId++);
	const [elementsProps, setElements] = useState<RadioElementType[]>([]);
	const [selectedElementIndex, setSelectedElementIndex] = useState<number>(0);

	useEffect(() => {
		setElements(elements);
	}, [elements]);

	const handleOnChangeRadio = (value: number) => {
		if (disabled) return;
		setSelectedElementIndex(value);
		handleOnChange(value);
	};

	useEffect(() => {
		if (storedValue) {
			setSelectedElementIndex(storedValue);
		}
	}, [storedValue]);
	return (
		<div
			className={`radio-group-container flex w-full gap-xxsm ${
				direction === "vertical" ? "flex-col" : ""
			} ${className}`}
		>
			{elementsProps.map((currentElement, index) => {
				return (
					<div
						key={`${index}/${currentElement.label}`}
						className={`flex ${
							direction === "vertical"
								? side === "left"
									? "flex-row-reverse mr-auto"
									: "flex-row-reverse"
								: "flex-col"
						} items-center`}
					>
						<label
							className={`${direction === "vertical" ? "ml-xsm" : "mb-xsm"} ${
								currentElement.className ??
								"text-xxsm font-normal text-primary-700P"
							}${disabled ? " !text-primary-500" : ""}`}
							htmlFor={`radio-${makeElementName(
								componentId.toString(),
								index,
							)}`}
						>
							{currentElement.label}
						</label>
						<RadioPrimitive
							defaultState={currentElement?.defaultCheckedState || false}
							itemSelected={selectedElementIndex}
							onChange={handleOnChangeRadio}
							name={makeElementName(componentId.toString(), index)}
						/>
					</div>
				);
			})}
		</div>
	);
}
