import type { ApplicationThumbType } from "@/types/applicationTypes";
import type { OfferApplicationType, SchoolOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import {
	type ScrollableTab,
	ScrollableTabGroup,
} from "@designSystem/molecules/TabGroup";

import { setCurrentDetailTabSelected } from "../../core/store/offersManagement.slice";

const TABS_FOR_PRESELECTED_TAB: ScrollableTab[] = [
	{
		label: "Offre",
		state: "offer",
		active: true,
	},
	{
		label: "Candidatures",
		state: "applications",
		active: false,
	},
	{
		label: "Envoi à l'entreprise",
		state: "sendToCompany",
		active: false,
	},
];

const TABS_FOR_DIRECT_TAB: ScrollableTab[] = [
	{
		label: "Offre",
		state: "offer",
		active: true,
	},
	{
		label: "Candidatures",
		state: "applications",
		active: false,
	},
];

const MakeScrollableTabGroupTabs = (
	offerData: Partial<SchoolOfferType>,
	typeOfOffer: OfferApplicationType,
	currentTabSelected: string,
): ScrollableTab[] => {
	const tabs: ScrollableTab[] = [];
	const { applicationsThumbnails } = useAppSelector((state) => state.offers);
	// if direct, use direct tabs. Check if the tabs is currently selected, if so, set it to active

	if (typeOfOffer === "direct") {
		TABS_FOR_DIRECT_TAB.forEach((tab) => {
			if (tab.state === "applications") {
				tab.label = "Candidatures";
			}
			tab.active = tab.state === currentTabSelected;
			tabs.push(tab);
		});
	} else {
		TABS_FOR_PRESELECTED_TAB.forEach((tab) => {
			if (tab.state === "applications") {
				const applicationsToTreat = offerData?.applicationsToTreat || 0;
				tab.label = `Candidatures (${applicationsToTreat} à traiter)`;
			}
			if (tab.state === "sendToCompany") {
				const numberOfApplicationsAccepted =
					applicationsThumbnails.filter(
						(application: ApplicationThumbType) =>
							application?.state === "accepted",
					)?.length || 0;
				const numberOfApplicationsTransmitted =
					offerData?.selection?.selectionUsers?.collection?.length || 0;
				const needToBeSendCounter =
					numberOfApplicationsAccepted - numberOfApplicationsTransmitted;
				tab.label = `Envoi à l'entreprise ${
					needToBeSendCounter > 0 ? `(${needToBeSendCounter} à envoyer)` : ""
				}`;
			}
			tab.active = tab.state === currentTabSelected;
			tabs.push(tab);
		});
	}
	return tabs;
};

function OfferDetailsTabs() {
	const dispatchEvent = useDispatch();
	const { currentDetailTabSelected, offerToEdit } = useAppSelector(
		(state) => state.offers,
	);

	const handleTabChange = (index: number) => {
		const tabsToUse =
			offerToEdit?.type === "direct"
				? TABS_FOR_DIRECT_TAB
				: TABS_FOR_PRESELECTED_TAB;
		const tabStateFromIndex = tabsToUse[index].state;
		if (tabStateFromIndex === currentDetailTabSelected) return;
		dispatchEvent(setCurrentDetailTabSelected(tabStateFromIndex));
	};
	return (
		<div className="col-span-12 w-full" data-testid="offerDetailsTab">
			<ScrollableTabGroup
				tabs={MakeScrollableTabGroupTabs(
					offerToEdit as SchoolOfferType,
					offerToEdit?.type || "direct",
					currentDetailTabSelected || "Offre",
				)}
				onTabChange={(index) => handleTabChange(index)}
			/>
		</div>
	);
}

export default OfferDetailsTabs;
