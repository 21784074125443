import type {
	OfferCellNameType,
	OffersStatusType,
	SchoolOfferType,
} from "@/types/offerTypes";
import type { UserType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import useOfferCellFactory from "@hooks/offers/useOfferCellFactory";
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
	CELL_DISALLOWED_RULES,
	OFFER_CLOSE_REASON_OPTIONS,
} from "@constants/Offers";

import { convertOfferCellsNamesToCells } from "@tools/Offers";
import { sortCellsByIndex } from "@tools/Table";
import { extractIdNumberFromId } from "@tools/Users";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { SelectItemPerPage } from "@designSystem/organisms/select/SelectItemPerPage";
import { type HeadType, Table } from "@designSystem/templates/table/Table";
import { StudentsTargetingModal } from "@modules/studentsTargetingModal";
import { updateToastWithError, updateToastWithSuccess } from "@tools/Toasts";

import { updateShareOffer } from "../../core/api/offerDetails.request";
import { provideOffer } from "../../core/api/offersManagement.request";
import {
	setCurrentPage,
	setItemsPerPage,
	setOfferStatusWasUpdated,
	setOfferToEdit,
	setOrder,
} from "../../core/store/offersManagement.slice";
import { fetchModifyOfferStatus } from "../../core/store/offersManagement.thunks";
import { CloseOfferModal } from "../modal/CloseOfferModal";

const MIN_OFFERS_FOR_SHOWING_SELECT = 9;

function OffersTable({
	data,
	currentStructureView,
}: {
	data: SchoolOfferType[];
	currentStructureView: object;
}) {
	const dispatchEvent = useDispatch();
	const {
		loadingStates,
		userTableConfig,
		currentFilters,
		currentTabSelected,
		itemsPerPage,
		pageShown,
		lastPage,
		offerToEdit,
		filteredOffersList,
	} = useAppSelector((state) => state.offers);
	const [showShareModal, setShowShareModal] = useState<boolean>(false);
	const [showCloseOfferModal, setShowCloseOfferModal] =
		useState<boolean>(false);
	const [closeReason, setCloseReason] = useState<string>(
		OFFER_CLOSE_REASON_OPTIONS[0].label,
	);
	const [wantedStatus, setWantedStatus] =
		useState<OffersStatusType>("provided");

	const [studentWhoProvided, setStudentWhoProvided] = useState<
		string | undefined
	>();

	const handleShareAction = (dataContainId: { id: string }) => {
		const offerData = filteredOffersList?.find(
			(offer: SchoolOfferType) => offer.id === dataContainId.id,
		);
		dispatchEvent(setOfferToEdit(offerData));
		setShowShareModal(!showShareModal);
	};

	const handleModifyCloseReason = (reason: string) => {
		setCloseReason(reason);
	};

	const handleModifyStudentWhoProvided = (student: UserType) => {
		setStudentWhoProvided(student?.id);
	};
	const handleCloseOffer = (dataContainId: { id: string }) => {
		dispatchEvent(setOfferToEdit(dataContainId));
		setShowCloseOfferModal(!showCloseOfferModal);
	};

	const handleConfirmCloseOffer = async () => {
		if (studentWhoProvided) {
			const response = await provideOffer(
				offerToEdit?.id || "",
				studentWhoProvided || "",
			);
			if (response.responseLabel === "success") {
				dispatchEvent(
					fetchModifyOfferStatus({
						id: offerToEdit?.id,
						status: wantedStatus,
						closeReason,
					}) as any,
				);
			}
		} else {
			dispatchEvent(
				fetchModifyOfferStatus({
					id: offerToEdit?.id,
					status: wantedStatus,
					closeReason,
				}) as any,
			);
		}
		dispatchEvent(setOfferStatusWasUpdated(true));
		setStudentWhoProvided(undefined);
		setShowCloseOfferModal(false);
	};

	const { buildOfferCellFromName } = useOfferCellFactory({
		handleShareAction,
		handleCloseOffer,
	});

	const generateRows = () => {
		if (!data) return [];
		return data?.map((offer) => {
			const cells: any = [];

			// We get the cells from the user table config and we sort them by index after conversion
			sortCellsByIndex(convertOfferCellsNamesToCells(userTableConfig))?.forEach(
				(cell) => {
					const disallowRules = CELL_DISALLOWED_RULES.get(
						cell.cellName as OfferCellNameType,
					);
					if (disallowRules?.includes(currentTabSelected as OffersStatusType)) {
						return;
					}
					cells.push({
						children: buildOfferCellFromName(
							cell.cellName as OfferCellNameType,
							offer,
						),
					});
				},
			);
			return {
				url: `/offers/details/${extractIdNumberFromId(offer.id)}`,
				cells,
			};
		});
	};

	const getHeaders = () => {
		const headers: HeadType[] = [];
		if (!userTableConfig) return headers;
		sortCellsByIndex(convertOfferCellsNamesToCells(userTableConfig))?.forEach(
			(cell) => {
				const disallowRules = CELL_DISALLOWED_RULES.get(
					cell.cellName as OfferCellNameType,
				);
				if (disallowRules?.includes(currentTabSelected as OffersStatusType)) {
					return;
				}
				const header: HeadType = {
					label: cell.cellLabel,
					sort: undefined,
				};

				if (
					!["Tags", "Actualisation", "Expire", "Clôturer", "Partager"].includes(
						cell.cellLabel,
					)
				) {
					header.sort = { fieldName: cell.cellName, defaultOrder: "ASC" };
				}

				headers.push(header);
			},
		);
		return headers;
	};

	const modifyFilters = useCallback((filters: any) => {
		const key = Object.keys(filters.order[0])[0];
		const value = filters.order[0][key];
		const stringifiedOrder = `order: [{${key}: "${value}"}]`;
		dispatchEvent(setOrder(stringifiedOrder) as any);
	}, []);

	const modifyItemPerPage = useCallback((value: number) => {
		dispatchEvent(setItemsPerPage(value));
	}, []);

	const modifyCurrentPage = useCallback((value: number) => {
		dispatchEvent(setCurrentPage(value));
	}, []);

	const handleInviteStudents = async (
		sendEmail: boolean,
		sendSMS: boolean,
		selectedStudents: any,
	) => {
		const toastId = toast.loading("Envoi des invitations...", {
			isLoading: true,
			hideProgressBar: false,
			type: "info",
		});
		const data = {
			offerId: offerToEdit?.id || "",
			sendEmail,
			sendSMS,
			students: selectedStudents.map((user: Partial<UserType>) => user.id),
		};
		const response = await updateShareOffer(data);
		if (response.responseLabel === "success") {
			updateToastWithSuccess(toastId, "Invitations envoyées");
			dispatchEvent(setOfferStatusWasUpdated(true));
		} else {
			updateToastWithError(
				toastId,
				"Une erreur est survenue lors de l'envoi des invitations",
			);
		}
		return [sendEmail, sendSMS, selectedStudents];
	};

	return (
		<section
			data-testid="offersTableContainer"
			className="mt-sm overflow-x-auto"
		>
			<Table
				headers={getHeaders()}
				rows={generateRows()}
				filtersProps={{
					filters: currentFilters || {},
					setFilters: (filters: any) => {
						modifyFilters(filters);
					},
				}}
				isLoading={loadingStates.isFetchingOffersList}
			/>
			<div className="flex justify-between rounded-b-md bg-white px-sm pb-md pt-lg">
				{data && data.length > MIN_OFFERS_FOR_SHOWING_SELECT && (
					<SelectItemPerPage
						onChange={modifyItemPerPage}
						defaultValue={itemsPerPage}
					/>
				)}
				<PaginationPrimary
					last={lastPage}
					current={pageShown}
					onChange={modifyCurrentPage}
					isLoading={loadingStates.isFetchingOffersList}
				/>
			</div>
			{currentStructureView && (
				<>
					<StudentsTargetingModal
						show={showShareModal}
						onClose={() => {
							setShowShareModal(false);
						}}
						structureView={currentStructureView}
						label="Vous pouvez sélectionner des étudiants à qui partager l'offre pour les notifier."
						title={`Partager l'offre ${offerToEdit?.job}`}
						share={(sendEmail: boolean, sendSMS, selectedStudents) => {
							handleInviteStudents(sendEmail, sendSMS, selectedStudents);
						}}
					/>
					<Modal
						show={showCloseOfferModal}
						onClose={() => {
							setShowCloseOfferModal(false);
						}}
						title="Clôturer l'offre"
						body={
							<CloseOfferModal
								catchCloseReason={(e) => handleModifyCloseReason(e)}
								triggerStudentSelection={(e) =>
									handleModifyStudentWhoProvided(e)
								}
								triggerWantedStatus={(e) => {
									setWantedStatus(e);
								}}
							/>
						}
						buttonsRight={[
							<ButtonSecondary
								key={0}
								onClick={() => setShowCloseOfferModal(false)}
								label="Annuler"
							/>,
							<ButtonPrimary
								key={1}
								onClick={() => {
									handleConfirmCloseOffer();
									setShowCloseOfferModal(false);
								}}
								label="Clôturer"
							/>,
						]}
					/>
				</>
			)}
		</section>
	);
}

export default memo(OffersTable);
