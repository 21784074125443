import type { AppDispatch } from "@config/redux/store";
import { getOfferMemo } from "@containers/school/Offers_v3/core/api/offersManagement.request";
import { fetchModifyOfferMemo } from "@containers/school/Offers_v3/core/store/offersManagement.thunks";
import type {
	IMemoGateway,
	RetrieveMemoType,
} from "@designSystem/molecules/memo/core/memo.gateway";

export class OfferMemoGateway implements IMemoGateway {
	public dispatcher: AppDispatch;
	constructor(dispatcher: AppDispatch) {
		this.dispatcher = dispatcher;
	}
	async saveThisMemo(
		id: string,
		value: string,
		initialValue: string,
	): Promise<boolean> {
		if (initialValue === value) return false;
		const response = await this.dispatcher(
			fetchModifyOfferMemo({
				id: id || "",
				memo: value,
			}) as any,
		);
		if (response?.payload === undefined || response?.payload === null) {
			return true;
		}
		return false;
	}

	async retrieveMemo(id: string): Promise<RetrieveMemoType> {
		const response = await getOfferMemo(id || "");
		return { body: response.data?.offer?.memo || "", initialValue: "" };
	}
}
