import type { ApplicationType } from "@/types/applicationTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import algoliasearch from "algoliasearch/lite";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { stringifyRequestFilters } from "@tools/Objects";

import { Onboarding } from "@modules/onboarding/Onboarding";

import type { ViewType } from "@/types/genericType";
import { EOnboarding } from "@constants/Others";
import { setCookie } from "@tools/Cookies";
import { useLocation } from "react-router";
import { retrieveApplications } from "../core/api/jobboard.api";
import {
	type JobboardFiltersType,
	setFilters,
	setTab,
	setTotalScrapedOffersCount,
	setUserApplications,
} from "../core/store/jobboard.slice";
import {
	fetchSchoolOffers,
	fetchSchoolOffersCount,
	fetchScrapedOffers,
} from "../core/store/jobboard.thunks";
import { Body } from "../ui/jobboard/ui/Body";
import { Head } from "../ui/jobboard/ui/Head";

const client = algoliasearch(
	import.meta.env.VITE_ALGOLIA_ID ?? "",
	import.meta.env.VITE_ALGOLIA_KEY ?? "",
);

const index = client.initIndex(import.meta.env.VITE_ALGOLIA_OFFER_INDEX ?? "");

type AlgoliaFiltersType = {
	page?: number;
	hitsPerPage: number;
	aroundLatLng?: string;
	aroundRadius?: number;
	filters?: string;
};

export function Jobboard() {
	const dispatchJobboard = useDispatch();

	const {
		currentInterface,
		structureView,
	}: { currentInterface: string; structureView: ViewType } = useOutletContext();
	const { setTitle }: { setTitle: Function } = useOutletContext();
	const { user }: GlobalContextType = useContext(GlobalContext);
	const { filters, tab, jobboardWasInitialized } = useAppSelector(
		(state) => state.jobboard,
	);

	const location = useLocation();

	const [displayOnBoarding, setDisplayOnBoarding] = useState<boolean>(false);

	useEffect(() => {
		if (user?.onboardingSteps) {
			const hasSeenOnboarding =
				user.onboardingSteps?.split("")[EOnboarding.JOBBOARD];
			if (!hasSeenOnboarding) return;
			setDisplayOnBoarding(Number.parseInt(hasSeenOnboarding) === 0);
		}
	}, []);

	const convertFiltersToAlgoliaFilters = () => {
		const algoliaFilters: AlgoliaFiltersType = {
			page: filters.page ? filters.page - 1 : 0,
			hitsPerPage: 10,
		};

		const subFilters: Array<string> = [];

		if (filters.position && filters.max_distance) {
			algoliaFilters.aroundLatLng = `${filters.position[1]}, ${filters.position[0]}`;
			algoliaFilters.aroundRadius = filters.max_distance * 1000;
		}

		if (filters.jobboard_list) {
			if (filters.jobboard_list.length > 0) {
				subFilters.push(
					`(${filters.jobboard_list
						.map((jobboard: string) => {
							return `jobboard:"${jobboard}"`;
						})
						.join(" OR ")})`,
				);
			}
		}
		if (filters.contract) {
			subFilters.push(`(contract:${filters.contract})`);
		}
		if (subFilters.length > 0) {
			algoliaFilters.filters = subFilters.join(" AND ");
		}
		return algoliaFilters;
	};

	const getSchoolOffers = async (
		filtersWithoutJobboardList: JobboardFiltersType,
	) => {
		await dispatchJobboard(
			fetchSchoolOffers(
				stringifyRequestFilters(filtersWithoutJobboardList),
			) as any,
		);
	};

	const getSchoolOffersCount = async (
		filtersWithoutJobboardList: JobboardFiltersType,
	) => {
		dispatchJobboard(
			fetchSchoolOffersCount(
				stringifyRequestFilters(filtersWithoutJobboardList),
			) as any,
		);
	};

	const getScrapedOffers = async () => {
		const params = {
			search: filters.search ?? "",
			algoliaFilters: convertFiltersToAlgoliaFilters(),
		};

		await dispatchJobboard(fetchScrapedOffers(params) as any);
	};

	const getScrapedOffersCount = async () => {
		const { nbHits } = await index.search(
			filters.search ?? "",
			convertFiltersToAlgoliaFilters(),
		);
		dispatchJobboard(setTotalScrapedOffersCount(nbHits.toString()));
	};

	const getJobboardData = async () => {
		if (!user) return;

		const schoolFilters = { ...filters };
		const jobboardFilters = { ...filters };

		schoolFilters.exists = { school: true };
		jobboardFilters.exists = { school: false };
		schoolFilters.school = user.school;
		jobboardFilters.school = null;

		const filtersWithoutJobboardList = { ...schoolFilters };
		delete filtersWithoutJobboardList.jobboard_list;
		if (tab === "schoolOffers") {
			getSchoolOffers(filtersWithoutJobboardList);
			getScrapedOffersCount();
		}
		if (tab === "jobboardsOffers") {
			getScrapedOffers();
			getSchoolOffersCount(filtersWithoutJobboardList);
		}
		if (!jobboardWasInitialized) {
			const applications: ApplicationType[] = await retrieveApplications(
				user.id,
			);
			dispatchJobboard(setUserApplications(applications));
		}
	};

	const { updatedFilters, updatedTab } = useMemo(() => {
		let updatedFilters = filters;
		let updatedTab = "";

		switch (location.pathname) {
			case "/school-jobboard":
			case "/jobboard":
				updatedFilters = {
					...filters,
					school: null,
					exists: { school: false },
					page: 1,
				};
				updatedTab = "jobboardsOffers";
				break;
			case "/school-extension":
			case "/extension":
				updatedTab = "jobboardAndExtension";
				break;
			default:
				updatedFilters = {
					...filters,
					school: user?.school,
					exists: { school: true },
					page: 1,
				};
				updatedTab = "schoolOffers";
				break;
		}

		return { updatedFilters, updatedTab };
	}, [location.pathname, filters, user?.school]);

	useEffect(() => {
		if (updatedTab !== tab) {
			dispatchJobboard(setFilters(updatedFilters));
			dispatchJobboard(setTab(updatedTab));
			setCookie(
				"JobboardFilters",
				JSON.stringify({ ...updatedFilters, tab: updatedTab }),
			);
		}
	}, [updatedTab, updatedFilters, dispatchJobboard]);

	useEffect(() => {
		setTitle("Jobboard");
		if (user?.school) {
			getJobboardData();
		}
	}, [filters, tab, structureView]);

	return (
		<main className="grid grid-cols-4 gap-md pb-5 sm:gap-6 md:grid-cols-8 lg:grid-cols-12 xxl:gap-8">
			<Head />
			<Body />
			{currentInterface === "student" && (
				<Onboarding
					currentPath={EOnboarding.JOBBOARD}
					show={displayOnBoarding}
					onClose={() => {
						setDisplayOnBoarding(false);
					}}
				/>
			)}
		</main>
	);
}
