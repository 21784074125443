import type { BackgroundColorType } from "@/types/colorsType";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "@assets/sass/ToggleButton.scss";
import "@assets/sass/ToggleButtonGroup.scss";
import { Icon } from "@iconify/react";

type ToggleButtonGroupType = {
	buttons: any;
	selectedIndex: number;
	backgroundColor?: BackgroundColorType | "none";
	className: string;
	disabled?: boolean;
};

type ToggleButtonType = {
	icon: any;
	className?: string;
	active: boolean;
	onChange?: Function;
	onClick: Function;
	label?: string | number | null;
};

export function ToggleButtonGroupLarge({
	buttons,
	selectedIndex = -1,
	className = "",
	backgroundColor = "bg-white",
	disabled = false,
}: ToggleButtonGroupType) {
	const [activeButton, setActiveButton] = useState<number>();

	const handleOnClick = (onClick: Function, index: number) => {
		if (disabled) return;
		setActiveButton(index);
		onClick();
	};

	useEffect(() => {
		setActiveButton(selectedIndex);
	}, [selectedIndex]);

	return (
		<div
			data-id="toggleButtonGroupLarge"
			className={`toggle-button-group-large ${backgroundColor} ${
				backgroundColor !== "none" && "shadow-lg"
			} ${className}`}
		>
			{buttons.map((btn: any, index: number) => (
				<ToggleButtonTertiary
					key={index}
					onClick={() => {
						if (buttons.length === 1 && index === activeButton) {
							handleOnClick(btn.onClick, -1);
						} else {
							handleOnClick(btn.onClick, index);
						}
					}}
					active={activeButton === index && !disabled}
					icon={btn.icon}
					label={btn.label}
				/>
			))}
		</div>
	);
}

ToggleButtonGroupLarge.propTypes = {
	buttons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	selectedIndex: PropTypes.number,
	backgroundColor: PropTypes.string,
	className: PropTypes.string,
};

function ToggleButtonTertiary({
	icon,
	className = "",
	active,
	onChange,
	onClick,
	label,
}: ToggleButtonType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<div
			data-id="toggleButonTertiary"
			className={`p-xsm w-10 h-10 rounded-md transition cursor-pointer flex items-center justify-center ${
				isActive
					? "bg-primary-100 shadow-primary700"
					: "hover:shadow-[inset_0px_0px_0px_3px_white] hover:bg-primary-50"
			} ${className}`}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				onClick();
				if (onChange) onChange(!isActive);
			}}
		>
			<Icon icon={icon} className="min-w-6 min-h-6" color="#2a4054" />
			{label && <p className="text-xsm font-bold text-primary-700P">{label}</p>}
		</div>
	);
}
