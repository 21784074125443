import React from "react";

import { ComboboxSearchAddress } from "@designSystem/molecules/ComboboxSearchAddress";
import { useDispatch } from "react-redux";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";

export function FindCompanyAddress() {
	const modifyCompanyToCreate =
		companiesManagementSlice.actions.modifyCompanyToCreate;
	const dispatchEvent = useDispatch();

	const handleAddressChange = (data: any) => {
		const city = data?.city?.shortName;
		const postalCode = data?.postalCode?.shortName;

		//If the street number is not found, we don't want to display it and remove the comma.
		//If the postal code is not found, we don't want to display it and remove the space.
		dispatchEvent(
			modifyCompanyToCreate({
				address: `${data?.streetNumber?.longName || ""} ${
					data?.streetName?.longName || ""
				}${data?.streetNumber?.longName || data?.streetName?.longName ? ", " : ""}${data?.postalCode?.shortName ? ` ${data?.postalCode?.shortName}` : ""}${data?.city?.shortName || ""}`,
				city,
				postalCode,
			}),
		);
	};
	return (
		<div>
			<ComboboxSearchAddress
				label={"Adresse postale"}
				placeholder="Adresse de l'entreprise"
				value=""
				className=""
				name="address"
				onChange={({
					latitude,
					longitude,
					streetNumber,
					streetName,
					city,
					postalCode,
					searchValue,
				}) =>
					handleAddressChange({
						latitude,
						longitude,
						streetNumber,
						streetName,
						city,
						postalCode,
						searchValue,
					})
				}
			/>
		</div>
	);
}
