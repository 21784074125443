import type { InfoItem, LinkItem } from "@/types/genericType";
import { Icon, type IconifyIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React from "react";
import { useOutletContext } from "react-router";

import { externalLinkFill } from "@assets/Icons";

import { checkUrl } from "@tools/Checkers";

import { Avatar } from "@designSystem/atoms/Avatar";
import { Badge } from "@designSystem/atoms/Badge";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";

type ButtonItem = {
	icon: IconifyIcon;
	text?: string;
	tooltip: string;
	onClick: Function;
	disabled?: boolean;
};

type OrganizationScrappingCardType = {
	illustration: string;
	color: string;
	logo: string;
	title: string;
	buttons: ButtonItem[];
	tag?: boolean;
	subtitle: string;
	infos: InfoItem[];
	links: LinkItem[];
	className: string;
	onClick: Function;
};

export function OrganizationScrappingCard({
	illustration,
	color,
	logo,
	title,
	buttons,
	tag,
	subtitle,
	infos,
	links,
	className,
	onClick,
}: OrganizationScrappingCardType) {
	const { currentInterface }: { currentInterface: string } = useOutletContext();

	return (
		<div
			className={`${className} rounded-lg relative shadow-sm outline-none overflow-hidden cursor-pointer  bg-white`}
		>
			{currentInterface === "student" && (
				<div className="buttons-container tr-corner absolute p-xxsm">
					{buttons?.map(
						({ onClick, disabled, icon, tooltip }: ButtonItem, index) => {
							return (
								<Tippy
									key={`button-tooltip-${index}`}
									content={tooltip}
									theme="primary"
									animation="fade"
									placement="top"
									zIndex={5}
								>
									<span>
										<ButtonTertiary
											onClick={() => {
												onClick();
											}}
											className="enabled ml-xxsm"
											icon={icon}
											disabled={disabled}
										/>
									</span>
								</Tippy>
							);
						},
					)}
				</div>
			)}

			<div
				onClick={() => {
					onClick();
				}}
			>
				<div
					className={`flex items-start justify-center h-[150px] overflow-hidden ${color}`}
				>
					<div className="tag-container">
						{tag && (
							<Badge
								label="Lien direct"
								className="m-xxsm"
								leftIcon={externalLinkFill}
							/>
						)}
					</div>
					<div className="h-[96%] w-full">
						{illustration ? (
							<img
								alt={title}
								className="h-full w-full object-cover"
								src={
									illustration.includes("http")
										? illustration
										: `${import.meta.env.VITE_S3}${illustration}`
								}
							/>
						) : (
							<p className="flex h-full w-full items-center justify-center text-center text-md text-white">
								{title}
							</p>
						)}
					</div>
				</div>
				<div className="p-sm">
					<div className="flex">
						<Avatar image={logo} firstname={title} lastname="" />
						<div className="ml-sm w-full p-0">
							<p className="w-10/12 truncate p-0 text-xsm font-bold text-primary-700P">
								{title}
							</p>
							<p className="text-xsm text-primary-300">{subtitle}</p>
						</div>
					</div>
					<div className="mt-md flex flex-wrap items-center justify-between">
						<div className="flex flex-col sm:mb-sm sm:w-full md:mb-0 md:w-6/12">
							{infos.map(({ icon, iconColor, text }: InfoItem, index) => {
								return (
									text && (
										<div
											className="flex items-center"
											key={`info-icon-${index}`}
										>
											<Icon icon={icon} className={`icon-Xs ${iconColor}`} />
											<p className="ml-xxsm w-10/12 text-xsm text-primary-500">
												{text}
											</p>
										</div>
									)
								);
							})}
						</div>
						<div className="br-corner flex justify-end sm:w-full md:w-5/12">
							{links.map(({ icon, link }, i) => {
								return (
									link && (
										<ButtonTertiary
											key={`link-icon-${i}`}
											iconColor="text-primary-700P"
											icon={icon}
											onClick={(event: any) => {
												event.stopPropagation();
												window.open(
													checkUrl(link) ? link : `https://${link}`,
													"_blank",
												);
											}}
											className={`${i > 0 ? "ml-sm" : ""}`}
										/>
									)
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
