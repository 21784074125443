import type { CityType } from "@/types/LocationTypes";
import { useAppSelector } from "@config/redux/hook";
import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fileTextOutline, refreshOutline, searchOutline } from "@assets/Icons";

import { CONTRACT_TYPE_OPTIONS, DISTANCE_OPTIONS } from "@constants/Offers";

import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { Input } from "@designSystem/molecules/Input";
import { SelectLocation } from "@designSystem/organisms/select/SelectLocation";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";

import { setFilters } from "@containers/student/Jobboard/core/store/jobboard.slice";

export function SearchBar() {
	const dispatchJobboard = useDispatch();
	const { filters } = useAppSelector((state) => state.jobboard);

	const [search, setSearch] = useState<string>(filters.search || "");
	const [contract, setContract] = useState<string | null>();
	const debouncedValue = useDebounce(search, 1000);
	const [maxDistance, setMaxDistance] = useState<number>(
		filters.max_distance || 50,
	);

	const [locationForFilter, setlocationForFilter] = useState<CityType | null>();

	const handleLocation = (cityReceived: CityType) => {
		setlocationForFilter(cityReceived);
		if (!cityReceived) {
			const filtersCopy = { ...filters };
			delete filtersCopy.location;
			delete filtersCopy.position;
			delete filtersCopy.max_distance;
			dispatchJobboard(setFilters(filtersCopy));
			return;
		}
		const newFilters = {
			...filters,
			location: cityReceived.location,
			max_distance: filters.max_distance ?? maxDistance,
		};

		if (cityReceived?.latitude && cityReceived?.longitude) {
			newFilters.position = [cityReceived.longitude, cityReceived.latitude];
		}

		dispatchJobboard(setFilters(newFilters));
	};

	const resetFilters = () => {
		dispatchJobboard(
			setFilters({
				tab: filters.tab,
				page: 1,
			}),
		);
	};

	useEffect(() => {
		dispatchJobboard(setFilters({ ...filters, search, page: 1 }));
	}, [debouncedValue]);

	useEffect(() => {
		setSearch(filters.search || "");
		setMaxDistance(maxDistance || 50);
		setContract(filters.contract || null);

		const newLocationForFilter = { location: "", position: [0, 0] };

		if (filters.location) newLocationForFilter.location = filters.location;
		if (filters.position) {
			newLocationForFilter.position = [
				filters.position[0],
				filters.position[1],
			];
		}

		setlocationForFilter(newLocationForFilter);
	}, [filters]);

	return (
		<div className="mt-sm flex w-full flex-col gap-sm rounded-lg bg-white p-sm md:flex-row">
			<div className="flex w-full gap-sm md:w-4/12">
				<Input
					placeholder="Poste recherché"
					icon={searchOutline}
					value={search}
					onChange={(e) => {
						e.target.value = e.target.value.replace(";", "");
						setSearch(e.target.value);
					}}
					className="w-full"
				/>
			</div>
			<div className="flex w-full flex-col gap-sm md:w-8/12 md:flex-row">
				<SelectTertiary
					placeholder="Contrat"
					options={CONTRACT_TYPE_OPTIONS}
					defaultValue={contract}
					icon={fileTextOutline}
					className="md:w-3/12"
					onChange={(e) => {
						dispatchJobboard(
							setFilters({ ...filters, contract: e.value, page: 1 }),
						);
					}}
				/>
				<SelectLocation
					withLabel={false}
					handleNewLocation={handleLocation}
					defaultValue={locationForFilter}
					className="md:w-6/12"
				/>
				<SelectTertiary
					placeholder="Distance"
					disabled={!filters.position}
					options={DISTANCE_OPTIONS}
					className=" md:w-3/12"
					onChange={(option) => {
						setMaxDistance(option.value);
						if (filters.position) {
							dispatchJobboard(
								setFilters({ ...filters, max_distance: option.value, page: 1 }),
							);
						}
					}}
				/>
			</div>
			<ButtonTertiary
				icon={refreshOutline}
				className="hidden md:flex"
				onClick={resetFilters}
				tooltip="Réinitialiser les filtres"
			/>
		</div>
	);
}
