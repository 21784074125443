import React, { useEffect, useState } from "react";

import { diffDate } from "@tools/Dates";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { FileInput } from "@designSystem/molecules/FileInput";
import { success } from "@tools/Toasts";

import {
	editUser,
	relaunchCVStudent,
	sendFile,
} from "@containers/school/Students/StudentDetail/StudentDetailAPI";

export function ToDeposit({ student, updateData }) {
	const [isLoading, setIsLoading] = useState(false);
	const importCV = async (file) => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append("file", file);
		formData.append("type", "cv");
		formData.append("state", "validated");
		formData.append("ownerId", student.id.split("/")[3]);

		const data = {
			id: student.id,
		};

		const response = await sendFile(formData);
		data.cv = response["@id"];

		await editUser(data);
		updateData(student.id);
	};

	useEffect(() => {
		setIsLoading(false);
	}, [student]);

	const handleOnRelaunchStudent = async () => {
		await relaunchCVStudent(student.id.split("/")[3]);
		success("Étudiant relancé avec succès. Il va recevoir un mail.");
		updateData(student.id);
	};

	return (
		<>
			<p className="text-primary-700P">
				L’étudiant n’a pas encore importé son CV.
			</p>
			<p className="mt-sm text-primary-700P">
				Si vous disposez de son CV, vous pouvez l’importer à sa place :
			</p>
			<FileInput
				isLoading={isLoading}
				title={
					<p className="text-xxsm text-primary-300">
						Déposez vos fichiers ou{" "}
						<span className="font-bold text-primary-700P">
							cliquez-ici pour les importer
						</span>
					</p>
				}
				subtitle={
					<p className="text-xxxsm text-primary-200">
						Taille max : 1Mo - Format : PDF
					</p>
				}
				acceptFormats={{ "application/pdf": [] }}
				maxSize={1}
				onSuccess={(e) => {
					importCV(e[0]);
				}}
			/>
			<p className="mt-md text-primary-700P">
				Si vous n’êtes pas en mesure de déposer son CV, vous pouvez relancer
				votre étudiant. En cliquant ci-dessous, il recevra une notification pour
				lui rappeler d’importer son CV.
			</p>
			<ButtonPrimary
				className="w-fit-content mt-sm"
				label="Relancer l'étudiant"
				disabled={diffDate(student.cvRelaunchDate) === 0}
				onClick={handleOnRelaunchStudent}
			/>
		</>
	);
}
