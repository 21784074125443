import { ShareLink } from "@containers/school/CVBoard/ui/CVsList/TableView/ShareCVModal/ShareLink";
import type { ShareSelectionModalType } from "@containers/school/ProfileSharing/ui/ShareSelectionEmailModal";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import React from "react";

export const ShareLinkModal = ({
	selection,
	show,
	onClose,
}: ShareSelectionModalType) => {
	return (
		<Modal
			title="Partage par lien"
			show={show}
			onClose={onClose}
			body={<ShareLink selection={selection} />}
			buttonsRight={[<ButtonPrimary onClick={onClose} label="Terminer" />]}
		/>
	);
};
