import type { UserCompleteType } from "@/types/userTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { createContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "./CVBoard.scss";

import { setCookie } from "@tools/Cookies";
import { resolveView } from "@tools/Users";

import { getCVs, getTabCounts } from "@containers/school/CVBoard/CVBoardAPI";

import { CVsList } from "./ui/CVsList";
import { Head } from "./ui/Head";

export type FiltersCvboardFiltersType = {
	tab: string;
	page: number;
	exists?: object;
};
export type structureViewType = { school?: string; campus?: string };
export type tabCountsType = {
	all: number | "...";
	validated: number | "...";
	toValidate: number | "...";
	editing: number | "...";
	without: number | "...";
};
export type CVBoardContextType = {
	load: boolean;
	setLoad: Function;
	canSwitch: boolean;
	setCanSwitch: Function;
	CVs: UserCompleteType[];
	setCVs: Function;
	structureView: structureViewType;
	tabCounts: tabCountsType;
	lastPage: number;
	totalCount: number;
	allCVsFilters: FiltersCvboardFiltersType | undefined;
};
export const CVBoardContext = createContext<CVBoardContextType>({
	load: true,
	setLoad: () => {},
	canSwitch: true,
	setCanSwitch: () => {},
	CVs: [],
	setCVs: () => {},
	structureView: {},
	tabCounts: {
		all: 0,
		validated: 0,
		toValidate: 0,
		editing: 0,
		without: 0,
	},
	lastPage: 1,
	totalCount: 0,
	allCVsFilters: undefined,
});

export function CVBoard() {
	const [load, setLoad] = useState<boolean>(true);
	const [CVs, setCVs] = useState<never[]>([]);
	const [canSwitch, setCanSwitch] = useState<boolean>(true);
	const [lastPage, setLastPage] = useState<number>(1);
	const [tabCounts, setTabCounts] = useState<tabCountsType>({
		all: "...",
		validated: "...",
		toValidate: "...",
		editing: "...",
		without: "...",
	});
	const [totalCount, setTotalCount] = useState(0);
	const {
		structureView,
		setTitle,
	}: { structureView: structureViewType; setTitle: Function } =
		useOutletContext();

	const [allCVsFilters, setAllCVsFilters] =
		useState<FiltersCvboardFiltersType>();

	const { currentSearch, currentFilters } = useAppSelector(
		(state) => state.usersFilters,
	);

	const resolveFilters = (currentFilters: FiltersType) => {
		switch (currentFilters.state) {
			case "validated":
				return { ...currentFilters, cv_state: "validated" };
			case "toValidate":
				return { ...currentFilters, cv_state: "toValidate" };
			case "editing":
				return { ...currentFilters, cv_state: "editing" };
			case "without":
				return { ...currentFilters, cvExists: { value: false } };
			default:
				return { ...currentFilters, cvExists: { value: true } };
		}
	};

	const constructFiltersCopy = (initialUseSearchValue: boolean) => {
		const filtersCopy = initialUseSearchValue
			? {
					search: currentSearch,
					state: currentFilters?.state,
					page: currentFilters?.page,
				}
			: currentSearch !== undefined &&
					currentSearch !== null &&
					currentSearch !== ""
				? {
						search: currentSearch,
						state: currentFilters?.state,
						page: currentFilters?.page,
					}
				: { ...currentFilters };

		return filtersCopy;
	};

	const manageStateFilters = (filtersCopy: Partial<FiltersType>) => {
		if ("state" in filtersCopy && filtersCopy.state === "all") {
			filtersCopy.state = undefined;
		}
		return filtersCopy;
	};

	const getData = async (initialUseSearchValue = false) => {
		let filtersCopy: Partial<FiltersType> = constructFiltersCopy(
			initialUseSearchValue,
		);
		filtersCopy = manageStateFilters(filtersCopy);

		setLoad(true);
		setCookie("cvboardFilters", JSON.stringify(filtersCopy));

		filtersCopy = resolveFilters(filtersCopy as FiltersType);
		if (filtersCopy.state) filtersCopy.state = undefined;
		if (filtersCopy.location) filtersCopy.location = undefined;
		setAllCVsFilters(filtersCopy as FiltersCvboardFiltersType);

		filtersCopy.cvExists =
			currentFilters?.state === "without" ? { value: false } : { value: true };
		const { data, responseLabel }: any = await getCVs(
			filtersCopy,
			resolveView(structureView, "spsPromotion"),
		);
		if (responseLabel === "success") {
			setCVs(data.collectionQuerySpsStudents?.collection);
			setTotalCount(data.collectionQuerySpsStudents?.paginationInfo.totalCount);
			setLastPage(data.collectionQuerySpsStudents?.paginationInfo.lastPage);
			setLoad(false);
		}
	};

	const getTabCountsData = async (useSearchValue = false) => {
		const filtersCopy: Partial<FiltersType> = useSearchValue
			? {
					search: currentSearch,
				}
			: { ...currentFilters };
		if (filtersCopy.state) filtersCopy.state = undefined;
		if (filtersCopy.location) filtersCopy.location = undefined;

		setTabCounts({
			all: "...",
			validated: "...",
			toValidate: "...",
			editing: "...",
			without: "...",
		});

		const { data, responseLabel } = await getTabCounts(
			filtersCopy,
			resolveView(structureView, "spsPromotion"),
		);
		if (responseLabel === "success") {
			const { all, validated, toValidate, editing, without }: any = data;

			setTabCounts({
				all: all?.paginationInfo.totalCount ?? "...",
				validated: validated?.paginationInfo.totalCount ?? "...",
				toValidate: toValidate?.paginationInfo.totalCount ?? "...",
				editing: editing?.paginationInfo.totalCount ?? "...",
				without: without?.paginationInfo.totalCount ?? "...",
			});
		}
	};
	useEffect(() => {
		setTitle("CVThèque");
		getData(
			currentSearch !== undefined &&
				currentSearch !== null &&
				currentSearch !== "",
		);
		getTabCountsData(
			currentSearch !== undefined &&
				currentSearch !== null &&
				currentSearch !== "",
		);
	}, [currentFilters, structureView]);

	useEffect(() => {
		if (
			currentSearch !== undefined &&
			currentSearch !== null &&
			currentSearch !== ""
		) {
			getData(true);
			getTabCountsData(true);
		}
	}, [currentSearch]);
	return (
		<CVBoardContext.Provider
			value={{
				CVs,
				setCVs,
				canSwitch,
				setCanSwitch,
				structureView,
				load,
				setLoad,
				tabCounts,
				lastPage,
				totalCount,
				allCVsFilters,
			}}
		>
			<main>
				<Head />
				<CVsList />
			</main>
		</CVBoardContext.Provider>
	);
}
