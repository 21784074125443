import { Formik, type FormikErrors } from "formik";
import React, { useEffect, useState } from "react";

import { getSubdomain } from "@tools/Auth";
import { checkEmail, checkPhone } from "@tools/Checkers";

import { FieldBuilder } from "./Components/FieldBuilder";
import { FormFooter } from "./Components/FormFooter";
import { FormLoader } from "./Components/FormLoader";
import { Header } from "./Components/Header";
import { HeaderLoader } from "./Components/HeaderLoader";
import { Info } from "./Components/Info";
import { SuccessRegistration } from "./Components/SuccessRegistration";
import { Why } from "./Components/Why";
import {
	getSchoolAndFormBySubdomain,
	getStepForSchool,
	sendRegistrationValuesToAPI,
	sendStepValuesToAPI,
} from "./RegisterFormAPI";

export type SubField = {
	value: string;
	label: string;
};

export type Field = {
	name: string;
	label: string;
	help?: string;
	type: string;
	isRequired: boolean;
	defaultValue?: string;
	subFields?: SubField[];
};

export type Step = {
	title: string;
	info?: string;
	why?: string;
	fields: Field[];
};

export type School = {
	subdomain: string;
	name: string;
	logo: string;
	illustrations: string;
};

interface schoolAndForm extends School {
	form: [];
	redirectUrl: string;
	nbSteps: number;
}

export const InscriptionForm = () => {
	const [formStep, setFormStep] = useState<number>(0);
	const [whyExpanded, setWhyExpanded] = useState(false);
	const [formValues, setFormValues] = useState<object>({});
	const [school, setSchool] = useState<School>();
	const [form, setForm] = useState<Step[]>([]);
	const [successFull, setSuccessFull] = useState(false);
	const [nbSteps, setNbSteps] = useState(0);
	const [errors, setErrors] = useState<object>({});
	const [registrationToken, setRegistrationToken] = useState<string>("");
	const [redirectUrl, setRedirectUrl] = useState<string>("");
	const retrieveSchoolAndForm = async (subdomain: string) => {
		await getSchoolAndFormBySubdomain(subdomain).then(
			({
				name,
				logo,
				illustrations,
				form,
				nbSteps,
				redirectUrl,
			}: schoolAndForm) => {
				if (name) {
					setSchool({
						subdomain,
						name,
						logo,
						illustrations: `${import.meta.env.VITE_S3}${illustrations}`,
					});
					setForm(form);
					setNbSteps(nbSteps);
					setRedirectUrl(redirectUrl);
				}
			},
		);
	};

	const saveForm = (values: object) => {
		// remove empty fields
		Object.keys(values).forEach((key) => {
			if (!values[key as keyof typeof values]) {
				delete values[key as keyof typeof values];
			}
		});
		if (Object.keys(validate(values)).length > 0) return;
		// get difference between formValues and values
		setFormValues({ ...formValues, ...values });
		setWhyExpanded(false);
		const diff = Object.keys(values).reduce((acc: object, key) => {
			let field;
			if (
				formValues[key as keyof typeof formValues] !==
					values[key as keyof typeof formValues] &&
				(field = form[formStep].fields.find((field) => field.name === key)) &&
				field?.type !== "file"
			) {
				return { ...acc, [key]: values[key as keyof typeof values] };
			}
			return acc;
		}, {});
		if (formStep === 0 && registrationToken.length === 0) {
			const domain = getSubdomain();
			if (domain) {
				sendRegistrationValuesToAPI(diff, domain).then((res) => {
					if (res) {
						setRegistrationToken(res.token);
					}
				});
			}
		} else if (Object.keys(diff).length > 0) {
			sendStepValuesToAPI(diff, registrationToken).then((res) => {
				if (res && formStep === nbSteps - 1) {
					setSuccessFull(true);
					setForm([]);
				} else {
					setFormStep(formStep + 1);
				}
			});
			return;
		}
		if (formStep !== nbSteps - 1) {
			setFormStep(formStep + 1);
		} else {
			setSuccessFull(true);
			setForm([]);
		}
	};

	const validate = (values: object) => {
		const errors: FormikErrors<any> = {};
		form[formStep].fields.forEach((field) => {
			if (field.isRequired && !values[field.name as keyof typeof values]) {
				errors[field.name as keyof typeof values] = "Ce champ est obligatoire";
			} else if (
				values[field.name as keyof typeof values] &&
				field.type === "email" &&
				!checkEmail(values[field.name as keyof typeof values] as string)
			) {
				errors[field.name as keyof typeof values] =
					"Le format de l'email est incorrect";
			} else if (
				values[field.name as keyof typeof values] &&
				field.type === "phone" &&
				!checkPhone(values[field.name as keyof typeof values])
			) {
				errors[field.name as keyof typeof values] =
					"Veuillez entrer un numéro de téléphone valide";
			}
		});
		setErrors(errors);
		return errors;
	};

	const goBack = () => {
		whyExpanded && setWhyExpanded(false);
		setErrors({});
		setFormStep(formStep - 1);
	};

	useEffect(() => {
		if (formStep + 1 === form.length && form.length < nbSteps) {
			const subdomain = getSubdomain();
			if (subdomain) {
				getStepForSchool(subdomain, formStep + 1).then((res) => {
					if (res) {
						setForm([...form, res]);
					}
				});
			}
		}
	}, [formStep]);

	useEffect(() => {
		const subdomain = getSubdomain();
		if (subdomain) {
			retrieveSchoolAndForm(subdomain);
		}
	}, []);
	return (
		<div className="flex flex-col">
			{school ? (
				<Header formStep={formStep} formTotalSteps={nbSteps} school={school} />
			) : (
				<HeaderLoader />
			)}
			<div className="flex flex-col flex-grow py-12">
				<div className="mx-auto w-full flex sm:max-w-lg md:max-w-2xl my-2 gap-4 bg-white">
					{form.length > formStep ? (
						<Formik
							initialValues={form[formStep].fields.reduce(
								(acc, field) => ({
									...acc,
									[field.name]: field.defaultValue || "",
								}),
								{},
							)}
							validate={validate}
							onSubmit={saveForm}
						>
							{({
								values,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
								touched,
							}) => (
								<form onSubmit={handleSubmit} className="w-full">
									<div className="flex flex-col">
										<div className="flex flex-col gap-4 p-8">
											<h2 className="text-2xl font-bold text-black">
												{form[formStep].title}
											</h2>
											{form[formStep].fields.find(
												(field) => field.isRequired,
											) && (
												<span className="text-end text-gray-600 text-xs before:content-['*'] before:text-red-600 before:mr-0.5">
													Champs obligatoires
												</span>
											)}
											{form[formStep].info && (
												<Info text={form[formStep].info as string} />
											)}
											{form[formStep].fields.map((field) => (
												<FieldBuilder
													field={field}
													formValues={formValues}
													setFormValues={setFormValues}
													handleChange={handleChange}
													handleBlur={handleBlur}
													setFieldValue={setFieldValue}
													values={values}
													touched={touched}
													errors={errors}
													registrationToken={registrationToken}
													key={`Field-${field.name}`}
												/>
											))}
											{form[formStep].why &&
												(form[formStep].why as string).trim().length > 0 && (
													<Why
														text={form[formStep].why as string}
														setWhyExpanded={setWhyExpanded}
														whyExpanded={whyExpanded}
													/>
												)}
										</div>
										<FormFooter
											formStep={formStep}
											goBack={goBack}
											formTotalSteps={nbSteps}
											errors={errors}
										/>
									</div>
								</form>
							)}
						</Formik>
					) : !successFull ? (
						<FormLoader />
					) : (
						<SuccessRegistration redirectUrl={redirectUrl} />
					)}
				</div>
			</div>
		</div>
	);
};
