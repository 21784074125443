import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import "@assets/sass/ToggleButton.scss";

type ToggleButtonType = {
	icon: any;
	className?: string;
	active: boolean;
	onChange?: Function;
	onClick: Function;
	label?: string | number | null;
};

type ToggleButtonQuaternaryType = ToggleButtonType & {
	activeIcon: any;
};

export function ToggleButtonFavorite({
	icon,
	className = "",
	active = false,
	activeIcon = null,
	onChange = () => {},
	onClick = () => {},
}: ToggleButtonQuaternaryType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<div
			data-id="toggleButtonFavorite"
			role="menu"
			className={`toggle-button-favorite-container ${
				isActive && "active"
			} ${className}`}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				onClick();
				onChange(!isActive);
			}}
		>
			{isActive ? (
				<Icon icon={activeIcon} className="toggle-button-favorite-active" />
			) : (
				<Icon icon={icon} className="toggle-button-favorite-icon" />
			)}
		</div>
	);
}
