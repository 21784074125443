import { Icon } from "@iconify/react";
import React from "react";
import Dropzone from "react-dropzone";

import {
	type sendFileResponse,
	uploadFile,
} from "@containers/public/InscriptionForm/RegisterFormAPI";
import { getCookie } from "@tools/Cookies";

export const DragDropFile = ({
	fieldName,
	prospectId,
	onSuccess,
}: {
	fieldName: string;
	prospectId: number;
	onSuccess: (res: sendFileResponse) => void;
}) => {
	return (
		<Dropzone
			onDrop={(acceptedFiles) =>
				uploadFile(
					acceptedFiles[0],
					fieldName,
					getCookie("token") as string,
					prospectId,
				).then((res) => {
					if (res.code === 200) {
						onSuccess(res);
					}
				})
			}
			maxFiles={1}
			maxSize={10000000}
			accept={{
				"application/pdf": [".pdf"],
			}}
		>
			{({ getRootProps, getInputProps, isDragActive }) => (
				<div
					className={`bg-gray-100 p-8 flex flex-col items-center rounded-xl cursor-pointer border-2 border-gray-100 ${
						isDragActive ? "border-dashed border-gray-500" : ""
					}`}
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					<p className={"text-center font-bold text-gray-500 text-sm"}>
						Déposez le fichier ici ou cliquez ici
					</p>
					<Icon icon="eva:cloud-upload-fill" className="text-4xl mt-4" />
				</div>
			)}
		</Dropzone>
	);
};
