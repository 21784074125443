import { useAppSelector } from "@config/redux/hook";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useOutletContext } from "react-router";

import { convertDateFromNow } from "@tools/Dates";

import { Avatar } from "@designSystem/atoms/Avatar";
import { TagsGroup } from "@designSystem/atoms/TagsGroup";

import { PostulateButton } from "../shared/PostulateButton";

import { Head } from "./Head";

export function Informations() {
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const {
		offer,
		showMinifyHead = false,
		isMobile = false,
	} = useAppSelector((state) => state.offerDetail);

	useEffect(() => {}, [offer]);

	if (!offer) {
		return (
			<div className="w-full">
				<Skeleton className="w-full" height="20px" />
				<Skeleton className="w-full" height="20px" />
				<Skeleton className="w-full" height="20px" />
			</div>
		);
	}
	const resolveTelework = (telework?: string) => {
		switch (telework) {
			case "full":
				return "Télétravail total";
			case "none":
				return "Pas de télétravail";
			case "half":
				return "Télétravail partiel";
			default:
				return "Non renseigné";
		}
	};

	const renderInformations = () => {
		return (
			<div className="flex flex-col gap-sm">
				<p className="text-sm font-bold text-primary-700P">Informations</p>
				<div className=" text-primary-700P">
					<p className="text-xxsm">Niveau de qualification requis</p>
					<p className="font-semibold">
						{offer?.qualification || "Non renseigné"}
					</p>
				</div>
				<div className=" text-primary-700P">
					<p className="text-xxsm text-primary-700P">Télétravail</p>

					<p className="font-semibold">{resolveTelework(offer?.telework)}</p>
				</div>
				{offer.tags && (
					<div>
						<p className="text-xxsm text-primary-700P">Compétences</p>
						<TagsGroup tags={offer.tags} />
					</div>
				)}
				{offer.responsable && (
					<div>
						<hr className="my-sm" />
						<p className="text-[12px] text-primary-300">
							Publiee {convertDateFromNow(offer.date)} par :{" "}
						</p>
						<div className="bg-primary-50 rounded-lg flex items-center p-xxsm gap-xxsm mt-xsm">
							<Avatar
								image={offer.responsable.avatar?.filePath}
								firstname={offer.responsable.firstname}
								lastname={offer.responsable.lastname}
								className="border-2 border-white shadow-sm"
							/>
							<div className="text-xxsm flex flex-col gap-xxsm">
								<p className=" font-bold">
									{offer.responsable.firstname} {offer.responsable.lastname}
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div
			className={`h-fit-content sticky top-0 flex w-full flex-col gap-md md:w-3/12 ${
				showMinifyHead ? "pt-lg" : ""
			} `}
		>
			{showMinifyHead && !isMobile ? (
				<>
					<Head position="reduce" />
					<div className="rounded-lg bg-white p-md">{renderInformations()}</div>
				</>
			) : (
				<div className="rounded-lg bg-white p-md">
					{renderInformations()}
					{isMobile && currentInterface === "student" && (
						<PostulateButton className="mt-md" />
					)}
				</div>
			)}
		</div>
	);
}
