import React from "react";
import { useDispatch } from "react-redux";

import { arrowIosForwardOutline } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";

import { setTab } from "@containers/student/Jobboard/core/store/jobboard.slice";

export function NoResults() {
	const dispatchJobboard = useDispatch();

	return (
		<div className="my-16 flex flex-col items-center">
			<p className="w-8/12 text-center text-primary-700P">
				Tu ne trouves pas de résultat ? Pas de panique, grâce à l'extension
				Mentor Goal tu vas pouvoir rechercher des offres sur tous les sites de
				recherches d'emploi.
			</p>
			<InlineButton
				id="jobboard-button"
				icon={arrowIosForwardOutline}
				label="Voir la liste des joboards"
				onClick={() => {
					dispatchJobboard(setTab("jobboardAndExtension"));
				}}
			/>
		</div>
	);
}
