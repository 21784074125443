import PropTypes from "prop-types";
import React from "react";

import "../StudentDetail.scss";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { TagsFinder } from "@designSystem/organisms/TagsFinder/TagsFinder";

export function Skills({
	editStudentTags,
	hardSkills,
	softSkills,
	tags,
	classname = "",
}) {
	return (
		<div className={`competence-container mt-md w-full ${classname}`}>
			<div>
				<h3 className="flex justify-start items-center gap-1">
					Compétences
					<TooltipElement id="student-skills" type="info" />
					<TooltipContainer
						anchorId="#student-skills"
						children="Visible par l'étudiant et votre école"
					/>
				</h3>
			</div>
			<section className="competence-top">
				<p className="title">
					Compétences techniques <span>- Hard Skills</span>
				</p>
			</section>
			<section>
				<TagsFinder
					placeholder="Rechercher..."
					defaultOptions={hardSkills}
					className=""
					options={tags
						?.filter((x) => x.type === "hardSkill")
						.sort((a, b) => a.name.localeCompare(b.name))}
					errorMessage="Aucun hard skill renseigné"
					onChange={(tags) => {
						editStudentTags("hardSkill", tags);
					}}
					onDelete={(tags) => {
						editStudentTags("hardSkill", tags);
					}}
				/>
			</section>
			<section className="competence-top">
				<p className="title">
					Compétences relationnelles <span>- Soft Skills</span>
				</p>
			</section>

			<section>
				<TagsFinder
					placeholder="Rechercher..."
					defaultOptions={softSkills}
					className=""
					options={tags
						?.filter((x) => x.type === "softSkill")
						.sort((a, b) => a.name.localeCompare(b.name))}
					errorMessage="Aucun soft skill renseigné"
					onChange={(tags) => {
						editStudentTags("softSkill", tags);
					}}
					onDelete={(tags) => {
						editStudentTags("softSkill", tags);
					}}
				/>
			</section>
		</div>
	);
}

Skills.propTypes = {
	editStudentTags: PropTypes.func.isRequired,
	softSkills: PropTypes.func.isRequired,
	hardSkills: PropTypes.func.isRequired,
	student: PropTypes.shape({
		id: PropTypes.string,
		school: PropTypes.shape({
			id: PropTypes.string,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf({}).isRequired,
};
