import type { NewFilterType, studentSelectedType } from "@/types/mailbox";
import type { UserType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";
import uuid from "react-uuid";

import empty from "@assets/illustrations/messaging/empty.svg";

import { Spinner } from "@designSystem/atoms/Spinner";
import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";
import { StudentsTargetingModal } from "@modules/studentsTargetingModal";
import { success } from "@tools/Toasts";

import {
	setCurrentTabSelected,
	setFetchInBackGround,
} from "../controller/mailBox.slice";

import { removeDuplicateSelectedStudent } from "@tools/Users";
import CreateGroupedMessagesModal from "./CreateGroupedMessagesModal";
import StudentCard from "./StudentCard";
import StudentsListLoader from "./StudentsListLoader";

type StudentsListType = {
	className: string;
	updateStudents: (filters?: NewFilterType) => void;
};
export default function StudentsList({
	className,
	updateStudents,
}: StudentsListType) {
	const dispatchMailBox = useDispatch();
	const listRef = useRef<HTMLDivElement>(null);

	const { structureView }: { structureView: object } = useOutletContext();
	const {
		studentsList,
		loading,
		currentTabSelected,
		totalUnsolvedConversationCount,
		currentPage,
		lastPage,
		fetchInBackGround,
		currentSearch,
	} = useAppSelector((state) => state.mailBox);

	const [displayTargetingModal, setDisplayTargetingModal] =
		useState<boolean>(false);
	const [displayCreateMail, setDisplayCreateMail] = useState<boolean>(false);
	const [currentTopPostion, setCurrentPostion] = useState<number>(0);
	const [studentSelectedMessageGrouped, setStudentSelectedMessageGrouped] =
		useState<UserType[]>([]);

	const sendMultipleMessage = async () => {
		// TODO: faire la requete message multiple
		success(`L'email a bien été envoyée !`);
	};

	useEffect(() => {
		if (listRef.current) {
			if (currentTopPostion) {
				listRef.current.scrollTo({
					top: currentTopPostion,
					left: 0,
					behavior: "instant",
				});
			}

			const handleScroll = () => {
				if (listRef.current) {
					const { scrollTop, scrollHeight, clientHeight } = listRef.current;

					const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
					if (isAtBottom) {
						if (!fetchInBackGround && currentPage < lastPage) {
							dispatchMailBox(setFetchInBackGround(true));
							updateStudents({
								name: "page",
								value: currentPage + 1,
							});
							setCurrentPostion(scrollTop);
						}
					}
				}
			};

			listRef.current.addEventListener("scroll", handleScroll);

			return () => {
				if (listRef.current) {
					listRef.current.removeEventListener("scroll", handleScroll);
				}
			};
		}
		return () => {};
	}, [loading, studentsList]);
	return (
		<div className={className}>
			<ScrollableTabGroup
				className="mb-md"
				isLoading={loading}
				tabs={[
					{
						label: "Toutes",
						active: currentTabSelected !== "unsolved",
						state: "all",
					},
					{
						label: `Non résolues (${
							loading || fetchInBackGround
								? "..."
								: totalUnsolvedConversationCount
						})`,
						active: currentTabSelected === "unsolved",
						state: "unsolved",
					},
				]}
				onTabChange={(i) => {
					switch (i) {
						case 0:
							dispatchMailBox(setCurrentTabSelected("all"));
							updateStudents({
								name: "spsConversation_status",
								value: null,
							});
							break;
						default:
							dispatchMailBox(setCurrentTabSelected("unsolved"));
							updateStudents({
								name: "spsConversation_status",
								value: "unsolved",
							});
							break;
					}
				}}
			/>
			{loading ? (
				<StudentsListLoader />
			) : (
				<div
					id="students-list"
					ref={listRef}
					onMouseLeave={() => {
						if (listRef.current) {
							listRef.current.scrollTop = listRef.current.scrollTop - 12;
						}
					}}
					className="flex h-full flex-col gap-sm overflow-y-auto pr-xsm max-h-[60vh] min-h-[59vh]"
				>
					{removeDuplicateSelectedStudent(studentsList)?.map(
						(student: studentSelectedType, i: number) => (
							<StudentCard
								key={`student-${uuid()}`}
								student={student}
								index={i}
							/>
						),
					)}

					{!fetchInBackGround &&
						!loading &&
						studentsList?.length === 0 &&
						currentSearch !== "" && (
							<div className="font-bold text-md flex flex-col items-center">
								<img src={empty} alt="empty_student" />
								<p className="py-sm text-center">
									Aucun(e) étudiant(e) trouvé(e)
								</p>
							</div>
						)}
					<div className="mt-sm text-center" key={0}>
						{studentsList &&
							studentsList?.length > 9 &&
							currentPage !== lastPage && <Spinner />}
					</div>
				</div>
			)}

			{/*
        // TODO: need back
        <ButtonSecondary
        className="w-full [&_span]:text-xsm [&_svg]:text-primary-700P"
        label="Message multiple"
        icon={peopleOutline}
        iconDirection="left"
        onClick={() => {
          setDisplayTargetingModal(true);
        }}
      /> */}
			<StudentsTargetingModal
				show={displayTargetingModal}
				onClose={() => {
					setDisplayTargetingModal(false);
				}}
				onClick={() => {
					setDisplayCreateMail(true);
				}}
				structureView={structureView}
				title="Message multiple"
				label="Vous pouvez sélectionner des étudiants à qui rédiger un message multiple."
				buttonLabel="Valider la sélection"
				share={(_, __, selectedStudents) => {
					setStudentSelectedMessageGrouped(selectedStudents);
				}}
			/>
			<CreateGroupedMessagesModal
				show={displayCreateMail}
				studentSelectedMessageGrouped={studentSelectedMessageGrouped}
				onClick={() => {
					sendMultipleMessage();
				}}
				onClose={(prev = false) => {
					if (prev) {
						setDisplayCreateMail(false);
						setDisplayTargetingModal(true);
					}
					setDisplayCreateMail(false);
				}}
			/>
		</div>
	);
}
