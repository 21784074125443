import React from "react";

import { plusFill, searchFill } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";

import { NewTagModal } from "./Head/NewTagModal";

type HeadProps = {
	filtersProps: {
		filters: any;
		setFilters: Function;
	};
	tabProps: {
		tab: string;
		setTab: Function;
	};
	displayNewTagProps: {
		displayNewTag: boolean;
		setDisplayNewTag: Function;
	};
	triggerGetData: Function;
};
export function Head({
	filtersProps,
	tabProps,
	displayNewTagProps,
	triggerGetData,
}: HeadProps) {
	const { displayNewTag, setDisplayNewTag } = displayNewTagProps;
	const { filters, setFilters } = filtersProps;
	return (
		<>
			<div className="flex w-full justify-between">
				<InputLargePrimary
					icon={searchFill}
					className="w-fit"
					placeholder="Rechercher..."
					onChange={(e) => {
						setFilters({
							...filters,
							name:
								e.target.value !== "" ? e.target.value.replace(" ", "%") : null,
						});
					}}
					value={filters.name ? filters.name : ""}
					type="text"
				/>
				<ButtonPrimary
					label="Créer un tag"
					icon={plusFill}
					onClick={() => {
						setDisplayNewTag(true);
					}}
				/>
			</div>

			<NewTagModal
				triggerGetData={triggerGetData}
				show={displayNewTag}
				filtersProps={filtersProps}
				tabProps={tabProps}
				onClose={() => {
					setDisplayNewTag(!displayNewTag);
				}}
			/>
		</>
	);
}
