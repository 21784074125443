import { InlineIcon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import Tippy from "@tippyjs/react";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import "../ApplicationDetails.scss";

import {
	editOutline,
	emailFill,
	infoOutline,
	linkedinFill,
	phoneFill,
	plusFill,
} from "@assets/Icons";

import { ModifyContactModal } from "@containers/student/Applications/ApplicationDetail/ui/Modals/Contact/ModifyContactModal";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Accordion } from "@designSystem/organisms/Accordion";

import { NewContactModal } from "./Modals/Contact/NewContactModal";

import "react-loading-skeleton/dist/skeleton.css";

export function Contacts({ contactsProps, applicationId, loading }) {
	const { user } = useContext(GlobalContext);
	const [displayNewContactModal, setDisplayNewContactModal] = useState(false);
	const [displayModifyContactModal, setDisplayModifyContactModal] =
		useState(false);

	const [contactToModify, setContactToModify] = useState({});

	const { contacts, setContacts } = contactsProps;

	const unfavoriteAllContacts = () => {
		const contactsCopy = [...contacts];
		contactsCopy.forEach((contact) => {
			contact.favorite = false;
		});
		return contactsCopy;
	};

	const _favoriteThisContact = (contactId) => {
		let contactsCopy = [...contacts];
		if (contacts.find((contact) => contact.id === contactId).favorite) {
			contactsCopy = unfavoriteAllContacts();
			setContacts(contactsCopy);
			return;
		}

		contactsCopy = unfavoriteAllContacts();

		const contactToFavorite = contactsCopy.find(
			(contact) => contact.id === contactId,
		);
		contactToFavorite.favorite = true;
		setContacts(contactsCopy);
	};

	const SkeletonLoader = () => {
		const array = [];
		array.length = 3;
		array.fill("");
		return array.map(() => (
			<div className="contact-loading mt-sm">
				<Skeleton className="ml-xxsm mr-sm" height={16} width={16} circle />
				<Skeleton className="ml-xxsm" height={44} width={44} circle />
				<div>
					<Skeleton className="ml-sm mt-sm" height={10} width={200} />
					<Skeleton className="ml-sm" height={10} width={140} />
				</div>
			</div>
		));
	};

	const sortContactsByFirstName = () => {
		const contactsCopy = [...contacts];
		contactsCopy.sort((a, b) => {
			if (a.firstname < b.firstname) return -1;
			if (a.firstname > b.firstname) return 1;
			return 0;
		});
		return contactsCopy;
	};

	useEffect(() => {}, [setContacts]);
	return (
		<div className="hidden w-full rounded-md bg-white px-sm py-md md:block">
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<p className="text-sm font-bold text-primary-700P">
						Contacts{" "}
						{contacts?.length >= 1 && (
							<span className="contact-number">({contacts?.length})</span>
						)}
					</p>
					<Tippy
						content="Avoir des contacts augmente tes chances de décrocher un entretien de 30%."
						theme="primary"
						animation="fade"
						placement="top"
						zIndex={5}
					>
						<span className="ml-xxsm cursor-pointer">
							<InlineIcon
								className=" h-4 text-primary-300"
								icon={infoOutline}
							/>
						</span>
					</Tippy>
				</div>
				<ButtonQuaternary
					icon={plusFill}
					onClick={() => {
						setDisplayNewContactModal(true);
					}}
				/>
			</div>
			<div id="accordion">
				{loading && SkeletonLoader()}

				{sortContactsByFirstName()?.map((contactElement, i) => {
					const items = [];
					if (contactElement.email) {
						items.push({
							icon: emailFill,
							label: contactElement.email,
							onClick: () => {
								window.location.href = `mailto:${contactElement.email}`;
							},
						});
					}
					if (contactElement?.phone?.length > 0 && contactElement.phone) {
						contactElement.phone.map((phone) => {
							if (phone) {
								return items.push({
									icon: phoneFill,
									label: phone,
									onClick: () => {
										window.location.href = `tel:${phone}`;
									},
								});
							}
							return null;
						});
					}
					if (contactElement.linkedin) {
						items.push({
							icon: linkedinFill,
							label: contactElement?.linkedin,
							onClick: () => {
								window.open(contactElement.linkedin, "_blank");
							},
						});
					}
					const hasInformations =
						contactElement.linkedin ||
						contactElement.email ||
						contactElement?.phone?.length > 0;
					return (
						<Accordion
							translate={false}
							title={`${contactElement.firstname} ${contactElement.lastname}`}
							subtitle={contactElement.job}
							button
							avatar
							avatarImage={contactElement.image}
							className="mt-sm text-left"
							items={items}
							deployed={i === 0}
							link={
								<InlineButton
									onClick={() => {
										setContactToModify(contactElement);
										setDisplayModifyContactModal(true);
									}}
									label={
										hasInformations
											? "Modifier le contact"
											: "Ajouter des informations"
									}
									icon={hasInformations ? editOutline : plusFill}
								/>
							}
						/>
					);
				})}
			</div>

			{contacts?.length === 0 && !loading && (
				<div className="mt-sm flex justify-center pb-sm pt-sm">
					<ButtonPrimary
						className="w-fit-content"
						label="Ajouter un contact"
						onClick={() => {
							setDisplayNewContactModal(true);
						}}
					/>
				</div>
			)}
			<NewContactModal
				userId={user?.id}
				show={displayNewContactModal}
				onClose={() => {
					setDisplayNewContactModal(false);
				}}
				contactsProps={{ contacts, setContacts }}
				applicationId={applicationId}
			/>
			<ModifyContactModal
				contact={contactToModify}
				show={displayModifyContactModal}
				onClose={() => {
					setDisplayModifyContactModal(false);
				}}
				contactsProps={{ contacts, setContacts }}
			/>
		</div>
	);
}
