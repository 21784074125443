import type { NoteType } from "@/types/notesType";
import React from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";

type DeleteNoteModalProps = {
	show: boolean;
	onClose: () => void;
	onDelete: (id: string) => void;
	note: NoteType;
};
export function DeleteNoteModal({
	show,
	onClose,
	onDelete,
	note,
}: DeleteNoteModalProps) {
	return (
		<div>
			<Modal
				show={show}
				onClose={() => {
					onClose();
				}}
				title="Supprimer la note"
				body={
					<p>
						Voulez-vous vraiment supprimer la note ? Cette action est
						irréversible.
					</p>
				}
				buttonsRight={[
					<ButtonSecondary
						key={0}
						onClick={() => {
							onClose();
						}}
						label="Non, annuler"
					/>,
					<ButtonPrimary
						key={1}
						onClick={() => {
							onDelete(note?.id.toString());
							onClose();
						}}
						label="Oui, supprimer"
					/>,
				]}
			/>
		</div>
	);
}
