import React, { createContext, useEffect, useRef, useState } from "react";
import type {
	MultiselectOptionsV2ContextType,
	MultiselectV2Type,
} from "./MultiselectV2Types";

export const MultiselectV2Context =
	createContext<MultiselectOptionsV2ContextType>({
		presenter: {
			isOpen: false,
			setIsOpen: () => {},
			name: "",
			options: [],
			selectedOptionsValues: [],
			setSelectedOptionsValues: () => {},
			hasSearchbar: false,
			searchbarPlaceholder: "",
			filteredOptions: [],
			label: "",
			tooltip: "",
			useFullWidth: false,
			handleSelectOption: () => {},
			handleOnSearch: () => {},
			multiselectV2Ref: { current: null },
			getWidthOptionsList: () => "200px",
		},
	});

/**
 * @description useMultiselectV2 is a custom hook that handles the logic of the MultiselectV2 component.
 * @param {MultiselectV2Type} props - The props of the MultiselectV2 component.
 * @returns {MultiselectOptionsV2ContextType} - The context of the MultiselectV2 component.
 */
export const useMultiselectV2 = ({
	name,
	options,
	preselectedOptionsValues = [],
	onSelectOption,
	hasSearchbar,
	searchbarPlaceholder,
	label,
	isRequired,
	tooltip,
	useFullWidth,
}: MultiselectV2Type) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOptionsValues, setSelectedOptionsValues] = useState<string[]>(
		preselectedOptionsValues,
	);
	const [filteredOptions, setFilteredOptions] = useState(options);
	const multiselectV2Ref = useRef<HTMLDivElement | null>(null);

	const handleSelectOption = (optionValue: string) => {
		let updatedOptionsValues: string[] = [];

		if (selectedOptionsValues.includes(optionValue)) {
			updatedOptionsValues = selectedOptionsValues.filter(
				(option) => option !== optionValue,
			);
		} else {
			updatedOptionsValues = [...selectedOptionsValues, optionValue];
		}

		setSelectedOptionsValues(updatedOptionsValues);
		onSelectOption(updatedOptionsValues);
	};

	const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const search = e.target.value;

		const updatedOptionsList = options.filter((option) =>
			option.label.toLowerCase().includes(search.toLowerCase()),
		);

		setFilteredOptions(updatedOptionsList);
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLDivElement>) => {
		if (
			multiselectV2Ref.current &&
			!multiselectV2Ref.current.contains(e.relatedTarget as Node)
		) {
			setIsOpen(false);
		}
	};

	const CHAR_WIDTH = 10;
	const MINIMAL_WIDTH = 250;
	const DEFAULT_WIDTH = 250;

	const getWidthOptionsList = () => {
		const maxOptionValueLength = Math.max(
			...options.map((option) => option.label.length),
		);
		const optionValueWidth = maxOptionValueLength * CHAR_WIDTH;
		if (optionValueWidth < MINIMAL_WIDTH) {
			return `${DEFAULT_WIDTH}px`;
		}
		return `${optionValueWidth > 320 ? 320 : optionValueWidth}px`;
	};

	useEffect(() => {
		setFilteredOptions(options);
	}, [options]);

	return {
		name,
		options,
		hasSearchbar,
		searchbarPlaceholder,
		MultiselectV2Context,
		handleSelectOption,
		isOpen,
		setIsOpen,
		filteredOptions,
		selectedOptionsValues,
		setSelectedOptionsValues,
		label,
		isRequired,
		tooltip,
		multiselectV2Ref,
		useFullWidth,
		handleOnBlur,
		handleOnSearch,
		getWidthOptionsList,
	};
};
