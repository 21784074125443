import type { CampusType, CampusesCollectionType } from "@/types/campusTypes";

import { useAppSelector } from "@config/redux/hook";
import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import { Spinner } from "@designSystem/atoms/Spinner";
import { Select } from "@designSystem/organisms/select/Select";
import { errorToast } from "@tools/Toasts";

import type { SchoolOfferType } from "@/types/offerTypes";
import { type AppState, useAppDispatch } from "@config/redux/store";
import { extractCampusList } from "@tools/Campuses";
import {
	type OfferManagementLoadingStatesType,
	modifyOfferToCreate,
	modifyOfferToEdit,
	setOfferToEditDataWasModified,
} from "../../core/store/offersManagement.slice";
import {
	fetchCampuses,
	fetchModifyOfferCampus,
} from "../../core/store/offersManagement.thunks";

type CampusModifierProps = {
	useAutosave?: boolean;
};
function CampusModifier({ useAutosave }: CampusModifierProps) {
	const dispatchEvent = useAppDispatch();
	const { user }: GlobalContextType = useContext(GlobalContext);

	const {
		offerToCreate,
		offerToEdit,
		offerToEditDataWasModified,
		loadingStates,
		campusesListReceived,
	}: {
		offerToCreate: Partial<SchoolOfferType>;
		offerToEdit?: SchoolOfferType;
		offerToEditDataWasModified?: boolean;
		loadingStates: OfferManagementLoadingStatesType;
		campusesListReceived: CampusType[];
	} = useAppSelector((state: AppState) => state.offers);
	const [campusesOptions, setCampusesOptions] = useState<CampusChangeType[]>(
		[],
	);
	const [campusSelectedIds, setCampusSelectedIds] = useState<string[]>(
		campusesOptions
			.filter((campusOption) => campusOption.checked)
			.map((c) => c.value),
	);
	type CampusChangeType = {
		checked: boolean;
		label: string;
		type: string;
		value: string;
	};

	const isCurrentlySelected = (campusId: string | undefined): boolean => {
		let thisCampusIsSelected = false;
		if (typeof campusId !== "string") return false;

		const rawCampuses: CampusesCollectionType[] = [
			offerToEdit?.campuses as CampusesCollectionType,
			offerToCreate?.campuses as CampusesCollectionType,
		];
		const extractedCampusList = useAutosave
			? extractCampusList(rawCampuses[0])
			: extractCampusList(
					rawCampuses[user?.roles.includes("ROLE_SCHOOL") ? 1 : 0],
				);
		if (extractedCampusList?.length === 0) {
			return false;
		}

		const occurencesOfThisCampus =
			extractedCampusList?.filter((campusToCheck: string) => {
				return campusToCheck === campusId;
			}) || [];

		if (occurencesOfThisCampus?.length > 0) thisCampusIsSelected = true;
		return thisCampusIsSelected;
	};
	const addUserCampusToTheList = async (campus: CampusType | undefined) => {
		dispatchEvent(
			modifyOfferToCreate({
				campuses: [campus?.id],
			}),
		);
	};

	const isTheCampusOfTheUser = (campus: CampusType): boolean => {
		if (!offerToCreate?.campuses && user?.roles.includes("ROLE_SCHOOL")) {
			const isTheCampusOfTheUser = extractCampusList(
				user?.campuses || [],
			).includes(campus.id);
			if (isTheCampusOfTheUser) addUserCampusToTheList(campus);
			return isTheCampusOfTheUser;
		}
		if (!offerToCreate?.campuses && user?.campuses) {
			if (extractCampusList(user.campuses).includes(campus.id)) {
				addUserCampusToTheList(campus);
				return true;
			}
		}
		return false;
	};

	const getCampusOptions = () => {
		if (useAutosave && !offerToEdit?.campuses) {
			return;
		}
		if (!campusesListReceived) return;
		const campusesForCheck = offerToCreate?.campuses as unknown as Array<any>;

		setCampusesOptions(
			campusesListReceived.map((campusElement: CampusType) => {
				let isChecked = false;
				if (useAutosave) {
					if (!offerToEdit?.campuses) {
						isChecked = isTheCampusOfTheUser(campusElement);
					} else {
						isChecked = isCurrentlySelected(campusElement?.id);
					}
				} else {
					if (
						!offerToCreate?.campuses ||
						(campusesForCheck?.length === 2 &&
							campusesForCheck?.[1].length === 1)
					) {
						isChecked = isTheCampusOfTheUser(campusElement);
					} else {
						isChecked = isCurrentlySelected(campusElement?.id);
					}
				}

				const campusChange = {
					label: campusElement.name,
					value: campusElement.id,
					checked: isChecked,
					type: "checkbox",
				} satisfies CampusChangeType;

				if (!offerToEdit?.campuses && isChecked) {
					handleChangeCampus([campusChange]);
				}
				return campusChange;
			}),
		);
	};

	const updateCampusOptions = () => {
		setCampusesOptions(
			campusesOptions.map((campusElement) => {
				return {
					...campusElement,
					checked:
						campusSelectedIds?.includes(campusElement?.value as string) ||
						false,
				};
			}),
		);
	};
	useEffect(() => {
		updateCampusOptions();
	}, [campusSelectedIds]);

	const handleChangeCampus = (campuses: CampusChangeType[]) => {
		const selectedIdsOnChange = campuses.map((c) => c.value);
		if (useAutosave && offerToEdit?.id) {
			if (selectedIdsOnChange.length === 0) {
				errorToast("Sauvegarde impossible : aucun campus sélectionné.");
			} else {
				dispatchEvent(
					fetchModifyOfferCampus({
						id: offerToEdit.id,
						campus: selectedIdsOnChange,
					}),
				);
			}
			dispatchEvent(
				modifyOfferToEdit({ campuses: { collection: selectedIdsOnChange } }),
			);
		} else if (!useAutosave) {
			dispatchEvent(
				modifyOfferToCreate({ campuses: { collection: selectedIdsOnChange } }),
			);
		}
		setCampusSelectedIds(selectedIdsOnChange);
	};

	useEffect(() => {
		if (campusesListReceived) {
			if (user?.roles.includes("ROLE_CAMPUS_USER")) {
				dispatchEvent(
					useAutosave
						? modifyOfferToEdit({ campuses: user?.campuses })
						: modifyOfferToCreate({ campuses: user?.campuses }),
				);
			} else {
				getCampusOptions();
			}
		}
	}, [campusesListReceived]);

	useEffect(() => {
		// event used to update the campus when previous or next button is clicked
		if (offerToEditDataWasModified) {
			if (user?.roles.includes("ROLE_CAMPUS_USER")) {
				dispatchEvent(
					useAutosave
						? modifyOfferToEdit({ campuses: user?.campuses })
						: modifyOfferToCreate({ campuses: user?.campuses }),
				);
			} else {
				getCampusOptions();
			}
			dispatchEvent(setOfferToEditDataWasModified(false));
		}
	}, [offerToEditDataWasModified]);

	useEffect(() => {
		dispatchEvent(fetchCampuses({ schoolId: `${user?.school}` || "" }) as any);
	}, []);

	// Dont move it because : its a conditional rendering
	if (!user || user?.roles?.includes("ROLE_CAMPUS_USER")) {
		return null;
	}

	if (loadingStates.isFetchingCampuses) {
		return <Spinner size="small" />;
	}

	return (
		<Select
			label="Campus*"
			position="right"
			placeholder="Campus"
			inputs={campusesOptions}
			multiselect
			onChange={(e: CampusChangeType[]) => {
				handleChangeCampus(e);
			}}
			searchbarPlaceholder="Rechercher un campus"
			className="wg-no-translate"
			tooltip="L’offre sera accessible aux référents des campus sélectionnés."
		/>
	);
}

export default CampusModifier;
