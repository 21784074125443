import { useAppSelector } from "@config/redux/hook";
/**
 * @packageDocumentation
 * @module school/Offers_v3
 * Application stats must be fetched from the SPS_APPLICATION table using the offerId
 * Fetching stats using async loading
 */
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
	OFFER_DETAILS_TYPE_TOOLTIP,
	OFFER_TYPE_LABEL_FOR_DETAILS,
} from "@constants/Offers";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Stats } from "@designSystem/molecules/Stats";
import { Modal } from "@designSystem/organisms/modal/Modal";

import {
	setCurrentDetailTabSelected,
	setSendRelaunchInvitationWasClicked,
} from "../../core/store/offersManagement.slice";
import { SharedToModal } from "../modal/SharedToModal";

export function OfferDetailsStatsRow() {
	const dispatchEvent = useDispatch();
	const { offerToEdit } = useAppSelector((state) => state.offers);
	const [showSharedToModal, setShowSharedToModal] = useState<boolean>(false);

	const handleCloseShowSharedToModal = (element: any) => {
		if (element) {
			element.stopPropagation();
			element.preventDefault();
		}
		setShowSharedToModal(false);
	};

	const handleTargetOtherStudents = () => {
		setShowSharedToModal(false);
		dispatchEvent(setSendRelaunchInvitationWasClicked(true));
	};

	const switchTab = (tab: string) => {
		dispatchEvent(setCurrentDetailTabSelected(tab));
	};

	return (
		<section data-testid="statsRow" className="flex gap-sm">
			<Stats
				label="Type d'offre"
				color="text-accent-1-dark"
				className="h-full min-w-fit flex-1 whitespace-nowrap"
				value={
					offerToEdit &&
					OFFER_TYPE_LABEL_FOR_DETAILS.get(offerToEdit?.type)?.label
				}
				subtitle={
					offerToEdit &&
					OFFER_TYPE_LABEL_FOR_DETAILS.get(offerToEdit?.type)?.subLabel
				}
				tooltip={
					offerToEdit && OFFER_DETAILS_TYPE_TOOLTIP.get(offerToEdit.type)
				}
				tooltipId="offerTypeTooltip"
			/>
			<div className="w-auto flex-1">
				<Stats
					className="h-full min-w-fit flex-1 whitespace-nowrap"
					label="Etudiants invités"
					color="text-accent-1-dark"
					value={offerToEdit?.sharesCount}
				/>
				<Modal
					title="Vous avez partagé cette offre à :"
					body={<SharedToModal />}
					show={showSharedToModal}
					onClose={(e: any) => handleCloseShowSharedToModal(e)}
					buttonsRight={[
						<ButtonSecondary
							label="Inviter d'autres étudiants"
							onClick={() => {
								handleTargetOtherStudents();
							}}
						/>,
						<ButtonPrimary
							label="Fermer"
							onClick={(e) => handleCloseShowSharedToModal(e)}
						/>,
					]}
				/>
			</div>
			<div className="w-auto flex-1">
				<Stats
					className="h-full min-w-fit flex-1 whitespace-nowrap"
					label="Vues étudiants"
					color="text-accent-1-dark"
					value={offerToEdit?.views}
				/>
			</div>
			<div
				className="w-auto flex-1 cursor-pointer"
				onClick={() => switchTab("applications")}
			>
				<Stats
					className="h-full min-w-fit flex-1 whitespace-nowrap"
					label="Candidatures"
					color="text-accent-1-dark"
					value={offerToEdit?.applicationsCount}
				/>
			</div>
			{offerToEdit && offerToEdit?.type === "preselection" && (
				<div
					className="w-auto flex-1 cursor-pointer"
					onClick={() => switchTab("sendToCompany")}
				>
					<Stats
						className="h-full min-w-fit flex-1 whitespace-nowrap"
						label="Envoyées à l'entreprise"
						color="text-accent-1-dark"
						value={
							offerToEdit?.selection?.selectionUsers?.collection?.length || "0"
						}
					/>
				</div>
			)}
			{offerToEdit && offerToEdit?.type === "direct" && (
				<div className="w-auto flex-1 cursor-pointer">
					<Stats
						className="h-full min-w-fit flex-1 whitespace-nowrap"
						label="Entretiens"
						color="text-accent-1-dark"
						value={offerToEdit && 0}
					/>
				</div>
			)}
		</section>
	);
}
