import { useAppSelector } from "@config/redux/hook";
import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { searchFill } from "@assets/Icons";

import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";

import type { AppState } from "@config/redux/store";
import {
	setCurrentSearch,
	setUseFilters,
} from "@modules/offersFilters/offersFiltersSlice";

export function OffersfiltersSearchbar() {
	const dispatchEvent = useDispatch();

	const { currentSearch }: { currentSearch: string } = useAppSelector(
		(state: AppState) => state.offersFilters,
	);

	const [search, setSearch] = useState<string>(currentSearch ?? "");
	const debouncedValue = useDebounce(search);

	useEffect(() => {
		dispatchEvent(setCurrentSearch(debouncedValue));
		dispatchEvent(setUseFilters(debouncedValue.length === 0));
	}, [debouncedValue]);

	useEffect(() => {
		setSearch(currentSearch ?? "");
	}, [currentSearch]);

	return (
		<InputLargePrimary
			icon={searchFill}
			placeholder="Rechercher..."
			onChange={(e) => {
				const cleanValue = e.target.value.replace(";", "");
				e.target.value = cleanValue;
				setSearch(cleanValue);
			}}
			value={currentSearch ? currentSearch.replace("%", " ") : ""}
			type="text"
			className="!w-max"
			containerClassName={"max-w-fit"}
		/>
	);
}
