import type { EventType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useNavigate } from "react-router-dom";

import { arrowBackFill } from "@assets/Icons";

import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";

import { EventCloudTags } from "./creation/EventCloudTags";
import { EventCreationHelp } from "./creation/EventCreationHelp";
import { EventCreationSubmit } from "./creation/EventCreationSubmit";
import { EventDate } from "./creation/EventDate";
import { EventImageSelection } from "./creation/EventImageSelection";
import { EventInformations } from "./creation/EventInformations";
import { EventLocation } from "./creation/EventLocation";

export function EventsSchoolCreation() {
	const targetEvent = useAppSelector((state) => state.events.targetEvent);

	return (
		<main className="grid grid-cols-4 gap-md pb-5 sm:gap-6 md:grid-cols-8 lg:grid-cols-12 xxl:gap-8">
			{renderHeader(targetEvent)}
			<section className="col-start-1 col-end-13 flex grid-flow-row flex-col-reverse gap-6 sm:flex-row">
				<article className="flex grow basis-2/3 flex-col items-start justify-center gap-6 rounded-lg bg-white p-10">
					<EventCloudTags />
					<EventInformations />
					<EventImageSelection />
					<EventCreationSubmit />
				</article>
				<article className="flex grow basis-1/3 flex-col items-start gap-6">
					<EventDate />
					<EventLocation />
					<EventCreationHelp />
				</article>
			</section>
		</main>
	);
}

const renderHeader = (targetEvent: EventType | undefined) => {
	const navigate = useNavigate();
	return (
		<section className="col-span-4 mt-4 flex min-h-[72px] items-center justify-start gap-4 rounded-lg bg-white pl-2 md:col-span-8 lg:col-span-12">
			<ButtonQuinary
				icon={arrowBackFill}
				onClick={() => {
					navigate("/events");
				}}
			/>
			<span
				className="text-sm font-bold text-primary-700P"
				data-testid="eventCreationPageTitle"
			>
				{targetEvent ? `Modifier ${targetEvent.title}` : "Créer un évènement"}
			</span>
		</section>
	);
};
