import type { MediaObjectType } from "@/types/mediaObjectTypes";
import React, { useState } from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { editMedia } from "@containers/school/CVBoard/CVBoardAPI";
type RefusalModalType = {
	className: string;
	show: boolean;
	onClose: Function;
	onSend: Function;
	cv?: MediaObjectType;
};
export function RefusalModal({
	className = "",
	show,
	onClose,
	onSend,
	cv,
}: RefusalModalType) {
	const [refusalMotive, setRefusalMotive] = useState<string>("");

	const handleClose = () => {
		setRefusalMotive("");
		onClose();
	};

	const refuseCV = async () => {
		await editMedia({
			id: cv?.id,
			state: "editing",
			refusalMotive,
			action: "refuse",
		});
		setRefusalMotive("");
		onSend();
	};

	return (
		<Modal
			className={className}
			show={show}
			onClose={handleClose}
			title="CV non validé"
			body={
				<div>
					<p className="text-xsm text-primary-700P">
						En poursuivant, vous enverrez une notification à l’étudiant pour
						l’avertir qu’il doit corriger son CV.
					</p>
					<p className="mt-md text-xsm text-primary-700P">
						Vous pouvez également lui laisser un commentaire pour l’aider à
						s’améliorer.
					</p>
					<TextArea
						height={310}
						className="mt-md"
						label="Commentaire (optionnel)"
						placeholder="Rédiger ici..."
						value={refusalMotive}
						onChange={(e) => {
							setRefusalMotive(e);
						}}
					/>
				</div>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					onClick={() => {
						handleClose();
					}}
					label="Annuler"
				/>,
				<ButtonPrimary
					key={1}
					onClick={() => {
						refuseCV();
					}}
					label="Envoyer"
				/>,
			]}
		/>
	);
}
