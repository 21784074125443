import type { EventType } from "@/types/eventsTypes";
import type { UserType } from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

import { toast } from "react-toastify";

import {
	checkEventAccessiblity,
	findStudentInvitation,
	getAccessButtonLabel,
	isMentorGoalEvent,
} from "@tools/Events";
import { extractIdNumberFromId } from "@tools/Users";

import { ButtonOpenAddress } from "@designSystem/atoms/ButtonOpenAddress";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

import {
	subscribeToEvent,
	updateHasParticipatedState,
} from "@containers/shared/Events/core/api/events.request";

import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";
import { EventSubscriptionRow } from "../EventSubscriptionRow";

type OptionInscriptionProps = {
	event: EventType;
};
export function OptionInscription({ event }: OptionInscriptionProps) {
	const dispatchEvent = useAppDispatch();
	const { user }: { user: Partial<UserType> | undefined } =
		useContext(GlobalContext);

	const handleOpenEventLink = () => {
		window.open(
			decodeURIComponent(event.eventLink || ""),
			"_blank",
			"noopener noreferrer",
		);
		toast.success("Lien ouvert dans un nouvel onglet");
	};
	const handleParticipation = async () => {
		if (!event || !event.type || !user?.id || typeof user.id !== "string") {
			return;
		}
		const invitationFound = findStudentInvitation(event, user.id);
		if (invitationFound) {
			await updateHasParticipatedState(
				invitationFound.id,
				isMentorGoalEvent(event.type),
			);
		} else {
			await subscribeToEvent({
				eventId: extractIdNumberFromId(event.id.toString()),
				subscriptionReply: "registered",
				hasParticipated: true,
				isMentorgoalType: isMentorGoalEvent(event.type),
			});
		}
		dispatchEvent(eventsSlice.actions.setPerformFetchEvents(true));
	};

	return (
		<div className="mt-2 flex flex-col items-center justify-center gap-4">
			{event.address &&
				(event.location === "Sur site" || event.location === "Hybride") && (
					<ButtonOpenAddress address={event.address || ""} />
				)}
			{event?.location &&
				(event.location === "Distanciel" || event.location === "Hybride") && (
					<ButtonPrimary
						label={getAccessButtonLabel(event)}
						onClick={() => {
							handleOpenEventLink();
							handleParticipation();
						}}
						className="w-full"
						disabled={!checkEventAccessiblity(event)}
					/>
				)}
			<EventSubscriptionRow eventTarget={event} />
		</div>
	);
}
