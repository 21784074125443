import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import { Formik } from "formik";
import React, { useContext, useState } from "react";

import { COLORS_TAG_OPTIONS, TAG_TYPES_OPTIONS } from "@constants/Tags";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Tag } from "@designSystem/atoms/Tag";
import { TextArea } from "@designSystem/atoms/TextArea";
import { ColorChoice } from "@designSystem/molecules/ColorChoice";
import { Input } from "@designSystem/molecules/Input";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";
import { errorToast, success } from "@tools/Toasts";

import { createTag } from "@containers/school/TagsManagement/TagsAPI";

type NewTagModalType = {
	show: boolean;
	onClose: Function;
	tabProps: { tab: string; setTab: Function };
	filtersProps: { filters: any; setFilters: Function };
	triggerGetData: Function;
};

type DataTag = {
	type: string;
	name: string;
	color: string;
	description: string;
};
export function NewTagModal({
	show,
	onClose,
	tabProps,
	triggerGetData,
}: NewTagModalType) {
	const { user } = useContext<GlobalContextType>(GlobalContext);
	const { setTab } = tabProps;

	const [tagType, setTagType] = useState<string>("school");

	const newTag = async (data: DataTag) => {
		const creationResponse = await createTag({
			school: user?.school,
			...data,
		});

		if (creationResponse.responseLabel === "success") {
			success("Tag créé avec succès.");
		} else {
			errorToast("Erreur lors de la création du tag.");
		}
		const tabSelected = tagType === "school" ? "generalTags" : "skillsTags";
		setTab(tabSelected);
		triggerGetData(
			tabSelected === "generalTags" ? ["school"] : ["hardSkill", "softSkill"],
		);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				type: "school",
				name: "",
				color: "bg-emerald-light",
				description: "",
			}}
			validate={(values) => {
				const errors: any = {};
				if (!values.name) {
					errors.name = "Veuillez remplir un titre";
				}
				if (values.name.length > 60) {
					errors.name = "Veuillez avoir un titre inférieur à 60 caractères";
				}
				return errors;
			}}
			onSubmit={(values, { resetForm }) => {
				newTag(values);
				resetForm({});
				onClose();
			}}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				resetForm,
				setFieldValue,
			}) => (
				<Modal
					title="Créer un tag"
					show={show}
					onClose={() => {
						onClose();
						resetForm();
					}}
					body={
						<div>
							<SelectTertiary
								label="Type de tag"
								placeholder="Choisir..."
								options={TAG_TYPES_OPTIONS}
								className="mb-md"
								position="right"
								defaultValue={{ label: values.name, value: values.type }}
								onChange={(e) => {
									setTagType(e.value);
									setFieldValue("type", e.value);
								}}
							/>
							<Input
								label="Nom du tag"
								placeholder="Ex: 1. Info : Zone géographique limitée"
								className="mb-md"
								value={values.name}
								onChange={(e) => {
									setFieldValue("name", e.target.value);
								}}
								errorMessage={
									errors.name && touched.name && errors.name ? errors.name : ""
								}
							/>
							<p className="font-semifont-bold mb-xsm text-xsm text-primary-700P">
								Couleur
							</p>
							<p className="mb-xsm text-xsm text-primary-300">
								Vous pouvez attribuer une couleur à ce tag :
							</p>
							<ColorChoice
								defaultColor={values.color}
								optionsColor={COLORS_TAG_OPTIONS}
								onChange={(e) => {
									setFieldValue("color", e.value);
								}}
							/>
							<p className="font-semifont-bold mb-sm text-xsm text-primary-700P">
								Aperçu
							</p>
							<Tag label={values.name} bgColor={values.color} />
							<TextArea
								className="mt-md"
								label="Description du tag (optionnel)"
								placeholder="Rédigez votre description ici..."
								value={values.description}
								onBlur={(e) => {
									setFieldValue("description", e.target.value);
								}}
							/>
						</div>
					}
					buttonsRight={[
						<ButtonSecondary
							key={0}
							label="Annuler"
							onClick={() => {
								resetForm();
								onClose();
							}}
						/>,
						<ButtonPrimary
							key={1}
							label="Créer"
							onClick={() => {
								handleSubmit();
							}}
						/>,
					]}
				/>
			)}
		</Formik>
	);
}
