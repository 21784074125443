import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import "./Coaching.scss";

import { arrowForwardFill } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";

export function Coaching() {
	const { setTitle }: any = useOutletContext();

	useEffect(() => {
		setTitle("Coaching");
	}, []);

	return (
		<main className="help-container">
			<iframe
				className="embed-notion -mt-5 max-h-[81vh]"
				title="notion"
				src="https://v2-embednotion.com/Live-Coaching-1dc7e36192744ebb8c5f705ea09497ff"
			/>
			<InlineButton
				id="help-button"
				className="more flex items-center"
				icon={arrowForwardFill}
				label="Cliquez ici si cette page ne s’affiche pas"
				onClick={() => {
					window.open(
						"https://mentorgoal.notion.site/Ateliers-Coaching-1dc7e36192744ebb8c5f705ea09497ff",
						"_blank",
					);
				}}
			/>
		</main>
	);
}
