import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import {
	calendarOutline,
	pricetagsOutline,
	trash2Outline,
} from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { CheckboxLabelPrimary } from "@designSystem/molecules/CheckboxLabel";
import { Input } from "@designSystem/molecules/Input";
import { InputDatePicker } from "@designSystem/molecules/InputDatePicker";
import { Modal } from "@designSystem/organisms/modal/Modal";

type valuesType = {
	title: string;
	date: any;
	hour: any;
	note: string;
	notes?: string;
	done: boolean;
};

type manageActionModalTypes = {
	show: boolean;
	onClose: (e?: any) => void;
	action: {
		id?: string;
		title?: string;
		datetime?: string;
		date?: string;
		hour?: string;
		note?: string;
		notes?: string;
		done?: boolean;
	};
	onApply: (id: string, values: valuesType) => void;
	onDelete: (id: string) => void;
};

export function ManageActionModal({
	show,
	onClose,
	action,
	onApply,
	onDelete,
}: manageActionModalTypes) {
	const [actionToShow, setActionToShow] = useState(action);

	useEffect(() => {
		setActionToShow(action);
	}, [action]);

	const handleOnClose = (newAction: any) => {
		onClose(newAction);
	};
	return (
		<Formik
			initialValues={{
				title: actionToShow.title || "",
				date: actionToShow.datetime || "",
				hour: actionToShow.hour,
				note: actionToShow.note || "",
				notes: actionToShow.notes || "",
				done: actionToShow.done || false,
			}}
			enableReinitialize
			validate={(values) => {
				const errors: { title?: string; date?: string } = {};
				if (!values.title) errors.title = "Entrez un titre";
				if (!values.date) errors.date = "Entrez une date";
				return errors;
			}}
			onSubmit={(values, { resetForm }) => {
				const valuesCopy = { ...actionToShow, ...values };
				onApply(actionToShow.id!, valuesCopy);
				resetForm({});
				handleOnClose(valuesCopy);
			}}
		>
			{({
				values,
				handleSubmit,
				resetForm,
				setFieldValue,
				handleChange,
				errors,
			}) => (
				<Modal
					show={show}
					onClose={() => {
						onClose();
						resetForm();
					}}
					title={actionToShow.title || ""}
					body={
						<div>
							<Input
								icon={pricetagsOutline}
								value={values.title}
								name="title"
								onChange={handleChange}
								className="mt-sm"
								placeholder="Nom de l'action"
								errorMessage={errors.title}
							/>
							<div className="mt-sm flex">
								<InputDatePicker
									onChange={(value) => {
										setFieldValue(
											"date",
											value.split("/").reverse().join("/"),
											false,
										);
									}}
									errorMessage={errors.date}
									value={values.date}
									icon={calendarOutline}
								/>
							</div>
							<TextArea
								value={values.note || values.notes || ""}
								onChange={(value) => {
									setFieldValue("note", value, false);
								}}
								className="mt-sm"
								placeholder="Notes"
								height={300}
							/>
							<div className="mt-sm flex justify-between">
								<CheckboxLabelPrimary
									checked={values.done}
									onCheck={(checked: boolean) => {
										setFieldValue("done", checked, false);
									}}
									label="Marquer comme effectuée"
								/>
							</div>
						</div>
					}
					buttonsRight={[
						<ButtonSecondary
							key="cancelBTN"
							onClick={() => {
								resetForm();
								onClose();
							}}
							label="Annuler"
						/>,
						<ButtonPrimary
							key="confirmBTN"
							onClick={() => {
								handleSubmit();
								onClose();
							}}
							label="Confirmer"
						/>,
					]}
					buttonsLeft={[
						<ButtonQuaternary
							key="deleteBTN"
							onClick={() => {
								onClose();
								onDelete(action.id!);
							}}
							icon={trash2Outline}
						/>,
					]}
				/>
			)}
		</Formik>
	);
}
