import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { UserType } from "@/types/userTypes";
import moment from "moment";
import React, { useEffect, useState } from "react";

import "../StudentDetail.scss";

import { convertDateFromNow } from "@tools/Dates";

import { InlineFileInput } from "@containers/school/Students/StudentDetail/ui/InlineFileInput";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Spinner } from "@designSystem/atoms/Spinner";
import { FilesPrimary } from "@designSystem/molecules/Files";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { success } from "@tools/Toasts";

import { PdfViewer } from "@designSystem/atoms/PdfViewer";
import Skeleton from "react-loading-skeleton";
import { deleteSchoolDocument, sendFile } from "../StudentDetailAPI";

export function Files({
	student,
}: {
	student: UserType & { school: { name: string } };
}) {
	const [cv, setCV] = useState<MediaObjectType | null>();
	const [portfolioPdf, setPortfolioPdf] = useState<MediaObjectType | null>();
	const [motivationLetter, setMotivationLetter] =
		useState<MediaObjectType | null>();
	const [showDocument, setShowDocument] = useState(false);
	const [fileIsLoading, setFileIsLoading] = useState(false);
	const [newFileLoading, setNewFileLoading] = useState(false);
	const [fileError, setFileError] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [documentToDelete, setDocumentToDelete] = useState<string | undefined>(
		undefined,
	);
	const [documentToDisplay, setDocumentToDisplay] =
		useState<Partial<MediaObjectType>>();

	const getDocumentCount = () => {
		let count = 0;
		if (student.cv?.filePath) count++;
		if (student.motivationLetter?.filePath) count++;
		if (student.portfolioPdf?.filePath) count++;
		if (student.schoolDocuments) count = count + student.schoolDocuments.length;
		return count;
	};
	const removeDoc = (id: string) => {
		if (!student.schoolDocuments) return;
		const docIndex = student.schoolDocuments.findIndex((doc) => doc.id === id);
		delete student.schoolDocuments[docIndex];
	};

	const importSchoolFile = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("type", "school");
		formData.append("state", "validated");
		formData.append("ownerId", student.id.split("/")[3]);

		const data: any = {
			id: student.id,
		};
		const response: Partial<MediaObjectType> = await sendFile(formData);

		const newFile = {
			id: response["@id"],
			filePath: response.filePath,
			type: response.type,
			size: response.size,
			createdAt: response.createdAt,
		};
		data.portfolioPdf = response["@id"];
		student.schoolDocuments?.unshift(newFile);
	};

	useEffect(() => {
		setCV(student.cv);
		setMotivationLetter(student.motivationLetter);
		setPortfolioPdf(student.portfolioPdf);
	}, [student]);

	return (
		<div className="document-container w-full">
			<h3>
				Documents{" "}
				<span className="text-primary-300">({getDocumentCount()})</span>
			</h3>
			<p className="mb-md mt-md font-bold">Importés par l'étudiant</p>
			{!cv && !motivationLetter && !portfolioPdf && (
				<p className="subtitle-p mt-xsm text-primary-200">
					Aucun document envoyé
				</p>
			)}
			<div className="flex flex-col gap-lg">
				{cv && (
					<div>
						<p className="text-xsm text-primary-700P">
							CV -{" "}
							<span className="text-xsm text-primary-200">
								Importé {convertDateFromNow(cv.createdAt)}
							</span>
						</p>
						<FilesPrimary
							isLoading={false}
							className="mt-xxsm"
							title={cv.filePath}
							subtitle={`${moment(cv.createdAt).format(
								"DD/MM/YYYY",
							)} ‧ ${cv?.filePath?.split(".")[1].toUpperCase()} ‧ ${Math.trunc(
								cv.size / 1000,
							)} Ko`}
							onDownload={() => {
								window.open(`${import.meta.env.VITE_S3}${cv.filePath}`);
							}}
							onView={() => {
								setDocumentToDisplay(cv);
								setShowDocument(true);
							}}
							onValidated={cv.state === "validated" || cv.state === "editing"}
							validated={cv.state === "validated"}
						/>
					</div>
				)}
				{motivationLetter && (
					<div>
						<p className="text-xsm text-primary-700P">
							Lettre de motivation -{" "}
							<span className="text-xsm text-primary-200">
								Importé {convertDateFromNow(motivationLetter?.createdAt)}
							</span>
						</p>
						<FilesPrimary
							isLoading={false}
							className="mt-xxsm"
							title={motivationLetter?.filePath}
							subtitle={`${moment(motivationLetter?.createdAt).format(
								"DD/MM/YYYY",
							)} ‧ ${motivationLetter?.filePath
								?.split(".")[1]
								.toUpperCase()} ‧ ${
								motivationLetter ? Math.trunc(motivationLetter.size / 1000) : 0
							} Ko`}
							onDownload={() => {
								window.open(
									`${import.meta.env.VITE_S3}${motivationLetter?.filePath}`,
								);
							}}
							onView={() => {
								setDocumentToDisplay(motivationLetter);
								setShowDocument(true);
							}}
						/>
					</div>
				)}
				{portfolioPdf && (
					<div>
						<p className="text-xsm text-primary-700P">
							Portfolio -{" "}
							<span className="text-xsm text-primary-200">
								Importé {convertDateFromNow(portfolioPdf?.createdAt)}
							</span>
						</p>
						<FilesPrimary
							isLoading={false}
							className="mt-xxsm"
							title={portfolioPdf?.filePath}
							subtitle={`${moment(portfolioPdf?.createdAt).format(
								"DD/MM/YYYY",
							)} ‧ ${portfolioPdf?.filePath?.split(".")[1]?.toUpperCase()} ‧ ${
								portfolioPdf ? Math.trunc(portfolioPdf.size / 1000) : 0
							} Ko`}
							onDownload={() => {
								window.open(
									`${import.meta.env.VITE_S3}${portfolioPdf?.filePath}`,
								);
							}}
							onView={() => {
								setDocumentToDisplay(portfolioPdf);
								setShowDocument(true);
							}}
						/>
					</div>
				)}
			</div>
			<p className="mb-md mt-md font-bold">
				Importés par {student?.school?.name}
			</p>
			<InlineFileInput
				className="my-xsm"
				id="school-doc"
				isLoading={newFileLoading}
				acceptFormats={{ "application/pdf": [] }}
				maxSize={10}
				onSuccess={async (e: File[]) => {
					setFileError(false);
					setNewFileLoading(true);
					try {
						await importSchoolFile(e[0]);
						success("Document ajouté");
					} catch (_e) {
						setFileError(true);
					} finally {
						setNewFileLoading(false);
					}
				}}
				error={fileError}
				onError={() => {
					setFileError(true);
				}}
			/>
			{newFileLoading && <FileLoader />}
			{student.schoolDocuments?.length ? (
				<div className="mt-md flex flex-col gap-lg">
					{student.schoolDocuments?.map((document) => {
						return (
							<div>
								<p className="text-xsm text-primary-200">
									Importé {convertDateFromNow(document.createdAt!)}
								</p>
								<FilesPrimary
									onDelete={() => {
										setDocumentToDelete(document.id!);
										setShowDeleteModal(true);
									}}
									isLoading={false}
									className="mt-xxsm"
									title={document.filePath!}
									subtitle={`${moment(document.createdAt).format(
										"DD/MM/YYYY",
									)} ‧ ${document
										.filePath!.split(".")[1]
										.toUpperCase()} ‧ ${Math.trunc(document.size! / 1000)} Ko`}
									onDownload={() => {
										window.open(
											`${import.meta.env.VITE_S3}${document.filePath}`,
										);
									}}
									onView={() => {
										setDocumentToDisplay(document);
										setShowDocument(true);
									}}
								/>
							</div>
						);
					})}
				</div>
			) : (
				<p>Aucun document envoyé</p>
			)}

			<Modal
				show={showDocument}
				title={`Document - ${documentToDisplay?.filePath}`}
				onClose={() => {
					setShowDocument(false);
				}}
				body={
					<PdfViewer
						src={`${import.meta.env.VITE_S3}${documentToDisplay?.filePath}`}
						title={`Document - ${documentToDisplay?.filePath}`}
					/>
				}
				className="my-2"
				size="lg"
			/>
			<Modal
				show={showDeleteModal}
				onClose={() => {
					setShowDeleteModal(false);
					setDocumentToDelete(undefined);
				}}
				title="Confirmer la suppression"
				body={
					<div className="flex w-full">
						{!fileIsLoading ? (
							<p>
								Êtes-vous sûr de vouloir supprimer ce document ? <br /> Cette
								action est irréversible
							</p>
						) : (
							<Spinner className="mx-auto" />
						)}
					</div>
				}
				buttonsRight={[
					<ButtonSecondary
						key={0}
						label="Non, annuler"
						disabled={fileIsLoading}
						onClick={() => {
							setShowDeleteModal(false);
						}}
					/>,
					<ButtonPrimary
						key={1}
						label="Oui, confirmer"
						disabled={fileIsLoading}
						onClick={async () => {
							if (!documentToDelete) return;
							setFileIsLoading(true);
							try {
								await deleteSchoolDocument(documentToDelete);
								removeDoc(documentToDelete);
								setShowDeleteModal(false);
								setDocumentToDelete(undefined);
							} catch {
								Error("Une erreur est survenue");
							} finally {
								setFileIsLoading(false);
							}
						}}
					/>,
				]}
			/>
		</div>
	);
}

function FileLoader() {
	return (
		<div className="mb-sm flex items-center justify-between">
			<div className="w-8/12">
				<div className="mb-xsm w-8/12">
					<Skeleton />
				</div>

				<div className="flex gap-xsm">
					<Skeleton width={40} height={40} borderRadius={10} />
					<div className="w-full">
						<Skeleton />
						<Skeleton />
					</div>
				</div>
			</div>
			<div className="flex gap-sm">
				<Skeleton width={25} height={25} />
				<Skeleton width={25} height={25} />
			</div>
		</div>
	);
}
