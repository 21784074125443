import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";
import { useNavigate, useOutletContext } from "react-router";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";
import { JobboardsOffers } from "./Body/JobboardsOffers";
import { SchoolOffers } from "./Body/SchoolOffers";

export function Body() {
	const navigate = useNavigate();
	const { user } = useContext(GlobalContext);
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const {
		load,
		tab,
		totalSchoolCount,
		totalScrapedCount,
		loadSchoolOffers,
		loadScrapedOffers,
	} = useAppSelector((state) => state.jobboard);
	const renderBodyPage = () => {
		switch (tab) {
			case "jobboardsOffers":
				return <JobboardsOffers />;
			default:
				return <SchoolOffers />;
		}
	};
	return (
		<section className="col-span-4 md:col-span-8 lg:col-span-12">
			<ScrollableTabGroup
				isLoading={load}
				tabs={[
					{
						label: `Offres ${user?.schoolName} (${
							loadSchoolOffers || loadScrapedOffers ? "..." : totalSchoolCount
						})`,
						active: tab === "schoolOffers",
					},
					{
						label: `Offres Jobboard (${
							loadSchoolOffers || loadScrapedOffers ? "..." : totalScrapedCount
						})`,
						active: tab === "jobboardsOffers",
					},
				]}
				onTabChange={(i) => {
					switch (i) {
						case 1:
							navigate(
								currentInterface === "student"
									? "/jobboard"
									: "/school-jobboard",
							);
							break;
						default:
							navigate(
								currentInterface === "student"
									? "/school-offers"
									: "/own-offers",
							);
							break;
					}
				}}
			/>
			<div className="mt-sm">{renderBodyPage()}</div>
		</section>
	);
}
