import type { EntOffersType } from "@/types/EntCompanyTypes";

import api, { graphqlRequestConstructor } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

import { errorToast } from "@tools/Toasts";

export const createOffer = async (inputs: Partial<EntOffersType>) => {
	let offer: Omit<Partial<EntOffersType>, "pdf"> & { pdf: string | null };
	if (inputs.isPdf && inputs.pdf) {
		offer = {
			...inputs,
			pdf: inputs.pdf?.["@id"] as string,
		};
	} else {
		offer = {
			...inputs,
			pdf: null,
		};
	}
	const queryData = {
		query: `mutation {
            createEntOffer(input: ${stringifyRequestInputs(offer)}) {
                entOffer {
                  id
                  job
                }
            }
        }`,
	};
	const rep = await graphqlRequestConstructor(queryData, "queryEntOffers");
	if (rep.responseLabel === "error") {
		throw new Error(rep.message);
	}
	return rep;
};

export const closeOfferApi = async (entOfferId: string) => {
	const data = {
		query: `mutation {
            updateEntOffer(input: {
                id: "${entOfferId}"
                status: "expired"
            }) {
                entOffer {
                  id
                  status
                  spsOffer {
                    id
                  }
                }
            }
        }`,
	};
	try {
		const repEnt = await api.post("graphql", data);
		if (repEnt.data.data.updateEntOffer.entOffer.spsOffer) {
			const dataSpsOffer = {
				query: `mutation {
            updateOffer(input: {
                id: "${repEnt.data.data.updateEntOffer.entOffer.spsOffer.id}"
                status: "expired"
            }) {
                offer {
                  id
                  status
                }
            }
        }`,
			};
			const repSps = await api.post("graphql", dataSpsOffer);
			return [repSps, repEnt];
		}
		return repEnt;
	} catch (e) {
		errorToast("Une erreur est survenue lors de la clôture.");
		return e;
	}
};

export async function sendFile(formdata: FormData) {
	let rep = {};

	await api.post("media_objects", formdata).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function queryOffers(profileId: number) {
	const data = {
		query: `query {
              entOffers(entCompanySchoolProfile: "${profileId}", 
              order: [{createdAt: "DESC"}]) {
                collection {
                  id
                  job
                  description
                  location
                  telework
                  isPdf
                  createdBy {
                  	id
										firstname
										lastname
                  }
                  spsSelection {
                    id
                    name
                    token
                    lastOpening
                    userLastAdded
                    selectionUsers {
											collection {
												id
											} 
                  	}
                  }
                  pdf {
                    id
                    filePath
                    size
                  }
                  qualification
                  status
                  link
                  contract
                  emailPreference
                  createdAt
                }
              }
            }`,
	};
	try {
		const rep = await api.post("graphql", data);
		const collection: EntOffersType[] = rep.data.data.entOffers.collection;

		return collection;
	} catch (error) {
		throw new Error(error);
	}
}
