import type { AppDispatch } from "@config/redux/store";
import { fetchCompanyMemo } from "@containers/school/Companies/core/usecases/fetchCompanyMemo.usecase";
import { updateCompanyMemo } from "@containers/school/Companies/core/usecases/updateCompanyMemo.usecase";
import type {
	IMemoGateway,
	RetrieveMemoType,
} from "@designSystem/molecules/memo/core/memo.gateway";

export class CompanyMemoGateway implements IMemoGateway {
	public dispatcher: AppDispatch;
	constructor(dispatcher: AppDispatch) {
		this.dispatcher = dispatcher;
	}
	async saveThisMemo(
		_id: string,
		value: string,
		initialValue: string,
	): Promise<boolean> {
		if (initialValue === value) return false;
		const response = await this.dispatcher(updateCompanyMemo(value) as any);
		if (response === undefined || response === null) {
			return true;
		}
		return false;
	}

	async retrieveMemo(): Promise<RetrieveMemoType> {
		const response = await this.dispatcher(fetchCompanyMemo as any);
		return {
			body: response?.length > 0 ? response : "",
			initialValue: response?.length > 0 ? response : "",
		};
	}
}
