import React from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { Modal } from "@designSystem/organisms/modal/Modal";

export function UpdateModal({ onClose, show, messages }) {
	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title="Informations mises à jour"
			body={
				<div>
					<TextArea disabled height="88px" value={messages?.[0]} />
					<p className="font-semifont-bold mt-sm text-xsm text-primary-700P">
						Tu as mis à jour ta candidature.
					</p>
					<p className="mt-sm text-xsm text-primary-200">
						Message envoyé à l&apos;école :
					</p>
					<p className="mt-xsm text-xsm text-primary-700P">{messages?.[1]}</p>
				</div>
			}
			buttonsRight={[
				<ButtonPrimary
					label="Fermer"
					onClick={() => {
						onClose();
					}}
				/>,
			]}
		/>
	);
}
