import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { FileInput } from "@designSystem/molecules/FileInput";
import { FilesPrimary } from "@designSystem/molecules/Files";

import { uploadOfferFileRequest } from "../../core/api/offerRest.request";
import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

const FILE_MAX_SIZE = 5;

function OfferFileUploader() {
	const dispatchEvent = useDispatch();
	const { offerToCreate } = useAppSelector((state) => state.offers);
	const [errorOfferFileLoading, setErrorOfferFileLoading] =
		useState<string>("");
	const [offerFileLoading, setOfferFileLoading] = useState<boolean>(false);

	const handleUploadFile = async (file: any) => {
		setOfferFileLoading(true);
		const data = new FormData();
		data.append("file", file);

		const mediaUploaded: MediaObjectType = (await uploadOfferFileRequest(
			data,
		)) as MediaObjectType;

		dispatchEvent(modifyOfferToCreate({ pdf: mediaUploaded }));
		setOfferFileLoading(false);
	};

	const handleDeleteFile = async () => {
		dispatchEvent(modifyOfferToCreate({ pdf: null }));
	};

	return (
		<div
			className="mt-4 flex w-full flex-col gap-sm"
			data-testid="offerFileUploader"
		>
			<p className="text-xsm font-bold text-primary-700P">Pièce jointe</p>
			<div>
				{offerToCreate?.pdf?.filePath ? (
					<FilesPrimary
						isLoading={offerFileLoading}
						title={
							offerToCreate?.pdf?.filePath
								? offerToCreate?.pdf?.filePath
								: "Aucun fichier sélectionné"
						}
						subtitle={`${Math.floor(
							offerToCreate?.pdf?.size ? offerToCreate?.pdf?.size / 1000 : 0,
						)} Ko`}
						onDelete={() => {
							handleDeleteFile();
						}}
						onView={() => {
							window.open(
								`${import.meta.env.VITE_S3}${offerToCreate?.pdf?.filePath}`,
							);
						}}
					/>
				) : (
					<FileInput
						id="offerFileUploader"
						isLoading={false}
						maxSize={5}
						acceptFormats={{ "application/pdf": [] }}
						onSuccess={(files: any) => {
							if (files !== undefined) {
								handleUploadFile(files[0]);
								setErrorOfferFileLoading("");
							} else {
								setErrorOfferFileLoading(
									`Le fichier envoyé dépasse la limite de ${FILE_MAX_SIZE}Mo ou n'est pas sous format PDF`,
								);
							}
						}}
						onError={(files: any) => {
							setErrorOfferFileLoading(
								files[0]?.size / 1000000 >= 1
									? "Le fichier envoyé dépasse la limite de 1Mo"
									: "Le fichier envoyé n'est pas sous format PDF",
							);
						}}
					/>
				)}
				{errorOfferFileLoading ? (
					<p className="text-error-dark">{errorOfferFileLoading}</p>
				) : (
					""
				)}
			</div>
		</div>
	);
}

export default OfferFileUploader;
