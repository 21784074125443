import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { getCookie, setCookie } from "@tools/Cookies";

import { HeaderSchool } from "@designSystem/organisms/header/Header";

import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";
import { MenuNavigation } from "../../../navigation/MenuNavigation";

export const AtsLayout = () => {
	const { user, setCampuses } = useContext(GlobalContext);
	const [slidingPaneIsOpen] = useState(false);
	const [currentInterface] = useState<"school" | "student">("school");
	const [title, setTitle] = useState(
		`Bienvenue sur votre espace ${user?.schoolName}`,
	);

	const [structureView, setStructureView] = useState(
		user?.roles.includes("ROLE_SCHOOL")
			? { school: user?.school }
			: { campus: user?.campuses },
	);

	const [accessList, setAccessList] = useState<any>("");

	const dispatchEvent = useAppDispatch();

	useEffect(() => {
		if (user)
			dispatchEvent(eventsSlice.actions.setIsMentorGoalUser(user?.email));
		setCampuses(accessList);
	}, [user]);

	const getHeaderSelectOptions = () => {
		return [
			{
				label: user?.schoolName,
				value: { school: user?.school },
			},
			{
				label: user?.campusName,
				value: { campus: user?.campuses },
			},
		];
	};

	useEffect(() => {
		setAccessList(getHeaderSelectOptions());
	}, []);
	useEffect(() => {
		document.title = `${title} - Mentor Goal`;
		setCampuses(accessList);

		if (slidingPaneIsOpen) {
			const bodyElement = document.getElementsByTagName("body")[0];
			bodyElement.style.overflow = "hidden";
		}
	}, [title, structureView, accessList, slidingPaneIsOpen]);

	return user ? (
		<div>
			<MenuNavigation user={user} currentInterface={"ats"} />
			<HeaderSchool
				title={title}
				notifCount={0}
				messageCount={0}
				structureView={structureView}
				accessList={accessList}
				onChange={(e) => {
					if (getCookie("StudentsManagementFilters")) {
						const filters = JSON.parse(
							getCookie("StudentsManagementFilters") ?? "",
						);
						filters.spsPromotion_list = undefined;
						setCookie("StudentsManagementFilters", JSON.stringify(filters));
					}
					setStructureView(e.value);
				}}
				onClick={() => {}}
			/>

			<Outlet context={{ structureView, setTitle, currentInterface }} />
		</div>
	) : (
		<div />
	);
};
