import type { UserCompleteType } from "@/types/userTypes";
import { EStatus } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React, { useCallback, useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";

import {
	alertTriangleOutline,
	arrowBackOutline,
	arrowDownwardOutline,
	arrowIosForwardOutline,
	checkMarkCircleOutline,
	checkmarkOutline,
	clockOutline,
	closeCircleOutline,
	downloadOutline,
	editOutline,
	emailOutline,
	paperPlaneOutline,
} from "@assets/Icons";

import { DAYS_BETWEEN_RELAUNCH } from "@constants/CvBoard";

import { convertDateFromNow, diffDate } from "@tools/Dates";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { Checkbox } from "@designSystem/atoms/Checkbox";
import { Spinner } from "@designSystem/atoms/Spinner";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { setCurrentFilters } from "@modules/usersFilters/usersFiltersSlice";
import { success } from "@tools/Toasts";

import { exportCVs, relaunchCvsMultipleStudents } from "../../CVBoardAPI";
import { CVBoardContext } from "../../index";
import { CreateSelectionModal } from "./TableView/CreateSelectionModal";

type SmallListType = {
	setStudent: (CV: UserCompleteType) => void;
	setSelectedCVs: Function;
	setShowOffer: (param: boolean) => void;
	setShowShareCVModal: (param: boolean) => void;
	showShareCVModal: boolean;
	handleOnCheckAll: (param: boolean) => void;
	student: UserCompleteType;
	selectedCVs: UserCompleteType[];
	handleOnCheckCV: (CV: UserCompleteType) => void;
	checkAllLoader: boolean;
};

export function SmallList({
	student,
	setStudent,
	setShowOffer,
	selectedCVs,
	setSelectedCVs,
	handleOnCheckAll,
	handleOnCheckCV,
	setShowShareCVModal,
	showShareCVModal,
	checkAllLoader,
}: SmallListType) {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector((state) => state.usersFilters);
	const { CVs, lastPage, load, canSwitch, totalCount } =
		useContext(CVBoardContext);

	const relaunchStudents = async () => {
		await relaunchCvsMultipleStudents(selectedCVs);
		setSelectedCVs([]);
		success("Les étudiants ont bien été relancés.");
		dispatchEvent(setCurrentFilters({ ...currentFilters }));
	};

	const isActive = (id: string) => {
		return id === student.id;
	};

	const downloadZip = async () => {
		const cvs = selectedCVs.map((student) => student.cv?.id);
		const { fileName }: { fileName?: string } = await exportCVs(cvs);

		if (fileName) {
			window.open(
				`${import.meta.env.VITE_BACK}/zip_export/${fileName}`,
				"_blank",
			);
			setSelectedCVs([]);
		}
	};

	const getStatus = (status: string) => {
		if (status === "toValidate") {
			return (
				<span className=" ml-xsm flex items-center whitespace-nowrap rounded-2xl border border-accent-3-dark bg-accent-3-lighter p-1 px-2 text-xs font-bold text-accent-3-dark">
					À valider
					<Icon className="ml-1 inline h-4 w-4" icon={alertTriangleOutline} />
				</span>
			);
		}
		if (status === "validated") {
			return (
				<span className="ml-xsm whitespace-nowrap rounded-2xl border border-accent-4-dark bg-accent-4-lighter p-1 px-2 text-xs font-bold text-accent-4-dark">
					CV validé
					<Icon className="ml-1 inline h-4 w-4" icon={checkmarkOutline} />
				</span>
			);
		}
		return (
			<span className="ml-xsm flex items-center whitespace-nowrap rounded-2xl border border-secondary-700 bg-secondary-50 p-1 px-2 text-xs font-bold text-secondary-700">
				En modification
				<Icon className="ml-1 inline h-4 w-4" icon={clockOutline} />
			</span>
		);
	};
	const getStudentStatus = (status: EStatus) => {
		if (status === EStatus.TOPLACE) {
			return (
				<>
					<Icon
						className="mr-1 inline h-4 w-4 text-accent-2"
						icon={clockOutline}
					/>
					<span className="text-[14px] text-primary-500">À placer</span>
				</>
			);
		}
		if (status === EStatus.PLACED) {
			return (
				<span className="text-[14px] text-primary-500">
					<Icon
						className="mr-1 inline h-4 w-4 text-accent-4"
						icon={checkmarkOutline}
					/>
					Placé
				</span>
			);
		}
		if (status === EStatus.BEING_SIGNED) {
			return (
				<span className="text-[14px] text-primary-500">
					<Icon
						className="mr-1 inline h-4 w-4 text-accent-1"
						icon={editOutline}
					/>
					En signature
				</span>
			);
		}
		return (
			<span className="text-[14px] text-primary-500">
				<Icon
					className="mr-1 inline h-4 w-4 text-accent-3"
					icon={closeCircleOutline}
				/>
				Abandon
			</span>
		);
	};
	const getNext = () => {
		const currentIndex = CVs.findIndex((elem: { id: string }) => {
			return elem.id === student.id;
		});

		if (currentIndex + 1 !== CVs.length) {
			setStudent(CVs[currentIndex + 1]);
		} else {
			if (currentFilters.page !== lastPage) {
				dispatchEvent(
					setCurrentFilters({
						...currentFilters,
						page: currentFilters.page + 1,
					}),
				);
			}
		}
	};
	const getPrev = () => {
		const currentIndex = CVs.findIndex((elem: { id: string }) => {
			return elem.id === student.id;
		});

		if (currentIndex - 1 !== -1) {
			setStudent(CVs[currentIndex - 1]);
		} else {
			if (currentFilters.page > 1) {
				if (student.id !== CVs[0].id) return;
				const newpage = currentFilters.page === 1 ? 1 : currentFilters.page - 1;
				setStudent(CVs[CVs.length - 1]);
				dispatchEvent(
					setCurrentFilters({
						...currentFilters,
						page: newpage,
					}),
				);
			}
		}
	};

	const skeleton = () => {
		const skeletons = Array(3)
			.fill(["#e9f5e4", "#fdf0e4", null, "#fbe6e1"])
			.flat();
		return skeletons.map((color) => (
			<>
				<div className="w-full">
					<Skeleton baseColor={color} height={125} />
				</div>
			</>
		));
	};
	const handleKeyPress = useCallback(
		(event: KeyboardEvent) => {
			if (canSwitch) {
				if (event.key === "ArrowDown") {
					event.preventDefault();
					getNext();
				}
				if (event.key === "ArrowUp") {
					event.preventDefault();
					getPrev();
				}
				if (event.key === "ArrowLeft") {
					setShowOffer(false);
				}
			}
		},
		[getNext, getPrev],
	);
	useEffect(() => {
		window.addEventListener("keydown", handleKeyPress);

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [handleKeyPress]);

	return (
		<>
			<div className="h-fit-content w-full gap-sm rounded-2xl bg-white p-sm slg:w-3/12 xl:w-2/12">
				<div className="flex">
					<button
						className="flex items-center"
						onClick={() => {
							setShowOffer(false);
						}}
					>
						<Icon
							icon={arrowBackOutline}
							className="inline rounded border-b-2 border-l border-r-2 border-t border-primary-900"
						/>
						<p className="ml-1 font-bold ">Écran principal</p>
					</button>
					<button className="ml-md flex items-center" onClick={() => getNext()}>
						<Icon
							icon={arrowDownwardOutline}
							className="inline rounded border-b-2 border-l border-r-2 border-t border-primary-900"
						/>
						<p className="ml-1 font-bold">Suivant</p>
					</button>
				</div>
				<div className="my-2 flex">
					{checkAllLoader ? (
						<Spinner size="small" />
					) : (
						<Checkbox
							className="my-auto mr-2"
							checked={selectedCVs.length === totalCount}
							onEnabled={() => {
								handleOnCheckAll(true);
							}}
							onDisabled={() => {
								handleOnCheckAll(false);
							}}
						/>
					)}
					{selectedCVs.length > 0 ? (
						<span className="my-auto flex w-full items-center">
							{selectedCVs.length} CVs sélectionnés{" "}
							<Tippy
								content="Télécharger les CV"
								theme="primary"
								animation="fade"
								placement="top-start"
								zIndex={5}
							>
								<span className="ml-auto">
									<ButtonTertiary
										className="h-[40px] w-[40px] cursor-pointer rounded p-1 outline outline-1 outline-primary-500"
										icon={downloadOutline}
										onClick={downloadZip}
									/>
								</span>
							</Tippy>
							{(currentFilters.state === "validated" ||
								currentFilters.state === "toValidate" ||
								currentFilters.state === "all") && (
								<Tippy
									content="Partager les CV"
									theme="primary"
									animation="fade"
									placement="top-start"
									zIndex={5}
								>
									<span>
										<ButtonPrimary
											className="ml-xsm inline h-[40px] w-[40px] cursor-pointer rounded p-1 outline outline-1 outline-primary-500"
											icon={paperPlaneOutline}
											onClick={() => {
												setShowShareCVModal(true);
											}}
										/>
									</span>
								</Tippy>
							)}
							{(currentFilters.state === "without" ||
								currentFilters.state === "editing") && (
								<Tippy
									content="Relancer les étudiants"
									theme="primary"
									animation="fade"
									placement="top-start"
									zIndex={5}
								>
									<span>
										<ButtonPrimary
											className="ml-xsm inline h-[40px] w-[40px] rounded p-2 outline outline-1 outline-primary-500"
											onClick={() => {
												relaunchStudents();
											}}
											icon={emailOutline}
										/>
									</span>
								</Tippy>
							)}
						</span>
					) : (
						<span className="my-auto flex h-[40px] w-full items-center">
							Tout sélectionner
						</span>
					)}
				</div>

				{load ? (
					<>{skeleton()}</>
				) : (
					CVs?.map((CV: UserCompleteType) => {
						const tippyEnabled = document.getElementById(
							`${CV.promotion.name}`,
						);

						return (
							<div
								className={`relative mb-4 flex cursor-pointer rounded-md ${
									isActive(CV.id)
										? "border border-solid border-primary-500 bg-primary-50 p-2 shadow"
										: "p-2 shadow"
								}`}
								onClick={(e: any) => {
									e.stopPropagation();
									setStudent(CV);
								}}
							>
								<div
									className="flex w-1/12"
									onClick={(e: any) => {
										e.stopPropagation();

										if (diffDate(new Date(CV.cvRelaunchDate)) !== 0) {
											handleOnCheckCV(CV);
										}
									}}
								>
									{currentFilters.state === "editing" &&
									diffDate(new Date(CV.cvRelaunchDate)) <
										DAYS_BETWEEN_RELAUNCH ? (
										<Icon
											className="my-auto mr-2 h-6 w-full text-primary-300"
											icon={checkMarkCircleOutline}
										/>
									) : (
										<Checkbox
											checked={
												selectedCVs.filter((e) => e.id === CV.id).length > 0
											}
											onEnabled={() => {
												handleOnCheckCV(CV);
											}}
											onDisabled={() => {
												handleOnCheckCV(CV);
											}}
											className="my-auto mr-2"
										/>
									)}
								</div>

								<div className="w-11/12">
									<div className="flex items-center">
										<span className="truncate text-xs text-primary-300">
											{`Déposé ${convertDateFromNow(CV.cv?.createdAt ?? "")}`}
										</span>
										{getStatus(CV.cv?.state ?? "")}
									</div>
									<div className="my-2 flex">
										<div className="w-full">
											<p className="wg-no-translate font-bold">
												{CV.firstname} {CV.lastname}
											</p>
											<Tippy
												content={CV.promotion.name}
												theme="primary"
												animation="fade"
												placement="top"
												zIndex={5}
												disabled={
													tippyEnabled !== null &&
													tippyEnabled !== undefined &&
													tippyEnabled.scrollWidth <= tippyEnabled.offsetWidth
												}
											>
												<span>
													<p
														id={CV.promotion.name}
														className="wg-no-translate w-9/12 truncate"
													>
														{CV.promotion.name}
													</p>
												</span>
											</Tippy>
										</div>
									</div>
									<div className="flex items-center">
										{getStudentStatus(CV.status)}
									</div>
									{isActive(CV.id) ? (
										<Icon
											width="24"
											height="24"
											icon={arrowIosForwardOutline}
											className="absolute right-0 top-1/2 ml-auto -translate-y-1/2 transform text-primary-500"
										/>
									) : null}
								</div>
							</div>
						);
					})
				)}
				<PaginationPrimary
					current={currentFilters.page}
					onChange={(newPage) => {
						dispatchEvent(
							setCurrentFilters({ ...currentFilters, page: newPage }),
						);
					}}
					last={lastPage}
					className="py-md"
				/>
			</div>
			<CreateSelectionModal
				CVsListProps={{ selectedCVs, setSelectedCVs }}
				show={showShareCVModal}
				onClose={() => setShowShareCVModal(false)}
			/>
		</>
	);
}
