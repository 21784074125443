import type { InterviewInfoType } from "@/types/interviewTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { InlineIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import moment from "moment";
import React from "react";

import { infoOutline } from "@assets/Icons";

import { FilesPrimary } from "@designSystem/molecules/Files";
import { Input } from "@designSystem/molecules/Input";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";

export function Application({
	application,
}: {
	application: InterviewInfoType & {
		job: string;
		type?: string;
		location: string;
		contract: string;
		documents: { collection: MediaObjectType[] };
	};
}) {
	const optionsContract = [
		{
			label: "Tout",
			value: "unknown",
		},
		{
			label: "Alternance",
			value: "apprenticeship",
		},
		{
			label: "Stage",
			value: "intern",
		},
		{ label: "CDI", value: "permanent" },
		{ label: "CDD", value: "temporary" },
	];

	const optionsType = [
		{
			label: "Spontanée",
			value: "spontaneous",
		},
		{
			label: "Offre d'emploi",
			value: "jobOffer",
		},
		{
			label: "Approche réseau",
			value: "networkApproach",
		},
		{
			label: "Réseau école",
			value: "schoolNetwork",
		},
		{
			label: "Autre",
			value: "other",
		},
	];

	return (
		<div className="mt-lg">
			<Input
				className="mb-md"
				disabled
				label="Poste"
				value={application?.job}
			/>

			<Input
				className="mb-md"
				disabled
				label="Localisation"
				placeholder="Non renseignée"
				value={application?.location}
			/>
			<div className="flex flex-wrap items-center">
				<SelectTertiary
					disabled
					className="mb-sm w-full"
					label="Type de contrat"
					options={optionsContract}
					defaultValue={
						optionsContract.find(
							(contract) => contract.value === application?.contract,
						)?.value || optionsContract[0].value
					}
				/>

				<SelectTertiary
					className="w-full"
					disabled
					label="Type de candidature"
					defaultValue={
						optionsType.find((type) => type.value === application?.type)?.value
					}
					options={optionsType}
				/>
			</div>
			<div className="mt-lg flex items-center ">
				<p className="text-sm font-bold text-primary-700P">Documents</p>
				<Tippy
					content="Avoir un CV et une lettre de motivation personnalisées augmentent tes chances de décrocher un entretien de 50%."
					theme="primary"
					animation="fade"
					placement="top"
					zIndex={5}
				>
					<span className="ml-xxsm cursor-pointer">
						<InlineIcon className=" h-4 text-primary-300" icon={infoOutline} />
					</span>
				</Tippy>
			</div>
			{application?.documents?.collection?.length > 0 ? (
				<p className="mt-sm text-xsm font-bold text-primary-300">
					Fichiers importés
				</p>
			) : (
				<p className="mt-sm text-xsm font-bold text-primary-300">
					Aucun fichier importé
				</p>
			)}
			{application?.documents?.collection?.map((doc: MediaObjectType) => (
				<FilesPrimary
					isLoading={false}
					className="mt-xsm"
					title={doc.filePath}
					subtitle={`${moment(doc?.createdAt).format(
						"DD/MM/YYYY",
					)} ‧ ${doc.filePath.split(".")[1]?.toUpperCase()} ‧ ${Math.trunc(
						doc.size / 1000,
					)}Ko`}
					onView={() => {
						window.open(`${import.meta.env.VITE_S3}${doc?.filePath}`);
					}}
					onDownload={() => {}}
				/>
			))}
		</div>
	);
}
