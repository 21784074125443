import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { arrowDownOutline, arrowUpOutline } from "@assets/Icons";

import { AttentionDot } from "@containers/student/StudentDashboard/AttentionDot";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { ProgressBarSecondary } from "@designSystem/atoms/Progress";

import type { ProgressBaseType } from "@designSystem/atoms/Progress";

import { CircularProgressbar } from "react-circular-progressbar";
import { fetchStepsToCompleteData } from "./DashboardAPI";
import { Content } from "./StepsToCompletAccordion/Content";

const CV_TAB = 1;
const SEARCH_TAB = 2;
const STUDENT_JOURNEY_TAB = 3;

type StepsToCompletAccordionType = {
	setTabInformation: Function;
};

type ProgressCircleType = ProgressBaseType & {
	showValue: boolean;
	dimensions: number;
};

type DataType = {
	cv: boolean;
	formations: {
		paginationInfo: {
			totalCount: number;
		};
	};
	desiredJob: string | null;
	desiredStartOfWork: string | null;
	geoAreaOfResearch: string | null;
	applicationsCount: number;
	contactsCount: number;
	actionsCount: number;
};

export function StepsToCompletAccordion({
	setTabInformation,
}: StepsToCompletAccordionType) {
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState<DataType>();
	const [isLoading, setIsLoading] = useState(true);

	const [completion, setCompletion] = useState(0);

	const { user } = useContext(GlobalContext);

	const navigate = useNavigate();
	const calculateCompletion = (values: DataType) => {
		let completion = 0;

		if (values.cv) {
			completion += 20;
		}
		if (values.formations.paginationInfo.totalCount > 0) {
			completion += 20;
		}
		if (
			values.desiredJob &&
			values.desiredStartOfWork &&
			values.geoAreaOfResearch
		) {
			completion += 20;
		}
		if (values.applicationsCount > 0) {
			completion += 20;
		}
		if (values.contactsCount > 0) {
			completion += 10;
		}

		if (values.actionsCount > 0) {
			completion += 10;
		}
		setCompletion(completion);
		setIsLoading(false);
	};

	const fetchData = async () => {
		if (!user) return;
		const response = await fetchStepsToCompleteData(user.id);

		setData(response);
		calculateCompletion(response);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="rounded-lg bg-white px-sm py-md flex flex-col gap-md">
			<div className="flex items-center justify-between">
				<div className="flex flex-col md:flex-row gap-sm items-center">
					<div className="hidden md:flex">
						{isLoading ? (
							<Skeleton borderRadius={1000} width={64} height={64} />
						) : (
							<ProgressCircle
								showValue
								dimensions={64}
								progressValue={completion}
							/>
						)}
					</div>
					<ProgressBarSecondary
						label="Étapes à completer"
						className="flex flex-col md:hidden w-full"
						progressValue={completion}
						fullValue={100}
					/>
					<div>
						<div className="md:flex hidden gap-xxsm">
							<p className="font-bold">Étapes à compléter</p>
							{completion < 100 && <AttentionDot />}
						</div>
						<p className="text-xsm">
							Nous avons besoin de ces informations afin de te fournir une
							expérience personnalisée. Merci de les renseigner au plus vite !
						</p>
					</div>
				</div>
				<ProgressBarSecondary
					label="Étapes à completer"
					className="mr-sm hidden w-full"
					progressValue={completion}
					fullValue={100}
				/>

				<ButtonTertiary
					onClick={() => {
						setIsOpen(!isOpen);
					}}
					icon={isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>

			{isOpen && data && (
				<Content
					blocs={[
						{
							title: "Tes informations",
							actions: [
								{
									title: "Importer ton CV",
									isComplete: data.cv,
									onclick: () => {
										setTabInformation(CV_TAB);
										window.scrollTo({
											behavior: "smooth",
											top: 0,
										});
									},
								},
								{
									title: "Indiquer ton parcours",
									isComplete: data.formations.paginationInfo.totalCount > 0,
									onclick: () => {
										setTabInformation(STUDENT_JOURNEY_TAB);
										window.scrollTo({
											behavior: "smooth",
											top: 0,
										});
									},
								},
								{
									title: "Preciser ta recherche",
									isComplete:
										data.desiredJob !== null &&
										data.desiredStartOfWork !== null &&
										data.geoAreaOfResearch !== null,
									onclick: () => {
										setTabInformation(SEARCH_TAB);
										window.scrollTo({
											behavior: "smooth",
											top: 0,
										});
									},
								},
							],
						},
						{
							title: "Tes premiers pas",
							actions: [
								{
									title: "Créer ta première candidature spontanée",
									isComplete: data.applicationsCount > 0,
									onclick: () => {
										navigate("/applications");
									},
								},
								{
									title: "Ajouter un premier contact dans une candidature",
									isComplete: data.contactsCount > 0,
									onclick: () => {
										navigate("/applications");
									},
								},
								{
									title: "Créer une première action dans une candidature",
									isComplete: data.actionsCount > 0,
									onclick: () => {
										navigate("/applications");
									},
								},
							],
						},
					]}
				/>
			)}
		</div>
	);
}

function ProgressCircle({
	className = "",
	progressValue = 0,
	showValue = false,
	dimensions = 0,
}: ProgressCircleType) {
	return (
		<div
			style={{ height: dimensions, width: dimensions }}
			className={`progress-circle-container ${className}`}
		>
			<CircularProgressbar
				value={progressValue}
				text={showValue ? `${progressValue}%` : ""}
			/>
		</div>
	);
}
