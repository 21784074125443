import type { UserCompleteType } from "@/types/userTypes";
import { InlineIcon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import Tippy from "@tippyjs/react";
import React, { useContext, useEffect, useState } from "react";

import { emailOutline, infoOutline } from "@assets/Icons";

import { CV_COMMENT } from "@constants/CvBoard";

import { isEmpty } from "@tools/Objects";

import { Avatar } from "@designSystem/atoms/Avatar";
import { Badge } from "@designSystem/atoms/Badge";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Checkbox } from "@designSystem/atoms/Checkbox";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { FilesPrimary } from "@designSystem/molecules/Files";
import { success } from "@tools/Toasts";

import { CVBoardContext } from "@containers/school/CVBoard";
import { deleteFile, editUser } from "@containers/school/CVBoard/CVBoardAPI";

const MAX_COMMENT_LENGTH = 4000;

type ToValidateType = {
	student: UserCompleteType;
	load: boolean;
	fileUpload: {
		filePath?: string;
		id?: string;
		path: string;
		size: number;
		spsStudent?: string;
		importedByReferent?: boolean;
	};
	setFileUpload: Function;
	changeStep: (
		param: string,
		refusalMotive?: string,
		validMotive?: string,
	) => void;
};
type CvCommentType = {
	id: number;
	name: string;
	comment: string;
	active?: boolean;
};
type studentCommentType = {
	studentId: string;
	validComment?: string | undefined;
	refusalMotive?: string | undefined;
	statusCheck?: "editing" | "validated" | "";
	tagsSelected?: CvCommentType[] | undefined;
	[key: string]: string | CvCommentType[] | undefined;
};

export function ToValidate({
	student,
	fileUpload,
	setFileUpload,
	load,
	changeStep,
}: ToValidateType) {
	const { user } = useContext(GlobalContext);
	const { setCanSwitch, setCVs, CVs } = useContext(CVBoardContext);
	const [status, setStatus] = useState<string>(
		fileUpload.importedByReferent ? "validated" : "",
	);
	const [refusalMotive, setRefusalMotive] = useState<string>("");
	const [validComment, setValidComment] = useState<string>("");
	const [tagsSelected, setTagsSelected] = useState<CvCommentType[]>(CV_COMMENT);
	const resetComment = () => {
		setRefusalMotive("");
		setValidComment("");
	};
	const setStatusCheck = (studentComment: studentCommentType) => {
		switch (studentComment.statusCheck) {
			case "editing":
				return setStatus("editing");
			case "validated":
				return setStatus("validated");
			default:
				return setStatus("");
		}
	};

	const setDefaultStudentByCookie = () => {
		const studentsComment: studentCommentType[] = localStorage.getItem(
			"cvboardComment",
		)
			? JSON.parse(localStorage.getItem("cvboardComment")!)
			: null;

		const studentComment = studentsComment?.find(
			(elem) => elem.studentId === student.id,
		);

		const defaultTags = CV_COMMENT.map((elem) => {
			return { ...elem, active: false };
		});
		if (!studentComment || isEmpty(studentComment)) {
			setStatus("");
			resetComment();
			setTagsSelected(defaultTags);
		} else {
			setStatusCheck(studentComment);
			setTagsSelected(studentComment.tagsSelected ?? defaultTags);
			setValidComment(studentComment.validComment ?? "");
			setRefusalMotive(studentComment.refusalMotive ?? "");
		}
	};
	useEffect(() => {
		setDefaultStudentByCookie();

		if (student.id !== fileUpload?.spsStudent) {
			setFileUpload({
				id: undefined,
				size: 0,
				path: "",
			});
		}
	}, [student.id]);

	const modules = {
		toolbar: [["bold", "italic", "underline"], [{ list: "bullet" }]],
		clipboard: {
			matchVisual: false,
		},
	};

	const handleValidate = async () => {
		if (status === "editing") {
			success("Le CV est à modifier. L'étudiant sera notifié.");
			changeStep(status, refusalMotive, validComment);
		}
		if (status === "validated") {
			success("Vous avez validé le CV de l’étudiant.");

			changeStep(status, refusalMotive, validComment);
		}
		if (fileUpload.importedByReferent) {
			await editUser({ id: student.id, cv: fileUpload.id });
			changeStep("validated", refusalMotive, validComment);
			const updatedCVs = CVs.map((item) => {
				if (item.id === student.id) {
					return {
						...item,
						cv: {
							...item.cv,
							filePath: fileUpload.filePath,
							state: "validated",
						},
					};
				}
				return item;
			});
			setCVs(updatedCVs);
		}
	};

	const setCvBoardCookie = (key: string, comment = "") => {
		const studentsComment: studentCommentType[] = localStorage.getItem(
			"cvboardComment",
		)
			? JSON.parse(localStorage.getItem("cvboardComment")!)
			: [];
		const studentComment: studentCommentType = {
			studentId: student.id,
			[key]: comment,
		};

		const studentIndex = studentsComment.findIndex(
			(elem) => elem.studentId === student.id,
		);

		if (studentIndex !== -1) {
			studentsComment[studentIndex] = {
				...studentsComment[studentIndex],
				...studentComment,
			};

			if (key === "refusalMotive") {
				studentsComment[studentIndex] = {
					...studentsComment[studentIndex],
					...studentComment,
					tagsSelected: [...tagsSelected],
				};
			}
		} else {
			let defaultStudent = { ...studentComment };
			if (key === "refusalMotive") {
				defaultStudent = {
					...defaultStudent,
					tagsSelected: [...tagsSelected],
				};
			}
			studentsComment.push(defaultStudent);
		}

		if (key === "statusCheck") setCanSwitch(true);
		localStorage.setItem(
			"cvboardComment",
			JSON.stringify([...studentsComment]),
		);
	};
	return (
		<>
			<div className="mb-sm">
				<div className="mb-xsm flex items-center font-semibold text-primary-700P">
					<Checkbox
						className="!rounded-full"
						checked={fileUpload.path !== "" || status === "validated"}
						onEnabled={() => {
							setCvBoardCookie("statusCheck", "validated");
							setStatus("validated");
						}}
						onDisabled={() => {
							setCvBoardCookie("statusCheck");
							setStatus("");
						}}
					/>
					<div
						className="ml-xsm cursor-pointer text-center"
						onClick={() =>
							status === "validated" ? setStatus("") : setStatus("validated")
						}
					>
						Valider le CV
					</div>
				</div>
				<div className="flex items-center font-semibold text-primary-700P">
					<Checkbox
						className="!rounded-full"
						checked={status === "editing"}
						onEnabled={() => {
							setCvBoardCookie("statusCheck", "editing");
							setStatus("editing");
						}}
						onDisabled={() => {
							setCvBoardCookie("statusCheck");
							setStatus("");
						}}
					/>
					<div
						className="ml-xsm cursor-pointer text-center"
						onClick={() =>
							status === "editing" ? setStatus("") : setStatus("editing")
						}
					>
						CV à modifier
					</div>
				</div>
			</div>

			{status === "editing" ? (
				<div>
					<p className="font-bold">Motif à envoyer à l'étudiant</p>

					<p className="flex">
						Modèles de commentaire :
						<Tippy
							content="Cliquez sur un modèle pour l'ajouter au commentaire"
							theme="primary"
							animation="fade"
							placement="right"
							zIndex={60}
						>
							<span className="ml-xxsm cursor-pointer">
								<InlineIcon
									className="text-primary-200"
									height={16}
									width={16}
									icon={infoOutline}
								/>
							</span>
						</Tippy>
					</p>
					<div className="mt-sm flex flex-wrap gap-xsm">
						{tagsSelected?.map((tag) => {
							return (
								<Badge
									label={tag.name}
									bgColor={tag.active ? "bg-emerald-light" : "bg-stone-light"}
									className="mb-xsm"
									hasBorder
									onClick={(elem) => {
										const tagsSelectedCpy = [...tagsSelected];
										const targetTag = tagsSelectedCpy?.find(
											(item) => item.name === elem,
										);

										if (targetTag) {
											targetTag.active = !targetTag.active;

											const activeComments = tagsSelectedCpy
												?.filter((tag) => tag.active)
												?.map((tag) => tag.comment);

											const containsRefusalMotive = activeComments?.some(
												(comment) =>
													comment.includes(
														refusalMotive.replace(/<[^>]*>/g, ""),
													),
											);

											if (
												!containsRefusalMotive &&
												activeComments.length === 0
											) {
												activeComments?.shift();
											}

											setTagsSelected(tagsSelectedCpy);

											const formattedRefusalMotive = activeComments?.join(
												activeComments[0]?.length > 0 ? "</br></br>" : "",
											);

											setRefusalMotive(formattedRefusalMotive);
											setCvBoardCookie("refusalMotive", formattedRefusalMotive);
										}
									}}
								/>
							);
						})}
					</div>
					<label htmlFor="comment">
						Commentaire*
						<RichTextEditor
							maxLength={MAX_COMMENT_LENGTH}
							id="comment"
							className="[&_.ql-active]:rounded [&_.ql-active]:!bg-primary-150 [&_.ql-container]:!min-h-[150px] [&_.ql-container]:!text-[16px] [&_.ql-container]:text-sm [&_.ql-container]:text-primary-700P"
							modules={modules}
							placeholder="Votre commentaire ici..."
							value={refusalMotive}
							onChange={(comment) => {
								const newTagsSelected = tagsSelected?.map((selection) => {
									return {
										...selection,
										active: comment
											.replace(/<[^>]*>/g, "")
											.includes(selection.comment),
									};
								});

								setTagsSelected(newTagsSelected);
								setRefusalMotive(comment);
							}}
							onBlur={(comment) => {
								setCanSwitch(true);
								setCvBoardCookie("refusalMotive", comment);
							}}
							onFocus={() => {
								setCanSwitch(false);
							}}
						/>
					</label>

					<div className="mb-md mt-xsm flex">
						<span className="my-auto text-xs font-bold text-primary-700P">
							Signé par :
						</span>
						<Avatar
							className="mx-1 my-auto"
							image={user!.avatar?.filePath}
							firstname={user!.firstname}
							lastname={user!.lastname}
							size="xxs"
						/>{" "}
						<span className="wg-no-translate my-auto text-xs font-bold text-primary-700P">
							{user!.firstname} {user!.lastname}
						</span>
					</div>
				</div>
			) : status === "validated" ? (
				<div>
					<p className="mb-xsm font-bold">Commentaire pour l’étudiant</p>
					<RichTextEditor
						id="comment"
						maxLength={MAX_COMMENT_LENGTH}
						modules={modules}
						className="[&_.ql-active]:rounded [&_.ql-active]:!bg-primary-150 [&_.ql-container]:!min-h-[150px] [&_.ql-container]:!text-[16px] [&_.ql-container]:text-sm [&_.ql-container]:text-primary-700P"
						placeholder="Votre commentaire ici..."
						value={validComment}
						onBlur={(comment) => {
							setCanSwitch(true);
							setCvBoardCookie("validComment", comment);
						}}
						onFocus={() => {
							setCanSwitch(false);
						}}
						onChange={(e) => {
							setValidComment(e);
						}}
					/>
					<div className="mb-md mt-xsm flex">
						<span className="my-auto text-xs font-bold text-primary-700P">
							Signé par :
						</span>
						<Avatar
							className="mx-1 my-auto"
							image={user!.avatar?.filePath}
							firstname={user!.firstname}
							lastname={user!.lastname}
							size="xxs"
						/>{" "}
						<span className="wg-no-translate my-auto text-xs font-bold text-primary-700P">
							{user!.firstname} {user!.lastname}
						</span>
					</div>
				</div>
			) : null}

			{fileUpload.path ? (
				<>
					{" "}
					<p className="mb-sm">
						Votre étudiant a modifié son CV et vous l’a transmis directement ?
						<br />
						Vous pouvez le déposer à sa place ci-dessous.
					</p>
					<FilesPrimary
						isLoading={load}
						title={fileUpload.path}
						subtitle={`${Math.floor(fileUpload.size / 1000)} Ko`}
						className="mb-sm mt-3"
						onView={() => {
							if (fileUpload) {
								window.open(`${import.meta.env.VITE_S3}${fileUpload.filePath}`);
							}
						}}
						onDelete={async () => {
							await editUser({
								id: student.id,
								cv: null,
							});
							const result = await deleteFile({
								id: fileUpload.id,
							});

							if (result) {
								setFileUpload({
									size: 0,
									path: "",
								});
								changeStep("editing");
								success("CV supprimé avec succès.");
							}
						}}
					/>
				</>
			) : (
				<>
					{(status === "validated" || status === "editing") && (
						<p className="mb-sm">
							En poursuivant, l’étudiant sera notifié par mail de votre choix.
						</p>
					)}
				</>
			)}

			<div className="flex justify-end">
				<ButtonPrimary
					disabled={
						(status === "editing" &&
							refusalMotive.replace(/<[^>]*>/g, "").length === 0) ||
						(status === "" && !fileUpload.importedByReferent) ||
						load ||
						(status === "validated" &&
							validComment.length > MAX_COMMENT_LENGTH) ||
						(status === "editing" && refusalMotive.length > MAX_COMMENT_LENGTH)
					}
					onClick={handleValidate}
					icon={emailOutline}
					label="Envoyer"
				/>
			</div>
		</>
	);
}
