import React from "react";
import { useNavigate } from "react-router";

import accessLimited from "@assets/illustrations/access_limited.svg";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

export function AccountDisabled() {
	const navigate = useNavigate();
	return (
		<main className="flex h-[calc(100vh)] flex-col items-center justify-center gap-2 pb-0 md:h-[calc(100vh-178px)] md:gap-md">
			<img src={accessLimited} alt="step" />
			<p className="text-xlg font-bold">Accès limité</p>
			<div className="text-center text-xxsm text-primary-300 md:text-xsm">
				<p>
					Il semblerait que tu n’aies pas accès à cette page. Merci de contacter
					directement ton école.
				</p>
			</div>
			<ButtonPrimary
				label="Retourner à l'accueil"
				className="mt-5 w-full md:mt-0 md:w-fit"
				onClick={() => {
					navigate("/");
				}}
			/>
		</main>
	);
}
