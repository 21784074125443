import DOMPurify from "dompurify";
import React from "react";

import "../ResourceDetail.scss";

import { Divider } from "@designSystem/atoms/Divider";
import { PdfViewer } from "@designSystem/atoms/PdfViewer";

export function Content({ resource }) {
	return (
		<div className="content-container mt-md w-full">
			<p className="wg-no-translate title">{resource?.title}</p>
			<p className="wg-no-translate description">{resource?.description}</p>
			<Divider className="mb-lg mt-lg" />
			<div
				className="wg-no-translate"
				id="break-line"
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(resource?.content, {
						ADD_TAGS: ["iframe"],
						ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
					}),
				}}
			/>
			{resource?.pdf && (
				<PdfViewer
					title="resourcePdf"
					className="mt-lg"
					src={`${import.meta.env.VITE_S3}${resource?.pdf?.filePath}`}
				/>
			)}
		</div>
	);
}
