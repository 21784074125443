import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
	STUDENT_GENDER_OPTIONS,
	STUDENT_STATUS_OPTIONS,
} from "@constants/Users";

import { checkPhone } from "@tools/Checkers";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { ComboboxSearchAddress } from "@designSystem/molecules/ComboboxSearchAddress";
import { Input } from "@designSystem/molecules/Input";
import { InputPhoneNumber } from "@designSystem/molecules/PhoneInput";
import { DatePicker } from "@designSystem/organisms/DatePicker";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";
import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";

import { editUser } from "@containers/school/Students/StudentDetail/StudentDetailAPI";

import { Switch } from "@designSystem/atoms/Switch";
import { success } from "@tools/Toasts";

export function FormikStudentProfile({
	studentCpyProps,
	currentStatusProps,
	show,
	onClose,
	promotions,
	defaultPromotionProps,
}) {
	const { studentCpy, setStudentCpy } = studentCpyProps;
	const { currentStatus, setCurrentStatus } = currentStatusProps;
	const { defaultPromotion, setDefaultPromotion } = defaultPromotionProps;
	const [_mailError, _setMailError] = useState("");
	const [loading, setLoading] = useState(false);
	const [showGallia, setShowGallia] = useState(false);

	useEffect(() => {
		setShowGallia(studentCpy.campus?.isGalliaEnabled ?? false);
	}, [studentCpy.campus?.isGalliaEnabled]);

	const modifyStudent = async (values) => {
		const data = {
			firstname: values.firstname,
			lastname: values.lastname,
			birthdate: values.birthdate,
			phone: values.phone,
			status: values.status,
			id: values.id,
			gender: values.gender,
			portfolio: values.portfolio,
			linkedin: values.linkedin,
			spsPromotion: values.promotionId,
			customLink1: values.customLink1,
			customLink2: values.customLink2,
			desiredJob: studentCpy.desiredJob,
			desiredStartOfWork: studentCpy.desiredStartOfWork,
			geoAreaOfResearch: studentCpy.geoAreaOfResearch,
			exportToGallia: values.exportToGallia,
			address: values.address,
			streetNumber: values.streetNumber,
			streetName: values.streetName,
			city: values.city,
			postalCode: values.postalCode,
			longitude: values.longitude,
			latitude: values.latitude,
		};

		await editUser(data);

		const updatedStudent = {
			...studentCpy,
			...data,
			spsPromotion: {
				id: values.promotionId,
				name: values.promotionName,
			},
			phone: data.phone?.replace("+", ""),
		};
		setStudentCpy(updatedStudent);
		success("L'étudiant a bien été modifié");
	};
	return (
		<div>
			<Formik
				initialValues={{
					id: studentCpy?.id,
					firstname: studentCpy?.firstname,
					lastname: studentCpy?.lastname,
					birthdate: studentCpy?.birthdate
						? new Date(studentCpy.birthdate)
						: null,
					promotionName: studentCpy?.spsPromotion?.name,
					promotionId: studentCpy?.spsPromotion?.id,
					portfolio: studentCpy?.portfolio,
					linkedin: studentCpy?.linkedin,
					phone: studentCpy?.phone
						? studentCpy?.phone.includes("+")
							? studentCpy?.phone
							: `+${studentCpy?.phone}`
						: null,
					gender: studentCpy?.gender,
					status: currentStatus?.value,
					customLink1: studentCpy?.customLink1,
					customLink2: studentCpy?.customLink2,
					desiredJob: studentCpy.desiredJob,
					desiredStartOfWork: studentCpy.desiredStartOfWork,
					geoAreaOfResearch: studentCpy.geoAreaOfResearch,
					exportToGallia: false,
					address: studentCpy?.address,
					streetNumber: studentCpy?.streetNumber,
					streetName: studentCpy?.streetName,
					city: studentCpy?.city,
					postalCode: studentCpy?.postalCode,
					longitude: studentCpy?.longitude,
					latitude: studentCpy?.latitude,
				}}
				onSubmit={(values, { resetForm }) => {
					setLoading(true);
					modifyStudent(values)
						.then(() => {
							resetForm({});
						})
						.finally(() => {
							setLoading(false);
							onClose();
						});
				}}
				enableReinitialize
				validate={(values) => {
					const errors = {};
					if (!values.firstname) {
						errors.firstname = "Le prénom de l'étudiant n'est pas renseigné";
					}
					if (!values.lastname) {
						errors.lastname = "Le nom de l'étudiant n'est pas renseigné";
					}
					if (values.phone && !checkPhone(values.phone)) {
						errors.phone = "Numéro de téléphone invalide";
					}

					if (values.gender === -1) {
						errors.gender = "Veuillez entrer un genre";
					}
					return errors;
				}}
			>
				{({
					handleChange,
					values,
					resetForm,
					handleSubmit,
					errors,
					setFieldValue,
				}) => (
					<div>
						<div>
							<div>
								{show && (
									<section className="profile-bottom relative">
										<div className="flex justify-center">
											<SelectSecondary
												className="mt-sm"
												defaultValue={() => {
													return STUDENT_STATUS_OPTIONS.find(
														(x) => x.value === currentStatus.value,
													);
												}}
												name="status"
												options={STUDENT_STATUS_OPTIONS}
												onChange={(value) => {
													setCurrentStatus(value);
												}}
											/>
										</div>
										<Input
											className="mt-xsm"
											placeholder="Prénom"
											label="Prénom"
											name="firstname"
											onChange={handleChange}
											value={values.firstname}
											errorMessage={errors.firstname}
										/>
										<Input
											className="mt-sm"
											placeholder="Nom"
											label="Nom"
											name="lastname"
											onChange={handleChange}
											value={values.lastname}
											errorMessage={errors.lastname}
										/>
										<SelectTertiary
											position="right"
											label="Promotion"
											placeholder="Promotion"
											className="mt-sm"
											defaultValue={defaultPromotion}
											options={promotions}
											onChange={(e) => {
												setFieldValue("promotionName", e.value);
												setFieldValue("promotionId", e.id);
												if (e.campus.isGalliaEnabled) {
													setShowGallia(true);
												} else if (showGallia) {
													setShowGallia(false);
													setFieldValue("exportToGallia", false);
												}
												setDefaultPromotion(e);
											}}
										/>
										<DatePicker
											withIcon
											label="Date de naissance"
											className="mt-sm"
											onDateChange={(value) => {
												setFieldValue("birthdate", value);
											}}
											selectedDate={values.birthdate}
										/>
										<SelectTertiary
											className="h-fit-content mt-md"
											defaultValue={values.gender}
											label="Genre"
											onChange={(gender) => {
												setFieldValue("gender", gender.value);
											}}
											options={STUDENT_GENDER_OPTIONS}
										/>
										<ComboboxSearchAddress
											className="address mt-sm items-center"
											label="Adresse"
											placeholder="Ex : 5 rue Rivoli"
											name="address"
											value={values.address}
											onChange={({
												streetNumber,
												streetName,
												city,
												postalCode,
												searchValue,
												longitude,
												latitude,
											}) => {
												setFieldValue("address", searchValue);
												setFieldValue("streetNumber", streetNumber?.longName);
												setFieldValue("streetName", streetName?.longName);
												setFieldValue("city", city?.longName);
												setFieldValue("postalCode", postalCode?.longName);
												setFieldValue("longitude", longitude);
												setFieldValue("latitude", latitude);
											}}
										/>
										<Input
											className="mt-sm"
											placeholder="exemple@exemple.com"
											label="Email"
											name="email"
											disabled
											value={studentCpy.email}
										/>
										<InputPhoneNumber
											label="Téléphone"
											value={values.phone}
											onChange={(phoneNumber) => {
												setFieldValue("phone", phoneNumber);
											}}
											required={false}
											className="mt-sm w-full"
											onBlur={(e) => setFieldValue("phone", e)}
										/>

										<Input
											value={values.linkedin}
											className="mt-sm"
											label="Lien du profil Linkedin"
											placeholder="Ex: linkedin.com/in/johndoe/"
											name="linkedin"
											onChange={handleChange}
										/>
										<Input
											value={values.portfolio}
											className="mt-sm"
											label="Lien du portfolio"
											placeholder="Ex: mywebsite.com"
											name="portfolio"
											onChange={handleChange}
										/>
										<Input
											value={values.customLink1}
											className="mt-sm"
											label="Lien externe 1"
											placeholder="Ex: https://"
											name="customLink2"
											onChange={(e) => {
												setFieldValue("customLink1", e.target.value);
											}}
										/>
										<Input
											value={values.customLink2}
											className="mt-sm"
											label="Lien externe 2"
											placeholder="Ex: https://"
											name="customLink2"
											onChange={(e) => {
												setFieldValue("customLink2", e.target.value);
											}}
										/>
										{showGallia && (
											<div className="mt-md flex items-center">
												<Switch
													checked={values.exportToGallia}
													onDisabled={() => {
														setFieldValue("exportToGallia", false);
													}}
													onEnabled={() => {
														setFieldValue("exportToGallia", true);
													}}
												/>
												<p className="ml-xsm text-xsm text-primary-700P">
													Importer l'étudiant dans Gallia
												</p>
											</div>
										)}
										<div className="flex justify-center">
											<div className="w-fit-content mt-xlg flex justify-center">
												<ButtonSecondary
													label="Annuler"
													onClick={() => {
														resetForm();
														onClose();
													}}
												/>
												<ButtonPrimary
													disabled={loading}
													onClick={handleSubmit}
													className="ml-sm"
													label="Enregistrer"
												/>
											</div>
										</div>
									</section>
								)}
							</div>
						</div>
					</div>
				)}
			</Formik>
		</div>
	);
}

FormikStudentProfile.propTypes = {
	studentCpyProps: PropTypes.shape({
		studentCpy: PropTypes.shape({
			id: PropTypes.string,
			desiredJob: PropTypes.string,
			desiredStartOfWork: PropTypes.string,
			geoAreaOfResearch: PropTypes.string,
			firstname: PropTypes.string,
			lastname: PropTypes.string,
			avatar: PropTypes.shape({
				filePath: PropTypes.string,
				id: PropTypes.string,
			}),
			spsPromotion: PropTypes.shape({
				name: PropTypes.string,
				id: PropTypes.string,
			}),
			portfolio: PropTypes.string,
			linkedin: PropTypes.string,
			address: PropTypes.string,
			phone: PropTypes.string,
			email: PropTypes.string,
			customLink1: PropTypes.string,
			customLink2: PropTypes.string,
		}).isRequired,
		setStudentCpy: PropTypes.func,
	}).isRequired,
	currentStatusProps: PropTypes.shape({
		currentStatus: PropTypes.shape({
			value: PropTypes.string,
		}),
		setCurrentStatus: PropTypes.func,
	}).isRequired,
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	promotions: PropTypes.arrayOf({}).isRequired,
	defaultPromotionProps: PropTypes.shape({
		defaultPromotion: PropTypes.string,
		setDefaultPromotion: PropTypes.func,
	}).isRequired,
};
