import PropTypes from "prop-types";
import React from "react";

import "../StudentDetail.scss";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { TagsFinder } from "@designSystem/organisms/TagsFinder/TagsFinder";

export function Tags({ schoolTags, editStudentTags, tags }) {
	return (
		<div className="tags-container w-full">
			<h3 className="flex justify-start items-center gap-1">
				Tags
				<TooltipElement id="student-tags" type="info" />
				<TooltipContainer
					anchorId="#student-tags"
					children="Visible uniquement par votre école"
				/>
			</h3>
			<section>
				<TagsFinder
					placeholder="Rechercher un tag..."
					defaultOptions={schoolTags}
					options={tags
						?.filter((x) => x.type === "school")
						.sort((a, b) => a.name.localeCompare(b.name))}
					onChange={(tags) => {
						editStudentTags("school", tags);
					}}
					onDelete={(tags) => {
						editStudentTags("school", tags);
					}}
					errorMessage={schoolTags?.length === 0 && "Aucun tag renseigné"}
				/>
			</section>
		</div>
	);
}

Tags.propTypes = {
	schoolTags: PropTypes.arrayOf({}).isRequired,
	editStudentTags: PropTypes.func.isRequired,
	tags: PropTypes.arrayOf({}).isRequired,
};
