import useDebounce from "@hooks/useDebounce";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

import { plusOutline, refreshFill, searchOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";

import { SpontaneousApplicationContext } from "..";

import { CreateOrganizationModal } from "./Head/CreateOrganizationModal";
import { OrganizationsFilters } from "./Head/OrganizationsFilters";

export function Head() {
	const { DEFAULT_FILTERS, filters, setFilters, loading } = useContext(
		SpontaneousApplicationContext,
	);
	const { currentInterface }: { setTitle: Function; currentInterface: string } =
		useOutletContext();

	const [showCreateOrganizationModal, setShowCreateOrganziationModal] =
		useState(false);
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search);

	useEffect(() => {
		if (!loading) {
			setFilters({ ...filters, name: search, page: 1 });
		}
	}, [debouncedValue]);

	return (
		<section className="flex flex-col gap-md">
			<div className="flex flex-col-reverse justify-between gap-sm md:flex-row">
				<div className="flex w-full gap-sm">
					<InputLargePrimary
						type="search"
						placeholder="Rechercher..."
						className="w-fit bg-white"
						icon={searchOutline}
						value={filters.name ? filters.name : ""}
						onChange={(e) => {
							e.target.value = e.target.value.replace(";", "");
							setSearch(e.target.value);
						}}
					/>
					<ButtonPrimary
						label=""
						icon={refreshFill}
						onClick={() => {
							setFilters(DEFAULT_FILTERS);
						}}
					/>
				</div>
				{currentInterface === "school" && (
					<ButtonPrimary
						label="Ajouter une entreprise"
						icon={plusOutline}
						onClick={() => {
							setShowCreateOrganziationModal(true);
						}}
					/>
				)}
			</div>
			<OrganizationsFilters />
			<CreateOrganizationModal
				show={showCreateOrganizationModal}
				onClose={() => {
					setShowCreateOrganziationModal(false);
				}}
			/>
		</section>
	);
}
