import {
	stringifyRequestFilters,
	stringifyRequestInputs,
} from "@tools/Objects";

import api, { graphqlRequestConstructor } from "@tools/API";

export async function getCampus(school: string) {
	let rep = {};
	const data = {
		query: `
      query {
      campuses (school: "${school}", itemsPerPage:1000) {
        collection {
          id
          name
        }
      }
    }
  `,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getFilteredPromotions(view: any, filters: any) {
	let rep = {};
	const data = {
		query: `
      query {
        promotions (${stringifyRequestFilters({ ...view, ...filters })}) {
          collection{
              id
              name
              campus {
                id
                name
              }
              archived
              toPlace
              placed
              surrend
              applications
              interviews
              relaunchs
          },
          paginationInfo {
            totalCount
            lastPage
            itemsPerPage
          }
        }
    }
  `,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function createPromotion(inputs: any) {
	let rep = {};
	const data = {
		query: `mutation {
            createPromotion(input: ${stringifyRequestInputs(inputs)}) {
              promotion {
                  id
              }
            }
          }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function editPromotion(inputs: any) {
	const data = {
		query: `mutation {
            updatePromotion(input: ${stringifyRequestInputs(inputs)}) {
              promotion {
                id
              }
            }
          }`,
	};

	const rep = await graphqlRequestConstructor(data, "editPromotion");
	return rep;
}
