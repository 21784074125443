import { useAppSelector } from "@config/redux/hook";
import { PdfViewer } from "@designSystem/atoms/PdfViewer";
import { RichTextPreview } from "@designSystem/atoms/RichTextPreview";
import React from "react";
import Skeleton from "react-loading-skeleton";

export function Description() {
	const { offer } = useAppSelector((state) => state.offerDetail);
	if (!offer) {
		return (
			<div className="content mb-lg mr-sm">
				<Skeleton width="200px" height="20px" />
			</div>
		);
	}

	return (
		<div className="content flex w-full flex-col rounded-lg bg-white p-md md:w-9/12">
			<p className="text-sm font-bold text-primary-700P">
				Description de l’offre
			</p>
			<div className="mt-lg">
				<RichTextPreview unsanitizedText={offer.description} />
				{offer.pdf ? (
					<PdfViewer
						title="Offerpdf"
						className="offer-pdf mt-lg"
						src={`${import.meta.env.VITE_S3}${offer?.pdf?.filePath}`}
					/>
				) : (
					""
				)}
			</div>
		</div>
	);
}
