import $ from "jquery";
import React, { type MouseEvent } from "react";
import { useEffect, useState } from "react";

import "@assets/sass/TabGroup.scss";

import "@assets/sass/Tabs.scss";

let uniqueId = 0;

export type ScrollableTab = {
	label: string;
	active?: boolean;
	subLabel?: number | string;
	display?: boolean;
	state?: string | number;
};

type ScrollableTabGroupPropsType = {
	tabs: ScrollableTab[];
	isLoading?: boolean;
	className?: string;
	sizeSwitch?: "sm" | "md" | "slg" | "lg" | "l";

	onTabChange?: (tab: number) => void;
};

export function ScrollableTabGroup({
	isLoading = false,
	tabs,
	className = "",
	sizeSwitch = "md",
	onTabChange = () => {},
}: ScrollableTabGroupPropsType) {
	const [tabsList, setTabList] = useState([...tabs]);
	const [componentId] = useState(() => uniqueId++);
	const [sizes] = useState({
		desktop: `${sizeSwitch}:flex`,
		mobile: `${sizeSwitch}:hidden`,
	});

	useEffect(() => {
		setTabList([...tabs]);
	}, [tabs]);

	const changeTab = (tabIndex: number) => {
		if (isLoading) return;
		$(`.tabgroup-${componentId} .scrollable-tab-secondary`).each(function (i) {
			if (i === tabIndex) $(this).addClass("active");
			else $(this).removeClass("active");
		});

		if (onTabChange) onTabChange(tabIndex);
	};

	const scrollToElement = (e: MouseEvent) => {
		const frames = document.getElementById(`tabgroup-${componentId}`);
		let target: HTMLElement = e.target as HTMLElement;
		while (!target.classList.contains("item-tab") && target.parentElement) {
			target = target.parentElement;
		}
		frames!.scrollLeft = target.offsetLeft - 30;
	};

	return (
		<>
			<div
				className={`hidden border-b tabgroup-${componentId} ${className} ${sizes.desktop}`}
			>
				{tabsList.map((tab, i) => {
					return (
						<ScrollableTabPrimary
							key={i}
							onClick={() => {
								changeTab(i);
							}}
							label={tab.label}
							subLabel={tab.subLabel}
							display={tab.display}
							active={tab.active}
						/>
					);
				})}
			</div>
			<div
				id={`tabgroup-${componentId}`}
				className={`tab-group flex overflow-x-scroll tabgroup-${componentId} ${className} ${sizes.mobile}`}
			>
				{tabsList.map((tab, i) => {
					return (
						<div key={i} className="item-tab min-w-fit whitespace-nowrap">
							<ScrollableTabSecondary
								onClick={(e: any) => {
									changeTab(i);
									scrollToElement(e!);
								}}
								label={tab.label}
								className={`${tab.active && "active"}`}
							/>
						</div>
					);
				})}
			</div>
		</>
	);
}

type TabType = {
	label: string;
	className?: string;
	active?: boolean;
	onClick: (e?: MouseEvent<HTMLElement>) => void;
};
type ScrollableTabPrimaryType = TabType & {
	subLabel?: number | string;
	display?: boolean;
};

function ScrollableTabPrimary({
	label,
	subLabel = undefined,
	className = "",
	active = false,
	onClick = () => {},
	display = true,
}: ScrollableTabPrimaryType) {
	if (!display) return null;

	return (
		<div
			data-id="scrollableTabPrimary"
			role="tab"
			tabIndex={0}
			onClick={onClick}
			className={`${className} font-semibold cursor-pointer rounded-t-sm  px-sm pb-[6px] pt-xxsm text-center text-sm transition-all hover:bg-primary-150 ${
				active
					? "border-b-2 border-primary-700P text-primary-700P "
					: "first-letter:border-primary-100 text-primary-300 mb-[1px]"
			}`}
		>
			<p className="truncate">
				{label}{" "}
				{subLabel !== undefined && (
					<span
						className={active ? "text-primary-700P" : "text-primary-200"}
					>{`(${subLabel})`}</span>
				)}
			</p>
		</div>
	);
}

function ScrollableTabSecondary({
	label,
	className = "",
	active = false,
	onClick = () => {},
}: TabType) {
	return (
		<div
			data-id="scrollableTabSecondary"
			role="tab"
			tabIndex={0}
			onClick={onClick}
			className={`scrollable-tab-secondary ${className} ${active && "active"}`}
		>
			<p className="text-primary-300">{label}</p>
		</div>
	);
}
