import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import { littleConfetti } from "@tools/Confetti";

import { errorToast } from "@tools/Toasts";

import { editApplication } from "@containers/student/Applications/core/api/ApplicationsManagementAPI";

import { Column } from "./ColumnsView/Column";
import { ColumnsViewLoader } from "./ColumnsView/ColumnsViewLoader";
import { useAppplicationsContext } from "./context";

import "react-loading-skeleton/dist/skeleton.css";

export function ColumnsView({ applicationsProps, load, getApplications }) {
	const applicationsContext = useAppplicationsContext();
	const { applications, setApplications } = applicationsProps;
	const [currentDestinationColumnName, setCurrentDestinationColumnName] =
		useState("");
	const columns = [
		{
			title: "En préparation",
			step: "readyToApply",
			className: "border-b-aquamarine-light",
			description: [
				"Les candidatures que tu mets ici sont celles auxquelles tu veux postuler.",
				<br />,
				<br />,
				"Tu peux glisser et déposer tes cartes d’une colonne à une autre et cliquer sur une carte pour voir son détail, ajouter un contact, créer des actions, ...",
			],
		},
		{
			title: "Candidature envoyée",
			titles: "Candidatures envoyées",
			step: "sent",
			className: "border-b-lavender-light",
			description: [
				"Quand tu as envoyé une candidature, place-là ici !",
				<br />,
				"Si tu as postulé à une offre de ton école, elle sera directement placée dans cette colonne.",
			],
		},
		{
			title: "Relance réalisée",
			titles: "Relances réalisées",
			step: "relaunch",
			className: "border-b-sunflower-light",
			description: [
				"Dès que tu relances une entreprise, mets ta candidature dans cette colonne.",
				<br />,
				<br />,
				"Nous conseillons de relancer après 7 jours sans réponse. Crée une action pour t’en souvenir !",
			],
		},
		{
			title: "Entretien obtenu",
			titles: "Entretiens obtenus",
			step: "interview",
			className: "border-b-emerald-light",
			description: [
				"Tu as obtenu un entretien ? Félicitations ! Déplace ta candidature dans cette colonne et prépare ton entretien.",
				<br />,
				<br />,
				"Si tu cliques sur une carte, tu retrouveras un onglet “Entretien”.",
			],
		},
	];

	const moveApplicationFrontside = (
		applicationIndex,
		sourceColumnName,
		destinationColumnName,
	) => {
		setCurrentDestinationColumnName(destinationColumnName);
		if (sourceColumnName === undefined || destinationColumnName === undefined) {
			return;
		}
		const updatedSourceColumn = applications[sourceColumnName];
		const updatedDestinationColumn = applications[destinationColumnName];

		updatedDestinationColumn.unshift({
			...updatedSourceColumn[applicationIndex],
			step: destinationColumnName,
		});

		updatedSourceColumn.splice(applicationIndex, 1);

		const applicationsCpy = {
			...applications,
		};

		applicationsCpy[sourceColumnName] = updatedSourceColumn;
		applicationsCpy[destinationColumnName] = updatedDestinationColumn;

		setApplications(applicationsCpy);
	};

	const onDragEnd = async ({ draggableId, source, destination }) => {
		if (source.droppableId === undefined) return;
		if (
			destination === null ||
			applications[source.droppableId] === undefined
		) {
			return;
		}

		if (source.droppableId === destination.droppableId) return;
		if (destination.droppableId === "interview") {
			littleConfetti();
		}

		moveApplicationFrontside(
			source.index,
			source.droppableId,
			destination.droppableId,
		);

		const application = applications[destination.droppableId][source.index];
		const appData = {
			id: draggableId,
			step: destination.droppableId,
			sentDate: application?.sentDate,
			relaunchDate: application?.relaunchDate,
			interviewDate: application?.interviewDate,
		};

		if (destination.droppableId === "sent") {
			appData.sentDate = new Date();
			appData.relaunchDate = application?.relaunchDate;
			appData.interviewDate = application?.interviewDate;
		}
		if (destination.droppableId === "relaunch") {
			appData.relaunchDate = new Date();
			appData.sentDate = application?.sentDate;
			appData.interviewDate = application?.interviewDate;
		}
		if (destination.droppableId === "interview") {
			appData.interviewDate = new Date();
			appData.sentDate = application?.sentDate;
			appData.relaunchDate = application?.relaunchDate;
		}
		const editApplicationResponse = await editApplication(appData);
		if (editApplicationResponse.responseLabel !== "success") {
			errorToast("Erreur lors de la mise à jour");
		} else {
			applicationsContext.setApplicationData({ ...application, ...appData });
		}
	};

	const optimisticRemoveApplication = (applicationId, step) => {
		const updatedColumn = applications[step].filter(
			(application) => application.id !== applicationId,
		);

		const applicationsCpy = {
			...applications,
		};

		applicationsCpy[step] = updatedColumn;

		setApplications(applicationsCpy);
	};

	return (
		<div className="flex overflow-x-scroll scrollbar-hide md:overflow-hidden">
			{load ? (
				columns?.map((column) => {
					return (
						<ColumnsViewLoader
							title={column.title}
							className={column.className}
							description={column.description}
							key={column.title}
						/>
					);
				})
			) : (
				<DragDropContext onDragEnd={onDragEnd}>
					{columns?.map((column, index) => {
						return (
							<Column
								title={column.title}
								index={index}
								className={column.className}
								step={column.step}
								description={column.description}
								applications={applications[column.step]}
								moveApplication={onDragEnd}
								getApplications={getApplications}
								currentDestinationColumnName={currentDestinationColumnName}
								optimisticRemoveApplication={optimisticRemoveApplication}
							/>
						);
					})}
				</DragDropContext>
			)}
		</div>
	);
}
