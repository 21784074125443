import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "./Parameters.scss";

import { SUPPORTED_LANGUAGES } from "@constants/Locales";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { errorToast, success } from "@tools/Toasts";

import {
	changePassword,
	editCoreUser,
	editUser,
	getSchoolSmsCredits,
} from "@containers/shared/Parameters/ParametersAPI";
import { Divider } from "@designSystem/atoms/Divider";
import { InputDoublePassword } from "@designSystem/molecules/InputDoublePassword";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";
import { changeAppLanguage } from "@tools/ChangeAppLanguage";
import { getCookie } from "@tools/Cookies";

export function Parameters() {
	const { user, setUser } = useContext(GlobalContext);
	const { setTitle }: { setTitle: (title: string) => void } =
		useOutletContext();
	const [userInterface] = useState(getCookie("interface"));
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordVerify, setNewPasswordVerify] = useState("");
	const [language, setLanguage] = useState("fr");
	const [smsCredits, setSmsCredits] = useState(0);
	const [load, setLoad] = useState(false);

	const languageOptions = SUPPORTED_LANGUAGES;
	const updatePassword = async () => {
		const data = {
			newPassword,
			newPasswordVerify,
		};
		try {
			await changePassword(data);
			success("Ton mot de passe a bien été modifié");
			setNewPassword("");
			setNewPasswordVerify("");
		} catch {
			errorToast("Une erreur est survenue");
		}
	};

	const changeLanguage = async (language: string) => {
		if (!user) return;
		const data = {
			id: user?.coreUser,
			choosedLanguage: language,
		};
		setLoad(true);
		try {
			await editCoreUser(data);
			user.choosedLanguage = language;
			success("La langue a bien été changée.");
		} catch (_e) {
			errorToast("Une erreur est survenue.");
		} finally {
			changeAppLanguage(language);
			setLanguage(language);
			setLoad(false);
		}
	};
	const getSmsCredits = async () => {
		try {
			const response: { school?: { smsCredits: number } } =
				await getSchoolSmsCredits(user?.school);
			setSmsCredits(response.school?.smsCredits || 0);
		} catch {
			errorToast("Une erreur est survenue");
		}
	};

	useEffect(() => {
		setTitle("Paramètres");
		if (user) {
			setLanguage(user.choosedLanguage || "fr");
			getSmsCredits();
		}
	}, [user]);

	const handleReinitializeOnboarding = async () => {
		const initOnboarding = {
			onboardingSteps: "0000",
		};
		const response = await editUser({
			id: user?.id,
			...initOnboarding,
		});

		if (response) {
			success('"Mes premiers pas" a bien été réinitialisé');
			setUser({
				...user,
				...initOnboarding,
			});
		}
	};

	return (
		<main className="parameters-container w-fullp-0">
			<section className="flex w-full flex-wrap gap-sm p-0 md:flex-nowrap">
				<InputDoublePassword
					className="parameters-left w-full md:w-6/12"
					firstPlaceholder="Nouveau mot de passe"
					secondPlaceholder="Confirmation du nouveau mot de passe"
					buttonLabel="Modifier"
					title="Modification du mot de passe"
					onValidate={updatePassword}
					onChange={setNewPassword}
					onChangeConfirm={setNewPasswordVerify}
				/>

				<div className="parameters-right h-fit-content flex w-full flex-wrap justify-between md:w-6/12">
					<div className="content w-full">
						<h3>Préférences</h3>
						<SelectTertiary
							className="mt-lg"
							defaultValue={languageOptions.find(
								(option) => option.value === language,
							)}
							placeholder="Modifier la langue"
							label="Modifier la langue"
							options={languageOptions}
							disabled={load}
							icon={
								languageOptions.find((option) => option.value === language)
									?.icon
							}
							onChange={(e) => {
								changeLanguage(e.value);
							}}
						/>
					</div>
					{userInterface === "student" && (
						<div className="content mt-sm w-full">
							<h3>Réinitialisation de l'onboarding</h3>
							<div className="mt-sm flex flex-wrap justify-between">
								<p className="mb-sm w-full p-0 md:w-7/12">
									Voulez-vous réinitialiser "Mes premiers pas" afin de revoir
									comment utiliser la plateforme ?
								</p>
								<ButtonPrimary
									label="Réinitialiser"
									className="w-full md:w-4/12 h-fit"
									onClick={() => {
										// TO DO : refactor this
										handleReinitializeOnboarding();
									}}
								/>
							</div>
						</div>
					)}
				</div>
			</section>
			<section className="flex w-full flex-wrap gap-sm p-0 md:flex-nowrap">
				{user?.roles.includes("ROLE_SCHOOL") && (
					<div className="content mt-sm w-full rounded-md bg-white p-md md:w-6/12">
						<h3>Gestion des crédits SMS</h3>
						<p className="mt-sm">
							Nombre de SMS restants:{" "}
							<span className="font-bold text-accent-2-dark">{smsCredits}</span>
						</p>
						<Divider className="my-md" />
						<p>
							Les demandes de crédits SMS sont à adresser à{" "}
							<a
								className="font-bold underline"
								href="mailto:thomas@mentorgoal.com"
							>
								thomas@mentorgoal.com
							</a>
						</p>
					</div>
				)}
			</section>
		</main>
	);
}
