import { useAppSelector } from "@config/redux/hook";
import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { searchFill } from "@assets/Icons";

import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";

import { setCurrentSearch } from "../usersFiltersSlice";

export function UserfilterSearchbar() {
	const dispatchEvent = useDispatch();
	const inputRef = useRef(null);
	const { currentSearch } = useAppSelector((state) => state.usersFilters);

	const [search, setSearch] = useState<string>(currentSearch ?? "");
	const debouncedValue = useDebounce(search);

	useEffect(() => {
		dispatchEvent(setCurrentSearch(debouncedValue));
	}, [debouncedValue]);

	useEffect(() => {
		setSearch(currentSearch ?? "");
	}, [currentSearch]);

	return (
		<div ref={inputRef}>
			<InputLargePrimary
				icon={searchFill}
				placeholder="Rechercher..."
				onChange={(e) => {
					const cleanValue = e.target.value.replace(";", "");
					e.target.value = cleanValue;
					setSearch(cleanValue);
				}}
				value={currentSearch ? currentSearch.replace("%", " ") : ""}
				type="text"
			/>
		</div>
	);
}
