import type { NoteType } from "@/types/notesType";
import React, { useEffect, useState } from "react";

import { trash2Outline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { Modal } from "@designSystem/organisms/modal/Modal";

type ManageNoteModalProps = {
	show: boolean;
	onClose: () => void;
	note: NoteType;
	onApply: (id: string, content: string) => void;
	onDelete: (id: string) => void;
};

export function ManageNoteModal({
	show,
	onClose,
	note,
	onApply,
	onDelete,
}: ManageNoteModalProps) {
	const [noteContentToCreate, setNoteContentToCreate] = useState<string>(
		note?.content,
	);
	const [noteCreationError, setNoteCreationError] = useState<string>("");

	const handleCreateNote = () => {
		if (!noteContentToCreate) {
			return setNoteCreationError(
				"Veuillez remplir le champs afin d'ajouter une note",
			);
		}
		onApply(note.id.toString(), noteContentToCreate);
		setNoteContentToCreate("");
		onClose();
	};

	useEffect(() => {
		setNoteContentToCreate(note?.content);
	}, [note]);

	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title={`Note du ${note.datetime}`}
			body={
				<div>
					<TextArea
						label="Modifier la note"
						placeholder="Ecris ta note ici..."
						value={noteContentToCreate}
						onChange={(value) => {
							setNoteContentToCreate(value);
						}}
						height={307}
						errorMessage={noteCreationError}
					/>
				</div>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					onClick={() => {
						onClose();
					}}
					label="Annuler"
				/>,
				<ButtonPrimary
					key={1}
					onClick={() => {
						handleCreateNote();
					}}
					label="Confirmer"
				/>,
			]}
			buttonsLeft={[
				note?.id && (
					<ButtonQuaternary
						key={0}
						onClick={() => {
							onDelete(note.id);
							onClose();
						}}
						icon={trash2Outline}
					/>
				),
			]}
		/>
	);
}
