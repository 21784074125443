import React from "react";

import type { BackgroundColorType } from "@/types/colorsType";
import { Badge } from "@designSystem/atoms/Badge";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";

type StatCardType = {
	subStat?: string;
	subStatBgColor?: BackgroundColorType;
	title: string;
	stat: number;
};

export function StatCard({
	subStat,
	subStatBgColor = "bg-error-light",
	title,
	stat,
}: StatCardType) {
	return (
		<WhiteCard
			size="small"
			className="w-full"
			titleColor="accent-1-dark"
			title={title}
			content={
				<p className="flex items-center text-xlg font-bold">
					<span className="mr-sm">{stat}</span>
					{subStat && <Badge label={subStat} bgColor={subStatBgColor} />}
				</p>
			}
		/>
	);
}
