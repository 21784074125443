import api from "@tools/API";

export async function queryLogs(userId: string, page: number) {
	const dataToSend = {
		query: `query {
      logs (page: ${page}, order: [{createdAt: "DESC"}], itemsPerPage: 30, spsStudent: "${userId}") {
        collection {
          id
          action
          user: spsStudent {
            id
          }
          createdAt
          application {
            job
            organization : organizationV2
          }
          description
        },
        paginationInfo {
          lastPage
        }
      }
    }`,
	};
	try {
		const { data } = await api.post("graphql", dataToSend);
		return data.data.logs;
	} catch (e) {
		throw new Error(e);
	}
}

export async function queryNotifications(userId: string, page: number) {
	const dataToSend = {
		query: `query {
      notifications (page: ${page}, order: [{createdAt: "DESC"}], itemsPerPage: 30, spsStudent: "${userId}") {
        collection {
          id
          type
          link
          info
          createdAt
        },
        paginationInfo {
          lastPage
        }
      }
    }`,
	};

	try {
		const { data } = await api.post("graphql", dataToSend);
		return data.data.notifications;
	} catch (e) {
		throw new Error(e);
	}
}

export async function queryActions(userId: string, page: number) {
	const dataToSend = {
		query: `query {
      actions (page: ${page}, order: [{datetime: "ASC"}], itemsPerPage: 30, spsStudent: "${userId}", done: false) {
        collection {
          id
          title
          type
          datetime
          application {
            id
            organization : organizationV2
          }
        },
        paginationInfo {
          lastPage
        }
      }
    }`,
	};

	try {
		const { data } = await api.post("graphql", dataToSend);
		return data.data.actions;
	} catch (e) {
		throw new Error(e);
	}
}

export async function mutationAction(actionId: string, isDone: boolean) {
	const dataToSend = {
		query: `mutation {
      updateAction(input: {id: "${actionId}", done: ${isDone}})
      {
        action {
          id
          done
        }
      }
    }`,
	};

	try {
		const { data } = await api.post("graphql", dataToSend);
		return data.data.updateAction.action;
	} catch (e) {
		throw new Error(e);
	}
}
