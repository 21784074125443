import React, { useEffect, useState } from "react";

import "@assets/sass/Pagination.scss";

import {
	arrowIosBackFill,
	arrowIosForwardFill,
	arrowheadLeftFill,
	arrowheadRightFill,
} from "@assets/Icons";

import { ButtonOctonary } from "@designSystem/atoms/ButtonOctonary";

import "@assets/sass/Pagination.scss";

type PaginationPrimaryPropsType = {
	className?: string;
	last: number;
	current?: number;
	onChange: (pageNumber: number) => void;
	isLoading?: boolean;
};
export function PaginationPrimary({
	last,
	current = 1,
	className = "",
	onChange,
	isLoading = false,
}: PaginationPrimaryPropsType) {
	const [currentPage, setCurrentPage] = useState(current);

	const [pages, setPages] = useState<any[]>([]);
	const initPages = () => {
		let pageList: Array<string | number> = [];
		for (
			let i = current - 3;
			current + 3 < last ? i < current + 3 : i < last;
			i++
		) {
			if (i >= 0) pageList.push(i + 1);
		}

		if (last < 6) {
			pageList = Array.from(Array(last + 1).keys());
			pageList.shift();
			return setPages(pageList);
		}

		if (pageList[0] !== 1 && current !== last) {
			pageList.unshift(1);
			pageList.splice(1, 0, "...");
			if (pageList[2] === Number(pageList[0]) + 1) pageList.splice(1, 1);
		}

		if (current !== last) {
			if (Number(pageList[pageList.length - 1]) - current >= 3) {
				pageList[pageList.length - 1] = "...";
				pageList[pageList.length] = last;
			}
		} else {
			pageList.splice(0, 0, 1);
			pageList.splice(1, 0, "...");
		}
		setPages(pageList);
	};
	useEffect(() => {
		initPages();
		setCurrentPage(current);
	}, [current, last]);

	return (
		<div
			className={`pagination-container flex w-full items-center justify-center ${className}`}
		>
			<ButtonOctonary
				disabled={currentPage === 1 || isLoading}
				onClick={() => {
					if (onChange) onChange(1);
					setCurrentPage(1);
				}}
				icon={arrowheadLeftFill}
			/>
			<ButtonOctonary
				className="ml-xsm"
				disabled={currentPage === 1 || isLoading}
				onClick={() => {
					if (onChange) onChange(currentPage - 1);
					setCurrentPage(currentPage - 1);
				}}
				icon={arrowIosBackFill}
			/>
			<div className="pages">
				{pages.map((page, i) => (
					<span key={i}>
						<PaginationButton
							disabled={isLoading}
							onClick={() => {
								if (onChange && page !== "...") {
									onChange(page);
									setCurrentPage(page);
								}
							}}
							className={`${currentPage === page && "active"}`}
							label={page}
						/>
					</span>
				))}
			</div>
			<ButtonOctonary
				className="mr-xsm"
				disabled={currentPage === last || isLoading}
				onClick={() => {
					if (onChange) onChange(currentPage + 1);
					setCurrentPage(currentPage + 1);
				}}
				icon={arrowIosForwardFill}
			/>
			<ButtonOctonary
				disabled={currentPage === last || isLoading}
				onClick={() => {
					if (onChange) onChange(last);
					setCurrentPage(last);
				}}
				icon={arrowheadRightFill}
			/>
		</div>
	);
}

type PaginationButtonPropsType = {
	className?: string | "";
	active?: boolean;
	disabled?: boolean;
	onChange?: Function;
	onClick?: Function;
	label?: string | number | null;
};
function PaginationButton({
	label = "",
	className = "",
	active = false,
	onChange = () => {},
	onClick = () => {},
	disabled = false,
}: PaginationButtonPropsType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<button
			data-id="toggleButtonOctonary"
			tabIndex={0}
			disabled={disabled}
			className={`pagination-button-container ${
				isActive && "active"
			} ${className} `}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				if (disabled) {
					return;
				}
				onClick();
				onChange(!isActive);
			}}
		>
			<p>{label}</p>
		</button>
	);
}
