import type { CVsListProps } from "@containers/school/CVBoard/ui/CVsList/TableView/CreateSelectionModal";
import { Selection } from "@containers/school/CVBoard/ui/CVsList/TableView/ShareCVModal/Selection";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import React from "react";

interface ViewSelectionListModalsProps {
	CVsListProps: CVsListProps;
	noModifiable: boolean;
	show: boolean;
	onClose: () => void;
}

export const ViewSelectionListModal = ({
	CVsListProps,
	noModifiable,
	show,
	onClose,
}: ViewSelectionListModalsProps) => {
	return (
		<Modal
			title="Profils partagés"
			show={show}
			onClose={onClose}
			body={
				<Selection CVsListProps={CVsListProps} noModifiable={noModifiable} />
			}
			buttonsRight={[<ButtonPrimary onClick={onClose} label="Confirmer" />]}
		/>
	);
};
