import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { arrowBackOutline, emailOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";

import { mailChangePassword } from "@containers/auth/Activate/ActivateAPI";

import { checkMicrosoftEmail } from "@tools/Checkers";

export function Activate({ isMicrosoftLoginState }) {
	const [email, setEmail] = useState(
		localStorage.getItem("email") ? localStorage.getItem("email") : "",
	);
	const { setIsMicrosoftLogin } = isMicrosoftLoginState;
	const navigate = useNavigate();
	const [validate, setValidate] = useState(false);

	const inputs = {
		email: setEmail,
	};

	useEffect(() => {
		checkMicrosoftEmail(email, setIsMicrosoftLogin, navigate);
	}, [email]);

	useEffect(() => {
		document.title = "Activez votre compte - Mentor Goal";
	}, []);

	const handleOnChange = (e) => {
		inputs[e.target.name](e.target.value);
	};

	const sendMail = async () => {
		mailChangePassword(email);
	};

	return (
		<div className="ml-0 !flex w-full grid-cols-4 flex-col justify-center gap-4 sm:gap-6 md:ml-[8%] md:grid-cols-8 md:justify-start lg:w-11/12 lg:grid-cols-12 xxl:gap-8">
			<NavLink
				to={{
					pathname: "/",
					state: {
						email,
					},
				}}
			>
				<ButtonQuinary
					className="cursor-pointer text-primary-700P"
					icon={arrowBackOutline}
				/>
			</NavLink>
			{validate ? (
				<div className="forgot-password ml-0 w-full px-6 md:ml-[8%] md:px-0">
					<h2 className="text-center text-primary-900 md:text-left">
						E-mail envoyé !
					</h2>
					<div className="ml-0 w-full pl-0 md:w-8/12">
						<p className="mb-md mt-md text-center text-xxsm text-primary-300 md:text-left">
							Si votre adresse mail est enregistrée, vous allez recevoir un mail
							pour initialiser votre mot de passe.
						</p>
					</div>
				</div>
			) : (
				<div className="flex w-full flex-col items-center px-6 md:w-8/12 md:items-baseline md:px-0">
					<h2 className="text-primary-900">Activez votre compte</h2>
					<div className="lg:w-12/12 ml-0 w-full pl-0">
						<p className="mb-md mt-md text-center text-xxsm text-primary-300 md:text-left">
							Indiquez l&apos;adresse mail transmise à votre école.
						</p>
						<InputLargePrimary
							onChange={handleOnChange}
							value={email}
							name="email"
							type="text"
							icon={emailOutline}
							placeholder="Adresse e-mail"
						/>
						<ButtonPrimary
							onClick={() => {
								setValidate(true);
								sendMail();
							}}
							className="mt-md w-full"
							disabled={!email}
							label="Envoyer"
						/>
					</div>
				</div>
			)}
		</div>
	);
}

Activate.propTypes = {};

Activate.defaultProps = {};
