import type { CardType } from "@containers/student/AssistedGeneration";
import { generateAssistedGeneration } from "@containers/student/AssistedGeneration/AssistedGenerationAPI";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { Spinner } from "@designSystem/atoms/Spinner";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import { DatePicker } from "@designSystem/organisms/DatePicker";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { errorToast } from "@tools/Toasts";
import React, { useState } from "react";

type AssistedGenerationModalProps = {
	show: boolean;
	card: CardType | null;
	onClose: () => void;
};

const AVAILABLE_VARIABLES = {
	sentDate: {
		name: "Date d'envoi",
		description: "Date de la candidature",
		maxLength: 10,
		type: "date",
	},
	jobName: {
		name: "Nom du poste",
		description: "Nom du poste",
		maxLength: 255,
		type: "text",
	},
	offerDescription: {
		name: "Description de l'offre",
		description: "Description de l'offre",
		maxLength: 7000,
		type: "text",
	},
	companyName: {
		name: "Nom de l'entreprise",
		description: "Nom de l'entreprise",
		maxLength: 255,
		type: "text",
	},
} as const;

type variablesValuesType = Record<keyof typeof AVAILABLE_VARIABLES, string>;

export const AssistedGenerationModal = ({
	show,
	card,
	onClose,
}: AssistedGenerationModalProps) => {
	const [generatedData, setGeneratedData] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [variablesValues, setVariablesValues] = useState<variablesValuesType>(
		{} as variablesValuesType,
	);

	const OnModalClose = () => {
		setGeneratedData("");
		setVariablesValues({} as variablesValuesType);
		setLoading(false);
		onClose();
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!card) return;
		setLoading(true);

		// check if variables are not too long
		Object.keys(variablesValues).forEach(
			(variable: keyof typeof AVAILABLE_VARIABLES) => {
				// check if the variable is in the available variables
				if (!AVAILABLE_VARIABLES[variable]) {
					errorToast(`La variable ${variable} n'est pas disponible`);
					setLoading(false);
					return;
				}
				if (
					variablesValues[variable].length >
					AVAILABLE_VARIABLES[variable].maxLength
				) {
					errorToast(
						`La taille maximale de la variable ${AVAILABLE_VARIABLES[variable].name} est de ${AVAILABLE_VARIABLES[variable].maxLength} caractères`,
					);
					setLoading(false);
					return;
				}
			},
		);

		const response = await generateAssistedGeneration(variablesValues, card.id);
		if (response.status !== 200) {
			errorToast(
				"Une erreur est survenue lors de la génération de la lettre de motivation",
			);
			setLoading(false);
			return;
		}
		setGeneratedData(response.data.message.replace(/\n/g, "<br>"));
		setLoading(false);
	};
	return (
		<Modal
			title={card?.name}
			show={show}
			onClose={OnModalClose}
			size="lg"
			body={
				<div className="flex flex-col gap-4">
					{loading ? (
						<div className="flex justify-center items-center w-full h-full flex-col gap-4">
							<Spinner />
							Génération en cours...
						</div>
					) : (
						<>
							<form onSubmit={handleSubmit} className={"flex flex-col gap-4"}>
								{card?.variablesArray.map((variable) => (
									<FormField
										key={variable}
										variable={variable as keyof typeof AVAILABLE_VARIABLES}
										loading={loading}
										setVariablesValues={setVariablesValues}
										variablesValues={variablesValues}
									/>
								))}
								<ButtonPrimary
									className={"self-end"}
									label="Générer"
									type={"submit"}
									disabled={loading}
								/>
							</form>
							{generatedData && (
								<RichTextEditor
									className="whitespace-pre-line"
									value={generatedData}
									onChange={(e) => setGeneratedData(e)}
									placeholder={""}
									modules={{
										toolbar: false,
										clipboard: {
											matchVisual: false,
										},
									}}
								/>
							)}
						</>
					)}
				</div>
			}
		/>
	);
};

const FormField = ({
	variable,
	loading,
	variablesValues,
	setVariablesValues,
}: {
	variable: keyof typeof AVAILABLE_VARIABLES;
	loading: boolean;
	variablesValues: variablesValuesType;
	setVariablesValues: React.Dispatch<React.SetStateAction<variablesValuesType>>;
}) => {
	if (!AVAILABLE_VARIABLES[variable]) return null;
	const info = AVAILABLE_VARIABLES[variable];
	if (info.type === "date")
		return (
			<DatePicker
				label={info.name}
				selectedDate={new Date(variablesValues[variable] ?? "")}
				onDateChange={(date) =>
					setVariablesValues({
						...variablesValues,
						[variable]: date.toISOString().split("T")[0],
					})
				}
			/>
		);
	return (
		<InputLargePrimary
			onChange={(e) =>
				setVariablesValues({ ...variablesValues, [variable]: e.target.value })
			}
			value={variablesValues[variable] ?? ""}
			label={info.name}
			name={variable}
			placeholder={info.description}
			disabled={loading}
		/>
	);
};
