import type {
	EntCompanyInviteStatusType,
	EntCompanyInviteType,
} from "@/types/EntCompanyTypes";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { closeOutline, emailOutline, eyeOutline } from "@assets/Icons";

import type { SchoolType } from "@/types/schoolTypes";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";
import { Table } from "@designSystem/templates/table/Table";
import { GlobalContext } from "@navigation/Router";
import {
	errorToast,
	updateToastWithError,
	updateToastWithSuccess,
} from "@tools/Toasts";
import Skeleton from "react-loading-skeleton";

import lookingForInvites from "@assets/illustrations/entreprise/loupe-404.svg";

import { Badge } from "@designSystem/atoms/Badge";
import {
	type InviteResendDataType,
	createInvites,
	getEployeeTokenFromReferent,
	hideInvite,
	queryInvites,
} from "../../../PartnersAPI";

import { ENT_COMPANY_INVITE_STATUS } from "@constants/Company";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { InputCopy } from "@designSystem/molecules/InputCopy";
import { extractIdNumberFromId } from "@tools/Users";
import { MultiMailModal } from "./ui/MultiMailModal";
import success = toast.success;
import type { IdUriType } from "@/types/genericType";
import { Spinner } from "@designSystem/atoms/Spinner";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { setCookie } from "@tools/Cookies";

export function InvitesTab({
	school,
	loadingInfos,
}: {
	school: Partial<SchoolType>;
	loadingInfos: boolean;
}) {
	const [invites, setInvites] = useState<EntCompanyInviteType[]>([]);
	const [load, setLoad] = useState(true);
	const [currentlyLoadingInviteId, setCurrentlyLoadingInviteId] = useState<
		IdUriType | "" | "all"
	>("");
	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showLoadingModal, setShowLoadingModal] = useState(false);

	const { user } = useContext(GlobalContext);

	const getInvites = async () => {
		if (!user) return;
		try {
			setLoad(true);
			const invites = await queryInvites(user.school);
			setInvites(invites);
		} catch {
			Error("Une erreur est survenue");
		} finally {
			setLoad(false);
		}
	};

	useEffect(() => {
		getInvites();
	}, [user]);

	const resendAll = async () => {
		setCurrentlyLoadingInviteId("all");
		const invitesToRelaunch = invites.filter((invite) => {
			return (
				new Date(invite.lastSent as Date).toLocaleDateString() !==
				new Date().toLocaleDateString()
			);
		});
		await resendMail(invitesToRelaunch.map((i) => extractIdNumberFromId(i.id)));
		setCurrentlyLoadingInviteId("");
	};

	const resendMail = async (invitesIds: number[]) => {
		const data: InviteResendDataType[] = [];
		for (const invite of invitesIds) {
			data.push({
				invitationId: invite,
			});
		}
		const loadingToast = toast.loading("Envoi des relances en cours...", {
			isLoading: true,
			hideProgressBar: true,
			type: "default",
		});
		try {
			const mailsSent = await createInvites(data);
			updateToastWithSuccess(
				loadingToast,
				mailsSent.length > 1
					? `${mailsSent.length} invitations relancées.`
					: `${mailsSent.length} invitation relancée.`,
			);
			await getInvites();
		} catch (_e) {
			updateToastWithError(
				loadingToast,
				"Une erreur est survenue lors de l'envoi.",
			);
		}
	};

	const deleteInvite = async (invite: IdUriType) => {
		setCurrentlyLoadingInviteId(invite);
		try {
			await hideInvite(invite);
			const newInvites = invites.filter((item) => {
				return item.id !== invite;
			});
			setInvites(newInvites);
			success("Invitation cachée");
		} catch {
			Error("Une erreur est survenue");
		} finally {
			setCurrentlyLoadingInviteId("");
		}
	};

	const previewEnt = async () => {
		setShowLoadingModal(true);
		try {
			const token = await getEployeeTokenFromReferent();
			setCookie("interface", "company");
			setCookie("token", token);
			window.location.replace(`/company?${school.subdomain}`);
		} catch {
			errorToast(
				"Une erreur est survenue lors de la récupération des profils.",
			);
			setShowLoadingModal(false);
		}
	};

	const getStatusBadge = (status?: EntCompanyInviteStatusType) => {
		const statusFound = ENT_COMPANY_INVITE_STATUS.find(
			(i) => i.value === status,
		);
		return (
			<Badge
				label={statusFound?.label || ""}
				size="medium"
				bgColor={statusFound?.bgColor}
				hasBorder
			/>
		);
	};
	const cellsResolver = (invite: EntCompanyInviteType) => {
		return [
			{ children: <div>{invite.email}</div> },
			{
				children: <div className="flex">{getStatusBadge(invite.status)}</div>,
			},
			{
				children: (
					<div className="flex">
						{format(new Date(invite.lastSent || ""), "d/MM/y")}
					</div>
				),
			},
			{
				children: (
					<ButtonPrimary
						label=""
						disabled={
							invite.status === "created" ||
							currentlyLoadingInviteId === invite.id ||
							currentlyLoadingInviteId === "all" ||
							new Date(invite.lastSent as Date).toLocaleDateString() ===
								new Date().toLocaleDateString()
						}
						icon={emailOutline}
						onClick={async () => {
							if (!invite.id) {
								errorToast("Invitation introuvable");
								return;
							}
							setCurrentlyLoadingInviteId(invite.id);
							await resendMail([extractIdNumberFromId(invite.id)]);
							invite.lastSent = new Date();
							setCurrentlyLoadingInviteId("");
						}}
					/>
				),
			},
			{
				children: (
					<ButtonTertiary
						disabled={currentlyLoadingInviteId === invite.id}
						icon={closeOutline}
						onClick={async () => {
							if (!invite.id) {
								errorToast("Invitation introuvable");
								return;
							}
							await deleteInvite(invite.id);
						}}
					/>
				),
			},
		];
	};
	const sharingLink = `${import.meta.env.VITE_URL}/company?${school.subdomain}`;
	return (
		<section>
			<div className="mb-md flex gap-sm w-full">
				<WhiteCard
					className="w-6/12 lg:w-7/12 !h- full flex-grow"
					title="Inviter une entreprise par lien"
					content={
						<div>
							<p className="font-bold">Par lien</p>
							<div className="flex flex-col gap-xsm">
								<p className="text-label-size text-primary-300">
									Copiez le lien ci-dessous et envoyez-le :
								</p>
								<div className="">
									{loadingInfos ? (
										<div className="w-11/12">
											<Skeleton height={32} />
										</div>
									) : (
										<>
											<InputCopy URI={sharingLink} />
											<InlineButton
												id="preview-ent"
												className="mt-xsm"
												label="Prévisualiser l’espace entreprise"
												icon={eyeOutline}
												iconPosition="left"
												iconSize="sm"
												onClick={() => {
													previewEnt();
												}}
											/>
										</>
									)}
								</div>
							</div>
						</div>
					}
				/>
				<WhiteCard
					className="w-6/12 lg:w-7/12 !h- full flex-grow"
					title="Inviter une entreprise par email"
					content={
						<div>
							<p className="font-bold">Par email</p>
							<p>
								Invitez par email des recruteurs, pour envoyer l’invitation à un
								ou plusieurs destinataires (ajoutez un espace entre chaque
								email).
							</p>
							<ButtonPrimary
								className="ml-auto mt-sm"
								label="Inviter par mail"
								disabled={loadingInfos}
								onClick={() => {
									setShowInviteModal(true);
								}}
							/>
						</div>
					}
				/>
			</div>
			<div className="w-full rounded-md bg-white pt-sm mb-xlg md:w-10/12 lg:w-8/12">
				<div className="flex px-sm mb-xsm">
					<p className="my-auto mr-auto font-bold">Invitations récentes</p>
					<ButtonPrimary
						disabled={!!currentlyLoadingInviteId}
						label="Relancer tous"
						onClick={() => {
							resendAll();
						}}
					/>
				</div>

				{invites.length === 0 && !load ? (
					<div className="p-sm">
						<img
							src={lookingForInvites}
							alt="Aucune invitation récente"
							className="mx-auto h-[162px] w-[162px]"
						/>
						<p className="text-center">Aucune invitation pour le moment</p>
					</div>
				) : (
					<Table
						isLoading={load}
						filtersProps={
							{
								// filters: currentFilters,
								// setFilters: handleTableSetCurrentFilters,
							}
						}
						rows={invites.map((invite) => {
							return {
								cells: cellsResolver(invite),
							};
						})}
						headers={[
							{
								label: "EMAIL",
							},
							{ label: "STATUT" },
							{
								label: "DERNIER ENVOI",
							},
							{ label: "RELANCER" },
							{ label: "SUPPRIMER" },
						]}
					/>
				)}
			</div>
			<MultiMailModal
				existingInvites={invites.map((i) => i.email as string)}
				show={showInviteModal}
				onClose={() => {
					setShowInviteModal(false);
					getInvites();
				}}
			/>
			<Modal
				onClose={() => {}}
				show={showLoadingModal}
				title="Accès à l'espace entreprise"
				body={
					<div className="flex flex-col">
						<Spinner className="mx-auto my-xsm" />
					</div>
				}
			/>
		</section>
	);
}
