import type { LogType } from "@/types/logTypes";
import React from "react";

import { formatTime } from "@tools/Dates";
import { resolveLog } from "@tools/Logs";

import { Avatar } from "@designSystem/atoms/Avatar";
import { LogDescription } from "@modules/logHistory/LogDescription";

type LogProps = {
	log: LogType;
	selectedStudentState: any;
	setShowStudent: Function;
};
export function Log({ log, selectedStudentState, setShowStudent }: LogProps) {
	const { setSelectedStudent } = selectedStudentState;
	const user = log?.spsStudent ?? log?.spsReferent;

	return (
		<div className="log flex">
			<Avatar
				firstname={user?.firstname || ""}
				lastname={user?.lastname || ""}
				image={
					user?.avatar?.filePath
						? `${import.meta.env.VITE_S3}${user?.avatar?.filePath}`
						: ""
				}
			/>
			<div className="ml-sm">
				<p className="text-xsm text-primary-700P">
					<span
						className={`wg-no-translate ${log.spsStudent && "cursor-pointer"} font-bold`}
						onClick={() => {
							if (log.spsStudent) {
								setSelectedStudent(log.spsStudent);
								setShowStudent(true);
							}
						}}
					>
						{user?.firstname} {user?.lastname}{" "}
					</span>
					<span
						dangerouslySetInnerHTML={{
							__html: resolveLog(log, "school").label,
						}}
					/>
				</p>
				<LogDescription log={log} />
				<hr className="mb-xxsm mt-xxsm" />
				<p className="text-xxxsm font-bold text-primary-200">
					À {log?.createdAt ? formatTime(log?.createdAt) : ""}
				</p>
			</div>
		</div>
	);
}
