import { Icon } from "@iconify/react";
import { Formik } from "formik";
import React from "react";

import { alertTriangleOutline, checkmarkCircle2Fill } from "@assets/Icons";

import { formatDate } from "@tools/Dates";

import { Badge } from "@designSystem/atoms/Badge";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Checkbox } from "@designSystem/atoms/Checkbox";
import { TextArea } from "@designSystem/atoms/TextArea";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";

import type { GoalType } from "@/types/goalsTypes";
import { editGoal } from "@containers/school/Students/StudentDetail/StudentDetailGoalsAPI";

type SchoolGoalsType = {
	goals: GoalType[];
};

export function SchoolGoals({ goals }: SchoolGoalsType) {
	return (
		<div className="goals w-full">
			<div className="mb-sm">
				<p className="text-sm font-bold">Objectifs proposés par mon école</p>
			</div>
			<div className="flex flex-col pb-sm pt-sm">
				<Badge
					label={`Date limite : ${formatDate(
						new Date(
							goals.filter((goal) => goal.deadline)[0].deadline as string,
						),
						"dd/mm/yyyy",
					)}`}
					className="date"
					leftIcon={alertTriangleOutline}
				/>

				<div className="flex flex-col">
					<Formik
						initialValues={{
							goals,
						}}
						enableReinitialize
						onSubmit={() => {}}
					>
						{({ values, setFieldValue }) => (
							<div className="flex flex-col">
								{values.goals?.map((goal, i) => (
									<div key={goal.id}>
										<div className="mt-sm flex items-center">
											<TooltipContainer
												anchorId={"#tooltip-checkbox-goal"}
												makeClickable={false}
												place="top"
											>
												L’objectif comme réalisé.
											</TooltipContainer>

											<span id="tooltip-checkbox-goal">
												<Checkbox
													onEnabled={() => {
														values.goals[i] = {
															...values.goals[i],
															isCompleted: true,
														};
														setFieldValue("goals", values.goals);
														if (values.goals[i].studentComment) {
															editGoal(values.goals[i]);
														}
													}}
													onDisabled={() => {
														values.goals[i] = {
															...values.goals[i],
															isCompleted: !values.goals[i],
														};
														setFieldValue("goals", values.goals);
														if (values.goals[i].studentComment) {
															editGoal(values.goals[i]);
														}
													}}
													checked={goal.isCompleted}
												/>
											</span>
											<p className="ml-sm text-xsm">{goal.title}</p>
											<TooltipContainer
												anchorId={"#tooltip-checkmark"}
												makeClickable={false}
												place="top"
											>
												Tu as réalisé l'objectif
											</TooltipContainer>
											{values.goals[i].studentComment && (
												<span id="tooltip-checkmark">
													<Icon
														icon={checkmarkCircle2Fill}
														className="icon green ml-sm"
													/>
												</span>
											)}
										</div>
										{values.goals[i].isCompleted && (
											<div className="trans">
												<p className="mb-xxsm mt-xsm text-xsm text-primary-300">
													Laisse un commentaire pour dire ce que tu as fait.
												</p>
												<TextArea
													placeholder="Ton commentaire ici..."
													value={
														goal.studentComment !== null
															? goal.studentComment
															: undefined
													}
													onChange={(value) => {
														values.goals[i] = {
															...values.goals[i],
															studentComment: value,
														};
														setFieldValue("goals", values.goals);
													}}
													disabled={!!goal.completionDate}
												/>

												<div className="flex justify-end">
													<ButtonPrimary
														label={
															goal.completionDate
																? "Commentaire envoyé"
																: "Envoyer"
														}
														className="w-fit-content add-responsive mt-xsm"
														onClick={() => {
															values.goals[i] = {
																...values.goals[i],
																completionDate: new Date().toISOString(),
															};

															editGoal(values.goals[i]).finally(() => {
																setFieldValue("goals", values.goals);
															});
														}}
														disabled={
															!goal.studentComment || !!goal.completionDate
														}
													/>
												</div>
											</div>
										)}
									</div>
								))}
							</div>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
