import type { ContactType } from "@/types/contactTypes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import { plusFill, trash2Outline } from "@assets/Icons";

import { checkEmail, checkLinkedinProfile, checkPhone } from "@tools/Checkers";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Input } from "@designSystem/molecules/Input";
import { InputPhoneNumber } from "@designSystem/molecules/PhoneInput";
import { Modal } from "@designSystem/organisms/modal/Modal";

import {
	deleteContact,
	editContact,
} from "@containers/student/Applications/core/api/ApplicationDetailAPI";

import type { ContactErrorType } from "./NewContactModal";

type ModifyContactModalProps = {
	show: boolean;
	onClose: () => void;
	contact: ContactType;
	contactsProps: any;
};

export function ModifyContactModal({
	show,
	onClose,
	contact,
	contactsProps,
}: ModifyContactModalProps) {
	const [displaySecondPhone, setDisplaySecondPhone] = useState<boolean>(
		contact?.phone?.length > 1,
	);

	const { contacts, setContacts } = contactsProps;

	const [wantDelete, setWantDelete] = useState(false);

	const modifyContact = (values: any) => {
		const contactsCopy = [...contacts];
		const indexContact = contactsCopy.indexOf(contact);
		const phones = [];

		if (values.phone1) phones.push(values.phone1);
		if (values.phone2) phones.push(values.phone2);

		contactsCopy[indexContact].firstname = values.firstname;
		contactsCopy[indexContact].lastname = values.lastname;
		contactsCopy[indexContact].job = values.job;
		contactsCopy[indexContact].phone = phones;
		contactsCopy[indexContact].linkedin = values.linkedin;
		contactsCopy[indexContact].email = values.email;
		contactsCopy[indexContact].image = values.image;

		const phoneNumbers = [values.phone1, values.phone2];

		const dataToSent = {
			id: contact.id,
			firstname: values.firstname,
			lastname: values.lastname,
			job: values.job,
			phone: phoneNumbers,
			email: values.email,
			linkedin: values.linkedin,
		};
		editContact(dataToSent);
		setContacts(contactsCopy);
		onClose();
	};

	const wantDeleteContact = () => {
		deleteContact(contact.id);
		const contactsCopy = [...contacts];
		const indexContact = contactsCopy.indexOf(contact);

		if (indexContact > -1) contactsCopy.splice(indexContact, 1);

		setContacts(contactsCopy);
		onClose();
	};

	const getPhoneValue = (key: number) => {
		if (!contact.phone) return "";
		return contact.phone[key - 1];
	};

	useEffect(() => {
		if (contact?.phone?.[1]) setDisplaySecondPhone(true);
	}, [contact?.phone]);

	return (
		<div>
			<Formik
				initialValues={{
					displaySecondPhone: contact?.phone?.length > 1,
					firstname: contact.firstname || "",
					lastname: contact.lastname || "",
					job: contact.job || "",
					email: contact.email || "",
					linkedin: contact.linkedin || "",
					phone1: contact.phone?.[0] ? contact.phone[0] : "",
					phone2: contact?.phone?.[1] ? contact?.phone[1] : "",
					image: contact.image,
				}}
				enableReinitialize
				validate={(values) => {
					const errors: ContactErrorType = {};
					if (!values.firstname) {
						errors.firstname = "Veuillez entrer le prénom du contact";
					}
					if (!values.lastname) {
						errors.lastname = "Veuillez entrer le nom du contact";
					}
					if (!values.job) {
						errors.job = "Veuillez entrer le nom du poste occupé";
					}
					if (values.email && !checkEmail(values.email)) {
						errors.email = "Entrez un email valide";
					}
					if (values.linkedin && !checkLinkedinProfile(values.linkedin)) {
						errors.linkedin = "Entrez un Linkedin valide";
					}

					if (
						values.phone1 &&
						values.phone1.length > 2 &&
						!checkPhone(values.phone1)
					) {
						errors.phone1 = "Veuillez remplir un numéro de téléphone valide";
					}

					if (
						values.phone2 &&
						values.phone2.length > 2 &&
						!checkPhone(values.phone2)
					) {
						errors.phone2 = "Veuillez remplir un numéro de téléphone valide";
					}
					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					modifyContact(values as Object as ContactType);
					resetForm({});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					setFieldValue,
					resetForm,
				}) => (
					<Modal
						id="modify-contact"
						subtitle=""
						show={show}
						onClose={() => {
							onClose();
							resetForm();
							setWantDelete(false);
						}}
						title={wantDelete ? "Supprimer le contact" : "Modifier le contact"}
						body={
							wantDelete ? (
								<p className="text-xsm">
									Souhaites-tu vraiment supprimer ce contact ? Cette action est
									irréversible.
								</p>
							) : (
								<form onSubmit={handleSubmit} className="pb-xsm">
									<div className="flex">
										<Avatar
											className="min-h-[96px] min-w-[96px]"
											size="lg"
											image={values.image}
											onChange={(object: any) => {
												setFieldValue("image", object.url);
											}}
											firstname={values.firstname}
											lastname={values.lastname}
										/>
										<div className="ml-sm w-full">
											<div className="flex items-baseline">
												<Input
													className="mr-md"
													placeholder="Prénom"
													type="text"
													name="firstname"
													onChange={handleChange}
													value={values.firstname}
													errorMessage={
														errors.firstname || touched.firstname
															? errors.firstname
															: undefined
													}
												/>
												<Input
													placeholder="Nom"
													type="text"
													name="lastname"
													onChange={handleChange}
													value={values.lastname}
													errorMessage={
														errors.lastname || touched.lastname
															? errors.lastname
															: undefined
													}
												/>
											</div>
											<Input
												placeholder="Poste occupée"
												className="mt-sm"
												type="text"
												name="job"
												onChange={handleChange}
												value={values.job}
												errorMessage={
													errors.job || touched.job ? errors.job : undefined
												}
											/>
										</div>
									</div>
									<Input
										label={"Email (Optionnel)"}
										placeholder="Email"
										type="email"
										name="email"
										className="mt-md"
										onChange={handleChange}
										value={values.email}
										errorMessage={
											errors.email || touched.email ? errors.email : undefined
										}
									/>
									<Input
										label={"Linkedin (Optionnel)"}
										placeholder="https://"
										className="mt-sm"
										name="linkedin"
										type="text"
										onChange={handleChange}
										value={values.linkedin}
										errorMessage={
											errors.linkedin || touched.linkedin
												? errors.linkedin
												: undefined
										}
									/>
									<InputPhoneNumber
										required={false}
										value={values.phone1}
										onChange={(phone: string) => {
											setFieldValue("phone1", phone);
										}}
										onBlur={(phone: string) => setFieldValue("phone1", phone)}
										label={"Numéro de téléphone (Optionnel)"}
										className="mt-sm"
										name="phone1"
									/>
									{getPhoneValue(2) !== "" && displaySecondPhone ? (
										<div className="mt-sm flex items-end">
											<InputPhoneNumber
												required={false}
												value={values.phone2}
												onBlur={(phone: string) => {
													setFieldValue("phone2", phone);
												}}
												onChange={(phone: string) => {
													setFieldValue("phone2", phone);
												}}
												label={"Numéro de téléphone (Optionnel)"}
												className="mt-sm w-full"
											/>
											<ButtonTertiary
												onClick={() => {
													setFieldValue("phone2", "");
													setDisplaySecondPhone(false);
												}}
												icon={trash2Outline}
												className="ml-sm"
											/>
										</div>
									) : (
										<InlineButton
											id="add-phone"
											onClick={() => {
												setDisplaySecondPhone(true);
												setDisplaySecondPhone(true);
											}}
											icon={plusFill}
											label={"Ajouter un numéro supplémentaire" || ""}
											className="mt-sm"
										/>
									)}
								</form>
							)
						}
						buttonsRight={[
							<ButtonSecondary
								key="cancelBTN"
								onClick={() => {
									if (!wantDelete) {
										onClose();
										resetForm();
									}
									setWantDelete(false);
								}}
								label="Annuler"
							/>,
							<ButtonPrimary
								key="confirmBTN"
								type="submit"
								onClick={() => {
									if (wantDelete) wantDeleteContact();
									else handleSubmit();
									setWantDelete(false);
								}}
								label={"Confirmer" || ""}
							/>,
						]}
						buttonsLeft={
							wantDelete
								? undefined
								: [
										<ButtonQuaternary
											key="deleteBTN"
											icon={trash2Outline}
											onClick={() => {
												setWantDelete(true);
											}}
										/>,
									]
						}
					/>
				)}
			</Formik>
		</div>
	);
}
