import type { LogType } from "@/types/logTypes";
import type { UserType } from "@/types/userTypes";
import React, { useState } from "react";

import { formatOptionsToMultiSelect } from "@tools/Arrays";

import { errorToast } from "@tools/Toasts";

import {
	getLogs,
	retrievePromotions,
	updateLogsConsultationLastDate,
} from "../SchoolLayoutAPI";

import { Results } from "./StudentsActivityFlow/Results";
import { SearchByUser } from "./StudentsActivityFlow/SearchByUser";
import { StudentsActivityFlowLoader } from "./StudentsActivityFlow/StudentsActivityFlowLoader";

type StudentsActivityFlowProps = {
	setShowStudent: Function;
	selectedStudentState: any;
	user: UserType | undefined;
};

type ActivityFlowFilterType = {
	name: string;
	value: string;
	page: number;
};
type FiltersType = {
	page: number;
	[key: string]: number | string | undefined;
};

export function StudentsActivityFlow({
	setShowStudent,
	selectedStudentState,
	user,
}: StudentsActivityFlowProps) {
	const [filters, setFilters] = useState<FiltersType>({ page: 1, search: "" });
	const [logs, setLogs] = useState<LogType[]>([]);
	const [page, setPage] = useState(1);
	const [promotions, setPromotions] = useState([]);
	const [load, setLoad] = useState(true);
	const [lastPage, setLastPage] = useState(1);
	const [inRequest, setInRequest] = useState(false);

	const filtersResolver = (newFilter: ActivityFlowFilterType | null) => {
		if (newFilter === null) return filters;
		let newFiltersList: FiltersType = filters;
		if (newFilter) {
			switch (newFilter.name) {
				case "page":
					newFiltersList.page = Number(newFilter?.value);
					setPage(Number(newFilter?.value));
					break;
				default:
					newFiltersList[newFilter.name] = newFilter.value;
					newFiltersList.page = 1;
					setPage(1);
			}
			// Convert `newFiltersList` to an array of [key, value] pairs
			let entries = Object.entries(newFiltersList);
			entries = entries.filter(
				([key, value]) => value !== null || key === "page",
			);
			newFiltersList = Object.fromEntries(entries) as FiltersType;

			setFilters(newFiltersList);
		}
		return newFiltersList;
	};

	const fetchLogs = async (newFilter: any, reloadFromScrolling: boolean) => {
		try {
			const newFiltersList = filtersResolver(newFilter);
			const serverResponse = await getLogs(newFiltersList);
			if (serverResponse.responseLabel === "success") {
				setLastPage(
					serverResponse.data.collectionQueryLogs.paginationInfo.lastPage,
				);
				if (reloadFromScrolling) {
					setLogs([
						...logs,
						...serverResponse.data.collectionQueryLogs.collection,
					]);
				} else {
					setLogs([...serverResponse.data.collectionQueryLogs.collection]);
				}
				setInRequest(false);
			} else {
				if (serverResponse.message !== "aborted") {
					errorToast("Une erreur est survenue");
				}
			}
		} catch (_error) {
			errorToast("Une erreur est survenue");
		}
		if (load) {
			setLoad(false);

			const serverResponse = await retrievePromotions();
			if (serverResponse.responseLabel === "success") {
				setPromotions(
					formatOptionsToMultiSelect(serverResponse.data.promotions.collection),
				);
			}
			updateLogsConsultationLastDate(user?.id);
		}
	};

	return (
		<div className="students-activity-flow">
			<SearchByUser
				filters={filters}
				updateLogs={fetchLogs}
				promotions={promotions}
			/>
			<hr className="mb-sm mt-sm" />
			{load ? (
				<StudentsActivityFlowLoader />
			) : (
				<Results
					logs={logs}
					updateLogs={fetchLogs}
					pageState={{ page, setPage }}
					lastPage={lastPage}
					selectedStudentState={selectedStudentState}
					setShowStudent={setShowStudent}
					filters={filters}
					inRequestProps={{ inRequest, setInRequest }}
				/>
			)}
		</div>
	);
}
