import type { TagType } from "@/types/tagTypes";
import type { UserCompleteType, UserType } from "@/types/userTypes";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { arrowBackOutline, closeOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";

import Chat from "@containers/school/Mailbox/ui/Chat";

import { setStudentSelected } from "../../Mailbox/controller/mailBox.slice";
import { getStudentDetail } from "../SchoolLayoutAPI";

import { Documents } from "./StudentDetail/Documents";
import { Head } from "./StudentDetail/Head";
import { Information } from "./StudentDetail/Information";
import { Tags } from "./StudentDetail/Tags";

type StudentDetailType = {
	selectedStudentProps: {
		selectedStudent?: UserCompleteType & { tags?: { collection: TagType[] } };
		setSelectedStudent: Function;
	};
	setSlidingPaneIsOpen: Function;
	setShowStudent: Function;
	scrollWhenClosePanel: Function;
};
export function StudentDetail({
	selectedStudentProps,
	setSlidingPaneIsOpen,
	setShowStudent,
	scrollWhenClosePanel,
}: StudentDetailType) {
	const navigate = useNavigate();
	const dispatchMailBox = useDispatch();

	const { selectedStudent, setSelectedStudent } = selectedStudentProps;
	const [currentTab, setCurrentTab] = useState<"profil" | "messenger">(
		"profil",
	);

	const SLIDE_TAB_MG = [
		{
			label: "Fiche étudiant",
			active: currentTab === "profil",
			state: "profil",
		},
		{
			label: "Messagerie",
			active: currentTab === "messenger",
			state: "messenger",
		},
	];

	const getData = async () => {
		if (!selectedStudent) return;
		const response = (await getStudentDetail(selectedStudent?.id)) as {
			spsStudent: UserType;
		};
		if (response?.spsStudent) {
			dispatchMailBox(setStudentSelected(response?.spsStudent));
			setSelectedStudent(response?.spsStudent);
		}
	};

	useEffect(() => {
		if (selectedStudent) {
			getData();
		}
	}, []);
	useEffect(() => {
		const crispElement = document.getElementById("crisp-chatbox");

		if (crispElement && currentTab === "profil") {
			crispElement.classList.toggle("cc-tlyw", true);
		}
	}, [currentTab]);

	return (
		<div className="flex flex-col justify-center p-sm pb-xxlg">
			<div className="mb-md flex justify-between">
				<ButtonQuinary
					icon={arrowBackOutline}
					onClick={() => {
						setShowStudent(false);
					}}
				/>
				<ButtonQuinary
					icon={closeOutline}
					onClick={() => {
						setSlidingPaneIsOpen(false);
						scrollWhenClosePanel();
					}}
				/>
			</div>
			<ScrollableTabGroup
				className="mb-sm"
				tabs={SLIDE_TAB_MG}
				onTabChange={(i) => {
					switch (i) {
						case 0:
							setCurrentTab("profil");
							break;
						default:
							setCurrentTab("messenger");
							break;
					}
				}}
			/>
			{currentTab === "profil" ? (
				<>
					<Head selectedStudent={selectedStudent} />
					<Information selectedStudent={selectedStudent} />
					{selectedStudent?.tags?.collection &&
						selectedStudent?.tags?.collection.length > 0 && (
							<Tags tags={selectedStudent.tags.collection} />
						)}
					{(selectedStudent?.cv || selectedStudent?.motivationLetter) && (
						<Documents
							cv={selectedStudent?.cv}
							motivationLetter={selectedStudent?.motivationLetter}
						/>
					)}
					<ButtonPrimary
						className="mt-sm"
						label="Voir sa fiche détaillée"
						onClick={() => {
							navigate(`/students/${selectedStudent?.id.split("/")[3]}`);
						}}
					/>
				</>
			) : (
				<div id="panel">
					<Chat className="h-[80vh] [&>*]:ml-0 [&>hr]:!hidden gap-sm !p-0" />
				</div>
			)}
		</div>
	);
}
