import useIsTextTruncated from "@hooks/useIsTextTruncated";
import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

import { arrowDownOutline, arrowUpOutline } from "@assets/Icons";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { DropdownItem } from "@designSystem/molecules/DropdownItem";
import { ItemSimpleSmall } from "@designSystem/molecules/ItemSimpleSmall";

import { SelectModal } from "../selectModal/SelectModal";

const HEIGHT_OF_PAGINATION = 60;
const HEIGHT_OF_SELECTSECONDARY = 40;
let uniqueId = 0;

type optionType = {
	icon?: string;
	value: string;
	color?: string;
	label: string;
	data?: unknown;
};

type SelectSecondaryType = {
	className?: string;
	options: any[];
	onChange: (param: optionType) => void;
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	position: string;
	defaultValue: any;
	breakSpaces?: boolean;
	fitContent?: boolean;
	tooltip?: string;
};
export function SelectSecondary({
	className,
	options,
	onChange,
	disabled,
	label,
	placeholder,
	position,
	defaultValue,
	breakSpaces = false,
	fitContent,
	tooltip,
}: SelectSecondaryType) {
	const [componentId] = useState(() => uniqueId++);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [value, setValue] = useState(defaultValue);
	const [displayModal, setDisplayModal] = useState<boolean>(false);
	const ref = useRef<HTMLInputElement>(null);
	const refOfValue = useRef(null);
	const valueTruncate = useIsTextTruncated(refOfValue);
	const refDropdownList = useRef<HTMLInputElement>(null);
	const [width, setWidth] = useState<number>(0);
	const [displayTable, setDisplayTable] = useState<boolean>(false);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue, options]);

	useEffect(() => {
		const checkTableDisplay = document.querySelector('[id*="table"]');

		setDisplayTable(!!checkTableDisplay);
		if (window.innerHeight !== document.documentElement.scrollHeight) {
			setDisplayTable(false);
		}
		if (checkTableDisplay && refDropdownList.current) {
			const dropdownList = refDropdownList.current.getBoundingClientRect();
			const positionDropdownList = dropdownList.y + dropdownList.height;

			if (window.innerHeight < positionDropdownList + HEIGHT_OF_PAGINATION) {
				const translateY = -(dropdownList.height + HEIGHT_OF_SELECTSECONDARY);
				refDropdownList.current.style.transform = `translate3d(0px,${translateY}px,10px)`;
			} else {
				refDropdownList.current.style.transform = "";
			}
		}

		if (ref.current) setWidth(ref.current.offsetWidth);

		const handleClickOutside = (event: MouseEvent) => {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [isOpen]);

	return (
		<div
			data-id="select-secondary"
			data-testid="selectSecondary"
			id={`select-secondary-${componentId}`}
			className={`select-secondary  dropdown relative min-w-[150px] rounded-md ${className} ${
				disabled && "disabled"
			}`}
			ref={ref}
		>
			{label && (
				<p className="label mb-xxsm flex gap-2 text-xxsm text-primary-500">
					{label}{" "}
					{tooltip && (
						<>
							<TooltipElement id={`tooltip-select-secondary-${componentId}`} />
							<TooltipContainer
								anchorId={`#tooltip-select-secondary-${componentId}`}
								children={tooltip}
								place="right"
							/>
						</>
					)}
				</p>
			)}
			<div
				data-toggle="dropdown"
				data-bs-toggle="dropdown"
				className={`select-secondary-dropdown-container ${
					isOpen
						? "border-accent-1-dark shadow-accent1Light"
						: "border-primary-150 hover:border-accent-1 hover:shadow-accent1Lighter"
				} flex items-center justify-between gap-xsm !rounded-md !border bg-white px-sm py-xsm transition-all`}
				onClick={(e: any) => {
					e.preventDefault();
					e.stopPropagation();
					setIsOpen(!isOpen);
					setDisplayModal(true);
				}}
			>
				<div className="flex items-center max-w-full">
					{value?.icon && (
						<Icon className="mr-xsm" icon={value.icon} color={value.color} />
					)}
					<p
						className={`${
							value ? "value" : "placeholder"
						} truncate w-full !max-w-full`}
						data-testid="selectSecondaryValueText"
						ref={refOfValue}
						id={`text-truncate-${label}`}
					>
						{value?.label ? value.label : placeholder}
					</p>
					{valueTruncate && (
						<TooltipContainer
							children={value?.label ? value.label : placeholder}
							anchorId={`#text-truncate-${label}`}
							place="top"
						/>
					)}
				</div>
				<Icon
					className="icon-dropdown-input h-4"
					icon={isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>
			<div className="dropdown-container w-full">
				<div
					ref={refDropdownList}
					style={{ width: `${width}px` }}
					className={`!rounded-md ${
						displayTable ? "fixed" : "absolute"
					}  flex flex-col ${fitContent && "w-fit"} z-10 h-auto max-h-80 ${
						options.length > 4 && "overflow-y-scroll"
					}  bg-white shadow-sm ${isOpen ? "show" : "hidden"} ${position}`}
				>
					{options?.map((input, i) => {
						return (
							<DropdownItem
								key={i}
								onClick={(e: any) => {
									e.preventDefault();
									e.stopPropagation();
									setValue(input);
									if (onChange) onChange(input);
									setIsOpen(false);
								}}
								icon={input.icon}
								color={input.color}
								label={input.label}
								breakSpaces={breakSpaces}
							/>
						);
					})}
				</div>
			</div>

			<SelectModal
				title={label ?? ""}
				onClick={() => {
					setDisplayModal(false);
				}}
				body={
					<div>
						{options?.map((input, i) => {
							return (
								<div key={i}>
									<ItemSimpleSmall label={input.label} />
								</div>
							);
						})}
					</div>
				}
				show={displayModal}
			/>
		</div>
	);
}
