import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

import { bulbOutline } from "@assets/Icons";

import { Avatar } from "@designSystem/atoms/Avatar";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";

import { useBreakpoints } from "@hooks/useBreakpoints";
import ConversationButton from "./ConversationButton";

export default function ChatHead() {
	const { user, currentInterface } = useContext(GlobalContext);
	const { isMobile } = useBreakpoints();
	const { studentSelected, conversation } = useAppSelector(
		(state) => state.mailBox,
	);

	return (
		<>
			{currentInterface === "school" ? (
				<div className="flex items-center gap-sm">
					<Avatar
						firstname={studentSelected?.firstname}
						lastname={studentSelected?.lastname}
						size="xs"
						image={
							studentSelected?.avatar
								? `${import.meta.env.VITE_S3}${
										studentSelected?.avatar?.filePath
									}`
								: null
						}
					/>
					<p className="text-sm font-bold">
						{studentSelected?.firstname} {studentSelected?.lastname}
					</p>
				</div>
			) : (
				<div className="flex flex-col gap-sm">
					<p className="font-bold text-sm">Messagerie {user?.schoolName}</p>
					{!isMobile && (
						<div className="bg-white rounded-2xl shadow-sm flex flex-row p-sm items-start gap-xsm">
							<Icon
								icon={bulbOutline}
								className="text-secondary-500P"
								height={24}
								width={24}
							/>
							<p>
								La messagerie te permet d'échanger directement avec{" "}
								{user?.schoolName}
							</p>
						</div>
					)}
				</div>
			)}
			<div className="my-sm h-0 border-t" />
			{currentInterface === "school" && studentSelected && (
				<div
					className={`m-0 flex justify-end items-center gap-xsm rounded-md px-sm py-xxsm mb-sm bg-${
						conversation?.status === "resolved"
							? "accent-4-lighter"
							: "accent-3-lighter"
					}`}
				>
					<ConversationButton />
					<TooltipElement
						id="tooltip-skills"
						className="h-fit font-semibold [&>svg]:!text-primary-700P"
					/>
					<TooltipContainer
						anchorId="#tooltip-skills"
						children="Cliquez ici lorsque la conversation ne nécessite plus d'autre interaction."
						place="top-end"
					/>
				</div>
			)}
		</>
	);
}
