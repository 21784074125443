import type { CityType } from "@/types/LocationTypes";
import type {
	promotionOptionType,
	promotionType,
} from "@/types/promotionTypes";
import type { TagType } from "@/types/tagTypes";
import type {
	FiltersType,
	JobContractSearchOptionType,
	StatusOptionType,
	UsersFiltersContextType,
	UsersFiltersType,
} from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import { DISTANCE_OPTIONS } from "@constants/Offers";
import {
	ACTIVITY_OPTIONS,
	ARCHIVED_OPTIONS,
	JOB_CONTRACT_OPTIONS,
	STUDENT_BIRTHDATE_OPTIONS,
	STUDENT_STATUS_OPTIONS,
} from "@constants/Users";
import { TagsFinder } from "@designSystem/organisms/TagsFinder/TagsFinder";
import { Select } from "@designSystem/organisms/select/Select";
import { SelectLocation } from "@designSystem/organisms/select/SelectLocation";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";
import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";
import { GlobalContext } from "@navigation/Router";
import { resolveView } from "@tools/Users";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";
import { getPromotions, getTags } from "./UsersFiltersAPI";
import {
	type UsersFiltersState,
	countCurrentFilters,
	removeSpecificFilter,
	setCurrentFilters,
	setCurrentStructure,
} from "./usersFiltersSlice";

export const UsersFiltersContext = createContext<UsersFiltersContextType>({
	filters: { page: 1, itemsPerPage: 30 },
	setFilters: () => {},
});

export function UsersFilters({
	filtersProps,
	enabledFilters = [
		"activity",
		"status",
		"promotions",
		"tags",
		"archived",
		"location",
		"birthdate",
		"jobContract",
	],
}: UsersFiltersType) {
	const { filters, setFilters } = filtersProps;
	return (
		<UsersFiltersContext.Provider value={{ filters, setFilters }}>
			<section
				className="
        flex flex-col gap-sm 
        divide-y-2 divide-solid divide-primary-150
        rounded-lg border-[1px] border-primary-700P 
        bg-primary-100 p-sm"
			>
				<div className="flex flex-wrap gap-sm">
					{enabledFilters.includes("location") && <LocationFilter />}
					{enabledFilters.includes("birthdate") && <BirthdateFilter />}
					{enabledFilters.includes("status") && <StatusFilter />}
					{enabledFilters.includes("promotions") && <PromotionsFilter />}
					{enabledFilters.includes("jobContract") && <JobContractFilter />}
					{enabledFilters.includes("activity") && <ActivityFilter />}
					{enabledFilters.includes("archived") && <ArchivedFilter />}
				</div>
				<div>{enabledFilters.includes("tags") && <TagsFilter />}</div>
			</section>
		</UsersFiltersContext.Provider>
	);
}

function ActivityFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	function lastConnexionResolver(choice: string) {
		const activityDateLimit = new Date();
		activityDateLimit.setDate(activityDateLimit.getDate() - 15);

		switch (choice) {
			case "before":
				return {
					before: activityDateLimit,
				};
			case "after":
				return {
					after: activityDateLimit,
				};
			default:
				return null;
		}
	}

	return (
		<SelectSecondary
			position="right"
			placeholder="Activité : tous"
			options={ACTIVITY_OPTIONS}
			defaultValue={
				currentFilters.lastConnexion
					? ACTIVITY_OPTIONS.find((option) => {
							const lastConnexion = currentFilters?.lastConnexion;
							return (
								lastConnexion && option.value === Object.keys(lastConnexion)[0]
							);
						})
					: ACTIVITY_OPTIONS[0]
			}
			onChange={(e) => {
				dispatchEvent(
					setCurrentFilters({
						...currentFilters,
						lastConnexion: lastConnexionResolver(e.value),
						page: 1,
					}),
				);
				dispatchEvent(countCurrentFilters());
			}}
		/>
	);
}

function TagsFilter() {
	const dispatchEvent = useDispatch();
	const { user } = useContext(GlobalContext);
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);
	const [tagsOptions, setTagsOptions] = useState<TagType[]>([]);
	const handleOnTagsChange = (tagsList: number[] = [], type = "and") => {
		const filtersCpy = { ...currentFilters };

		if (tagsList.length === 0) {
			filtersCpy.tags = undefined;
			dispatchEvent(setCurrentFilters(filtersCpy));
		} else {
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					tags: { list: tagsList, operator: type },
					page: 1,
				}),
			);
		}
		dispatchEvent(countCurrentFilters());
	};

	const retrieveTags = () => {
		if (user) {
			getTags(user.school).then(({ collection }: { collection: TagType[] }) => {
				setTagsOptions(collection);
			});
		}
	};
	const getDefaultTags = () => {
		let tagsList: TagType[] = [];

		if (currentFilters.tags) {
			tagsList = tagsOptions.filter((tagOption) =>
				currentFilters.tags?.list.includes(
					Number.parseInt(tagOption.id.split("/")[3], 10),
				),
			);
		}

		return tagsList;
	};

	useEffect(() => {
		retrieveTags();
	}, [user]);

	return (
		<TagsFinder
			options={tagsOptions?.sort((a, b) => a.name.localeCompare(b.name))}
			defaultOptions={getDefaultTags()}
			className="mt-sm"
			placeholder="Rechercher un tag... "
			useAsFilter={true}
			onChange={(tagFilter: any) => {
				handleOnTagsChange(tagFilter.list, tagFilter.operator);
			}}
		/>
	);
}

function StatusFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	const getFormatedStatus = () => {
		const formattedStatusOptions: StatusOptionType[] =
			STUDENT_STATUS_OPTIONS.map((statusOption: StatusOptionType) => {
				return {
					...statusOption,
					checked:
						currentFilters.status_list?.includes(statusOption.label) || false,
					label: statusOption.label,
					value: statusOption.value,
					icon: statusOption.icon,
					type: "checkbox",
				};
			});

		return formattedStatusOptions;
	};

	const [status, setStatus] = useState<StatusOptionType[]>(getFormatedStatus());

	useEffect(() => {
		if (currentFilters.status_list?.length !== 0) {
			setStatus(getFormatedStatus());
		}
	}, [currentFilters]);

	const handleChange = (statusSelected: StatusOptionType[]) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				status_list: statusSelected.map((status) => status.value),
				page: 1,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<Select
			position="right"
			placeholder="Statut"
			inputs={status.map((statusItem) => ({
				id: statusItem.value,
				name: statusItem.label,
				value: statusItem.value,
				label: statusItem.label,
				type: "checkbox",
				checked:
					currentFilters.status_list?.includes(statusItem.value) || false,
			}))}
			onChange={(statusSelected: any) => {
				handleChange(statusSelected);
			}}
			multiselect
			breakSpaces
		/>
	);
}

function PromotionsFilter() {
	const { structureView }: any = useOutletContext();
	const [fetchedPromotions, setFetchedPromotions] = useState<promotionType[]>(
		[],
	);
	const [promotionsOptions, setPromotionsOptions] = useState<
		promotionOptionType[]
	>([]);

	const dispatchEvent = useDispatch();
	const { currentFilters, currentStructure } = useAppSelector(
		(state) => state.usersFilters as UsersFiltersState,
	);

	const getPromotionsForSelect = () => {
		let promotionsSelectOptions: promotionOptionType[] = [];
		promotionsSelectOptions = fetchedPromotions?.map((promo) => {
			return currentFilters?.spsPromotion_list?.includes(promo.id)
				? {
						value: promo.id,
						label: promo.name,
						type: "checkbox",
						checked: true,
					}
				: {
						value: promo.id,
						label: promo.name,
						type: "checkbox",
						checked: false,
					};
		}) as promotionOptionType[];
		return promotionsSelectOptions;
	};
	const retrievePromotions = () => {
		getPromotions(resolveView(structureView)).then(
			({ collection }: { collection: promotionType[] }) => {
				setFetchedPromotions(collection);
			},
		);
	};

	const handleStructureModification = (structureId: string) => {
		dispatchEvent(setCurrentStructure(structureId));
		dispatchEvent(removeSpecificFilter("promotion_list"));
		dispatchEvent(countCurrentFilters());

		retrievePromotions();
	};

	useEffect(() => {
		if (structureView?.school && structureView?.school !== currentStructure) {
			handleStructureModification(structureView.school);
		} else if (
			structureView?.campus &&
			structureView?.campus !== currentStructure
		) {
			handleStructureModification(structureView.campus);
		} else {
			retrievePromotions();
		}
	}, [structureView]);

	useEffect(() => {
		setPromotionsOptions(getPromotionsForSelect());
	}, [fetchedPromotions, currentFilters]);

	const handleChange = (promotionsSelected: promotionOptionType[]) => {
		setPromotionsOptions(
			promotionsOptions?.map((promotion) => {
				return promotionsSelected?.includes(promotion)
					? { ...promotion, checked: true }
					: { ...promotion, checked: false };
			}),
		);
		const promotionSelectedIds: string[] = promotionsSelected
			.map((promotion) => {
				const matchedPromotion = fetchedPromotions.find(
					(fetchedPromotion) => fetchedPromotion.id === promotion.value,
				);
				return matchedPromotion ? matchedPromotion.id : "";
			})
			.filter((id) => id !== ""); // Filter out any empty strings

		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				spsPromotion_list: promotionSelectedIds,
				page: 1,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	return (
		<>
			<Select
				position="right"
				placeholder="Promotions"
				inputs={promotionsOptions}
				multiselect={true}
				withSearchbar
				breakSpaces
				onChange={handleChange}
				searchbarPlaceholder="Rechercher une promo"
				className="wg-no-translate"
			/>
		</>
	);
}

type SelectOptionType = {
	id?: number;
	label?: string;
	value: string | number | null | object;
};
const getMaxDistanceObjectFromFilters = (
	currentFilters: Partial<FiltersType>,
): SelectOptionType => {
	if (currentFilters.max_distance) {
		return {
			value: currentFilters.max_distance,
		};
	}
	return {
		value: null,
	};
};

function LocationFilter() {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector((state) => state.usersFilters);

	const [defaultMaxDistanceOption, setDefaultMaxDistanceOption] =
		useState<SelectOptionType>(getMaxDistanceObjectFromFilters(currentFilters));
	const handleLocationChange = (cityReceived: CityType) => {
		if (!cityReceived) {
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					location: undefined,
					position: undefined,
					max_distance: undefined,
				}),
			);
			return;
		}
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				location: cityReceived.location,
				position: [cityReceived.longitude, cityReceived.latitude],
				max_distance:
					cityReceived.location !== "" ? currentFilters.max_distance : 50,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const handleMaxDistanceChange = (option: SelectOptionType) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				max_distance: Number(option.value ?? 50),
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const getCityObjectFromFilters = (): CityType | null => {
		if (currentFilters.location && currentFilters.position) {
			return {
				location: currentFilters.location,
				latitude: currentFilters.position[1],
				longitude: currentFilters.position[0],
			};
		}
		return null;
	};

	useEffect(() => {
		setDefaultMaxDistanceOption(
			getMaxDistanceObjectFromFilters(currentFilters),
		);
	}, [currentFilters]);

	return (
		<div className="flex w-fit gap-1">
			<SelectLocation
				withLabel={false}
				handleNewLocation={(cityReceived: CityType) => {
					handleLocationChange(cityReceived);
				}}
				defaultValue={
					currentFilters.location ? getCityObjectFromFilters() : null
				}
			/>
			<SelectTertiary
				position="right"
				placeholder="Distance"
				options={DISTANCE_OPTIONS}
				defaultValue={defaultMaxDistanceOption}
				className="w-full max-w-[120px]"
				onChange={(option) => {
					handleMaxDistanceChange(option);
				}}
				disabled={!currentFilters.location || currentFilters.location === ""}
			/>
		</div>
	);
}

function JobContractFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	const getFormatedJobContracts = () => {
		const formattedJobContractOptions: JobContractSearchOptionType[] =
			JOB_CONTRACT_OPTIONS.map((statusOption: JobContractSearchOptionType) => {
				return {
					...statusOption,
					checked:
						currentFilters.status_list?.includes(statusOption.value) || false,
					label: statusOption.label,
					value: statusOption.value,

					type: "checkbox",
				};
			});

		return formattedJobContractOptions;
	};

	const [jobContractSearch, setJobContractSearch] = useState<
		JobContractSearchOptionType[]
	>(getFormatedJobContracts());

	useEffect(() => {
		if (currentFilters.status_list?.length !== 0) {
			setJobContractSearch(getFormatedJobContracts());
		}
	}, [currentFilters]);

	const handleChange = (statusSelected: JobContractSearchOptionType[]) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				contractSearched_list: statusSelected.map((status) => status?.value),
				page: 1,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<Select
			position="right"
			placeholder="Contrat"
			inputs={jobContractSearch.map((jobContractItem) => ({
				id: jobContractItem.value,
				name: jobContractItem.label,
				value: jobContractItem.value,
				label: jobContractItem.label,
				type: "checkbox",
				checked:
					currentFilters.contractSearched_list?.includes(
						jobContractItem.value,
					) || false,
			}))}
			onChange={(statusSelected: any) => {
				handleChange(statusSelected);
			}}
			multiselect
			breakSpaces
		/>
	);
}

function BirthdateFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	const handleChangeBirthdateFilter = (option: SelectOptionType) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				birthdate: option?.value
					? { label: option.label, value: option.value }
					: null,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const getBirthdateLabelFromFilters = (): any => {
		if (currentFilters?.birthdate) {
			const optionFind = STUDENT_BIRTHDATE_OPTIONS.find((option) => {
				return option.label === currentFilters.birthdate?.label;
			});
			return optionFind?.label;
		}
		return "Age : Tous";
	};
	return (
		<SelectTertiary
			position="right"
			placeholder="Age : Tous"
			options={STUDENT_BIRTHDATE_OPTIONS}
			defaultValue={getBirthdateLabelFromFilters()}
			className="w-full max-w-[160px]"
			onChange={(option) => {
				handleChangeBirthdateFilter(option);
			}}
		/>
	);
}

function ArchivedFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	return (
		<SelectSecondary
			position="right"
			options={ARCHIVED_OPTIONS}
			defaultValue={
				currentFilters.spsPromotion_archived !== undefined
					? ARCHIVED_OPTIONS.find((option) => {
							return option.value === currentFilters.spsPromotion_archived;
						})
					: ARCHIVED_OPTIONS[0]
			}
			onChange={(e) => {
				dispatchEvent(
					setCurrentFilters({
						...currentFilters,
						spsPromotion_archived: e.value,
						page: 1,
					}),
				);

				dispatchEvent(countCurrentFilters());
			}}
		/>
	);
}
