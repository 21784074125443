import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import {
	alertTriangleOutline,
	arrowHeadRightOutline,
	bellOutline,
	checkmarkOutline,
	cornerDownRightOutline,
	undoOutline,
} from "@assets/Icons";

import { diffDate, formatDate } from "@tools/Dates";

import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { success } from "@tools/Toasts";

import type { ActionType } from "@containers/student/Applications/ApplicationDetail/ui/Tabs/Actions";
import { mutationAction } from "@containers/student/StudentLayout/SlidingPaneContent/SlidingPaneContentAPI";
import { extractIdNumberFromId } from "@tools/Users";

export function ActionCard({
	action,
	setSlidingPaneIsOpen,
}: { action: ActionType; setSlidingPaneIsOpen: (isOpen: boolean) => void }) {
	const [complete, setComplete] = useState(false);
	const navigate = useNavigate();

	const resolveLink = () => {
		switch (action.type) {
			case "changeCV":
				return "/profile";
			case "applyToOffer":
				return "/jobboard";
			case "updateApplyData":
				return `/applications/${extractIdNumberFromId(action.application?.id)}`;
			case "prepareInterview":
				return `/applications/${extractIdNumberFromId(action.application?.id)}`;
			case "importCV":
				return "/profile";
			case "defineJobSearchArea":
				return "/profile";
			case "completeCareer":
				return "/profile";
			case "createSpontaneousApplication":
				return "/spontaneous-application";
			case "addContact":
				return "/applications";
			case "addAction":
				return "/applications";
			case "action":
				return `/applications/${extractIdNumberFromId(action.application?.id)}`;
			default:
				return "/profile";
		}
	};
	const resolveDate = () => {
		const date = new Date(action.datetime);
		if (diffDate(date) === 0) {
			return { color: "text-accent-2-dark", icon: cornerDownRightOutline };
		}
		if (diffDate(date) > 0) {
			return { color: "text-accent-3-dark", icon: alertTriangleOutline };
		}
		return { color: "text-accent-4-dark", icon: bellOutline };
	};

	const completeAction = (isDone: boolean) => {
		mutationAction(action.id, isDone).then(({ done }) => {
			setComplete(done);
			success("L'action a bien été mise à jour");
		});
	};

	return (
		<div className="flex flex-col gap-sm px-md py-sm shadow-sm rounded-lg bg-white md:flex-row">
			<div className="w-full md:w-8/12">
				<p className="text-xsm text-primary-700P">{action.title}</p>
				{action.application && (
					<p className="text-xsm text-primary-300">
						{action.application.organization.name}
					</p>
				)}
				<div
					className={`${
						resolveDate().color
					} mt-xsm flex items-center gap-xxsm text-xxsm`}
				>
					<Icon icon={resolveDate().icon} />
					<p>{formatDate(new Date(action.datetime), "dd/mm/yyyy")}</p>
				</div>
			</div>
			<div className="flex w-full items-center justify-center gap-sm md:w-4/12">
				<ButtonTertiary
					icon={arrowHeadRightOutline}
					onClick={() => {
						navigate(resolveLink());
						setSlidingPaneIsOpen(false);
					}}
				/>
				{complete ? (
					<ButtonTertiary
						icon={undoOutline}
						onClick={() => {
							completeAction(false);
						}}
					/>
				) : (
					<ButtonTertiary
						icon={checkmarkOutline}
						onClick={() => {
							completeAction(true);
						}}
					/>
				)}
			</div>
		</div>
	);
}
