import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";

import { getUserDocuments } from "../../DashboardAPI";

import { Document } from "./Documents/Document";

export type DocumentType = {
	label: string;
	file: any;
	filename: string;
	maxSize: number;
};
export function Documents() {
	const { user } = useContext(GlobalContext);
	const [documents, setDocuments] = useState<any>();

	const fetchDocuments = async () => {
		if (!user) return;

		const documents = await getUserDocuments(user.id);

		setDocuments({
			cv: {
				label: "CV",
				file: documents.cv,
				filename: "cv",
				type: "cv",
				maxSize: 1,
			},
			motivationLetter: {
				label: "Lettre de motivation",
				file: documents.motivationLetter,
				filename: "motivationLetter",
				type: "motivation",
				maxSize: 1,
			},
			portfolioPdf: {
				label: "Portfolio",
				file: documents.portfolioPdf,
				filename: "portfolioPdf",
				type: "portfolio",
				maxSize: 5,
			},
		});
	};

	useEffect(() => {
		fetchDocuments();
	}, []);

	return (
		<div className="flex flex-col gap-sm">
			<div className="flex gap-xxsm">
				<p className="text-[18px] font-bold">Mes documents</p>
				<TooltipElement id={"tooltip-documents"} />
				<TooltipContainer
					anchorId="#tooltip-documents"
					makeClickable={false}
					children={
						"Ils permettront à ton école de proposer ton profil à des entreprises partenaires."
					}
					place="top"
				/>
			</div>
			<p className="text-xsm">
				Les documents de ton profil seront visibles par ton école, dans le but
				qu’elle puisse les partager à des entreprises partenaires.
			</p>
			{documents && (
				<>
					<Document
						documents={documents}
						setDocuments={setDocuments}
						label={documents.cv.label}
						file={documents.cv.file}
						filename={documents.cv.filename}
						maxSize={documents.cv.maxSize}
						type={documents.cv.type}
					/>
					<Document
						documents={documents}
						setDocuments={setDocuments}
						label={documents.motivationLetter.label}
						file={documents.motivationLetter.file}
						filename={documents.motivationLetter.filename}
						maxSize={documents.motivationLetter.maxSize}
						type={documents.motivationLetter.type}
					/>
					<Document
						documents={documents}
						setDocuments={setDocuments}
						label={documents.portfolioPdf.label}
						file={documents.portfolioPdf.file}
						filename={documents.portfolioPdf.filename}
						maxSize={documents.portfolioPdf.maxSize}
						type={documents.portfolioPdf.type}
					/>
				</>
			)}
		</div>
	);
}
