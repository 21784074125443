import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

import "@assets/sass/style.scss";
import "./style.scss";

import {
	arrowIosBackFill,
	arrowIosForwardFill,
	calendarOutline,
} from "@assets/Icons";

import { DaysPanel } from "@designSystem/molecules/DaysPanel";
import { InputLargePrimary } from "@designSystem/molecules/InputLargePrimary";
import { MonthPopUp } from "@designSystem/molecules/MonthPopUp";
import { YearPopUp } from "@designSystem/molecules/YearPopUp";

import { useBreakpoints } from "@hooks/useBreakpoints";

type NavigateType = {
	onClickLeft?: () => void;
	onClickRight?: () => void;
};

export const Navigate = (props: NavigateType) => {
	const { onClickLeft, onClickRight } = props;

	return (
		<div className="navigate">
			{onClickLeft && (
				<button onClick={onClickLeft} type="button">
					<Icon icon={arrowIosBackFill} />
				</button>
			)}
			{onClickRight && (
				<button onClick={onClickRight} type="button">
					<Icon icon={arrowIosForwardFill} />
				</button>
			)}
		</div>
	);
};
let uniqueId = 0;

const SHORT_DAY_LABELS = ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"];

const FULL_MONTH_LABELS = [
	"Janvier",
	"Février",
	"Mars",
	"Avril",
	"Mai",
	"Juin",
	"Juillet",
	"Août",
	"Septembre",
	"Octobre",
	"Novembre",
	"Décembre",
];
type DateActionType = "click" | "switch_month" | "switch_year" | "input";

export type DatePickerPropsType = {
	selectedDate?: Date | undefined;
	onDateChange: (date: Date, action: DateActionType) => void;
	errorMessage?: string;
	withIcon?: boolean;
	className?: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
};

export const DatePicker = ({
	selectedDate = undefined,
	onDateChange,
	errorMessage = "",
	withIcon = false,
	className = "",
	label = "",
	disabled = false,
	placeholder,
}: DatePickerPropsType) => {
	const currentDate = new Date();
	const { isMobile } = useBreakpoints();

	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [showMonthSelection, setShowMonthSelection] = useState<boolean>(false);
	const [showYearSelection, setShowYearSelection] = useState<boolean>(false);
	const [inputTimeout, setInputTimeout] = useState<any>(null);
	const [inputValue, setInputValue] = useState<string>("");
	const [isInTable, setIsInTable] = useState<boolean>(false);
	const [isInModal, setIsInModal] = useState<boolean>(false);
	const [componentId] = useState(() => uniqueId++);
	const [date, setDate] = useState<Date>(currentDate);
	const [today] = useState(new Date());
	const inputRef = useRef<HTMLDivElement>(null);
	const calendarRef = useRef<HTMLDivElement>(null);
	const monthPickerRef = useRef<HTMLDivElement>(null);
	const yearPickerRef = useRef<HTMLDivElement>(null);

	const handleOnChangeDate = (date: Date, action: DateActionType) => {
		onDateChange(date, action);
		setDate(date);
	};
	const handleOnClickOnDay = (day: number) => {
		const month = date.getMonth();
		const year = date.getFullYear();

		const newDate = new Date(
			year,
			month,
			day,
			date.getHours(),
			date.getMinutes(),
		);
		handleOnChangeDate(newDate, "click");
		setShowCalendar(false);
	};

	const handleOnChangeMonth = (month: number) => {
		const year = date.getFullYear();
		const day = date.getDay();
		handleOnChangeDate(
			new Date(year, month, day, today.getHours()),
			"switch_month",
		);
		setShowMonthSelection(false);
	};
	useEffect(() => {
		const table = document.querySelector('[id*="table"]');
		const dateInput = inputRef.current;
		if (!dateInput) return;
		setIsInTable(!!table && table.contains(dateInput));
		if (!!table && table.contains(dateInput)) {
			return;
		}
		const modal = document.getElementById("modal-body");
		setIsInModal(!!modal && modal.contains(dateInput));
	}, [showCalendar]);

	useEffect(() => {
		if (!showCalendar) return;
		const datePicker = calendarRef.current;
		if ((isInTable || isInModal) && datePicker) {
			// IF IS IN TABLE OR MODAL
			datePicker.style.transform = "";
			const selectRect = inputRef.current?.getBoundingClientRect();
			datePicker.style.top = `${selectRect?.bottom}px`;
			datePicker.style.left = `${selectRect?.left}px`;
			datePicker.style.position = "fixed";
		}
	}, [showCalendar]);

	const handleOnChangeYear = (year: number) => {
		const day = date.getDay();
		const month = date.getMonth();
		handleOnChangeDate(
			new Date(year, month, day, today.getHours()),
			"switch_year",
		);
		setShowYearSelection(false);
	};

	const handleOnChange = (e: any) => {
		clearTimeout(inputTimeout);

		const value = e.target.value;

		const [day, month, year] = value.split("/");
		setInputValue(e.target.value);
		const today = new Date();
		const date = new Date(year, month - 1, day, today.getHours());
		if (date.getDate() && year.length > 3) {
			setInputTimeout(setTimeout(() => handleOnChangeDate(date, "input"), 500));
		}
	};

	const handleOnClickOnInput = () => {
		if (isMobile) {
			const inputMobile = document.getElementById("date-picker-mobile");
			inputMobile?.focus();
			inputMobile?.click();
		} else {
			setShowCalendar(true);
		}
	};

	const dateToString = (date: Date) => {
		let day = `${date.getDate()}`;
		if (Number(day) < 10) {
			day = `0${day}`;
		}
		let month = `${date.getMonth() + 1}`;
		if (Number(month) < 10) {
			month = `0${month}`;
		}
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	};

	useEffect(() => {
		if (selectedDate?.getDate()) {
			const date = new Date(selectedDate);
			date.setHours(date.getHours() + 2);
			setDate(date);
			setInputValue(dateToString(date));
		}
	}, [selectedDate]);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			const isOutsideOfInput =
				inputRef.current && !inputRef.current.contains(event.target);
			const isOutsideOfCalendar =
				calendarRef.current && !calendarRef.current.contains(event.target);
			const isOutsideOfMonthPicker =
				monthPickerRef.current &&
				!monthPickerRef.current.contains(event.target);
			const isOutsideOfYearPicker =
				yearPickerRef.current && !yearPickerRef.current.contains(event.target);

			if (isOutsideOfInput && isOutsideOfCalendar) {
				setShowCalendar(false);
			}
			if (isOutsideOfMonthPicker) {
				setShowMonthSelection(false);
			}
			if (isOutsideOfYearPicker) {
				setShowYearSelection(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className={`layout-date-picker relative ${className}`}>
			<div
				ref={inputRef}
				id={`date-input-${componentId}`}
				onClick={handleOnClickOnInput}
			>
				<InputLargePrimary
					label={label}
					errorMessage={errorMessage}
					icon={withIcon ? calendarOutline : null}
					onChange={handleOnChange}
					value={inputValue}
					disabled={disabled}
					placeholder={placeholder || "01/01/2001"}
				/>
			</div>

			{showCalendar && (
				<div
					id={`date-picker-${componentId}`}
					style={{ width: 324, zIndex: 20 }}
					ref={calendarRef}
					className="day-picker top-100 absolute left-0 mt-2 rounded-md bg-white p-sm"
				>
					<div className="date-label flex items-center justify-between">
						<div className="flex items-center">
							<p
								onClick={() => setShowMonthSelection(true)}
								className="month-label"
							>
								{FULL_MONTH_LABELS[date.getMonth()]}
							</p>
							<p
								onClick={() => setShowYearSelection(true)}
								className="year-label"
							>
								{date.getFullYear()}
							</p>
						</div>
						{!disabled && (
							<Navigate
								onClickLeft={() => handleOnChangeMonth(date.getMonth() - 1)}
								onClickRight={() => handleOnChangeMonth(date.getMonth() + 1)}
							/>
						)}
					</div>

					<MonthPopUp
						ref={monthPickerRef}
						handleOnChangeMonth={handleOnChangeMonth}
						selectedMonth={date.getMonth()}
						show={showMonthSelection}
					/>

					<YearPopUp
						ref={yearPickerRef}
						handleOnChangeYear={handleOnChangeYear}
						selectedYear={date.getFullYear()}
						show={showYearSelection}
					/>

					<div className="months">
						{SHORT_DAY_LABELS.map((m, index) => (
							<div key={index} className="month-item">
								<p className="text-xsm text-primary-200">{m}</p>
							</div>
						))}
					</div>

					<DaysPanel
						selectedDate={date}
						handleOnClickOnDay={handleOnClickOnDay}
					/>
				</div>
			)}
		</div>
	);
};
