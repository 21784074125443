import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import NoAccessIllustration from "@assets/illustrations/403.svg";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
const REDIRECT_TIMEOUT = 5;
export function OfferExpired() {
	const navigate = useNavigate();
	const [redirectTimeout, setRedirectTimeout] =
		useState<number>(REDIRECT_TIMEOUT);
	useEffect(() => {
		setTimeout(() => {
			navigate("/school-offers");
		}, REDIRECT_TIMEOUT * 1000);
		const interval = setInterval(() => {
			setRedirectTimeout((prev) => prev - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	return (
		<main className="errors">
			<div className="main-content flex flex-col items-center justify-center">
				<img alt="Une femme cherche une direction" src={NoAccessIllustration} />
				<h2 className="mt-md text-lg font-bold text-primary-900 md:text-3xl">
					Offre expiré
				</h2>
				<p className="mt-md text-center text-xxsm text-primary-300 md:text-xsm">
					l'offre a expiré, restez à l'affut de futures opportunités
				</p>
				<ButtonPrimary
					className="mt-8 md:mt-6"
					onClick={() => navigate("/school-offers")}
					label={`Redirection dans ${redirectTimeout}`}
				/>
			</div>
		</main>
	);
}
