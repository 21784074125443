import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { deleteApplication } from "../../../core/api/ApplicationDetailAPI";

export function DeleteModal({ show, onClose, applicationProps }) {
	const navigate = useNavigate();

	const { application } = applicationProps;

	const handleDeleteApplication = () => {
		deleteApplication(application.id).then(() => {
			navigate("/applications");
		});
	};

	return (
		<Modal
			title="Confirmation de suppression"
			body={
				<p className="text-xsm text-primary-700P">
					Souhaites-tu vraiment supprimer cette candidature ? Cela entrainera
					une action irréversible.
				</p>
			}
			show={show}
			onClose={onClose}
			buttonsRight={[
				<ButtonSecondary key="cancelBTN" label="Annuler" onClick={onClose} />,
				<ButtonPrimary
					key="confirmBTN"
					label="Confirmer"
					onClick={handleDeleteApplication}
				/>,
			]}
		/>
	);
}

DeleteModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	handleDeleteApplication: PropTypes.func.isRequired,
};
