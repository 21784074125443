import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { type StandardGraphQLResponse, extractError } from "@tools/API";
import { success } from "@tools/Toasts";
import { eventsSlice } from "../store/events.slice";

export const fetchRelaunchIndividualInvitation =
	(eventId: string, studentId: string, eventType: string) =>
	async (
		dispatch: AppDispatch,
		_api: AppGetState,
		dependencies: Dependencies,
	): Promise<boolean> => {
		try {
			if (!dependencies.eventsGateway) {
				throw new Error("eventsGateway is not available in dependencies");
			}
			const fetchingResponse: StandardGraphQLResponse =
				await dependencies.eventsGateway.relaunchIndividualInvitation(
					eventId,
					studentId,
					eventType,
				);
			if (fetchingResponse.responseLabel !== "success") {
				throw new Error(fetchingResponse.message);
			}
			success("Invitation relancée avec succès");
			return true;
		} catch (error: unknown) {
			dispatch(
				eventsSlice.actions.handleRelaunchIndividualInvitationError(
					extractError(error).message.toString(),
				),
			);
			return false;
		}
	};
