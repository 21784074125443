import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { CheckboxLabelPrimary } from "@designSystem/molecules/CheckboxLabel";
import { SelectCompany } from "@designSystem/organisms/select/SelectCompany";

import type { SchoolOfferType } from "@/types/offerTypes";
import type { AppState } from "@config/redux/store";
import { checkCrmCompany } from "@containers/school/Offers_v3/core/api/offersManagement.request";
import { getCompanyList } from "@containers/school/Offers_v3/core/api/offersManagement.request";
import { errorToast } from "@tools/Toasts";
import { extractIdNumberFromId } from "@tools/Users";
import { useLocation } from "react-router";
import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

function CompanyNameModifier() {
	const { state } = useLocation();

	const [entCompanyLoading, setEntCompanyLoading] = useState(!!state?.entOffer);

	const dispatchEvent = useDispatch();
	const { offerToCreate }: { offerToCreate: Partial<SchoolOfferType> } =
		useAppSelector((state: AppState) => state.offers);

	const handleAnonymousChange = (value: boolean) => {
		dispatchEvent(modifyOfferToCreate({ anonymous: value }));
	};

	const handleCompanySelection = (companySelected: string) => {
		dispatchEvent(
			modifyOfferToCreate({
				crmCompany: { name: companySelected },
			}),
		);
	};

	const handleCheckEntCompany = async () => {
		try {
			const { data } = await checkCrmCompany(
				extractIdNumberFromId(state.entOffer.company.id),
			);
			if (data?.data?.name) {
				state.entOffer.company.crmCompany = data.data;
				handleCompanySelection(data.data.name);
			}
		} catch (e) {
			if (e.response?.status === 409) return;
			errorToast("Erreur lors de la récupération de l'entreprise");
		} finally {
			setEntCompanyLoading(false);
		}
	};

	useEffect(() => {
		if (state?.entOffer) {
			if (!state.entOffer.company.crmCompany) {
				handleCheckEntCompany();
			} else {
				handleCompanySelection(state.entOffer.company.crmCompany.name);
			}
		}
	}, [state?.entOffer?.company.crmCompany]);

	useEffect(() => {
		if (offerToCreate?.anonymous === undefined) {
			dispatchEvent(modifyOfferToCreate({ anonymous: false }));
		}
	}, [offerToCreate]);

	return (
		<div data-testid="organizationName" className="w-full">
			<div className="flex w-full flex-col items-end justify-start gap-xsm ">
				<SelectCompany
					disabled={entCompanyLoading}
					handleNewCompany={handleCompanySelection}
					defaultValue={offerToCreate?.crmCompany}
					getCompanyList={getCompanyList}
				/>
			</div>
			<div
				className="flex items-center justify-start gap-1"
				data-testid="checkbox-anonymous"
			>
				<CheckboxLabelPrimary
					disabled={entCompanyLoading}
					className="primary-50 mt-3"
					label="Rendre anonyme pour les étudiants"
					checked={offerToCreate?.anonymous || false}
					onCheck={(e: boolean) => {
						handleAnonymousChange(e);
					}}
				/>
				<TooltipElement id="tooltip-anonymous" />
				<TooltipContainer
					anchorId="#tooltip-anonymous"
					makeClickable={false}
					place="bottom"
				>
					Les étudiants ne verront pas le nom de l'entreprise.
				</TooltipContainer>
			</div>
		</div>
	);
}

export default CompanyNameModifier;
