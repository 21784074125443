import type { OfferApplicationType, SchoolOfferType } from "@/types/offerTypes";
import { InlineIcon } from "@iconify/react";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";

import { arrowBackFill, infoOutline } from "@assets/Icons";

import { OFFER_CREATION_HEADER_TOOLTIP } from "@constants/Offers";

import { extractIdNumberFromId } from "@tools/Users";

import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";

import {
	clearDataFetched,
	setOfferToCreate,
} from "../../core/store/offersManagement.slice";

type CreationHeaderProps = {
	dataToUse: {
		offerToEdit: Partial<SchoolOfferType> | undefined;
		offerToCreate: Partial<SchoolOfferType> | undefined;
	};
	editionMode?: boolean;
};
export function OfferCreationHeader({
	dataToUse,
	editionMode,
}: CreationHeaderProps) {
	const [params] = useSearchParams();
	const dispatchEvent = useDispatch();
	const getTypeFromData = (): OfferApplicationType => {
		if (dataToUse?.offerToEdit?.type) {
			return dataToUse.offerToEdit.type;
		}
		if (dataToUse?.offerToCreate?.type) {
			return dataToUse.offerToCreate.type;
		}
		return "direct";
	};

	const handleBackButton = () => {
		dispatchEvent(clearDataFetched());
		dispatchEvent(setOfferToCreate(undefined));
	};

	const getu = () => {
		const u = params.get("u");
		if (u) {
			return u;
		}
		return editionMode && dataToUse.offerToEdit
			? `/offers/details/${extractIdNumberFromId(dataToUse?.offerToEdit?.id)}`
			: "/offers";
	};

	return (
		<section className="flex min-h-[72px] w-full items-center justify-start gap-4 rounded-lg bg-white pl-2">
			<NavLink to={getu()}>
				<ButtonQuinary icon={arrowBackFill} onClick={handleBackButton} />
			</NavLink>
			<span
				className="text-sm font-bold text-primary-700P"
				data-testid="offerCreationPageTitle"
			>
				{editionMode
					? `Modifier ${dataToUse?.offerToEdit?.job}`
					: `Créer une offre ${
							dataToUse?.offerToCreate?.type === "direct"
								? "directe étudiant"
								: "avec présélection"
						}`}
			</span>
			<span
				className="flex h-6 items-start justify-center"
				id="tooltipOffersHeader"
			>
				<InlineIcon icon={infoOutline} className="h-4 w-4 text-primary-300" />
			</span>
			<TooltipContainer
				anchorId="#tooltipOffersHeader"
				makeClickable={false}
				place="top"
				children={OFFER_CREATION_HEADER_TOOLTIP.get(getTypeFromData())}
			/>
		</section>
	);
}
