/**
 * @file API requests for offers management : list view, creation, delete
 */

import type { CampusesCollectionType } from "@/types/campusTypes";
import type { SchoolOfferType } from "@/types/offerTypes";
import type { OfferFiltersType } from "@/types/offersFiltersTypes";
import type { FetchOffersListParamsType } from "@/types/offersRequestTypes";

import API, { checkGraphQLError, graphqlRequestConstructor } from "@tools/API";
import { extractCampusList } from "@tools/Campuses";
import { stringifyRequestInputs } from "@tools/Objects";

export const formatDataBeforeRequest = (
	offerData: Partial<SchoolOfferType>,
) => {
	// disable this rule because we need to remove some fields from the offerData before sending it to the API
	const {
		id,
		date,
		views,
		studentGetJob,
		crmCompany,
		applicationsCount,
		applicationsToTreat,
		sharesCount,
		updateAt,
		...restOfferData
	} = offerData;
	const formattedData: any = { ...restOfferData };

	const campusCollection: string[] = extractCampusList(
		offerData?.campuses as unknown as CampusesCollectionType,
	);

	if (campusCollection) {
		formattedData.campuses = campusCollection;
	}

	if (crmCompany?.name) {
		formattedData.crmCompanyName = crmCompany.name;
	}
	if (offerData?.pdf) {
		formattedData.pdf = offerData?.pdf["@id"] || offerData.pdf?.id;
	}
	if (offerData?.entOffer) {
		formattedData.entOffer = offerData.entOffer?.id;
	}

	if (offerData?.responsable) {
		formattedData.responsable = offerData.responsable?.id;
	}
	return formattedData;
};

export const formatFiltersBeforeRequest = (
	filters: OfferFiltersType,
): string => {
	const positionFilter: string = filters.position
		? `,position: [${filters.position[0]}, ${filters.position[1]}], max_distance: 50`
		: "";

	const visibilityFilter: string = filters?.visibility
		? `,visibility: "${filters?.visibility}"`
		: "";

	const referentFilter: string = filters?.referent
		? `,responsable: "${filters?.referent}"`
		: "";

	const typeFilter: string = filters?.type ? `,type: "${filters?.type}"` : "";

	const tagsFilter: string = filters?.tags
		? `,tags: {list: [${filters?.tags?.list?.map(
				(tag) => `"${tag}"`,
			)}], operator: ${filters?.tags?.operator}}`
		: "";

	const contractTypeFilter: string = filters?.contract
		? `,contract: "${filters?.contract}"`
		: "";

	return ` ${positionFilter}${visibilityFilter}${referentFilter}${typeFilter}${tagsFilter}${contractTypeFilter}`;
};
export async function getTags() {
	const data = {
		query: `
      query {
        tags (itemsPerPage: 1000){
          collection {
            id
            name
            color
            type
          }
        }
      }
    `,
	};

	try {
		const response = await API.post("graphql", data);
		return response.data.data.tags.collection;
	} catch (error) {
		throw new Error(error);
	}
}

export async function checkCrmCompany(entCompanyId: number) {
	const response = await API.get(`check_create_company/${entCompanyId}`);
	if (response.status !== 200) {
		throw new Error(response.statusText);
	}
	return response;
}

export async function createOffer(offerData: Partial<SchoolOfferType>) {
	try {
		const formattedData: Partial<SchoolOfferType> =
			formatDataBeforeRequest(offerData);
		const queryToSend = {
			query: `mutation{
        createOffer(input:${stringifyRequestInputs(formattedData)}){
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(response, "Erreur lors de la création de l'offre");
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}
export async function getOffers(params: FetchOffersListParamsType) {
	const queryToSend = {
		query: `
      query {
        collectionQueryOffers (
          school: "${params.schoolId}",
          campuses: "${params.campuses}",
          ${params.order}, status: "${params.status}", 
          referentSearch: "${params.search}", page: ${params.page}, 
          itemsPerPage: ${params.itemsPerPage}${formatFiltersBeforeRequest(
						params.filters,
					)}) {
          collection {
            id
            responsable {
              id
              firstname
              lastname
              avatar{
                filePath
              }
            }
            entOffer {
            	id
            }
            crmCompany {
              id
              name
              website
            }
            campuses{
              collection{
                id
                name
              }
            }
            tags
            pdf {
              id
              filePath
              size
            }
            description
            date
            anonymous
            job
            status
            views
            contract
            type
            visibility
            telework
            qualification
            motivationLetterRequired
            location
            latitude
            longitude
            applicationsCount
            applicationsToTreat
            applicationsToSend
            sharesCount
            updateAt
            expirationDate
            wayToApply
            closeAt
            closeReason
            externalId
            externalLink
            sentToCompanyCount
            openPositionsCount
            studentGetJob: conContract{
              student: spsStudent {
                id
                firstname
                lastname
                avatar{
                  filePath
                }
              }
            }         
          }
          paginationInfo {
            totalCount
            lastPage
          }
        }
      }`,
	};
	return graphqlRequestConstructor(
		queryToSend,
		"getOffers",
		"Erreur lors de la récupération des offres",
	);
}
export async function getCounters(params: FetchOffersListParamsType) {
	let structureViewFilters = "";
	if (params.campuses) {
		structureViewFilters = `campuses: "${params.campuses}"`;
	} else if (params.schoolId) {
		structureViewFilters = `school: "${params.schoolId}"`;
	}

	const queryToSend = {
		query: `
      query {
        onlineCounter: collectionQueryOffers (${structureViewFilters}, status: "online", referentSearch: "${
					params.search
				}", page: ${params.page} ${formatFiltersBeforeRequest(params.filters)}){
          paginationInfo {
            totalCount
          }
        }
        providedCounter: collectionQueryOffers (${structureViewFilters}, status: "provided", referentSearch: "${
					params.search
				}", page: ${params.page} ${formatFiltersBeforeRequest(params.filters)}){
          paginationInfo {
            totalCount
          }
        }
        notProvidedCounter: collectionQueryOffers (${structureViewFilters},status: "notProvided",referentSearch: "${
					params.search
				}", page: ${params.page} ${formatFiltersBeforeRequest(params.filters)}){
          paginationInfo {
            totalCount
          }
        }
        draftCounter: collectionQueryOffers (${structureViewFilters}, status: "draft", referentSearch: "${
					params.search
				}", page: ${params.page} ${formatFiltersBeforeRequest(params.filters)}){
          paginationInfo {
            totalCount
          }
        }
        expiredCounter: collectionQueryOffers (${structureViewFilters}, status: "expired",referentSearch: "${
					params.search
				}", page: ${params.page} ${formatFiltersBeforeRequest(params.filters)}){
          paginationInfo {
            totalCount
          }
        }
      }`,
	};
	return graphqlRequestConstructor(queryToSend, "getOffersCounters");
}

export async function getReferents(campusList: string[], schoolId: string) {
	const queryToSend = {
		query: `
      query {
        campusReferents : spsReferents (itemsPerPage: 1000, coreCampuses_list: ${stringifyRequestInputs(
					campusList,
				)}){
            collection {
                id
                firstname
                lastname
              }
            }
        schoolReferents: spsReferents (itemsPerPage: 1000, coreSchool: "${schoolId}"){
            collection {
                id
                firstname
                lastname
                avatar{
                  filePath
                }
            }
        }
    }
      `,
	};
	return graphqlRequestConstructor(
		queryToSend,
		"getOffersReferents",
		"Erreur lors de la récupération des référents",
	);
}

export async function getOfferMemo(id: string) {
	const queryToSend = {
		query: `
      query {
        offer (id: "${id}") {
          memo
        }
      }`,
	};

	return graphqlRequestConstructor(
		queryToSend,
		"getOffersMemo",
		"Récupération du mémo de l'offre impossible",
	);
}

export async function getCompanyList(companyToSearch: string) {
	try {
		const queryToSend = {
			query: `
      query {
        crmCompanies (name: "${companyToSearch}") {
          collection {
              id
              name
              website
          }
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Récupération des entreprises impossible",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function getCampusesList(schoolId: string) {
	try {
		const queryToSend = {
			query: `
      query {
        campuses  (itemsPerPage: 1000, school: "${schoolId}", order: [{name: "ASC"}]){
          collection {
            id
            name
          }
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(response, "Récupération des campus impossible");
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function provideOffer(offerId: string, studentId: string) {
	try {
		const queryToSend = {
			query: `mutation {
        createConContract (input: {spsStudent:"${studentId}", 
        offer: "${offerId}"}) {
            conContract {
                id
            }
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function deleteOffer(id: string) {
	try {
		const queryToSend = {
			query: `mutation{
        deleteOffer(input:{id:"${id}"}){
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la suppression de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}
