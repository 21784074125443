import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export function ResourcesLoader({ tab }: { tab: string }) {
	const bgColor = [
		"bg-emerald-light",
		"bg-terracotta-light",
		"bg-sakura-light",
		"bg-kaki-light",
		"bg-aquamarine-light",
		"bg-sunflower-light",
		"bg-lavender-light",
		"bg-stone-light",
	];

	const skeletonColor = [
		"#BFE2DE",
		"#e3bbab",
		"#F3D7E8",
		"#c5caaa",
		"#b7daf1",
		"#f1d99e",
		"#CAB6EB",
		"#C8D3DC",
	];

	const resourcesSkeletons = Array(12)
		.fill("")
		.map((_, i) => {
			const randomValue = Math.floor(Math.random() * bgColor.length);

			return {
				bgColor: bgColor[randomValue],
				skeletonColor: skeletonColor[randomValue],
				id: i,
			};
		});
	return (
		<div className="container-ressources">
			<p className="title-school mb-md w-full text-sm uppercase text-primary-300">
				Les ressources de ton école
			</p>
			{tab === "resources" ? (
				<div className="school-resources flex w-full flex-wrap justify-between p-0 grid-list">
					{resourcesSkeletons.map(({ bgColor, skeletonColor, id }) => (
						<div
							className={`rounded-lg relative shadow-sm outline-none overflow-hidden cursor-pointer w-full sm:w-[48%] md:w-[32%] lg:w-[23%] ${bgColor} flex flex-col items-start pt-[144px]`}
							key={`resource-loader-${id}`}
						>
							<div className="p-md w-full bg-white h-[200px]">
								<Skeleton
									className="md:w-full"
									height={24}
									baseColor={skeletonColor}
								/>
								<Skeleton
									className="mt-sm md:w-full"
									height={12}
									width={80}
									baseColor={skeletonColor}
								/>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="school-resources flex w-full gap-y-sm flex-wrap justify-between p-0">
					{resourcesSkeletons.map(({ bgColor, skeletonColor, id }) => (
						<div
							className={`relative px-xsm py-md cursor-pointer shadow-sm rounded-lg aspect-[9/10] w-full sm:w-[48%] md:w-[32%] lg:w-[24%] ${bgColor}`}
							key={`card-loader-${id}`}
						>
							<div className="tag-container ml-xxsm">
								<Skeleton
									className="ml-xsm mt-xsm"
									baseColor={skeletonColor}
									height={18}
									width={90}
								/>
							</div>
							<div className="flex h-full w-full items-center justify-center">
								<Skeleton
									className="mr-xsm mt-xsm"
									baseColor={skeletonColor}
									height={24}
									width={150}
								/>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}
