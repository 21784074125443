import type { TagType } from "@/types/tagTypes";
import React from "react";

import { Tag } from "@designSystem/atoms/Tag";

type TagsType = {
	tags: TagType[];
};
export function Tags({ tags }: TagsType) {
	return (
		<div>
			<p className="my-xsm text-sm font-bold text-primary-700P">Tags</p>
			<div className="my-xsm flex flex-wrap gap-sm">
				{tags.map((tag: TagType) => {
					return <Tag label={tag.name} bgColor={tag.color} />;
				})}
			</div>
		</div>
	);
}
