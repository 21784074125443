import type { ApplicationType } from "@/types/applicationTypes";
import type { SchoolOfferType, ScrapedOfferType } from "@/types/offerTypes";
import type { UserType } from "@/types/userTypes";
import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "@tools/Cookies";
import { errorToast } from "@tools/Toasts";
import {
	fetchSchoolOffers,
	fetchSchoolOffersCount,
	fetchScrapedOffers,
} from "./jobboard.thunks";

export type JobboardFiltersType = {
	tab?: string | "schoolOffers" | "jobboardsOffers" | "jobboardAndExtension";
	page?: number;
	search?: string;
	position?: [number, number];
	location?: string;
	max_distance?: number;
	contract?: string;
	jobboard_list?: Array<string>;
	exists?: {
		school: boolean;
	};
	school?: string | null;
};
type JobboardSliceState = {
	tab: string;
	schoolOffers: Array<SchoolOfferType>;
	scrapedOffers: Array<ScrapedOfferType>;
	lastPage: number;
	loadSchoolOffers: boolean;
	loadScrapedOffers: boolean;
	totalSchoolCount: number;
	totalScrapedCount: number;
	user: UserType | undefined;
	filters: JobboardFiltersType;
	userApplications: Array<ApplicationType>;
	jobboardWasInitialized: boolean;
};
const getSettingsCookie = (name: string, defaultValue: any) => {
	const cookie = getCookie(name);
	if (cookie) {
		return { ...JSON.parse(getCookie(name) as string) };
	}
	return defaultValue;
};
const getTabCookie = () => {
	const tabCookie = getSettingsCookie("JobboardFilters", "schoolOffers");
	return typeof tabCookie === "string" ? tabCookie : tabCookie?.tab;
};

const initialState: JobboardSliceState = {
	loadSchoolOffers: false,
	loadScrapedOffers: false,
	tab: getTabCookie(),
	schoolOffers: [],
	scrapedOffers: [],
	lastPage: 1,
	totalSchoolCount: 0,
	totalScrapedCount: 0,
	user: undefined,
	filters: getSettingsCookie("JobboardFilters", {
		page: 1,
	}),
	userApplications: [],
	jobboardWasInitialized: false,
};

const jobboardSlice = createSlice({
	name: "JobboardSlice",
	initialState,
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		addUserApplication(state, action) {
			const applications = [...state.userApplications];
			applications.push(action.payload);
			state.userApplications = applications;
		},
		setUserApplications(state, action) {
			state.userApplications = action.payload;
		},
		setFilters(state, action) {
			state.filters = action.payload;
		},
		setTotalScrapedOffersCount(state, action) {
			state.totalScrapedCount = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchSchoolOffers.pending, (state) => {
			state.schoolOffers = [];
			state.lastPage = 1;
			state.totalSchoolCount = 0;
			state.loadSchoolOffers = true;
		});
		builder.addCase(fetchSchoolOffers.fulfilled, (state, action) => {
			state.schoolOffers = action.payload.data.collectionQueryOffers.collection;
			state.lastPage =
				action.payload.data.collectionQueryOffers.paginationInfo.lastPage;
			state.totalSchoolCount =
				action.payload.data.collectionQueryOffers.paginationInfo.totalCount;

			if (state.jobboardWasInitialized === false) {
				state.jobboardWasInitialized = true;
			}
			state.loadSchoolOffers = false;
		});
		builder.addCase(fetchSchoolOffers.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				errorToast(
					"Une erreur est survenue lors de la récupération des données.",
				);
				state.loadSchoolOffers = false;
			}
		});
		builder.addCase(fetchSchoolOffersCount.pending, (state) => {
			state.loadSchoolOffers = true;
		});
		builder.addCase(fetchSchoolOffersCount.fulfilled, (state, action) => {
			state.totalSchoolCount =
				action.payload.data.collectionQueryOffers.paginationInfo.totalCount;
			state.loadSchoolOffers = false;
		});
		builder.addCase(fetchSchoolOffersCount.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				errorToast(
					"Une erreur est survenue lors de la récupération des données.",
				);
				state.loadSchoolOffers = false;
			}
		});
		builder.addCase(fetchScrapedOffers.pending, (state) => {
			state.loadScrapedOffers = true;
		});
		builder.addCase(fetchScrapedOffers.fulfilled, (state, action) => {
			state.scrapedOffers =
				action.payload.data.collectionQueryOffers.collection;
			state.lastPage =
				action.payload.data.collectionQueryOffers.paginationInfo.lastPage;
			state.totalScrapedCount =
				action.payload.data.collectionQueryOffers.paginationInfo.totalCount;
			if (state.jobboardWasInitialized === false) {
				state.jobboardWasInitialized = true;
			}
			state.loadScrapedOffers = false;
		});
		builder.addCase(fetchScrapedOffers.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				errorToast(
					"Une erreur est survenue lors de la récupération des données.",
				);
				state.loadScrapedOffers = false;
			}
		});
	},
});

export const {
	setTab,
	setUserApplications,
	addUserApplication,
	setFilters,
	setTotalScrapedOffersCount,
} = jobboardSlice.actions;

export default jobboardSlice.reducer;
