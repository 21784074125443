import { Spinner } from "@designSystem/atoms/Spinner";
import { BodyGrid } from "@designSystem/templates/page/BodyGrid";
import { Container } from "@designSystem/templates/page/Container";
import { Subcontainer } from "@designSystem/templates/page/Subcontainer";
import React, { Suspense, lazy, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { ContactSkeletons } from "../../ui/skeletons/ContactSkeletons";
import { HeaderSkeletons } from "../../ui/skeletons/HeaderSkeletons";
import { InformationsSkeletons } from "../../ui/skeletons/InformationsSkeletons";
import { OffersSkeletons } from "../../ui/skeletons/OffersSkeletons";
import { useCompanyDetails } from "./useCompanyDetails";

const CompanyInformations = lazy(
	() => import("./sections/CompanyInformations"),
);
const CompanyOffers = lazy(() => import("./sections/CompanyOffers"));

const CompanyHeader = lazy(() => import("./sections/CompanyHeader"));

const CompanyRelations = lazy(() => import("./sections/CompanyRelations"));

const CompanyContact = lazy(() => import("./sections/CompanyContact"));

const CompanyMemo = lazy(() => import("./sections/CompanyMemo"));

export function CompanyDetails() {
	const { setTitle }: { structureView: object; setTitle: Function } =
		useOutletContext();
	const presenter = useCompanyDetails();

	useEffect(() => {
		setTitle("Gestion des entreprises");
		presenter.startCompanyIdExtraction();
	}, []);

	useEffect(() => {
		presenter.retrieveAllReferents();
	}, [presenter.user]);

	return (
		<main data-testid="company-details" className="flex flex-col gap-6">
			{presenter.isExtractingId ? (
				<div className="flex h-[70vh] w-full flex-col items-center justify-center gap-6">
					<p className="text-base font-bold text-primary-700P">
						Chargement de l'entreprise
					</p>
					<Spinner />
				</div>
			) : (
				<div className="flex flex-col gap-10">
					<Suspense fallback={<HeaderSkeletons />}>
						<CompanyHeader resetFunction={presenter.resetDetailsState} />
					</Suspense>
					<BodyGrid>
						<Container side="left" reversedGrid>
							<Subcontainer>
								<Suspense fallback={<InformationsSkeletons />}>
									<CompanyRelations />
								</Suspense>
							</Subcontainer>
							<Subcontainer>
								<Suspense fallback={<ContactSkeletons />}>
									<CompanyContact />
								</Suspense>
							</Subcontainer>
							<Subcontainer>
								<Suspense fallback={<InformationsSkeletons />}>
									<CompanyInformations />
								</Suspense>
							</Subcontainer>
						</Container>

						<Container side="right" reversedGrid>
							<Subcontainer withoutBackground>
								<Suspense fallback={<Spinner />}>
									<CompanyMemo />
								</Suspense>
							</Subcontainer>
							<Subcontainer withoutBackground>
								<Suspense fallback={<OffersSkeletons />}>
									<CompanyOffers />
								</Suspense>
							</Subcontainer>
						</Container>
					</BodyGrid>
				</div>
			)}
		</main>
	);
}
