import PropTypes from "prop-types";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

import "../StudentDetail.scss";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";
import { History } from "@modules/logHistory/History";

import { Applications } from "./Informations/Applications";
import { CV } from "./Informations/CV";
import { Career } from "./Informations/Career";
import Goals from "./Informations/Goals";
import { Notes } from "./Informations/Notes";

export function Informations({
	applications,
	student,
	setStudent,
	updateData,
	formations,
	professionalExperiences,
	projects,
	load,
}) {
	const [tabIndex, setTabIndex] = useState(
		window.location.pathname.split("/")[1] === "cvboard" ? 4 : 0,
	);
	const arrayAppLayout = [];
	arrayAppLayout.length = 6;
	arrayAppLayout.fill("");
	const returnTab = (index) => {
		switch (index) {
			case 1:
				return (
					<Career
						studentProps={student}
						formations={formations}
						professionalExperiences={professionalExperiences}
						projects={projects}
					/>
				);
			case 2:
				return <Notes student={student} setStudent={setStudent} />;
			case 3:
				return <Goals studentId={student.id} />;
			case 4:
				return <CV student={student} updateData={updateData} />;
			case 5:
				return (
					<History
						className=""
						currentStudent={student}
						showByDefault={true}
						filters={{
							action_list: [
								"submitCV",
								"modifyCV",
								"deleteCV",
								"cvToModify",
								"cvValidated",
								"cvReminder",
								"loginReminder",
								"shareJobOffer",
								"createStudent",
								"loginReminder",
								"changeStatus",
								"completeProfile",
								"modifyProfile",
								"submitMotivationLetter",
								"deleteMotivationLetter",
								"responseToEvent",
								"createSpontaneousApplication",
								"firstConnection",
								"creatingAnApplication",
								"applicationSent",
								"relaunch",
								"interviewObtained",
							],
							page: 1,
						}}
					/>
				);
			default:
				return <Applications applications={applications} student={student} />;
		}
	};

	if (load) {
		return (
			<div className="mb-md mt-md w-full rounded-lg bg-white p-lg">
				<div className="mt-md flex flex-wrap">
					<Skeleton className="mt-sm w-full" height={24} width={100} />
					<Skeleton className="ml-sm mt-sm w-full" height={24} width={100} />
					<Skeleton className="ml-sm mt-sm w-full" height={24} width={100} />
					<Skeleton className="ml-sm mt-sm w-full" height={24} width={100} />
					<Skeleton className="ml-sm mt-sm w-full" height={24} width={100} />
				</div>
				<div className="mb-md mt-md w-full rounded-lg bg-white">
					{arrayAppLayout.map((i) => {
						return (
							<div key={i + Math.random()} className="mt-lg flex items-center">
								<Skeleton className="" circle height={40} width={40} />
								<div className="w-full">
									<Skeleton className="ml-sm" height={16} />
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
	return (
		<div className="mt-md flex w-full flex-col rounded-lg bg-white px-md">
			<ScrollableTabGroup
				sizeSwitch="lg"
				tabs={[
					{ label: "Candidatures", active: tabIndex === 0 },
					{ label: "Parcours", active: tabIndex === 1 },
					{ label: "Suivi", active: tabIndex === 2 },
					{ label: "Objectifs", active: tabIndex === 3 },
					{ label: "CV", active: tabIndex === 4 },
					{ label: "Historique", active: tabIndex === 5 },
				]}
				onTabChange={(i) => {
					setTabIndex(i);
				}}
				className="mt-lg"
			/>
			{returnTab(tabIndex)}
		</div>
	);
}

Informations.propTypes = {
	applications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	student: PropTypes.shape({}).isRequired,
	updateData: PropTypes.func.isRequired,
};
