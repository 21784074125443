import React, { type ReactNode } from "react";

/***
 * @description A container is a wrapper for a section of a page.
 * @example a right panel or a left pannel in a page.
 * @param {ReactNode} children - The content of the container.
 * @param {string} side - The side of the container.
 * @param {boolean} reversedGrid - If the grid is reversed. Exemple: left pannel are smaller than right pannel.
 * @returns {JSX.Element} The container component.
 */
export function Container({
	children,
	side,
	reversedGrid = false,
	overrideWidth = null,
}: {
	children: ReactNode;
	side: "left" | "right";
	reversedGrid?: boolean;
	overrideWidth?: string | null;
}) {
	return (
		<section
			className={`flex grow flex-col gap-6 mb-12 ${
				side === "left"
					? `${
							reversedGrid ? "max-w-[40%] basis-2/5" : "max-w-[60%] basis-3/5"
						} items-center justify-start`
					: `${
							reversedGrid ? "max-w-[60%] basis-3/5" : "max-w-[40%] basis-2/5"
						} items-start`
			} ${overrideWidth !== null && overrideWidth}`}
			data-testid={`pannel-${side}`}
		>
			{children}
		</section>
	);
}
