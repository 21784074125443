import API, { checkGraphQLError, graphqlRequestConstructor } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";
import { errorToast } from "@tools/Toasts";

export async function getOfferApplication(id: string) {
	const queryToSend = {
		query: `
      query {
        application(id: "${id}") {
            id
            job
            offerLink
            contract
            type
            state
            step
            isTreated
            treatmentReason
            messages
            updateAt
            createdAt
            offer {
              id
            }
            documents {
              collection {
                id
                filePath
                size
                createdAt
                type
              }
            }
            spsStudent {
              id
              firstname
              lastname
              email
              phone
              avatar{
                filePath
              }
              tags
              geoAreaOfResearch
              streetName
              city
              streetNumber
              postalCode
              promotion: spsPromotion  {
                name
              }
              birthdate
            }   
            organization: organizationV2        
        }
      }`,
	};

	return graphqlRequestConstructor(
		queryToSend,
		"getOffersApplicationDetail",
		"Récupération des candidatures de l'offre impossible",
	);
}
export async function getOfferApplicationsThumbs(id: string) {
	const queryToSend = {
		query: `
      query {
        offer (id: "${id}") {
          applicationsCount
          applicationsToTreat
          applicationsToSend
          applications (itemsPerPage: 1000){
            collection {
              id
              state
              step
              isTreated
              acceptedDate
              refusedDate
              modificationRequestDate
              updateAt
              createdAt
              documents {
                collection {
                  id
                  filePath
                  size
                  createdAt
                  type
                }
              }
              spsStudent {
                id
                firstname
                lastname
                avatar{
                  filePath
                }
                geoAreaOfResearch
                streetName
                city
                streetNumber
                postalCode
                promotion: spsPromotion  {
                  name
                }
              }     
            }
          }
        }
      }`,
	};
	return graphqlRequestConstructor(
		queryToSend,
		"getOffersApplicationsThumbs",
		"Récupération des candidatures de l'offre impossible",
	);
}

export async function updateApplication(inputs: any) {
	try {
		const tmpInputs = { ...inputs };

		if (tmpInputs.state === "accepted") {
			tmpInputs.acceptedDate = `${new Date().toLocaleString("en-US", {
				timeZone: "Europe/Paris",
			})}`;
		}
		if (tmpInputs.state === "refused") {
			tmpInputs.refusedDate = `${new Date().toLocaleString("en-US", {
				timeZone: "Europe/Paris",
			})}`;
		}
		if (tmpInputs.state === "onHold") {
			tmpInputs.modificationRequestDate = `${new Date().toLocaleString(
				"en-US",
				{
					timeZone: "Europe/Paris",
				},
			)}`;
		}
		const queryToSend = {
			query: `mutation {
          updateApplication(input: ${stringifyRequestInputs(tmpInputs)}) 
          {
            application {
              favorite
              job
              step
              state
              isTreated
              treatmentReason
              messages
              organization: organizationV2
            }
          }
        }`,
		};
		const response = await API.post("graphql", queryToSend);

		return checkGraphQLError(
			response,
			"Erreur lors de la modification de la candidature",
		);
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}

export async function createReferentApplicationsFromOffer(
	offerId: string,
	spsStudentIds: string[],
) {
	const id = offerId.split("/").pop();
	try {
		return await API.post(
			`/create_referent_applications_from_offer/${id}`,
			spsStudentIds,
		);
	} catch (_e) {
		errorToast("Une erreur est survenue lors de la création des candidatures");
		return false;
	}
}
