import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import success = toast.success;
import { useAppSelector } from "@config/redux/hook";

import { errorToast } from "@tools/Toasts";

import { mailChangePassword } from "../../ForgotPassword/ForgotPasswordAPI";

type ValidateEmailType = {
	setLoading: (e: boolean) => void;
	loading: boolean;
	email: string;
};
export function ValidateEmail({
	loading,
	setLoading,
	email,
}: ValidateEmailType) {
	const { school } = useAppSelector((state) => state.company);

	const sendEmail = async () => {
		setLoading(true);
		try {
			await mailChangePassword(email, Number.parseInt(school?.id));
			success("Email envoyé");
		} catch {
			errorToast(
				"Une erreur est survenue : cette adresse e-mail n'est pas assignée",
			);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		sendEmail();
	}, []);

	return (
		<div className="mx-auto mb-lg w-full">
			<div className="mb-lg rounded-md bg-white p-lg text-center shadow-md">
				<p className="mb-lg font-bold">Confirmez votre email</p>
				<p>Pour continuer veuillez confirmer votre adresse email.</p>
				<ButtonSecondary
					className="mx-auto mt-lg"
					label="Renvoyer un mail"
					disabled={loading}
					onClick={() => {
						sendEmail();
					}}
				/>
			</div>
		</div>
	);
}
