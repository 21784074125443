import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import { arrowForwardFill } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";

export function MentorGoalAcademy() {
	const { setTitle } = useOutletContext();

	useEffect(() => {
		setTitle("Mentor Goal Academy");
	}, []);

	return (
		<main className="help-container">
			<iframe
				className="rounded-lg h-[90vh] w-full p-0 -mt-5 max-h-[81vh]"
				title="notion"
				src="https://v2-embednotion.com/MENTOR-GOAL-ACADEMY-1a13b3c793744d72bcfff1c7f67a51e1"
			/>
			<InlineButton
				className="more flex items-center"
				icon={arrowForwardFill}
				label="Cliquez ici si cette page ne s’affiche pas"
				onClick={() => {
					window.open(
						"https://mentorgoal.notion.site/MENTOR-GOAL-ACADEMY-1a13b3c793744d72bcfff1c7f67a51e1",
						"_blank",
					);
				}}
			/>
		</main>
	);
}
