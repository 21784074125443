import { InAPICompanyContactGateway } from "@containers/school/Companies/core/gateway-infra/inAPI.companyContactGateway";
import { InAPICompanyInformationsGateway } from "@containers/school/Companies/core/gateway-infra/inAPI.companyInformationsGateway";
import { InAPICompanyOffersGateway } from "@containers/school/Companies/core/gateway-infra/inAPI.companyOffersGateway";
import { InAPICompanyRelationsGateway } from "@containers/school/Companies/core/gateway-infra/inAPI.companyRelationsGateway";
import { InAPIReferentGateway } from "@containers/school/Referents/core/gateway-infra/inAPI.referentGateway";
import { InAPIEventsGateway } from "@containers/shared/Events/core/gateway-infra/inAPI.eventsGateway";
import { InAPICampusGateway } from "@modules/campusMultiselect/core/gateway-infra/InAPI.campusGateway";
import { SystemIDProvider } from "./core/system.id-provider";
import type { Dependencies } from "./dependencies";
import { type AppStore, createStore } from "./redux/store";

export class App {
	public dependencies: Dependencies;
	public store: AppStore;

	constructor() {
		this.dependencies = this.setupDependencies();
		this.store = createStore({ dependencies: this.dependencies });
	}

	setupDependencies = (): Dependencies => {
		return {
			campusGateway: new InAPICampusGateway(),
			companyInformationsGateway: new InAPICompanyInformationsGateway(),
			companyOffersGateway: new InAPICompanyOffersGateway(),
			companyRelationsGateway: new InAPICompanyRelationsGateway(),
			companyContactGateway: new InAPICompanyContactGateway(),
			referentsGateway: new InAPIReferentGateway(),
			eventsGateway: new InAPIEventsGateway(),
			idProvider: new SystemIDProvider(),
		};
	};
}

export const app = new App();
