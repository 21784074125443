import { Icon, type IconifyIcon } from "@iconify/react";
import React from "react";

import "@assets/sass/Stats.scss";

import {
	arrowDownwardOutline,
	arrowUpwardOutline,
	minusOutline,
} from "@assets/Icons";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";

type StatsPropsType = {
	className?: string;
	icon?: IconifyIcon | string;
	label?: string;
	value?: number | string;
	bgIcon?: string;
	progressValue?: number;
	subtitle?: string;
	progress?: boolean;
	color?: string;
	tooltip?: string;
	tooltipId?: string;
};

type ProgressIndicatorType = {
	value: number;
	color: string;
	icon: IconifyIcon;
};

export function ProgressIndicator({
	value,
	color,
	icon,
}: ProgressIndicatorType) {
	return (
		<div className={`flex items-center rounded ${color} p-[4px]`}>
			<Icon icon={icon} width={16} height={16} />
			<p className="leading-none">{value}%</p>
		</div>
	);
}

export function Stats({
	label = "",
	icon,
	value = 0,
	progressValue = 0,
	bgIcon = "",
	color = "primary-100",
	className = "",
	subtitle = "",
	progress = false,
	tooltip,
	tooltipId,
}: StatsPropsType) {
	return (
		<div
			className={`rounded-lg bg-white px-sm py-md shadow-xsm ${className} flex ${
				tooltip ? "flex-row-reverse" : ""
			} items-start justify-between`}
			data-testid="stats"
		>
			{tooltip && (
				<div>
					<TooltipElement id={tooltipId || ""} />
					<TooltipContainer
						anchorId={`#${tooltipId}`}
						children={<p>{tooltip}</p>}
						place="top"
					/>
				</div>
			)}
			<div className="flex min-w-fit flex-col gap-xxsm">
				<div className="flex items-center justify-start p-0">
					<div
						className={`${
							icon && "mr-xsm p-xxsm"
						}  flex items-center justify-center rounded-lg  ${bgIcon}`}
					>
						{icon && <Icon className={`${color} icon`} icon={icon} />}
					</div>
					<p className={`${color} text-[18px] font-bold`}>{label}</p>
				</div>
				<p className={"text-[34px] font-bold text-primary-700P"}>{value}</p>
				<div className="flex items-center gap-1">
					{progress &&
						(progressValue > 0 ? (
							<ProgressIndicator
								value={progressValue}
								color="bg-success-light text-success-dark"
								icon={arrowUpwardOutline}
							/>
						) : progressValue < 0 ? (
							<ProgressIndicator
								value={progressValue}
								color="bg-error-light text-error-dark"
								icon={arrowDownwardOutline}
							/>
						) : (
							<ProgressIndicator
								value={progressValue}
								color="bg-primary-100 text-primary-150"
								icon={minusOutline}
							/>
						))}
					<p className="text-xsm text-primary-200">{subtitle}</p>
				</div>
			</div>
		</div>
	);
}
