import type { CellType } from "@/types/TableTypes";
import type { ApplicationThumbType } from "@/types/applicationTypes";
import type { FormErrorType } from "@/types/genericType";
import type {
	OfferCellNameType,
	OfferVisibilityType,
	OffersCounterType,
	OffersStatusType,
	SchoolOfferType,
} from "@/types/offerTypes";
import type {
	ReferentType,
	UserCompleteType,
	UserRoleType,
} from "@/types/userTypes";
import { toast } from "react-toastify";

import {
	CONTRACT_TYPE_OPTIONS,
	OFFER_TYPE_SELECTION_OPTIONS,
	QUALIFICATION_OPTIONS,
	TELEWORK_OPTIONS,
	offerVisibilityLabel,
	offersCellMapping,
	offersTabsLabels,
	visibilityAffix,
} from "@constants/Offers";

import type { SettingsItem } from "@designSystem/molecules/Settings";
import { updateToastWithError, updateToastWithSuccess } from "@tools/Toasts";

import type { CampusSelectedType } from "@/types/campusTypes";
import type { BackgroundColorType } from "@/types/colorsType";
import { exportCVs } from "@containers/school/CVBoard/CVBoardAPI";
import { extractCampusList } from "./Campuses";

export const getContractType = (
	contract: number | string,
): { label: string; color: BackgroundColorType } => {
	if (contract === 1 || contract === "apprenticeship") {
		return {
			label: "Alternance",
			color: "bg-emerald-light",
		};
	}
	if (contract === 2 || contract === "permanent") {
		return {
			label: "CDI",
			color: "bg-terracotta-light",
		};
	}
	if (contract === 3 || contract === "professionalization") {
		return {
			label: "Contrat de professionalisation",
			color: "bg-sakura-light",
		};
	}
	if (contract === 4 || contract === "intern") {
		return {
			label: "Stage",
			color: "bg-kaki-light",
		};
	}

	if (contract === 5 || contract === "temporary") {
		return {
			label: "CDD",
			color: "bg-aquamarine-light",
		};
	}

	if (contract === 6 || contract === "volunteering") {
		return {
			label: "Bénévolat",
			color: "bg-sunflower-light",
		};
	}

	if (contract === 7 || contract === "civic") {
		return {
			label: "Service civique",
			color: "bg-lavender-light",
		};
	}

	return {
		label: "Non renseigné",
		color: "bg-stone-light",
	};
};

export const retrieveActiveOffers = (
	offers: SchoolOfferType[],
): SchoolOfferType[] => {
	return offers.filter((offer) => offer.status === "online");
};

export const sortByCreationDate = (
	offers: SchoolOfferType[],
): SchoolOfferType[] => {
	if (!offers) return [];
	const offersCopy = [...offers];
	return (
		offersCopy?.sort(
			(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
		) || []
	);
};

export interface TabData {
	label: string | undefined;
	counter: number;
}

export const getTabsData = (counters: OffersCounterType[]): TabData[] => {
	const tabsData: TabData[] = [];
	tabsData.push({
		label: offersTabsLabels.get("online"),
		counter:
			counters.find((counter) => counter?.offerType === "online")?.count || 0,
	});
	tabsData.push({
		label: offersTabsLabels.get("provided"),
		counter:
			counters.find((counter) => counter?.offerType === "provided")?.count || 0,
	});
	tabsData.push({
		label: offersTabsLabels.get("notProvided"),
		counter:
			counters.find((counter) => counter?.offerType === "notProvided")?.count ||
			0,
	});
	tabsData.push({
		label: offersTabsLabels.get("draft"),
		counter:
			counters.find((counter) => counter?.offerType === "draft")?.count || 0,
	});
	tabsData.push({
		label: offersTabsLabels.get("expired"),
		counter:
			counters.find((counter) => counter?.offerType === "expired")?.count || 0,
	});
	return tabsData;
};

/***
 * Filter offers by status
 * @param offersList list of offers to filter
 * @param status an array of status to filter by
 * @returns filtered list of offers
 */
export const filterOffersByStatus = (
	offersList: SchoolOfferType[],
	status: OffersStatusType[],
) => {
	if (!offersList || !status) return [];
	if (
		!Array.isArray(offersList) ||
		!offersList.every((item) => typeof item === "object" && item !== null)
	) {
		return [];
	}
	return offersList.filter((offer) => status.includes(offer.status));
};

export const convertSettingsItemToOfferCell = (
	setting: SettingsItem,
): CellType => {
	if (!setting) return {} as CellType;
	const offerCellRetrived = offersCellMapping.get(
		setting.value as OfferCellNameType,
	);
	if (!offerCellRetrived) return {} as CellType;
	return offerCellRetrived;
};

export const convertOfferCellToSettingsItem = (
	cell: CellType,
	index: number,
): SettingsItem => {
	if (!cell) return {} as SettingsItem;
	return {
		label: cell.cellLabel,
		value: cell.cellName,
		positionValue: index,
	};
};

export const checkIfOfferIsInEditMode = (
	dataToUse:
		| {
				offerToCreate: Partial<SchoolOfferType> | undefined;
				offerToEdit: Partial<SchoolOfferType> | undefined;
		  }
		| undefined,
): boolean => {
	if (dataToUse?.offerToEdit?.id) {
		return true;
	}
	return false;
};

export const getAllCells = (): CellType[] => {
	const cells: CellType[] = [];
	offersCellMapping.forEach((cell) => {
		cells.push(cell);
	});
	return cells;
};

export const convertOfferCellsNamesToCells = (
	cellsNames: OfferCellNameType[] | undefined,
): CellType[] => {
	if (!cellsNames) return [];
	const cells: CellType[] = [];
	cellsNames.forEach((cellName) => {
		const cell = offersCellMapping.get(cellName);
		if (cell) {
			cells.push(cell);
		}
	});
	return cells;
};

export const checkIfOfferCouldBeCreated = (
	offerToCreate: Partial<SchoolOfferType> | undefined,
	editionMode?: boolean,
): FormErrorType[] => {
	const errorStack: FormErrorType[] = [];
	if (!offerToCreate) {
		errorStack.push({
			id: "offer",
			errorMessage: "Erreur dans la création de l’offre",
		});
	}
	if (!offerToCreate?.job) {
		errorStack.push({
			id: "job",
			errorMessage: "Nom du poste manquant",
		});
	}
	if (!offerToCreate?.description || offerToCreate?.description.length < 1) {
		if (!offerToCreate?.pdf) {
			errorStack.push({
				id: "document",
				errorMessage: "Description du poste manquante",
			});
		}
	}

	if (!offerToCreate?.crmCompany) {
		errorStack.push({
			id: "crmCompany",
			errorMessage: "Entreprise manquante",
		});
	}
	if (
		!offerToCreate?.location ||
		!offerToCreate?.longitude ||
		!offerToCreate?.latitude
	) {
		errorStack.push({
			id: "location",
			errorMessage: "Lieu de travail manquant",
		});
	}
	if (!offerToCreate?.status && !editionMode) {
		errorStack.push({
			id: "status",
			errorMessage: "Statut de l’offre manquant",
		});
	}

	if (!offerToCreate?.type) {
		errorStack.push({
			id: "type",
			errorMessage: "Type de l’offre manquant",
		});
	}

	if (
		!offerToCreate?.expirationDate &&
		offerToCreate?.type === "preselection"
	) {
		errorStack.push({
			id: "expirationDate",
			errorMessage: "Date d’expiration manquante",
		});
	}
	if (!offerToCreate?.responsable && !editionMode) {
		errorStack.push({
			id: "responsable",
			errorMessage: "Responsable de l’offre manquant",
		});
	}
	if (!offerToCreate?.campuses && !editionMode) {
		errorStack.push({
			id: "campuses",
			errorMessage: "Campus manquant",
		});
	} else {
		const campusList = extractCampusList(offerToCreate?.campuses as any);
		if (campusList?.length === 0) {
			errorStack.push({
				id: "campuses",
				errorMessage: "Campus manquant",
			});
		}
	}

	if (offerToCreate?.type === "direct" && !offerToCreate?.wayToApply) {
		errorStack.push({
			id: "wayToApply",
			errorMessage: "Lien de redirection manquant",
		});
	}

	return errorStack;
};

export const getTeleworkOption = (
	offerData: Partial<SchoolOfferType> | undefined,
) => {
	if (!offerData) {
		return TELEWORK_OPTIONS[TELEWORK_OPTIONS.length - 1];
	}
	if (offerData?.telework) {
		const indexFound = TELEWORK_OPTIONS.findIndex(
			(data) => data.value === offerData?.telework,
		);
		if (indexFound !== -1) {
			return TELEWORK_OPTIONS[indexFound];
		}
		return TELEWORK_OPTIONS[TELEWORK_OPTIONS.length - 1];
	}
	return TELEWORK_OPTIONS[TELEWORK_OPTIONS.length - 1];
};

export const getQualificationOption = (
	offerData: Partial<SchoolOfferType> | undefined,
) => {
	if (!offerData) {
		return QUALIFICATION_OPTIONS[QUALIFICATION_OPTIONS.length - 1];
	}
	if (offerData?.qualification) {
		const indexFound = QUALIFICATION_OPTIONS.findIndex(
			(data) => data.value === offerData?.qualification,
		);
		if (indexFound !== -1) {
			return QUALIFICATION_OPTIONS[indexFound];
		}
		return QUALIFICATION_OPTIONS[QUALIFICATION_OPTIONS.length - 1];
	}
	return QUALIFICATION_OPTIONS[QUALIFICATION_OPTIONS.length - 1];
};

export const getContractOption = (
	offerData: Partial<SchoolOfferType> | undefined,
) => {
	if (!offerData) {
		return CONTRACT_TYPE_OPTIONS[CONTRACT_TYPE_OPTIONS.length - 1];
	}
	if (offerData?.contract) {
		const indexFound = CONTRACT_TYPE_OPTIONS.findIndex(
			(data) => data.value === offerData?.contract,
		);
		if (indexFound !== -1) {
			return CONTRACT_TYPE_OPTIONS[indexFound];
		}
		return CONTRACT_TYPE_OPTIONS[CONTRACT_TYPE_OPTIONS.length - 1];
	}
	return CONTRACT_TYPE_OPTIONS[CONTRACT_TYPE_OPTIONS.length - 1];
};

type GetVisibilityProps = {
	cutDetails?: boolean;
	roles?: UserRoleType[];
	isEdition?: boolean;
	currentVisibility?: OfferVisibilityType;
};
export const getVisibilityOptions = ({
	cutDetails = false,
	roles,
	isEdition,
	currentVisibility,
}: GetVisibilityProps = {}) => {
	if (!roles) return [];
	const isCampusUser = roles.includes("ROLE_CAMPUS_USER");
	const getAffix = (key: OfferVisibilityType) => {
		switch (key) {
			case "private":
				return `(${visibilityAffix?.private})`;
			case "restricted":
				return `(${visibilityAffix?.restricted})`;
			case "visible":
				return `(${visibilityAffix?.visible})`;
			default:
				return "";
		}
	};

	const options = Array?.from(offerVisibilityLabel)?.map(([key, data]) => {
		return {
			label: cutDetails
				? data?.label.split("(")?.[0]
				: `${data?.label} ${getAffix(key)}`,
			value: key,
		};
	});

	const roleFilteredOptions =
		(isCampusUser && !isEdition) ||
		(isCampusUser && isEdition && currentVisibility !== "visible")
			? options?.filter((option) => option.value !== "visible")
			: options;

	return roleFilteredOptions;
};

export const getOfferTypeOptions = (useShortlabel = false) => {
	return Array.from(OFFER_TYPE_SELECTION_OPTIONS)?.map((data) => {
		return {
			label: useShortlabel ? data?.shortLabel : data?.label,
			value: data?.type,
		};
	});
};

export const getAllOfferContractOptions = () => {
	return Array.from(CONTRACT_TYPE_OPTIONS).map((data) => {
		return {
			label: data?.label,
			value: data?.value,
		};
	});
};
export const sanitizeCampusesForRequest = (
	campusesSelected: CampusSelectedType[],
): string[] => {
	if (!campusesSelected || campusesSelected?.length === 0) return [];
	if (Object.prototype.hasOwnProperty.call(campusesSelected[0], "id")) {
		const sanitizedCampuses = campusesSelected.map((campus: any) => campus?.id);
		return sanitizedCampuses as string[];
	}
	const sanitizedCampuses = campusesSelected.map((campus) => campus.value);
	return sanitizedCampuses as string[];
};

export const removeDuplicateReferents = (
	referents: ReferentType[] | undefined,
) => {
	if (!referents) return [];
	const uniqueIds = Array?.from(
		new Set(referents?.map((referent) => referent.id)),
	);
	return uniqueIds?.map(
		(id) => referents?.find((referent) => referent.id === id)!,
	);
};

export const sortReferentsByLastName = (
	referents: ReferentType[] | undefined,
) => {
	if (!referents) return [];
	const referentsCopy = [...referents];
	return referentsCopy.sort((a, b) => {
		const aLastName = a?.lastname || "";
		const bLastName = b?.lastname || "";
		return aLastName.localeCompare(bLastName);
	});
};

/**
 * @description Using a list of applications, extract the user profil and return it in a list
 * @param applications list of applications
 * @returns list of user profile to transmit to company
 */
export const extractProfilsToSend = (
	applications: ApplicationThumbType[],
): Partial<UserCompleteType>[] => {
	const users: Partial<UserCompleteType>[] = [];
	applications.forEach((application) => {
		if (application?.spsStudent && application?.state === "accepted") {
			users.push(application.spsStudent);
		}
	});
	return users;
};

/**
 * @description Return a list of resume IDs using a list of applications thumb
 * @param applications list of applications
 * @returns list of resume IDs
 */
export const extractResumeIds = (applications: ApplicationThumbType[]) => {
	const resumeIds: string[] = [];
	applications.forEach((application) => {
		if (application?.documents?.collection) {
			application.documents.collection.forEach((document) => {
				if (document?.type === "cv" && document?.id) {
					resumeIds.push(document.id);
				}
			});
		}
	});
	return resumeIds;
};

/**
 * Download the resume for a list of applications
 * @param applications
 * @returns true if the download was successful, false otherwise
 */
export const downloadResumeForApplications = async (
	applications: ApplicationThumbType[],
): Promise<boolean> => {
	const resumeIds = extractResumeIds(applications);
	const id = toast.loading("CV en cours de téléchargement.", {
		isLoading: true,
		hideProgressBar: false,
		type: "default",
	});

	const { fileName } = (await exportCVs(resumeIds)) as { fileName: string };

	if (fileName) {
		updateToastWithSuccess(id, "CV téléchargés avec succès.");
		window.open(
			`${import.meta.env.VITE_BACK}/zip_export/${fileName}`,
			"_blank",
		);
		return true;
	}
	updateToastWithError(id, "Erreur lors du téléchargement des CV.");
	return false;
};
