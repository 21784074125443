import { useAppSelector } from "@config/redux/hook";
import { PdfViewer } from "@designSystem/atoms/PdfViewer";
import React from "react";

export function OfferDetailsDocuments() {
	const { offerToEdit } = useAppSelector((state) => state.offers);

	if (offerToEdit?.pdf?.filePath || "") {
		return (
			<div className="h-fit">
				<PdfViewer
					src={`${import.meta.env.VITE_S3}${offerToEdit?.pdf?.filePath}`}
					title="Document"
				/>
			</div>
		);
	}
	return null;
}
