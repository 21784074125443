import { Icon } from "@iconify/react";
import React from "react";
import { useDropzone } from "react-dropzone";

import { cloudUploadOutline } from "@assets/Icons";

import { Spinner } from "@designSystem/atoms/Spinner";

type ImageUploadType = {
	id: string;
	isLoading: boolean;
	acceptFormats: any;
	className?: string;
	onSuccess: Function;
	onError: Function;
	maxSize: number;
	error?: boolean;
};
export function ImageUploader({
	id = "",
	acceptFormats,
	className = "",
	onSuccess,
	onError,
	maxSize = 1,
	error = false,
	isLoading = false,
}: ImageUploadType) {
	const onDrop = (files: any) => {
		const goodFiles = files.filter((file: any) => {
			if (file.size / 1000000 <= maxSize) return file;
			return 0;
		});
		const badFiles = files.filter((file: any) => {
			if (file.size / 1000000 > maxSize) return file;
			return 0;
		});
		if (files.length === 0) onError(files);
		else if (badFiles.length > 0) onError(badFiles);
		else onSuccess(goodFiles);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: acceptFormats,
	});

	return (
		<div
			id={id}
			data-id="uploadImage"
			{...getRootProps()}
			className={`flex h-10 min-w-full items-center justify-center gap-2 rounded-sm border-[1px] border-primary-200 px-6 py-4 hover:cursor-pointer ${
				isDragActive && "drag-activated"
			} ${className} ${error && "error"}`}
		>
			{isLoading ? (
				<div className="flex !max-h-2 items-center justify-center">
					<Spinner className="!h-4 !w-4 [&>*]:!m-0 [&>*]:!h-4 [&>*]:!w-4 [&>*]:!border-2 " />
				</div>
			) : (
				<>
					<Icon icon={cloudUploadOutline} className="h-[24px] w-[24px]" />
					<input {...getInputProps()} />
					<div className=" w-full text-center text-base font-normal text-primary-700P">
						Importer une image
					</div>
				</>
			)}
		</div>
	);
}
