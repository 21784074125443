import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";

import { MODULE_PRESET_OFFER } from "@constants/Others";
import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

function DescriptionModifier() {
	const dispatchEvent = useDispatch();
	const { offerToCreate } = useAppSelector((state) => state.offers);

	const handleChangeDescription = (value: string) => {
		dispatchEvent(modifyOfferToCreate({ description: value }));
	};

	return (
		<div data-testid="descriptionModifier" className="w-full">
			<span className="text-xxsm font-normal text-primary-500">
				Description de l’offre*
			</span>
			<RichTextEditor
				value={offerToCreate?.description || ""}
				placeholder="Copiez-collez ou rédigez la description de l'offre ici..."
				onChange={(e: string) => handleChangeDescription(e)}
				modules={MODULE_PRESET_OFFER}
			/>
		</div>
	);
}

export default DescriptionModifier;
