import type { EntOffersType } from "@/types/EntCompanyTypes";
import type { SchoolType } from "@/types/schoolTypes";
import { Icon } from "@iconify/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import type { MouseEvent } from "react";

import { archiveOutline, briefcaseOutline, pinOutline } from "@assets/Icons";

import { CONTRACT_TYPE_OPTIONS } from "@constants/Offers";

import { Badge } from "@designSystem/atoms/Badge";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";

import type { EntOfferStatusSelectionOption } from "@/types/offerTypes";
import { ENT_COMPANY_OFFER_STATUS } from "@constants/Company";
import { selectionHasNewUsers } from "@tools/Selections";
import Skeleton from "react-loading-skeleton";
import { OfferNumbersBlock } from "./OfferNumbersBlock";
import { OfferSkeleton } from "./OfferSkeleton";

export function OfferCard({
	offer,
	seeModal,
	showCenterBlocks = true,
	loading = false,
	setSeeModal,
	className = "",
	onSelect,
	school,
}: {
	offer: EntOffersType;
	school: Partial<SchoolType>;
	seeModal?: boolean | number | string;
	setSeeModal?: (e: false | string) => void;
	index: number;
	loading?: boolean;
	showCenterBlocks?: boolean;
	className?: string;
	onSelect?: () => void;
}) {
	const [status, setStatus] = useState<
		EntOfferStatusSelectionOption | undefined
	>(undefined);

	useEffect(() => {
		if (selectionHasNewUsers(offer.spsSelection)) {
			setStatus(ENT_COMPANY_OFFER_STATUS.find((e) => e.value === "new"));
		} else
			setStatus(
				ENT_COMPANY_OFFER_STATUS.find((e) => e.value === offer.status) ||
					ENT_COMPANY_OFFER_STATUS.find((e) => e.default),
			);
	}, [offer.spsSelection]);

	const getStatus = (classes = "") => {
		return (
			<div className={`flex h-[24px] items-center ${classes}`}>
				{status ? (
					<Badge
						size="medium"
						label={status?.label || "Erreur"}
						bgColor={status?.bgColor}
						hasBorder={true}
					/>
				) : (
					<Skeleton width={"84px"} />
				)}
				{offer.status !== "expired" &&
					seeModal !== undefined &&
					setSeeModal && (
						<>
							<TooltipContainer
								anchorId="#archive-button"
								makeClickable={false}
								place="top"
							>
								Clôturer l'offre
							</TooltipContainer>
							<span
								id="archive-button"
								className="ml-xsm h-full cursor-pointer"
								onClick={(e: any) => {
									e.stopPropagation();
									seeModal === offer.id
										? setSeeModal(false)
										: setSeeModal(offer.id);
								}}
							>
								<Icon icon={archiveOutline} className="h-full w-full" />
							</span>
						</>
					)}
			</div>
		);
	};

	return loading ? (
		<OfferSkeleton />
	) : (
		<div
			className={`mt-md cursor-pointer justify-between gap-sm rounded-md bg-white p-sm shadow-md transition hover:bg-primary-50 lg:flex ${className}`}
			onClick={onSelect}
		>
			<div className="flex flex-col">
				<p className="mb-xsm flex flex-col-reverse font-bold sm:flex-row">
					{offer.job?.length ? offer.job : "name"}
					{getStatus(
						"justify-between mb-xsm sm:mb-0 sm:justify-start sm:ml-auto lg:hidden",
					)}
				</p>
				<div className="flex gap-sm">
					<span className="flex items-center gap-[4px]">
						<Icon icon={briefcaseOutline} />
						{
							CONTRACT_TYPE_OPTIONS.find((e) => e.value === offer.contract)
								?.label
						}
					</span>
					<span className="flex items-center gap-[4px]">
						<Icon icon={pinOutline} /> {offer.location}
					</span>
				</div>
				<p className="my-xsm text-[12px] text-primary-200">
					Créée le {moment(offer.createdAt).format("DD/MM/YYYY")}
				</p>
			</div>
			{showCenterBlocks && offer.userData && (
				<OfferNumbersBlock
					userData={{ total: 3, consulted: 2 }}
					className="md:mx-auto md:max-w-[620px]"
				/>
			)}
			<div className="flex flex-col">
				{getStatus("hidden lg:flex")}
				{offer.link && offer.status !== "pending" && (
					<ButtonPrimary
						className="md:w-fit-content mt-sm md:ml-auto lg:mt-auto"
						disabled={!offer.spsSelection?.selectionUsers?.collection.length}
						label={
							offer.spsSelection?.selectionUsers?.collection.length
								? "Voir les candidats"
								: "Aucun candidat"
						}
						onClick={(e: MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							window.open(
								`${import.meta.env.VITE_URL}/selection/${school.subdomain}/${
									offer.link
								}`,
								"_blank",
							);
						}}
					/>
				)}
			</div>
		</div>
	);
}
