import React, { useEffect, useState } from "react";

import { Checkbox } from "@designSystem/atoms/Checkbox";

type CheckboxLabelPrimaryPropsType = {
	className?: string;
	checked: boolean;
	onCheck: Function;
	label?: string;
	disabled?: boolean;
};

export function CheckboxLabelPrimary({
	className = "",
	checked = false,
	onCheck = () => {},
	label,
	disabled,
}: CheckboxLabelPrimaryPropsType) {
	const [activated, setActivated] = useState(checked);

	useEffect(() => {
		setActivated(checked);
	}, [checked]);

	return (
		<div
			className={`flex cursor-pointer items-center gap-xsm ${className}`}
			data-testid="checkboxLabelPrimary"
		>
			<Checkbox
				onEnabled={() => {
					if (onCheck) onCheck(!activated);
					setActivated(true);
				}}
				onDisabled={() => {
					if (onCheck) onCheck(!activated);
					setActivated(false);
				}}
				checked={activated}
				disabled={disabled}
			/>
			<p
				onClick={() => {
					setActivated(!activated);
					onCheck(!activated);
				}}
				className="text-xxsm text-primary-700P"
			>
				{label}
			</p>
		</div>
	);
}
