import { stringifyRequestInputs } from "@tools/Objects";

import api from "@tools/API";

export async function upload(formdata) {
	let rep = {};

	await api.post("media_objects", formdata).then(({ data }) => {
		rep = data;
	});

	return rep;
}

export async function editResources(inputs) {
	let rep = {};

	const data = {
		query: `
      mutation{
        updateResource(input: ${stringifyRequestInputs(inputs)}){
          resource{
            id
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function createResource(inputs) {
	let rep = {};

	const data = {
		query: `
      mutation{
        createResource(input: ${stringifyRequestInputs(inputs)}){
          resource{
            id
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getResourceByID(id) {
	let rep = {};
	const data = {
		query: `
          query {
              resource(id: "${id}") {
                  id
                  title
                  description
                  isOnline
                  isMentorgoal
                  color
                  link
                  illustration
                  {
                    id
                    filePath
                    size
                  }
                  pdf{
                    id
                    size
                    filePath
                  }
                  type
                  content
                  views
                  createdAt
                  link
                  isDirectLink
                  author: spsReferent {
                    firstname
                    lastname
                  }
              }
          }
      `,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.resource;
	});

	return rep;
}

export async function getSchoolPromotions(school) {
	let rep = {};

	const data = {
		query: `
    query {
      promotions(school: "${school}", archived: false, type: "student", itemsPerPage: 1000) {
        collection{
          id
          name
          archived
          toPlace
          notSearching
          placed
          surrend
          applications
          relaunchs
          interviews
          year
          school{
            id
            name
          }
        }
        paginationInfo {
          totalCount
          lastPage
          itemsPerPage
        }
      }
    }
`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.promotions;
	});
	return rep;
}

export async function deleteResource(inputs) {
	let rep = {};

	const data = {
		query: `
    mutation{
      deleteResource(input: ${stringifyRequestInputs(inputs)}){
        resource{
          id
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}
