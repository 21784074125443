import type {
	EntCompanySchoolProfileType,
	EntOffersContractType,
	EntOffersType,
} from "@/types/EntCompanyTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { arrowBackOutline, pinOutline } from "@assets/Icons";

import {
	CONTRACT_TYPE_OPTIONS,
	QUALIFICATION_OPTIONS,
	TELEWORK_OPTIONS,
} from "@constants/Offers";

import { checkEmail } from "@tools/Checkers";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { Spinner } from "@designSystem/atoms/Spinner";
import { Switch } from "@designSystem/atoms/Switch";
import { FileInput } from "@designSystem/molecules/FileInput";
import { FilesPrimary } from "@designSystem/molecules/Files";
import { Input } from "@designSystem/molecules/Input";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";
import { SelectLocation } from "@designSystem/organisms/select/SelectLocation";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";

import { errorToast } from "@tools/Toasts";
import { createOffer, sendFile } from "../OffersAPI";

export function CreateOffer() {
	const [, setHasErrors] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pdfLoading, setPdfLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const {
		companySchoolProfile,
	}: { companySchoolProfile: Partial<EntCompanySchoolProfileType> } =
		useAppSelector((state) => state.company);

	const getPlaceholder = (language: string) => {
		if (language === "fr") {
			return "Copiez collez ou rédigez la description de l’offre...";
		}
		if (language === "en") {
			return "Copy and paste or write the description of the offer...";
		}
		if (language === "es") {
			return "Copia y pega o escribe la descripción de la oferta...";
		}
		if (language === "de") {
			return "Kopieren Sie die Beschreibung des Angebots und fügen Sie sie ein oder schreiben Sie sie...";
		}
		return "Copy and paste or write the description of the offer...";
	};

	const navigate = useNavigate();
	const { user } = useContext(GlobalContext);
	const { school } = useAppSelector((state) => state.company);

	useEffect(() => {}, []);
	const getDefaultValues = () => {
		const defaultContract = CONTRACT_TYPE_OPTIONS.find((type) => {
			return type.default;
		});
		const defaultTeleworking = TELEWORK_OPTIONS.find((option) => {
			return option.default;
		});
		const defaultQualification = QUALIFICATION_OPTIONS.find((option) => {
			return option.default;
		});
		return {
			defaultQualification,
			defaultTeleworking,
			defaultContract,
		};
	};

	return (
		<div className="pt-sm">
			<InlineButton
				disabled={loading}
				className="mb-sm"
				label="Retour"
				icon={arrowBackOutline}
				onClick={() => {
					navigate(`/company?${school?.subdomain || ""}`);
				}}
			/>
			{!success ? (
				<Formik
					validateOnChange={false}
					onSubmit={async (e: EntOffersType) => {
						setLoading(true);
						try {
							e.createdBy = user?.id;
							e.status = "pending";
							e.entCompanySchoolProfile = `/company/ent_company_school_profiles/${companySchoolProfile.id}`;
							await createOffer(e);
							setSuccess(true);
						} catch {
							errorToast("Une erreur est survenue");
						} finally {
							setLoading(false);
						}
					}}
					initialValues={{
						emailPreference: user?.email || "",
						isPdf: false,
						pdf: null,
						description: "",
						contract: getDefaultValues().defaultContract
							?.value as EntOffersContractType,
						qualification: getDefaultValues().defaultQualification?.value,
						telework: getDefaultValues().defaultTeleworking?.value,
						location: "",
						job: "",
					}}
					validate={(values: Partial<EntOffersType>) => {
						type ErrorsType = {
							description?: string;
							contract?: string;
							telework?: string;
							location?: string;
							job?: string;
							pdf?: string;
							emailPreference?: string;
						};
						const errors: ErrorsType = {};
						if (!values.job || !values.job.length) {
							errors.job = "Veuillez entrer un nom de poste";
						}
						if (!values.description || !values.description.length) {
							errors.description = "Veuillez entrer une description";
						}
						if (values.telework === null) {
							errors.telework = "Veuillez sélectionner un statut";
						}
						if (!values.location || !values.location.length) {
							errors.location = "Veuillez entrer une ville";
						}
						if (!values.contract) {
							errors.contract = "Veuillez sélectionner un type de contrat";
						}
						if (
							!values.emailPreference ||
							!checkEmail(values.emailPreference)
						) {
							errors.emailPreference =
								"Veuillez entrer une adresse email valide";
						}
						if (values.isPdf && (!values.pdf || !values.pdf.filePath)) {
							errors.pdf = "Veuillez déposer un fichier PDF";
						}
						setHasErrors(Object.values(errors).length > 0);
						return errors;
					}}
				>
					{({
						values,
						errors,
						setFieldValue,
						setFieldError,
						handleSubmit,
						handleChange,
					}) => (
						<>
							<WhiteCard
								title="Votre recrutement"
								content={
									<>
										<Input
											placeholder="Nom du poste"
											label="Poste*"
											className="mb-sm"
											name="job"
											errorMessage={errors.job}
											value={values.job}
											onChange={handleChange}
										/>
										<div className="mb-sm flex gap-sm">
											<SelectLocation
												errorMessage={errors.location}
												className="w-full"
												icon={pinOutline}
												placeholder="Lieu du poste"
												handleNewLocation={async (e: {
													location?: string;
													latitude?: number;
													longitude?: number;
													zipCode?: string;
												}) => {
													setFieldValue(
														"location",
														e?.location ? e.location : undefined,
													);
													setFieldValue(
														"latitude",
														e?.location ? e.latitude : undefined,
													);
													setFieldValue(
														"longitude",
														e?.location ? e.longitude : undefined,
													);
												}}
											/>
											<SelectTertiary
												errorMessage={errors.telework}
												label="Télétravail"
												onChange={(e) => {
													setFieldValue("telework", e.value);
												}}
												defaultValue={getDefaultValues().defaultTeleworking}
												className="w-full"
												options={TELEWORK_OPTIONS}
											/>
										</div>
										<div className="mb-sm flex gap-sm">
											<SelectTertiary
												errorMessage={errors.contract}
												label="Type de contrat"
												className="w-full"
												onChange={(e) => {
													setFieldValue("contract", e.value);
												}}
												defaultValue={getDefaultValues().defaultContract}
												options={CONTRACT_TYPE_OPTIONS}
											/>
											<SelectTertiary
												className="w-full"
												onChange={(e) => {
													setFieldValue("qualification", e.value);
												}}
												label="Niveau d'étude"
												defaultValue={getDefaultValues().defaultQualification}
												options={QUALIFICATION_OPTIONS}
											/>
										</div>
										<label
											className="text-label-size mb-xxsm mt-md text-primary-300"
											htmlFor="description"
										>
											Description du poste*
										</label>
										<RichTextEditor
											className=" [&_.ql-container]:!h-[329px]"
											onChange={(e) => {
												setFieldValue("description", e, false);
											}}
											value={values.description as string}
											placeholder={getPlaceholder(Weglot.getCurrentLang())}
										/>
										{errors.description && (
											<p className="text-label-size pt-xxsm text-error-dark">
												{errors.description}
											</p>
										)}
										<div className="mt-sm flex flex-wrap items-center justify-start">
											<Switch
												className="mr-md"
												checked={values.isPdf}
												onEnabled={() => {
													setFieldValue("isPdf", true);
												}}
												onDisabled={() => {
													setFieldValue("isPdf", false);
												}}
											/>
											<p>Déposer un PDF</p>
										</div>
										{values.isPdf &&
											(values.pdf?.filePath ? (
												<div>
													<FilesPrimary
														isLoading={pdfLoading}
														className=" mt-md"
														title={values.pdf.filePath}
														subtitle={`${values.pdf.size / 1000} Ko`}
														onView={() => {
															window.open(
																`${import.meta.env.VITE_S3}${
																	values.pdf?.filePath
																}`,
															);
														}}
														onDelete={async () => {
															await setFieldValue("pdf", null);
														}}
													/>
												</div>
											) : (
												<>
													<FileInput
														id="ent_offer_pdf"
														isLoading={pdfLoading || loading}
														className="mt-sm"
														maxSize={2}
														acceptFormats={{ "application/pdf": [] }}
														onSuccess={async (e: File[]) => {
															setFieldError("pdf", undefined);
															if (e !== undefined) {
																const fileData = new FormData();
																fileData.append("file", e[0]);
																fileData.append("type", "ent_offer_pdf");
																try {
																	setPdfLoading(true);
																	const file = (await sendFile(
																		fileData,
																	)) as Partial<MediaObjectType>;
																	setFieldValue("pdf", file);
																} catch {
																	setFieldError(
																		"pdf",
																		"Le fichier envoyé dépasse la limite de 2Mo ou n'est pas sous format PDF",
																	);
																} finally {
																	setPdfLoading(false);
																}
															} else {
																setFieldError(
																	"pdf",
																	"Le fichier envoyé dépasse la limite de 2Mo ou n'est pas sous format PDF",
																);
															}
														}}
														onError={(e: File[]) => {
															setFieldError(
																"pdf",
																e[0]?.size / 1000000 >= 2
																	? "Le fichier envoyé dépasse la limite de 2Mo"
																	: "Le fichier envoyé n'est pas sous format PDF",
															);
														}}
													/>
													{errors.pdf && (
														<p className="mt-sm text-error-dark">
															{errors.pdf}
														</p>
													)}
												</>
											))}
										<hr className="mb-sm mt-sm" />
										<p className="font-bold">
											À quelle adresse souhaitez vous recevoir les candidatures
											?
										</p>
										<Input
											placeholder="exemple@mentorgoal.com"
											className="mt-sm"
											name="emailPreference"
											errorMessage={errors.emailPreference}
											value={values.emailPreference}
											onChange={handleChange}
										/>
									</>
								}
							/>
							{loading ? (
								<div className="flex w-full">
									<Spinner className="mx-auto" />
								</div>
							) : (
								<ButtonPrimary
									key="confirmBTN"
									label="Envoyer"
									className="button-form mx-auto mb-xxlg mt-md"
									type="submit"
									disabled={pdfLoading}
									onClick={() => {
										handleSubmit();
									}}
								/>
							)}
						</>
					)}
				</Formik>
			) : (
				<WhiteCard
					content={
						<div>
							<p className="mb-md text-center font-bold">Félicitations !</p>
							<p className="mb-md text-center">
								Votre recrutement a bien été créé auprès de l’école.
								<br />
								Vous pouvez si vous le souhaitez, déposer une nouvelle demande.
								Vos recrutement sont également disponible dans l’onglet : “Vos
								recrutements”.
							</p>
							<ButtonPrimary
								className="mx-auto"
								label="Retour à l'accueil"
								onClick={() => {
									navigate(`/company?${school?.subdomain || ""}`);
								}}
							/>
						</div>
					}
				/>
			)}
		</div>
	);
}
