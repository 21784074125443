import { errorToast } from "@tools/Toasts";

/**
 * @description: utility function to check if a condition is true, if not, throw an error with the message
 * @param condition
 * @param message
 */
export const invariant = (condition: boolean, message: string) => {
	if (!condition) {
		errorToast(message);
		throw new Error(message);
	}
};
