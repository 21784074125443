import type {
	CampusSelectedType,
	CampusesCollectionType,
} from "@/types/campusTypes";

/**
 * @deprecated
 */
export const extractCampusList = (
	campuses: CampusesCollectionType,
): string[] => {
	let campusCollection: string[] = [];
	if (Array.isArray(campuses)) {
		if (
			campuses.every(
				(campus) =>
					typeof campus === "object" && "id" in campus && "name" in campus,
			)
		) {
			campusCollection = campuses.map((campus) => campus.id);
		} else {
			campusCollection = computeAllCampusIds(campuses[1]);
		}
	} else if (campuses?.collection && Array.isArray(campuses.collection)) {
		campusCollection = campuses.collection.map(
			(campus: CampusSelectedType | string) =>
				typeof campus === "string" ? campus : campus.id,
		);
	}

	return campusCollection;
};

export const computeAllCampusIds = (campuses: CampusSelectedType[]) => {
	if (Array.isArray(campuses) === false) {
		return [];
	}
	const campusIds: string[] =
		campuses?.map((campus: CampusSelectedType) => campus?.value as string) ||
		[];
	return campusIds;
};
