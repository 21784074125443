import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "../CVBoard.scss";

import { cloudDownloadOutline } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { UsersFilters } from "@modules/usersFilters";
import {
	countCurrentFilters,
	setCurrentFilters,
} from "@modules/usersFilters/usersFiltersSlice";
import { ResetUserFiltersButton } from "@modules/usersFilters/widgets/ResetUserFiltersButton";
import { ToggleUserFilters } from "@modules/usersFilters/widgets/ToggleUserFilters";
import { UserfilterSearchbar } from "@modules/usersFilters/widgets/UserfilterSearchbar";

import { ImportModal } from "./Head/ImportModal";

export function Head() {
	const dispatchEvent = useDispatch();
	const { currentFilters, showFilters } = useAppSelector(
		(state) => state.usersFilters,
	);
	const [showImportModal, setShowImportModal] = useState(false);

	useEffect(() => {
		dispatchEvent(countCurrentFilters());
	}, []);

	return (
		<div className="flex w-full flex-col gap-sm">
			<div className="flex justify-between">
				<div className="flex w-7/12 gap-sm">
					<UserfilterSearchbar />
					<ToggleUserFilters disabled={!showFilters} />
					<ResetUserFiltersButton />
				</div>

				<ButtonPrimary
					onClick={() => setShowImportModal(true)}
					label="Importer un CV"
					icon={cloudDownloadOutline}
					className="btn-add ml-sm w-2/12"
				/>
			</div>
			{showFilters && (
				<UsersFilters
					filtersProps={{
						filters: currentFilters,
						setFilters: setCurrentFilters,
					}}
				/>
			)}
			<ImportModal
				show={showImportModal}
				onClose={() => setShowImportModal(false)}
			/>
		</div>
	);
}
