import { Formik } from "formik";
import React, { useState } from "react";

import { plusFill, trash2Outline } from "@assets/Icons";

import { checkEmail, checkLinkedinProfile, checkPhone } from "@tools/Checkers";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Input } from "@designSystem/molecules/Input";
import { InputPhoneNumber } from "@designSystem/molecules/PhoneInput";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { createContact } from "@containers/student/Applications/core/api/ApplicationDetailAPI";
type NewContactModalProps = {
	show: boolean;
	onClose: () => void;
	applicationId: string | number;
	contactsProps: any;
};
export type ContactErrorType = {
	firstname?: string;
	lastname?: string;
	job?: string;
	email?: string;
	linkedin?: string;
	phone1?: string;
	phone2?: string;
};
export function NewContactModal({
	show,
	onClose,
	contactsProps,
	applicationId,
}: NewContactModalProps) {
	const [displaySecondPhone, setDisplaySecondPhone] = useState(false);

	const { contacts, setContacts } = contactsProps;

	const [isCreating, setIsCreating] = useState(false);

	const handleCreateContact = async (values: {
		firstname?: string;
		lastname?: string;
		job?: string;
		email?: string;
		linkedin?: string;
		phone1?: string;
		phone2?: string;
		image?: any;
	}) => {
		setIsCreating(true);
		const contactsCopy = [...contacts];
		const phones: string[] = [];

		if (values.phone1) phones.push(values.phone1);
		if (values.phone2) phones.push(values.phone2);

		const dataToSent = {
			applications: `${applicationId}`,
			firstname: values.firstname,
			lastname: values.lastname,
			job: values.job,
			phone: phones,
			email: values.email,
			linkedin: values.linkedin,
		};

		try {
			const createdContact: any = await createContact(dataToSent);

			contactsCopy.push({
				id: createdContact.createContact.contact.id,
				firstname: values.firstname,
				lastname: values.lastname,
				favorite: false,
				job: values.job,
				phone: createdContact.createContact.contact.phone,
				linkedin: values.linkedin,
				email: values.email,
				image: values.image,
			});
			setContacts(contactsCopy);
			onClose();
		} catch {}

		setIsCreating(false);
	};

	return (
		<div>
			<Formik
				initialValues={{
					firstname: "",
					lastname: "",
					job: "",
					email: "",
					linkedin: "",
					phone1: "",
					phone2: "",
					image: null,
				}}
				validate={(values) => {
					const errors: ContactErrorType = {};
					if (!values.firstname) {
						errors.firstname = "Veuillez entrer le prénom du contact";
					}
					if (!values.lastname) {
						errors.lastname = "Veuillez entrer le nom du contact";
					}
					if (!values.job) {
						errors.job = "Veuillez entrer le nom du poste occupé";
					}
					if (values.email && !checkEmail(values.email)) {
						errors.email = "Entrez un email valide";
					}
					if (values.linkedin && !checkLinkedinProfile(values.linkedin)) {
						errors.linkedin = "Entrez un Linkedin valide";
					}
					if (
						values.phone1 &&
						values.phone1.length > 0 &&
						!checkPhone(values.phone1)
					) {
						errors.phone1 = "Veuillez remplir un numéro de téléphone valide";
					}

					if (
						values.phone2 &&
						values.phone2.length > 0 &&
						!checkPhone(values.phone2)
					) {
						errors.phone2 = "Veuillez remplir un numéro de téléphone valide";
					}
					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					handleCreateContact(values);
					resetForm({});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					resetForm,
					setFieldValue,
				}) => (
					<Modal
						show={show}
						onClose={() => {
							onClose();
							resetForm({});
						}}
						title="Ajouter un contact"
						body={
							<form onSubmit={handleSubmit} className="pb-xsm">
								<div className="flex flex-col items-center gap-md md:flex-row">
									<Avatar
										modify
										image={values.image}
										onChange={(object: { [key: string]: any }) => {
											setFieldValue("image", object.url);
										}}
										firstname={values.firstname}
										lastname={values.lastname}
										size="lg"
									/>
									<div className="w-full">
										<div className="flex flex-col gap-sm md:flex-row">
											<Input
												placeholder="Prénom"
												type="text"
												name="firstname"
												className="w-full md:w-1/2"
												onChange={handleChange}
												value={values.firstname}
												errorMessage={
													errors.firstname || touched.firstname
														? errors.firstname
														: undefined
												}
											/>
											<Input
												placeholder="Nom"
												type="text"
												name="lastname"
												className="w-full md:w-1/2"
												onChange={handleChange}
												value={values.lastname}
												errorMessage={
													errors.lastname || touched.lastname
														? errors.lastname
														: undefined
												}
											/>
										</div>
										<Input
											placeholder="Poste occupé"
											className="mt-sm"
											type="text"
											name="job"
											onChange={handleChange}
											value={values.job}
											errorMessage={
												errors.job || touched.job ? errors.job : undefined
											}
										/>
									</div>
								</div>
								<Input
									label={"Email (Optionnel)"}
									placeholder="Email"
									type="email"
									name="email"
									className="mt-md"
									onChange={handleChange}
									value={values.email}
									errorMessage={
										errors.email || touched.email ? errors.email : undefined
									}
								/>
								<Input
									label={"Linkedin (Optionnel)"}
									placeholder="https://"
									className="mt-sm"
									name="linkedin"
									type="text"
									onChange={handleChange}
									value={values.linkedin}
									errorMessage={
										errors.linkedin || touched.linkedin
											? errors.linkedin
											: undefined
									}
								/>

								<InputPhoneNumber
									required={false}
									value={values.phone1}
									name="phone1"
									onChange={(phone) => {
										setFieldValue("phone1", phone);
									}}
									onBlur={(phone) => {
										setFieldValue("phone1", phone);
									}}
									label={"Numéro de téléphone (Optionnel)"}
									className="mt-sm"
								/>
								{displaySecondPhone ? (
									<div className="mt-sm flex">
										<InputPhoneNumber
											required={false}
											value={values.phone2}
											onBlur={(phone) => setFieldValue("phone2", phone)}
											onChange={(phone) => {
												setFieldValue("phone2", phone);
											}}
											label={"Numéro de téléphone (Optionnel)"}
											className="mt-sm w-full"
										/>
										<ButtonTertiary
											onClick={() => {
												setDisplaySecondPhone(false);
											}}
											icon={trash2Outline}
											className="ml-sm mt-md"
										/>
									</div>
								) : (
									<InlineButton
										id="add-phone"
										onClick={() => {
											setDisplaySecondPhone(true);
										}}
										icon={plusFill}
										label="Ajouter un numéro supplémentaire"
										className="mt-sm"
									/>
								)}
							</form>
						}
						buttonsRight={[
							<ButtonSecondary
								key="cancelBTN"
								onClick={() => {
									onClose();
									resetForm({});
								}}
								label="Annuler"
							/>,
							<ButtonPrimary
								key="confirmBTN"
								type="submit"
								onClick={() => {
									handleSubmit();
								}}
								label="Confirmer"
								disabled={isCreating}
							/>,
						]}
					/>
				)}
			</Formik>
		</div>
	);
}
