// USAGE MULTISELECT DEPRECIE : Utiliser <Multiselect /> à la place

import useIsTextTruncated from "@hooks/useIsTextTruncated";
import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

import { arrowDownOutline, arrowUpOutline, searchFill } from "@assets/Icons";

import { Checkbox } from "@designSystem/atoms/Checkbox";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { InputSecondary } from "@designSystem/molecules/InputSecondary";
import { ItemSimpleLarge } from "@designSystem/molecules/ItemSimpleLarge";
import "@assets/sass/ItemMenu.scss";

type SelectType = {
	placeholder?: string;
	className?: string;
	inputs: Array<any>;
	position?: "left" | "right";
	onChange: Function;
	bgColor?: string;
	multiselect?: boolean;
	breakSpaces?: boolean;
	label?: string;
	withSearchbar?: boolean;
	searchbarPlaceholder?: string;
	tooltip?: string;
};

let uniqueId = 0;

export function Select({
	placeholder,
	className,
	inputs,
	position,
	onChange,
	bgColor,
	multiselect,
	breakSpaces,
	label,
	withSearchbar = false,
	searchbarPlaceholder = "Rechercher",
	tooltip = "",
}: SelectType) {
	const [componentId] = useState(() => uniqueId++);
	const [isOpen, setIsOpen] = useState(false);

	const [selectedValue, setSelectedValue] = useState("");
	const [selectedValues, setSelectedValues] = useState<any[]>([]);
	const ref = useRef(null);
	const refOfValue = useRef(null);
	const valueTruncate = useIsTextTruncated(refOfValue);

	const [searchValue, setSearchValue] = useState("");

	const returnValue = (input: any) => {
		if (multiselect) {
			return input;
		}
		if (
			typeof input?.value === "boolean" ||
			typeof input?.value === "object" ||
			typeof input?.value === "string"
		) {
			return input.value;
		}
		return input.label;
	};

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [isOpen]);

	useEffect(() => {
		if (inputs.find((input) => input.type === "checkbox") && multiselect) {
			const defaultValue = inputs.filter((input) => input.checked);
			setSelectedValues(defaultValue);
		}
	}, [inputs]);

	return (
		<div
			data-id="select-primary"
			id={`select-primary-${componentId}`}
			className={`select-primary dropdown relative ${className}`}
			onClick={() => {
				setIsOpen(!isOpen);
			}}
			ref={ref}
		>
			{label && (
				<p className="mb-xxsm flex gap-2 text-xxsm text-primary-300">
					{label}
					{tooltip && tooltip !== "" && (
						<>
							<TooltipElement id={`tooltip-select-primary-${componentId}`} />
							<TooltipContainer
								anchorId={`#tooltip-select-primary-${componentId}`}
								place="right"
							>
								{tooltip}
							</TooltipContainer>
						</>
					)}
				</p>
			)}
			<div
				data-toggle="dropdown"
				className={`relative flex cursor-pointer items-center justify-between gap-4 px-4 py-2 text-primary-700P ${bgColor} w-full min-w-[180px] rounded-md border border-primary-150 bg-white `}
				data-bs-toggle="dropdown"
			>
				<div className="flex items-center gap-4 max-w-full">
					<p
						className={`truncate w-full ${
							selectedValue && "selected text-base text-primary-300"
						}`}
						id={`text-truncate-${placeholder?.slice(0, 4)}`}
						ref={refOfValue}
					>
						{selectedValue || placeholder}
					</p>
					{valueTruncate && (
						<TooltipContainer
							anchorId={`#text-truncate-${placeholder?.slice(0, 4)}`}
							place="top"
						>
							{selectedValue || placeholder}
						</TooltipContainer>
					)}
					{selectedValues?.length > 0 && (
						<span className="flex h-6 w-6 items-center justify-center rounded-md border border-accent-1-dark bg-white p-1">
							<p className="text-[10px] font-bold text-primary-700P">
								{selectedValues?.length}
							</p>
						</span>
					)}
				</div>
				<Icon
					className="h-6 text-primary-700P"
					icon={isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>
			<div
				className={`dropdown-menu absolute z-10 h-auto max-h-96 min-w-full flex-col overflow-y-scroll rounded-sm border-xs border-slate-200 bg-white ${
					isOpen ? "show" : "hidden"
				} ${position === "left" && "left-version"}`}
			>
				{withSearchbar && (
					<div
						className="w-full min-w-max"
						onClick={(e) => e.stopPropagation()}
					>
						<InputSecondary
							name=""
							onChange={(e) => {
								setSearchValue(e.target.value);
							}}
							icon={searchFill}
							placeholder={searchbarPlaceholder || ""}
							value={searchValue}
						/>
					</div>
				)}
				{inputs
					?.filter((currentInput) => {
						const lowerCaseSearchValue = searchValue?.toLowerCase() || "";
						const lowerCaseInputName =
							currentInput?.name?.toLowerCase() ||
							currentInput?.label?.toLowerCase() ||
							"";
						return lowerCaseInputName?.includes(lowerCaseSearchValue);
					})
					?.map((inputElementFromMap, i) => {
						if (inputElementFromMap.type === "checkbox") {
							return (
								<div
									key={i}
									className="w-full"
									onClick={(e: any) => {
										e.stopPropagation();
										const copyFilter = [...selectedValues];

										if (inputElementFromMap.checked) {
											copyFilter?.splice(
												copyFilter?.indexOf(inputElementFromMap),
												1,
											);
										} else {
											copyFilter.push(returnValue(inputElementFromMap));
										}
										onChange(copyFilter);
										setSelectedValues(copyFilter);
									}}
								>
									<ItemInteractionLarge
										onClick={(isChecked) => {
											if (isChecked) {
												if (inputElementFromMap.onEnabled) {
													inputElementFromMap.onEnabled();
												}
											} else {
												if (inputElementFromMap.onDisabled) {
													inputElementFromMap.onDisabled();
												}
											}
										}}
										label={inputElementFromMap.label}
										checked={inputElementFromMap.checked}
										breakSpaces={breakSpaces}
									/>
								</div>
							);
						}
						return (
							<div
								role="menu"
								key={i}
								className="flex items-center"
								onClick={(e: any) => {
									e.stopPropagation();
									onChange(returnValue(inputElementFromMap));
									setSelectedValue(returnValue(inputElementFromMap));
								}}
							>
								<ItemSimpleLarge
									className="w-full"
									label={inputElementFromMap.label}
								/>
							</div>
						);
					})}
			</div>
		</div>
	);
}

type ItemInteractionLargePropsType = {
	label: string;
	className?: string;
	onClick: (isChecked: boolean) => void;
	checked: boolean;
	breakSpaces?: boolean;
};

function ItemInteractionLarge({
	label,
	className = "",
	onClick,
	checked,
	breakSpaces = false,
}: ItemInteractionLargePropsType) {
	const [componentId] = useState(() => uniqueId++);
	const [isChecked, setIsChecked] = useState(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	useEffect(() => {
		const element = document.getElementById(
			`interaction-item-large-${componentId}`,
		);
		if (element) {
			element.addEventListener("keyup", (event) => {
				if (event.keyCode === 13) {
					element.click();
				}
			});
		}
	}, [componentId]);

	return (
		<div
			data-id="itemInteractionLarge"
			id={`interaction-item-large-${componentId}`}
			role="menu"
			onClick={(e: any) => {
				e.preventDefault();
				if (onClick) onClick(!isChecked);
				setIsChecked(!isChecked);
			}}
			className={`${className} interaction-item-large-container w-full`}
		>
			<Checkbox className="mr-xsm" checked={isChecked} />
			<p
				className={`w-[250px] text-primary-700P ${
					breakSpaces ? "whitespace-break-spaces" : "truncate"
				}`}
			>
				{label}
			</p>
		</div>
	);
}
