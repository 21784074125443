import API, { checkGraphQLError } from "@tools/API";

export const generateAssistedGeneration = async (
	variables: Record<string, string>,
	spsIACardId: string,
) => {
	try {
		return await API.post("assisted_generation", { ...variables, spsIACardId });
	} catch (error: any) {
		return error.response;
	}
};

export const getAssistedGenerationCards = async () => {
	const data = {
		query: `query {
      	     spsIACards (itemsPerPage: 1000, order: [{position: "ASC"}]){
      	     	collection {
      	     		id
      	     		name
      	     		description
      	     		color
      	     		tag
      	     		step
      	     		position
      	     		variablesArray
      	     	}
      	     }
    	}`,
	};
	try {
		const response = await API.post("graphql", data);
		return checkGraphQLError(
			response,
			"Erreur lors de la récupération des cartes",
		);
	} catch (error: any) {
		return error.response;
	}
};
