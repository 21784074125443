import type { CompaniesCellNameType } from "@/types/companiesManagement.types";

import type { CellType } from "@/types/TableTypes";

import type { CrmCompanyDomainModel } from "@/types/crmCompany.domainModel";
import type { FormErrorType } from "@/types/genericType";
import { COMPANIES_CELLS_MAPPING } from "@constants/Company";
import { checkSiren, checkSiret } from "./Checkers";

export const convertCompaniesCellsNamesToCells = (
	cellsNames: CompaniesCellNameType[] | ["testing"] | undefined,
): CellType[] => {
	if (!cellsNames) return [];
	const cells: CellType[] = [];
	cellsNames.forEach((cellName) => {
		const cell = COMPANIES_CELLS_MAPPING.get(cellName);
		if (cell) {
			cells.push(cell);
		}
	});
	return cells;
};

export const checkIfCompanyCouldBeCreated = (
	companyToCreate: Partial<CrmCompanyDomainModel.Company> | undefined,
	companyAlreadyExist: boolean,
	siretAlreadyExist: boolean,
): FormErrorType[] => {
	const errorStack: FormErrorType[] = [];
	if (!companyToCreate) {
		errorStack.push({
			id: "companyToCreate",
			errorMessage: "L'entreprise à créer est vide",
		});
		return errorStack;
	}

	if (!companyToCreate.name) {
		errorStack.push({
			id: "name",
			errorMessage: "Le nom de l'entreprise est obligatoire",
		});
	}

	if (companyAlreadyExist) {
		errorStack.push({
			id: "name",
			errorMessage: "L'entreprise existe déjà",
		});
	}

	if (!companyToCreate.spsReferent?.id) {
		errorStack.push({
			id: "spsReferent",
			errorMessage: "Le référent est obligatoire",
		});
	}

	if (
		companyToCreate?.coreCampuses === undefined ||
		companyToCreate.coreCampuses.length === 0
	) {
		errorStack.push({
			id: "campuses",
			errorMessage: "Le campus est obligatoire",
		});
	}

	if (companyToCreate?.siret) {
		if (
			!checkSiret(companyToCreate.siret) &&
			!checkSiren(companyToCreate.siret)
		) {
			errorStack.push({
				id: "siret",
				errorMessage: "Le siret/siren est invalide",
			});
		} else {
			if (siretAlreadyExist) {
				errorStack.push({
					id: "siret",
					errorMessage: "Le siret/siren existe déjà",
				});
			}
		}
	}

	return errorStack;
};

export const extractLogoFromClearbit = (
	clearbitResponse: {
		name: string;
		domain: string;
		logo: string;
	}[],
	companyNameToSearch: string,
): string => {
	if (
		!clearbitResponse ||
		companyNameToSearch === "" ||
		Array.isArray(clearbitResponse) === false
	) {
		return "";
	}
	const findExactMatches = clearbitResponse?.find((company: any) => {
		return company.name === companyNameToSearch;
	});
	if (!findExactMatches || !findExactMatches?.logo) return "";
	return findExactMatches.logo;
};
