import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";
import { companiesManagementSlice } from "../store/companiesManagement.slice";

export const fetchCompanyOffers = async (
	dispatch: AppDispatch,
	api: AppGetState,
	dependencies: Dependencies,
) => {
	try {
		if (!dependencies.companyOffersGateway) {
			throw new Error("companyOffersGateway is not available in dependencies");
		}
		dispatch(companiesManagementSlice.actions.handleOffersLoading());
		const companyInformations =
			await dependencies.companyOffersGateway?.getCompanyOffers(
				api().companiesManagement.targetCompany,
			);
		dispatch(
			companiesManagementSlice.actions.modifyCompanyOffers(companyInformations),
		);
	} catch (error: unknown) {
		console.error(error);
		dispatch(
			companiesManagementSlice.actions.handleOffersError(
				extractError(error).message.toString(),
			),
		);
	}
};
