import type { CompaniesCellNameType } from "@/types/companiesManagement.types";
import { useAppSelector } from "@config/redux/hook";
import useCompaniesCellFactory from "@hooks/compagnies/useCompaniesCellFactory";
import React, { useCallback } from "react";

import { convertCompaniesCellsNamesToCells } from "@tools/Companies";
import { sortCellsByIndex } from "@tools/Table";

import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { SelectItemPerPage } from "@designSystem/organisms/select/SelectItemPerPage";
import { type HeadType, Table } from "@designSystem/templates/table/Table";

import { extractIdNumberFromId } from "@tools/Users";

import { useAppDispatch } from "@config/redux/store";
import type { CrmCompanyDomainModel } from "../../../../../../types/crmCompany.domainModel";
import { companiesManagementSlice } from "../../../core/store/companiesManagement.slice";
import { useCompaniesTable } from "./useCompaniesTable";

const MIN_COMPANIES_FOR_SHOWING_SELECT = 9;
const CELL_WITHOUT_SORT: string[] = [];

export function CompaniesTable() {
	const { buildCompaniesCellFromName } = useCompaniesCellFactory();
	const presenter = useCompaniesTable();

	const setOrder = companiesManagementSlice.actions.setOrder;
	const {
		filteredCompaniesList,
		loadingStates,
		paginationState,
		numberOfCompanies,
		companiesTableConfig,
	} = useAppSelector((state) => state.companiesManagement);
	const dispatchEvent = useAppDispatch();

	const resolveSortName = (fieldName: string) => {
		switch (fieldName) {
			case "spsReferent":
				return "spsReferent_lastname";
			case "location":
				return "postalCode";
			default:
				return fieldName;
		}
	};

	const generateHeaders = () => {
		const headers: HeadType[] = [];
		if (!companiesTableConfig) return headers;
		sortCellsByIndex(
			convertCompaniesCellsNamesToCells(companiesTableConfig),
		)?.forEach((cell) => {
			const headerToCreate: HeadType = {
				label:
					cell.cellName === "name"
						? `${cell.cellLabel} (${numberOfCompanies})`
						: cell.cellLabel,
				sort: undefined,
			};
			if (!CELL_WITHOUT_SORT.includes(cell.cellLabel)) {
				headerToCreate.sort = {
					fieldName: resolveSortName(cell.cellName),
					defaultOrder: "ASC",
				};
			}
			headers.push(headerToCreate);
		});

		return headers;
	};

	const generateRows = () => {
		if (!filteredCompaniesList) return [];
		return filteredCompaniesList?.map(
			(company: CrmCompanyDomainModel.Company) => {
				const cellsOfThisRow: any = [];
				sortCellsByIndex(
					convertCompaniesCellsNamesToCells(companiesTableConfig),
				).forEach((cell) => {
					const cellToCreate = buildCompaniesCellFromName(
						cell.cellName as CompaniesCellNameType,
						company,
					);
					if (cellToCreate) {
						cellsOfThisRow.push({ children: cellToCreate });
					}
				});

				return {
					url: `/crm-company/${extractIdNumberFromId(company.id)}`,
					cells: cellsOfThisRow,
				};
			},
		);
	};

	const modifyFilters = useCallback((filters: any) => {
		const key = Object.keys(filters.order[0])[0];
		const value = filters.order[0][key];
		const stringifiedOrder = `[{${key}: "${value}"}]`;
		dispatchEvent(setOrder(stringifiedOrder) as any);
	}, []);

	return (
		<section
			data-testid="companiesManagementableContainer"
			className="mt-sm overflow-x-auto"
		>
			<Table
				headers={generateHeaders()}
				rows={generateRows()}
				filtersProps={{
					filters: {},
					setFilters: (filters: any) => {
						modifyFilters(filters);
					},
				}}
				isLoading={loadingStates.isFetchingCompanies}
			/>
			<div className="flex justify-between rounded-b-md bg-white px-sm pb-md pt-lg mb-12">
				{filteredCompaniesList &&
					filteredCompaniesList.length > MIN_COMPANIES_FOR_SHOWING_SELECT && (
						<SelectItemPerPage
							onChange={presenter.modifyItemPerPage}
							defaultValue={paginationState.itemsPerPage}
						/>
					)}
				<PaginationPrimary
					last={paginationState.lastPage}
					current={paginationState.pageShown}
					onChange={presenter.modifyCurrentPage}
					isLoading={loadingStates.isFetchingCompanies}
				/>
			</div>
		</section>
	);
}
