import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "@assets/sass/Inputs.scss";

import { closeOutline, eyeOffOutline, eyeOutline } from "@assets/Icons";

import { Spinner } from "@designSystem/atoms/Spinner";

import "react-nice-dates/build/style.css";
import { SystemIDProvider } from "@config/core/system.id-provider";

// Rename en SearchBar
export function InputLargePrimary({
	icon,
	placeholder = "",
	value = "",
	onChange,
	className = "",
	name = "",
	type = "text",
	errorMessage = "",
	onBlur,
	label,
	onFocus,
	disabled = false,
	containerClassName,
	isLoading = false,
	helperText,
}) {
	const [inputValue, setInputValue] = useState(value);
	const [visibility, setVisibility] = useState(!type === "password");
	const idProvider = new SystemIDProvider();
	const id = idProvider.generate();

	useEffect(() => {
		setInputValue(value);
	}, [value, onBlur]);

	const resetValue = () => {
		setInputValue("");
		if (onChange) onChange({ target: { value: "", name } });
	};

	const changeVisibility = () => {
		setVisibility(!visibility);
	};

	return (
		<div className={`flex w-full flex-col gap-xxsm ${containerClassName}`}>
			{label && (
				<label
					htmlFor="inputLargePrimary"
					className="text-xxsm text-primary-500"
				>
					{label}
				</label>
			)}
			<div
				data-id="inputLargePrimary"
				className={`flex items-center gap-xsm rounded-md border border-primary-150 px-sm py-xsm transition-all ${
					errorMessage
						? "has-[:focus]:shadow-accent3Light border-accent-3-dark"
						: "has-[:focus]:border-accent-1-dark has-[:focus]:shadow-accent1Light hover:border-accent-1 hover:shadow-accent1Lighter"
				} bg-white ${className}`}
			>
				{icon && !isLoading && (
					<Icon className="icon-input" width={20} height={20} icon={icon} />
				)}
				{isLoading && <Spinner size="small" />}
				<input
					id={id}
					autoComplete="on"
					onBlur={onBlur ? onBlur : null}
					onFocus={onFocus}
					name={name}
					type={type === "password" ? (visibility ? "text" : "password") : type}
					className={`${
						icon && " with-icon "
					} w-full text-xsm outline-none autofill:!shadow-white ${
						errorMessage && "error-message"
					}`}
					placeholder={placeholder}
					value={inputValue}
					onChange={(e) => {
						onChange(e);
						setInputValue(e.target.value);
					}}
					disabled={disabled}
				/>
				{!disabled && (
					<Icon
						onClick={() => {
							resetValue();
						}}
						className={`close-icon ${!inputValue && "hidden"}`}
						icon={closeOutline}
					/>
				)}
				{type === "password" &&
					(visibility ? (
						<Icon
							className="password-icon cursor-pointer"
							icon={eyeOutline}
							onClick={changeVisibility}
						/>
					) : (
						<Icon
							className="password-icon cursor-pointer"
							icon={eyeOffOutline}
							onClick={changeVisibility}
						/>
					))}
			</div>
			{errorMessage && (
				<p className="text-xxxsm font-bold text-error-dark">{errorMessage}</p>
			)}
			{!errorMessage && helperText && (
				<p className="text-xxxsm font-bold text-primary-200">{helperText}</p>
			)}
		</div>
	);
}

InputLargePrimary.propTypes = {
	icon: PropTypes.shape({}),
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	errorMessage: PropTypes.string,
	onBlur: PropTypes.func,
	label: PropTypes.string,
	onFocus: PropTypes.func,
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
};
