import type { OfferApplicationType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useOutletContext, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { Spinner } from "@designSystem/atoms/Spinner";
import { BodyGrid } from "@designSystem/templates/page/BodyGrid";
import { Container } from "@designSystem/templates/page/Container";
import { Subcontainer } from "@designSystem/templates/page/Subcontainer";

import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import {
	modifyOfferToCreate,
	setUserWasInEditionPage,
} from "../core/store/offersManagement.slice";
import { fetchOfferDetails } from "../core/store/offersManagement.thunks";
import { ComplementaryInformations } from "../ui/editing/ComplementaryInformations";
import { OfferCreationActions } from "../ui/editing/OfferCreationActions";
import OfferCreationDetails from "../ui/editing/OfferCreationDetails";
import { OfferCreationHeader } from "../ui/editing/OfferCreationHeader";
import { OfferCreationInformationHeader } from "../ui/editing/OfferCreationInformationHeader";
import { OfferCreationParameters } from "../ui/editing/OfferCreationParameters";
import { OfferIdentityInformations } from "../ui/editing/OfferIdentityInformations";

const removeOverflowHidden = () => {
	const body = document.getElementsByTagName("body")[0];
	body.classList.remove("overflow-hidden");
};

export function OfferEditorView({
	editionMode = false,
}: {
	editionMode: boolean;
}) {
	const dispatchEvent = useDispatch();
	const { setTitle }: { setTitle: (title: string) => void } =
		useOutletContext();
	const { state } = useLocation();
	const { offerToCreate, offerToEdit, loadingStates } = useAppSelector(
		(state) => state.offers,
	);

	const { id } = useParams<{ id: string }>();
	const [searchParams] = useSearchParams();
	const { user }: GlobalContextType = useContext(GlobalContext);

	const loadOfferToEdit = async (entOfferType?: OfferApplicationType) => {
		await dispatchEvent(
			fetchOfferDetails({
				id: id ? `/referent/offers/${id}` : state.entOffer.id,
				copyToCreate: true,
				entOfferType,
				user,
			}) as any,
		);
	};

	useEffect(() => {
		if (state?.entOffer) {
			setTitle("Publication d’une offre partenaire");
			editionMode = true;
			dispatchEvent(setUserWasInEditionPage(true));
			loadOfferToEdit(state.entOffer.type);
		} else {
			setTitle(editionMode ? "Édition d’une offre" : "Création d’une offre");
			const typeFromUrl = searchParams.get("type") as OfferApplicationType;
			if (typeFromUrl) {
				dispatchEvent(modifyOfferToCreate({ type: typeFromUrl }));
			}
			removeOverflowHidden();
			if (offerToEdit === undefined && editionMode) {
				loadOfferToEdit();
			}
		}
	}, []);

	return (
		<main data-testid="offerEditorView" className="flex flex-col gap-6">
			{(!offerToEdit && editionMode) || loadingStates.isFetchingOfferDetails ? (
				<div className="flex h-[70vh] w-full flex-col items-center justify-center gap-6">
					<p className="text-base font-bold text-primary-700P">
						Chargement de l'offre
					</p>
					<Spinner />
				</div>
			) : (
				<>
					<OfferCreationHeader
						dataToUse={{ offerToCreate, offerToEdit }}
						editionMode={editionMode}
					/>
					<OfferCreationInformationHeader />
					<BodyGrid>
						<Container side="left">
							<Subcontainer>
								<OfferIdentityInformations />
								<OfferCreationDetails />
								<OfferCreationActions editionMode={editionMode} />
							</Subcontainer>
						</Container>
						<Container side="right">
							<Subcontainer>
								<ComplementaryInformations editionMode={editionMode} />
							</Subcontainer>
							{!editionMode && <OfferCreationParameters />}
						</Container>
					</BodyGrid>
				</>
			)}
		</main>
	);
}
