import { useBreakpoints } from "@hooks/useBreakpoints";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";
import { useOutletContext } from "react-router";

import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { editUser } from "./AnnoncementsApi";

type AnnouncementsModalType = {
	onClose: () => void;
	show: boolean;
	link: string;
};
export default function AnnouncementsModal({
	onClose,
	show,
	link,
}: AnnouncementsModalType) {
	const { user } = useContext(GlobalContext);
	const { isMobile } = useBreakpoints();
	onClose;
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const renderButton = () => {
		if (isMobile) {
			return {
				buttonsRight: [
					<ButtonSecondary
						key={0}
						onClick={(e) => {
							e.stopPropagation();
							onClose();
						}}
						label="Quitter"
					/>,
				],
			};
		}
		return { buttonsRight: [] };
	};

	return (
		<Modal
			title="Nouveauté"
			show={show}
			onClose={() => {
				if (!user) return;
				const dataToSend: {
					id: string;
					hasSeenNews?: boolean;
				} = {
					id: user.id,
				};
				dataToSend.hasSeenNews = true;
				if (currentInterface === "school" || currentInterface === "student") {
					editUser(dataToSend, currentInterface);
				}
				onClose();
			}}
			body={
				<>
					<iframe
						title="news"
						className="min-h-[550px] w-full"
						id="softr-c39f8491-741c-498c-8754-4eee2e9cfab8"
						src={link}
					/>
				</>
			}
			{...renderButton()}
		/>
	);
}
