import React from "react";
import { useLocation } from "react-router";

import { TemplateLayout } from "@modules/softr/TemplateLayout";
/***
 * @description  toolkit to ship fast, cms build some pages, for mg academy.
 * @docs https://docs.softr.io
 */
export default function TestSoftr() {
	const { pathname } = useLocation();
	return (
		<main className="h-[100vh] pb-0 pl-xxlg pr-0">
			{pathname === "/test-softr-preview" ? (
				<>
					<TemplateLayout />
					<iframe
						title="test"
						id="softr-c39f8491-741c-498c-8754-4eee2e9cfab8"
						src="https://karyn9541.softr.app/?user=1"
						width="100%"
						height="100%"
					/>
				</>
			) : (
				<iframe
					title="test"
					className="h-full w-full"
					id="softr-c39f8491-741c-498c-8754-4eee2e9cfab8"
					src="https://karyn9541.softr.app/?user=1"
				/>
			)}
		</main>
	);
}
