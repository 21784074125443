import { useBreakpoints } from "@hooks/useBreakpoints";
import { NavigationItem } from "@navigation/NavigationItem";
import { GlobalContext } from "@navigation/Router";
import { SubMenu } from "@navigation/SubMenu";
import type { NavigationBarType, NavigationType } from "@navigation/type";
import React, { createContext, useContext, useEffect, useState } from "react";

import "./NavigationBar.scss";

import { closeOutline } from "@assets/Icons";
import logoLight from "@assets/logos/logoLight.svg";
import tinyLogoLight from "@assets/logos/tinyLogoLight.svg";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

export const NavigationBarContext = createContext<{
	currentLabelSubMenu: string;
	setCurrentLabelSubMenu: Function;
	activeNavigationBar: boolean;
	menuHover: boolean;
	setMenuHover: Function;
}>({
	currentLabelSubMenu: "",
	setCurrentLabelSubMenu: () => {},
	activeNavigationBar: false,
	menuHover: false,
	setMenuHover: () => {},
});
export function NavigationBar({ navigation }: NavigationBarType) {
	const { menuActiveNavigation, setMenuActiveNavigation } =
		useContext(GlobalContext);
	const { isMobile } = useBreakpoints();
	const [activeNavigationBar, setActiveNavigationBar] = useState(false);
	const [menuHover, setMenuHover] = useState(false);
	const [currentLabelSubMenu, setCurrentLabelSubMenu] = useState("");
	const onHideMenu = () => {
		setMenuActiveNavigation(false);
		setActiveNavigationBar(false);
	};

	useEffect(() => {
		if (menuActiveNavigation) {
			setActiveNavigationBar(menuActiveNavigation);
		}
	}, [menuActiveNavigation]);

	return (
		<>
			<div
				role="menu"
				onClick={onHideMenu}
				className={`menu-container ${activeNavigationBar ? "active" : ""}`}
			>
				<div
					className={`menu group md:p-sm ${
						!activeNavigationBar ? "sm:p-0" : "p-sm"
					} ${menuHover ? "hover:w-[245px]" : "w-[72px]"}  hover:ease-in`}
					onClick={(e: any) => {
						e.stopPropagation();
					}}
					onMouseEnter={() => {
						setMenuHover(true);
					}}
					onMouseLeave={() => {
						setMenuHover(false);
					}}
				>
					<div className="mb-md flex items-center justify-between">
						<img
							alt="Logo Mentor Goal"
							src={logoLight}
							className="logo-menu mt-xsm max-w-fit"
						/>
						<img
							alt="Logo Mentor Goal"
							src={tinyLogoLight}
							className="tiny-logo-menu mt-xsm max-w-fit"
						/>
						<ButtonPrimary
							label=""
							className="md:hidden"
							icon={closeOutline}
							onClick={onHideMenu}
						/>
					</div>
					<NavigationBarContext.Provider
						value={{
							currentLabelSubMenu,
							setCurrentLabelSubMenu,
							activeNavigationBar,
							menuHover,
							setMenuHover,
						}}
					>
						<div className="list-menu-items mt-xxsm justify-items-stretch overflow-hidden transition">
							{navigation.map((nav: NavigationType, index: number) => (
								<div key={nav.title}>
									{nav.subNavigation ? (
										<SubMenu
											key={index}
											nav={nav}
											onClickSubMenu={() => {
												if (isMobile) {
													setActiveNavigationBar(false);
												}
											}}
										/>
									) : (
										<NavigationItem
											key={index}
											nav={nav}
											onClick={() => {
												setCurrentLabelSubMenu("");
												if (isMobile) {
													setActiveNavigationBar(false);
												}
											}}
										/>
									)}
								</div>
							))}
						</div>
					</NavigationBarContext.Provider>
				</div>
			</div>
		</>
	);
}
