import type { SelectionType } from "@/types/profileSharingTypes";
import type { UserType } from "@/types/userTypes";
import React, { useEffect, useState } from "react";

import { copyOutline } from "@assets/Icons";

import { convertDateFromNow, formatDate } from "@tools/Dates";

import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { CellAvatarLabelSecondary } from "@designSystem/molecules/CellAvatarLabelSecondary";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { Table } from "@designSystem/templates/table/Table";
import { success } from "@tools/Toasts";

import type { ProfilSharingFiltersType } from "@containers/school/ProfileSharing";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { SelectionModal } from "./SelectionModal";
type TableViewType = {
	pageProps: { page: number; setPage: (page: number) => void };
	selections: SelectionType[];
	lastPage: number;
	filtersProps: {
		filters: ProfilSharingFiltersType;
		setFilters: (filters: ProfilSharingFiltersType) => void;
	};
	load: boolean;
	user?: UserType;
	onDelete: () => void;
};
export function TableView({
	pageProps,
	selections,
	lastPage,
	filtersProps,
	load,
	user,
	onDelete,
}: TableViewType) {
	const { page, setPage } = pageProps;
	const { filters, setFilters } = filtersProps;
	const [selectedSelection, setSelectedSelection] = useState<SelectionType>();
	const [showModal, setShowModal] = useState<boolean>();
	const headers = [
		{
			label: "Entreprise",
		},
		{
			label: "Poste à pourvoir",
		},
		{
			label: "Date de l'envoi",
		},
		{
			label: "Profils partagés",
		},
		{
			label: "CV consultés",
		},
		{
			label: "Dernière ouverture",
		},
		{
			label: "Partagé par",
		},
		{
			label: "Lien",
		},
	];

	const cellsResolver = (selection: SelectionType) => {
		return [
			{
				children: (
					<span className="wg-no-translate">
						{selection.crmCompany?.name ?? "Non renseigné"}
					</span>
				),
			},
			{
				children: (
					<span className="wg-no-translate">
						{selection.name?.length === 0 ? "Non renseigné" : selection.name}
					</span>
				),
			},
			{
				children: formatDate(new Date(selection.sendDate)),
			},
			{
				children: `${selection.studentsCount} étudiants`,
			},
			{
				children: selection.consultedEntities?.length ?? 0,
			},
			{
				children: selection.lastOpening
					? convertDateFromNow(new Date(selection.lastOpening!))
					: "Jamais ouvert",
			},
			{
				children: (
					<div className="cell-app">
						{selection.spsReferent ? (
							<CellAvatarLabelSecondary
								translate={false}
								label={`${selection.spsReferent.lastname} ${selection.spsReferent.firstname}`}
								sublabel=""
								firstname={selection?.spsReferent?.firstname || ""}
								lastname={selection?.spsReferent?.lastname || ""}
								avatar={selection?.spsReferent.avatar?.filePath || ""}
							/>
						) : (
							"-"
						)}
					</div>
				),
			},
			{
				onClick: () => {
					navigator.clipboard.writeText(
						`${import.meta.env.VITE_URL}/selection/${user!.schoolSubdomain}/${
							selection.token
						}`,
					);
					success("Le lien a bien été copié dans le presse-papiers");
				},
				children: (
					<>
						<div id={`copy-btn-${selection.token}`}>
							<ButtonTertiary icon={copyOutline} className="text-primary-300" />
						</div>

						<TooltipContainer
							anchorId={`#copy-btn-${selection.token}`}
							place="top"
							float={false}
						>
							Copier le lien
						</TooltipContainer>
					</>
				),
			},
		];
	};

	useEffect(() => {}, [selections]);

	return (
		<>
			<Table
				isLoading={load}
				filtersProps={{ filters, setFilters }}
				headers={headers}
				rows={selections.map((selection) => {
					return {
						onClick: () => {
							setSelectedSelection(selection);
							setShowModal(true);
						},
						cells: cellsResolver(selection),
					};
				})}
			/>
			<PaginationPrimary
				onChange={(newPage) => {
					setPage(newPage);
					setFilters({ ...filters, page: newPage });
				}}
				current={page}
				last={lastPage}
			/>
			<SelectionModal
				onDelete={onDelete}
				selectionId={selectedSelection?.id || ""}
				show={showModal!}
				onClose={() => {
					setShowModal(false);
				}}
				user={user!}
			/>
		</>
	);
}
