import type { UserCompleteType } from "@/types/userTypes";
import React from "react";

import { eyeOutline, minusOutline } from "@assets/Icons";

import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { CellAvatarLabelSecondary } from "@designSystem/molecules/CellAvatarLabelSecondary";

type SelectionPropsType = {
	CVsListProps: {
		selectedCVs: Partial<UserCompleteType>[];
		setSelectedCVs: Function;
	};
	noModifiable?: boolean;
};
export function Selection({
	CVsListProps,
	noModifiable = false,
}: SelectionPropsType) {
	const { selectedCVs, setSelectedCVs } = CVsListProps;

	return (
		<div className="max-h-[70vh]">
			<p>{selectedCVs.length} étudiants sélectionnés</p>
			<div className="my-2 flex h-96 w-full flex-col gap-sm overflow-y-auto">
				<div className="my-2 flex h-96 w-full flex-col gap-sm overflow-y-scroll">
					{selectedCVs.map((student, index) => {
						return (
							<div
								className="flex items-center gap-sm"
								key={`Selection-Student-${index}`}
							>
								<CellAvatarLabelSecondary
									firstname={student?.firstname || ""}
									lastname={student?.lastname || ""}
									avatar={student.avatar?.filePath || ""}
									label={`${student?.lastname} ${student?.firstname}`}
									sublabel={student?.promotion?.name || ""}
									className="w-10/12"
								/>
								<ButtonTertiary
									icon={eyeOutline}
									onClick={() => {
										window.open(
											`${import.meta.env.VITE_S3}${student?.cv?.filePath}`,
										);
									}}
								/>
								{!noModifiable && (
									<ButtonTertiary
										icon={minusOutline}
										iconColor="text-accent-3"
										onClick={() => {
											const cvCopy: Partial<UserCompleteType>[] = [
												...selectedCVs,
											];
											cvCopy.splice(index, 1);
											setSelectedCVs(cvCopy);
										}}
									/>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
