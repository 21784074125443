import React from "react";

import { refreshFill } from "@assets/Icons";

import { Avatar } from "@designSystem/atoms/Avatar";
import { Badge } from "@designSystem/atoms/Badge";
import { InlineButton } from "@designSystem/atoms/InlineButton";

import { RelaunchStudentButton } from "../actions/RelaunchStudentButton";

export function SharedToModal() {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-sm">
				{renderStudentRow({
					invitationDate: "12/12/2020",
					seen: true,
					postulated: true,
					firstName: "John",
					lastName: "Doe",
					schoolName: "Lycée de la ville",
					schoolYear: "1ère",
				})}
				{renderStudentRow({
					invitationDate: "12/12/2020",
					seen: false,
					postulated: false,
					firstName: "John",
					lastName: "Doe",
					schoolName: "Lycée de la ville",
					schoolYear: "1ère",
				})}
				{renderStudentRow({
					invitationDate: "12/12/2020",
					seen: true,
					postulated: false,
					firstName: "John",
					lastName: "Doe",
					schoolName: "Lycée de la ville",
					schoolYear: "1ère",
				})}
			</div>
			<InlineButton
				id="relaunch-all"
				onClick={() => {}}
				icon={refreshFill}
				iconPosition="left"
				label="Tout relancer"
			/>
		</div>
	);
}

const renderStudentRow = (student: any) => {
	return (
		<div>
			<div className="flex flex-col gap-xxsm">
				<p className="text-xxsm font-bold text-primary-200">
					Le {student.invitationDate}
				</p>
				<div className="flex gap-2">
					{student.seen && <Badge label="A vu" />}
					{student.postulated && <Badge label="A postulé" />}
				</div>
				<div className="flex justify-between">
					<div className="flex gap-4">
						<Avatar lastname="" firstname="" image="" />
						<div className="flex flex-col">
							<p>
								{student.firstName} {student.lastName}
							</p>
							<p>
								{student.schoolName} - {student.schoolYear}
							</p>
						</div>
					</div>
					<RelaunchStudentButton />
				</div>
			</div>
		</div>
	);
};
