import { arrowIosForwardOutline, pinOutline } from "@assets/Icons";
import { Avatar } from "@designSystem/atoms/Avatar";
import type { BadgeType } from "@designSystem/atoms/Badge";
import { Badge } from "@designSystem/atoms/Badge";
import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import type { User } from "../SelectionAPI";
import { StudentsSelectionContext } from "../SelectionStudentDetails";
import { getBadgeByStatus } from "./StudentCard";

type SmallStudentCardProps = {
	student: User;
};

export const SmallStudentCard = ({ student }: SmallStudentCardProps) => {
	const { user } = useContext(GlobalContext);
	const userId = window.location.pathname.split("/")[5];
	const [badge, setBadge] = useState<BadgeType | null>(null);
	const { students, setStudents } = useContext(StudentsSelectionContext);
	useEffect(() => {
		setBadge(getBadgeByStatus(student.selectionStatus));
	}, [student.selectionStatus]);
	const navigate = useNavigate();
	const [active, setActive] = useState(false);
	useEffect(() => {
		setActive(student.id === Number.parseInt(userId));
	}, [student.id, userId]);
	return (
		<div
			className={`flex flex-1 gap-xxsm cursor-pointer box-content min-w-[300px] max-w-[300px] ${
				active
					? "border border-primary-500 rounded-md pl-4 py-2 pr-1 shadow-md"
					: "pl-4 py-2 pr-2 shadow-xsm"
			}`}
			onClick={() => {
				if (!user && !active) {
					const index = students.findIndex(
						(s) => s.id === Number.parseInt(userId),
					);
					if (index > -1 && students[index].selectionStatus === 0) {
						const newStudents = [...students];
						newStudents[index] = { ...students[index], selectionStatus: 1 };
						setStudents(newStudents);
					}
				}
				navigate(`../users/${student.id}`);
			}}
		>
			<div className="flex flex-1 flex-col gap-xxsm">
				<div className="flex flex-1 justify-between items-center">
					<div className="flex gap-xsm items-center">
						<Avatar
							firstname={student.firstname}
							lastname={student.lastname}
							size="xs"
							image={
								student.avatar
									? `${import.meta.env.VITE_S3}${student.avatar}`
									: null
							}
						/>
						<p className="text-xsm font-bold text-primary-700P block break-all">
							{student.firstname} {student.lastname}
						</p>
					</div>
				</div>
				{badge && (
					<Badge
						label={badge.label}
						bgColor={badge.bgColor}
						textColor={badge.textColor}
						borderColor={badge.borderColor}
						leftIcon={badge?.leftIcon}
						size="small"
					/>
				)}
				{student.postalCode && student.city && (
					<p className={"flex items-center text-primary-300 text-xxsm"}>
						<Icon icon={pinOutline} /> {student.postalCode} - {student.city}
					</p>
				)}
			</div>
			{active && (
				<div className="flex items-center">
					<Icon className="w-6 h-6" icon={arrowIosForwardOutline} />
				</div>
			)}
		</div>
	);
};
