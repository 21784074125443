import type { EventTagColorsType, EventType } from "@/types/eventsTypes";
import { isBefore } from "date-fns";
import React from "react";
import { useOutletContext } from "react-router-dom";

import {
	getDefaultEventIllustration,
	getTagColorFromEventType,
} from "@tools/Events";

import { Badge } from "@designSystem/atoms/Badge";
import { ButtonOpenAddress } from "@designSystem/atoms/ButtonOpenAddress";

import type { BackgroundColorType } from "@/types/colorsType";
import { RichTextPreview } from "@designSystem/atoms/RichTextPreview";
import EventDateInformations from "../card/EventDateInformations";
import { EventSubscriptionRow } from "../card/EventSubscriptionRow";

const BASE_MEDIA_URL = `${import.meta.env.VITE_S3}`;

export function KnowMoreModal({
	eventTarget,
	setIsKnowMoreModalOpen,
}: {
	eventTarget: EventType;
	setIsKnowMoreModalOpen?: Function;
}) {
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	return (
		<div className="flex h-full flex-col justify-between">
			<div className="px-0 md:px-6">
				<img
					src={
						eventTarget?.illustration
							? `${BASE_MEDIA_URL + eventTarget.illustration.filePath}`
							: getDefaultEventIllustration(eventTarget.defaultIllustration)
					}
					alt="Illustration en savoir plus"
					className="h-[137px] w-[100%] object-cover"
				/>
				<h3 className="wg-no-translate mt-8 !text-2xl !font-semibold text-primary-700P">
					{eventTarget.title}
				</h3>
				{renderInformations(eventTarget)}
				<RichTextPreview unsanitizedText={eventTarget.description} />
				{eventTarget?.address &&
				(eventTarget.location === "Sur site" ||
					eventTarget.location === "Hybride") ? (
					<div className="mb-4 flex w-full items-center justify-center gap-4">
						<span className="text-base font-normal text-primary-700P">
							Lieu:
						</span>
						<ButtonOpenAddress address={eventTarget?.address || ""} />
					</div>
				) : null}
			</div>
			{currentInterface !== "school" &&
			!isBefore(new Date(eventTarget.endDate), new Date()) ? (
				<EventSubscriptionRow
					eventTarget={eventTarget}
					setIsKnowMoreModalOpen={setIsKnowMoreModalOpen}
				/>
			) : null}
		</div>
	);
}

const renderInformations = (event: EventType) => {
	const tagColors: EventTagColorsType = getTagColorFromEventType(event.type);

	return (
		<div className="mt-2 flex flex-wrap justify-between">
			<div className="flex flex-wrap gap-4">
				<EventDateInformations event={event} />

				<Badge
					label={event.type}
					bgColor={tagColors.bgColor as BackgroundColorType}
				/>
				<div className="text-base font-bold text-primary-700P">
					{event.location}
				</div>
			</div>
		</div>
	);
};
