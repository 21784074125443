import React, { type MouseEventHandler } from "react";

import { editOutline, externalLinkFill } from "@assets/Icons";

import { Badge } from "@designSystem/atoms/Badge";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";

type CardModelPropsType = {
	title: string;
	image: string | null;
	edit: boolean;
	onEdit?: MouseEventHandler;
	onClick?: MouseEventHandler;
	color?: string;
	className?: string;
	translate?: boolean;
	smallCard?: boolean;
};

export function CardModel({
	title,
	image = "",
	onEdit = () => {},
	className = "",
	edit,
	onClick = () => {},
	color = "bg-emerald-light",
	translate = true,
}: CardModelPropsType) {
	const textColor =
		color === "bg-white"
			? "text-primary-700P"
			: `text-${color.split("-")[1]}-dark`;

	return (
		<div
			className={`w-full min-w-fit relative cursor-pointer shadow-sm rounded-lg overflow-hidden aspect-[8/10] group ${
				!image && `${color}`
			} ${className}`}
		>
			<div className="flex justify-between w-full absolute top-0 p-sm !pointer-events-auto items-center">
				<Badge label="Lien direct" leftIcon={externalLinkFill} />
				{edit && (
					<ButtonTertiary
						onClick={onEdit}
						className="small enabled ml-xxsm"
						icon={editOutline}
					/>
				)}
			</div>

			{image ? (
				<div>
					<div
						className="w-full object-cover overflow-hidden"
						onClick={onClick}
					>
						<img
							alt={title}
							src={image}
							className="w-full h-full object-cover"
						/>
					</div>
					<div className="absolute bottom-0 left-0 w-full h-[60px] rounded-md flex items-center justify-start transition-all px-2 py-4 bg-resource-linear gap-2 opacity-100">
						<p
							className={`text-center font-bold text-white text-xxsm text-shadow-md ${
								!translate ? " wg-no-translate" : ""
							}`}
						>
							{title}
						</p>
					</div>
				</div>
			) : (
				<div
					className="flex h-full w-full items-center justify-center"
					onClick={onClick}
				>
					<p
						className={` text-center text-md ${
							!translate ? " wg-no-translate" : ""
						} ${textColor}`}
					>
						{title}
					</p>
				</div>
			)}
		</div>
	);
}
