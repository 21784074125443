import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React from "react";

import { doneAllOutline } from "@assets/Icons";

import { diffDate } from "@tools/Dates";

import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";

export function RemoveProfilButton({ onClick }: { onClick: () => void }) {
	const { offerToEdit } = useAppSelector((state) => state.offers);

	return (
		<div className="flex flex-col gap-xxsm items-end">
			<p className="flex flex-row gap-xxsm items-center">
				<Icon icon={doneAllOutline} className="text-success-dark" />
				<span className="text-primary-300 text-xxsm font-normal">
					Transmis à l'entreprise il y a{" "}
					{diffDate(offerToEdit.selection.sendDate)}j
				</span>
			</p>
			<ButtonSecondary
				label="Retirer de l'envoi"
				className="max-w-fit"
				onClick={onClick}
			/>
		</div>
	);
}
