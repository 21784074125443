import React from "react";

import { refreshFill } from "@assets/Icons";

import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";

export function RelaunchStudentButton() {
	return (
		<div className="max-h-2">
			<ButtonTertiary icon={refreshFill} />
		</div>
	);
}
