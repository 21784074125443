import API, { graphqlRequestConstructor } from "@tools/API";
import { formatTime } from "@tools/Dates";
import { stringifyRequestFilters } from "@tools/Objects";

export async function retrieveCampuses(schoolId = "") {
	let rep = {};
	const data = {
		query: `query {
      campuses (itemsPerPage: 1000, school: "${schoolId}", order: [{name: "ASC"}]) {
          collection {
            id
            name
          }
      }
    }`,
	};

	await API.post("graphql", data).then(({ data }) => {
		rep = data.data.campuses.collection;
	});
	return rep;
}

export async function retrievePromotions() {
	const data = {
		query: `query {
      promotions (itemsPerPage: 1000, archived: false) {
        collection {
          id
          name
        }
      }
    }`,
	};

	const response = graphqlRequestConstructor(
		data,
		"getPromotions",
		"Erreur lors de la récupération des promotions. Veuillez réessayer.",
	);
	return response;
}

export async function updateLogsConsultationLastDate(userId = "") {
	let rep = {};
	const data = {
		query: `mutation { 
      updateSpsReferent (input: {id: "${userId}", logsConsultationLastDate: "${formatTime(
				new Date(),
			)}"}) {
        spsReferent {
          logsConsultationLastDate
        }
      }
    }`,
	};

	await API.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getLogs(filters: any) {
	const queryToSend = {
		query: `query {
        collectionQueryLogs (${stringifyRequestFilters({
					...filters,
				})}, itemsPerPage: 30, order: [{id: "DESC"}]) {
          collection {
            spsStudent {
              id
              avatar {
                filePath
              }
              firstname
              lastname
            }
            spsReferent {
              id
              avatar {
                filePath
              }
              firstname
              lastname
            }
            application {
              id
              job
              organization : organizationV2
            }
            action
            description
            createdAt
          },
          paginationInfo {
            lastPage
          }
        }
      }`,
	};
	const response = graphqlRequestConstructor(
		queryToSend,
		"getLogs",
		"Erreur lors de la récupération des logs. Veuillez réessayer.",
	);
	return response;
}

export async function getStudentDetail(id: string) {
	let rep = {};
	const data = {
		query: `query {
      spsStudent (id: "${id}") {
        id
        avatar {
          filePath
        }
        conversation: spsConversation {
          id
          status
          updatedAt
        }
        firstname
        lastname
        promotion: spsPromotion  {
          name
        }
        portfolio
        linkedin
        status
        desiredJob
        customLink1
        customLink2
        email
        phone
        address
        tags
        cv {
          filePath
        }
        motivationLetter {
          filePath
        }
      }
    }`,
	};

	await API.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}
