import type { OrganizationType } from "@/types/organizationTypes";
import { Formik } from "formik";
import React, { useContext, useState } from "react";

import { briefcaseOutline, pinOutline } from "@assets/Icons";
import CITIES from "@assets/json/cities.json";

import { ORGANIZATIONS_BUSINESS_LINE_OPTIONS } from "@constants/Organizations";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Combobox } from "@designSystem/molecules/Combobox";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { success } from "@tools/Toasts";

import { SpontaneousApplicationContext } from "../..";
import { autoComplet, editOrganization } from "../../SpontaneousApplicationAPI";

type UpdateOrganizationModalType = {
	show: boolean;
	onClose: Function;
	company: OrganizationType;
};
type CompanyType = { label: string; logo: string; domain: string };
export function UpdateOrganizationModal({
	show,
	onClose,
	company,
}: UpdateOrganizationModalType) {
	const { filters, setFilters } = useContext(SpontaneousApplicationContext);

	const [companyList, setCompanyList] = useState<CompanyType[]>([]);

	const [locationSelectOptions, setLocationSelectOptions] = useState(
		CITIES.slice(0, 4),
	);
	const [businessLinesList, setBusinessLinesList] = useState(
		ORGANIZATIONS_BUSINESS_LINE_OPTIONS,
	);
	const handleBusinessLine = (businessLine: string) => {
		setBusinessLinesList(
			ORGANIZATIONS_BUSINESS_LINE_OPTIONS.filter((option) => {
				return option.label.toLowerCase().includes(businessLine.toLowerCase());
			}),
		);
	};

	const handleLocation = (location: string) => {
		setLocationSelectOptions(
			CITIES.filter((city) => {
				return city.label.toLowerCase().includes(location.toLowerCase());
			}).slice(0, 4),
		);
	};

	const autocompletAPI = async (company: string) => {
		const companyList: { label: string; logo: string; domain: string }[] = [];
		if (!company) {
			setCompanyList(companyList);
			return;
		}
		const data: any = await autoComplet(company);

		if (data) {
			data.map((company: OrganizationType) => {
				if (company) {
					return companyList.push({
						label: company.name,
						logo: company.logo,
						domain: company.domain,
					});
				}

				return "";
			});
			setCompanyList(companyList);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				logo: company.logo || "",
				name: company.name || "",
				locations: company.locations || [],
				businessSector: company.businessSector || "",
			}}
			validate={(values) => {
				const errors: { name?: string } = {};
				if (values.name === "") {
					errors.name = "Veuillez renseigner un nom";
				}
				return errors;
			}}
			onSubmit={(values) => {
				editOrganization({
					id: company.id,
					...values,
				}).then(() => {
					success("L'entreprise a bien été modifiée !");

					setFilters({ ...filters });
					onClose();
				});
			}}
		>
			{({ values, errors, touched, handleSubmit, setFieldValue }) => (
				<Modal
					title="Modifer une entreprise"
					onClose={onClose}
					show={show}
					body={
						<div>
							<div className="flex flex-col items-center">
								<Avatar
									image={values.logo}
									firstname={values.name.split(" ")[0]}
									lastname={values.name.split(" ")[1]}
									onChange={(e: { url: string }) => {
										setFieldValue("logo", e.url);
									}}
									size="lg"
								/>
								<Combobox
									value={values.name}
									onChange={(e) => {
										setFieldValue("name", e.target.value);
										autocompletAPI(e.target.value);
									}}
									options={companyList}
									className="mt-md"
									placeholder="Nom de l'entreprise"
									onClickOnItem={(elem) => {
										setFieldValue("logo", elem.logo);
									}}
									errorMessage={errors.name && touched.name && errors.name}
								/>
							</div>
							<div className="flex flex-col gap-sm">
								<Combobox
									placeholder="Localisation"
									label="Localisation"
									options={locationSelectOptions}
									icon={pinOutline}
									value={values.locations[0]}
									hideAvatar
									onChange={(e) => {
										handleLocation(e.target.value);
									}}
									onClickOnItem={(e) => {
										setFieldValue("locations", [e.label]);
									}}
								/>
								<Combobox
									className="[&_.options-list]:-translate-y-[130%] [&_.options-list]:shadow-2xl"
									placeholder="Secteurs d'activité"
									label="Secteurs d'activité (optionnel)"
									options={businessLinesList}
									icon={briefcaseOutline}
									value={values.businessSector}
									hideAvatar
									onChange={(e) => handleBusinessLine(e.target.value)}
									onClickOnItem={(e) => {
										setFieldValue("businessSector", e.label);
									}}
								/>
							</div>
						</div>
					}
					buttonsRight={[
						<ButtonSecondary
							label="Fermer"
							onClick={() => {
								onClose();
							}}
						/>,
						<ButtonPrimary
							label="Modifier"
							onClick={() => {
								handleSubmit();
							}}
						/>,
					]}
				/>
			)}
		</Formik>
	);
}
