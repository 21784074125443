import useDebounce from "@hooks/useDebounce";
import React, { useContext, useEffect, useState } from "react";

import { pinOutline } from "@assets/Icons";

import {
	ORGANIZATIONS_BUSINESS_LINE_OPTIONS,
	ORGANIZATIONS_SIZE_OPTIONS,
} from "@constants/Organizations";

import { findCities } from "@tools/API";

import { Combobox } from "@designSystem/molecules/Combobox";

import { Select } from "@designSystem/organisms/select/Select";
import { SpontaneousApplicationContext } from "../..";

type LocationItem = {
	id: number;
	label: string;
};
type OptionItem = {
	label: string;
	value?: string;
	checked?: boolean;
	type?: string;
};

export function OrganizationsFilters() {
	const { setFilters, filters, loading } = useContext(
		SpontaneousApplicationContext,
	);
	const [locations, setLocations] = useState<LocationItem[]>([]);
	const [cityToSearch, setCityToSearch] = useState<string>("");
	const [searchLocation, setSearchLocation] = useState<string>("");
	const debouncedValue = useDebounce(searchLocation, 500);
	const debounceCity = useDebounce(cityToSearch, 500);

	const handleChangeLocation = async (value: string) => {
		setCityToSearch(value);
	};

	/**
	 * Explanation: Receives as parameter the type of the multiselect, and an array containing the possible options.
	 *
	 * Scans each of the options, and sets the "checked" field of these options to true if their value is currently contained in the filters.
	 *
	 * Returns an array containing the multiselect options, formatted.
	 */
	const getMultiselectOptions = (type: string, options: OptionItem[]) => {
		const formattedOptions: Array<OptionItem> = [];

		options.forEach((option: OptionItem) => {
			formattedOptions.push({
				...option,
				checked: filters[type]?.includes(option.value),
				type: "checkbox",
			});
		});

		return formattedOptions;
	};

	const fetchCities = async () => {
		const data = await findCities(cityToSearch);
		if (data) {
			const array = data?.map(
				(city: { properties: { label: string } }, index: number) => {
					return { id: index, label: `${city?.properties?.label}` };
				},
			);

			setLocations([...array]);
		}
	};

	useEffect(() => {
		if (!loading) {
			setFilters({ ...filters, locations: debouncedValue, page: 1 });
		}
	}, [debouncedValue]);

	useEffect(() => {
		fetchCities();
	}, [debounceCity]);

	return (
		<section className="col-span-4 flex w-full flex-col items-center justify-between gap-sm rounded-lg bg-white p-sm shadow-md md:col-span-8 md:flex-row lg:col-span-12">
			<Combobox
				hideAvatar
				className="w-full md:w-6/12 lg:w-6/12"
				icon={pinOutline}
				onChange={(e) => {
					if (e.target.value.length > 2) {
						handleChangeLocation(e.target.value);
					} else {
						setLocations([]);
						setSearchLocation("");
					}
				}}
				value={filters.locations}
				options={locations}
				placeholder="Localisation"
				onClickOnItem={(elem: LocationItem) => {
					setSearchLocation(elem.label);
				}}
			/>

			<Select
				position="right"
				bgColor="bg-primary-50"
				className="w-full md:w-6/12 lg:w-3/12"
				placeholder="Secteur d'activité"
				inputs={getMultiselectOptions(
					"businessSector_list",
					ORGANIZATIONS_BUSINESS_LINE_OPTIONS,
				)}
				multiselect
				onChange={(optionsSelected: any) => {
					setFilters({
						...filters,
						page: 1,
						businessSector_list: optionsSelected.map(
							(option: OptionItem) => option.value,
						),
					});
				}}
			/>

			<Select
				position="right"
				bgColor="bg-primary-50"
				className="w-full md:w-6/12 lg:w-3/12"
				placeholder="Taille d'entreprise"
				inputs={getMultiselectOptions("size_list", ORGANIZATIONS_SIZE_OPTIONS)}
				multiselect
				onChange={(optionsSelected: any) => {
					setFilters({
						...filters,
						page: 1,
						size_list: optionsSelected?.map(
							(option: OptionItem) => option.value,
						),
					});
				}}
			/>
		</section>
	);
}
