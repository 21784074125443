import React from "react";
import { NavLink } from "react-router-dom";

import Follow from "@assets/illustrations/Follow.svg";
import Offer from "@assets/illustrations/Offer.svg";
import HeroJobboard from "@assets/illustrations/heroJobboard.svg";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";

export function Discover() {
	const getBlockHead = (title, index) => {
		return (
			<div className="flex items-center gap-sm">
				<p className="flex aspect-square items-center justify-center rounded-lg bg-primary-700P px-sm py-xxsm text-sm text-white">
					{index}
				</p>
				<p className="text-sm font-bold">{title}</p>
			</div>
		);
	};

	return (
		<main className="flex w-full flex-col items-center justify-center pb-xxlg text-primary-700P">
			<div className="w-full text-sm  md:w-8/12 xl:w-6/12">
				<p className="text-lg font-bold ">Comment ça marche ?</p>
				<p className="mt-md text-primary-700P">
					Voici un tutoriel pour commencer à postuler et suivre tes candidatures
					:
				</p>
				<div className="mt-xlg w-full rounded-lg bg-white px-lg py-lg md:px-xlg">
					<div id="step-1" className="mb-lg">
						{getBlockHead(
							"Trouve une offre qui te correspond sur la Jobthèque",
							1,
						)}
						<p className="mb-xlg mt-sm">
							Parcours la Jobthèque où tu trouveras des milliers d&apos;offres
							d&apos;emploi, ainsi que les offres des entreprises partenaires de
							ton école.
						</p>
						<div
							id="hero"
							className="relative flex h-36 w-full items-center justify-center overflow-hidden rounded-lg object-cover"
						>
							<img
								src={HeroJobboard}
								alt="Jobboard illustration"
								className="h-full w-full object-cover"
							/>
							<p className="absolute text-center text-lg text-white">
								Trouve l'offre qui te correspond
							</p>
						</div>
					</div>
					<div id="step-2" className="mb-lg">
						{getBlockHead("Postule", 2)}

						<p className="mb-xlg mt-sm">
							Une fois trouvée, clique sur l&apos;offre qui te redirigera vers
							le site pour postuler.
							<br />
							Si c&apos;est une offre exclusive de ton école, postule
							directement sans sortir de la plateforme !
						</p>
						<img src={Offer} alt="Illustration offre" className="w-full" />
					</div>{" "}
					<div id="step-3">
						{getBlockHead("Ajoute tes candidatures à ton tableau de suivi", 3)}

						<p className="mb-xlg mt-sm">
							Après avoir postulé, ajoute ta candidature sur ton tableau de
							suivi sous “Mes candidatures”. Tu pourras ainsi gérer son
							évolution, ajouter des actions, des rappels, ... <br /> Si c’est
							une offre exclusive de ton école, ta candidature sera
							automatiquement ajoutée à ton tableau de suivi !
						</p>
						<img src={Follow} alt="Illustration suivi" className="w-full" />
					</div>
				</div>
				<div className="mt-xlg flex flex-col items-center">
					<p className="mb-xsm text-xsm font-bold text-primary-700P">
						Te voilà prêt(e) pour utiliser Mentor Goal !
					</p>
					<p className="text-center text-xsm text-primary-700P">
						Pour que tu aies la meilleure expérience possible, commence par
						renseigner ton <span className="font-bold">profil</span> ;)
					</p>

					<NavLink className="text-decoration-none mt-xxlg" to="/">
						<ButtonPrimary label="Commencer" className="w-fit-content start " />
					</NavLink>
				</div>
			</div>
		</main>
	);
}
