import React from "react";

import { ITEM_PER_PAGE_OPTIONS } from "@constants/Table";

import { SelectSecondary } from "../selectSecondary/SelectSecondary";

const getOptions = (options?: number[]) => {
	return (
		options ||
		ITEM_PER_PAGE_OPTIONS?.map((option) => {
			return { value: option, label: option };
		})
	);
};

const getDefaultValue = (defaultValue?: number) => {
	return {
		value: defaultValue || ITEM_PER_PAGE_OPTIONS[0],
		label: defaultValue || ITEM_PER_PAGE_OPTIONS[0],
	};
};

type SelectItemPerPageProps = {
	defaultValue?: number;
	options?: number[];
	onChange?: (value: number) => void;
};
export function SelectItemPerPage({
	defaultValue,
	options,
	onChange,
}: SelectItemPerPageProps) {
	const handleOnChange = (value: number) => {
		onChange?.(value);
	};
	return (
		<div
			className="flex items-center justify-start gap-2"
			data-testid="selectItemPerPage"
		>
			<div className="whitespace-nowrap text-xxsm text-primary-300">
				Éléments par page
			</div>
			<SelectSecondary
				options={getOptions(options || undefined)}
				onChange={(e) => handleOnChange(Number.parseInt(e.value))}
				defaultValue={getDefaultValue(defaultValue || undefined)}
				label=""
				position="right"
				className="!min-w-[76px] !max-w-fit [&>*]:min-w-[76px] [&>*]:max-w-fit"
			/>
		</div>
	);
}
