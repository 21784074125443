import type { SelectionType } from "@/types/profileSharingTypes";
import { eyeOutline } from "@assets/Icons";
import { useAppSelector } from "@config/redux/hook";
import { ShareMailPreview } from "@containers/school/CVBoard/ui/CVsList/TableView/ShareCVModal/ShareMailPreview";
import { EMAIL_MAX_LENGTH } from "@containers/school/ProfileSharing/ui/ShareSelectionEmailModal";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { Input } from "@designSystem/molecules/Input";
import React from "react";

type ShareEmailProps = {
	selection: Partial<SelectionType> | undefined;
	recipientProps: { recipient: string; setRecipient: Function };
	ccProps: { cc: string; setCc: Function };
	subjectInputValueProps: {
		subjectInputValue: string;
		setSubjectInputValue: Function;
	};
	bodyInputValueProps: { bodyInputValue: string; setBodyInputValue: Function };
};

const RECIPIENT_PLACEHOLDER = "email-recruteur@entreprise.com";
const CC_PLACEHOLDER = "email1@entreprise.com, email2@entreprise.com";

export const ShareEmail = ({
	selection,
	recipientProps,
	ccProps,
	subjectInputValueProps,
	bodyInputValueProps,
}: ShareEmailProps) => {
	const { school } = useAppSelector((state) => state.company);
	const { recipient, setRecipient } = recipientProps;
	const { cc, setCc } = ccProps;
	const { subjectInputValue, setSubjectInputValue } = subjectInputValueProps;
	const { bodyInputValue, setBodyInputValue } = bodyInputValueProps;
	const uri = `${import.meta.env.VITE_URL}/selection/${school?.subdomain}/${selection?.token}`;
	return (
		<div className="flex gap-md">
			<div className="flex flex-1 flex-col gap-sm">
				<p className="text-primary-200 text-xxsm uppercase">Votre message</p>
				<Input
					label="Saisissez le destinataire*"
					placeholder={RECIPIENT_PLACEHOLDER}
					type={"email"}
					value={recipient}
					onChange={(e) => setRecipient(e.target.value)}
				/>
				<Input
					label="Ajoutez des personnes en copie"
					placeholder={CC_PLACEHOLDER}
					name={"cc"}
					value={cc}
					onChange={(e) => setCc(e.target.value)}
					infoText={
						"Entrez les adresses mail des personnes à mettre en copie, en les séparant par une virgule."
					}
				/>
				<Input
					label="Objet*"
					value={subjectInputValue}
					onChange={(e) => {
						setSubjectInputValue(e.target.value);
					}}
				/>
				<div className="flex flex-col">
					<label
						htmlFor="inputLargePrimary"
						className="text-xxsm text-primary-500"
					>
						Votre message
					</label>
					<RichTextEditor
						value={bodyInputValue}
						placeholder={"Contenu de votre mail"}
						onChange={(value) => setBodyInputValue(value)}
						maxLength={EMAIL_MAX_LENGTH}
					/>
				</div>
				<InlineButton
					id="previewOffer"
					label="Prévisualiser le partage en tant qu’entreprise"
					icon={eyeOutline}
					onClick={() => {
						window.open(uri, "_blank");
					}}
				/>
			</div>
			<div className="flex flex-1">
				<ShareMailPreview
					message={bodyInputValue}
					subject={subjectInputValue}
					to={recipient || RECIPIENT_PLACEHOLDER}
					cc={cc}
				/>
			</div>
		</div>
	);
};
