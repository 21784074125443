import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect, useState } from "react";

import { Modal } from "@designSystem/organisms/modal/Modal";

import type { EntEmployeeStatusType } from "@/types/EntCompanyTypes";
import { GlobalContext } from "@navigation/Router";

export function EmployeeAccessModal({
	status,
}: { status: EntEmployeeStatusType }) {
	const { showEmployeeAccessModal, school } = useAppSelector(
		(state) => state.company,
	);

	const { user } = useContext(GlobalContext);

	const [message, setMessage] = useState<string>("");
	const [title, setTitle] = useState<string>("");

	const closeModal = () => {
		location.replace(
			`${import.meta.env.VITE_URL}/company/company-create/sent?${
				school.subdomain
			}`,
		);
	};
	useEffect(() => {
		switch (status) {
			case "refused":
				setMessage(
					`Votre demande d'adhésion à ${user?.entCompany?.name} a été refusée.`,
				);
				setTitle("Accès refusé");
				break;
			default:
				setMessage(
					`Votre demande d'adhésion à ${user?.entCompany?.name} est en cours de traitement.`,
				);
				setTitle("Demande d'accès en attente");
				break;
		}
	}, [status]);

	return (
		<Modal
			body={
				<div>
					<p>{message}</p>
				</div>
			}
			title={title}
			show={showEmployeeAccessModal}
			onClose={() => {
				closeModal();
			}}
		/>
	);
}
