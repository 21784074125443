import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import "./SchoolHelp.scss";

import { arrowForwardFill } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";

type ContextType = {
	setTitle: (param: string) => string;
};
export function News() {
	const { setTitle }: ContextType = useOutletContext();

	useEffect(() => {
		setTitle("Nous contacter");
	}, []);

	return (
		<main className="help-container">
			<iframe
				className="embed-notion -mt-5 max-h-[81vh]"
				title="notion"
				src="https://v2-embednotion.com/D-couvrir-les-nouveaut-s-Mentor-Goal-06f008d645d04bdcb83a1a23ec4594a1"
			/>
			<InlineButton
				id="more"
				className="more flex items-center"
				icon={arrowForwardFill}
				label="Cliquez ici si cette page ne s'affiche pas"
				onClick={() => {
					window.open(
						"https://mentorgoal.notion.site/Centre-d-aide-558d5534a1054bf5b685b923103f39fe",
						"_blank",
					);
				}}
			/>
		</main>
	);
}
