import React from "react";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";

import { TableView } from "./TagsList/TableView";

export function TagsList({
	load,
	tags,
	lastPage,
	tabProps,
	filtersProps,
	getData,
}) {
	const { tab, setTab } = tabProps;
	const { filters, setFilters } = filtersProps;

	return (
		<section className="col-span-4 md:col-span-8 lg:col-span-12">
			<>
				<ScrollableTabGroup
					tabs={[
						{
							label: "Tags généraux",
							active: tab === "generalTags",
							state: "school",
						},
						{
							label: "Tags de compétences",
							active: tab === "skillsTags",
							state: "skills",
						},
					]}
					onTabChange={(i) => {
						switch (i) {
							case 1:
								setFilters({
									...filters,
									type_list: ["hardSkill", "softSkill"],
									page: 1,
								});
								setTab("skillsTags");
								break;
							default:
								setFilters({
									...filters,
									type_list: ["school"],
									page: 1,
								});
								setTab("generalTags");
								break;
						}
					}}
					className="mt-lg"
				/>
				<TableView
					load={load}
					tags={tags}
					tab={tab}
					lastPage={lastPage}
					filtersProps={{ filters, setFilters }}
					getData={getData}
				/>
			</>
		</section>
	);
}
