import { GlobalContext } from "@navigation/Router";
import React from "react";
import { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { diffDate } from "@tools/Dates";

import { Spinner } from "@designSystem/atoms/Spinner";

import { queryNotifications } from "@containers/student/StudentLayout/SlidingPaneContent/SlidingPaneContentAPI";

import type { NotificationType } from "@/types/notificationTypes";
import { NotificationCard } from "./Notifications/NotificationCard";

export function Notifications() {
	const { user } = useContext(GlobalContext);
	const [thisWeekNotifications, setThisWeekNotifications] = useState<
		NotificationType[]
	>([]);
	const [lastWeekNotifications, setLastWeekNotifications] = useState<
		NotificationType[]
	>([]);
	const [olderNotifications, setOlderNotifications] = useState<
		NotificationType[]
	>([]);
	const [lastPage, setLastPage] = useState(2);
	const [nextPage, setNextPage] = useState(1);
	const [notificationsCount, setNotificationsCount] = useState(1);

	const notificationsSplitter = (notifications: NotificationType[]) => {
		setThisWeekNotifications([
			...thisWeekNotifications,
			...notifications.filter((notification: NotificationType) => {
				return diffDate(notification.createdAt as Date) < 7;
			}),
		]);

		setLastWeekNotifications([
			...lastWeekNotifications,
			...notifications.filter((notification: NotificationType) => {
				return (
					diffDate(notification.createdAt as Date) >= 7 &&
					diffDate(notification.createdAt as Date) <= 14
				);
			}),
		]);

		setOlderNotifications([
			...olderNotifications,
			...notifications.filter((notification: NotificationType) => {
				return diffDate(notification.createdAt as Date) > 14;
			}),
		]);
	};

	const fetchNotifications = (page = 1) => {
		queryNotifications(user?.id as string, page).then(
			({ collection, paginationInfo }) => {
				notificationsSplitter(collection);
				setNotificationsCount(collection.length);
				setNextPage(page + 1);
				setLastPage(paginationInfo.lastPage);
			},
		);
	};

	return (
		<div className="flex h-full flex-col">
			<div className="flex flex-col overflow-y-scroll ">
				<InfiniteScroll
					pageStart={1}
					loadMore={() => {
						fetchNotifications(nextPage);
					}}
					useWindow={false}
					hasMore={nextPage < lastPage}
					loader={
						<div className="mt-sm text-center" key={0}>
							<Spinner />
						</div>
					}
				>
					{notificationsCount === 0 && (
						<p className="text-center text-primary-700P">
							Vous n'avez aucune notification
						</p>
					)}
					{thisWeekNotifications.length > 0 && (
						<div>
							<p className="w-fit rounded-full bg-secondary-100 px-md py-xsm text-sm text-primary-700P">
								Cette semaine
							</p>
							<div className="mt-md flex flex-col gap-md">
								{thisWeekNotifications.map((notification) => {
									return <NotificationCard notification={notification} />;
								})}
							</div>
						</div>
					)}
					{lastWeekNotifications.length > 0 && (
						<div className="my-md">
							<p className="w-fit rounded-full bg-accent-2-light px-md py-xsm text-sm text-primary-700P">
								La semaine dernière
							</p>
							<div className="mt-md flex flex-col gap-md">
								{lastWeekNotifications.map((notification) => {
									return <NotificationCard notification={notification} />;
								})}
							</div>
						</div>
					)}
					{olderNotifications.length > 0 && (
						<div className="my-md">
							<p className="w-fit rounded-full bg-accent-3-light px-md py-xsm text-sm text-primary-700P">
								Plus anciens
							</p>
							<div className="mt-md flex flex-col gap-md">
								{olderNotifications.map((notification) => {
									return <NotificationCard notification={notification} />;
								})}
							</div>
						</div>
					)}
				</InfiniteScroll>
			</div>
		</div>
	);
}
