import React, { useEffect, useState } from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { TextArea } from "@designSystem/atoms/TextArea";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";

type RefuseApplicationModalProps = {
	show: boolean;
	from: "selection" | "application";
	onClose: () => void;
	edit: () => void;
	state: string;
	treatmentProps: {
		treatmentReason: string;
		setTreatmentReason: (treatmentReason: string) => void;
	};
	messagesProps: {
		messages: string[];
		setMessages: (messages: string[]) => void;
	};
};
export function RefuseApplicationModal({
	show,
	from,
	onClose,
	edit,
	state,
	treatmentProps,
	messagesProps,
}: RefuseApplicationModalProps) {
	const [choice, setChoice] = useState("notAccepted");
	const { setTreatmentReason } = treatmentProps;
	const { messages, setMessages } = messagesProps;
	const refusedOptions = [
		{ label: "Non retenue", value: "notAccepted" },
		{ label: "Trop de candidats", value: "tooManyApplicants" },
		{ label: "Offre non adaptée", value: "unsuitableOffer" },
		{ label: "Étape recrutement", value: "recruitmentStep" },
		{ label: "Offre pourvue", value: "filledOffer" },
	];

	const textValue = (motif: string) => {
		switch (motif) {
			case "tooManyApplicants":
				return "Non sélectionné en raison d'un grand nombre de candidats.\nNous avons reçu un grand nombre de candidatures pour un nombre de places en entretien limité. Malgré la qualité de votre candidature, elle n'a pas été sélectionnée.";
			case "unsuitableOffer":
				return "L'offre de l'entreprise ne correspond pas à votre profil, votre candidature n'a donc pas été sélectionnée.";
			case "recruitmentStep":
				return "L'entreprise a décidé d'avancer avec d'autres candidats.\n\nMalheureusement, elle est désormais à l'étape suivante de son processus de recrutement et votre candidature n'a pas été retenue.";
			case "filledOffer":
				return "Malheureusement, l'offre est déjà pourvue.";
			default:
				return "Après l'étude de votre candidature et malgré la qualité de celle-ci, elle n'a pas été transmise à l'entreprise partenaire.";
		}
	};

	useEffect(() => {
		if (show) {
			setTreatmentReason("notAccepted");
			setMessages(state !== "refused" ? [textValue("")] : messages);
		}
	}, [show]);

	return (
		<Modal
			show={show}
			title={
				from === "application"
					? "Confirmation de refus"
					: "Retirer ce candidat des profils envoyés"
			}
			onClose={() => {
				onClose();
			}}
			body={
				<div>
					{from === "application" ? (
						<p>
							Vous avez indiqué que le{" "}
							<span>
								profil de cet étudiant n&apos;est pas interéssant pour
								l&apos;offre concernée.
							</span>
						</p>
					) : (
						<p>
							Si vous retirez ce profil des profils transmis à l’entreprise,
							vous pourrez le retrouver dans les candidatures à traiter.
						</p>
					)}
					<p>Merci de lui indiquer la raison du refus :</p>
					<SelectTertiary
						options={refusedOptions}
						defaultValue={refusedOptions.find(
							(option) => option.value === choice,
						)}
						className="mt-md w-5/12 p-0"
						label="Motif"
						onChange={(e) => {
							setChoice(e.value);
							setTreatmentReason(e.value);
							setMessages([textValue(e.value)]);
						}}
						position="right"
					/>
					<TextArea
						height="208px"
						className="mt-sm"
						value={messages?.[0]}
						onBlur={(e) => {
							setMessages([e.target.value]);
						}}
						label="Message à envoyer à l'étudiant"
					/>
				</div>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					onClick={(e: any) => {
						e.stopPropagation();
						onClose();
					}}
					label="Annuler"
				/>,
				<ButtonPrimary
					key={1}
					onClick={(e: any) => {
						e.stopPropagation();
						onClose();
						edit();
					}}
					label={from === "application" ? "Envoyer" : "Retirer le profil"}
				/>,
			]}
		/>
	);
}
