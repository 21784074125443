import { AddSiretToCompany } from "@containers/school/Companies/ui/actions/AddSiretToCompany";
import { ChooseCompanyName } from "@containers/school/Companies/ui/actions/ChooseCompanyName";
import { FindCompanyAddress } from "@containers/school/Companies/ui/actions/FindCompanyAddress";
import { ReferentSelection } from "@containers/school/Companies/ui/actions/ReferentSelection";
import { SelectRelationToCompany } from "@containers/school/Companies/ui/actions/SelectRelationToCompany";
import { CampusMultiselect } from "@modules/campusMultiselect/ui/CampusMultiselect";
import React from "react";
import { useCompanyCreation } from "./useCompanyCreation";

export function CompanyCreationModal() {
	const presenter = useCompanyCreation();
	return (
		<section className="flex flex-col gap-md">
			<ChooseCompanyName />
			<SelectRelationToCompany />
			<ReferentSelection />
			<CampusMultiselect
				defaultCoreCampusesSelected={presenter.coreCampusesSelected}
				handleSelectCampus={presenter.handleCampusesSelection}
				useFullWidth
				useUserCampus
				isRequired
			/>
			<FindCompanyAddress />
			<AddSiretToCompany siretValidator={presenter.checkCompanySiret} />
		</section>
	);
}
