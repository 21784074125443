import type { EventTagColorsType, EventType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import DOMPurify from "dompurify";
import React, { type Dispatch, useState } from "react";

import { NavLink, useOutletContext } from "react-router-dom";

import { arrowForwardFill, editOutline } from "@assets/Icons";

import { extractTimeFromISOString } from "@tools/Dates";
import {
	checkEventStartedStatus,
	getDefaultEventIllustration,
	getTagColorFromEventType,
	isMentorGoalEvent,
} from "@tools/Events";

import { Badge } from "@designSystem/atoms/Badge";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@designSystem/atoms/ButtonQuaternary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { KnowMoreModal } from "../modal/KnowMoreModal";

import type { BackgroundColorType } from "@/types/colorsType";
import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "../../core/store/events.slice";
import EventDateInformations from "./EventDateInformations";
import { OptionFinished } from "./options/OptionFinished";
import { OptionInscription } from "./options/OptionInscription";
import { OptionPlanned } from "./options/OptionPlanned";
import { OptionReplay } from "./options/OptionReplay";
import { useEventReplies } from "./useEventReplies";
import { EventCounters } from "./widgets/EventCounters";
const BASE_MEDIA_URL = `${import.meta.env.VITE_S3}`;

type EventCardProps = {
	event: EventType;
	parentListID: string;
};

export default function EventCard({ event, parentListID }: EventCardProps) {
	const presenter = useEventReplies();
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const [isKnowMoreModalOpen, setIsKnowMoreModalOpen] =
		useState<boolean>(false);
	return (
		<article
			className="
      md:max-w-5/12 sm:max-w-1/3 flex w-full max-w-full gap-1 rounded-lg border-[1px] border-accent-3-light
      bg-white p-6 pb-2 sm:min-h-[780px] md:max-w-[48%] xl:max-w-[32.3%] xxl:max-w-[24%]
    "
		>
			<div className="flex w-full flex-col gap-4">
				{renderHeader(event, currentInterface, parentListID)}
				{renderBody(
					event,
					setIsKnowMoreModalOpen,
					presenter,
					parentListID,
					currentInterface,
				)}
			</div>
			<Modal
				title="En savoir plus"
				id="knowMoreModal"
				buttonsRight={[
					<ButtonPrimary
						label="Fermer"
						onClick={() => setIsKnowMoreModalOpen(false)}
					/>,
				]}
				show={isKnowMoreModalOpen}
				onClose={() => setIsKnowMoreModalOpen(false)}
				body={
					<KnowMoreModal
						eventTarget={event}
						setIsKnowMoreModalOpen={setIsKnowMoreModalOpen}
					/>
				}
			/>
		</article>
	);
}

const renderHeader = (
	event: EventType,
	currentInterface: string,
	parentListID: string,
) => {
	const tagColors: EventTagColorsType = getTagColorFromEventType(event.type);
	return (
		<div className="flex justify-between">
			<div className="flex flex-[1_1_100%]">
				<div className="flex flex-col items-start justify-start gap-4">
					<EventDateInformations event={event} />
					<div className="flex flex-wrap items-center justify-start gap-2">
						<Badge
							label={event.type}
							bgColor={tagColors.bgColor as BackgroundColorType}
						/>
						<div className="text-base font-bold text-primary-700P">
							{parentListID === "planned" && event.location}
						</div>
						{parentListID === "planned" ? (
							<div className="flex gap-4">
								{checkEventStartedStatus(event) ? renderStartedTag() : null}
							</div>
						) : null}
					</div>
				</div>
			</div>
			{currentInterface === "school" && renderEditButton(event)}
		</div>
	);
};

const renderBody = (
	event: EventType,
	setIsKnowMoreModalOpen: Dispatch<boolean>,
	presenter: ReturnType<typeof useEventReplies>,
	parentListName: string,
	currentInterface: string,
) => {
	const getLabel = (language: string) => {
		if (language === "fr") {
			return "Ajouter au calendrier";
		}
		if (language === "en") {
			return "Add to calendar";
		}
		if (language === "es") {
			return "Añadir al calendario";
		}
		if (language === "de") {
			return "Zum kalender hinzufügen";
		}
		return "Add to calendar";
	};
	return (
		<div className="flex h-full flex-col gap-4">
			{renderEventIllustration(event)}
			<div className="flex h-full w-full flex-col justify-between">
				<span className="wg-no-translate text-md font-semibold not-italic text-primary-700P">
					{event.title}
				</span>
				<div className="wg-no-translate my-2 line-clamp-3 h-20 max-h-36 overflow-ellipsis text-base font-normal text-primary-700P">
					{DOMPurify.sanitize(event.description).replace(/(<([^>]+)>)/gi, "")}
				</div>
				<InlineButton
					id="knowMoreButton"
					onClick={() => {
						setIsKnowMoreModalOpen(true);
					}}
					label="En savoir plus"
					icon={arrowForwardFill}
					iconPosition="right"
				/>
				<div className="flex h-[60%] flex-col justify-end">
					{renderOptions(event, parentListName, currentInterface)}
					<div
						className={`mt-2 flex flex-col items-start ${parentListName === "planned" ? "justify-between" : "justify-end"} gap-1 sm:flex-row sm:items-center sm:gap-0`}
					>
						{parentListName === "planned" && (
							<AddToCalendarButton
								label={getLabel(Weglot.getCurrentLang())}
								name={event?.title || "Event title"}
								options={["Apple", "Google", "Outlook.com", "Yahoo"]}
								location={
									event?.location === "Sur site"
										? event?.address
										: event.eventLink
								}
								description={event?.description || ""}
								startDate={event?.startDate.split("T")[0] || undefined}
								endDate={event?.endDate.split("T")[0] || undefined}
								startTime={
									extractTimeFromISOString(event?.startDate) || undefined
								}
								endTime={extractTimeFromISOString(event?.endDate) || undefined}
								timeZone="Europe/Paris"
								language="fr"
								size="3|3|3"
								inline
								listStyle="modal"
								styleLight="--btn-shadow: none; --btn-background: transparent; --btn-border: none; --btn-text: #2A4054; --btn-background-hover: transparent; --btn-text-hover: #2A4054; --btn-shadow-hover: none; --btn-shadow-active: none"
								styleDark="--btn-shadow: none; --btn-background: transparent; --btn-border: none; --btn-text: #2A4054; --btn-background-hover: transparent; --btn-text-hover: #2A4054; --btn-shadow-hover: none; --btn-shadow-active: none"
							/>
						)}

						{(currentInterface === "school" ||
							parentListName === "planned") && (
							<EventCounters
								event={event}
								currentInterface={currentInterface}
								repliesPresenter={presenter}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const renderEventIllustration = (event: EventType) => {
	return (
		<div className={"w-full rounded-sm bg-primary-100 backdrop-blur-lg"}>
			<img
				src={
					event?.illustration
						? `${BASE_MEDIA_URL + event.illustration.filePath}`
						: getDefaultEventIllustration(event.defaultIllustration)
				}
				alt="event"
				className={`h-full max-h-[172px] min-h-[172px] w-full overflow-clip lg:max-h-[293px] lg:min-h-[293px] ${
					event.illustration
						? "object-contain"
						: "object-cover sm:object-top lg:object-cover"
				}`}
			/>
		</div>
	);
};

const renderStartedTag = () => {
	return (
		<div className="flex max-h-7 items-center justify-center gap-2 rounded-full bg-accent-3-lighter px-4 py-1">
			<div className="icon-sm rounded-full bg-accent-3-dark" />
			<span className="text-xxsm font-normal text-primary-700P">En cours</span>
		</div>
	);
};

const renderEditButton = (targetEvent: EventType) => {
	const dispatchEvent = useAppDispatch();
	const isMentorGoalUser = useAppSelector(
		(state) => state.events.isMentorGoalUser,
	);
	const handleEditButtonClick = () => {
		dispatchEvent(eventsSlice.actions.resetEventToCreate());
		dispatchEvent(eventsSlice.actions.setEventTarget(targetEvent));
	};
	if (!isMentorGoalUser && isMentorGoalEvent(targetEvent?.type)) return null;
	return (
		<NavLink to="/event-creation">
			<ButtonQuaternary
				icon={editOutline}
				id="editEventButton"
				onClick={() => {
					handleEditButtonClick();
				}}
			/>
		</NavLink>
	);
};

const renderOptions = (
	event: EventType,
	parentListID: string,
	currentInterface: string,
) => {
	const optionsComponents =
		currentInterface === "school"
			? new Map([
					["planned", <OptionPlanned event={event} />],
					["replay", <OptionReplay event={event} />],
					["finished", <OptionFinished event={event} />],
				])
			: new Map([
					["planned", <OptionInscription event={event} />],
					["replay", <OptionReplay event={event} />],
					["finished", <OptionFinished event={event} />],
				]);

	return optionsComponents.get(parentListID);
};
