import type { UserCompleteType } from "@/types/userTypes";
import React, { useContext, useEffect, useState } from "react";

import { HistoryContext } from "@modules/logHistory/History";
import { editMedia } from "../../../Students/StudentDetail/StudentDetailAPI";

import { CVStatus } from "./CVHeaderComponents/CVStatus";
import { Editing } from "./CVHeaderComponents/Editing";
import { ToValidate } from "./CVHeaderComponents/ToValidate";
import { Valid } from "./CVHeaderComponents/Valid";

type SmallListType = {
	student: UserCompleteType;
	setStudent: Function;
	fileUpload: { id?: string; size: number; path: string };
	setFileUpload: Function;
};

export function CVHeader({
	student,
	setStudent,
	fileUpload,
	setFileUpload,
}: SmallListType) {
	const [load, setLoad] = useState<boolean>(false);
	const { updateHistory, setUpdateHistory } = useContext(HistoryContext);

	const changeStep = async (
		newStep: string,
		refusalMotive?: string,
		validMotive = "",
		sendMail = true,
		updatedStudent: Partial<UserCompleteType> | null = null,
	) => {
		setLoad(true);

		let action = "";
		if (newStep === "validated" || newStep === "toValidate") {
			action = "validate";
		}
		if (newStep === "editing") {
			action = "refuse";
		}
		let data;
		if (refusalMotive && refusalMotive.length > 0 && newStep === "editing") {
			data = {
				id: fileUpload?.id || student.cv?.id,
				state: newStep,
				refusalMotive,
				action,
				comment: "",
			};
		} else if (validMotive && validMotive.length > 0) {
			data = {
				id: fileUpload?.id || student.cv?.id,
				state: newStep,
				comment: validMotive,
				action,
			};
		} else {
			data = {
				id: fileUpload?.id || student.cv?.id,
				state: newStep,
				comment: "",
			};
		}
		if (sendMail) data = { ...data, action };
		await editMedia(data);
		const newStudent = { ...student, ...updatedStudent };
		if (newStudent.cv) {
			newStudent.cv.state = newStep;
			newStudent.cv.updatedAt = new Date().toString();
		}
		setStudent(newStudent);
		setLoad(false);
		setUpdateHistory(updateHistory + 1);
	};

	useEffect(() => {}, [student]);

	return (
		<div className="h-fit-content ml-0 rounded-lg bg-white p-sm md:w-full  lg:w-full l:ml-0">
			<CVStatus status={student.cv?.state ?? ""} />
			<p className="my-4 font-bold">Validation du CV</p>
			{student.cv?.state === "toValidate" && (
				<ToValidate
					student={student}
					load={load}
					fileUpload={fileUpload}
					setFileUpload={setFileUpload}
					changeStep={changeStep}
				/>
			)}
			{student.cv?.state === "validated" && (
				<Valid student={student} load={load} changeStep={changeStep} />
			)}
			{student.cv?.state === "editing" && (
				<Editing
					student={student}
					load={load}
					setLoad={setLoad}
					setFileUpload={setFileUpload}
					changeStep={changeStep}
				/>
			)}
		</div>
	);
}
