import type { CrmCompanyDomainModel } from "../../../../../types/crmCompany.domainModel";
import {
	fetchGetCompanyRelations,
	fetchUpdateCompanyInformations,
} from "../api/companiesManagement.request";
import type { ICompanyRelationsGateway } from "../gateway/companyRelations.gateway";

export class InAPICompanyRelationsGateway implements ICompanyRelationsGateway {
	async getCompanyRelations(
		id: string,
	): Promise<CrmCompanyDomainModel.Company> {
		const response = await fetchGetCompanyRelations(id);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors du chargement des informations de l'entreprise",
			);
		}
		if (response.data.crmCompany) {
			return response.data.crmCompany;
		}
		return undefined as any;
	}

	async updateCompanyRelations(
		companyId: string,
		valueToUpdate: CrmCompanyDomainModel.CompanyValue[],
	) {
		const response = await fetchUpdateCompanyInformations(
			companyId,
			valueToUpdate,
		);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors de la mise à jour des informations de l'entreprise",
			);
		}
		return response.data;
	}
}
