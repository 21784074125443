import { Formik } from "formik";
import React, { useState } from "react";

import {
	APPLICATION_STEP_OPTIONS,
	APPLICATION_TYPE_OPTIONS,
} from "@constants/Applications";
import { CONTRACT_TYPE_OPTIONS } from "@constants/Offers";

import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Combobox } from "@designSystem/molecules/Combobox";
import { Input } from "@designSystem/molecules/Input";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { SelectTertiary } from "@designSystem/organisms/select/SelectTertiary";

import { autoComplet } from "@containers/student/Applications/core/api/ApplicationsManagementAPI";

export function NewApplicationModal({ show, onClose, onApply, savedData }) {
	const [companyList, setCompanyList] = useState([]);

	const autocompletAPI = async (company) => {
		const companyList = [];
		if (!company) {
			setCompanyList(companyList);
			return;
		}

		autoComplet(company).then((data) => {
			if (data.length > 0) {
				data.map((company, index) => {
					if (company) {
						return companyList.push({
							id: index,
							label: company.name,
							logo: company.logo,
							domain: company.domain,
						});
					}

					return "";
				});
			}
			setCompanyList(companyList);
		});
	};

	return (
		<Formik
			initialValues={{
				logo: undefined,
				company: "",
				job: "",
				offerLink: "",
				accepted: null,
				step: savedData.step ?? "readyToApply",
				contract: savedData.contract ?? "apprenticeship",
				type: savedData.type ?? "spontaneous",
			}}
			enableReinitialize
			validate={(values) => {
				const errors = {};
				if (!values.company) {
					errors.company = "Entrez un nom d'entreprise valide";
				}
				if (!values.job) errors.job = "Entrez un nom de poste";

				return errors;
			}}
			onSubmit={(values, { resetForm }) => {
				autocompletAPI("");
				onApply(values);
				resetForm({});
				onClose();
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				setFieldValue,
				resetForm,
			}) => (
				<Modal
					scroll
					show={show}
					onClose={() => {
						onClose();
						resetForm();
					}}
					title="Créer une candidature"
					body={
						<div>
							<div className="flex flex-col items-center">
								<Avatar
									image={values.logo}
									firstname={values.company.split(" ")[0]}
									lastname={values.company.split(" ")[1]}
									onChange={(e) => {
										setFieldValue("logo", e.url);
									}}
									size="lg"
								/>
								<Combobox
									value={values.company}
									onChange={(e) => {
										autocompletAPI(e.target.value);
										setFieldValue("company", e.target.value);
									}}
									options={companyList}
									className="mt-md"
									placeholder="Nom de l'entreprise"
									onClickOnItem={(elem) => {
										setFieldValue("logo", elem.logo);
									}}
									errorMessage={
										errors.company && touched.company && errors.company
									}
								/>
							</div>
							<div>
								<Input
									onClick={(e) => {
										e.preventDefault();
									}}
									className="mt-md"
									placeholder="Ex : Front-End Developer"
									label="Poste"
									type="text"
									name="job"
									value={values.job}
									errorMessage={errors.job && touched.job && errors.job}
									onChange={handleChange}
								/>
								<SelectTertiary
									defaultValue={APPLICATION_STEP_OPTIONS.find(
										(step) => step.value === values.step,
									)}
									label="Étape"
									className="mt-md"
									options={APPLICATION_STEP_OPTIONS}
									onChange={(e) => {
										setFieldValue("step", e.value);
									}}
								/>
								<div className="mt-md flex items-center gap-sm">
									<SelectTertiary
										className="w-6/12"
										defaultValue={CONTRACT_TYPE_OPTIONS.find(
											(contract) => contract.value === values.contract,
										)}
										label="Type de contrat"
										options={CONTRACT_TYPE_OPTIONS}
										onChange={(e) => {
											setFieldValue("contract", e.value);
										}}
									/>
									<SelectTertiary
										label="Type de candidature"
										className="w-6/12"
										defaultValue={APPLICATION_TYPE_OPTIONS.find(
											(type) => type.value === values.type,
										)}
										options={APPLICATION_TYPE_OPTIONS}
										onChange={(e) => {
											setFieldValue("type", e.value);
										}}
									/>
								</div>
								<Input
									onClick={(e) => {
										e.preventDefault();
									}}
									className="mt-md"
									name="offerLink"
									type="text"
									label="Lien de l'offre"
									placeholder="https://..."
									value={values.offerLink}
									onChange={handleChange}
								/>
							</div>
						</div>
					}
					buttonsRight={[
						<ButtonSecondary
							key="cancelBTN"
							onClick={() => {
								resetForm();
								onClose();
							}}
							label="Annuler"
						/>,
						<ButtonPrimary
							key="confirmBTN"
							onClick={() => {
								handleSubmit();
							}}
							label="Confirmer"
						/>,
					]}
				/>
			)}
		</Formik>
	);
}
