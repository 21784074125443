import type { UserType } from "@/types/userTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { resolveView } from "@tools/Users";

import { Checkbox } from "@designSystem/atoms/Checkbox";
import { Spinner } from "@designSystem/atoms/Spinner";
import { CellAvatarLabelSecondary } from "@designSystem/molecules/CellAvatarLabelSecondary";
import { CheckboxLabelPrimary } from "@designSystem/molecules/CheckboxLabel";
import { Table } from "@designSystem/templates/table/Table";
import {
	setCurrentFilters,
	setCurrentSearch,
} from "@modules/usersFilters/usersFiltersSlice";

import { StudentsTargetingModalContext } from ".";
import {
	getAllStudents,
	retrieveStudents,
	scrollStudents,
} from "./StudentsTargetingModalAPI";

const MIN_ELEMENTS_FOR_SCROLL = 11;

interface ExpectedRetrieveStudentsResponseType {
	responseLabel: string;
	data: {
		visibleStudents?: {
			paginationInfo: {
				totalCount: number;
			};
			collection: UserType[];
		};
	};
}

interface ExpectedGetAllStudentsResponseType {
	data: {
		allStudents?: {
			collection: UserType[];
		};
	};
}

function isExpectedRetrieveStudentsResponseType(
	response: any,
): response is ExpectedRetrieveStudentsResponseType {
	return (
		response &&
		typeof response.responseLabel === "string" &&
		response.data &&
		(response.data.visibleStudents === undefined ||
			(response.data.visibleStudents.paginationInfo &&
				typeof response.data.visibleStudents.paginationInfo.totalCount ===
					"number" &&
				Array.isArray(response.data.visibleStudents.collection)))
	);
}

function isExpectedGetAllStudentsResponseType(
	response: any,
): response is ExpectedGetAllStudentsResponseType {
	return (
		response?.data &&
		(response.data.allStudents === undefined ||
			Array.isArray(response.data.allStudents.collection))
	);
}
export function StudentsList() {
	const {
		structureView,
		totalCount,
		setTotalCount,
		selectedStudents,
		setSelectedStudents,
		getStudentWithCv,
	} = useContext(StudentsTargetingModalContext);
	const dispatchEvent = useDispatch();
	const { currentFilters, currentSearch } = useAppSelector(
		(state) => state.usersFilters,
	);
	const [students, setStudents] = useState<UserType[]>([]);
	const [load, setLoad] = useState<boolean>(true);
	const [isCheckAllLoading, setIsCheckAllLoading] = useState<boolean>(false);

	const topTableRef = useRef<any>();
	const onSearch = async (useSearchValue = false) => {
		setLoad(true);
		const filtersCopy: Partial<FiltersType> = useSearchValue
			? { search: currentSearch }
			: { ...currentFilters };
		if (topTableRef?.current) {
			topTableRef.current.scrollIntoView({ behavior: "smooth" });
		}
		if (getStudentWithCv) {
			filtersCopy.cvExists = { value: true };
		}
		const response = await retrieveStudents(
			filtersCopy,
			resolveView(structureView, "spsPromotion"),
		);
		if (isExpectedRetrieveStudentsResponseType(response)) {
			const { responseLabel, data } = response;
			if (responseLabel === "success" && data.visibleStudents) {
				setTotalCount(data.visibleStudents.paginationInfo.totalCount);
				setStudents(data.visibleStudents.collection);
				setLoad(false);
			}
		}
	};

	const onScroll = async () => {
		setLoad(true);
		const filtersCopy = { ...currentFilters };
		if (getStudentWithCv) {
			filtersCopy.cvExists = { value: true };
		}
		const { visibleStudents }: any = await scrollStudents(
			filtersCopy,
			resolveView(structureView, "spsPromotion"),
		);

		setStudents([...students, ...visibleStudents.collection]);
		setLoad(false);
	};

	const handleOnCheck = (student: UserType) => {
		// if student already exist in the selection, remove him
		if (
			selectedStudents.some(
				(selectedStudent) => selectedStudent.id === student.id,
			)
		) {
			setSelectedStudents(
				selectedStudents.filter(
					(selectedStudent) => selectedStudent.id !== student.id,
				),
			);
		} else {
			// if user dont already selected, add him
			setSelectedStudents(
				Array.from(
					new Set([
						...selectedStudents,
						{ id: student.id, phone: student.phone },
					]),
				),
			);
		}
	};

	const handleCheckAll = async () => {
		if (selectedStudents.length === totalCount) {
			setSelectedStudents([]);
			return;
		}
		setIsCheckAllLoading(true);
		const filtersCopy = { ...currentFilters };
		if (getStudentWithCv) {
			filtersCopy.cvExists = { value: true };
		}
		const response = await getAllStudents(
			{ search: currentSearch, ...filtersCopy },
			resolveView(structureView, "spsPromotion"),
		);
		if (isExpectedGetAllStudentsResponseType(response)) {
			const { data } = response;
			if (data.allStudents) {
				if (
					JSON.stringify(selectedStudents) ===
					JSON.stringify(data.allStudents.collection)
				) {
					setSelectedStudents([]);
				} else {
					setSelectedStudents(data.allStudents.collection);
				}
			}
		}

		setIsCheckAllLoading(false);
	};

	const handleOnReachEnd = () => {
		if (totalCount > currentFilters?.page * MIN_ELEMENTS_FOR_SCROLL) {
			const currentSearchCopy = currentSearch;
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					search: currentSearchCopy,
					page: currentFilters.page + 1,
				}),
			);
			if (
				currentSearchCopy !== undefined &&
				currentSearchCopy !== null &&
				currentSearchCopy !== ""
			) {
				dispatchEvent(setCurrentSearch(currentSearchCopy));
			}
		}
	};

	const cellsResolver = (student: UserType) => {
		return [
			{
				children: (
					<CheckboxLabelPrimary
						checked={selectedStudents.some((selectedStudent) => {
							return selectedStudent.id === student.id;
						})}
						onCheck={() => handleOnCheck(student)}
					/>
				),
			},
			{
				children: (
					<CellAvatarLabelSecondary
						firstname={student.firstname}
						lastname={student.lastname}
						avatar={student.avatar?.filePath}
						label={`${student.lastname} ${student.firstname}`}
						sublabel={student?.spsPromotion?.name}
						onClick={() => {
							handleOnCheck(student);
						}}
					/>
				),
			},
		];
	};

	useEffect(() => {
		if (
			currentSearch !== undefined &&
			currentSearch !== null &&
			currentSearch !== ""
		) {
			if (currentFilters.page === 1) onSearch(true);
			else onScroll();
		} else {
			if (currentFilters.page === 1) onSearch();
			else onScroll();
		}
	}, [currentSearch, currentFilters]);

	return (
		<div className="flex w-1/2 flex-col gap-xsm pl-md">
			<p className="text-xxsm text-primary-200">Résultats : {totalCount}</p>
			<div className="flex items-center">
				<p className="text-xxsm text-primary-700P" />
			</div>
			<div className="h-[470px] overflow-y-auto">
				<div ref={topTableRef} />
				<Table
					isLoading={load}
					filtersProps={{ currentFilters, dispatchEvent: setCurrentFilters }}
					headers={[
						{
							content: isCheckAllLoading ? (
								<Spinner size="small" />
							) : (
								<Checkbox
									checked={selectedStudents.length === totalCount}
									onEnabled={() => {
										handleCheckAll();
									}}
									onDisabled={() => {
										handleCheckAll();
									}}
									disabled={load}
								/>
							),
						},
						{
							label: `${selectedStudents.length} étudiant${
								selectedStudents.length > 1 ? "s" : ""
							} ajouté${selectedStudents.length > 1 ? "s" : ""} à la sélection`,
						},
					]}
					rows={students.map((student: UserType) => {
						return {
							cells: cellsResolver(student),
						};
					})}
					onReachEnd={() => {
						handleOnReachEnd();
					}}
				/>
			</div>
		</div>
	);
}
