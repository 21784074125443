import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Onboarding } from "@modules/onboarding/Onboarding";

import { plusOutline } from "@assets/Icons";
import { EOnboarding } from "@constants/Others";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { GlobalContext } from "@navigation/Router";
import { getCookie } from "@tools/Cookies";
import { Body } from "./ui/Body";
import { Search } from "./ui/Search";
import {
	type ResourcesNavigationType,
	useResourcesNavigation,
} from "./useResourcesNavigation";

type ResourcesProps = {
	defaultTab?: ResourcesNavigationType;
};
export function Resources({ defaultTab = "resources" }: ResourcesProps) {
	const presenter = useResourcesNavigation(defaultTab);
	const { setTitle }: { setTitle: (title: string) => void } =
		useOutletContext();
	const { user } = useContext(GlobalContext);

	const [displayOnBoarding, setDisplayOnBoarding] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (user?.onboardingSteps) {
			const hasSeenOnboarding =
				user.onboardingSteps?.split("")[EOnboarding.RESOURCES];
			if (!hasSeenOnboarding) return;
			setDisplayOnBoarding(Number.parseInt(hasSeenOnboarding) === 0);
		}
	}, []);

	useEffect(() => {
		setTitle("Mes ressources");
	}, [window.location.href]);

	return (
		<main className="flex flex-col items-end gap-md">
			{getCookie("interface") === "referent" && (
				<ButtonPrimary
					label="Créer une ressource"
					onClick={() => {
						navigate("/school-resources/create");
					}}
					icon={plusOutline}
				/>
			)}

			<Search
				filters={presenter.currentFilter}
				updateResourcesList={presenter.callUpdateResourcesList}
				load={presenter.isLoading}
			/>

			<Body
				load={presenter.isLoading}
				mgResources={presenter.mgResources}
				schoolResources={presenter.schoolResources}
				presenterRef={presenter}
			/>
			<Onboarding
				currentPath={EOnboarding.RESOURCES}
				show={displayOnBoarding}
				onClose={() => {
					setDisplayOnBoarding(false);
				}}
			/>
		</main>
	);
}
