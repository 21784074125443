import type { Author, MessageCompleteType, MessageType } from "@/types/mailbox";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import { format } from "date-fns";
import React, {
	type FormEvent,
	type KeyboardEvent,
	useContext,
	useEffect,
	useState,
} from "react";
import { useDispatch } from "react-redux";

import { moreHorizontalOutline, paperPlaneOutline } from "@assets/Icons";

import { MODULE_PRESET } from "@constants/Others";

import { stringifyRequestFilters } from "@tools/Objects";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { RichTextEditor } from "@designSystem/atoms/RichTextEditor";
import { Switch } from "@designSystem/atoms/Switch";
import { Input } from "@designSystem/molecules/Input";
import { errorToast } from "@tools/Toasts";

import type { StandardGraphQLResponse } from "@tools/API";
import { getSMSCredits, postMessage } from "../controller/mailBox.request";
import { setMessages } from "../controller/mailBox.slice";
import { fetchUpdateConversation } from "../controller/mailBoxManagement.thunks";

type MessageEditorType = {
	className?: string;
};

export default function MessageEditor({ className = "" }: MessageEditorType) {
	const dispatchMailBox = useDispatch();
	const { user, currentInterface, slidingPaneIsOpen } =
		useContext(GlobalContext);
	const { studentSelected, conversation, messages } = useAppSelector(
		(state) => state.mailBox,
	);

	const [message, setMessage] = useState<string>("");
	const [sendSMS, setSendSMS] = useState<boolean>(false);
	const [sendMail, setSendMail] = useState<boolean>(true);
	const [smsCredits, setSMSCredits] = useState<number>(0);
	const [loadSubmitMessage, setLoadSubmitMessage] = useState<boolean>(false);

	const getCredits = async () => {
		if (!user) return;
		const { data }: StandardGraphQLResponse = await getSMSCredits(user?.school);

		if (data) setSMSCredits(data.school?.smsCredits ?? 0);
	};

	const handleSubmitMessage = async (
		event: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLInputElement>,
	) => {
		event.preventDefault();
		if (!studentSelected) return;
		const messageToSend: MessageCompleteType = {
			conversation: studentSelected.conversation?.id,
			content: message,
			isGroupedMessage: false,
			isSendBySMS: currentInterface === "school" ? sendSMS : false,
			isSendByMail: currentInterface === "school" ? sendMail : false,
		};
		currentInterface === "school"
			? (messageToSend.spsReferent = user?.id)
			: (messageToSend.spsStudent = user?.id);

		setLoadSubmitMessage(true);
		const { data }: any = await postMessage(
			stringifyRequestFilters(messageToSend),
		);
		try {
			if (data && user) {
				const newMessage: MessageType = {
					id: data.createSpsMessage.id,

					content: message,
					isReadByStudent: true,
					isGroupedMessage: false,
					isSendBySMS: currentInterface === "school" ? sendSMS : false,
					isSendByMail: currentInterface === "school" ? sendMail : false,
					datetime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
				};
				const entity = {
					id: user.id,
					avatar: user.avatar
						? { id: user.avatar.id, filePath: user.avatar.filePath }
						: null,
					firstname: user.firstname,
					lastname: user.lastname,
				} satisfies Author;
				if (currentInterface === "school") {
					newMessage.spsReferent = entity;
				} else {
					newMessage.spsStudent = entity;
				}

				dispatchMailBox(
					fetchUpdateConversation({
						id: studentSelected.conversation?.id,
						status: "unsolved",
					}) as any,
				);
				dispatchMailBox(setMessages([...messages, newMessage]));
				setMessage("");
				getCredits();
				setLoadSubmitMessage(false);
			}
		} catch (_e) {
			setLoadSubmitMessage(false);
			errorToast("une erreur est survenue. votre message n'a pas été envoyé");
		}
	};

	const getLabel = () => {
		if (currentInterface === "school") {
			return {
				label: `${loadSubmitMessage ? "Envoi" : "Envoyer"}`,
			};
		}
		return {};
	};
	useEffect(() => {
		setMessage("");
		getCredits();
	}, [studentSelected, conversation, currentInterface]);

	return (
		<form
			className={`flex ${
				currentInterface === "school" ? "flex-col" : ""
			} justify-between rounded-xl border px-md py-xsm shadow-md ${className}`}
			onSubmit={handleSubmitMessage}
		>
			{currentInterface === "school" ? (
				<RichTextEditor
					className={`mb-sm !flex-col [&_.ql-active]:!rounded [&_.ql-active]:!bg-primary-150   [&_.ql-container]:!border-none [&_.ql-container]:bg-white  [&_.ql-snow]:!border-none [&_.ql-toolbar]:!border-none [&_.ql-editor]:!border-none [&_.ql-toolbar]:bg-white [&_.ql-toolbar]:!p-0 ${
						currentInterface === "school"
							? "[&_.ql-editor]:!min-h-[80px] [&_.ql-container]:!min-h-[80px]"
							: ""
					}`}
					modules={MODULE_PRESET}
					placeholder="Ecrire un message ..."
					value={message}
					onChange={(value) => {
						setMessage(value);
					}}
				/>
			) : (
				<Input
					onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
						if (event.key === "Enter") {
							handleSubmitMessage(event as KeyboardEvent<HTMLInputElement>);
						}
					}}
					className="!min-h-0 w-full [&_*]:!border-none [&_*]:!shadow-none"
					value={message}
					placeholder="Ecrire un message ..."
					type="text"
					onChange={(e) => {
						setMessage(e.target.value);
					}}
				/>
			)}

			<div className={"flex flex-col justify-between sm:flex-row h-fit }"}>
				{currentInterface === "school" && (
					<div
						className={`${
							slidingPaneIsOpen ? "" : "md:w-8/12 "
						} mb-sm flex w-full flex-wrap items-start gap-xsm`}
					>
						<div
							className={`flex w-fit flex-row-reverse items-start gap-sm ${
								slidingPaneIsOpen ? "" : "md:flex-row"
							}`}
						>
							<p className="text-xxsm text-primary-700P">
								Notifier l'étudiant par e-mail
							</p>
							<Switch
								checked={sendMail}
								onEnabled={() => {
									setSendMail(true);
								}}
								onDisabled={() => {
									setSendMail(false);
								}}
								className="mr-xsm"
							/>
						</div>
						{!slidingPaneIsOpen && (
							<hr className="vertical-separator mx-sm hidden h-full w-1/12 md:block" />
						)}

						<div
							className={`flex w-full  flex-col items-start ${
								slidingPaneIsOpen ? "" : "md:w-5/12"
							}`}
						>
							<div
								className={`flex flex-row-reverse items-center gap-sm ${
									slidingPaneIsOpen ? "" : "md:flex-row"
								}`}
							>
								<p className="text-xxsm text-primary-700P">
									Notifier l'étudiant par sms
								</p>
								<Switch
									checked={sendSMS}
									onEnabled={() => {
										setSendSMS(true);
									}}
									onDisabled={() => {
										setSendSMS(false);
									}}
									className="mr-xsm"
								/>
							</div>
							<p className="text-xxxsm text-primary-500">
								SMS disponnibles: {smsCredits}
							</p>
							{smsCredits === 0 && sendSMS && (
								<p className="text-xxsm text-error-dark">
									Vous n'avez pas assez de crédit SMS, veuillez décocher cette
									option ou recharger vos crédits SMS
								</p>
							)}
						</div>
					</div>
				)}

				<ButtonPrimary
					onClick={() => {}}
					label=""
					className="ml-0 h-fit w-full sm:ml-sm sm:w-fit"
					icon={loadSubmitMessage ? moreHorizontalOutline : paperPlaneOutline}
					type="submit"
					disabled={
						(smsCredits === 0 && sendSMS) ||
						loadSubmitMessage ||
						message?.replace(/<[^>]*>/g, "").length === 0
					}
					{...getLabel()}
				/>
			</div>
		</form>
	);
}
