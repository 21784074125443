import type { ApplicationThumbType } from "@/types/applicationTypes";
import type { SchoolOfferType } from "@/types/offerTypes";
import type { SelectionType } from "@/types/profileSharingTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";

import { extractProfilsToSend } from "@tools/Offers";
import { extractIdNumberFromId } from "@tools/Users";

import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { BodyGrid } from "@designSystem/templates/page/BodyGrid";
import { Container } from "@designSystem/templates/page/Container";
import { Subcontainer } from "@designSystem/templates/page/Subcontainer";

import { CreateSelectionModal } from "@containers/school/CVBoard/ui/CVsList/TableView/CreateSelectionModal";

import {
	fetchOfferDetails,
	fetchOffersApplication,
	fetchOffersApplicationsThumbnails,
} from "@containers/school/Offers_v3/core/store/offersManagement.thunks";

import { ApplicationDataVisualizer } from "../applications/ApplicationDataVisualizer";

import { modifyOfferProfilsSelection } from "@containers/school/Offers_v3/core/api/offerDetails.request";
import {
	modifyOfferToEdit,
	setSendProfilWasClicked,
} from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import { SendProfilsButton } from "./SendProfilsButton";
import { SentThumb } from "./SentThumb";

export function SendToCompanyTab({
	offerToEdit,
}: {
	offerToEdit: Partial<SchoolOfferType>;
}) {
	const dispatchEvent = useDispatch();
	const {
		loadingStates,
		applicationsThumbnails,
		applicationSelectedId,
		sendProfilWasClicked,
	} = useAppSelector((state) => state.offers);
	const acceptedApplicationbsCounter = applicationsThumbnails?.filter(
		(thumbnail: ApplicationThumbType) => thumbnail?.state === "accepted",
	)?.length;
	const buildThumbnailsBlock = (
		showSendProfilButton = true,
		showSentProfils = false,
		tooltipText?: string,
	) => {
		const usersInSelection =
			offerToEdit?.selection?.selectionUsers?.collection || [];
		const thumbnails = applicationsThumbnails?.filter(
			(thumbnail: ApplicationThumbType) =>
				showSentProfils
					? thumbnail?.state === "accepted" &&
						usersInSelection.find(
							({ user }) => user.id === thumbnail?.spsStudent?.id,
						)
					: thumbnail?.state === "accepted" &&
						!usersInSelection.find(
							({ user }) => user.id === thumbnail?.spsStudent?.id,
						),
		);
		return (
			<div className="mt-md flex flex-col items-start justify-start gap-sm">
				{showSendProfilButton ? <SendProfilsButton /> : null}
				<p className="text-sm font-bold text-primary-700P flex flex-row gap-2 items-center">
					{showSentProfils ? "Profils transmis" : "Profils non transmis"}(
					{thumbnails?.length})
					{tooltipText && <TooltipElement id="thumbnailListTooltip" />}
				</p>
				<TooltipContainer
					anchorId="#thumbnailListTooltip"
					place="top-start"
					children={tooltipText}
				/>
				{thumbnails?.map((thumbnail: ApplicationThumbType) => (
					<SentThumb
						key={thumbnail?.id}
						dispatchEvent={dispatchEvent}
						thumbnailData={thumbnail}
						isSelected={applicationSelectedId === thumbnail?.id}
						isSent={showSentProfils}
					/>
				))}
			</div>
		);
	};

	const callFetchThumbnails = () => {
		dispatchEvent(
			fetchOffersApplicationsThumbnails(offerToEdit?.id || "") as any,
		);
	};

	const callFetchSelectedApplication = () => {
		dispatchEvent(fetchOffersApplication(applicationSelectedId || "") as any);
	};

	const handleCloseShareProfilModal = () => {
		dispatchEvent(setSendProfilWasClicked(false));
		dispatchEvent(
			fetchOfferDetails({
				id: extractIdNumberFromId(offerToEdit?.id).toString(),
			}) as any,
		);
	};

	const handleSelectionCaught = (selection: SelectionType) => {
		if (!offerToEdit.selection)
			modifyOfferProfilsSelection(offerToEdit?.id || "", selection?.id || "");
		dispatchEvent(
			modifyOfferToEdit({
				selection,
			}),
		);
	};

	useEffect(() => {
		if (applicationSelectedId) {
			callFetchSelectedApplication();
		}
	}, [applicationSelectedId]);

	useEffect(() => {
		callFetchThumbnails();
	}, [offerToEdit?.id]);
	const showApplications =
		offerToEdit.applicationsCount !== 0 &&
		acceptedApplicationbsCounter !== 0 &&
		!loadingStates?.isFetchingApplicationsThumbnail;
	return (
		<BodyGrid>
			<Container side="left" reversedGrid overrideWidth={"w-80 !max-w-[312px]"}>
				<Subcontainer>
					<div className="max-h-[600px] overflow-y-auto scrollbar-hide">
						<div className="flex flex-row gap-md items-center justify-between">
							<p className="text-xxsm font-normal uppercase text-primary-300">
								liste des étudiants
							</p>
						</div>
						{loadingStates.isFetchingApplicationsThumbnail && (
							<Skeleton count={8} className="h-28 w-80 max-w-full rounded-md" />
						)}
						{!showApplications && (
							<p className="text-xxsm font-bold text-primary-500 text-center mt-md">
								Vous n'avez aucun profils.
							</p>
						)}
						{offerToEdit?.type === "preselection" &&
							acceptedApplicationbsCounter !== 0 &&
							buildThumbnailsBlock(
								true,
								false,
								"Profils non envoyés à l’entreprise.",
							)}
						{offerToEdit?.type === "preselection" &&
							acceptedApplicationbsCounter !== 0 &&
							buildThumbnailsBlock(false, true)}
					</div>
				</Subcontainer>
			</Container>
			{showApplications && (
				<Container
					side="right"
					reversedGrid
					overrideWidth={"!w-full !max-w-full"}
				>
					{loadingStates.isFetchingApplication ? (
						<>
							<Subcontainer>
								<Skeleton
									count={1}
									className="h-96 min-h-full max-w-full rounded-md"
								/>
							</Subcontainer>
							<Subcontainer>
								<Skeleton
									count={1}
									className="h-96 min-h-full max-w-full rounded-md"
								/>
							</Subcontainer>
						</>
					) : (
						<Subcontainer>
							{acceptedApplicationbsCounter !== 0 && (
								<ApplicationDataVisualizer />
							)}
						</Subcontainer>
					)}
				</Container>
			)}
			<CreateSelectionModal
				show={sendProfilWasClicked || false}
				onClose={handleCloseShareProfilModal}
				CVsListProps={{
					selectedCVs: extractProfilsToSend(applicationsThumbnails),
					setSelectedCVs: () => {},
				}}
				noModifiable
				autocomplete={{
					company: offerToEdit?.crmCompany?.name || "",
					jobname: offerToEdit?.job || "",
				}}
				catchSelection={(selection: SelectionType) => {
					handleSelectionCaught(selection);
				}}
				selectionToEdit={offerToEdit?.selection}
				offerId={offerToEdit?.id}
			/>
		</BodyGrid>
	);
}
